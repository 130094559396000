import React from 'react';
import PropTypes from 'prop-types';
import DownloadStep from './DownloadStep';
import ExplainStep from './ExplainStep';
import UploadStep from './UploadStep';

const ModalContent = ({
  templateLocation,
  handleUpload,
  onDrop,
  templateName,
  docBase64,
  loading,
}) => (
  <div className="upload-modal-content">
    <DownloadStep templateName={templateName} templateLocation={templateLocation} />
    <ExplainStep templateName={templateName.split('.')[0]} />
    <UploadStep onDrop={onDrop} />
    <div className="buttons">
      <button
        className="button button--upload"
        onClick={() => handleUpload()}
        disabled={!docBase64 || loading}
        type="button"
      >
        {loading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  </div>
);

ModalContent.propTypes = {
  templateLocation: PropTypes.string,
  handleUpload: PropTypes.func,
  onDrop: PropTypes.func,
  templateName: PropTypes.string,
  docBase64: PropTypes.string,
  loading: PropTypes.bool,
};

export default React.memo(ModalContent);
