import React from 'react';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import FullscreenDialog from 'material-ui-fullscreen-dialog';

const FullscreenAgreementsDialog = ({
  open,
  pageNumber,
  numPages,
  handleAgreementsState,
  onDocumentLoad,
  title,
  file,
  setPageNumber,
}) => (
  <FullscreenDialog
    open={open}
    onRequestClose={handleAgreementsState}
    style={{
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'center',
    }}
    title={title}
  >
    <div>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoad}
      >
        <Page
          pageNumber={pageNumber}
        />
      </Document>
      <div>
        <div className="terms-conditions-modal">
          <p
            className={`${pageNumber === 1 ? 'hide-button' : ''} underline`}
            onClick={() => pageNumber !== 1 && setPageNumber(pageNumber - 1)}
          >
            Previous
          </p>
          <p>
            Page
            {pageNumber}
            {' '}
            of
            {numPages}
          </p>
          <p
            className={`${pageNumber === numPages ? 'hide-button' : ''} underline`}
            onClick={() => pageNumber !== numPages && setPageNumber(pageNumber + 1)}
          >
            Next
          </p>
        </div>
      </div>
    </div>
  </FullscreenDialog>
);

FullscreenAgreementsDialog.propTypes = {
  open: PropTypes.bool,
  pageNumber: PropTypes.number,
  numPages: PropTypes.number,
  handleAgreementsState: PropTypes.func,
  onDocumentLoad: PropTypes.func,
  title: PropTypes.string,
  file: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  setPageNumber: PropTypes.func,
};

export default React.memo(FullscreenAgreementsDialog);
