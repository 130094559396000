import { START_LOADING, STOP_LOADING } from '../constants/ActionTypes';

const initialState = {
  calendar: { loaded: false, cached: false },
  bookings: { loaded: false, cached: false },
  drawer: { loaded: false, cached: false },
  clients: { loaded: false, cached: false },
  managers: { loaded: false, cached: false },
  partners: { loaded: false, cached: false },
  products: { loaded: false, cached: false },
  instructors: { loaded: false, cached: false },
  salary: { loaded: false, cached: false },
  salaryTable: { loaded: false, cached: false },
  additionalServices: { loaded: false, cached: false },
  groups: { loaded: false, cached: false },
  hotels: { loaded: false, cached: false },
};

const refreshIndicator = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING: {
      const { payload: { destination } } = action;
      const loader = state[destination];

      return { ...state, [destination]: { ...loader, ...{ loaded: false } } };
    }
    case STOP_LOADING: {
      const { payload: { destination } } = action;
      const loader = state[destination];

      return { ...state, [destination]: { ...loader, ...{ loaded: true, cached: true } } };
    }
    default: {
      return state;
    }
  }
};

export default refreshIndicator;
