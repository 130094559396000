import React from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRoute } from 'react-router';
import Authentication from '../containers/Authentication';

const Route = (props) => {
  const {
    component,
    layout,
    path,
    exact,
    allowedRoles,
    name,
  } = props;

  let routeComponent = (componentProps) => React.createElement(component, componentProps);

  if (layout) {
    routeComponent = (componentProps) => React.createElement(layout, props,
      React.createElement(component, componentProps));

    if (allowedRoles) {
      routeComponent = (componentProps) => React.createElement(Authentication, { allowedRoles },
        React.createElement(layout, props,
          React.createElement(component, componentProps)));
    }
  } else if (allowedRoles) {
    routeComponent = (componentProps) => React.createElement(Authentication, { allowedRoles },
      React.createElement(component, componentProps));
  }

  return <ReactRoute name={name} exact={exact} path={path} component={routeComponent} />;
};

Route.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  layout: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  path: PropTypes.string,
  exact: PropTypes.bool,
  allowedRoles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  name: PropTypes.string,
};

export default React.memo(Route);
