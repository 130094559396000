import React from 'react';
import PropTypes from 'prop-types';

const GroupRenderer = ({ group }) => {
  const handleClick = () => {
    if (group.role === 'manager') {
      group.onClick(group.id);
    }
  };

  return (
    <div
      className="title"
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
      id={`group-${group.id}`}
    >
      {group.title}
    </div>
  );
};

GroupRenderer.propTypes = {
  group: PropTypes.shape({
    role: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
};

export default GroupRenderer;
