import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import _ from 'lodash';
import moment from 'moment';
import Checkbox from 'material-ui/Checkbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Popover from 'material-ui/Popover';
import Dialog from '../components/dialog/Dialog';
import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';
import { DateField } from '../components/Field';
import * as accountActions from '../actions/accountActions';
import * as instructorSalaryActions from '../actions/instructorSalaryActions';
import * as instructorsActions from '../actions/instructorsActions';
import * as indicatorActions from '../actions/refreshIndicatorActions';
import { notifySuccess } from '../actions/notificationActions';
import ListTable from '../components/ListTable';
import EditEntityButton from '../components/EditEntityButton';
import DownloadEntityButton from '../components/DownloadEntityButton';
import MassActionsComponent from '../components/MassActionsComponent';
import TaxesLabel from '../components/TaxesLabel';
import InstructorSalaryForm from './InstructorSalaryForm';
import invokeURL from '../../config/api-config';
import { sortTable } from '../utils/helpers';
import '../styles/InstructorSalary.scss';

const filterActiveInstructors = (instructors, props) => {
  const { salaries } = props;
  const filteredInstructors = [];

  _.forEach(instructors, (instructor) => {
    _.forEach(salaries, (salary) => {
      if (instructor.active && instructor.id === salary.instructors[0].id) {
        filteredInstructors.push(instructor);
      }
    });
  });

  return filteredInstructors;
};

class InstructorSalary extends Component {
  static getDerivedStateFromProps(props, state) {
    const { instructors } = props;
    if (instructors) {
      return {
        ...state,
        instructors: filterActiveInstructors(instructors, props),
      };
    }
    return state;
  }

  datePickerStyle = {
    color: '#253D63',
    fontSize: '14px',
    width: '195px',
  };

  customStyle = {
    width: '482px',
    height: '435px',
  };

  constructor(props) {
    super(props);
    this.state = {
      datesEntered: false,
      dialogOpen: false,
      instructorIdEditing: null,
      massActions: [
        { text: 'Edit', value: 0 },
        { text: 'Download', value: 1 },
      ],
      massAction: false,
      selectedMassAction: null,
      instructors: [],
      selectedInstructors: [],
      taxesOpen: false,
      anchorEl: null,
      initialValuesTaxesLabel: null,
      generating: false,
    };
  }

  componentDidMount() {
    const {
      actions: {
        indicatorActions: { startLoading, stopLoading },
        account,
        instructors,
      },
      change,
    } = this.props;
    startLoading('salary');
    Promise.all([
      account.fetchMyAccount(),
      instructors.fetchInstructors(),
    ]).then(() => {
      stopLoading('salary');
    });
    change('date', new Date());
  }

  handleSingleCheck = (instructorId) => () => {
    const { selectedInstructors } = this.state;
    const { instructors } = this.props;
    let instructorsChecked = selectedInstructors;
    if (_.some(selectedInstructors, { id: instructorId })) {
      instructorsChecked = _.filter(instructorsChecked,
        (instructor) => instructor.id !== instructorId);
      this.refs.multiCheck.state.switched = false;
    } else {
      instructorsChecked.push(instructors[instructorId]);
    }
    this.setState({
      selectedInstructors: instructorsChecked,
    });
  }

  handleMultiCheck = (event, isInputChecked) => {
    const { instructors } = this.state;
    let instructorsChecked = [];
    if (isInputChecked) {
      instructorsChecked = instructors;
    }
    this.setState({
      selectedInstructors: instructorsChecked,
    });
  }

  setMassActionType = (type) => {
    this.setState({
      selectedMassAction: parseInt(type, 10),
    });
  }

  handleDialog = (id) => {
    const { dialogOpen } = this.state;
    this.setState({
      dialogOpen: !dialogOpen,
      instructorIdEditing: id || null,
    });
  };

  handleInvoiceButton = (path) => {
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    link.href = [invokeURL.invokeUrl, path].join('');
    link.target = '_blank';
    link.download = 'file.pdf';
    link.click();
    document.body.removeChild(link);
    // window.open([invokeURL.invokeUrl, path].join(''), '_blank');
  };

  handleAsyncInvoice = (account, dateFrom, dateTo, instructor, date, ct) => {
    const path = `/salaryInvoice/${account[0].id}?timeFrom=${dateFrom}&timeTo=${dateTo}&instructor=${instructor.id}&paymentOwner=${account[0].name}&paymentLocation=${account[0].paymentLocation}&paymentDate=${date}`;
    setTimeout(() => {
      this.handleInvoiceButton(path);
    }, 1000 * ct);
  }

  handleMassActionSubmit = () => {
    const { selectedMassAction, selectedInstructors } = this.state;
    if (selectedMassAction === 0) {
      this.setState({
        dialogOpen: true,
        instructorIdEditing: 1,
        massAction: true,
      });
    } else if (selectedMassAction === 1) {
      const { selector, account } = this.props;
      const dateFrom = moment(selector.dateFrom).format('YYYY-MM-DD');
      const dateTo = moment(selector.dateTo).format('YYYY-MM-DD');
      const date = moment(selector.date).format('YYYY-MM-DD');
      let ct = 1;
      _.forEach(selectedInstructors, (instructor) => {
        this.handleAsyncInvoice(account, dateFrom, dateTo, instructor, date, ct);
        ct += 1;
      });
    }
  };

  instructorsToRows = (instructors) => {
    const { selectedInstructors } = this.state;
    const { selector, account } = this.props;
    const { currency } = account[0];
    const rows = [];
    const dateFrom = moment(selector.dateFrom).format('YYYY-MM-DD');
    const dateTo = moment(selector.dateTo).format('YYYY-MM-DD');
    const date = moment(selector.date).format('YYYY-MM-DD');
    const paymentLocation = account[0].paymentLocation && account[0].paymentLocation;
    _.forEach(instructors, (instructor) => {
      const row = [];
      const salaries = this.groupSalariesByInstructor(instructor.id);
      const taxes = [];
      const taxesLabel = [];
      if (salaries.taxes) {
        _.forEach(salaries.taxes, (tax) => {
          if (tax && Object.keys(tax).length !== 0) {
            if (tax.percent) {
              taxes.push(`${tax.percent}%`);
              taxesLabel.push({ name: tax.name, percent: tax.percent });
            }
          }
        });
      }
      const path = `/salaryInvoice/${account[0].id}?timeFrom=${dateFrom}&timeTo=${dateTo}&instructor=${instructor.id}&paymentOwner=${account[0].name}&paymentLocation=${paymentLocation}&paymentDate=${date}`;
      row[0] = (
        <Checkbox
          checked={_.some(selectedInstructors, { id: instructor.id })}
          onCheck={this.handleSingleCheck(instructor.id)}
        />
      );
      row[1] = instructor.name ? instructor.name : '-';
      row[2] = instructor.surname ? instructor.surname : '-';
      row[3] = salaries.wageType === 'fixed' ? salaries.wage + currency : '-';
      row[4] = salaries.wageType === 'hourly' ? salaries.wage + currency : '-';
      row[5] = salaries.hours ? `${salaries.hours} h` : '-';
      if (salaries.bonusType === 'fixed') {
        row[6] = salaries.bonus ? salaries.bonus + currency : '-';
      } else {
        row[6] = salaries.bonus ? (salaries.bonus * salaries.bonusHours) + currency : '-';
      }
      row[7] = taxes
        ? (
          <span
            className="taxes-label"
            ref={`tax_${instructor.id}`}
            style={{ cursor: 'pointer' }}
            onClick={this.handleShowLabel(taxesLabel, instructor.id)}
          >
            {_.join(taxes, '\u00A0\u00A0')}
          </span>
        ) : '-';
      row[8] = salaries.total ? salaries.total.toFixed(2) + currency : '-';
      row[9] = (
        <div className="list-table__column-content--inline">
          <span onClick={() => this.handleDialog(instructor.id)}><EditEntityButton /></span>
          <span onClick={() => this.handleInvoiceButton(path)}><DownloadEntityButton /></span>
        </div>
      );
      rows.push(row);
    });
    return rows;
  }

  handleShowLabel = (taxes, instructorId) => () => {
    const refName = `tax_${instructorId}`;
    this.setState({
      initialValuesTaxesLabel: taxes,
      anchorEl: this.refs[refName],
      taxesOpen: true,
    });
  }

  handleCloseLabel = () => {
    this.setState({
      taxesOpen: false,
    });
  }

  handleGenerateButton = () => {
    this.setState({
      generating: true,
    });

    const {
      actions: {
        indicatorActions: { startLoading, stopLoading },
        instructorSalary: { fetchInstructorsSalary },
      },
      selector: { dateFrom, dateTo },
      change,
    } = this.props;
    const dateInterval = {
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD'),
    };
    change('selector.dateFrom', null);
    change('selector.dateTo', null);
    startLoading('salaryTable');
    fetchInstructorsSalary(dateInterval)
      .then(() => {
        stopLoading('salaryTable');
      });
  }

  handleDatesEntered = (fieldName) => () => {
    const { selector } = this.props;
    if (fieldName === 'dateFrom') {
      if (selector.dateTo) {
        this.setState({
          datesEntered: true,
        });
      }
    } else if (selector.dateFrom) {
      this.setState({
        datesEntered: true,
      });
    }
  }

  handleUpdate = (body, instructorId) => {
    const { selector, actions } = this.props;
    const payload = {
      ...body,
      dateFrom: moment(selector.dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(selector.dateTo).format('YYYY-MM-DD'),
    };
    return Promise.all([
      actions.instructorSalary.updateInstructorSalary(payload, instructorId),
    ]).then(() => {
      actions.instructorSalary.fetchInstructorsSalary({
        dateFrom: payload.dateFrom,
        dateTo: payload.dateTo,
      });
      this.setState({
        dialogOpen: false,
        instructorIdEditing: null,
        massAction: false,
      });
      actions.notifySuccess({}, 'Instructor updated successfully');
    }).catch((err) => err);
  }

  groupSalariesByInstructor = (id) => {
    const { salaries } = this.props;
    const salariesGrouped = _.groupBy(salaries, 'instructors[0].id');
    return salariesGrouped[id] ? salariesGrouped[id][0] : {};
  };

  handleSort = (columnIndex, asc) => {
    const { salaries } = this.props;
    const { instructors } = this.state;
    const columns = {
      1: {
        name: ['name'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      2: {
        name: ['surname'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      3: {
        name: ['wageType', 'wage'], dependency: 'salaries', level: 2, orderType: 'every', dataArray: false,
      },
      4: {
        name: ['wageType', 'wage'], dependency: 'salaries', level: 2, orderType: 'every', dataArray: false,
      },
      5: {
        name: ['hours'], dependency: 'salaries', level: 2, orderType: 'normal', dataArray: false,
      },
      6: {
        name: ['bonus'], dependency: 'salaries', level: 2, orderType: 'normal', dataArray: false,
      },
      7: {
        name: ['taxes'], dependency: 'salaries', level: 2, orderType: 'normal', dataArray: 'percent',
      },
      8: {
        name: ['total'], dependency: 'salaries', level: 2, orderType: 'normal', dataArray: false,
      },
    };
    const dependencyData = {
      salaries: { data: _.mapValues(_.keyBy(salaries, 'instructors[0].id')), name: 'id' },
    };
    const sortedList = sortTable(instructors, columns, columnIndex, asc, dependencyData);
    this.setState({
      instructors: sortedList,
    });
  }

  render() {
    const {
      datesEntered,
      dialogOpen,
      instructorIdEditing,
      selectedInstructors,
      massAction,
      instructors,
      generating,
      massActions,
      taxesOpen,
      anchorEl,
      initialValuesTaxesLabel,
    } = this.state;
    const {
      handleSubmit,
      account,
      salaries,
      submitting,
      selector,
      refreshIndicator,
    } = this.props;
    return (
      <div className="instructor-salary">
        {
          !_.isEmpty(account) && refreshIndicator.salary.loaded
            ? (
              <form
                onSubmit={handleSubmit(this.handleGenerateButton)}
              >
                <div className="instructor-salary__meta-data">
                  <div className="instructor-salary__meta-data__item">
                    <span className="instructor-salary__text--label">Payment Owner:</span>
                    <span className="instructor-salary__text--value">{account[0].name}</span>
                  </div>
                  <div className="instructor-salary__meta-data__item">
                    <span className="instructor-salary__text--label">Payment Location:</span>
                    <span className="instructor-salary__text--value">{account[0].paymentLocation}</span>
                  </div>
                  <div className="instructor-salary__meta-data__item__material">
                    <DateField
                      fieldLabel="Payment Date:"
                      name="date"
                      textFieldStyle={this.datePickerStyle}
                    />
                  </div>
                </div>
                <div className="instructor-salary__subheading">Calculate</div>
                <div className="instructor-salary__date-picker">
                  <div className="instructor-salary__date-picker__item">
                    <DateField
                      fieldLabel="Date from"
                      name="dateFrom"
                      textFieldStyle={this.datePickerStyle}
                      onChange={this.handleDatesEntered('dateFrom')}
                    />
                  </div>
                  <div className="instructor-salary__date-picker__item">
                    <DateField
                      fieldLabel="Date To:"
                      name="dateTo"
                      textFieldStyle={this.datePickerStyle}
                      onChange={this.handleDatesEntered('dateTo')}
                      minDate={selector.dateFrom}
                    />
                  </div>
                  <button
                    className={`instructor-salary__button${!datesEntered ? ' instructor-salary__button--disabled' : ''}`}
                    onClick={this.handleGenerateButton}
                    disabled={!datesEntered || submitting}
                    style={{ cursor: 'pointer' }}
                    type="submit"
                  >
                    generate
                  </button>
                </div>
              </form>
            )
            : (
              <RefreshIndicator
                size={300}
                top={150}
                left={540}
                status={refreshIndicator.salary.loaded ? 'hide' : 'loading'}
                className={refreshIndicator.salary.loaded ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
              />
            )
        }

        { generating && (
          !refreshIndicator.salaryTable.loaded
            ? (
              <RefreshIndicator
                size={300}
                top={150}
                left={540}
                status={refreshIndicator.salaryTable.loaded ? 'hide' : 'loading'}
                className={refreshIndicator.salaryTable.loaded ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
              />
            )
            : (
              <div>
                <div className="instructor-list__row-wrapper">
                  <MassActionsComponent
                    massActions={massActions}
                    detectMassAction={this.setMassActionType}
                    handleMassAction={this.handleMassActionSubmit}
                  />
                </div>
                <ListTable
                  dest="salary"
                  names={['checkbox', 'name', 'surname', 'fixed', 'wage', 'hours', 'bonus', 'taxes', 'total', 'icons']}
                  className={!refreshIndicator.salaryTable.loaded ? 'indicator-hidden' : ''}
                  header={[
                    <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
                      <Checkbox ref="multiCheck" onCheck={this.handleMultiCheck} />
                    </MuiThemeProvider>,
                    'Name',
                    'Surname',
                    'Fixed',
                    'Wage',
                    'Hours',
                    'Bonus',
                    'Taxes',
                    'Total',
                    ' ',
                  ]}
                  rows={instructors ? this.instructorsToRows(instructors) : []}
                  handleSort={this.handleSort}
                />
              </div>
            )
        ) }
        <Popover
          open={taxesOpen}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleCloseLabel}
        >
          <TaxesLabel initialValues={initialValuesTaxesLabel} />
        </Popover>
        {Object.keys(salaries).length && instructorIdEditing
          ? (
            <Dialog
              modal={false}
              open={dialogOpen}
              onRequestClose={this.handleDialog}
              contentStyle={this.customStyle}
            >
              <InstructorSalaryForm
                initialValues={this.groupSalariesByInstructor(instructorIdEditing)}
                handleUpdate={this.handleUpdate}
                instructorsArray={selectedInstructors}
                massAction={massAction}
                currency={account[0].currency}
              />
            </Dialog>
          ) : null}
      </div>
    );
  }
}

const formConfig = {
  form: 'InstructorSalary',
};

const selector = formValueSelector('InstructorSalary');

const mapDispatchToProps = (dispatch) => ({
  actions: {
    account: bindActionCreators(accountActions, dispatch),
    instructorSalary: bindActionCreators(instructorSalaryActions, dispatch),
    instructors: bindActionCreators(instructorsActions, dispatch),
    notifySuccess: bindActionCreators(notifySuccess, dispatch),
    indicatorActions: bindActionCreators(indicatorActions, dispatch),
  },
});

const mapStateToProps = (state) => ({
  account: _.values(state.entities.account),
  instructors: state.entities.instructor,
  salaries: state.instructorSalary,
  selector: selector(state, 'date', 'dateFrom', 'dateTo'),
  refreshIndicator: state.refreshIndicator,
});

InstructorSalary.propTypes = {
  actions: PropTypes.shape({
    notifySuccess: PropTypes.func,
    indicatorActions: PropTypes.shape({
      startLoading: PropTypes.func,
      stopLoading: PropTypes.func,
    }),
    account: PropTypes.shape({
      fetchMyAccount: PropTypes.func,
    }),
    instructors: PropTypes.shape({
      fetchInstructors: PropTypes.func,
    }),
    instructorSalary: PropTypes.shape({
      fetchInstructorsSalary: PropTypes.func,
      updateInstructorSalary: PropTypes.func,
    }),
  }),
  change: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  instructors: PropTypes.object,
  selector: PropTypes.shape({
    dateFrom: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    dateTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  account: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    currency: PropTypes.string,
    paymentLocation: PropTypes.string,
  })),
  salaries: PropTypes.shape({
    instructors: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }),
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  refreshIndicator: PropTypes.shape({
    salary: PropTypes.shape({
      loaded: PropTypes.bool,
    }),
    salaryTable: PropTypes.shape({
      loaded: PropTypes.bool,
    }),
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(InstructorSalary));
