import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { values } from 'lodash';
import Popover from 'material-ui/Popover';
import FlatButton from 'material-ui/FlatButton';
import '../styles/Table.scss';
import '../styles/AccountList.scss';
import Dialog from '../components/dialog/Dialog';
import SchoolStatistics from '../components/SchoolStatistics';
import AccountInvoices from '../components/AccountInvoices';
import accountListColumns from '../components/accountListColumns';
import {
  fetchAccounts,
  updateAccount,
  fetchAccountStatistics,
  deleteAccount,
} from '../actions/accountActions';
import { startLoading, stopLoading } from '../actions/refreshIndicatorActions';
import { notifySuccess } from '../actions/notificationActions';
import { fetchPlans } from '../actions/subscriptionPlanActions';

class AccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      stats: {},
      pageSizeOptions: [],
      dialogOpen: false,
      accountId: undefined,
      loading: true,
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.startLoading('oneColumn');
    Promise.all([
      actions.fetchAccounts(),
      actions.fetchPlans(),
    ]).then((res) => {
      const { account } = res[0].payload.result;
      const pageSizeOptions = this.pageSizeCounter(account);
      this.setState({ pageSizeOptions, loading: false });
    }).then(() => {
      actions.stopLoading('oneColumn');
    });
  }

  pageSizeCounter = (account) => {
    const defaultPageSizeOptions = [5, 10, 20, 25, 50, 100, 1000];
    const count = Object.keys(account).length;
    let pageSizeOptions = [];
    for (let i = 0; i < defaultPageSizeOptions.length; i += 1) {
      if (count > defaultPageSizeOptions[i]) {
        pageSizeOptions = [...pageSizeOptions, defaultPageSizeOptions[i]];
      } else {
        pageSizeOptions = [...pageSizeOptions, count];
        break;
      }
    }
    return pageSizeOptions;
  }

  onActiveButtonClick = (id) => {
    const { actions, accountList } = this.props;
    actions.updateAccount(id, {
      active: !accountList[id].active,
    });
  }

  updatePaymentType = (e, id) => {
    const { actions } = this.props;
    actions.updateAccount(id, { planPaymentType: e.target.value });
  }

  openStatistics = (event, accountId) => {
    const { actions } = this.props;
    event.persist();
    actions.fetchAccountStatistics(accountId).then((res) => {
      const { statistics } = res.payload.result.account[accountId];
      this.setState({
        anchorEl: event.target,
        stats: statistics,
        open: true,
      });
    });
  }

  closeStatistics = () => {
    this.setState({
      open: false,
      stats: {},
    });
  }

  deleteSchool = (accountId) => {
    const { actions } = this.props;
    actions.deleteAccount(accountId).then(() => {
      actions.notifySuccess({}, 'School deleted successfully');
    });
  }

  setIndividualDiscount = (e, id) => {
    const { actions } = this.props;
    actions.updateAccount(id, {
      discount: this[`discount${id}`].input.value,
    });
  }

  setRef = (input, id) => {
    this[`discount${id}`] = input;
  }

  handleDialog = (accountId) => {
    const { dialogOpen } = this.state;
    this.setState({
      dialogOpen: !dialogOpen,
      accountId,
    });
  }

  valueUpdater = (accountList) => {
    const updatedValues = {};
    Object.keys(accountList).forEach((key) => {
      updatedValues[key] = accountList[key];
      Object.assign(updatedValues[key], { newId: key });
    });
    return updatedValues;
  }

  render() {
    const {
      accountList, resortList, openDialog, history: { push },
    } = this.props;
    const {
      stats, anchorEl, open, pageSizeOptions, dialogOpen, accountId, loading,
    } = this.state;
    const dialogActions = [
      <FlatButton onClick={this.handleDialog} color="primary">
        Close
      </FlatButton>,
    ];

    const updatedValues = this.valueUpdater(accountList);

    return (
      <div>
        <h1 className="account-list-header">Account List</h1>
        <div className="account-list-total">
          Total amount:
          {Object.keys(accountList).length}
        </div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.closeStatistics}
        >
          <SchoolStatistics stats={stats} />
        </Popover>
        <Dialog
          modal={false}
          open={dialogOpen}
          title="Select invoice to download"
          actions={dialogActions}
          autoScrollBodyContent
        >
          <AccountInvoices accountId={accountId} handleDialog={this.handleDialog} />
        </Dialog>
        <ReactTable
          loading={loading}
          data={values(updatedValues)}
          columns={accountListColumns(
            accountList,
            resortList,
            this.onActiveButtonClick,
            this.openStatistics,
            this.setIndividualDiscount,
            this.handleDialog,
            this.deleteSchool,
            this.setRef,
            this.updatePaymentType,
            push,
            openDialog,
          )}
          defaultPageSize={10}
          pageSizeOptions={pageSizeOptions}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resortList: state.entities.resort,
  accountList: state.entities.account,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAccounts,
    fetchAccountStatistics,
    updateAccount,
    deleteAccount,
    notifySuccess,
    fetchPlans,
    startLoading,
    stopLoading,
  }, dispatch),
});

AccountList.propTypes = {
  actions: PropTypes.shape({
    fetchAccounts: PropTypes.func,
    fetchAccountStatistics: PropTypes.func,
    updateAccount: PropTypes.func,
    deleteAccount: PropTypes.func,
    notifySuccess: PropTypes.func,
    fetchPlans: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  accountList: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  resortList: PropTypes.object,
  openDialog: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
