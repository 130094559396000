import getMuiTheme from 'material-ui/styles/getMuiTheme';

const skiTheme = getMuiTheme({
  palette: {
    textColor: '#0d47a1',
    primary1Color: '#1565c0',
    accent3Color: '#424242',
    primary3Color: '#616161',
    primary2Color: '#607d8b',
    shadowColor: 'rgba(255, 255, 255, 0.03)',
    clockCircleColor: '#1565c0',
    pickerHeaderColor: '#1565c0',
    accent1Color: '#1976d2',
    borderColor: '#0d47a1',
    secondaryTextColor: '#546e7a',
  },
  textField: {
    floatingLabelColor: '#607d8b',
    hintColor: '#607d8b',
  },
  datePicker: {
    selectColor: '#1565c0',
    selectTextColor: '#ffffff',
    calendarYearBackgroundColor: '#ffffff',
  },
  toggle: {
    thumbOnColor: '#1976d2',
    trackOnColor: 'rgba(30, 136, 229, 0.5)',
    trackOffColor: 'rgba(30, 136, 229, 0.5)',
    trackDisabledColor: '#1e88e5',
    thumbOffColor: '#1565c0',
  },
  tableHeaderColumn: {
    textColor: '#0d47a1',
  },
  tableRow: {
    hoverColor: '#fafafa',
    stripeColor: '#cfd8dc',
    borderColor: '#78909c',
    selectedColor: 'rgba(207, 216, 220, 0.11)',
  },
  floatingActionButton: {
    buttonSize: 25,
    miniSize: 14,
  },
});

export default skiTheme;
