const initialState = {};

const instructorSalary = (state = initialState, action) => {
  switch (action.type) {
    case 'INSTRUCTORS_SALARY_GET_SUCCESS':
      return action.payload.result.salary;
    case 'INSTRUCTOR_SALARY_PUT_SUCCESS':
      return action.payload.result.salary;
    default:
      return state;
  }
};

export default instructorSalary;
