import React from 'react';
import PropTypes from 'prop-types';
import * as Field from './Field';
import {
  required,
} from '../utils/validators';

const RenderGroupBuyer = ({ fields, disabled, validators }) => (
  <div>
    {fields.map((buyer, index) => (
      <div key={index}>
        <Field.Text
          name={`${buyer}.name`}
          fieldLabel="Name*:"
          disabled={disabled}
          theme="white"
        />
        <Field.Text
          name={`${buyer}.surname`}
          fieldLabel="Surname:"
          disabled={disabled}
          theme="white"
        />
        <Field.Number
          name={`${buyer}.age`}
          fieldLabel="Age:*"
          disabled={disabled}
          theme="white"
          required
          validate={[required]}
          warn={validators}
        />
      </div>
    ))}
  </div>
);

RenderGroupBuyer.propTypes = {
  disabled: PropTypes.bool,
  fields: PropTypes.shape({
    map: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  validators: PropTypes.array,
};

export default RenderGroupBuyer;
