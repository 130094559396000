import moment from 'moment/moment';

export const convertToUTC = (date) => moment.utc(date).subtract(new Date(date).getTimezoneOffset() / 60, 'hour');

export const convertForPrice = (date) => moment.utc(date).subtract((new Date(date).getTimezoneOffset() * 2) / 60, 'hour');

export const convertForCalendar = (date) => moment.utc(date).add(new Date(date).getTimezoneOffset() / 60, 'hour');

export const convertToDate = (date) => new Date(new Date(date).getTime()
  + new Date(date).getTimezoneOffset() * 60000);
