import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import FlatButton from 'material-ui/FlatButton';
import { required } from '../utils/validators';

const AdditionalServiceForm = ({
  currency,
  handleUpdate,
  handleSubmit,
  handleDialog,
  initialValues,
  invalid,
}) => (
  <form
    id="service"
    onSubmit={handleSubmit(handleUpdate)}
  >
    <div onClick={handleDialog}><i className="material-icons sidemenu__close additional-service-close-icon">close</i></div>
    <div className="additional-service__input-wrapper">
      <div className="additional-service__label">Name:*</div>
      <Field
        name="name"
        component={TextField}
        type="text"
        validate={[required]}
      />
    </div>
    <div className="additional-service__input-wrapper-price">
      <div className="additional-service__label">Price:*</div>
      <Field
        name="price"
        component={TextField}
        type="number"
        step={0.01}
        min={0}
        validate={[required]}
      />
      <span>{currency}</span>
    </div>
    <FlatButton
      label={initialValues ? 'UPDATE' : 'CREATE'}
      primary
      type="submit"
      disabled={invalid}
    />
  </form>
);

const formConfig = {
  form: 'AdditionalServicesForm',
  enableReinitialize: true,
};

AdditionalServiceForm.propTypes = {
  currency: PropTypes.string,
  handleUpdate: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleDialog: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  invalid: PropTypes.bool,
};

export default reduxForm(formConfig)(React.memo(AdditionalServiceForm));
