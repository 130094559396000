import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import {
  includes,
  forEach,
  toLower,
  isEqual,
  filter,
  map,
  get,
} from 'lodash';

import ReactTable from 'react-table';
import RefreshIndicator from 'material-ui/RefreshIndicator';

import Dialog from '../components/dialog/Dialog';
import AddEntityButton from '../components/AddEntityButton';
import AdditionalServiceForm from '../components/AdditionalServiceForm';
import MassActionsComponent from '../components/MassActionsComponent';
import Filter from '../components/FilterComponent';
import UploadModal from './UploadModal/UploadModal';

import {
  createService,
  fetchServices,
  updateService,
  deleteService,
  deleteServices,
  importServices,
  servicesExport,
} from '../actions/serviceActions';

import { formatObjectToArray } from '../utils/format';
import additionalServicesListColumns from './TableColumns/AdditionalServicesListColumns';
import { notifyError, notifySuccess } from '../actions/notificationActions';
import { goToStep } from '../actions/userGuide';
import { startLoading, stopLoading } from '../actions/refreshIndicatorActions';
import { downloadCsvFile } from '../utils/csvHelper';
import '../styles/AdditionalServicesList.scss';

const customContentStyle = {
  width: '325px',
};

class AdditionalServicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpenPrice: false,
      dialogOpenService: false,
      serviceIdEditing: null,
      services: [],
      selectedAll: false,
      selected: [],
      searchValues: [],
      currency: get(JSON.parse(localStorage.user), 'account.currency', ''),
      massActions: [
        { text: 'Delete', value: 1 },
      ],
      uploadModalOpen: false,
    };
  }

  componentDidMount() {
    const { actions, userGuide } = this.props;
    const { general, currentGuide, step } = userGuide;

    actions.startLoading('additionalServices');
    Promise.all([
      actions.fetchServices(),
    ]).then(() => {
      actions.stopLoading('additionalServices');
    });

    if (general && currentGuide === 'additionalServices' && step === 0) {
      setTimeout(() => actions.goToStep(1), 250);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { services } = nextProps;
    const { services: stateServices } = this.state;

    if (!isEqual(services, stateServices)) {
      const searchValues = map(services, (s) => ({ value: s, text: s.name }));

      this.setState({
        services: formatObjectToArray(services),
        searchValues,
      });
    }
  }

  handleEditButton = (id) => {
    const { dialogOpenPrice } = this.state;
    this.setState({
      dialogOpenPrice: !dialogOpenPrice,
      serviceIdEditing: id,
    });
  }

  handleDialogPrice = (id) => {
    const { dialogOpenPrice } = this.state;
    this.setState({
      dialogOpenPrice: !dialogOpenPrice,
      serviceIdEditing: id || null,
    });
  };

  handleDialogService = () => {
    const { dialogOpenService } = this.state;
    this.setState({ dialogOpenService: !dialogOpenService });
  }

  handleDelete = (id) => {
    const { actions } = this.props;
    const { selected } = this.state;

    if (id === '*') {
      return actions.deleteServices({ ids: selected }).then((res) => (
        this.handleMultipleDeletionResponse(res)
      ));
    }
    return actions.deleteService(id).then(() => {
      actions.notifySuccess({}, 'Service deleted successfully');
    });
  }

  handleMultipleDeletionResponse = (res) => {
    const { actions } = this.props;
    const discarded = get(res, 'payload.result.discarded', []);

    if (discarded.length) {
      return actions.notifyError({}, 'Some of the services could not be deleted because of the relations to bookings');
    }

    return actions.notifySuccess({}, 'All services deleted successfully');
  };

  handleUpdate = (values) => {
    const { actions } = this.props;
    const { serviceIdEditing } = this.state;

    if (serviceIdEditing) {
      const additionalService = {
        name: values.name,
        price: parseFloat(values.price),
      };
      return actions.updateService(additionalService, serviceIdEditing).then(() => {
        this.handleDialogPrice();
        actions.notifySuccess({}, 'Additional service updated successfully');
      }).catch((err) => {
        this.handleError(err);
      });
    }
    const additionalService = {
      name: values.name,
      price: parseFloat(values.price),
    };
    return actions.createService(additionalService).then(() => {
      this.handleDialogService();
      actions.notifySuccess({}, 'Additional service created successfully');
    }).catch((err) => {
      this.handleError(err);
    });
  }

  handleAddButton = () => {
    this.handleDialogService();
  };

  handleError = (err) => {
    const property = get(err, 'data.constraintViolations[0].property', '');
    const message = get(err, 'data.constraintViolations[0].message', '');

    if (property) {
      throw new SubmissionError({ [property]: message });
    }
  };

  handleCheck = (id) => {
    if (id === '*') {
      this.handleMultiCheck();
    } else {
      this.handleSingleCheck(id);
    }
  };

  handleMultiCheck = () => {
    const {
      services,
      selectedAll,
    } = this.state;
    const selected = [];

    if (!selectedAll) {
      forEach(services, (s) => selected.push(s.id));
    }

    this.setState({
      selected,
      selectedAll: !selectedAll,
    });
  };

  handleSingleCheck = (id) => {
    const { selected, services } = this.state;
    let tempSelected = selected;

    if (includes(selected, id)) {
      tempSelected = filter(selected, (s) => s !== id);
    } else {
      tempSelected.push(id);
    }

    this.setState({
      selected: tempSelected,
      selectedAll: services.length === tempSelected.length,
    });
  };

  handleSearchAutocomplete = (requestList, inputValue) => {
    const { services } = this.props;

    const filtered = filter(services, (s) => includes(toLower(s.name), toLower(inputValue)));

    this.setState({ services: filtered });
  };

  handleUploadModalState = () => {
    const { uploadModalOpen } = this.state;
    this.setState({ uploadModalOpen: !uploadModalOpen });
  }

  handleExportClick = () => {
    const { actions } = this.props;
    actions.servicesExport().then((res) => {
      const { payload: { result: { data } } } = res;
      downloadCsvFile(data, 'additional-services.csv');
    });
  };

  render() {
    const {
      dialogOpenService,
      serviceIdEditing,
      dialogOpenPrice,
      searchValues,
      massActions,
      selectedAll,
      services,
      currency,
      selected,
      uploadModalOpen,
    } = this.state;

    const {
      actions,
      submitting,
      pristine,
      openDialog,
      refreshIndicator,
      services: propServices,
    } = this.props;

    return (
      <div className="react-table additional-services-list">
        <div className="react-table__row-wrapper">
          <AddEntityButton
            label="ADD ADDITIONAL SERVICE"
            onClick={this.handleAddButton}
          />
          <AddEntityButton
            label="UPLOAD"
            className="add-entity-button__theme--green"
            id="upload-anchor"
            onClick={this.handleUploadModalState}
          />
          <AddEntityButton
            label="EXPORT"
            className="add-entity-button__theme--pink"
            onClick={this.handleExportClick}
          />
          <MassActionsComponent
            massActions={massActions}
            detectMassAction={() => {}}
            handleMassAction={() => openDialog(() => this.handleDelete('*'))}
          />
          <Filter
            destination="Services"
            dataSource={searchValues}
            onUpdateInput={this.handleSearchAutocomplete}
          />
        </div>
        <UploadModal
          open={uploadModalOpen}
          handleUploadModalState={this.handleUploadModalState}
          uploadFn={actions.importServices}
          onSuccessCallback={actions.fetchServices}
          templateName="services.csv"
          templateLocation="https://api.skicms.com/templates/s3/skicms-csv/services.csv"
        />
        {refreshIndicator.additionalServices.loaded
          && (
          <ReactTable
            className={!refreshIndicator.additionalServices.cached ? 'indicator-hidden' : ''}
            noDataText="No data found"
            columns={additionalServicesListColumns(
              selectedAll,
              openDialog,
              selected,
              currency,
              this.handleEditButton,
              this.handleDelete,
              this.handleCheck,
            )}
            data={services}
            pageSize={services.length}
            showPagination={false}
          />
          )}
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.additionalServices.cached ? 'hide' : 'loading'}
          className={refreshIndicator.additionalServices.cached ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
        <Dialog
          modal={false}
          contentStyle={customContentStyle}
          open={dialogOpenPrice}
          onRequestClose={this.handleDialogPrice}
        >
          <AdditionalServiceForm
            initialValues={propServices[serviceIdEditing]}
            currency={currency}
            handleUpdate={this.handleUpdate}
            submitting={submitting}
            pristine={pristine}
            handleDialog={this.handleDialogPrice}
          />
        </Dialog>
        <Dialog
          modal={false}
          contentStyle={customContentStyle}
          open={dialogOpenService}
          onRequestClose={this.handleDialogService}
        >
          <AdditionalServiceForm
            initialValues={null}
            currency={currency}
            handleUpdate={this.handleUpdate}
            submitting={submitting}
            pristine={pristine}
            handleDialog={this.handleDialogService}
          />
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  services: state.entities.service,
  userGuide: state.userGuide,
  refreshIndicator: state.refreshIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    createService,
    fetchServices,
    updateService,
    deleteService,
    deleteServices,
    importServices,
    servicesExport,
    notifySuccess,
    notifyError,
    goToStep,
    startLoading,
    stopLoading,
  }, dispatch),
});

AdditionalServicesList.propTypes = {
  actions: PropTypes.shape({
    createService: PropTypes.func,
    fetchServices: PropTypes.func,
    updateService: PropTypes.func,
    deleteService: PropTypes.func,
    deleteServices: PropTypes.func,
    importServices: PropTypes.func,
    servicesExport: PropTypes.func,
    notifySuccess: PropTypes.func,
    notifyError: PropTypes.func,
    goToStep: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
  }),
  userGuide: PropTypes.shape({
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  services: PropTypes.object,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    additionalServices: PropTypes.shape({
      cached: PropTypes.bool,
      loaded: PropTypes.bool,
    }),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServicesList);
