import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import activitySchema from '../schema/Activity';

export const fetchActivities = () => httpRequest({
  method: 'GET',
  path: '/activity',
  types: [
    'ACTIVITIES_GET_REQUEST',
    'ACTIVITIES_GET_SUCCESS',
    'ACTIVITIES_GET_FAILURE',
  ],
  schema: new schema.Array(activitySchema),
});

export const fetchActivity = (id) => httpRequest({
  method: 'GET',
  path: `/activity/${id}`,
  types: [
    'ACTIVITY_GET_REQUEST',
    'ACTIVITY_GET_SUCCESS',
    'ACTIVITY_GET_FAILURE',
  ],
  schema: activitySchema,
});
