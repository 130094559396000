import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import instructorSchema from '../schema/Instructor';
import availabilitySchema from '../schema/Availability';

export const fetchInstructors = () => httpRequest({
  method: 'GET',
  path: '/instructor',
  types: [
    'INSTRUCTORS_GET_REQUEST',
    'INSTRUCTORS_GET_SUCCESS',
    'INSTRUCTORS_GET_FAILURE',
  ],
  schema: new schema.Array(instructorSchema),
});

export const fetchSortedInstructors = () => httpRequest({
  method: 'GET',
  path: '/instructor',
  types: [
    'INSTRUCTORS_GET_REQUEST',
    'INSTRUCTORS_GET_SUCCESS',
    'INSTRUCTORS_GET_FAILURE',
  ],
  schema: null,
});

export const fetchInstructor = (id) => httpRequest({
  method: 'GET',
  path: `/instructor/${id}`,
  types: [
    'INSTRUCTOR_GET_REQUEST',
    'INSTRUCTOR_GET_SUCCESS',
    'INSTRUCTOR_GET_FAILURE',
  ],
  schema: instructorSchema,
});

export const fetchInstructorAvailabilities = (id) => httpRequest({
  method: 'GET',
  path: `/instructor/${id}/availability`,
  types: [
    'INSTRUCTOR_AVAILABILITIES_GET_REQUEST',
    'INSTRUCTOR_AVAILABILITIES_GET_SUCCESS',
    'INSTRUCTOR_AVAILABILITIES_GET_FAILURE',
  ],
  schema: new schema.Array(availabilitySchema),
});

export const createInstructor = (body) => httpRequest({
  method: 'POST',
  path: '/instructor',
  body,
  types: [
    'INSTRUCTOR_POST_REQUEST',
    'INSTRUCTOR_POST_SUCCESS',
    'INSTRUCTOR_POST_FAILURE',
  ],
  schema: instructorSchema,
});

export const deleteInstructor = (id) => httpRequest({
  path: `/instructor/${id}`,
  method: 'DELETE',
  types: [
    'INSTRUCTOR_DELETE_REQUEST',
    'INSTRUCTOR_DELETE_SUCCESS',
    'INSTRUCTOR_DELETE_FAILURE',
  ],
  schema: instructorSchema,
});

export const updateInstructor = (id, body) => httpRequest({
  method: 'PUT',
  path: `/instructor/${id}`,
  body,
  types: [
    'INSTRUCTOR_PUT_REQUEST',
    'INSTRUCTOR_PUT_SUCCESS',
    'INSTRUCTOR_PUT_FAILURE',
  ],
  schema: instructorSchema,
});

export const updateAccount = (id, body) => httpRequest({
  method: 'PUT',
  path: `/instructor/${id}`,
  body,
  types: [
    'INSTRUCTOR_PUT_REQUEST',
    'INSTRUCTOR_PUT_SUCCESS',
    'INSTRUCTOR_PUT_FAILURE',
  ],
  schema: instructorSchema,
});

export const fetchInstructorsByAvailabilityFilter = (body) => httpRequest({
  method: 'POST',
  path: '/availability/instructor',
  body,
  types: [
    'INSTRUCTOR_GET_REQUEST',
    'INSTRUCTOR_GET_SUCCESS',
    'INSTRUCTOR_GET_FAILURE',
  ],
  schema: new schema.Array(instructorSchema),
});

export const fetchFilteredInstructors = (body) => httpRequest({
  method: 'POST',
  path: '/instructor/calendar/filter',
  body,
  types: [
    'INSTRUCTOR_GET_REQUEST',
    'INSTRUCTOR_GET_SUCCESS',
    'INSTRUCTOR_GET_FAILURE',
  ],
  schema: null,
});

export const fetchInstructorsUsedAmount = () => httpRequest({
  method: 'GET',
  path: '/instructor/count',
  types: [
    'USED_AMOUNT_GET_REQUEST',
    'USED_AMOUNT_GET_SUCCESS',
    'USED_AMOUNT_GET_FAILURE',
  ],
  schema: null,
});

export const instructorsImport = (body) => httpRequest({
  method: 'POST',
  body,
  path: '/instructor/import',
  types: [
    'INSTRUCTOR_IMPORT_POST_REQUEST',
    'INSTRUCTOR_IMPORT_POST_SUCCESS',
    'INSTRUCTOR_IMPORT_POST_FAILURE',
  ],
  schema: null,
});

export const instructorsExport = () => httpRequest({
  method: 'GET',
  path: '/instructor/export',
  types: [
    'INSTRUCTOR_EXPORT_GET_REQUEST',
    'INSTRUCTOR_EXPORT_GET_SUCCESS',
    'INSTRUCTOR_EXPORT_GET_FAILURE',
  ],
  schema: null,
});
