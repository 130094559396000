import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from 'redux-form';
import _ from 'lodash';
import moment from 'moment';
import { TextField, Toggle } from 'redux-form-material-ui';
import Dropzone from '../components/dropzone/Dropzone';
import { notifySuccess } from '../actions/notificationActions';
import { fetchSpeciality, fetchSpecialities } from '../actions/specialityActions';
import * as activitiesActions from '../actions/activitiesActions';
import * as productsActions from '../actions/productsActions';
import * as seasonsActions from '../actions/seasonsActions';
import * as accountActions from '../actions/accountActions';
import * as resortsActions from '../actions/resortsActions';
import EntityForm from '../components/EntityForm';
import RenderField from '../components/RenderField';
import RenderPricingTable from './PricingTable/RenderPricingTable';
import RenderSelectAsync from '../components/RenderSelectAsync';
import RenderSelect from '../components/RenderSelect';
import { Time } from '../components/Field';
import { required, notEmpty } from '../utils/validators';
import {
  parseOptionValue,
  parseSeasons,
  parseGroupSizeArray,
  parseProductTiming,
} from '../utils/helpers';
import { convertToDate } from '../utils/dateTime';

import '../styles/ProductAdd.scss';
import { activateGuide, goToStep } from '../actions/userGuide';

class ProductForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      specialities: [],
      activites: [],
      resorts: [],
      levels: [
        { name: 'First Steps', id: 'first_steps' },
        { name: 'Beginner', id: 'beginner' },
        { name: 'Intermediate', id: 'intermediate' },
        { name: 'Advanced', id: 'advanced' },
        { name: 'Any', id: 'any' },
      ],
      ready: false,
      image: '',
      imageName: '',
    };
  }

  componentDidMount() {
    const {
      userGuide: {
        general,
        currentGuide,
        step,
      },
      actions: {
        activateGuideA,
        activities: { fetchActivities },
        accounts: { fetchMyAccount },
        seasons: { fetchSeasonsByProduct },
        products: { fetchProduct },
        resorts: { fetchResortsByProduct },
        fetchSpecialityA,
      },
      match: { params: { id } },
    } = this.props;

    Promise.all([
      fetchActivities(),
      fetchMyAccount(),
      id ? fetchSeasonsByProduct(id) : null,
      id ? fetchProduct(id) : null,
      id ? fetchResortsByProduct(id) : null,
    ]).then((response) => {
      let activities = {};
      let resorts = {};
      let product = {};
      _.forEach(response, (responseEntity) => {
        if (responseEntity) {
          switch (responseEntity.type) {
            case 'ACTIVITIES_GET_SUCCESS':
              activities = responseEntity.payload.result.activity;
              break;
            case 'RESORTS_GET_SUCCESS':
              resorts = responseEntity.payload.result.resort;
              break;
            case 'PRODUCTS_GET_SUCCESS':
              product = responseEntity.payload.result.product[id];
              break;
            default:
              break;
          }
        }
      });
      if (activities && Object.keys(activities).length) {
        this.formatActivities(activities);
      }
      if (resorts && Object.keys(resorts).length) {
        this.formatResorts(resorts);
      }
      if (product && Object.keys(product).length) {
        if (product.speciality) {
          fetchSpecialityA(product.speciality).then((res) => {
            this.formatInitialSpecialities(res.payload.result.speciality[product.speciality]);
          });
        }
      }
      this.setState({
        ready: true,
      });
    });

    if (general && currentGuide === 'products' && step === 3) {
      setTimeout(() => activateGuideA('productForm', true), 1000);
    }
    window.setTimes = (timeFromH, timeFromM, timeToH, timeToM) => {
      this.changeTimes(timeFromH, timeFromM, timeToH, timeToM);
    };
  }

  changeTimes = (timeFromH, timeFromM, timeToH, timeToM) => {
    const { change } = this.props;
    const tF = new Date();
    tF.setHours(timeFromH);
    tF.setMinutes(timeFromM);
    const tT = new Date();
    tT.setHours(timeToH);
    tT.setMinutes(timeToM);
    change('timeFrom', tF);
    change('timeTo', tT);
  }

  formatActivities = (activities) => {
    const activitiesOptions = [];
    _.forEach(activities, (activity) => {
      const option = { id: activity.id, name: activity.name };
      activitiesOptions.push(option);
    });
    this.setState({
      activities: activitiesOptions,
    });
  }

  formatResorts = (resorts) => {
    const { change } = this.props;
    const resortsSet = [];
    _.forEach(resorts, (resort) => {
      const r = { value: resort.id, label: resort.name };
      resortsSet.push(r);
    });
    change('resorts', resortsSet);
  }

  formatSpecialities = ({ value }) => {
    const { speciality, change, actions: { fetchSpecialitiesA } } = this.props;
    change('activity', value);
    const specialitiesOptions = [];
    const filter = `/activity/${value}`;
    if (speciality) {
      change('speciality', null);
      this.setState({
        specialities: [],
      });
    }
    fetchSpecialitiesA(filter).then((response) => {
      _.forEach(response.payload.result.speciality, (s) => {
        const option = { id: s.id, name: s.name };
        specialitiesOptions.push(option);
      });
      if (specialitiesOptions.length) {
        this.setState({ specialities: specialitiesOptions });
      }
    });
  }

  formatInitialSpecialities = (speciality) => {
    const { change, actions: { fetchSpecialitiesA } } = this.props;
    change('speciality', speciality.id);
    change('activity', speciality.activity);
    const id = speciality.activity;
    const filter = `/activity/${id}`;
    const specialitiesOptions = [];
    fetchSpecialitiesA(filter).then((response) => {
      _.forEach(response.payload.result.speciality, (s) => {
        const option = { id: s.id, name: s.name };
        specialitiesOptions.push(option);
      });
      if (specialitiesOptions.length) {
        this.setState({ specialities: specialitiesOptions });
      }
    });
  }

  setSpeciality = (value) => {
    const { change } = this.props;
    change('speciality', value.value);
  }

  setLevel = (value) => {
    const { change } = this.props;
    change('level', value.value);
  }

  getResorts = (input) => {
    if (input.length < 3) {
      return Promise.resolve({ options: [] });
    }
    const { actions: { resorts: { fetchResorts } } } = this.props;

    return fetchResorts(input).then((response) => {
      const resortsResponse = [];
      _.forEach(response.payload.result.resort, (resort) => {
        resortsResponse.push({
          id: resort.id,
          name: resort.name,
        });
      });
      return { options: resortsResponse };
    });
  }

  onSubmit = (values) => {
    const {
      seasons,
      resort,
      userGuide,
      actions: {
        goToStepA,
        notifySuccessA,
        products: { updateProduct, createProduct },
      },
      paidHours,
      timeRange,
      accounts,
      match: { params: { id } },
      history: { push },
    } = this.props;
    const { image } = this.state;
    const level = parseOptionValue(values.level);
    const speciality = parseOptionValue(values.speciality);
    const seasonsParsed = parseSeasons(seasons);
    const groupSizeArray = parseGroupSizeArray(seasons);
    if (groupSizeArray.length !== _.uniq(groupSizeArray).length) {
      throw new SubmissionError({ _error: 'Group size has to be unique' });
    }
    if (groupSizeArray.length === 0) {
      throw new SubmissionError({ _error: 'You need to add pricing for your product' });
    }
    const timeValues = parseProductTiming(values.timeFrom, values.timeTo);
    let fullValues = {
      ...values,
      paidHours: paidHours || 0,
      timeRange: timeRange || false,
      account: accounts[0].id,
      resorts: _.map(resort, (item) => item.value),
      level,
      speciality,
      seasons: seasonsParsed,
      timeFrom: timeValues.timeFrom,
      timeTo: timeValues.timeTo,
    };

    if (!level && !speciality) {
      fullValues = {
        ...fullValues,
        export: false,
      };
    }

    delete fullValues.activity;
    if (id) {
      return updateProduct(id, {
        ...fullValues,
      })
        .then(() => {
          if (image) {
            this.handleImageUpload(id);
          }
          push('/products');
          notifySuccessA({}, 'Product updated successfully');
        }).catch((err) => {
          this.handleError(err);
        });
    }
    return createProduct(fullValues)
      .then((res) => {
        const product = _.get(res, 'payload.result.product', {});
        const productId = Object.keys(product)[0];
        if (productId && image) {
          this.handleImageUpload(productId);
        }
        push('/products');
        notifySuccessA({}, 'Product created successfully');
        if (userGuide && userGuide.currentGuide === 'productForm') {
          goToStepA(9);
        }
      }).catch((err) => {
        this.handleError(err);
      });
  }

  handleToggle = (e, name) => {
    const { change } = this.props;
    const stateObj = () => {
      const returnObj = {
        ...this.state,
      };
      // eslint-disable-next-line react/destructuring-assignment
      returnObj[name] = !this.state[name];
      if (!returnObj[name]) {
        change(name, null);
      }
      return returnObj;
    };
    this.setState(stateObj);
  }

  handleHoursToggle = () => {
    const { change } = this.props;
    change('lessonHours', 0);
    change('timeFrom', moment().month(0).hours(0).minutes(0));
    change('timeTo', moment().month(0).hours(0).minutes(0));
  }

  handlePaidHoursButton = (e) => {
    const { paidHours, change } = this.props;
    let temp = paidHours || 0;
    if (e.target.name === 'increase') {
      temp += 0.5;
      change('paidHours', temp);
    } else if (paidHours > 0) {
      temp -= 0.5;
      change('paidHours', temp);
    }
  }

  handleError = (err) => {
    const property = _.get(err, 'data.constraintViolations[0].property', '');
    const message = _.get(err, 'data.constraintViolations[0].message', '');

    if (property === 'seasons' || property === 'price' || property === 'size') {
      throw new SubmissionError({ _error: message });
    }
    if (property) {
      throw new SubmissionError({ [property]: message });
    }
  };

  handleGroup = () => {
    const { seasons = [] } = this.props;
    const newGroup = [];
    if (seasons.length) {
      _.forEach(seasons[0], (season) => {
        newGroup.push({
          dateFrom: season.dateFrom ? season.dateFrom : '',
          dateTo: season.dateTo ? season.dateTo : '',
          price: '',
          size: '',
        });
      });
    } else {
      newGroup.push({
        size: '',
      });
    }
    return newGroup;
  }

  handleGroupChange = (index) => (e, newSize) => {
    const { change, seasons } = this.props;
    const updatedGroup = [];
    _.forEach(seasons[index], (val) => {
      updatedGroup.push({
        ...val,
        size: newSize,
      });
    });
    seasons[index] = updatedGroup;
    change('seasons', seasons);
  }

  handleAddSeason = (dateFromParam, dateToParam) => {
    const dateFrom = moment(dateFromParam).format('DD/MMM/YYYY');
    const dateTo = moment(dateToParam).format('DD/MMM/YYYY');
    const { seasons = [], change } = this.props;
    if (seasons.length) {
      _.forEach(seasons, (season, index) => {
        seasons[index].push({
          dateFrom,
          dateTo,
          price: '',
          size: season[0].size ? season[0].size : '',
        });
      });
    } else {
      seasons.push([{
        dateFrom,
        dateTo,
        price: '',
        size: '',
      }]);
    }
    change('seasons', seasons);
  }

  handleRemoveSeason = (dateFrom, dateTo) => {
    const { change, seasons } = this.props;
    let modifiedSeasons = _.map(seasons,
      (season) => _.filter(season,
        (ob) => (ob.dateFrom !== dateFrom) || (ob.dateTo !== dateTo)));
    const isEmpty = _.find(modifiedSeasons, (arr) => _.isEmpty(arr));
    if (isEmpty) {
      modifiedSeasons = [];
    }
    change('seasons', modifiedSeasons);
  }

  onImageDrop = (acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      const imgBase64 = reader.result;
      const img = document.getElementById('img');
      img.src = imgBase64;
      this.setState({
        image: imgBase64,
        imageName: acceptedFiles[0].name,
      });
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }

  onImageDelete = () => {
    const { change } = this.props;
    this.setState({
      image: '',
      imageName: '',
      format: '',
    });
    change('image', null);
    const img = document.getElementById('img');
    img.src = '';
  };

  handleImageUpload = (id, deleteFlag) => {
    const { actions: { products: { uploadProductImage } } } = this.props;
    const { image } = this.state;
    if (deleteFlag) {
      return uploadProductImage({
        image: '',
        format: '',
      }, id);
    }
    const header = image.split(',')[0];
    const base64 = image.split(',')[1];
    const format = header.split(':')[1].split(';')[0].split('/')[1];
    return uploadProductImage({
      image: base64,
      format,
    }, id);
  }

  checkIfSeasonsValid = () => {
    const { seasons } = this.props;
    let isNotValid = false;
    if (seasons) {
      seasons.forEach((season) => {
        if (season.length) {
          season.forEach((seasonRow) => {
            if (!seasonRow.price || !seasonRow.size || Number(seasonRow.size) === 0) {
              isNotValid = true;
            }
          });
        }
      });
    }
    return isNotValid;
  };

  handleSelectOpen = (name) => () => {
    const { touch } = this.props;
    touch(name);
  };

  render() {
    const {
      ready,
      activities,
      specialities,
      levels,
      imageName,
      disabled,
    } = this.state;
    const {
      match: { params: { id } },
      initialValues,
      handleSubmit,
      timeRange,
      accounts,
      submitting,
      error,
      invalid,
      pristine,
      image,
    } = this.props;
    if (!ready) {
      return null;
    }
    if (id && !initialValues) {
      return null;
    }

    const isSeasonNotValid = this.checkIfSeasonsValid();

    return (
      <EntityForm
        formDestination="Product"
        handleSubmit={handleSubmit(this.onSubmit)}
        editing={!!id}
        submitting={submitting}
        pristine={pristine}
        invalid={invalid}
        isSeasonNotValid={isSeasonNotValid}
      >
        <Dropzone
          className="dropzone dropzone__edit-product"
          multiple={false}
          accept="image/png"
          maxSize={5242880}
          onDrop={this.onImageDrop}
        >
          <>
            <p>Drop an image or click to select a file to upload.</p>
            <div className="dropzone__edit-product--options">
              <img id="img" src={image} alt="" />
              {(image || image) && <div className="delete-text" onClick={this.onImageDelete}>Delete</div>}
              {imageName && <div className="image-text">{imageName || ''}</div>}
            </div>
          </>
        </Dropzone>
        <small className="dropzone__label">*Only .png format supported (max size - 1 MB)</small>
        <div className="lesson-type-form">
          <form
            id="lessontype-form"
            className="centered-form"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <div className="lesson-type-form__input-wrapper">
              <label>Lesson name:*</label>
              <div className="lesson-type-form__input">
                <Field
                  name="name"
                  component={TextField}
                  type="text"
                  hintText="New Lesson"
                  validate={[required]}
                  underlineStyle={{
                    borderColor: 'rgba(32,51,90,0.4)',
                  }}
                  underlineFocusStyle={{
                    borderColor: 'rgba(32,51,90,0.4)',
                  }}
                  inputStyle={{
                    color: '#253D63',
                    fontSize: '16px',
                    lineHeight: '24px',
                  }}
                />
                <span id="lesson-name-anchor" />
              </div>
            </div>
            <div className="lesson-type-form__time-range-wrapper">
              <label>Hours</label>
              <div className="lesson-type-form__time-range">
                <Field
                  component={Toggle}
                  name="timeRange"
                  label="Time range"
                  labelPosition="right"
                  parse={Boolean}
                  onChange={this.handleHoursToggle}
                />
              </div>
            </div>
            {timeRange ? (
              <div className="lesson-type-form__time-wrapper">
                <label>Lesson time:*</label>
                <div className="lesson-type-form__time">
                  <Time
                    name="timeFrom"
                    hintText="09:00"
                    className="entity-form__time-selector"
                    defaultTime={moment().month(0).hours(0).minutes(0)}
                    validate={[required]}
                  />
                  <span className="lesson-type-form__separator">&ndash;</span>
                  <Time
                    name="timeTo"
                    hintText="11:00"
                    className="entity-form__time-selector"
                    defaultTime={moment().month(0).hours(0).minutes(0)}
                    validate={[required]}
                  />
                </div>
              </div>
            ) : (
              <div className="lesson-type-form__time-wrapper">
                <label>Lesson hours:*</label>
                <div className="lesson-type-form__time">
                  <Field
                    name="lessonHours"
                    min={0}
                    component={TextField}
                    type="number"
                    hintText="0"
                    step={0.5}
                    underlineStyle={{
                      borderColor: 'rgba(32,51,90,0.4)',
                    }}
                    underlineFocusStyle={{
                      borderColor: 'rgba(32,51,90,0.4)',
                    }}
                    inputStyle={{
                      color: '#253D63',
                      fontSize: '16px',
                      lineHeight: '24px',
                      width: '40px',
                      textAlign: 'center',
                    }}
                    className="lesson-type-form__time-to"
                    style={{
                      width: '40px',
                      textAlign: 'center',
                    }}
                    validate={[required]}
                  />
                  <label className="lesson-type-form__time-h-label">h</label>
                </div>
              </div>
            )}
            <div className="lesson-type-form__activity">
              <div className="lesson-type-form__input-wrapper">
                <label>Activity / Speciality:*</label>
                <div className="lesson-type-form__activity-input">
                  <Field
                    name="activity"
                    clearable={false}
                    options={activities}
                    component={RenderSelect}
                    onChange={this.formatSpecialities}
                    disabled={disabled}
                    onOpen={this.handleSelectOpen('activity')}
                    validate={[required]}
                  />
                </div>
                <div className="lesson-type-form__activity-input">
                  <Field
                    name="speciality"
                    clearable={false}
                    options={specialities}
                    component={RenderSelect}
                    onChange={this.setSpeciality}
                    disabled={disabled}
                    onOpen={this.handleSelectOpen('speciality')}
                    validate={[required]}
                  />
                  <span id="level-anchor" />
                </div>
              </div>
            </div>
            <div className="lesson-type-form__level">
              <div className="lesson-type-form__input-wrapper">
                <label>Level:*</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="level"
                    options={levels}
                    clearable={false}
                    component={RenderSelect}
                    onChange={this.setLevel}
                    onOpen={this.handleSelectOpen('level')}
                    validate={[required]}
                  />
                </div>
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Resorts:*</label>
              <div className="lesson-type-form__input">
                <Field
                  multi
                  searchable
                  clearable={false}
                  name="resorts"
                  component={RenderSelectAsync}
                  loadOptions={this.getResorts}
                  autoload
                  validate={[required, notEmpty]}
                  styleError={{ color: 'red', 'font-size': '13px' }}
                />
                <span id="resorts-anchor" />
              </div>
            </div>
            <div className="account-settings__box-wrapper">
              <label>Description:</label>
              <div className="account-settings__box-input">
                <Field
                  type="textarea"
                  component="textarea"
                  name="description"
                  placeholder="Something about the product..."
                  disabled={disabled}
                />
                <span id="description-anchor" />
              </div>
            </div>
          </form>
          <div className="lesson-type-form__season-pricing">
            <FieldArray
              name="seasons"
              component={RenderPricingTable}
              currency={accounts[0].currency}
              handleGroup={this.handleGroup}
              handleSizeChange={this.handleGroupChange}
              handleSeason={this.handleAddSeason}
              handleRemoveSeason={this.handleRemoveSeason}
              groupForm={false}
            />
          </div>
          <div className="table-validation">
            Active price rows in the table must be filled to continue.
          </div>
          <span className="error">
            {' '}
            {error && <span>{error}</span>}
          </span>
          <form id="form" className="options-form">
            <div className="lesson-type-form__options">
              <div className="options__title-wrapper">
                <span
                  className="options__title"
                  onClick={(e) => this.handleToggle(e, 'options')}
                >
                  Options
                </span>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Instructor hours:</label>
                <div className="lesson-type-form__paid-hours-wrapper">
                  <button
                    name="decrease"
                    type="button"
                    onClick={this.handlePaidHoursButton}
                    className="lesson-type-form__paid-hours--decrease"
                  />
                  <div className="lesson-type-form__paid-hours-input">
                    <Field
                      component={RenderField}
                      type="number"
                      min={0}
                      step={0.5}
                      name="paidHours"
                      disabled
                    />
                  </div>
                  <button
                    name="increase"
                    type="button"
                    onClick={this.handlePaidHoursButton}
                    className="lesson-type-form__paid-hours--increase"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </EntityForm>
    );
  }
}

const formConfig = {
  form: 'ProductForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
};

const selector = formValueSelector('ProductForm');

function mapStateToProps(state, props) {
  const seasonsIdx = _.get(state, `entities.product[${props.match.params.id}].seasons`, []);
  const seasons = [];
  _.forEach(seasonsIdx, (id) => {
    let season = _.get(state, `entities.season[${id}]`, {});
    season = {
      ...season,
      dateFrom: moment.utc(season.dateFrom).format('DD/MMM/YYYY'),
      dateTo: moment.utc(season.dateTo).format('DD/MMM/YYYY'),
    };
    seasons.push(season);
  });
  let timeRangeValues = {};
  if (state.entities.product[props.match.params.id]
    && state.entities.product[props.match.params.id].timeFrom
    && state.entities.product[props.match.params.id].timeTo) {
    timeRangeValues = {
      timeFrom: convertToDate(state.entities.product[props.match.params.id].timeFrom),
      timeTo: convertToDate(state.entities.product[props.match.params.id].timeTo),
    };
  }
  const mappedSeasons = _.values(_.groupBy(seasons, 'size'));
  const updatedSeasons = [];
  _.forEach(mappedSeasons, (season) => {
    updatedSeasons.push(season.sort((a, b) => new Date(b.dateFrom) - new Date(a.dateFrom)));
  });
  const initialValues = props.match.params.id ? {
    ...state.entities.product[props.match.params.id],
    seasons: updatedSeasons,
    ...timeRangeValues,
  } : {};
  return {
    initialValues,
    activities: state.entities.activity,
    specialities: state.entities.speciality,
    resorts: state.entities.resorts,
    accounts: _.values(state.entities.account),
    timeRange: selector(state, 'timeRange'),
    seasons: selector(state, 'seasons'),
    paidHours: selector(state, 'paidHours'),
    activity: selector(state, 'activity'),
    speciality: selector(state, 'speciality'),
    level: selector(state, 'level'),
    timeFrom: selector(state, 'timeFrom'),
    timeTo: selector(state, 'timeFrom'),
    resort: selector(state, 'resorts'),
    image: selector(state, 'image'),
    userGuide: state.userGuide,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      activities: bindActionCreators(activitiesActions, dispatch),
      products: bindActionCreators(productsActions, dispatch),
      seasons: bindActionCreators(seasonsActions, dispatch),
      accounts: bindActionCreators(accountActions, dispatch),
      resorts: bindActionCreators(resortsActions, dispatch),
      fetchSpecialitiesA: bindActionCreators(fetchSpecialities, dispatch),
      fetchSpecialityA: bindActionCreators(fetchSpeciality, dispatch),
      notifySuccessA: bindActionCreators(notifySuccess, dispatch),
      activateGuideA: bindActionCreators(activateGuide, dispatch),
      goToStepA: bindActionCreators(goToStep, dispatch),
    },
  };
}

ProductForm.propTypes = {
  userGuide: PropTypes.shape({
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
  }),
  actions: PropTypes.shape({
    activateGuideA: PropTypes.func,
    fetchSpecialityA: PropTypes.func,
    fetchSpecialitiesA: PropTypes.func,
    goToStepA: PropTypes.func,
    notifySuccessA: PropTypes.func,
    activities: PropTypes.shape({
      fetchActivities: PropTypes.func,
    }),
    accounts: PropTypes.shape({
      fetchMyAccount: PropTypes.func,
    }),
    seasons: PropTypes.shape({
      fetchSeasonsByProduct: PropTypes.func,
    }),
    products: PropTypes.shape({
      fetchProduct: PropTypes.func,
      updateProduct: PropTypes.func,
      createProduct: PropTypes.func,
      uploadProductImage: PropTypes.func,
    }),
    resorts: PropTypes.shape({
      fetchResortsByProduct: PropTypes.func,
      fetchResorts: PropTypes.func,
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  change: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  speciality: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  seasons: PropTypes.array,
  resort: PropTypes.number,
  paidHours: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  timeRange: PropTypes.array,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    currency: PropTypes.string,
  })),
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  image: PropTypes.string,
  touch: PropTypes.func,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(ProductForm)));
