export const showDrawer = (component, props, submitFunction) => (dispatch) => {
  dispatch({
    type: 'SHOW_DRAWER',
    open: true,
    component,
    props,
    submitFunction,
  });
};

export const hideDrawer = () => (dispatch) => {
  dispatch({
    type: 'HIDE_DRAWER',
  });
};
