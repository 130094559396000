import * as types from '../constants/ActionTypes';

const products = (state = [], action) => {
  switch (action.type) {
    case types.PRODUCTS_GET_SUCCESS:
      return [...state, action.payload];

    case types.PRODUCT_POST_SUCCESS:
      return [...state, action.payload];

    case types.PRODUCT_GET_SUCCESS: {
      const newState = [...state];

      const productLoaded = action.payload;

      let currentProduct;

      newState.forEach((productInState, i) => {
        if (productInState.id === productLoaded.id) {
          currentProduct = i;
        }
      });

      if (currentProduct) {
        newState[currentProduct] = productLoaded;
      } else {
        newState.push(productLoaded);
      }
      return newState;
    }
    default:
      return state;
  }
};

export default products;
