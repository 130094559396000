import React from 'react';

export const calendar = [
  {
    step: 1,
    selector: '#timeline-header-anchor',
    title:
  <div className="guide-header">
    <span className="name">Main calendar</span>
    <span className="step">Step 1 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    This is the main Calendar. You can change the views by clicking on the days, months or years.
  </div>,
  },
  {
    step: 2,
    selector: '.rct-sidebar-row-odd',
    title:
  <div className="guide-header">
    <span className="name">Active Instructors</span>
    <span className="step">Step 2 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Here you see a list of Active Instructors, who work for you.
    By clicking on the Instructor you can open his/her profile in a separate window.
  </div>,
  },
  {
    step: 3,
    selector: '.event__type-group',
    title:
  <div className="guide-header">
    <span className="name">Elements of the calendar</span>
    <span className="step">Step 3 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    <div>
      The light green block in the Calendar represents Availability.
      The green / red box represents a Booking (paid/unpaid). The blue box represents
      a Group Booking, the colours identify the snowsport experience Level of the Group.
    </div>
    <div className="photo photo--calendar-item" />
  </div>,
    latency: 500,
  },
  {
    step: 4,
    selector: '.event__type-group',
    title:
  <div className="guide-header">
    <span className="name">Actions with groups / bookings</span>
    <span className="step">Step 4 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    A double click on Booking opens the Booking Form. Clicking on the white dot opens the
    Groups participants management. With a second click and hold you can Drag & Drop the Lesson.
  </div>,
  },
  {
    step: 5,
    selector: '#calendar-filter',
    title:
  <div className="guide-header">
    <span className="name">Filter</span>
    <span className="step">Step 5 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    The Calendar filter shows the Booking according to selected criteria.
    Toggle to see Instructors filter shows only those Instructors with the selected criteria.
  </div>,
    position: 'bottom',
    horizontalOffset: 100,
  },
  {
    step: 6,
    selector: '.today-button',
    title:
  <div className="guide-header">
    <span className="name">Today</span>
    <span className="step">Step 6 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Clicking on this icon brings the Calendar to the current day.
  </div>,
    verticalOffset: -15,
  },
  {
    step: 7,
    selector: '#group-0',
    title:
  <div className="guide-header">
    <span className="name">Reserved bookings</span>
    <span className="step">Step 7 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Reserved Bookings area is for Bookings which have not yet been assigned
    to any Instructor. Online Bookings will appear here.
  </div>,
    latency: 500,
    position: 'top',
    verticalOffset: -150,
  },
  {
    step: 8,
    selector: '#atlwdg-trigger',
    title:
  <div className="guide-header">
    <span className="name">Help us get better</span>
    <span className="step">Step 8 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    If you have any Problems or need Technical Support, please contact our Technical Support here.
  </div>,
    position: 'topLeft',
    verticalOffset: -150,
  },
  {
    step: 9,
    selector: '#menu-anchor',
    title:
  <div className="guide-header">
    <span className="name">Menu</span>
    <span className="step">Step 9 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    You can access other pages using the Menu. Click here to open it.
  </div>,
    horizontalOffset: -10,
    lastStep: true,
  },
];

export const schoolSettings = [
  {
    step: 0,
    selector: '#school-settings-anchor',
    title:
  <div className="guide-header">
    <span className="name">School information</span>
  </div>,
    body:
  <div className="guide-text">
    Before getting deep into the Software let’s set up our Snowsport School.
    Click on School Settings here.
  </div>,
  },
  {
    step: 1,
    selector: '#vat-anchor',
    title:
  <div className="guide-header">
    <span className="name">VAT</span>
    <span className="step">Step 1 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Fill in your VAT number if you are going to use it for your Pricing on Additional Services.
  </div>,
    position: 'right',
  },
  {
    step: 2,
    selector: '#administration-fee-anchor',
    title:
  <div className="guide-header">
    <span className="name">Administration fee</span>
    <span className="step">Step 2 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    If you charge an additional Administration Fee on your prices, add it here.
  </div>,
    position: 'right',
  },
  {
    step: 3,
    selector: '#description-anchor',
    title:
  <div className="guide-header">
    <span className="name">Add description of your school</span>
    <span className="step">Step 3 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Add a short Description of your school, it will be visible on offers and in the E-Shop.
  </div>,
    position: 'right',
  },
  {
    step: 4,
    selector: '#address-anchor',
    title:
  <div className="guide-header">
    <span className="name">Fill in your address</span>
    <span className="step">Step 4 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Fill in your Address, it will be visible on Invoices and Salary Sheets.
  </div>,
    position: 'right',
  },
  {
    step: 5,
    selector: '#salary-sheets-anchor',
    title:
  <div className="guide-header">
    <span className="name">Salary sheets text</span>
    <span className="step">Step 5 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Write a message here that will be visible on the Salary Sheet for Instructors.
    Primary text on top, Secondary text under the sheet.
  </div>,
    position: 'right',
  },
  {
    step: 6,
    selector: '#invoicing-anchor',
    title:
  <div className="guide-header">
    <span className="name">Invoicing details</span>
    <span className="step">Step 6 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Fill in the Invoicing Details if your Clients want to pay by bank transfer.
  </div>,
    position: 'right',
  },
  {
    step: 7,
    selector: '#confirmation-text-anchor',
    title:
  <div className="guide-header">
    <span className="name"> Booking confirmation text</span>
    <span className="step">Step 7 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Write a message that will be displayed in a Confirmation Email when a Client Booking is created.
  </div>,
    position: 'right',
    lastStep: true,
  },
];

export const accountInformation = [
  {
    step: 0,
    selector: '#account-information-anchor',
    title:
  <div className="guide-header">
    <span className="name">Account information</span>
  </div>,
    body:
  <div className="guide-text">
    Next set up your Credentials and your Account. Click on Account Information here.
  </div>,
  },
  {
    step: 1,
    selector: '#phone-anchor',
    title:
  <div className="guide-header">
    <span className="name">Add your phone number</span>
    <span className="step">Step 1 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    Add your Phone number on which Clients, Managers and Instructors can contact you.
  </div>,
    position: 'right',
  },
  {
    step: 2,
    selector: '#upgrade-plan-anchor',
    title:
  <div className="guide-header">
    <span className="name">Upgrade your current plan</span>
    <span className="step">Step 2 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    If you decide to Upgrade or when you reach the Plan Limit, simply change the Plan here.
    Choose the Plan that best suits your needs.
  </div>,
    position: 'right',
  },
  {
    step: 3,
    selector: '#change-password-anchor',
    title:
  <div className="guide-header">
    <span className="name">Change your password (if needed)</span>
    <span className="step">Step 3 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    You can change your Password here.
  </div>,
    position: 'right',
    lastStep: true,
  },
];

export const instructors = [
  {
    step: 0,
    selector: '#instructors-anchor',
    title:
  <div className="guide-header">
    <span className="name">Instructors List</span>
  </div>,
    body:
  <div className="guide-text">
    One essential step in using the Software is setting the Instructors who work for you.
    Let’s look at what we can do. Click on Instructors here.
  </div>,
  },
  {
    step: 1,
    selector: '.list-table__column-content--instructor--name',
    title:
  <div className="guide-header">
    <span className="name">Instructors</span>
    <span className="step">Step 1 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Here you can see all of your Instructors with details.
  </div>,
    position: 'top',
  },
  {
    step: 2,
    selector: '#active-anchor',
    title:
  <div className="guide-header">
    <span className="name">Active instructors</span>
    <span className="step">Step 2 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Clicking on this checkmark identifies an Active Instructor. He/She is visible in the Calendar.
    When you activate an Instructor, he/she receives the Access Details about Login to his/her
    Account to his/her email. If you deactivate and then activate an Instructor,
    it will change his / her Password.
  </div>,
    position: 'right',
  },
  {
    step: 3,
    selector: '#headcoach-anchor',
    title:
  <div className="guide-header">
    <span className="name">Head coach</span>
    <span className="step">Step 3 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Clicking this checkmark makes the Instructor a Head Coach.
    An active Head Coach can see the working schedule of all the Instructors.
  </div>,
    position: 'right',
  },
  {
    step: 4,
    selector: '#drag-anchor',
    title:
  <div className="guide-header">
    <span className="name">Instructor reordering</span>
    <span className="step">Step 4 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    You can Drag and Drop Instructors lines to change the Order of Instructors.
    This Order will also be applied in your Calendar.
  </div>,
    position: 'right',
  },
  {
    step: 5,
    selector: '#upload-anchor',
    title:
  <div className="guide-header">
    <span className="name">Add or upload instructors</span>
    <span className="step">Step 5 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    You can add your Instructor by pressing Add Instructor.
    If you have a list of Instructors, you can upload it, by pressing “Upload”
  </div>,
    position: 'bottom',
  },
  {
    step: 6,
    selector: '#edit-anchor',
    title:
  <div className="guide-header">
    <span className="name">Actions</span>
    <span className="step">Step 6 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    You can perform actions with Instructors.
    Edit Profile, Delete Instructor or adjust his/her Availability.
  </div>,
    position: 'left',
  },
  {
    step: 7,
    selector: '#edit-anchor',
    title:
  <div className="guide-header">
    <span className="name">Availability</span>
    <span className="step">Step 7 of 7</span>
  </div>,
    body:
  <div className="guide-text">
    Let’s see what can be done with Availability, click on this icon.
  </div>,
    horizontalOffset: 5,
    lastStep: true,
    extend: true,
    reduceNextStepForSkip: true,
  },
];

export const availabilities = [
  {
    step: 1,
    selector: '#availability-anchor',
    title:
  <div className="guide-header">
    <span className="name">Availabilities</span>
    <span className="step">Step 1 of 4</span>
  </div>,
    body:
  <div className="guide-text">
    Before you start creating Bookings, add the Availability of Instructors.
    Only available Instructors can be booked.
  </div>,
    position: 'topLeft',
  },
  {
    step: 2,
    selector: '.add-entity-button__theme--blue',
    title:
  <div className="guide-header">
    <span className="name">Add availabilities</span>
    <span className="step">Step 2 of 4</span>
  </div>,
    body:
  <div className="guide-text">
    Click here to Add Availability to your Instructor.
  </div>,
    position: 'bottom',
  },
  {
    step: 3,
    selector: '#radio-buttons-anchor',
    title:
  <div className="guide-header">
    <span className="name">Fully / half available</span>
    <span className="step">Step 3 of 4</span>
  </div>,
    body:
  <div className="guide-text">
    Set up Fully Available when an Instructor is working for you.
    A Half Available Instructor can be booked, but is not currently at work
    (e.g. instructor is in resort but has time off).
  </div>,
    latency: 500,
    // horizontalOffset: -30,
  },
  {
    step: 4,
    selector: '.edit',
    title:
  <div className="guide-header">
    <span className="name">Adjust availabilities</span>
    <span className="step">Step 4 of 4</span>
  </div>,
    body:
  <div className="guide-text">
    You can Adjust or Delete Availability for each individual day.
  </div>,
    position: 'topLeft',
    verticalOffset: -120,
    lastStep: true,
    enableCallback: true,
  },
];

export const hotels = [
  {
    step: 0,
    selector: '#hotels-anchor',
    title:
  <div className="guide-header">
    <span className="name">Hotels List</span>
  </div>,
    body:
  <div className="guide-text">
    Adding Hotels will help you know where your Client is staying. Press on Hotels here.
  </div>,
  },
  {
    step: 1,
    selector: '#upload-anchor',
    title:
  <div className="guide-header">
    <span className="name">Add or upload hotels</span>
    <span className="step">Step 1 of 1</span>
  </div>,
    body:
  <div className="guide-text">
    Select Add Hotels to add Hotels and Chalets or Upload
    by clicking on the Upload button if you have a list of Hotels.
  </div>,
    lastStep: true,
    enableCallback: true,
    position: 'bottom',
    horizontalOffset: -31,
  },
];

export const additionalServices = [
  {
    step: 0,
    selector: '#additional-service-anchor',
    title:
  <div className="guide-header">
    <span className="name">Additional Services</span>
  </div>,
    body:
  <div className="guide-text">
    To provide multiple services create them here. Click on Additional Services.
  </div>,
  },
  {
    step: 1,
    selector: '#upload-anchor',
    title:
  <div className="guide-header">
    <span className="name">Add or upload additional service</span>
    <span className="step">Step 1 of 1</span>
  </div>,
    body:
  <div className="guide-text">
    Add new Additional Services for your Bookings by pressing Add Additional Services
    or alternatively click on Upload to upload if you have a list of Additional Services.
    Prices can also be edited individually during the Booking creation.
  </div>,
    lastStep: true,
    enableCallback: true,
    position: 'bottom',
    horizontalOffset: -31,
  },
];

export const products = [
  {
    step: 0,
    selector: '#products-anchor',
    title:
  <div className="guide-header">
    <span className="name">Create Products</span>
  </div>,
    body:
  <div className="guide-text">
    To sell Bookings, Products need to be created. Press on Products to continue.
  </div>,
  },
  {
    step: 1,
    selector: '.list-table__column-content--product--type',
    title:
  <div className="guide-header">
    <span className="name">Products</span>
    <span className="step">Step 1 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    The Product List shows all information related to the Product.
  </div>,
    position: 'topLeft',
    horizontalOffset: 100,
    verticalOffset: -100,
    latency: 1500,
  },
  {
    step: 2,
    selector: '#export-anchor',
    title:
  <div className="guide-header">
    <span className="name">Enable for E-shop</span>
    <span className="step">Step 2 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    Click on this icon to enable the Product to be sold on the E-Shop.
  </div>,
    verticalOffset: -20,
  },
  {
    step: 3,
    selector: '.add-entity-button__theme--blue',
    title:
  <div className="guide-header">
    <span className="name">Add product</span>
    <span className="step">Step 3 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    By clicking on “Add Product” you can create a new Product, which can be sold in your Bookings.
  </div>,
    lastStep: true,
    position: 'bottom',
    extend: true,
    latency: 1500,
  },
];

export const productForm = [
  {
    step: 1,
    selector: '#lesson-name-anchor',
    title:
  <div className="guide-header">
    <span className="name">Add a lesson name</span>
    <span className="step">Step 1 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Choose the Lesson Name.
  </div>,
    position: 'right',
    horizontalOffset: -90,
    verticalOffset: -20,
  },
  {
    step: 2,
    selector: '.lesson-type-form__time-h-label',
    title:
  <div className="guide-header">
    <span className="name">Fixed time / Hourly lessons</span>
    <span className="step">Step 2 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    If you select the Hours type - the Lesson can be provided at any time during the day.
    If you set up a Time Range - the Lesson can start and end only at the defined times.
  </div>,
    position: 'right',
    horizontalOffset: -30,
    verticalOffset: -35,
  },
  {
    step: 3,
    selector: '#level-anchor',
    title:
  <div className="guide-header">
    <span className="name">Activity / Speciality and Level</span>
    <span className="step">Step 3 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    You can add an Activity / Speciality and Level here or you can leave it
    blank - then you can choose the Activity / Speciality and Level for this product in Booking.
  </div>,
    position: 'top',
    verticalOffset: -180,
    horizontalOffset: -28,
  },
  {
    step: 4,
    selector: '#resorts-anchor',
    title:
  <div className="guide-header">
    <span className="name">Resorts</span>
    <span className="step">Step 4 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Choose Resorts where this Product can be provided.
  </div>,
    position: 'right',
    verticalOffset: -30,
    horizontalOffset: -60,
  },
  {
    step: 5,
    selector: '#description-anchor',
    title:
  <div className="guide-header">
    <span className="name">Description</span>
    <span className="step">Step 5 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Write a short Product Description for the E-Shop.
  </div>,
    position: 'right',
    verticalOffset: -50,
    horizontalOffset: -100,
  },
  {
    step: 6,
    selector: '.season-pricing__add-season-label',
    title:
  <div className="guide-header">
    <span className="name">Setup pricing</span>
    <span className="step">Step 6 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Let
    {'\''}
    s set up the Pricing for this Product. First enter the Seasons for this Product.
  </div>,
    position: 'topLeft',
    verticalOffset: -140,
  },
  {
    step: 7,
    selector: '.button--add-group',
    title:
  <div className="guide-header">
    <span className="name">Create size</span>
    <span className="step">Step 7 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Add the number of Participants in the Size column.
  </div>,
    position: 'right',
    verticalOffset: -10,
  },
  {
    step: 8,
    selector: '.season-pricing__price-input',
    title:
  <div className="guide-header">
    <span className="name">Add a price</span>
    <span className="step">Step 8 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Set an appropriate Price for predefined numbers of Participants.
  </div>,
    position: 'right',
    horizontalOffset: -80,
    verticalOffset: -10,
  },
  {
    step: 9,
    selector: '.lesson-type-form__paid-hours--increase',
    title:
  <div className="guide-header">
    <span className="name">Working hours</span>
    <span className="step">Step 9 of 9</span>
  </div>,
    body:
  <div className="guide-text">
    Set up how many Working Hours will be added to the Instructor
    {'\''}
    s Salary once this Product has been provided by him/her.
  </div>,
    position: 'right',
    verticalOffset: -10,
  },
  {
    step: 10,
    selector: '.authored-nav__link-calendar',
    title:
  <div className="guide-header">
    <span className="name">Back to the calendar</span>
  </div>,
    body:
  <div className="guide-text">
    Let’s get back to the Calendar. We’re almost done. Click on the Calendar in the Menu.
  </div>,
    position: 'right',
    horizontalOffset: -70,
    verticalOffset: -5,
    lastStep: true,
    increaseNext: true,
  },
];

export const createGroup = [
  {
    step: 1,
    selector: '.add-entity-button__theme--green',
    title:
  <div className="guide-header">
    <span className="name">Create Group</span>
  </div>,
    body:
  <div className="guide-text">
    We’ve created a Product - which we use for individual Lessons.
    Now let’s create a Group, for which we can create Group Bookings.
    Start by clicking on Add Group here.
  </div>,
    position: 'bottom',
  },
  {
    step: 2,
    selector: '#date-time-anchor',
    title:
  <div className="guide-header">
    <span className="name">Setup Date/Time</span>
    <span className="step">Step 1 of 6</span>
  </div>,
    body:
  <div className="guide-text">
    Set up the Dates and Times of the new Group.
  </div>,
    position: 'left',
    horizontalOffset: 50,
    verticalOffset: -10,
  },
  {
    step: 3,
    selector: '#repeat-anchor',
    title:
  <div className="guide-header">
    <span className="name">Repeat</span>
    <span className="step">Step 2 of 6</span>
  </div>,
    body:
  <div className="guide-text">
    Choose if the Group should repeat within the chosen Dates every week,
    every two weeks or the Group is one-off and shall not be repeated.
  </div>,
    position: 'left',
    horizontalOffset: 50,
  },
  {
    step: 4,
    selector: '#other-anchor',
    title:
  <div className="guide-header">
    <span className="name">Fill it in</span>
    <span className="step">Step 3 of 6</span>
  </div>,
    body:
  <div className="guide-text">
    Choose all the other Group parameters.
  </div>,
    position: 'left',
    horizontalOffset: 50,
    verticalOffset: -100,
  },
  {
    step: 5,
    selector: '#amounts-anchor',
    title:
  <div className="guide-header">
    <span className="name">Enter amounts</span>
    <span className="step">Step 4 of 6</span>
  </div>,
    body:
  <div className="guide-text">
    Number of customers is the maximum number of Participants in this Group.
  </div>,
    position: 'left',
    horizontalOffset: 50,
  },
  {
    step: 6,
    selector: '#hours-anchor',
    title:
  <div className="guide-header">
    <span className="name">Instructor hours</span>
    <span className="step">Step 5 of 6</span>
  </div>,
    body:
  <div className="guide-text">
    Set up how many Instructor Hours will be added to the Instructor
    {'\''}
    s Salary once this Group has been provided by him/her.
  </div>,
    position: 'left',
    horizontalOffset: 50,
  },
  {
    step: 7,
    selector: '#price-anchor',
    title:
  <div className="guide-header">
    <span className="name">Setup Days / Price</span>
    <span className="step">Step 6 of 6</span>
  </div>,
    body:
  <div className="guide-text">
    Set up the Days and appropriate Prices to calculate the Final Price
    depending on the Number of Days booked by the Client.
  </div>,
    position: 'left',
    horizontalOffset: 35,
    verticalOffset: 65,
    increaseNext: true,
    lastStep: true,
  },
];

export const createBooking = [
  {
    step: 1,
    selector: '.add-entity-button__theme--blue',
    title:
  <div className="guide-header">
    <span className="name">Create Booking</span>
  </div>,
    body:
  <div className="guide-text">
    We
    {'\''}
    ve successfully gone through the Software. Now it’s time to create a Booking.
    Using it you can sell Products and Groups. Click on “Add Booking” at the top.
  </div>,
    position: 'bottom',
  },
  {
    step: 2,
    selector: '#client-anchor',
    title:
  <div className="guide-header">
    <span className="name">Booking form</span>
    <span className="step">Step 1 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    Here’s the Booking Form, it contains all information about a Booking and its Lessons.
  </div>,
    position: 'left',
    verticalOffset: -10,
    horizontalOffset: -105,
  },
  {
    step: 3,
    selector: '.floating-button-small',
    title:
  <div className="guide-header">
    <span className="name">Add a client</span>
    <span className="step">Step 2 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    For a Booking we need a Client. Choose the Client if they already exist. If we don
    {'\''}
    t have any Clients yet then Click on “Add New Client” and fill in the Form to create a Client.
  </div>,
    position: 'left',
    verticalOffset: -15,
    horizontalOffset: -10,
  },
  {
    step: 4,
    selector: '#add-hotel-anchor',
    title:
  <div className="guide-header">
    <span className="name">Add a hotel</span>
    <span className="step">Step 3 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    Select the Hotel that this Client is staying at.
  </div>,
    position: 'left',
    verticalOffset: -10,
    horizontalOffset: -35,
  },
  {
    step: 5,
    selector: '.floating-button',
    title:
  <div className="guide-header">
    <span className="name">Client from partner</span>
    <span className="step">Step 4 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    If this Client is from a Partner, Click on Client from Partner.
  </div>,
    position: 'left',
    verticalOffset: -10,
  },
  {
    step: 6,
    selector: '#new-partner-manager-anchor',
    title:
  <div className="guide-header">
    <span className="name">Add new manager</span>
    <span className="step">Step 5 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    In this field you can select a Manager from Partner.
    If we don’t have any yet, press “Add New Partner Manager”.
  </div>,
    position: 'left',
    verticalOffset: -15,
    horizontalOffset: -15,
  },
  {
    step: 7,
    selector: '#new-partner-anchor',
    title:
  <div className="guide-header">
    <span className="name">Create a partner</span>
    <span className="step">Step 6 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    Fill in the information about the Partner Manager.
    If the Manager is from an existing Partner, select the Partner.
    To add a new Partner fill in the Partner Company field and select the Create New option below.
  </div>,
    position: 'left',
    verticalOffset: -25,
    horizontalOffset: 35,
  },
  {
    step: 8,
    selector: '#invoicing-anchor',
    title:
  <div className="guide-header">
    <span className="name">Invoicing client</span>
    <span className="step">Step 7 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    If you need to send an invoice to Partner with the Client details, check this box here.
  </div>,
    position: 'left',
    verticalOffset: -10,
    horizontalOffset: 5,
  },
  {
    step: 9,
    selector: '#menu-anchor',
    title:
  <div className="guide-header">
    <span className="name">See clients / managers / partners</span>
    <span className="step">Step 8 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    <div>
      You’ve created a Client, Manager and Partner. Now you can find them in the respective Menu.
    </div>
    <div className="photo photo--booking-form" />
  </div>,
    horizontalOffset: -5,
  },
  {
    step: 10,
    selector: '#lesson-anchor',
    title:
  <div className="guide-header">
    <span className="name">Lesson</span>
    <span className="step">Step 9 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    This is the Lesson block, where you create Lessons for your Booking.
    The Lesson describes the type of Activity the Client wants to participate in, Date,
    Time, which Instructor will teach him/her and where they will meet.
    The Instructor can only be assigned to a Group or Individual Lesson if his Activity / Speciality
    and Level matches the Product Activity / Speciality and Level.
  </div>,
    position: 'left',
    horizontalOffset: 30,
  },
  {
    step: 11,
    selector: '.booking-section__add-lesson',
    title:
  <div className="guide-header">
    <span className="name">Duplicate a lesson</span>
    <span className="step">Step 10 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    To duplicate a Lesson click on the “+” sign.
  </div>,
    position: 'left',
  },
  {
    step: 12,
    selector: '#remove-1-anchor',
    title:
  <div className="guide-header">
    <span className="name">Delete a lesson</span>
    <span className="step">Step 11 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    To delete a Lesson click on the Delete icon.
  </div>,
    position: 'left',
  },
  {
    step: 13,
    selector: '#discount-anchor-1',
    title:
  <div className="guide-header">
    <span className="name">Add a discount</span>
    <span className="step">Step 12 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    You can provide a Discount by adding a number in this field.
    If you add a % sign at the end, the Discount will be calculated as a Percentage.
    If you simply add a number the Discount will apply as a Fixed amount.
  </div>,
    position: 'left',
    horizontalOffset: 40,
    latency: 300,
  },
  {
    step: 14,
    selector: '#paid-0-anchor',
    title:
  <div className="guide-header">
    <span className="name">Paid / Not paid</span>
    <span className="step">Step 13 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    You can toggle if the Booking is Paid or Unpaid, depending on your needs.
    If a Booking is Paid by Credit Card through our
    Software Payment Provider this toggle updates automatically.
  </div>,
    position: 'left',
    verticalOffset: -25,
    horizontalOffset: 40,
  },
  {
    step: 15,
    selector: '#special-needs-anchor',
    title:
  <div className="guide-header">
    <span className="name">Special needs</span>
    <span className="step">Step 14 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    If the Client has any Special Needs write them here.
  </div>,
    position: 'left',
    verticalOffset: -10,
    horizontalOffset: 15,
  },
  {
    step: 16,
    selector: '#additional-service-add-anchor',
    title:
  <div className="guide-header">
    <span className="name">Additional services</span>
    <span className="step">Step 15 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    If the Client needs some Additional Services add them here.
  </div>,
    position: 'left',
    verticalOffset: -15,
    horizontalOffset: 20,
  },
  {
    step: 17,
    selector: '#additional-service-0-anchor',
    title:
  <div className="guide-header">
    <span className="name">Action with additional services</span>
    <span className="step">Step 16 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    You can Add more, Delete it, toggle if it includes VAT,
    add a Discount and toggle if it’s paid or not.
  </div>,
    position: 'left',
    horizontalOffset: 10,
  },
  {
    step: 18,
    selector: '#comment-anchor',
    title:
  <div className="guide-header">
    <span className="name">Comment</span>
    <span className="step">Step 17 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    You can add a Comment if you have additional information about the Client.
  </div>,
    position: 'left',
    verticalOffset: -10,
    horizontalOffset: 15,
  },
  {
    step: 19,
    selector: '.booking-form-additional-otherinfo-amount-label',
    title:
  <div className="guide-header">
    <span className="name">Prepayment</span>
    <span className="step">Step 18 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    If the Client has prepaid a certain amount, write it here and choose the Payment Method.
  </div>,
    position: 'left',
    verticalOffset: -15,
  },
  {
    step: 20,
    selector: '.BookingForm__footer-checkbox',
    title:
  <div className="guide-header">
    <span className="name">Send invoice</span>
    <span className="step">Step 19 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    Toggle this to Notify Client or Manager about the Booking and Payment.
    The Client is notified if he/she’s assigned.
    When a Manager is also assigned, only the Manager is notified.
  </div>,
    position: 'topLeft',
    verticalOffset: -152,
    horizontalOffset: 12,
  },
  {
    step: 21,
    selector: '#book-anchor',
    title:
  <div className="guide-header">
    <span className="name">Book</span>
    <span className="step">Step 20 of 20</span>
  </div>,
    body:
  <div className="guide-text">
    Click on “Book” and we have created our first Booking!
  </div>,
    position: 'topLeft',
    verticalOffset: -125,
    horizontalOffset: 23,
    lastStep: true,
  },
];

export const partners = [
  {
    step: 1,
    selector: '.list-table__column-content--Partner--address',
    title:
  <div className="guide-header">
    <span className="name">Partner List</span>
    <span className="step">Step 1 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    This page shows all your Partners.
  </div>,
    position: 'topLeft',
    verticalOffset: -100,
    horizontalOffset: 80,
  },
  {
    step: 2,
    selector: '.add-entity-button__theme--blue',
    title:
  <div className="guide-header">
    <span className="name">Add or upload partner</span>
    <span className="step">Step 2 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    Press Add Partner to create a new Partner or upload Partners from a list.
  </div>,
    position: 'bottom',
    horizontalOffset: 115,
  },
  {
    step: 3,
    selector: '.partner-form__header',
    title:
  <div className="guide-header">
    <span className="name">Create partner</span>
    <span className="step">Step 3 of 3</span>
  </div>,
    body:
  <div className="guide-text">
    Fill in this form to create a new Partner and set up the Commission rate.
    This commission will be calculated for all Managers assigned to this Partner.
    The Partner address will appear on the Invoice if the Booking is through a Partner.
  </div>,
    position: 'right',
    lastStep: true,
    enableCallback: true,
    suspendCallbackOnLast: true,
    verticalOffset: 250,
  },
];

export const managers = [
  {
    step: 1,
    selector: '.add-entity-button__theme--blue',
    title:
  <div className="guide-header">
    <span className="name">Add or upload managers</span>
    <span className="step">Step 1 of 2</span>
  </div>,
    body:
  <div className="guide-text">
    Add new Manager by pressing Add Manager or upload Managers by pressing Upload.
  </div>,
    position: 'bottom',
    horizontalOffset: 125,
  },
  {
    step: 2,
    selector: '.edit-button',
    title:
  <div className="guide-header">
    <span className="name">Managers profile</span>
    <span className="step">Step 2 of 2</span>
  </div>,
    body:
  <div className="guide-text">
    Here you can edit Manager Details.
    In the table below you can see all Bookings and Turnovers created by this Manager.
  </div>,
    position: 'bottomLeft',
    horizontalOffset: 40,
    enableCallback: true,
    suspendCallbackOnLast: true,
    latency: 2000,
    lastStep: true,
  },
];

export const clients = [
  {
    step: 1,
    selector: '.list-table__column-content--client--email',
    title:
  <div className="guide-header">
    <span className="name">Client List</span>
    <span className="step">Step 1 of 2</span>
  </div>,
    body:
  <div className="guide-text">
    In the Clients table you can see all main details and the last Bookings for this Client.
  </div>,
    position: 'topLeft',
    verticalOffset: -100,
    horizontalOffset: 80,
  },
  {
    step: 2,
    selector: '.add-entity-button__theme--blue',
    title:
  <div className="guide-header">
    <span className="name">Add or upload client</span>
    <span className="step">Step 2 of 2</span>
  </div>,
    body:
  <div className="guide-text">
    Add a new Client here or if you have a list of Clients click on Upload.
  </div>,
    position: 'bottom',
    horizontalOffset: 100,
    lastStep: true,
  },
];

export const createOffer = [
  {
    step: 0,
    selector: '.add-entity-button__theme--red',
    title:
  <div className="guide-header">
    <span className="name">Create an offer</span>
  </div>,
    body:
  <div className="guide-text">
    This button enables you to create offers for the online shop,
    which comes with every SSMS account.
    At the moment, this can only be used to sell products. Press “Create offers”.
  </div>,
    position: 'bottom',
  },
  {
    step: 1,
    selector: '.export-wizard-header',
    title:
  <div className="guide-header">
    <span className="name">Filter</span>
    <span className="step">Step 1 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    <div>
      <div>
        Here you can filter the products that you have enabled in the product lists E-shop column.
        It is only possible to enable products that have a fixed time (example 10:00 - 12:00)
      </div>
      <div>BEFORE CONTINUING PLEASE MAKE SURE:</div>
      <ul style={{ marginTop: 0 }}>
        <li>INSTRUCTORS SHOULD HAVE AVAILABILITIES IN RECENT DATES</li>
        <li>PRODUCTS FOR E-SHOP SHOULD BE ENABLED</li>
        <li>ENABLED PRODUCTS SHOULD HAVE A SEASON AND PRICE ASSIGNED IN RECENT DATES</li>
        <li>INSTRUCTORS AND PRODUCTS ACTIVITY, SPECIALITY AND LEVEL MATCH EACH OTHER</li>
        <li>FILTER PRODUCT IN THE DATES THAT YOU ADDED</li>
      </ul>
    </div>
    <div className="photo photo--create-offer" />
  </div>,
    position: 'right',
    latency: 500,
    horizontalOffset: -230,
    verticalOffset: -20,
  },
  {
    step: 2,
    selector: '#dateFrom',
    title:
  <div className="guide-header">
    <span className="name">Filter dates</span>
    <span className="step">Step 2 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    Choose the dates that you are planning to offer for people to book.
  </div>,
    position: 'right',
    horizontalOffset: -30,
  },
  {
    step: 3,
    selector: '#activity',
    title:
  <div className="guide-header">
    <span className="name">Add more criteria</span>
    <span className="step">Step 3 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    You can also add additional criteria.
  </div>,
    position: 'right',
    horizontalOffset: -20,
  },
  {
    step: 4,
    selector: '#button-next',
    title:
  <div className="guide-header">
    <span className="name">Going to the next step</span>
    <span className="step">Step 4 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    When you are done press next.
  </div>,
    verticalOffset: -15,
    // position: 'topLeft',
    // verticalOffset: -120,
    // horizontalOffset: 150,
  },
  {
    step: 5,
    selector: '.export-wizard-header',
    title:
  <div className="guide-header">
    <span className="name">Products to be sold</span>
    <span className="step">Step 5 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    Here you can see the filtered results.
    These products are available to be sold in your chosen dates
  </div>,
    position: 'right',
    latency: 500,
    horizontalOffset: -230,
    verticalOffset: 50,
  },
  {
    step: 6,
    selector: '#li-0 > div > div > div > div:nth-child(3)',
    title:
  <div className="guide-header">
    <span className="name">Select / Deselect</span>
    <span className="step">Step 6 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    If you don’t want to sell a product in the chosen date deselect it.
  </div>,
    position: 'right',
    verticalOffset: -12,
  },
  {
    step: 7,
    selector: '#button-next',
    title:
  <div className="guide-header">
    <span className="name">Going to the next step</span>
    <span className="step">Step 7 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    When you’re done press next.
  </div>,
    verticalOffset: -15,
  },
  {
    step: 8,
    selector: '.rdl-control',
    title:
  <div className="guide-header">
    <span className="name">Available lessons</span>
    <span className="step">Step 8 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    This box shows the list available lesson in the selected date
  </div>,
    position: 'right',
    latency: 3000,
    horizontalOffset: -50,
  },
  {
    step: 9,
    selector: '.rdl-control',
    title:
  <div className="guide-header">
    <span className="name">Selected lessons</span>
    <span className="step">Step 9 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    This box shows the list of the lessons that will be on e-shop.
  </div>,
    position: 'left',
    horizontalOffset: 410,
  },
  {
    step: 10,
    selector: '.rdl-filter',
    title:
  <div className="guide-header">
    <span className="name">Search</span>
    <span className="step">Step 10 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    You can search for a specific date using these fields.
  </div>,
    position: 'bottom',
    horizontalOffset: 100,
    verticalOffset: -10,
  },
  {
    step: 11,
    selector: '.rdl-move.rdl-move-all.rdl-move-right',
    title:
  <div className="guide-header">
    <span className="name">Add all lessons</span>
    <span className="step">Step 11 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    Press on
    {' >> '}
    icon to add all available lessons.
  </div>,
    position: 'right',
    horizontalOffset: -10,
    verticalOffset: -10,
  },
  {
    step: 12,
    selector: '.rdl-move.rdl-move-right',
    title:
  <div className="guide-header">
    <span className="name">Add 1 or multiple lessons</span>
    <span className="step">Step 12 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    Press on
    {' > '}
    icon to add 1 available lesson. Select multiple lessons while pressing “Shift”.
  </div>,
    position: 'right',
    horizontalOffset: -10,
    verticalOffset: 20,
  },
  {
    step: 13,
    selector: '.get-link-button',
    title:
  <div className="guide-header">
    <span className="name">Get link</span>
    <span className="step">Step 13 of 13</span>
  </div>,
    body:
  <div className="guide-text">
    You can send this link to your clients or share it
    and they can order lessons in the e-shop using it.
  </div>,
    position: 'topLeft',
    lastStep: true,
    verticalOffset: -140,
    horizontalOffset: 60,
    extend: true,
  },
];
