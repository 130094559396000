import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MaterialDialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

const messageSelect = (calendarItem) => (
  <div>
    <h3>Original</h3>
    <ul>
      Name:
      {calendarItem.original.name}
    </ul>
    <ul>
      Instructor:
      {calendarItem.original.instructor}
    </ul>
    <ul>
      Time:
      {calendarItem.original.timeFrom}
      {' '}
      -
      {calendarItem.original.timeTo}
    </ul>
    <h3>Changed</h3>
    <ul>
      Name:
      {calendarItem.changed.name}
    </ul>
    <ul>
      Instructor:
      {calendarItem.changed.instructor}
    </ul>
    <ul>
      Time:
      {calendarItem.changed.timeFrom}
      {' '}
      -
      {calendarItem.changed.timeTo}
    </ul>
  </div>
);

const Dialog = ({
  open,
  calendarItem,
  onCancel,
  onOk,
  message,
}) => {
  const selectedMessage = useMemo(() => {
    if (calendarItem) {
      return messageSelect(calendarItem);
    }
    return message;
  }, [calendarItem, message]);
  return (
    <MaterialDialog
      modal={false}
      open={open}
      actions={[
        <FlatButton
          label={calendarItem ? 'Cancel' : 'No'}
          primary
          onClick={onCancel}
        />,
        <FlatButton
          label={calendarItem ? 'Confirm' : 'Yes'}
          primary
          keyboardFocused
          onClick={onOk}
        />,
      ]}
      onRequestClose={onCancel}
    >
      {selectedMessage}
    </MaterialDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  calendarItem: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  message: PropTypes.string,
};

export default React.memo(Dialog);
