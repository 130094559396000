import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { map, isEqual, find } from 'lodash';
import Select from 'react-select';
import { connect } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';
import RenderField from './RenderField';
import RemoveLessonButton from './RemoveLessonButton';
import { formatActivityOptions } from '../utils/format';
import { mapSpecToActivity } from '../utils/map';

const levels = ['First steps', 'Beginner', 'Intermediate', 'Advanced'];

const ActivityTable = ({
  activity,
  entities,
  activityList,
  activities,
  onActivityDelete,
  formValues,
  changeFieldValue,
  setActivity,
}) => {
  const activitySpecs = useMemo(() => {
    if (entities.specsToActivity && activity) {
      return entities.specsToActivity[activity];
    }
    return [];
  }, [entities, activity]);
  const options = useMemo(() => formatActivityOptions(activityList, activities, activity),
    [activityList, activities, activity]);

  const onSelect = ({ value }) => {
    setActivity(value, activity);
  };

  return (
    <div key={Math.random()}>
      <Select
        key={`${activity}-selecet`}
        className="w377 inline-block"
        name={`activities[${activity}][activity]`}
        options={options}
        clearable={false}
        onChange={onSelect}
        placeholder="Activity"
        value={find(options, (option) => option.value === activity)}
      />
      <RemoveLessonButton
        onClick={onActivityDelete}
        className="inline-block"
      />
      <br />
      {activitySpecs && activitySpecs.length
        ? (
          <table className="entity-form-table list-table__column wider">
            <thead>
              <tr>
                <th />
                {map(activitySpecs, ((spec, index) => (
                  <th className="entity-form-table__column" key={`th${index}`}>{spec}</th>
                )
                ))}
              </tr>
            </thead>
            <tbody>
              {map(levels, (level, rowIndex) => (
                <tr key={rowIndex} className={rowIndex}>
                  <td className="td100">{level}</td>
                  {map(activitySpecs, ((spec, columnIndex) => (
                    <td key={`th${columnIndex}`} className={columnIndex}>
                      <Field
                        className="entity-form__weekday-checkbox-field"
                        component={RenderField}
                        type="radio"
                        value={level || false}
                        name={`activities[${activity}][${spec}]`}
                        radio
                        columnIndex={`${columnIndex}-${activity}`}
                        rowIndex={rowIndex}
                        onClick={() => {
                          if (formValues.activities[activity][spec]) {
                            changeFieldValue('InstructorPage', `activities[${activity}][${spec}]`, '');
                          }
                        }}
                      />
                    </td>
                  )))}
                </tr>
              ))}
            </tbody>
          </table>
        )
        : <div />}
    </div>
  );
};

ActivityTable.propTypes = {
  activity: PropTypes.string,
  entities: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    specsToActivity: PropTypes.object,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  activityList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  activities: PropTypes.object,
  onActivityDelete: PropTypes.func,
  formValues: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    activities: PropTypes.object,
  }),
  changeFieldValue: PropTypes.func,
  setActivity: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  if (Object.keys(state.entities.speciality).length && props.activityList) {
    // eslint-disable-next-line no-param-reassign
    state.entities.specsToActivity = mapSpecToActivity(
      state.entities.speciality,
      props.activityList,
    );
  }
  return {
    ...state,
    formValues: getFormValues('InstructorPage')(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: bindActionCreators(change, dispatch),
});

const areEqual = (prevProps, nextProps) => prevProps.activity === nextProps.activity
  && isEqual(prevProps.entities.specsToActivity, nextProps.entities.specsToActivity)
  && isEqual(prevProps.activities, nextProps.activities)
  && isEqual(prevProps.activityList, nextProps.activityList)
  && isEqual(prevProps.formValues, nextProps.formValues);

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ActivityTable, areEqual));
