import moment from 'moment';
import {
  get, forEach, includes, filter, map, find,
} from 'lodash';
import { LANGUAGES } from '../common/constants';
import { convertToDate } from './dateTime';

export const formatCurrency = (value, currency) => (parseFloat(value) || 0).toLocaleString('en-US', {
  style: 'currency',
  currency,
});

export const formatDate = (date) => {
  if (!date) { return null; }
  return moment(date).format('DD/MMM/YYYY');
};

const formatFilterResorts = (resorts) => {
  const resortsReturn = [];
  forEach(resorts, (r) => {
    resortsReturn.push(r.id);
  });
  return resortsReturn;
};

export const formatObjectToArray = (object) => (
  map(object, (value) => value)
);

export const formatFilterPayload = (f) => {
  const timeFrom = f.timeFrom ? moment.utc(f.timeFrom.toDateString()).format() : null;
  const timeTo = f.timeTo ? moment.utc(f.timeTo.toDateString()).format() : null;
  const type = 'individual';
  const group = null;
  const instructors = [];
  const language = get(f, 'language.value', null);
  const level = get(f, 'level.value', null);
  const speciality = get(f, 'speciality.value', null);
  const resorts = formatFilterResorts(get(f, 'resort', []));
  const products = [];
  forEach(f.products, (p) => {
    if (p.checked) {
      products.push(p.value);
    }
  });

  return {
    timeFrom,
    timeTo,
    type,
    group,
    instructors,
    language,
    level,
    speciality,
    products,
    resorts,
  };
};

export const formatGeneratedTimesOptions = (options, products) => {
  const optionsReturn = [];
  forEach(options, (option, key) => {
    const tempOptions = [];
    const label = products[key].name;
    forEach(option, (o, index) => {
      const timeFrom = moment.utc(o.timeFrom).format('DD/MMM/YYYY HH:mm');
      const timeTo = moment.utc(o.timeTo).format('DD/MMM/YYYY HH:mm');
      tempOptions.push({
        value: `${key}[${index}]`,
        label: `${timeFrom} - ${timeTo}`,
      });
    });
    optionsReturn.push({
      label,
      options: tempOptions,
    });
  });
  return optionsReturn;
};

export const formatSelectedTimesPayload = (options, selected) => {
  const formattedPayload = [];
  forEach(options, (option, keyLesson) => {
    forEach(option, (time, index) => {
      const optionPath = `${keyLesson}[${index}]`;
      if (!includes(selected, optionPath)) {
        formattedPayload.push(time);
      }
    });
  });
  return formattedPayload;
};

export const formatMessageFromDiscardedAvailabilities = (availabilities) => {
  let msg = 'Availabilities updated successfully, except those who have upcomming events:\n';
  forEach(availabilities, (a) => {
    const timeFrom = moment(convertToDate(a.timeFrom)).format('DD/MMM/YYYY HH:mm');
    const timeTo = moment(convertToDate(a.timeTo)).format('DD/MMM/YYYY HH:mm');
    msg = msg.concat(timeFrom, ' - ', timeTo, '\n');
  });
  return msg;
};

export const formatActivityOptions = (available, taken, selected) => {
  const current = find(available, (option) => option.value === selected);
  const takenNames = map(taken, (option, key) => key);
  const filtered = filter(available, (option) => !includes(takenNames, option.value) && option.value !== 'Any');
  if (current) {
    filtered.push(current);
  }
  return filtered;
};

export const formatClientPhoneNumbers = (formValues, clientList) => {
  const phoneNumbers = get(clientList, `[${formValues.client}].phoneNumbers`, undefined);
  let phoneNumbersReturn = '';

  if (phoneNumbers) {
    forEach(phoneNumbers, (p) => {
      phoneNumbersReturn = phoneNumbersReturn.concat(p.phoneNumber, ' ');
    });
  }
  return phoneNumbersReturn;
};

export const formatGroupListData = (groups) => {
  const mappedGroups = [];
  forEach(Object.keys(groups), (key) => {
    if (Object.prototype.hasOwnProperty.call(groups, key)) {
      for (let i = groups[key].length - 1; i >= 0; i -= 1) {
        mappedGroups.push(groups[key][i]);
      }
    }
  });
  const groupList = [];
  forEach(mappedGroups, (group) => {
    const { id } = group;
    const { guid } = group;
    const { speciality } = group;
    const { language } = group;
    const { level } = group;
    const minAge = group.minAge.toString();
    const { maxAge } = group;
    const from = moment(group.timeFrom);
    const to = moment(group.timeTo);
    const dateFrom = from.format('DD/MMM/YYYY');
    const dateTo = to.format('DD/MMM/YYYY');
    const timeFrom = moment.utc(from.toISOString()).format('HH:mm');
    const timeTo = moment.utc(to.toISOString()).format('HH:mm');
    const name = {
      name: group.name, speciality, level, guid,
    };

    const instructors = [];
    forEach(group.instructors, (instructor) => {
      instructors.push(`${instructor.name} ${instructor.surname}`);
    });

    groupList.push({
      id,
      guid,
      name: JSON.stringify(name),
      date: JSON.stringify({ dateFrom, dateTo }),
      time: JSON.stringify({ timeFrom, timeTo }),
      age: JSON.stringify({ minAge, maxAge }),
      instructors,
      columns: {
        ...group,
        speciality,
        resort: group.resort.id,
      },
      ids: {
        guid,
        id,
      },
      language: language ? LANGUAGES[language] : '-',
    });
  });
  return groupList;
};
