import { schema } from 'normalizr';
import partnerSchema from '../schema/Partner';
import { httpRequest } from '../middleware/http';

export const fetchPartners = () => httpRequest({
  method: 'GET',
  path: '/partner',
  types: [
    'PARTNERS_GET_REQUEST',
    'PARTNERS_GET_SUCCESS',
    'PARTNERS_GET_FAILURE',
  ],
  schema: new schema.Array(partnerSchema),
});

export const fetchPartner = (id) => httpRequest({
  method: 'GET',
  path: `/partner/${id}`,
  types: [
    'PARTNER_GET_REQUEST',
    'PARTNER_GET_SUCCESS',
    'PARTNER_GET_FAILURE',
  ],
  schema: partnerSchema,
});

export const updatePartner = (body, id) => httpRequest({
  method: 'PUT',
  path: `/partner/${id}`,
  body,
  types: [
    'PARTNER_PUT_REQUEST',
    'PARTNER_PUT_SUCCESS',
    'PARTNER_PUT_FAILURE',
  ],
  schema: partnerSchema,
});

export const createPartner = (body) => httpRequest({
  method: 'POST',
  path: '/partner',
  body,
  types: [
    'PARTNER_POST_REQUEST',
    'PARTNER_POST_SUCCESS',
    'PARTNER_POST_FAILURE',
  ],
  schema: partnerSchema,
});

export const deletePartner = (id) => httpRequest({
  method: 'DELETE',
  path: `/partner/${id}`,
  types: [
    'PARTNER_DELETE_REQUEST',
    'PARTNER_DELETE_SUCCESS',
    'PARTNER_DELETE_FAILURE',
  ],
  schema: partnerSchema,
});

export const deletePartners = (body) => httpRequest({
  method: 'POST',
  body,
  path: '/partner/delete',
  types: [
    'PARTNERS_DELETE_REQUEST',
    'PARTNERS_DELETE_SUCCESS',
    'PARTNERS_DELETE_FAILURE',
  ],
  clear: true,
  schema: partnerSchema,
});

export const partnersImport = (body) => httpRequest({
  method: 'POST',
  body,
  path: '/partner/import',
  types: [
    'PARTNER_IMPORT_POST_REQUEST',
    'PARTNER_IMPORT_POST_SUCCESS',
    'PARTNER_IMPORT_POST_FAILURE',
  ],
  schema: null,
});

export const partnersExport = () => httpRequest({
  method: 'GET',
  path: '/partner/export',
  types: [
    'PARTNER_EXPORT_GET_REQUEST',
    'PARTNER_EXPORT_GET_SUCCESS',
    'PARTNER_EXPORT_GET_FAILURE',
  ],
  schema: null,
});
