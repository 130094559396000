import getMuiTheme from 'material-ui/styles/getMuiTheme';

const skiThemeLessonDatepicker = getMuiTheme({
  palette: {
    textColor: '#0d47a1',
    primary1Color: '#1565c0',
    accent3Color: '#424242',
    primary3Color: '#616161',
    primary2Color: '#607d8b',
    shadowColor: 'rgba(255, 255, 255, 0.03)',
    clockCircleColor: '#1565c0',
    pickerHeaderColor: '#1565c0',
    accent1Color: '#1976d2',
    borderColor: '#ffffff',
    secondaryTextColor: '#546e7a',
  },

  datePicker: {
    selectColor: '#1565c0',
    selectTextColor: '#ffffff',
    calendarYearBackgroundColor: '#ffffff',
  },
  timePicker: {
    clockCircleColor: '#1594ff',
    headerColor: '#1565c0',
    color: '#ffffff',
    textColor: '#ffffff',
    accentColor: '#01579B',
    selectColor: '#1565c0',
    selectTextColor: '#ffffff',
  },

  textField: {
    textColor: '#ffffff',
    floatingLabelColor: '#607d8b',
    hintColor: '#ffffff',

  },

});

export default skiThemeLessonDatepicker;
