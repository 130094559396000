import React from 'react';
import PropTypes from 'prop-types';

const TourButton = ({
  style,
  onClick,
  children,
  ...rest
}) => (
  <button style={style} onClick={onClick} type="button" {...rest}>
    {children}
  </button>
);

TourButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.string,
};

export default TourButton;
