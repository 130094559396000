import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import productsSchema from '../schema/Product';

export const updateProduct = (id, body) => httpRequest({
  method: 'PUT',
  path: `/product/${id}`,
  body,
  types: [
    'PRODUCT_PUT_REQUEST',
    'PRODUCT_PUT_SUCCESS',
    'PRODUCT_PUT_FAILURE',
  ],
  schema: productsSchema,
});

export const fetchProducts = () => httpRequest({
  method: 'GET',
  path: '/product',
  types: [
    'PRODUCTS_GET_REQUEST',
    'PRODUCTS_GET_SUCCESS',
    'PRODUCTS_GET_FAILURE',
  ],
  schema: new schema.Array(productsSchema),
});

export const fetchProduct = (id) => httpRequest({
  method: 'GET',
  path: `/product/${id}`,
  types: [
    'PRODUCTS_GET_REQUEST',
    'PRODUCTS_GET_SUCCESS',
    'PRODUCTS_GET_FAILURE',
  ],
  schema: productsSchema,
});

export const deleteProduct = (id) => httpRequest({
  method: 'DELETE',
  path: `/product/${id}`,
  types: [
    'PRODUCT_DELETE_REQUEST',
    'PRODUCT_DELETE_SUCCESS',
    'PRODUCT_DELETE_FAILURE',
  ],
  schema: productsSchema,
});

export const createProduct = (body) => httpRequest({
  method: 'POST',
  path: '/product',
  body,
  types: [
    'PRODUCT_POST_REQUEST',
    'PRODUCT_POST_SUCCESS',
    'PRODUCT_POST_FAILURE',
  ],
  schema: productsSchema,
});

export const getFilteredProductsTimes = (body) => httpRequest({
  method: 'POST',
  path: '/instructor/filter',
  body,
  types: [
    'PRODUCT_TIMES_REQUEST',
    'PRODUCT_TIMES_SUCCESS',
    'PRODUCT_TIMES_FAILURE',
  ],
  schema: null,
});

export const switchFilteredProductTimes = (options) => ({
  type: 'PRODUCT_TIMES_SWITCH',
  options,
});

export const removeFilteredProductTimes = () => ({
  type: 'PRODUCT_TIMES_REMOVE',
});

export const uploadProductImage = (body, id) => httpRequest({
  method: 'POST',
  path: `/product/image/${id}`,
  body,
  types: [
    'PRODUCT_POST_REQUEST',
    'PRODUCT_POST_SUCCESS',
    'PRODUCT_POST_FAILURE',
  ],
  schema: productsSchema,
});
