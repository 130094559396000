import React from 'react';
import PropTypes from 'prop-types';
import '../styles/StatsPopup.scss';

const Column = ({ className, name, val }) => (
  <div className="stat-row">
    <span className={className}>
      {name}
      :
    </span>
    <span className="fr">{val}</span>
  </div>
);

const SchoolStatistics = ({ stats }) => (
  <div className="stats-modal">
    <Column
      name="Bookings"
      val={stats.individualBookings + stats.groupBookings}
    />
    <Column
      name="Private"
      val={stats.individualBookings}
      className="indent-tab"
    />
    <Column
      name="Group"
      val={stats.groupBookings}
      className="indent-tab"
    />
    <Column
      name="Instructors"
      val={stats.instructors}
    />
    <Column
      name="Products"
      val={stats.products}
    />
    <Column
      name="Partners"
      val={stats.partners}
    />
    <Column
      name="Clients"
      val={stats.clients}
    />
  </div>
);

Column.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  val: PropTypes.string,
};

SchoolStatistics.propTypes = {
  stats: PropTypes.shape({
    individualBookings: PropTypes.number,
    groupBookings: PropTypes.number,
    instructors: PropTypes.number,
    products: PropTypes.number,
    partners: PropTypes.number,
    clients: PropTypes.number,
  }),
};

export default React.memo(SchoolStatistics);
