import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { omit } from 'lodash';
import RenderField from './RenderField';
import RenderSelect from './RenderSelect';
import RenderSelectAsync from './RenderSelectAsync';
import RenderSelectAsyncInstructors from './RenderSelectAsyncInstructors';
import RenderTimePicker from './RenderTimePicker';
import RenderDatePicker from './RenderDatePicker';
import FormField from './FormField';

export const Select = (props) => {
  const { fieldLabel, theme } = props;
  return (
    <FormField label={fieldLabel} theme={theme}>
      <Field
        clearable={false}
        disabled={false}
        component={RenderSelect}
        {...props}
      />
    </FormField>
  );
};

export const SelectAsync = (props) => (
  <Select
    component={RenderSelectAsync}
    searchable
    autoload
    {...props}
  />
);

export const SelectAsyncInstructors = (props) => (
  <Select
    component={RenderSelectAsyncInstructors}
    searchable
    autoload
    {...props}
  />
);

export const DateField = (props) => {
  const { fieldLabel, theme } = props;
  return (
    <FormField label={fieldLabel} theme={theme}>
      <Field
        component={RenderDatePicker}
        theme={theme}
        autoOk
        {...omit(props, 'fieldLabel', 'theme')}
        format={null}
      />
    </FormField>
  );
};

export const DateFrom = (props) => (
  <DateField
    fieldLabel="Date from*:"
    hintText="From..."
    {...props}
  />
);

export const DateTo = (props) => (
  <DateField
    fieldLabel="Date to:*"
    hintText="To..."
    {...props}
  />
);

export const Time = (props) => {
  const { fieldLabel, theme } = props;
  return (
    <FormField label={fieldLabel} theme={theme}>
      <Field
        component={RenderTimePicker}
        format={(value) => (value ? new Date(value) : null)}
        minutesStep={5}
        props={{ format: '24hr' }}
        autoOk
        {...omit(props, 'fieldLabel')}
      />
    </FormField>
  );
};

export const TimeFrom = (props) => (
  <Time
    fieldLabel="Time from:*"
    placeholder="From..."
    {...props}
  />
);

export const TimeTo = (props) => (
  <Time
    fieldLabel="Time to:*"
    placeholder="To..."
    {...props}
  />
);

export const Text = (props) => {
  const { fieldLabel, theme } = props;
  return (
    <FormField label={fieldLabel} theme={theme}>
      <Field
        component={RenderField}
        type="text"
        placeholder="..."
        {...omit(props, 'fieldLabel')}
      />
    </FormField>
  );
};

export const Number = (props) => {
  const { fieldLabel, theme } = props;
  return (
    <FormField label={fieldLabel} theme={theme}>
      <Field
        component={RenderField}
        type="number"
        placeholder="..."
        min={0}
        {...omit(props, 'fieldLabel')}
      />
    </FormField>
  );
};

export const Activity = (props) => (
  <Select
    simpleValue
    fieldLabel="Activity*:"
    {...props}
  />
);

export const Speciality = (props) => (
  <Select
    simpleValue
    fieldLabel="Speciality*:"
    {...props}
  />
);

Select.propTypes = {
  fieldLabel: PropTypes.string,
  theme: PropTypes.string,
};

DateField.propTypes = {
  fieldLabel: PropTypes.string,
  theme: PropTypes.string,
};

Time.propTypes = {
  fieldLabel: PropTypes.string,
  theme: PropTypes.string,
};

Text.propTypes = {
  fieldLabel: PropTypes.string,
  theme: PropTypes.string,
};

Number.propTypes = {
  fieldLabel: PropTypes.string,
  theme: PropTypes.string,
};
