import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import RaisedButton from 'material-ui/RaisedButton';
import FormField from './FormField';
import RenderField from './RenderField';
import RenderPhoneInput from './RenderPhoneInput';
import { required, phone } from '../utils/validators';
import '../styles/BookingForm.scss';
import { bookingStyleObject } from '../styles/BookingFormStyles';

const ClientForm = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
}) => (
  <div className="BookingForm__addnew-form">
    <FormField label="First name:*" theme="small">
      <Field
        name="name"
        component={RenderField}
        type="text"
        placeholder="First Name"
        validate={[required]}
      />
    </FormField>
    <FormField label="Last name:*" theme="small">
      <Field
        name="surname"
        component={RenderField}
        type="text"
        placeholder="Last Name"
        validate={[required]}
      />
    </FormField>
    <FormField label="Email:*" theme="small">
      <Field
        name="email"
        component={RenderField}
        type="email"
        placeholder="Email"
        validate={[required]}
      />
    </FormField>
    <FormField label="Phone:" theme="small">
      <Field
        name="phoneNumbers"
        component={RenderPhoneInput}
        validate={[phone]}
        placeholder="Phone number"
      />
    </FormField>
    <FormField label="Country:" theme="small">
      <Field
        name="country"
        component={RenderField}
        type="text"
        placeholder="Country"
      />
    </FormField>
    <div>
      <RaisedButton
        onClick={handleSubmit}
        backgroundColor="#253D63"
        label="CREATE CLIENT"
        labelStyle={bookingStyleObject.createNewClientButton.label}
        style={bookingStyleObject.createNewClientButton}
        disabled={pristine || invalid || submitting}
      />
    </div>
  </div>
);

ClientForm.propTypes = {
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default reduxForm({ form: 'ClientForm' })(React.memo(ClientForm));
