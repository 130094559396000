import React from 'react';
import '../styles/EditEntityButton.scss';

const EditEntityButton = () => (
  <div className="edit-entity-button">
    <div className="edit-entity-button__label" />
  </div>
);

export default React.memo(EditEntityButton);
