import { schema } from 'normalizr';
import partnerManagerSchema from '../schema/PartnerManager';
import { httpRequest } from '../middleware/http';

export const fetchPartnerManagers = () => httpRequest({
  method: 'GET',
  path: '/partnerManager',
  types: [
    'PARTNER_MANAGERS_GET_REQUEST',
    'PARTNER_MANAGERS_GET_SUCCESS',
    'PARTNER_MANAGERS_GET_FAILURE',
  ],
  schema: new schema.Array(partnerManagerSchema),
});

export const fetchPartnerManager = (id) => httpRequest({
  method: 'GET',
  path: `partnerManager/${id}`,
  types: [
    'PARTNER_MANAGER_GET_REQUEST',
    'PARTNER_MANAGER_GET_SUCCESS',
    'PARTNER_MANAGER_GET_FAILURE',
  ],
  schema: partnerManagerSchema,
});

export const deletePartnerManager = (id) => httpRequest({
  method: 'DELETE',
  path: `/partnerManager/${id}`,
  types: [
    'PARTNER_MANAGER_DELETE_REQUEST',
    'PARTNER_MANAGER_DELETE_SUCCESS',
    'PARTNER_MANAGER_DELETE_FAILURE',
  ],
  schema: partnerManagerSchema,
});

export const createPartnerManager = (body) => httpRequest({
  method: 'POST',
  path: '/partnerManager',
  body,
  types: [
    'PARTNER_MANAGER_POST_REQUEST',
    'PARTNER_MANAGER_POST_SUCCESS',
    'PARTNER_MANAGER_POST_FAILURE',
  ],
  schema: partnerManagerSchema,
});

export const updatePartnerManager = (id, body) => httpRequest({
  method: 'PUT',
  path: `/partnerManager/${id}`,
  body,
  types: [
    'PARTNER_MANAGER_PUT_REQUEST',
    'PARTNER_MANAGER_PUT_SUCCESS',
    'PARTNER_MANAGER_PUT_FAILURE',
  ],
  schema: partnerManagerSchema,
});

export const partnerManagersImport = (body) => httpRequest({
  method: 'POST',
  body,
  path: '/partnerManager/import',
  types: [
    'PARTNER_MANAGER_IMPORT_POST_REQUEST',
    'PARTNER_MANAGER_IMPORT_POST_SUCCESS',
    'PARTNER_MANAGER_IMPORT_POST_FAILURE',
  ],
  schema: null,
});

export const partnerManagersExport = () => httpRequest({
  method: 'GET',
  path: '/partnerManager/export',
  types: [
    'PARTNER_MANAGER_EXPORT_GET_REQUEST',
    'PARTNER_MANAGER_EXPORT_GET_SUCCESS',
    'PARTNER_MANAGER_EXPORT_GET_FAILURE',
  ],
  schema: null,
});
