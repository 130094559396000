const PlansCredits = {
  0: {
    name: 'No plan',
    credits: [],
  },
  1: {
    name: 'Free',
    credits:
      [
        'Up to 3 active instructors',
        'Up to 100 bookings',
        'Support BASIC',
      ],
    setupFee: '0',
    priceMonthly: '0',
  },
  2: {
    name: 'Successful Business',
    credits:
      [
        'Unlimited Active Instructors',
        'Unlimited Bookings',
        'Support PRO',
      ],
    setupFee: '0',
    priceMonthly: '110',
  },
  3: {
    name: 'Growing Business',
    credits:
      [
        'Up to 30 active instructors',
        'Up to 300 bookings',
        'Support ADVANCED',
      ],
    setupFee: '0',
    priceMonthly: '90',
  },
};

export default PlansCredits;
