import { get } from 'lodash';

const initialState = {
  errorMessage: '',
};

const error = (state = initialState, action) => {
  switch (action.type) {
    case 'GLOBAL_ERROR':
      if (action.payload.status === 409) {
        return {
          ...state,
          errorMessage: get(action.payload, 'data.constraintViolations[0].message', ''),
        };
      } if (action.payload.status === 400 && get(action.payload, 'data.constraintViolations[0].property', 'not_found') === '') {
        return {
          ...state,
          errorMessage: get(action.payload, 'data.constraintViolations[0].message', ''),
        };
      }
      return {
        ...state,
        errorMessage: '',
      };
    default:
      return {
        ...state,
        errorMessage: '',
      };
  }
};

export default error;
