import {
  merge,
  get,
  omit,
  forEach,
} from 'lodash';
import * as c from '../constants/Api';

const initialState = {
  speciality: {},
  booking: {},
  instructor: {},
  lessons: {},
  product: {},
  level: {},
  client: {},
  resort: {},
  hotel: {},
  partner: {},
  partnerManager: {},
  account: {},
  activity: {},
  season: {},
  buyer: {},
  service: {},
  bookingService: {},
  group: {},
  lessonPrice: {},
  salary: {},
  lessonBlock: {},
  availability: {},
  groupPrice: {},
};

const entities = (state = initialState, action) => {
  switch (action.type) {
    case c.API_REQUEST:
      return state;
    case c.API_SUCCESS:
      if (action.payload.method === 'DELETE') {
        const { key } = action.payload.schema;
        const newState = {
          ...state,
        };
        newState[key] = { ...newState[key] };
        const tempEntities = action.payload.entities[key];
        forEach(tempEntities, (val, k) => {
          delete newState[key][k];
        });
        return newState;
      }
      if (action.payload.method === 'POST' || action.payload.method === 'GET') {
        const { key } = action.payload.schema;

        if (action.payload.clear) {
          const updated = get(action, 'payload.entities.updated');
          const entity = omit(state[key], updated);

          return {
            ...state,
            [key]: entity,
          };
        }
        return merge({}, state, action.payload.entities);
      }
      if (action.payload.method === 'PUT') {
        const tempState = state;
        tempState.needsUpdate = ['test'];
        const entity = action.payload.schema.key
          ? action.payload.schema.key : action.payload.schema.schema.key;
        const keys = Object.keys(action.payload.entities[entity]);
        keys.forEach((key) => {
          tempState[entity][key] = action.payload.entities[entity][key];
        });
        return merge({}, tempState, action.payload.entities);
      }
      return state;
    case c.API_FAILURE:
      return state;
    default:
      return state;
  }
};

export default entities;
