import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import _ from 'lodash';
import moment from 'moment';
import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';
import AddLessonButton from '../components/AddLessonButton';
import RemoveLessonButton from '../components/RemoveLessonButton';
import { Select, Text } from '../components/Field';
import { fetchLessonPrice } from '../actions/lessonActions';
import { fetchGroupDates } from '../actions/groupsActions';
import Lesson from './Lesson';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/BookingForm.scss';
import { mapLessonsToOptions, mapLessonBlockToPayload, mapLessonToPayload } from '../utils/map';
import { sortDates } from '../utils/helpers';
import { formatCurrency } from '../utils/format';
import RenderToggle from '../components/RenderToggle';
import { removeBookingScrollIntoView } from '../actions/userGuide';
import { convertToDate, convertForPrice } from '../utils/dateTime';
import { types } from '../utils/formHelpers';

const LessonBlock = ({
  edit,
  fields,
  change,
  userGuide,
  onLessonSubmit,
  fetchGroupDatesA,
  fetchLessonPriceA,
  meta: { error },
  removeBookingScrollIntoViewA,
  currency,
  fieldsDisabled,
  createBookingPermission,
  seePricePermission,
}) => {
  const userGuidePrev = useRef();
  const guideElements = useRef({});

  const length = useMemo(() => {
    if (edit) {
      return fields.length;
    }
    return 1;
  }, []);

  const [lessons, setLessons] = useState(Array.from(Array(length), () => 0));
  const [enabledDates, setEnabledDatesState] = useState([]);

  const setEnabledDates = (index, lesson, guid) => {
    fetchGroupDatesA(guid).then((response) => {
      if (!enabledDates[index]) {
        enabledDates[index] = [];
      }

      enabledDates[index][lesson] = response.payload.result.map((day) => convertToDate(day));
      setEnabledDatesState([...enabledDates]);
    });
  };

  const setEnabledDatesForLesson = (blockLessons, index, lesson) => {
    const filteredLessons = _.filter(blockLessons, (block) => block.id !== '*');

    const tempEnabledDates = [];
    tempEnabledDates[index] = [];
    tempEnabledDates[index][lesson] = _.map(filteredLessons, (l) => new Date(l.dateFrom));
    setEnabledDatesState(tempEnabledDates);
  };

  const onLessonChange = (index) => (e, item) => {
    const blockLessons = fields.get(index).lessons;
    const selectAllOption = _.find(blockLessons, { id: '*' });

    if (item === '*' && !selectAllOption) {
      const firstLesson = blockLessons[0];
      blockLessons.push({
        ...firstLesson,
        id: item,
        dateFrom: _.minBy(blockLessons, 'dateFrom').dateFrom,
        dateTo: _.maxBy(blockLessons, 'dateTo').dateTo,
      });
      change(`lessonBlocks[${index}].lessons`, blockLessons);
    }

    const lesson = _.findIndex(fields.get(index).lessons, (l) => l.id === item);

    lessons[index] = lesson;

    change(`lessonBlocks[${index}].activeLesson`, item);
    setLessons([...lessons]);

    const { type } = fields.get(index);

    if (_.get(type, 'id') === 'group') {
      const group = fields.get(`[${index}].lessons[${lesson}].group`);

      setEnabledDates(index, lesson, group.id);
    }
    if (item === '*') {
      setEnabledDatesForLesson(blockLessons, index, lesson);
    }
  };

  const setInitialEnabledDatesForLesson = () => {
    const tempEnabledDates = [];

    fields.getAll().forEach((lessonBlock, index) => {
      const filteredLessons = _.filter(lessonBlock.lessons, (block) => block.id !== '*');
      lessonBlock.lessons.forEach((lesson, index2) => {
        if (lesson.id === '*') {
          tempEnabledDates[index] = [];
          tempEnabledDates[index][index2] = _.map(filteredLessons, (l) => new Date(l.dateFrom));
        }
      });
    });
    setEnabledDatesState(tempEnabledDates);
  };

  useEffect(() => {
    fields.getAll().forEach((lessonBlock, index) => {
      const sortedLessons = sortDates(lessonBlock.lessons);
      const head = sortedLessons.find(() => true);

      if (edit && sortedLessons.length > 1 && lessonBlock.type === 'individual') {
        onLessonChange(index)(null, '*');
      } else if (head) {
        onLessonChange(index)(null, head.id);
      } else if (!head) {
        onLessonChange(index)(null, null);
      }
    });
    if (fields.length) {
      setInitialEnabledDatesForLesson();
    }
  }, []);

  const mapDateTimeToTime = (dateFrom, dateTo, timeFrom, timeTo) => {
    if (!dateFrom || !dateTo || !timeFrom || !timeTo) {
      return {
        timeFrom: undefined,
        timeTo: undefined,
      };
    }
    const isoDateFrom = convertForPrice(dateFrom).toISOString();
    const isoDateTo = convertForPrice(dateTo).toISOString();
    const isoTimeFrom = moment(timeFrom).toISOString();
    const isoTimeTo = moment(timeTo).toISOString();

    return {
      timeFrom: `${isoDateFrom.split('T')[0]}T${isoTimeFrom.split('T').pop()}`,
      timeTo: `${isoDateTo.split('T')[0]}T${isoTimeTo.split('T').pop()}`,
    };
  };

  const mapValuesToPayload = (index) => {
    const lessonBlock = fields.get(index);
    let type = fields.get(`[${index}].type`);
    let payload;

    if (typeof type === 'object') {
      type = type.value;
    }

    if (type === 'individual') {
      payload = {
        ...mapLessonBlockToPayload(lessonBlock),
        lessons: _.map(lessonBlock.lessons, (lesson) => mapLessonToPayload(type, lesson)),
      };
    } else {
      payload = {
        ...mapLessonBlockToPayload(lessonBlock),
        lessons: _.map(lessonBlock.lessons, (lesson) => mapLessonToPayload(type, lesson)),
      };
    }
    return payload;
  };

  const checkSubmitErrors = (payload, index, lessonIndex) => {
    if (error) {
      if (lessonIndex || lessonIndex === 0) {
        if (Object.keys(error).length === 1) {
          const errors = error[index.toString()];
          if (errors) {
            if (Object.keys(errors).length === 1) {
              const lessonErrors = errors[lessonIndex.toString()];
              if (lessonErrors) {
                const errorKeys = Object.keys(lessonErrors);
                let persistedErrors = false;
                for (let i = 0; i < errorKeys.length; i += 1) {
                  if (!payload.lessons[lessonIndex.toString()][errorKeys[i]]) {
                    persistedErrors = true;
                    break;
                  }
                }
                return persistedErrors;
              }
            }
          }
        }
      }
      return true;
    }
    return false;
  };

  const onSubmit = (index, lessonIndex, buyers) => {
    let payload = mapValuesToPayload(index);
    if (checkSubmitErrors(payload, index, lessonIndex)) {
      return;
    }

    const allLessons = _.find(payload.lessons, (lesson) => lesson.id === '*');

    const checkAddLesson = (lesson) => {
      if (allLessons && fields.get(index).activeLesson === '*') {
        const { dateFrom, dateTo } = allLessons;
        return convertForPrice(lesson.dateFrom).set('hours', 0).set('minutes', 0)
          .isSameOrAfter(convertForPrice(dateFrom).set('hours', 0).set('minutes', 0))
          && convertForPrice(lesson.dateTo).set('hours', 0).set('minutes', 0)
            .isSameOrBefore(convertForPrice(dateTo).set('hours', 0).set('minutes', 0));
      }
      return true;
    };

    payload.lessons = _.reduce(payload.lessons, (arr, lesson, i) => {
      if (lesson.id !== '*' && checkAddLesson(lesson)) {
        const formattedLesson = {
          ...lesson,
          ...mapDateTimeToTime(lesson.dateFrom, lesson.dateTo, lesson.timeFrom, lesson.timeTo),
        };
        if (buyers && i === lessonIndex) {
          formattedLesson.buyers = _.map(buyers, (buyer) => {
            const temp = buyer;
            if (temp.level === '') {
              temp.level = null;
            }
            delete temp.id;
            return temp;
          });
          formattedLesson.clientAmount = buyers.length;
        }
        return [...arr, formattedLesson];
      }
      return arr;
    }, []);

    fetchLessonPriceA(payload).then((response) => {
      const { price } = response.payload.result;
      payload = mapValuesToPayload(index);

      change(`lessonBlocks[${index}].total`, price);
      onLessonSubmit(index, payload);
    }).catch(() => {
      payload = mapValuesToPayload(index);

      change(`lessonBlocks[${index}].total`, 0);
      onLessonSubmit(index, payload);
    });
  };

  const onPush = (index) => {
    const lessonBlock = {
      ...fields.get(index),
    };

    const tempLessons = lessons || [];
    tempLessons.push(0);

    setLessons(tempLessons);

    delete lessonBlock.id;
    delete lessonBlock.account;

    const head = tempLessons[index];
    const lesson = lessonBlock.lessons[head];

    const block = {
      ...lessonBlock,
      lessons: [
        {
          ..._.omit(lesson, 'id', 'lessonBlock', 'account', 'total'),
          buyers: _.map(lesson.buyers, (v) => _.omit(v, 'id')),
        },
      ],
      edit: false,
    };
    new Promise((resolve) => {
      fields.push(block);
      resolve();
    }).then(() => {
      onSubmit(index + 1);
    });
  };

  const scrollIntoView = (name, alignTop) => {
    const el = guideElements.current[name];

    if (el) {
      el.scrollIntoView(alignTop);
      removeBookingScrollIntoViewA();
    }
  };

  useEffect(() => {
    if (userGuide) {
      if (userGuide.triggerLessonBlockAdd && !userGuidePrev.current.triggerLessonBlockAdd) {
        onPush(0);
      }
    }
    if (userGuide.bookingScrollIntoView && !userGuidePrev.current.bookingScrollIntoView) {
      scrollIntoView(userGuide.bookingScrollIntoView, userGuide.alignTop);
    }
    userGuidePrev.current = userGuide;
  }, [userGuide]);

  const valueOf = (index, lesson) => (name) => {
    const value = fields.get(`[${index}].lessons[${lesson}].${name}`);
    if (value && typeof value === 'object' && !(value instanceof Date) && Object.prototype.toString.call(value) !== '[object Array]') {
      if (value.value) {
        return value.value;
      }
      return value.id;
    }

    return value;
  };

  const handleChange = (index, lesson) => (name, value, submit = true) => {
    change(`lessonBlocks[${index}].lessons[${lesson}].${name}`, value);
    if (submit) {
      const buyers = name === 'buyers' ? value : undefined;
      onSubmit(index, lesson, buyers);
    }
  };

  const onRemove = (index) => () => {
    lessons.splice(index, 1);
    setLessons([...lessons]);
    fields.remove(index);
    onLessonSubmit(index, null);
  };

  const onGroupChange = (index) => (lesson, guid) => {
    setEnabledDates(index, lesson, guid);
  };

  const onDiscountChange = (index) => (e, value) => {
    const i = value.indexOf('%');
    const discount = i === -1 ? value : value.substr(0, i) || 0;

    Promise.all([
      change(`lessonBlocks[${index}].discount`, discount),
      change(`lessonBlocks[${index}].discountType`, i === -1 ? 'fixed' : 'percent'),
    ]).then(() => onSubmit(index));
  };

  const isDeleteButtonVisible = useMemo(() => fields.length > 1, [fields]);

  return (
    <div>
      {fields.map((lessonBlock, index) => (
        <div key={index}>
          {fields.get(index).edit && !fieldsDisabled && fields.get(index).lessons.length > 1
          && (
            <Select
              simpleValue
              name={`${lessonBlock}.activeLesson`}
              fieldLabel="Select lesson:"
              options={mapLessonsToOptions(fields.get(index).lessons)}
              onChange={onLessonChange(index)}
            />
          )}
          <div className="BookingForm__lesson">
            {isDeleteButtonVisible && (!fieldsDisabled || createBookingPermission)
            && (
              <div
                className="booking-section__remove-lesson"
                id={`remove-${index}-anchor`}
                ref={(element) => { guideElements.current[`remove-${1}-anchor`] = element; }}
              >
                <RemoveLessonButton onClick={onRemove(index)} />
              </div>
            )}
            <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
              <div className="lesson-block">
                <div
                  className="lesson-form"
                >
                  <span id={`paid-${index}-anchor`} />
                  {!fieldsDisabled
                  && (
                    <Field
                      component={RenderToggle}
                      name={`${lessonBlock}.paid`}
                      disabled={fieldsDisabled}
                      onChange={(e, v) => change(`${lessonBlock}.paid`, v)}
                      value={fields.get(`[${index}].paid`)}
                      normalize={(v) => (_.isBoolean(v) ? v : false)}
                      wrapperClass="booking"
                    />
                  )}
                  <Select
                    name={`${lessonBlock}.type`}
                    fieldLabel="Lesson type*:"
                    options={types}
                    onChange={(type) => change(`${lessonBlock}.type`, type.value)}
                    disabled={fields.get(index).edit
                    || (fieldsDisabled && !createBookingPermission)}
                    theme="white"
                  />
                  <Lesson
                    type={fields.get(`[${index}].type`)}
                    lesson={`${lessonBlock}.lessons[${lessons[index]}]`}
                    lessons={fields.get(index).lessons}
                    index={lessons[index]}
                    disabled={fields.get(index).edit}
                    fieldsDisabled={fieldsDisabled}
                    createBookingPermission={createBookingPermission}
                    edit={fields.get(index).edit}
                    change={handleChange(index, lessons[index])}
                    valueOf={valueOf(index, lessons[index])}
                    onGroupChange={onGroupChange(index)}
                    enabledDates={_.get(enabledDates, `[${index}][${lessons[index]}]`) || []}
                    activeLesson={fields.get(index).activeLesson}
                  />
                  <span
                    id={`discount-anchor-${index}`}
                    ref={(element) => { guideElements.current[`discountAnchor-${index}`] = element; }}
                  />
                  {!fieldsDisabled
                  && (
                    <Text
                      name={`${lessonBlock}.discount`}
                      fieldLabel="Discount:"
                      type="text"
                      onChange={onDiscountChange(index)}
                      theme="white"
                    />
                  )}
                </div>
                {seePricePermission
                && (
                  <div className="lesson-form-price-bar">
                    <label className="lesson-form-price-label">Price: </label>
                    <div className="lesson-form-price-value">{formatCurrency(fields.get(index).total || 0, currency)}</div>
                  </div>
                )}
              </div>
            </MuiThemeProvider>
            {(!fieldsDisabled || createBookingPermission)
            && (
              <div
                className="booking-section__add-lesson"
                ref={(element) => { guideElements.current[`formBottom-${index}`] = element; }}
              >
                <AddLessonButton style={{ cursor: 'pointer' }} onClick={() => onPush(index)} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userGuide: state.userGuide,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLessonPriceA: bindActionCreators(fetchLessonPrice, dispatch),
  fetchGroupDatesA: bindActionCreators(fetchGroupDates, dispatch),
  removeBookingScrollIntoViewA: bindActionCreators(removeBookingScrollIntoView, dispatch),
});

LessonBlock.propTypes = {
  edit: PropTypes.bool,
  fields: PropTypes.shape({
    length: PropTypes.number,
    get: PropTypes.func,
    getAll: PropTypes.func,
    push: PropTypes.func,
    remove: PropTypes.func,
    map: PropTypes.func,
  }),
  change: PropTypes.func,
  userGuide: PropTypes.shape({
    triggerLessonBlockAdd: PropTypes.bool,
    bookingScrollIntoView: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    alignTop: PropTypes.object,
  }),
  onLessonSubmit: PropTypes.func,
  fetchGroupDatesA: PropTypes.func,
  fetchLessonPriceA: PropTypes.func,
  meta: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    error: PropTypes.object,
  }),
  removeBookingScrollIntoViewA: PropTypes.func,
  currency: PropTypes.string,
  fieldsDisabled: PropTypes.bool,
  createBookingPermission: PropTypes.bool,
  seePricePermission: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LessonBlock));
