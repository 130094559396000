import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Drawer from '../components/Drawer';
import Page from '../components/Page';
import AuthoredNav from '../components/AuthoredNav';
import Dialog from '../components/Dialog';
import { open } from '../actions/dialogActions';
import Notification from '../components/Notification';
import { notifyError } from '../actions/notificationActions';
import '../styles/App.scss';

const TwoColumnsLayout = (props) => {
  const {
    error,
    notifyErrorA,
    dialog,
    children,
  } = props;

  useEffect(() => {
    if (error && error.errorMessage) {
      notifyErrorA({}, error.errorMessage);
    }
  }, [error]);

  return (
    <div>
      <Dialog {...dialog} />
      <div className="app__sidebar">
        <AuthoredNav />
      </div>
      <div className="app__content">
        <div>
          <Notification />
          <Drawer width={1000} />
          <Page
            {...props}
          >
            <div>
              {children && React.cloneElement(children, props)}
            </div>
          </Page>
        </div>
      </div>
    </div>
  );
};

TwoColumnsLayout.propTypes = {
  error: PropTypes.shape({
    errorMessage: PropTypes.string,
  }),
  notifyErrorA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  dialog: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

const mapStateToProps = (state) => ({
  drawer: state.drawer,
  dialog: state.dialog,
  error: state.error,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: bindActionCreators(open, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(TwoColumnsLayout));
