import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { map, get, find } from 'lodash';
import { List, ListItem } from 'material-ui/List';
import { FlatButton } from 'material-ui';
import Select from 'react-select';
import Dialog from './dialog/Dialog';
import PlansCredits from '../constants/Plans';
import PlansRender from '../containers/PlansRender';

const itemStyle = {
  fontSize: '12px',
  fontFamily: 'Ubuntu',
  color: '#455D8D',
  padding: '3px 0',
};

class RenderSelectedPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      planPaymentTypeOptions: [
        { value: 'monthly', label: 'monthly' },
        { value: 'annually', label: 'annually' },
      ],
      paymentType: undefined,
      plan: undefined,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { planPaymentType, plan } = props;
    const { paymentType, plan: statePlan } = state;
    if (!paymentType || !statePlan) {
      return {
        ...state,
        paymentType: planPaymentType,
        plan,
      };
    }
    return state;
  }

  handleDialog = () => {
    const { dialogOpen } = this.state;
    this.setState({
      dialogOpen: !dialogOpen,
    });
  }

  handleSubmit = () => {
    const { handleInstantPlanChange, setSelectorValue } = this.props;
    const { paymentType, plan } = this.state;

    if (handleInstantPlanChange) {
      this.handleDialog();
      handleInstantPlanChange(paymentType, plan);
    } else {
      setSelectorValue('planPaymentType', paymentType);
      setSelectorValue('plan', plan);
      this.handleDialog();
    }
  }

  render() {
    const { planPricing } = this.props;
    const {
      dialogOpen,
      planPaymentTypeOptions,
      plan,
      paymentType,
    } = this.state;
    const planId = plan ? get(plan, 'id', plan) : undefined;
    const modalActions = [
      <FlatButton
        label="Submit"
        primary
        onClick={this.handleSubmit}
      />,
    ];

    return (
      <div className="pbl-20">
        <p>{planPricing && planId && planPricing[planId].name}</p>
        <Field name="plan" component="hidden" type="hidden" value={plan} />
        <Dialog
          modal={false}
          open={dialogOpen}
          onRequestClose={() => this.handleDialog()}
          actions={modalActions}
          className="account-settings-upgrade-dialog"
        >
          <>
            <div onClick={() => this.handleDialog()}>
              <i className="material-icons sidemenu__close close-button">
                close
              </i>
            </div>
            <div className="account-settings-planbox">
              <PlansRender
                planPricing={planPricing}
                setSelectorValue={(value) => this.setState({ plan: value.value })}
              />
            </div>
            <div className="register-form__input-wrapper" style={{ paddingBottom: '50px' }}>
              <label>Plan payment type:*</label>
              <div className="register-form__input">
                <Select
                  value={find(planPaymentTypeOptions, (option) => option.value === paymentType)}
                  clearable={false}
                  options={planPaymentTypeOptions}
                  onChange={(val) => this.setState({ paymentType: val.value })}
                />
              </div>
            </div>
          </>
        </Dialog>
        <List className="w-300">
          {planPricing && planId && map(PlansCredits[planId].credits, (credit, index) => (
            <ListItem
              key={index}
              disabled
              style={itemStyle}
            >
              {credit}
            </ListItem>
          ))}
          <ListItem
            disabled
            style={itemStyle}
          >
            Setup fee
            {' '}
            {planPricing && planId && planPricing[planId].setupFee}
            {' '}
            EUR
          </ListItem>
        </List>
        <p className="account-settings-additional" onClick={() => this.handleDialog()}>Upgrade</p>
        <span style={{ position: 'relative', left: '25%' }} id="upgrade-plan-anchor" />
      </div>
    );
  }
}

RenderSelectedPlan.propTypes = {
  planPaymentType: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  plan: PropTypes.object,
  handleInstantPlanChange: PropTypes.func,
  setSelectorValue: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  planPricing: PropTypes.object,
};

export default RenderSelectedPlan;
