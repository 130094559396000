import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import TimelineMonthBlock from './TimelineMonthBlock';
import { mapEventsToBlocks, mapMonthEvents } from '../../utils/map';
import '../../styles/InstructorTimeline.scss';

const InstructorTimeline = ({ events, onItemDoubleClick, onGroupOpen }) => (
  <div className="instructor-timeline">
    {map(mapEventsToBlocks(events), (block, key) => (
      <TimelineMonthBlock
        key={key}
        month={key}
        events={mapMonthEvents(block)}
        onItemDoubleClick={onItemDoubleClick}
        onGroupOpen={onGroupOpen}
      />
    ))}
  </div>
);

InstructorTimeline.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.object,
  onItemDoubleClick: PropTypes.func,
  onGroupOpen: PropTypes.func,
};

export default React.memo(InstructorTimeline);
