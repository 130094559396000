import getMuiTheme from 'material-ui/styles/getMuiTheme';

const skiThemeLessonForm = getMuiTheme({
  palette: {
    primary3Color: '#fafafa',
    primary2Color: '#ffffff',
    primary1Color: '#253D63',
    accent1Color: '#00e676',
    accent2Color: '#00e676',
    accent3Color: '#00e676',
    borderColor: '#ffffff',
    canvasColor: '#ffffff',
    textColor: '#000000',
    secondaryTextColor: '#1565c0',
    alternateTextColor: 'rgb(255, 255, 255)',
    disabledColor: 'rgba(255, 255, 255, 0.87)',
  },
  checkbox: {
    checkedColor: '#ffffff',
    boxColor: '#ffffff',
  },
  datePicker: {
    selectColor: '#1565c0',
    selectTextColor: '#ffffff',
    calendarYearBackgroundColor: '#ffffff',
  },
  timePicker: {
    clockCircleColor: '#1594ff',
    headerColor: '#1565c0',
    color: '#ffffff',
    textColor: '#ffffff',
    accentColor: '#01579B',
    selectColor: '#1565c0',
    selectTextColor: '#ffffff',
  },

  toggle: {
    thumbOffColor: '#00e676',
    thumbOnColor: '#00e676',
    trackOnColor: 'rgba(0, 230, 118, 0.6)',
    trackOffColor: 'rgba(0, 230, 118, 0.62)',
    trackDisabledColor: '#90a4ae',
    thumbDisabledColor: '#607d8b',
  },
  textField: {
    textColor: '#ffffff',
    floatingLabelColor: '#607d8b',
    hintColor: '#ffffff',

  },
  tableHeaderColumn: {
    textColor: '#1565c0',
  },
  tableRow: {
    hoverColor: '#fafafa',
    stripeColor: '#cfd8dc',
    borderColor: '#78909c',
    selectedColor: 'rgba(207, 216, 220, 0.11)',
  },
  floatingActionButton: {
    buttonSize: 25,
    miniSize: 14,
  },
});

export default skiThemeLessonForm;
