const initialState = {
  options: [],
  selected: [],
};

const productTimes = (state = initialState, action) => {
  switch (action.type) {
    case 'PRODUCT_TIMES_SUCCESS': {
      return {
        ...state,
        options: action.payload.result,
      };
    }
    case 'PRODUCT_TIMES_SWITCH': {
      return {
        ...state,
        selected: action.options,
      };
    }
    case 'PRODUCT_TIMES_REMOVE': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default productTimes;
