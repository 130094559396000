import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SubmissionError,
  formValueSelector,
} from 'redux-form';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import Dialog from './dialog/Dialog';
import ForgotPasswordForm from './ForgotPasswordForm';
import { forgotPassword } from '../actions/accountActions';
import '../styles/ForgotPassword.scss';

const customContentStyle = {
  width: '30%',
  maxWidth: 'none',
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'hide',
    };
  }

  forgotPasswordSubmit = () => {
    const { emailForgot, forgotPasswordA } = this.props;
    const domain = window.location.host.split('.')[0];

    this.setState({
      status: 'loading',
    });

    return forgotPasswordA(emailForgot, domain).then(() => {
      this.setState({
        status: 'success',
      });
    }).catch((err) => {
      const message = get(err, 'data.constraintViolations[0].message', '');

      this.setState({
        status: 'hide',
      });
      throw new SubmissionError({ emailForgot: message });
    });
  }

  render() {
    const {
      onForgotPasswordClick,
      dialogOpen,
    } = this.props;
    const { status } = this.state;

    return (
      <Dialog
        modal={false}
        title="Forgot password"
        open={dialogOpen}
        contentClassName="forgotpass-dialog"
        titleClassName="forgotpass-title"
        contentStyle={customContentStyle}
        onRequestClose={onForgotPasswordClick}
      >
        <ForgotPasswordForm forgotPasswordSubmit={this.forgotPasswordSubmit} status={status} />
      </Dialog>
    );
  }
}

const selector = formValueSelector('ForgotPassword');

const mapStateToProps = (state) => ({
  emailForgot: selector(state, 'emailForgot'),
});

const mapDispatchToProps = (dispatch) => ({
  forgotPasswordA: bindActionCreators(forgotPassword, dispatch),
});

ForgotPassword.propTypes = {
  emailForgot: PropTypes.string,
  forgotPasswordA: PropTypes.func,
  onForgotPasswordClick: PropTypes.func,
  dialogOpen: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
