import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TwoColumnsLayout from './TwoColumnsLayout';
import OneColumnLayout from './OneColumnLayout';
import '../styles/GroupView.scss';

const ColumnLayout = (props) => {
  const { display, children } = props;
  const component = useMemo(() => {
    if (display === 'two-col') {
      return (
        <TwoColumnsLayout {...props}>
          {children}
        </TwoColumnsLayout>
      );
    }
    if (display === 'one-col') {
      return (
        <OneColumnLayout {...props}>
          {children}
        </OneColumnLayout>
      );
    }
    return null;
  }, [display, children]);
  return (
    <div>
      {component}
    </div>
  );
};

ColumnLayout.propTypes = {
  display: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
};

export default React.memo(ColumnLayout);
