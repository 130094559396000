/* eslint-disable */
import React from 'react';
import {
  map,
  minBy,
  maxBy,
  omit,
  uniq,
  forEach,
  get,
  includes,
} from 'lodash';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import moment from 'moment';
import { isJsonString } from '../../utils/helpers';
import { formatDate } from '../../utils/format';
import skiThemeLessonForm from '../../styles/theme/skiThemeLessonForm';
import EditEntityButton from '../../components/EditEntityButton';
import DeleteEntityButton from '../../components/DeleteEntityButton';
import SpecialityIcon from '../../components/SpecialityIcon';
import ActiveButton from '../../components/ActiveButton';

const NameSpecialityIcon = (props) => (
  <div>
    <span className="speciality-tag-name">{props.name}</span>
    <SpecialityIcon
      {...omit(props, 'name')}
    />
  </div>
);

const GroupListColumns = (
  handleSelection,
  selectedGroups,
  selectedGuids,
  handleEdit,
  handleDeletion,
  openDialog,
  onEshopToggle,
  onGroupExport2Shop,
  selectAll,
) => [
  {
    Header: (
      <div>
        <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
          <Checkbox
            onCheck={() => handleSelection('*')}
            checked={selectAll}
          />
        </MuiThemeProvider>
      </div>
    ),
    accessor: 'ids',
    aggregate: (vals) => vals[0],
    Cell: (props) => (
      <div>
        <Checkbox
          onCheck={() => handleSelection(props.value.id)}
          checked={includes(selectedGuids, props.value.guid) || includes(selectedGroups, props.value.id)}
        />
      </div>
    ),
    Aggregated: (props) => {
      const row = get(props, 'subRows[0]._original');
      return (
        <div>
          <Checkbox
            onCheck={() => handleSelection(row.id, row.guid)}
            checked={includes(selectedGuids, props.value.guid)}
          />
        </div>
      );
    },
    sortable: false,
  },
  {
    pivot: true,
  },
  {
    Header: 'Group Name',
    accessor: 'name',
    // eslint-disable-next-line react/prop-types
    PivotValue: ({ value }) => React.createElement(NameSpecialityIcon, { ...JSON.parse(value) }),
  },
  {
    Header: 'E-shop',
    accessor: 'export',
    maxWidth: 90,
    width: 90,
    pivot: true,
    Cell: (props) => {
      const row = get(props, 'original.columns');
      let isLoading = false;
      return row && (
        <ActiveButton
          id={row.id}
          status={isLoading || row.export}
          onClick={() => {
            isLoading = true;
            onEshopToggle(row.id, !row.export);
          }}
          anchor="export-anchor"
        />
      );
    },
    Aggregated: (props) => {
      // group
      const row = props.row._subRows[0]._original;
      let isLoading = false;
      return (
        <ActiveButton
          id={props.id}
          status={isLoading || row.columns.export}
          onClick={() => {
            isLoading = true;
            const exportArray = props.row._subRows.map((rr) => ({
              id: rr.id,
              export: !row.columns.export,
            }));
            onGroupExport2Shop(row.guid, exportArray);
          }}
          anchor="export-anchor"
        />
      );
    },
  },
  {
    Header: 'Date',
    accessor: 'date',
    aggregate: (vals) => {
      const parsed = map(vals, (val) => JSON.parse(val));
      const parsedDateFrom = map(parsed, (item) => moment(item.dateFrom));
      const parsedDateTo = map(parsed, (item) => moment(item.dateTo));

      const min = formatDate(moment.min(parsedDateFrom));
      const max = formatDate(moment.max(parsedDateTo));
      return min.concat(' - ', max);
    },
    Aggregated: (props) => <div>{props.value}</div>,
    Cell: (props) => {
      let val = props.value;
      if (isJsonString(props.value)) {
        const parsed = JSON.parse(props.value);
        val = parsed.dateFrom.concat(' - ', parsed.dateTo);
      }

      return (
        <div>{val}</div>
      );
    },
    sortable: true,
  },
  {
    Header: 'Lesson Time',
    accessor: 'time',
    aggregate: (vals) => {
      const parsed = map(vals, (val) => JSON.parse(val));
      const min = minBy(parsed, 'timeFrom').timeFrom;
      const max = maxBy(parsed, 'timeTo').timeTo;

      return min.concat(' - ', max);
    },
    Aggregated: (props) => <div>{props.value}</div>,
    Cell: (props) => {
      let val = props.value;
      if (isJsonString(props.value)) {
        const parsed = JSON.parse(props.value);
        val = parsed.timeFrom.concat(' - ', parsed.timeTo);
      }

      return (
        <div>{val}</div>
      );
    },
    sortable: false,
  },
  {
    Header: 'Age',
    accessor: 'age',
    aggregate: (vals) => {
      const parsed = map(vals, (val) => JSON.parse(val));
      const min = minBy(parsed, 'minAge').minAge;
      const max = maxBy(parsed, 'maxAge').maxAge;

      return min.concat(' - ', max);
    },
    Aggregated: (props) => <div>{props.value}</div>,
    Cell: (props) => {
      let val = props.value;
      if (isJsonString(props.value)) {
        const parsed = JSON.parse(props.value);
        val = parsed.minAge.concat(' - ', parsed.maxAge);
      }

      return (
        <div>{val}</div>
      );
    },
    sortable: true,
  },
  {
    Header: 'Instructors',
    accessor: 'instructors',
    Cell: (props) => {
      let val = props.value;

      if (typeof val !== 'string') {
        val = val.join(', ');
      }
      return (
        <div>{val}</div>
      );
    },
    aggregate: (vals) => {
      let array = [];
      forEach(vals, (val) => {
        array = array.concat(val);
      });
      return uniq(array).join(', ');
    },
    Aggregated: (props) => <div>{props.value}</div>,
    sortable: false,
  },
  {
    Header: 'Language',
    accessor: 'language',
    aggregate: (vals) => vals[0],
    Aggregated: (props) => <div>{props.value}</div>,
    Cell: (props) => (<div>{props.value}</div>),
    sortable: true,
  },
  {
    Header: '',
    accessor: 'id',
    Aggregated: (props) => {
      const guid = get(props, 'subRows[0]._original.guid');

      return (
        <span onClick={() => handleEdit(guid, '*')}><EditEntityButton /></span>
      );
    },
    Cell: (props) => {
      const guid = get(props, 'original.guid');
      return (
        <div>
          <span
            onClick={() => handleEdit(guid, props.value)}
          >
            <EditEntityButton />
          </span>
          <DeleteEntityButton
            id={props.value}
            handleDelete={() => openDialog(() => handleDeletion(props.value))}
          />
        </div>
      );
    },
    sortable: true,
  },
];

export default GroupListColumns;
/* eslint-enable */
