import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import FlatButton from 'material-ui/FlatButton';
import { TextField, Toggle } from 'redux-form-material-ui';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import MinusButton from '../components/MinusButton';
import '../styles/ProductAdd.scss';

class InstructorSalaryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
      taxes: [{}],
      deletedListId: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { initialValues } = props;
    if (initialValues && initialValues.taxes) {
      return {
        ...state,
        taxes: initialValues.taxes,
      };
    }
    return state;
  }

  handleAddButton = () => {
    const { taxes } = this.state;
    taxes.push({});
    this.setState({
      taxes,
    });
  }

  handleMinusButton = (id) => () => {
    const { deletedListId } = this.state;
    deletedListId.push(id);
    this.setState({
      deletedListId,
    });
  }

  handleUpdate = (values) => {
    const { deletedListId } = this.state;
    const { massAction, handleUpdate, instructorsArray } = this.props;
    const taxes = [];
    _.forEach(values.taxes, (tax, index) => {
      if (tax) {
        if (!_.includes(deletedListId, index)) {
          taxes.push({ ...tax, percent: parseFloat(tax.percent) });
        }
      }
    });
    const wageType = values.wageType ? 'hourly' : 'fixed';
    const bonusType = values.bonusType ? 'hourly' : 'fixed';
    const bonusHours = values.bonusHours ? values.bonusHours : 0;
    const body = {
      ...values,
      taxes,
      wageType,
      bonusType,
      bonusHours,
    };
    if (!massAction) {
      const instructorId = values.instructors[0].id;
      return handleUpdate(body, instructorId).then((response) => {
        if (response) {
          if (response.data.constraintViolations) {
            const field = response.data.constraintViolations[0].property;
            const error = response.data.constraintViolations[0].message;
            throw new SubmissionError({ [field]: error });
          }
        }
      });
    }
    _.forEach(instructorsArray, (instructor) => {
      handleUpdate(body, instructor.id);
    });
    return Promise.resolve();
  }

  render() {
    const {
      handleSubmit,
      bonusType,
      currency,
      submitting,
    } = this.props;
    const { taxes, deletedListId } = this.state;
    return (
      <form
        className="instructor-salary-form"
        onSubmit={handleSubmit(this.handleUpdate)}
      >
        <div className="instructor-salary-form__input-section">
          <label className="instructor-salary-form__input-label">Fixed</label>
          <div className="instructor-salary-form__toggle">
            <Field
              component={Toggle}
              name="wageType"
              label="Hourly"
              labelPosition="right"
              parse={Boolean}
            />
          </div>
        </div>
        <div className="instructor-salary-form__input-section">
          <div>Wage:*</div>
          <Field
            name="wage"
            component={TextField}
            type="number"
            min={0}
            step={0.1}
          />
          <div>{currency}</div>
        </div>
        <div className="instructor-salary-form__title">Bonus / Deduction</div>
        <div className="instructor-salary-form__input-section">
          <label className="instructor-salary-form__input-label">Fixed</label>
          <div className="instructor-salary-form__toggle">
            <Field
              component={Toggle}
              name="bonusType"
              label="Hourly"
              labelPosition="right"
              parse={Boolean}
            />
          </div>
        </div>
        {!bonusType
          ? (
            <div className="instructor-salary-form__input-section">
              <div>Fixed:*</div>
              <Field
                name="bonus"
                component={TextField}
                type="number"
                step={0.1}
              />
              <div>{currency}</div>
            </div>
          )
          : (
            <div className="instructor-salary-form__input-section">
              <label className="instructor-salary-form__input-label">Hours:</label>
              <Field
                name="bonusHours"
                component={TextField}
                type="number"
                step={0.5}
                min={0}
              />
              <div style={{ marginRight: '25px' }}>h</div>
              <div className="instructor-salary-form__input-label">Bonus:*</div>
              <Field
                name="bonus"
                component={TextField}
                type="number"
                step={0.1}
              />
              <div>{currency}</div>
            </div>
          )}
        <div className="instructor-salary-form__title">Taxes</div>
        <div>
          {_.map(taxes, (tax, index) => (!_.includes(deletedListId, index) && tax ? (
            <div key={index} className="instructor-salary-form__taxes-section">
              <Field
                name={`taxes[${index}].name`}
                component={TextField}
                type="text"
              />
              <Field
                name={`taxes[${index}].percent`}
                component={TextField}
                type="number"
                step={0.00001}
                max={100}
                min="0"
              />
              <span style={{ marginRight: '25px' }}>%</span>
              <span onClick={this.handleMinusButton(index)}><MinusButton /></span>
            </div>
          ) : null))}
          <FloatingActionButton
            className="client-manager-form__plus-button"
            mini
            type="button"
            backgroundColor="#01579B"
            onClick={this.handleAddButton}
          >
            <ContentAdd />
          </FloatingActionButton>
        </div>
        <FlatButton
          label="UPDATE"
          primary
          type="submit"
          className="instructor-salary-form__button"
          disabled={submitting}
        />
      </form>
    );
  }
}

const formConfig = {
  form: 'InstructorSalaryForm',
};

const selector = formValueSelector('InstructorSalaryForm');
const mapStateToProps = (state, props) => {
  const initialValues = props.massAction ? null : {
    ...props.initialValues,
    wageType: props.initialValues.wageType !== 'fixed',
    bonusType: props.initialValues.bonusType !== 'fixed',
  };
  return {
    initialValues,
    bonusType: selector(state, 'bonusType'),
  };
};

InstructorSalaryForm.propTypes = {
  initialValues: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    taxes: PropTypes.array,
  }),
  massAction: PropTypes.bool,
  handleUpdate: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  instructorsArray: PropTypes.array,
  handleSubmit: PropTypes.func,
  bonusType: PropTypes.bool,
  currency: PropTypes.string,
  submitting: PropTypes.bool,
};

export default connect(mapStateToProps)(reduxForm(formConfig)(InstructorSalaryForm));
