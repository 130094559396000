import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import calendar from './calendar';
import booking from './booking';
import seasons from './seasonsReducer';
import user from './usersReducer';
import drawer from './drawerReducer';
import products from './productsReducer';
import formReducer from './formReducer';
import entities from './entitiesReducer';
import events from './eventsReducer';
import instructorSalary from './instructorSalaryReducer';
import dialog from './dialogReducer';
import error from './errorReducer';
import refreshIndicator from './refreshIndicatorReducer';
import productTimes from './productTimesReducer';
import userGuide from './userGuide';
import usedAmounts from './usedAmounts';
import invoices from './invoicesReducer';

const reducers = combineReducers({
  user,
  drawer,
  dialog,
  notifications,
  booking,
  calendar,
  products,
  entities,
  events,
  seasons,
  instructorSalary,
  error,
  refreshIndicator,
  productTimes,
  form: formReducer,
  userGuide,
  usedAmounts,
  invoices,
});

export default reducers;
