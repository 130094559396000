import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Dialog from '../../components/dialog/Dialog';
import ModalContent from './ModalContent';
import SuccessUpload from './SuccessUpload';
import FailureUpload from './FailureUpload';
import { parseBase64Body } from '../../utils/helpers';

import '../../styles/UploadModal.scss';

class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docBase64: '',
      Content: <ModalContent />,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open } = nextProps;
    const { open: openPrev } = this.props;

    if (openPrev && !open) {
      this.setState({ Content: <ModalContent />, docBase64: '' });
    }
  }

  handleUpload = () => {
    const { uploadFn } = this.props;
    const { docBase64 } = this.state;

    this.setState({ loading: true });
    uploadFn({
      data: parseBase64Body(docBase64),
      discardAllOnFailure: true,
    }).then(() => this.handleSuccessResponse()).catch((err) => this.handleFailureResponse(err));
  };

  onDrop = (docBase64) => this.setState({ docBase64 });

  handleSuccessResponse = () => {
    const { onSuccessCallback } = this.props;

    this.setState({ Content: <SuccessUpload />, loading: false });
    onSuccessCallback();
  };

  handleFailureResponse = (err) => {
    const errors = get(err, 'data.constraintViolations');
    const error = 'Something went wrong';

    this.setState({ Content: <FailureUpload error={error} errors={errors} />, docBase64: '', loading: false });
  };

  handleTryAgain = () => this.setState({ Content: <ModalContent /> });

  render() {
    const {
      open = false,
      handleUploadModalState,
      templateName,
      templateLocation,
    } = this.props;
    const { Content, docBase64, loading } = this.state;

    return (
      <Dialog
        modal={false}
        open={open}
        className="upload-modal-dialog"
      >
        <div className="upload-modal">
          <i
            className="material-icons"
            style={{
              color: '#00559D', float: 'right', padding: '2%', cursor: 'pointer',
            }}
            onClick={() => handleUploadModalState()}
          >
            close
          </i>
          {React.cloneElement(Content, {
            handleUpload: this.handleUpload,
            handleTryAgain: this.handleTryAgain,
            onDrop: this.onDrop,
            templateName,
            docBase64,
            loading,
            templateLocation,
          })}
        </div>
      </Dialog>
    );
  }
}

UploadModal.propTypes = {
  open: PropTypes.bool,
  uploadFn: PropTypes.func,
  onSuccessCallback: PropTypes.func,
  handleUploadModalState: PropTypes.func,
  templateName: PropTypes.string,
  templateLocation: PropTypes.string,
};

export default UploadModal;
