import { schema } from 'normalizr';
// eslint-disable-next-line import/no-cycle
import bookingSchema from './Booking';
// eslint-disable-next-line import/no-cycle
import lessonSchema from './Lesson';

const lessonBlockSchema = new schema.Entity('lessonBlock', { idAttribute: 'id' });

lessonBlockSchema.define({
  booking: bookingSchema,
  lessons: new schema.Array(lessonSchema),
});

export default lessonBlockSchema;
