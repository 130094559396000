import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Popover.scss';

const Popover = ({
  children,
  content,
  offsetX,
  offsetY,
  onClose,
  open,
}) => {
  if (!open) return children;
  return (
    <div className="popover">
      <div className="popover__overlay" onClick={onClose} />
      <div className="popover__content" style={{ left: offsetX || '0px', top: offsetY || '0px' }}>
        {content}
      </div>
      {children}
    </div>
  );
};

Popover.propTypes = {
  offsetX: PropTypes.string,
  offsetY: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.element,
  content: PropTypes.element,
};

export default Popover;
