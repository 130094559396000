import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from 'material-ui/Popover';
import LabelActivity from './LabelActivity';
import '../styles/Tag.scss';

class Tag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };

    this.activity = React.createRef();
  }

  handleCloseLabel = () => {
    this.setState({
      open: false,
    });
  }

  handleShowLabel = () => {
    this.setState({
      anchorEl: this.activity.current,
      open: true,
    });
  }

  render() {
    const { value, colorName, specialities } = this.props;
    const { open, anchorEl } = this.state;
    return (
      <div>
        <div className={['tag', colorName].join(' ')}>
          <span
            ref={this.activity}
            onClick={this.handleShowLabel}
            style={{ cursor: 'pointer' }}
          >
            {value}
          </span>
        </div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleCloseLabel}
        >
          <LabelActivity specialities={specialities} />
        </Popover>
      </div>
    );
  }
}

Tag.propTypes = {
  value: PropTypes.string,
  colorName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  specialities: PropTypes.object,
};

export default Tag;
