/* eslint-disable */
import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { includes } from 'lodash';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import skiThemeLessonForm from '../../styles/theme/skiThemeLessonForm';
import '../../styles/BookingList.scss';
import EditEntityButton from '../../components/EditEntityButton';
import DownloadEntityButton from '../../components/DownloadEntityButton';
import SpecialityIcon from '../../components/SpecialityIcon';
import Popover from '../../components/Popover';
import TableLessonLabel from '../../components/TableLessonLabel';
import ActiveIcon from "../../components/ActiveIcon";
import InActiveIcon from "../../components/InActiveIcon";

const isSelected = (selectedItems, id, selectAll) => {
  if ((selectAll && !includes(selectedItems, id)) || (!selectAll && includes(selectedItems, id))) {
    return true;
  }
  if (selectAll && includes(selectedItems, id)) {
    return false;
  }
  return false;
};

const bookingListColumns = (
  handleSelection,
  selectedItems,
  selectAll,
  handleEditButton,
  handleInvoiceButton,
  handleNotifyDialogButton,
) => [
  {
    Header: (
      <div>
        <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
          <Checkbox
            onCheck={() => handleSelection('*')}
            checked={selectAll}
          />
        </MuiThemeProvider>
      </div>
    ),
    accessor: 'id',
    Cell: (props) => (
      <div>
        <Checkbox
          onCheck={() => handleSelection(props.value)}
          checked={isSelected(selectedItems, props.value, selectAll)}
        />
      </div>
    ),
    sortable: false,
  },
  {
    Header: 'ID',
    accessor: 'bid',
    Cell: (props) => (
      <span
        onClick={() => handleEditButton(props.original.id)}
        className="edit-booking"
      >
        B-
        {props.value}
      </span>
    ),
  },
  {
    Header: 'Date',
    accessor: 'dateFrom',
    Cell: (row) => (
      <span>
        {moment(row.value).format('DD/MMM/YYYY')}
        {' '}
        -
        {' '}
        {moment(row.original.dateTo).format('DD/MMM/YYYY')}
      </span>
    ),
  },
  {
    Header: 'Client',
    accessor: 'client',
    sortMethod: (a, b) => (a.name > b.name ? 1 : -1),
    Cell: (row) => {
      if (!row.value.id) {
        return (
          <span>-</span>
        );
      }
      return (
        <Link to={{ pathname: `/clients/${row.value.id.id}/overview` }}>{row.value.name}</Link>
      );
    },
  },
  {
    Header: 'Lesson',
    accessor: 'lesson',
    sortable: false,
    Cell: (props) => {
      const [showPopover, setShowPopover] = useState(false);
      const values = props.value;
      if (values.length === 1) {
        return (
          <div className="lesson-column">
            <span>{values[0].instructorFullName}</span>
            <span>{values[0].productName}</span>
            {values[0].productSpeciality
              ? (
                <SpecialityIcon
                  speciality={values[0].productSpeciality}
                  level={values[0].productLevel}
                  activity={values[0].productActivity}
                />
              ) : null}
          </div>
        );
      }
      return (
        <Popover
          open={showPopover}
          onClose={() => setShowPopover(false)}
          content={<TableLessonLabel initialValues={values} />}
        >
          <span className="lesson-column__pop-up" onClick={() => setShowPopover(true)}>
            {values.length}
            {' '}
            lessons
          </span>
        </Popover>
      );
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    sortMethod: (a, b) => (a.total > b.total ? 1 : -1),
    Cell: (props) => (
      <span>
        {props.value.currency}
        {' '}
        {props.value.total}
      </span>
    ),
  },
  {
    Header: 'Manager',
    accessor: 'partnerManager',
    Cell: (props) => <span>{props.value}</span>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (props) => <span className="table-alignment">{props.value}</span>,
  },
  {
    Header: 'Paid',
    accessor: 'paid',
    Cell: (props) => {
      if(props.value)
        return <ActiveIcon />
      return <InActiveIcon />
    },
  },
  {
    Header: '',
    accessor: 'id',
    sortable: false,
    Cell: (props) => (
      <div className="list-table__column-content--inline">
        <span onClick={() => handleEditButton(props.value)}><EditEntityButton /></span>
        <span onClick={() => handleInvoiceButton(props.value)}><DownloadEntityButton /></span>
        <span
            onClick={() => handleNotifyDialogButton(props.value)}
            style={{marginLeft: '3px', lineHeight: 1, cursor: 'pointer'}}
            hidden={!props.original.paid || moment(props.original.dateFrom).isBefore()}
        > @ </span>
      </div>
    ),
  },
];

export default bookingListColumns;
/* eslint-enable */
