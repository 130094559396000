import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({
  className,
  multiple,
  accept,
  maxSize,
  onDrop,
  children,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    accept,
    maxSize,
    onDrop,
    noClick: false,
    noKeyboard: true,
  });

  return (
    <div {...getRootProps({ className })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

Dropzone.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  onDrop: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
};

export default React.memo(Dropzone);
