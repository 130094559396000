/* eslint-disable */
import React from 'react';
import moment from 'moment';
import {
  find,
  filter,
  get,
  map,
  values,
  clone,
  keyBy,
  maxBy,
  minBy,
  isEmpty,
} from 'lodash';
import { mapInstructorsToValue } from '../utils/map';
import { convertToUTC, convertToDate } from '../utils/dateTime';
import { getGroupPromises } from '../utils/formHelpers';

class Component extends React.Component {
  initializeAutoPrefilledData = (lesson) => {
    const { user, activity, speciality } = this.props;
    const { account: { resort } } = user;

    const activityFiltered = find(activity, (a) => a.name === 'SKI');
    const specialitiesFiltered = filter(speciality, (s) => s.activity === activityFiltered.id);
    const specialityFiltered = find(specialitiesFiltered, (s) => s.name === 'Standard');

    return {
      ...lesson,
      resort: { label: resort.name, value: resort.id },
      activity: lesson.activity ? lesson.activity : get(activityFiltered, 'id', null),
      speciality: lesson.speciality ? lesson.speciality : get(specialityFiltered, 'id', null),
      dateFrom: lesson.dateFrom ? lesson.dateFrom : new Date().toISOString(),
      dateTo: lesson.dateTo ? lesson.dateTo : new Date().toISOString(),
      clientAmount: 1,
    };
  }

  getPrepopulatedLesson() {
    const filter = JSON.parse(localStorage.getItem('filter')) || {};
    let values = {
      ...filter,
      dateFrom: filter.timeFrom ? new Date(filter.timeFrom) : null,
      dateTo: filter.timeTo ? new Date(filter.timeTo) : null,
      timeFrom: null,
      timeTo: null,
    };
    if (filter.instructors && filter.instructors.length > 0) {
      if (!filter.type || (filter.type && filter.type.id === 'individual')) {
        values = {
          ...values,
          instructor: filter.instructors[0].id,
        };
      } else {
        values = {
          ...values,
          instructor: filter.instructors || [],
        };
      }
    }
    if (filter.group) {
      values = {
        ...filter,
        group: this.props.group[filter.group].guid,
      };
    }
    return this.initializeAutoPrefilledData(values);
  }

  resolveEntity = (entityType, id, callback) => {
    const entity = get(this.props, `${entityType}`, null);

    return new Promise((resolve) => {
      if (entity && get(entity, `${id}.name`, null)) {
        resolve(entity[id]);
      } else {
        callback(id).then((response) => {
          resolve(response.payload.result[entityType][id]);
        });
      }
    });
  }

  resolveLessonBlock = (lessonBlock) => ({
    ...lessonBlock,
    edit: true,
    type: lessonBlock.type,
    discount: lessonBlock.discountType === 'percent' ? `${lessonBlock.discount}%` : lessonBlock.discount,
    lessons: map(lessonBlock.lessons, (lesson) => {
      let entity = {
        ...lesson,
        dateFrom: convertToDate(lesson.timeFrom),
        dateTo: convertToDate(lesson.timeTo),
        timeFrom: convertToDate(lesson.timeFrom),
        timeTo: convertToDate(lesson.timeTo),
        group: !isEmpty(lesson.groups) ? {
          id: lesson.groups[0].guid,
          name: lesson.groups[0].name,
        } : null,
        buyers: lesson.buyers.map((b) => { delete b.lesson; return b; }),
      };
      if (lessonBlock.type === 'group') {
        const instructors = get(lesson, 'groups[0].instructors', []);
        entity = {
          ...entity,
          instructor: map(instructors, (instructor) => mapInstructorsToValue(instructor)),
        };
      }

      entity.lessonBlock = entity.lessonBlock.id;
      delete entity.groups;
      delete entity.booking;
      return entity;
    }),
  })

  fromFilter = (values) => {
    const { user } = this.props;
    const currency = get(user, 'account.currency', '');

    return new Promise((resolve) => {
      resolve({
        edit: false,
        currency,
        initialValues: {
          prepaymentType: null,
          fullpaymentType: null,
          partnerManagerCommission: false,
          lessonBlocks: [
            {
              edit: false,
              type: values.type || 'individual',
              lessons: [{
                ...this.getPrepopulatedLesson(),
                paid: false,
                buyers: [
                  { level: null },
                ],
              }],
            },
          ],
        },
      });
    });
  }

  fromAvailability = (item) => new Promise((resolve) => {
    const dateFrom = convertToDate(moment(convertToUTC(item.timeFrom)));
    const dateTo = convertToDate(moment(convertToUTC(item.timeTo)));

    resolve({
      edit: false,
      currency: this.props.user.account.currency,
      initialValues: {
        prepaymentType: null,
        fullpaymentType: null,
        partnerManagerCommission: false,
        lessonBlocks: [
          {
            edit: false,
            type: 'individual',
            lessons: [{
              ...this.getPrepopulatedLesson(),
              paid: false,
              buyers: [
                { level: null },
              ],
              instructors: item.instructor,
              dateFrom,
              dateTo,
              timeFrom: dateFrom,
              timeTo: dateTo,
            }],
          },
        ],
      },
    });
  })

  fromBooking = (id) => {
    const { actions: { fetchBooking, fetchGroupByGuid }, user } = this.props;
    return new Promise((resolve) => {
      this.resolveEntity('booking', id, fetchBooking).then((booking) => {
        const { lessonBlocks, bookingServices, sendClientInvoiceToManager } = booking;
        const promises = getGroupPromises(lessonBlocks, fetchGroupByGuid);
        const _lessonBlocks = lessonBlocks.map((block) => this.resolveLessonBlock(block));
        Promise.all(promises).then(() => {
          resolve({
            edit: true,
            currency: user.account.currency,
            initialValues: {
              ...booking,
              bookingServices: map(bookingServices, (bookingService) => ({
                ...bookingService,
                discount: bookingService.discountType === 'percent' ? `${bookingService.discount}%` : bookingService.discount,
              })),
              lessonBlocks: _lessonBlocks,
            },
            openDialog: this.props.openDialog,
            sendClientInvoiceToManager,
          });
        });
      });
    });
  }

  fromGroup = (guid, id) => {
    const { actions: { fetchGroupPrice, fetchGroupByGuid } } = this.props;

    return new Promise((resolve) => {
      let groups = [];
      fetchGroupByGuid(guid).then((res) => {
        groups = map(values(res.payload.result.group), (group) => ({
         ...group,
         resort: find(res.payload.result.resort, (resort) => resort.id === group.resort),
        }));
        const firstGroupInstance = {
          ...clone(groups[0]),
          id: '*',
          timeFrom: minBy(groups, 'timeFrom').timeFrom,
          timeTo: maxBy(groups, 'timeTo').timeTo,
          repeat: null,
          days: [],
        };
        groups.push(firstGroupInstance);
        groups = keyBy(groups, 'id');
        fetchGroupPrice(guid).then((priceRes) => {
          resolve({
            initialValues: {
              activeGroup: id,
              groups,
              guid,
              groupPrice: values(priceRes.payload.result.groupPrice),
            },
            initialResorts: res.payload.result.resort,
          });
        });
      });
    });
  }
}

export default Component;
