import { schema } from 'normalizr';
import instructorSchema from './Instructor';
// eslint-disable-next-line import/no-cycle
import bookingSchema from './Booking';
import productSchema from './Product';
import levelSchema from './Level';
import activitySchema from './Activity';
import buyerSchema from './Buyer';
import groupSchema from './Group';
// eslint-disable-next-line import/no-cycle
import lessonBlockSchema from './LessonBlock';

const lessonSchema = new schema.Entity('lessons', { idAttribute: 'id' });

lessonSchema.define({
  booking: bookingSchema,
  instructor: instructorSchema,
  product: productSchema,
  level: levelSchema,
  activity: activitySchema,
  buyer: buyerSchema,
  group: groupSchema,
  lessonBlock: lessonBlockSchema,
});

export default lessonSchema;
