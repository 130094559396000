import React from 'react';
import PropTypes from 'prop-types';

const Individual = ({ event, onItemDoubleClick }) => (
  <div className={`event ${event.paid ? 'event--paid' : 'event--not-paid'}`} onClick={() => onItemDoubleClick(event)} style={{ cursor: 'pointer' }}>
    <div className="event-row--individual--time">
      {event && `${event.timeFrom} - ${event.timeTo}`}
    </div>
    <div className="event-row--individual">
      <div className="event-column  event-column--bold">
        {event && event.bid}
      </div>
      <div className="event-column">
        {event && event.client}
      </div>
    </div>
  </div>
);

Individual.propTypes = {
  event: PropTypes.shape({
    paid: PropTypes.bool,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    bid: PropTypes.string,
    client: PropTypes.number,
  }),
  onItemDoubleClick: PropTypes.func,
};

export default React.memo(Individual);
