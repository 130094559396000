import React from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumber } from '../utils/helpers';
import { LANGUAGES } from '../common/constants';

const CalendarItemPopover = ({
  activityName,
  specialityName,
  specialClientNeeds,
  language,
  phoneNumbers,
  hotel,
  clientAmount,
  meetingPoint,
  group,
  bookingId,
  groupInfo,
}) => {
  if (!group) {
    return (
      <div className="calendar-item-popup">
        <div className="data-row">
          <span className="data-column data-column-left">
            Meeting point:
          </span>
          <span className="data-column data-column-right">
            {meetingPoint && meetingPoint}
          </span>
        </div>
        <div className="data-row">
          <span className="data-column data-column-left">
            Phone number:
          </span>
          <span className="data-column data-column-right">
            {phoneNumbers && parsePhoneNumber(phoneNumbers)}
          </span>
        </div>
        <div className="data-row">
          <span className="data-column data-column-left">
            Client amount:
          </span>
          <span className="data-column data-column-right">
            {clientAmount && clientAmount}
          </span>
        </div>
        <div className="data-row">
          <span className="data-column data-column-left">
            Hotel:
          </span>
          <span className="data-column data-column-right">
            {hotel && hotel}
          </span>
        </div>
        <div className="data-row">
          <span className="data-column data-column-left">
            Language:
          </span>
          <span className="data-column data-column-right">
            {`${LANGUAGES[language]}(${language.toUpperCase()})`}
          </span>
        </div>
        <div className="data-row">
          <span className="data-column data-column-left">
            Activity/Speciality:
          </span>
          <span className="data-column data-column-right">
            {activityName || 'No activity'}
            /
            {specialityName || 'No speciality'}
          </span>
        </div>
        <div className="data-row">
          <span className="data-column data-column-left">
            Special Needs:
          </span>
          <span className="data-column data-column-right">
            {specialClientNeeds}
          </span>
        </div>
      </div>
    );
  }
  if (groupInfo) {
    const groupsData = groupInfo[bookingId][0];

    return (
      <div className="calendar-item-popup">
        {
          groupsData.client && (
            <div>
              <div className="data-row">
                <span className="data-column data-column-left">
                  Client:
                </span>
                <span className="data-column data-column-right">
                  {`${groupsData.client.name} ${groupsData.client.surname}`}
                </span>
              </div>
              <div className="data-row">
                <span className="data-column data-column-left">
                  Phone number:
                </span>
                <span className="data-column data-column-right">
                  {groupsData.client.phoneNumbers}
                </span>
              </div>
              <br />
            </div>
          )
        }
        {
          groupsData.partnerManager && (
            <div>
              <div className="data-row">
                <span className="data-column data-column-left">
                  Partner manager:
                </span>
                <span className="data-column data-column-right">
                  {`${groupsData.partnerManager.name} ${groupsData.partnerManager.surname}`}
                </span>
              </div>
              <div className="data-row">
                <span className="data-column data-column-left">
                  Phone number:
                </span>
                <span className="data-column data-column-right">
                  {groupsData.partnerManager.phoneNumbers}
                </span>
              </div>
            </div>
          )
        }
      </div>
    );
  }
  return <div />;
};

CalendarItemPopover.propTypes = {
  activityName: PropTypes.string,
  specialityName: PropTypes.string,
  specialClientNeeds: PropTypes.string,
  language: PropTypes.string,
  phoneNumbers: PropTypes.string,
  hotel: PropTypes.string,
  clientAmount: PropTypes.number,
  meetingPoint: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  group: PropTypes.object,
  bookingId: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  groupInfo: PropTypes.object,
};

export default React.memo(CalendarItemPopover);
