import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../styles/EventLabel.scss';

const TaxesLabel = ({ initialValues }) => (
  <div className="event-label">
    <div className="event-label__wrapper">
      <div className="event-label__row">
        <div className="event-label__label">
          {_.map(initialValues, (tax) => <div>{`./. ${tax.percent}% + ${tax.name}`}</div>)}
        </div>
      </div>
    </div>
  </div>
);

TaxesLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
};

export default React.memo(TaxesLabel);
