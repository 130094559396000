import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { get } from 'lodash';
// Material ui
import { TextField } from 'redux-form-material-ui';
import { FlatButton } from 'material-ui';
import RefreshIndicator from 'material-ui/RefreshIndicator';
// Dependencies
import * as userActions from '../actions/userActions';
import ForgotPassword from '../components/ForgotPassword';
// Styles
import '../styles/LoginForm.scss';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      dialogOpen: false,
      status: 'hide',
      userGuide: false,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const confirmation = urlParams.get('confirmation');
    if (confirmation === 'success') {
      this.setState({
        message: 'Congratulations, your account has been activated successfully',
        userGuide: true,
      });
    } else if (confirmation === 'changed') {
      this.setState({
        message: 'Your password was changed successfully',
      });
    }
  }

  onSubmit = (values) => {
    const { email, password } = values;
    const { userGuide, message: stateMessage } = this.state;
    const { actions, history } = this.props;

    this.setState({
      status: 'loading',
    });

    return actions.user.login(email, password).then((response) => {
      const user = response.payload.result;

      if (user && user.accessToken && user.role) {
        localStorage.setItem('user', JSON.stringify(user));
        switch (user.role) {
          case 'manager': {
            const route = userGuide ? '/calendar?user-guide=active' : '/calendar';
            history.push(route);
            return;
          }
          case 'instructor':
            history.push('/availability');
            return;
          case 'headCoach':
            history.push('/availability');
            return;
          case 'admin':
            history.push('/accounts');
            return;
          default:
            history.push('/login');
        }
      }
    }).catch((er) => {
      this.setState({
        status: 'hide',
      });
      const message = get(er, 'data.constraintViolations[0].message', '');
      if (stateMessage) {
        this.setState({
          message: '',
        });
      }
      throw new SubmissionError({ _error: message });
    });
  }

  closeMessage = () => {
    this.setState({
      message: '',
    });
  }

  onForgotPasswordClick = () => {
    const { dialogOpen } = this.state;
    this.setState({
      dialogOpen: !dialogOpen,
    });
  };

  render() {
    const { handleSubmit, error } = this.props;
    const { message, dialogOpen, status } = this.state;

    return (
      <div className="form-page__login-form">
        <ForgotPassword
          dialogOpen={dialogOpen}
          onForgotPasswordClick={this.onForgotPasswordClick}
        />
        <form
          onSubmit={handleSubmit(this.onSubmit)}
          className={status === 'loading' ? 'login-form indicator-hidden' : 'login-form'}
        >
          <div className="login-form__input-wrapper">
            <small className="red">{error && error}</small>
            <small className="login-success-message" onClick={this.closeMessage}>{message && message}</small>
          </div>
          <div className="login-form__input-wrapper">
            <label>E-mail:</label>
            <Field
              name="email"
              type="text"
              component={TextField}
              className="login-form__email"
              hintText="username@example.com"
              underlineStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              underlineFocusStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              inputStyle={{
                color: '#253D63',
                fontSize: '16px',
                lineHeight: '24px',
              }}
            />
          </div>
          <div className="login-form__input-wrapper">
            <label>Password:</label>
            <Field
              name="password"
              type="password"
              component={TextField}
              className="login-form__password"
              hintText="Your password"
              underlineStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              underlineFocusStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              inputStyle={{
                color: '#253D63',
                fontSize: '16px',
                lineHeight: '24px',
              }}
            />
          </div>
          <div className="login-form__input-wrapper">
            <p className="forgot-password" onClick={this.onForgotPasswordClick.bind(this)}>Forgot password?</p>
          </div>
          <div className="login-form__submit-wrapper">
            <FlatButton
              type="submit"
              label="LOG IN"
              className="login-form__submit"
              labelStyle={{
                fontSize: '24px',
                color: '#FFFFFF',
                fontFamily: 'Ubuntu, sans-serif',
                fontWeight: '400',
              }}
              style={{
                backgroundColor: '#1E88E5',
                borderRadius: '7px',
                color: '#FFFFFF',
                height: '50px',
                margin: '0 auto',
                width: '289px',
                display: 'block',
              }}
            />
          </div>
        </form>
        <RefreshIndicator
          size={300}
          left={550}
          top={300}
          status={status}
          className={status !== 'loading' ? 'indicator-hidden' : 'indicator-shown indicator-shown__login'}
        />
      </div>
    );
  }
}

LoginForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  actions: PropTypes.shape({
    user: PropTypes.shape({
      login: PropTypes.func,
    }),
  }),
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
};

const formConfig = {
  form: 'login',
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    user: bindActionCreators(userActions, dispatch),
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(LoginForm)));
