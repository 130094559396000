import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Drawer from 'material-ui/Drawer';
import PropTypes from 'prop-types';
import AuthoredNav from './AuthoredNav';
import { removeMenuOpen, activateGuide } from '../actions/userGuide';

import '../styles/Sidemenu.scss';

const style = {
  close: {
    textAlign: 'right',
    top: '20px',
    right: '20px',
    position: 'absolute',
  },
};

class Sidemenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentDidUpdate() {
    const { userGuide, removeMenuOpenA } = this.props;

    if (userGuide && userGuide.triggerMenuOpen) {
      this.handleToggle();
      removeMenuOpenA();
    }
  }

  handleToggle = () => {
    const { userGuide, activateGuideA } = this.props;
    const { currentGuide, step, general } = userGuide;
    const { open } = this.state;

    this.setState({ open: !open });
    if (userGuide && currentGuide === 'calendar' && general && step === 9) {
      setTimeout(() => activateGuideA('schoolSettings', general, 0), 250);
    }
  };

  handleClose = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    return (
      <div className="sidemenu">
        <Drawer
          docked
          openSecondary={false}
          open={open}
          onRequestChange={(o) => this.setState({ open: o })}
          containerClassName="shadowed"
        >
          <div>
            <div style={style.close} onClick={this.handleClose}><i className="material-icons sidemenu__close">close</i></div>
            <AuthoredNav />
          </div>
        </Drawer>
        <div className="sidemenu__open">
          <div onClick={this.handleToggle}><i className="material-icons" id="menu-anchor">view_headline</i></div>
        </div>
      </div>
    );
  }
}

Sidemenu.propTypes = {
  userGuide: PropTypes.shape({
    triggerMenuOpen: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
    general: PropTypes.bool,
  }),
  removeMenuOpenA: PropTypes.func,
  activateGuideA: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userGuide: state.userGuide,
});

const mapDispatchToProps = (dispatch) => ({
  removeMenuOpenA: bindActionCreators(removeMenuOpen, dispatch),
  activateGuideA: bindActionCreators(activateGuide, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
