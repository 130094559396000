import React from 'react';
import Filter from './Filter';

const Statistics = () => (
  <div>
    <Filter />
  </div>
);

export default React.memo(Statistics);
