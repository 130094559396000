import { schema } from 'normalizr';
// eslint-disable-next-line import/no-cycle
import commissionSchema from './Commission';
// eslint-disable-next-line import/no-cycle
import partnerManagerSchema from './PartnerManager';

const partnerSchema = new schema.Entity('partner', { idAttribute: 'id' });

partnerSchema.define({
  commission: new schema.Array(commissionSchema),
  partnerManagers: new schema.Array(partnerManagerSchema),
});

export default partnerSchema;
