import { schema } from 'normalizr';

const seasonSchema = new schema.Entity('season', { idAttribute: 'id' });
const productSchema = new schema.Entity('product', { idAttribute: 'id' });

seasonSchema.define({
  product: productSchema,
});

export default seasonSchema;
