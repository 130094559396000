import _, { get } from 'lodash';

export const resolveEntity = (entities, entityType, id, callback, propertyCheck = 'name') => {
  const entity = get(entities, `${entityType}`, null);

  return new Promise((resolve) => {
    if (entity && get(entity, `${id}.${propertyCheck}`, null)) {
      resolve(entity[id]);
    } else {
      callback(id).then((response) => {
        resolve(response.payload.result[entityType][id]);
      });
    }
  });
};

export const onChange = (change, field, value, submit) => change(field, value, submit);

export const resolveSpecialityFromValue = (props, change, id, submit = true) => {
  const { fetchSpecialityA, fetchActivityA } = props;

  resolveEntity(props, 'speciality', id, fetchSpecialityA).then((speciality) => {
    resolveEntity(props, 'activity', speciality.activity, fetchActivityA).then((activity) => {
      if (activity.name !== 'Any') {
        onChange(change, 'activity', activity.id, submit);
      }
    });
    if (speciality.name !== 'Any') {
      onChange(change, 'speciality', speciality.id, submit);
    }
  });
};

export const filterSpecialities = (specialities, spec) => {
  if (!spec) {
    return [];
  }
  const { activity } = _.find(specialities, { id: spec });
  return _.filter(_.values(specialities), (speciality) => activity === speciality.activity);
};

export const types = [
  { value: 'individual', label: 'Private' },
  { value: 'group', label: 'Group' },
];

export const levels = [
  { value: 'first_steps', label: 'First steps' },
  { value: 'beginner', label: 'Beginner' },
  { value: 'intermediate', label: 'Intermediate' },
  { value: 'advanced', label: 'Advanced' },
];

export const days = [
  { name: 'sunday', label: 'Sun' },
  { name: 'monday', label: 'Mon' },
  { name: 'tuesday', label: 'Tue' },
  { name: 'wednesday', label: 'Wed' },
  { name: 'thursday', label: 'Thu' },
  { name: 'friday', label: 'Fri' },
  { name: 'saturday', label: 'Sat' },
];

export const repeat = [
  { value: 0, label: 'Every day' },
  { value: 1, label: 'Every week' },
  { value: 2, label: 'Every two weeks' },
];

export const getGroupPromises = (lessonBlocks, fetchGroupByGuid) => {
  let guids = [];
  let promises = [];
  _.forEach(lessonBlocks, (lessonBlock) => {
    if (lessonBlock.type === 'group') {
      _.forEach(lessonBlock.lessons, (lesson) => {
        _.forEach(lesson.groups, (group) => guids.push(group.guid));
      });
    }
  });
  if (guids.length) {
    guids = _.uniq(guids);
    promises = _.map(guids, (guid) => fetchGroupByGuid(guid));
  }
  return promises;
};
