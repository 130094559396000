import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import Checkbox from 'material-ui/Checkbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import * as productsActions from '../actions/productsActions';
import * as specialitiesActions from '../actions/specialityActions';
import * as notificationActions from '../actions/notificationActions';
import ListTable from '../components/ListTable';
import DeleteEntityButton from '../components/DeleteEntityButton';
import EditEntityButton from '../components/EditEntityButton';
import SpecialityIcon from '../components/SpecialityIcon';
import AddEntityButton from '../components/AddEntityButton';
import MassActionsComponent from '../components/MassActionsComponent';
import ActiveButton from '../components/ActiveButton';
import { notifySuccess, notifyError } from '../actions/notificationActions';
import '../styles/Products.scss';
import '../styles/Button.scss';
import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';
import { sortTable, isEmptyObject } from '../utils/helpers';
import * as indicatorActions from '../actions/refreshIndicatorActions';
import { goToStep } from '../actions/userGuide';

const columns = {
  1: {
    name: ['export'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
  },
  2: {
    name: ['name'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
  },
  3: {
    name: ['timeRange'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
  },
  4: {
    name: ['lessonHours', 'timeTo', 'timeFrom'], dependency: null, level: 1, orderType: 'rotational', dataArray: false,
  },
  5: {
    name: ['paidHours'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
  },
};

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      massActions: [
        { text: 'Delete', value: 0 },
      ],
      products: {},
      selectedProducts: [],
      prevProducts: {},
    };
  }

  componentDidMount() {
    const {
      actions: {
        goToStepA,
        indicatorActions: {
          startLoading,
          stopLoading,
        },
        products: { fetchProducts },
        specialities: { fetchSpecialities },
      },
      userGuide: {
        general,
        currentGuide,
        step,
      },
    } = this.props;

    startLoading('products');
    Promise.all([
      fetchProducts(),
      fetchSpecialities(),
    ]).then(() => {
      stopLoading('products');
    });

    if (general && currentGuide === 'products' && step === 0) {
      setTimeout(() => goToStepA(1), 1500);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { products } = props;

    if (!isEmptyObject(products)) {
      const productsSorted = _.orderBy(_.map(products), 'createdAt');
      const { prevProducts } = state;
      if (!_.isEqual(productsSorted, prevProducts)) {
        return {
          ...state,
          products: productsSorted,
          prevProducts: productsSorted,
        };
      }
      return state;
    }
    return {
      ...state,
      products: {},
      prevProducts: {},
    };
  }

  componentWillUnmount() {
    const { actions: { indicatorActions: { stopLoading } } } = this.props;
    stopLoading('twoColumns');
  }

  handleSingleCheck = (lessonId) => () => {
    const { selectedProducts } = this.state;
    const { products } = this.props;
    let lessonsChecked = selectedProducts;
    if (_.some(selectedProducts, { id: lessonId })) {
      lessonsChecked = _.filter(lessonsChecked, (lesson) => lesson.id !== lessonId);
      this.refs.multiCheck.state.switched = false;
    } else {
      lessonsChecked.push(products[lessonId]);
    }
    this.setState({
      selectedProducts: lessonsChecked,
    });
  }

  handleMultiCheck = (event, isInputChecked) => {
    const { products } = this.props;
    let lessonsChecked = [];
    if (isInputChecked) {
      lessonsChecked = products;
    }
    this.setState({
      selectedProducts: lessonsChecked,
    });
  }

  setMassActionType = () => {
    this.setState((state) => ({ ...state }));
  }

  handleDelete = (id) => () => {
    const {
      actions: {
        products: { deleteProduct },
        notifySuccessA,
      },
    } = this.props;
    return deleteProduct(id).then(() => {
      notifySuccessA({}, 'Product deleted successfully');
    });
  }

  handleDeleteMassAction = () => {
    const { selectedProducts } = this.state;
    const {
      actions: {
        products: { deleteProduct },
        notifySuccessA,
      },
    } = this.props;
    const undeletedProducts = [];
    const deletions = _.map(selectedProducts, (product) => deleteProduct(product.id).catch(() => {
      undeletedProducts.push(product.id);
    }));
    const results = Promise.all(deletions);
    return results.then(() => {
      if (undeletedProducts.length === 0) {
        notifySuccessA({}, 'All products deleted successfully');
      }
      this.setState({
        selectedProducts: [],
      });
    });
  }

  lessonsToRows = (lessons) => {
    const { specialities, openDialog } = this.props;
    const { selectedProducts } = this.state;
    const rows = [];
    _.forEach(lessons, (lesson) => {
      const row = [];
      const nameSpeciality = [];
      nameSpeciality[0] = lesson.name ? (
        <Link key={`link_${lesson.id}`} to={`/products/${lesson.id}`}>{lesson.name}</Link>) : '-';
      nameSpeciality[1] = lesson.speciality ? (
        <SpecialityIcon
          key={`icon_${lesson.id}`}
          speciality={specialities[lesson.speciality]}
          level={lesson.level ? lesson.level : null}
        />
      ) : '';
      let timeFrom = 'N/A';
      let timeTo = 'N/A';

      if (lesson.timeFrom && lesson.timeTo) {
        timeFrom = moment.utc(lesson.timeFrom).format('HH:mm');
        timeTo = moment.utc(lesson.timeTo).format('HH:mm');
      }

      const lessonHours = lesson.lessonHours ? `${lesson.lessonHours}h` : 'N/A';
      row[0] = (
        <Checkbox
          checked={_.some(selectedProducts, { id: lesson.id })}
          onCheck={this.handleSingleCheck(lesson.id)}
        />
      );
      row[1] = lesson.timeRange ? (
        <ActiveButton
          id={lesson.id}
          status={lesson.export}
          onClick={this.onExportButtonClick(lesson.id)}
          anchor="export-anchor"
        />
      ) : '';
      row[2] = nameSpeciality;
      row[3] = lesson.timeRange ? 'Time range' : 'Hours';
      row[4] = lesson.timeRange ? `${timeFrom} - ${timeTo}` : lessonHours;
      row[5] = lesson.paidHours ? lesson.paidHours : '-';
      row[6] = (
        <div className="list-table__column-content--inline">
          <Link to={{ pathname: `/products/${lesson.id}` }}><EditEntityButton /></Link>
          <a>
            <DeleteEntityButton
              id={lesson.id}
              handleDelete={() => openDialog(this.handleDelete(lesson.id))}
            />
          </a>
        </div>
      );
      rows.push(row);
    });
    return rows;
  }

  onExportButtonClick = (id) => () => {
    const { products: p, actions: { products: { updateProduct }, notifyErrorA } } = this.props;
    const { products } = this.state;
    const currentStatus = _.find(products, { id }).export;
    const updateStatus = !currentStatus;
    const product = p[id];

    if (product && product.speciality && product.level) {
      updateProduct(id, {
        export: updateStatus,
      });
    } else {
      notifyErrorA({}, 'Cannot enable product. Product has to have activity and speciality assigned to it');
    }
  }

  handleSortTable = (columnIndex, asc) => {
    const { products } = this.state;
    const sortedList = sortTable(products, columns, columnIndex, asc);
    this.setState({
      products: sortedList,
    });
  }

  render() {
    const { massActions, products } = this.state;
    const { refreshIndicator, openDialog } = this.props;

    return (
      <div className="lesson-types">
        <div className="group-list__row-wrapper">
          <AddEntityButton
            link
            label="Add Product"
            path="/product"
          />
          <MassActionsComponent
            massActions={massActions}
            detectMassAction={this.setMassActionType}
            handleMassAction={() => openDialog(this.handleDeleteMassAction)}
          />
        </div>
        <ListTable
          dest="product"
          names={['checkbox', 'eshop', 'lesson-name', 'type', 'time', 'instructor-hours', 'icons']}
          className={!refreshIndicator.products.cached ? 'indicator-hidden' : ''}
          header={[
            <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
              <Checkbox ref="multiCheck" onCheck={this.handleMultiCheck} />
            </MuiThemeProvider>,
            'E-shop',
            'Lesson Name',
            'Type',
            'Time',
            'Instructor hours',
            ' ',
          ]}
          rows={this.lessonsToRows(products)}
          handleSort={this.handleSortTable}
        />
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.products.cached ? 'hide' : 'loading'}
          className={refreshIndicator.products.cached ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
      </div>
    );
  }
}

Products.propTypes = {
  products: PropTypes.shape({
    createdAt: PropTypes.string,
  }),
  actions: PropTypes.shape({
    goToStepA: PropTypes.func,
    indicatorActions: PropTypes.shape({
      startLoading: PropTypes.func,
      stopLoading: PropTypes.func,
    }),
    products: PropTypes.shape({
      fetchProducts: PropTypes.func,
      deleteProduct: PropTypes.func,
      updateProduct: PropTypes.func,
    }),
    specialities: PropTypes.shape({
      fetchSpecialities: PropTypes.func,
    }),
    notifySuccessA: PropTypes.func,
    notifyErrorA: PropTypes.func,
  }),
  userGuide: PropTypes.shape({
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  specialities: PropTypes.object,
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    products: PropTypes.shape({
      cached: PropTypes.bool,
    }),
  }),
};

function mapStateToProps(state) {
  return {
    products: state.entities.product,
    specialities: state.entities.speciality,
    userGuide: state.userGuide,
    refreshIndicator: state.refreshIndicator,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      products: bindActionCreators(productsActions, dispatch),
      specialities: bindActionCreators(specialitiesActions, dispatch),
      notifications: bindActionCreators(notificationActions, dispatch),
      notifySuccessA: bindActionCreators(notifySuccess, dispatch),
      indicatorActions: bindActionCreators(indicatorActions, dispatch),
      goToStepA: bindActionCreators(goToStep, dispatch),
      notifyErrorA: bindActionCreators(notifyError, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
