import React from 'react';
import PropTypes from 'prop-types';
import '../styles/UserGuide.scss';

const GuideFinishPopup = ({ onFinishClick }) => (
  <div className="guide-activation-modal">
    <div className="header">
      This is the end of the Guide. If you have any more questions,
      do not hesitate to contact us in Technical Support.
    </div>
    <div className="buttons">
      <button className="buttons buttons__finish" onClick={onFinishClick} type="button">Finish</button>
    </div>
  </div>
);

GuideFinishPopup.propTypes = {
  onFinishClick: PropTypes.func,
};

export default React.memo(GuideFinishPopup);
