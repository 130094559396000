import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  forEach,
  values,
  map,
  find,
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import { fetchResorts } from '../actions/resortsActions';

class ResortSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  handleChange = (value) => {
    const { addResorts } = this.props;
    if (Array.isArray(value)) {
      addResorts(map(value, (val) => ({ id: val.value, name: val.label })));
    } else if (value) {
      addResorts({ id: value.value, name: value.label });
    } else {
      addResorts(null);
    }
  }

  getResorts = (input) => {
    const { fetchResortsA } = this.props;
    if (input.length < 3) {
      return Promise.resolve([]);
    }

    return fetchResortsA(input).then((response) => {
      const resortsResponse = [];
      forEach(response.payload.result.resort, (resort) => {
        resortsResponse.push({
          value: resort.id,
          label: resort.name,
        });
      });
      this.setState({
        options: resortsResponse,
      });
      return resortsResponse;
    });
  }

  renderResortOption = (resort) => {
    if (resort.value) {
      return resort;
    }
    return { value: resort.id, label: resort.name };
  }

  renderValues = (resorts) => {
    const { options } = this.state;
    if (resorts) {
      if (Array.isArray(resorts)) {
        return map(resorts, this.renderResortOption);
      }
      if (typeof resorts === 'object') {
        return this.renderResortOption(resorts);
      }
      return find(options, (option) => option.value === resorts);
    }
    return '';
  }

  render() {
    const {
      resorts,
      meta,
      underlineStyle,
      underlineFocusStyle,
      inputStyle,
      multi,
    } = this.props;
    const { options } = this.state;

    return (
      <div className="react-select">
        <Select.Async
          simplevalue
          name="resorts"
          searchable
          value={this.renderValues(resorts)}
          loadOptions={this.getResorts}
          onChange={this.handleChange}
          style={{ marginTop: '6px' }}
          underlineStyle={underlineStyle}
          underlineFocusStyle={underlineFocusStyle}
          inputStyle={inputStyle}
          filterOptions={() => options}
          multi={multi}
          cache={false}
        />
        <span className="error">
          {' '}
          {meta.touched && meta.error && <span>{meta.error}</span>}
        </span>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchResortsA: bindActionCreators(fetchResorts, dispatch),
});

const mapStateToProps = (state) => ({
  options: values(state.entities.resort),
});

ResortSelect.propTypes = {
  addResorts: PropTypes.func,
  fetchResortsA: PropTypes.func,
  resorts: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  underlineStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  underlineFocusStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  inputStyle: PropTypes.object,
  multi: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)((ResortSelect));
