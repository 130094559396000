const initialState = {
  calendarVisibleTime: null,
};

const calendar = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CALENDAR_VISIBLE_TIME':
      return {
        ...state,
        calendarVisibleTime: action.newVisibleTime,
      };
    default:
      return state;
  }
};

export default calendar;
