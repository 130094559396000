import React from 'react';
import PropTypes from 'prop-types';
import '../styles/NavigateButton.scss';

const NavigateButton = ({
  direction,
  disabled,
  onClickHandler,
  id,
  label,
}) => (
  <div className={`navigate-button navigate-button--${direction}`}>
    <span
      className={direction}
      disabled={disabled}
    />
    <label
      onClick={!disabled && onClickHandler}
      disabled={disabled}
      id={id}
    >
      {label}
    </label>
  </div>
);

NavigateButton.propTypes = {
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  onClickHandler: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
};

export default React.memo(NavigateButton);
