import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { map, get } from 'lodash';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { downloadInvoices, downloadInvoiceTemporal, clearInvoices } from '../actions/accountActions';
import DownloadEntityButton from './DownloadEntityButton';
import invokeURL from '../../config/api-config';

class AccountInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'loading',
      errorMsg: undefined,
      errorStatus: undefined,
    };
  }

  componentDidMount() {
    const { downloadInvoicesA, accountId } = this.props;

    downloadInvoicesA(accountId).then(() => this.setState({ status: 'hide' }))
      .catch((err) => {
        const error = this.handleErrResponse(err);

        this.setState({ status: 'hide', ...error });
      });
  }

  componentWillUnmount() {
    const { clearInvoicesA } = this.props;

    clearInvoicesA();
  }

  handleInvoiceDownload = (id) => {
    const { downloadInvoiceTemporalA } = this.props;

    this.setState({ status: 'loading' });
    downloadInvoiceTemporalA(id).then((res) => {
      const downloadToken = get(res, 'payload.result.downloadToken');
      const link = document.createElement('a');
      link.href = [invokeURL.invokeUrl, `/planInvoice/${downloadToken}`].join('');
      link.download = 'file.pdf';
      link.dispatchEvent(new MouseEvent('click'));

      this.setState({ status: 'hide', errorMsg: undefined, errorStatus: undefined });
    }).catch((err) => {
      const error = this.handleErrResponse(err);

      this.setState({ status: 'hide', ...error });
    });
  }

  handleErrResponse = (err) => {
    if (err.status === 400) {
      return {
        errorMsg: 'No results were found',
        errorStatus: false,
      };
    }

    return {
      errorMsg: 'Something went wrong...',
      errorStatus: true,
    };
  };

  getErrorStyle = (status) => {
    if (status) {
      return {
        color: '#a52a2a',
      };
    }
    return {
      color: '#0d47a1',
    };
  };

  render() {
    const { invoices } = this.props;
    const { status, errorMsg, errorStatus } = this.state;

    return (
      <div>
        <RefreshIndicator
          size={50}
          left={300}
          top={10}
          status={status}
        />
        {errorMsg
        && <span style={this.getErrorStyle(errorStatus)}>{errorMsg}</span>}
        {map(invoices, (invoice, index) => (
          <div key={index} style={{ width: '35%' }}>
            <span>{invoice.date}</span>
            <span style={{ float: 'right' }}>
              <DownloadEntityButton handleDownload={() => this.handleInvoiceDownload(invoice.id)} />
            </span>
          </div>
        ))}
      </div>
    );
  }
}

AccountInvoices.propTypes = {
  downloadInvoicesA: PropTypes.func,
  accountId: PropTypes.number,
  clearInvoicesA: PropTypes.func,
  downloadInvoiceTemporalA: PropTypes.func,
  invoices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
};

const mapStateToProps = (state) => ({
  invoices: state.invoices,
});

const mapDispatchToProps = (dispatch) => ({
  downloadInvoicesA: bindActionCreators(downloadInvoices, dispatch),
  downloadInvoiceTemporalA: bindActionCreators(downloadInvoiceTemporal, dispatch),
  clearInvoicesA: bindActionCreators(clearInvoices, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInvoices);
