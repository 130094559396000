import React from 'react';
import PropTypes from 'prop-types';
import { Creatable } from 'react-select';

const RenderCreatable = ({
  input,
  multi,
  clearable,
  simpleValue,
  options,
  meta: { error, touched },
}) => (
  <div>
    <Creatable
      name={input.name}
      multi={multi}
      clearable={clearable}
      simpleValue={simpleValue}
      options={options}
      placeholder="Input text"
      valueKey="id"
      labelKey="name"
      onChange={input.onChange}
      value={input.value}
      onBlur={() => input.onBlur()}
      className="react-select"
    />
    {error && touched && <span className="error">{error}</span>}
  </div>
);

RenderCreatable.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  simpleValue: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
};

export default React.memo(RenderCreatable);
