import {
  calendar,
  schoolSettings,
  accountInformation,
  instructors,
  availabilities,
  hotels,
  additionalServices,
  products,
  productForm,
  createGroup,
  createBooking,
  partners,
  managers,
  clients,
  createOffer,
} from './guides';

export const getCurrentGuideSteps = (guide) => {
  switch (guide) {
    case 'calendar': {
      return calendar;
    }
    case 'schoolSettings': {
      return schoolSettings;
    }
    case 'accountInformation': {
      return accountInformation;
    }
    case 'instructors': {
      return instructors;
    }
    case 'availabilities': {
      return availabilities;
    }
    case 'hotels': {
      return hotels;
    }
    case 'additionalServices': {
      return additionalServices;
    }
    case 'products': {
      return products;
    }
    case 'productForm': {
      return productForm;
    }
    case 'createGroup': {
      return createGroup;
    }
    case 'createBooking': {
      return createBooking;
    }
    case 'partners': {
      return partners;
    }
    case 'managers': {
      return managers;
    }
    case 'clients': {
      return clients;
    }
    case 'createOffer': {
      return createOffer;
    }
    default: {
      return [];
    }
  }
};

export const getCurrentGuideStepsLength = (guide) => getCurrentGuideSteps(guide).length;

export const getNextGuide = (guide, skip) => {
  switch (guide) {
    case 'calendar': {
      return 'schoolSettings';
    }
    case 'schoolSettings': {
      return 'accountInformation';
    }
    case 'accountInformation': {
      return 'instructors';
    }
    case 'instructors': {
      if (skip) {
        return 'hotels';
      }
      return 'availabilities';
    }
    case 'availabilities': {
      return 'hotels';
    }
    case 'hotels': {
      return 'additionalServices';
    }
    case 'additionalServices': {
      return 'products';
    }
    case 'products': {
      if (skip) {
        return 'createGroup';
      }
      return 'productForm';
    }
    case 'productForm': {
      return 'createGroup';
    }
    case 'createGroup': {
      return 'createBooking';
    }
    case 'createBooking': {
      return 'createOffer';
    }
    default: return undefined;
  }
};
