import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SpecialityIcon from './SpecialityIcon';
import '../styles/LessonLabel.scss';

const TableLessonLabel = ({ initialValues }) => (
  <div className="lesson-label">
    {_.map(initialValues, (lesson, index) => (
      <div key={index} className="lesson-label-row">
        <span className="row-item">{lesson.instructorFullName}</span>
        <span className="row-item">{lesson.productName}</span>
        {lesson.productSpeciality
          ? (
            <span className="row-item">
              <SpecialityIcon
                level={lesson.productLevel}
                speciality={lesson.productSpeciality}
                activity={lesson.productActivity}
              />
            </span>
          )
          : <span />}
      </div>
    ))}
  </div>
);

TableLessonLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
};

export default React.memo(TableLessonLabel);
