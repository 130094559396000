import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Field, reduxForm, formValueSelector, SubmissionError,
} from 'redux-form';
import { forEach, get } from 'lodash';
import moment from 'moment';
import { RadioButtonGroup } from 'redux-form-material-ui';
import { RadioButton } from 'material-ui/RadioButton';
import RenderSelectAsync from '../components/RenderSelectAsync';
import { Time, DateField } from '../components/Field';
import { fetchResorts } from '../actions/resortsActions';
import { FormatAvailabilities } from '../utils/helpers';
import { convertToUTC, convertToDate } from '../utils/dateTime';
import '../styles/AvailabilityForm.scss';
import '../styles/ReactSelectBox.scss';

const required = (value) => (value ? undefined : 'This field is required');

const AvailabilityForm = ({
  change,
  initialValues,
  onSubmit,
  handleSubmit,
  resort,
  dateFrom,
}) => {
  const changeTimes = (timeFromH, timeFromM, timeToH, timeToM) => {
    const tF = new Date();
    tF.setHours(timeFromH);
    tF.setMinutes(timeFromM);
    const tT = new Date();
    tT.setHours(timeToH);
    tT.setMinutes(timeToM);
    change('timeFrom', tF);
    change('timeTo', tT);
  };

  useEffect(() => {
    window.setTimes = (timeFromH, timeFromM, timeToH, timeToM) => {
      changeTimes(timeFromH, timeFromM, timeToH, timeToM);
    };
  }, []);

  const handleError = (err) => {
    const property = get(err, 'data.constraintViolations[0].property', null);
    const message = get(err, 'data.constraintViolations[0].message', null);
    if (!Number.isNaN(parseInt(property.substr(1, 2), 10))) {
      throw new SubmissionError({ _error: message });
    }
    throw new SubmissionError({ [property]: message });
  };

  const handleUpdate = (values) => {
    const {
      timeFrom,
      timeTo,
      dateFrom: dateFromValues,
      dateTo,
      type,
    } = values;
    const availabilities = timeFrom && timeTo && dateFromValues && dateTo
      ? FormatAvailabilities(timeFrom, timeTo, dateFromValues, dateTo) : [];
    const body = [];
    if (initialValues.editing) {
      const dateStart = moment(convertToUTC(dateFromValues)).toISOString();
      const dateEnd = moment(convertToUTC(dateTo)).toISOString();
      const timeStart = moment(convertToUTC(timeFrom)).toISOString();
      const timeEnd = moment(convertToUTC(timeTo)).toISOString();
      const availabilityTimeFrom = `${dateStart.split('T')[0]}T${timeStart.split('T').pop()}`;
      const availabilityTimeTo = `${dateEnd.split('T')[0]}T${timeEnd.split('T').pop()}`;
      const resortValue = values.resort ? values.resort : null;
      let tempResort = null;
      if (resortValue) {
        tempResort = resortValue.value ? resortValue.value : resortValue;
      }
      const availabilityData = {
        type,
        timeFrom: availabilityTimeFrom,
        timeTo: availabilityTimeTo,
        instructor: parseInt(initialValues.instructor, 10),
        resort: tempResort,
      };
      return onSubmit(initialValues.id, availabilityData).then((res) => {
        if (res) {
          handleError(res);
        }
      });
    }
    const resortValue = values.resort ? values.resort : null;
    forEach(availabilities, (availability) => {
      let tempResort = null;
      if (resortValue) {
        tempResort = resortValue.value ? resortValue.value : resortValue;
      }
      const availabilityData = {
        type: values.type,
        timeFrom: availability.timeFrom,
        timeTo: availability.timeTo,
        resort: tempResort,
        instructor: parseInt(initialValues.instructor, 10),
      };
      body.push(availabilityData);
    });
    return onSubmit(body).then((res) => {
      if (res) {
        handleError(res);
      }
    });
  };

  return (
    <div className="availability-form">
      <form
        onSubmit={handleSubmit(handleUpdate)}
      >
        <span className="add-availability">{initialValues.editing ? 'Edit Availability' : 'Add Availability'}</span>

        <div className="form-field">
          <span id="radio-buttons-anchor" />
          <Field name="type" component={RadioButtonGroup}>
            <RadioButton value="full" label="Fully Available" required />
            <RadioButton value="half" label="Half Available" required />
          </Field>
        </div>
        <div className="form-field">
          <label className="l1">Date From:</label>
          <div className="date-input">
            <DateField
              name="dateFrom"
              className="date"
              underlineStyle={{ display: 'none' }}
              validate={[required]}
            />
          </div>
        </div>
        <div className="form-field">
          <label className="l1">Date To:</label>
          <div className="date-input">
            <DateField
              name="dateTo"
              className="date"
              underlineStyle={{ display: 'none' }}
              validate={[required]}
              minDate={new Date(dateFrom)}
            />
          </div>
        </div>
        <div className="form-field">
          <label className="l1">Available times:</label>
          <div className="time-input-field">
            <div className="time-input">
              <Time
                name="timeFrom"
                hintText=""
                underlineStyle={{ display: 'none' }}
                style={{ width: 80, height: 35 }}
                validate={[required]}
              />
            </div>
            <span className="time-input item">-</span>
            <div className="time-input">
              <Time
                name="timeTo"
                hintText=""
                underlineStyle={{ display: 'none' }}
                style={{ width: 80, height: 35 }}
                validate={[required]}
              />
            </div>
          </div>
        </div>
        <div className="form-field">
          <label className="l1">Resort:</label>
          <Field
            searchable
            clearable={false}
            name="resort"
            component={RenderSelectAsync}
            value={resort || ''}
            autoload
            validate={[required]}
          />
        </div>
        <button
          style={{ cursor: 'pointer' }}
          type="submit"
          className="button"
        >
          {initialValues.editing ? 'UPDATE' : 'ADD'}
        </button>
      </form>
    </div>
  );
};

const formConfig = {
  form: 'AvailabilityForm',
};

const selector = formValueSelector('AvailabilityForm');

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchResorts,
  }, dispatch),
});

const mapStateToProps = (state, props) => {
  let timeRangeValues = {};
  if (props.initialValues && props.initialValues.timeFrom && props.initialValues.timeTo) {
    timeRangeValues = {
      timeFrom: moment(props.initialValues.timeFrom).add(new Date().getTimezoneOffset() / 60, 'hour').toDate(),
      timeTo: moment(props.initialValues.timeTo).add(new Date().getTimezoneOffset() / 60, 'hour').toDate(),
      dateFrom: convertToDate(props.initialValues.dateFrom),
      dateTo: convertToDate(props.initialValues.dateTo),
    };
  }
  return {
    resorts: state.entities.resort,
    resort: selector(state, 'resort'),
    dateFrom: selector(state, 'dateFrom'),
    initialValues: {
      ...props.initialValues,
      ...timeRangeValues,
    },
  };
};

AvailabilityForm.propTypes = {
  change: PropTypes.func,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    editing: PropTypes.bool,
    instructor: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  resort: PropTypes.shape({
    id: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  dateFrom: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(React.memo(AvailabilityForm)));
