import React from 'react';
import PropTypes from 'prop-types';
import '../styles/BuyerItem.scss';

const BuyerItem = ({
  group,
  onDoubleClick,
  onContextClick,
  originalId,
  item,
  onClick,
}) => (
  <div
    className={`buyer buyer__level--${group.level}`}
    onDoubleClick={onDoubleClick}
    onContextMenu={(e) => onContextClick(originalId, e, item.bookingId)}
    id={originalId}
  >
    <div className="buyer-section">
      <div>
        <div className="buyer-speciality">
          {item.activityName}
          {'/'}
          {item.specialityName}
          {' '}
        </div>
        <div className="buyer-language">
          {' '}
          {item.language}
          {' '}
        </div>
      </div>
      <div className="buyer-booking" onClick={onClick}>
        B-
        {item.booking}
      </div>
    </div>

    <div className="buyer-name" onClick={(e) => onContextClick(originalId, e, item.bookingId)}>{ `${item.name} ${item.surname || ''}` }</div>

    <div className="buyer-section">
      <span className={`buyer-paid buyer-paid__${item.paid}`} />
      {item.age && (
        <div className="buyer-age">
          Age
          { item.age }
        </div>
      )}
    </div>

  </div>
);

BuyerItem.propTypes = {
  group: PropTypes.shape({
    level: PropTypes.string,
  }),
  onDoubleClick: PropTypes.func,
  onContextClick: PropTypes.func,
  originalId: PropTypes.string,
  item: PropTypes.shape({
    activityName: PropTypes.string,
    specialityName: PropTypes.string,
    language: PropTypes.string,
    booking: PropTypes.number,
    bookingId: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    paid: PropTypes.bool,
    age: PropTypes.number,
  }),
  onClick: PropTypes.func,
};

export default React.memo(BuyerItem);
