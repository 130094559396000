import React from 'react';
import PropTypes from 'prop-types';
import '../styles/RemoveLessonButton.scss';

const RemoveLessonButton = ({ onClick, className }) => (
  <div style={{ cursor: 'pointer' }} className={`remove-lesson-button ${className}`} onClick={onClick}> </div>
);

RemoveLessonButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default React.memo(RemoveLessonButton);
