import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const SecondaryDateHeaderCell = ({ getIntervalProps, intervalContext }) => {
  const { interval: { startTime, endTime, labelWidth } } = intervalContext;
  const text = useMemo(() => {
    if (moment(startTime).add(1, 'hours').isSameOrAfter(endTime)) {
      if (labelWidth > 50) {
        return startTime.format('HH:mm');
      }
      return startTime.format('HH');
    }
    if (moment(startTime).add(1, 'days').isSameOrAfter(endTime)) {
      if (labelWidth > 150) {
        return startTime.format('dddd, Do');
      }
      if (labelWidth > 100) {
        return startTime.format('ddd, Do');
      }
      if (labelWidth > 60) {
        return startTime.format('dd Do');
      }
      return startTime.format('DD');
    }
    if (labelWidth > 100) {
      return startTime.format('MMMM');
    }
    if (labelWidth > 50) {
      return startTime.format('MMM');
    }
    return startTime.format('MM');
  }, [startTime, endTime, labelWidth]);

  return (
    <div className="rct-dateHeader" {...getIntervalProps()}>
      {text}
    </div>
  );
};

SecondaryDateHeaderCell.propTypes = {
  getIntervalProps: PropTypes.func,
  intervalContext: PropTypes.shape({
    interval: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      startTime: PropTypes.object,
      // eslint-disable-next-line react/forbid-prop-types
      endTime: PropTypes.object,
      labelWidth: PropTypes.number,
    }),
    intervalText: PropTypes.string,
  }),
};

export default SecondaryDateHeaderCell;
