import React from 'react';
import PropTypes from 'prop-types';
import ActiveIcon from './ActiveIcon';
import InActiveIcon from './InActiveIcon';

const ActiveButton = ({ status, onClick, anchor }) => (status ? (
  <ActiveIcon
    onClick={onClick}
    id={anchor}
  />
) : (
  <InActiveIcon
    onClick={onClick}
    id={anchor}
  />
)
);

ActiveButton.propTypes = {
  status: PropTypes.bool,
  onClick: PropTypes.func,
  anchor: PropTypes.string,
};

export default ActiveButton;
