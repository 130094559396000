import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import bookingSchema from '../schema/Booking';

export const fetchBooking = (id) => httpRequest({
  method: 'GET',
  path: `/booking/${id}`,
  types: [
    'BOOKING_GET_REQUEST',
    'BOOKING_GET_SUCCESS',
    'BOOKING_GET_FAILURE',
  ],
  schema: bookingSchema,
});

export const fetchBookings = () => httpRequest({
  method: 'GET',
  path: '/booking',
  types: [
    'BOOKINGS_GET_REQUEST',
    'BOOKINGS_GET_SUCCESS',
    'BOOKINGS_GET_FAILURE',
  ],
  schema: new schema.Array(bookingSchema),
});

export const fetchBookingsTemp = () => httpRequest({
  method: 'GET',
  path: '/booking',
  types: [
    'BOOKINGS_GET_REQUEST',
    'BOOKINGS_GET_SUCCESS',
    'BOOKINGS_GET_FAILURE',
  ],
  schema: new schema.Array(bookingSchema),
});

export const fetchBookingPrice = (body) => httpRequest({
  method: 'POST',
  path: '/booking/price',
  body,
  types: [
    'BOOKING_PRICE_POST_REQUEST',
    'BOOKING_PRICE_POST_SUCCESS',
    'BOOKING_PRICE_POST_FAILURE',
  ],
  schema: null,
});

export const createBooking = (body) => httpRequest({
  method: 'POST',
  path: '/booking',
  body,
  types: [
    'BOOKING_POST_REQUEST',
    'BOOKING_POST_SUCCESS',
    'BOOKING_POST_FAILURE',
  ],
  schema: bookingSchema,
});

export const updateBooking = (id, body) => httpRequest({
  method: 'PUT',
  path: `/booking/${id}`,
  body,
  types: [
    'BOOKING_PUT_REQUEST',
    'BOOKING_PUT_SUCCESS',
    'BOOKING_PUT_FAILURE',
  ],
  schema: bookingSchema,
});

export const revertCanceledBooking = (id) => httpRequest({
  method: 'GET',
  path: `/booking/${id}/revert`,
  types: [
    'BOOKING_REVERT_REQUEST',
    'BOOKING_REVERT_SUCCESS',
    'BOOKING_REVERT_FAILURE',
  ],
  schema: bookingSchema,
});

export const updateComment = (id, body) => httpRequest({
  path: `/booking/${id}/comment`,
  method: 'PUT',
  types: [
    'BOOKING_PUT_REQUEST',
    'BOOKING_PUT_SUCCESS',
    'BOOKING_PUT_FAILURE',
  ],
  body,
  schema: bookingSchema,
});

export const deleteBooking = (id) => httpRequest({
  path: `/booking/${id}`,
  method: 'DELETE',
  types: [
    'BOOKING_DELETE_REQUEST',
    'BOOKING_DELETE_SUCCESS',
    'BOOKING_DELETE_FAILURE',
  ],
  schema: bookingSchema,
});

export const cancelBooking = (id, body) => httpRequest({
  path: `/booking/${id}/cancel`,
  method: 'POST',
  types: [
    'BOOKING_CANCEL_REQUEST',
    'BOOKING_CANCEL_SUCCESS',
    'BOOKING_CANCEL_FAILURE',
  ],
  body,
  schema: null,
});

export const fetchBookingsUsedAmount = () => httpRequest({
  method: 'GET',
  path: '/booking/count',
  types: [
    'USED_AMOUNT_GET_REQUEST',
    'USED_AMOUNT_GET_SUCCESS',
    'USED_AMOUNT_GET_FAILURE',
  ],
  schema: null,
});

export const fetchBookingsForList = (pageNumber, pageSize, body) => httpRequest({
  path: `/booking/list?page=${pageNumber}&size=${pageSize}`,
  method: 'POST',
  body,
  types: [
    'BOOKING_PAGES_REQUEST',
    'BOOKING_PAGES_SUCCESS',
    'BOOKING_PAGES_FAILURE',
  ],
});

export const fetchBookingsByIds = (body) => httpRequest({
  path: '/booking/filter/id',
  method: 'POST',
  types: [
    'BOOKING_BY_ID_GET_REQUEST',
    'BOOKING_BY_ID_GET_SUCCESS',
    'BOOKING_BY_ID_GET_FAILURE',
  ],
  body,
  schema: null,
});

export const exportBookings = (body) => httpRequest({
  path: '/booking/export',
  method: 'POST',
  types: [
    'BOOKING_EXPORT_REQUEST',
    'BOOKING_EXPORT_SUCCESS',
    'BOOKING_EXPORT_FAILURE',
  ],
  body,
  schema: null,
});
