import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import RaisedButton from 'material-ui/RaisedButton';
import FormField from './FormField';
import RenderField from './RenderField';
import RenderCreatable from './RenderCreatable';
import {
  required, number, notEmpty, phone,
} from '../utils/validators';
import { fetchPartners } from '../actions/partnerActions';
import { bookingStyleObject } from '../styles/BookingFormStyles';
import { mapPropsToOptions } from '../utils/map';
import RenderPhoneInput from './RenderPhoneInput';

const BookingPartnerManagerForm = ({
  partnerList,
  fetchPartnersA,
  handleSubmit,
  pristine,
  invalid,
  submitting,
  partner,
  disabled,
}) => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    fetchPartnersA();
  }, []);

  useEffect(() => {
    setPartners(mapPropsToOptions(partnerList));
  }, [partnerList]);

  return (
    <div className="BookingForm__addnew-form">
      <FormField label="First name:*" theme="small">
        <Field
          name="name"
          component={RenderField}
          type="text"
          placeholder="First Name"
          validate={[required]}
          disabled={disabled}
        />
      </FormField>
      <FormField label="Last name:*" theme="small">
        <Field
          name="surname"
          component={RenderField}
          type="text"
          placeholder="Last Name"
          validate={[required]}
          disabled={disabled}
        />
      </FormField>
      <FormField label="Email:*" theme="small">
        <Field
          name="email"
          component={RenderField}
          type="email"
          placeholder="Email"
          validate={[required]}
          disabled={disabled}
        />
      </FormField>
      <FormField label="Phone:" theme="small">
        <Field
          name="phoneNumbers"
          component={RenderPhoneInput}
          validate={[phone]}
          placeholder="Phone number"
          disabled={disabled}
        />
      </FormField>
      <span id="new-partner-anchor" />
      <FormField label="Partner company:*" theme="small">
        <Field
          clearable
          disabled={disabled}
          name="partner"
          component={RenderCreatable}
          options={partners}
          validate={[required, notEmpty]}
        />
      </FormField>
      <p className="partner-company-label">
        To create a new Partner Company write
        {' '}
        {"it's"}
        {' '}
        name in the input field and select Create option
      </p>
      { partner && typeof partner.id === 'string' && (
        <FormField label="Commission:*" theme="small">
          <Field
            name="commission"
            component={RenderField}
            type="number"
            validate={[required, number]}
            disabled={disabled}
            step="0.1"
          />
        </FormField>
      )}
      <div>
        <RaisedButton
          onClick={handleSubmit}
          backgroundColor="#253D63"
          label="CREATE MANAGER"
          labelStyle={bookingStyleObject.createNewClientButton.label}
          style={bookingStyleObject.createNewClientButton}
          disabled={pristine || invalid || submitting || disabled}
        />
      </div>
    </div>
  );
};

const formConfig = {
  form: 'BookingPartnerManagerForm',
};

const selector = formValueSelector(formConfig.form);

const mapStateToProps = (state) => {
  const { entities } = state;
  return {
    partnerList: entities.partner,
    partner: selector(state, 'partner'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPartnersA: bindActionCreators(fetchPartners, dispatch),
});

BookingPartnerManagerForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  partnerList: PropTypes.object,
  fetchPartnersA: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  partner: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  disabled: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(React.memo(BookingPartnerManagerForm)));
