import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import MaterialDatePicker from 'material-ui/DatePicker';
import classNames from 'classnames';

const DatePicker = ({
  enabled,
  change,
  name,
  value,
  classes,
  placeholder,
}) => {
  const input = useRef();

  const divClasses = useMemo(() => {
    let tempClasses = classes;
    if (!Array.isArray(tempClasses)) {
      tempClasses = [];
    }
    if (enabled) {
      tempClasses.push('enabled');
    }
    return tempClasses;
  }, [classes, enabled]);

  const open = () => {
    if (!enabled) {
      return;
    }
    input.current.openDialog();
  };

  const onChange = (e, val) => {
    change(name, val);
  };

  return (
    <div className={classNames(['date-picker', divClasses])}>
      <MaterialDatePicker
        style={{ display: 'none' }}
        textFieldStyle={{ display: 'none' }}
        ref={input}
        onChange={onChange}
        autoOk
      />
      <div style={{ cursor: 'pointer' }} onClick={open}>
        { value ? (
          <span>{ dateFormat(value, 'dd-mmm-yyyy') }</span>
        ) : (
          <span>{placeholder}</span>
        )}
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  enabled: PropTypes.bool,
  change: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
};

export default React.memo(DatePicker);
