import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import { connect } from 'react-redux';
import { email, required } from '../../utils/validators';
import '../../styles/RegistrationWizard.scss';

const ThirdPage = ({
  handleSubmit,
  previousPage,
  password,
}) => {
  const classNames = useMemo(() => {
    const includesNumber = /[0-9]+/g;
    let styles = [];
    if (!password) {
      styles = [
        {
          bubble: 'password-validation--bubble',
          text: 'password-validation--text',
        },
        {
          bubble: 'password-validation--bubble',
          text: 'password-validation--text',
        },
      ];
      return styles;
    }
    if (password.length > 7 && includesNumber.test(password)) {
      styles = [
        {
          bubble: 'password-validation--bubble__completed',
          text: 'password-validation--text__text-green password-validation--text',
        },
        {
          bubble: 'password-validation--bubble__completed',
          text: 'password-validation--text__text-green password-validation--text',
        },
      ];
      return styles;
    }
    if (password.length < 8 && includesNumber.test(password)) {
      styles = [
        {
          bubble: 'password-validation--bubble__error',
          text: 'password-validation--text__text-red password-validation--text',
        },
        {
          bubble: 'password-validation--bubble__completed',
          text: 'password-validation--text__text-green password-validation--text',
        },
      ];
      return styles;
    }
    if (password.length > 7 && !includesNumber.test(password)) {
      styles = [
        {
          bubble: 'password-validation--bubble__completed',
          text: 'password-validation--text__text-green password-validation--text',
        },
        {
          bubble: 'password-validation--bubble__error',
          text: 'password-validation--text__text-red password-validation--text',
        },
      ];
      return styles;
    }
    if (password.length < 8 && !includesNumber.test(password)) {
      styles = [
        {
          bubble: 'password-validation--bubble__error',
          text: 'password-validation--text__text-red password-validation--text',
        },
        {
          bubble: 'password-validation--bubble__error',
          text: 'password-validation--text__text-red password-validation--text',
        },
      ];
      return styles;
    }
    return undefined;
  }, [password]);

  return (
    <div>
      <div className="step-info">
        <div>Step 3 - Enter your account details</div>
        <div>This information will help you to access Ski School Management Software</div>
      </div>
      <form onSubmit={handleSubmit} className="register-form">
        <div className="register-form__section-wrapper">
          <div className="register-form__input-wrapper">
            <label>Email:*</label>
            <Field
              validate={[email, required]}
              name="email"
              component={TextField}
              className="register-form__email"
              type="text"
              hintText="username@example.com"
              underlineStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              underlineFocusStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              inputStyle={{
                color: '#253D63',
                fontSize: '16px',
                lineHeight: '24px',
              }}
            />
          </div>
          <div className="register-form__input-wrapper">
            <label>Password:*</label>
            <Field
              className="register-form__email"
              component={TextField}
              name="password"
              validate={required}
              type="password"
              hintText="Enter your password"
            />
          </div>
          <div className="password-validation__first-margin register-form__input-wrapper">
            <label />
            <div className="password-validation__box">
              <span className={classNames[0].bubble} />
              <span className={classNames[0].text}>At least 8 characters long</span>
            </div>
          </div>
          <div className="password-validation__second-margin register-form__input-wrapper">
            <label />
            <div className="password-validation__box">
              <span className={classNames[1].bubble} />
              <span className={classNames[1].text}>At least one digit</span>
            </div>
          </div>
          <div className="register-form__input-wrapper">
            <label>Repeat password:*</label>
            <Field
              name="passwordRepeat"
              component={TextField}
              validate={[required]}
              className="register-form__password"
              type="password"
              hintText="Confirm password"
              underlineStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              underlineFocusStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              inputStyle={{
                color: '#253D63',
                fontSize: '16px',
                lineHeight: '24px',
              }}
            />
          </div>
        </div>
        <div className="navigation">
          <span onClick={previousPage}>Back</span>
          <button type="submit">Next</button>
        </div>
      </form>
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  if (values.password && values.passwordRepeat && values.password !== values.passwordRepeat) {
    errors.passwordRepeat = 'Passwords must match';
  }
  return errors;
};

ThirdPage.propTypes = {
  handleSubmit: PropTypes.func,
  previousPage: PropTypes.func,
  password: PropTypes.string,
};

const formConfig = {
  form: 'RegisterThirdPage',
  destroyOnUnmount: false,
  validate,
};

const selector = formValueSelector('RegisterThirdPage');

const mapStateToProps = (state) => ({
  password: selector(state, 'password'),
});

export default connect(
  mapStateToProps,
)(reduxForm(formConfig)(React.memo(ThirdPage)));
