export const ACTIVATE_GUIDE = 'ACTIVATE_GUIDE';
export const DEACTIVATE_GUIDE = 'DEACTIVATE_GUIDE';
export const GO_TO_STEP = 'GO_TO_STEP';
export const SET_TIMELINE_ANCHOR = 'SET_TIMELINE_ANCHOR';
export const TRIGGER_TIMELINE_BOUNDS_CHANGE = 'TRIGGER_TIMELINE_BOUNDS_CHANGE';
export const REMOVE_TIMELINE_BOUNDS_CHANGE = 'REMOVE_TIMELINE_BOUNDS_CHANGE';
export const REMOVE_MENU_OPEN = 'REMOVE_MENU_OPEN';
export const TRIGGER_MENU_OPEN = 'TRIGGER_MENU_OPEN';
export const SET_INSTRUCTOR_ANCHOR = 'SET_INSTRUCTOR_ANCHOR';
export const TRIGGER_AVAILABILITY_FORM_OPEN = 'TRIGGER_AVAILABILITY_FORM_OPEN';
export const TRIGGER_AVAILABILITY_FORM_CLOSE = 'TRIGGER_AVAILABILITY_FORM_CLOSE';
export const TRIGGER_GROUP_FORM_OPEN = 'TRIGGER_GROUP_FORM_OPEN';
export const TRIGGER_GROUP_FORM_CLOSE = 'TRIGGER_GROUP_FORM_CLOSE';
export const TRIGGER_BOOKING_FORM_OPEN = 'TRIGGER_BOOKING_FORM_OPEN';
export const TRIGGER_BOOKING_FORM_CLOSE = 'TRIGGER_BOOKING_FORM_CLOSE';
export const GROUP_SCROLL_INTO_VIEW = 'GROUP_SCROLL_INTO_VIEW';
export const BOOKING_SCROLL_INTO_VIEW = 'BOOKING_SCROLL_INTO_VIEW';
export const TRIGGER_ADD_NEW_CLIENT_OPEN = 'TRIGGER_ADD_NEW_CLIENT_OPEN';
export const TRIGGER_ADD_NEW_PARTNER_OPEN = 'TRIGGER_ADD_NEW_PARTNER_OPEN';
export const TRIGGER_CLIENT_FROM_PARTNER_OPEN = 'TRIGGER_CLIENT_FROM_PARTNER_OPEN';
export const TRIGGER_LESSON_BLOCK_ADD = 'TRIGGER_LESSON_BLOCK_ADD';
// export const TRIGGER_SPECIAL_NEEDS_OPEN = 'TRIGGER_SPECIAL_NEEDS_OPEN';
export const TRIGGER_ADDITIONAL_SERVICE_OPEN = 'TRIGGER_ADDITIONAL_SERVICE_OPEN';
// export const TRIGGER_COMMENT_OPEN = 'TRIGGER_COMMENT_OPEN';
// export const TRIGGER_PREPAYMENT_OPEN = 'TRIGGER_PREPAYMENT_OPEN';
export const REMOVE_BOOKING_SCROLL_INTO_VIEW = 'REMOVE_BOOKING_SCROLL_INTO_VIEW';
export const TRIGGER_PARTNER_MODAL_OPEN = 'TRIGGER_PARTNER_MODAL_OPEN';
export const SET_MANAGER_ANCHOR = 'SET_MANAGER_ANCHOR';
export const TRIGGER_CREATE_OFFERS_OPEN = 'TRIGGER_CREATE_OFFERS_OPEN';
export const TRIGGER_CREATE_OFFERS_CLOSE = 'TRIGGER_CREATE_OFFERS_CLOSE';
export const TRIGGER_EXPORT_STEP_CHANGE = 'TRIGGER_EXPORT_STEP_CHANGE';
export const TRIGGER_CALENDAR_SCROLL_INTO_VIEW = 'TRIGGER_CALENDAR_SCROLL_INTO_VIEW';
export const REMOVE_CALENDAR_SCROLL_INTO_VIEW = 'REMOVE_CALENDAR_SCROLL_INTO_VIEW';
