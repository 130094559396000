import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';
import Popover from 'material-ui/Popover';
import Checkbox from 'material-ui/Checkbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { FlatButton } from 'material-ui';
import { fetchClients } from '../actions/clientsActions';
import { fetchSpecialities } from '../actions/specialityActions';
import { fetchActivities } from '../actions/activitiesActions';
import { fetchInstructors } from '../actions/instructorsActions';
import { fetchPartnerManagers } from '../actions/partnerManagerActions';
import { fetchProducts } from '../actions/productsActions';
import { fetchLessons } from '../actions/lessonActions';
import { fetchMyAccount } from '../actions/accountActions';
import { fetchGroups } from '../actions/groupsActions';
import { showDrawer } from '../actions/drawerActions';
import { fetchBooking } from '../actions/bookingActions';
import { startLoading, stopLoading } from '../actions/refreshIndicatorActions';
import ListTable from '../components/ListTable';
import DownloadEntityButton from '../components/DownloadEntityButton';
import ClientCommentsLabel from '../components/ClientCommentsLabel';
import TableLessonLabel from '../components/TableLessonLabel';
import SpecialityIcon from '../components/SpecialityIcon';
import { sortTable } from '../utils/helpers';
import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';
import '../styles/ClientList.scss';
import invokeURL from '../../config/api-config';
import BookingForm from './BookingForm';
import { mapInstructorsToValue } from '../utils/map';
import { convertToDate } from '../utils/dateTime';
import Dialog from '../components/dialog/Dialog';

class BookingHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBookings: [],
      comments: '',
      ready: false,
      open: false,
      anchorEl: null,
      turnover: '',
      isCommentsLabel: true,
      lessons: [],
      bookings: [],
      notifyDialogOpen: false,
      notifyDialogId: 0,
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    Promise.all([
      actions.fetchMyAccount(),
      actions.fetchClients(),
      actions.fetchInstructors(),
      actions.fetchSpecialities(),
      actions.fetchActivities(),
      actions.fetchProducts(),
      actions.fetchLessons(),
      actions.fetchPartnerManagers(),
      actions.fetchGroups(),
    ]).then(() => {
      this.setState({
        ready: true,
      });
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { bookings } = props;
    return {
      ...state,
      bookings,
    };
  }

  handleMultiCheck = (event, isInputChecked) => {
    const { bookings } = this.props;
    let bookingsChecked = [];
    if (isInputChecked) {
      bookingsChecked = bookings;
    }
    this.setState({
      selectedBookings: bookingsChecked,
    });
  }

  handleSingleCheck = (bookingId) => {
    const { selectedBookings } = this.state;
    const { bookings } = this.props;
    let bookingsChecked = selectedBookings;
    if (_.some(selectedBookings, { id: bookingId })) {
      bookingsChecked = _.filter(bookingsChecked, (booking) => booking.id !== bookingId);
      this.refs.multiCheck.state.switched = false;
    } else {
      const booking = _.find(bookings, { id: bookingId });
      bookingsChecked.push(booking);
    }
    this.setState({
      selectedBookings: bookingsChecked,
    });
  }

  generateTurnover = () => {
    const { selectedBookings } = this.state;
    const { accounts } = this.props;
    let turnover = 0;
    _.forEach(selectedBookings, (booking) => {
      turnover += booking.total;
    });
    turnover = `${accounts[0].currency} ${turnover}`;
    this.setState({
      turnover,
    });
  }

  handleSortTable = (columnIndex, asc) => {
    const { entity, clients } = this.props;
    const { bookings } = this.state;
    if (entity === 'client') {
      const columns = {
        0: {
          name: ['id'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
        },
        1: {
          name: ['timeFrom'], dependency: null, level: 1, orderType: 'normal', dataArray: 'timeFrom',
        },
        3: {
          name: ['total'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
        },
        4: {
          name: ['status'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
        },
      };

      const sortedList = sortTable(bookings, columns, columnIndex, asc);
      this.setState({
        bookings: sortedList,
      });
    } else if (entity === 'partnerManager') {
      const columns = {
        1: {
          name: ['id'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
        },
        2: {
          name: ['timeFrom'], dependency: null, level: 1, orderType: 'normal', dataArray: 'timeFrom',
        },
        3: {
          name: ['name', 'surname'], dependency: 'clients', level: 2, orderType: 'normal', dataArray: false,
        },
        5: {
          name: ['total'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
        },
        6: {
          name: ['status'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
        },
      };
      const dependencyData = {
        clients: { data: clients, name: 'client' },
      };

      const sortedList = sortTable(bookings, columns, columnIndex, asc, dependencyData);
      this.setState({
        bookings: sortedList,
      });
    }
  }

  handleDownloadButton = (id) => {
    const link = document.createElement('a');
    link.href = [invokeURL.invokeUrl, `/bookingInvoice/${id}`].join('');
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }

  handleEmailButton = (id) => {
    if (id > 0) {
      fetch(`${invokeURL.invokeUrl}/booking/${id}/notify-update`)
        .then(async () => {
          this.handleNotifyDialog(0);
        });
    }
  }

  handleNotifyDialog = (id) => {
    const { notifyDialogOpen } = this.state;
    this.setState({
      notifyDialogOpen: !notifyDialogOpen,
      notifyDialogId: id,
    });
  }

  handleShowLessonsLabel = (booking) => {
    const {
      lessons,
      instructors,
      products,
      specialities,
      activities,
      groups,
    } = this.props;
    let lessonsBooking = [];
    if (booking.lessonBlocks && booking.lessonBlocks.length) {
      _.forEach(booking.lessonBlocks, (block) => {
        const blockLessons = _.map(lessons[block.id], (lesson) => lesson);
        lessonsBooking = lessonsBooking.concat(blockLessons);
      });
    }
    const refName = `booking_${booking.id}`;
    const initialValues = [];
    let instructorFullName = '';
    let productActivity = '';
    let productName = '';
    let productLevel = '';
    let productSpeciality = '';

    _.forEach(lessonsBooking, (lesson) => {
      if (lesson.groups.length) {
        const groupId = lesson.groups[0].id;
        const group = groups[groupId];
        const productSpecialityId = group && _.get(group, 'speciality');
        productSpeciality = productSpecialityId && specialities[productSpecialityId];
        const productActivityId = productSpeciality && _.get(productSpeciality, 'activity');

        instructorFullName = 'Group lesson';
        productActivity = productActivityId && _.get(activities, `[${productActivityId}].name`);
        productName = group && _.get(group, 'name');
        productLevel = group && _.get(group, 'level');
      } else {
        const productId = _.get(lesson, 'product');
        const product = products[productId];
        const productActivityId = product && _.get(lesson, 'activity');
        const productSpecialityId = product && _.get(lesson, 'speciality.id');

        instructorFullName = lesson.instructor
          ? `${instructors[lesson.instructor].name} ${instructors[lesson.instructor].surname}` : 'No instructor ';
        productActivity = productActivityId && _.get(activities, `[${productActivityId}].name`);
        productName = product ? product.name : ' No product';
        productSpeciality = productSpecialityId && specialities[productSpecialityId];
        productLevel = product && product.level;
      }

      const product = {
        instructorFullName,
        productName,
        productSpeciality,
        productLevel,
        productActivity,
      };
      initialValues.push(product);
    });

    this.setState({
      anchorEl: this.refs[refName],
      open: true,
      lessons: initialValues,
      isCommentsLabel: false,
    });
  }

  handleCloseLabel = () => {
    this.setState({
      open: false,
    });
  }

  openBookingForm = (prepopulationStrategy) => {
    const { actions } = this.props;
    actions.startLoading('drawer');
    prepopulationStrategy().then((values) => {
      const bookingValues = values;
      const blocks = [];
      _.forEach(values.initialValues.lessonBlocks, (block, key) => {
        const bl = block;
        const { discount } = bl;
        const { lessons } = bl;
        bl.discount = discount.toString();
        bl.type = bl.type.id;
        blocks[key] = bl;
        _.forEach(lessons, (lesson, key2) => {
          lessons[key2].timeFrom = lesson.dateFrom;
          lessons[key2].timeTo = lesson.dateTo;
          // timeFrom: moment(timeFrom).add(new Date().getTimezoneOffset() / 60, 'hour').toDate(),
        });
        bl.lessons = lessons;
      });
      bookingValues.initialValues.lessonBlocks = blocks;
      actions.showDrawer(BookingForm, bookingValues);
      actions.stopLoading('drawer');
    });
  }

  resolveEntity = (entityType, id, callback) => {
    const entity = _.get(this.props, `${entityType}`, null);

    return new Promise((resolve) => {
      if (entity && _.get(entity, `${id}.name`, null)) {
        resolve(entity[id]);
      } else {
        callback(id).then((response) => {
          resolve(response.payload.result[entityType][id]);
        });
      }
    });
  }

  resolveLessonBlock = (lessonBlock) => ({
    ...lessonBlock,
    edit: true,
    type: {
      id: lessonBlock.type,
      name: _.capitalize(lessonBlock.type),
    },
    discount: lessonBlock.discountType === 'percent' ? `${lessonBlock.discount}%` : lessonBlock.discount,
    lessons: _.map(lessonBlock.lessons, (lesson) => {
      let entity = {
        ...lesson,
        dateFrom: convertToDate(lesson.timeFrom),
        dateTo: convertToDate(lesson.timeTo),
        timeFrom: new Date(lesson.timeFrom),
        timeTo: new Date(lesson.timeTo),
        group: !_.isEmpty(lesson.groups) ? {
          id: lesson.groups[0].guid,
          name: lesson.groups[0].name,
        } : null,
        buyers: lesson.buyers.map((b) => {
          const temp = b;
          delete temp.lesson;
          return temp;
        }),
      };
      if (lessonBlock.type === 'group') {
        const instructors = _.get(lesson, 'groups[0].instructors', []);
        entity = {
          ...entity,
          instructor: _.map(instructors, (instructor) => mapInstructorsToValue(instructor)),
        };
      }

      entity.lessonBlock = entity.lessonBlock.id;
      delete entity.groups;
      delete entity.booking;

      return entity;
    }),
  })

  fromBooking = (id) => {
    const { actions, user, openDialog } = this.props;

    return new Promise((resolve) => {
      this.resolveEntity('booking', id, actions.fetchBooking).then((booking) => {
        const { lessonBlocks, bookingServices, sendClientInvoiceToManager } = booking;
        const tempLessonBlocks = lessonBlocks.map((block) => this.resolveLessonBlock(block));

        resolve({
          edit: true,
          currency: user.account.currency,
          initialValues: {
            ...booking,
            bookingServices: _.map(bookingServices, (bookingService) => ({
              ...bookingService,
              discount: bookingService.discountType === 'percent' ? `${bookingService.discount}%` : bookingService.discount,
            })),
            lessonBlocks: tempLessonBlocks,
          },
          openDialog,
          sendClientInvoiceToManager,
        });
      });
    });
  }

  handleEditButton = (bookingId) => {
    const prepopulationStrategy = () => this.fromBooking(bookingId);
    this.openBookingForm(prepopulationStrategy);
  }

  bookingsToRows = (bookings) => {
    const {
      clients,
      instructors,
      lessons,
      products,
      specialities,
      accounts,
      entity,
      activities,
    } = this.props;
    const { selectedBookings } = this.state;
    const rows = [];
    _.forEach(bookings, (booking) => {
      let lessonsBooking = [];
      if (booking.lessonBlocks && booking.lessonBlocks.length) {
        _.forEach(booking.lessonBlocks, (block) => {
          const blockLessons = _.map(lessons[block.id], (lesson) => lesson);
          lessonsBooking = lessonsBooking.concat(blockLessons);
        });
      }
      const productActivityId = _.get(lessonsBooking, '[0].activity');
      const productActivity = _.get(activities, `[${productActivityId}].name`);
      const row = [];
      const minDateBookingLesson = lessonsBooking.length ? _.minBy(lessonsBooking, 'timeFrom') : null;
      const maxDateBookingLesson = lessonsBooking.length ? _.maxBy(lessonsBooking, 'timeTo') : null;
      const instructorFullName = lessonsBooking.length && lessonsBooking[0].instructor ? `${instructors[lessonsBooking[0].instructor].name} ${instructors[lessonsBooking[0].instructor].surname}` : 'No instructor';
      const productName = lessonsBooking.length && lessonsBooking[0].product && products[lessonsBooking[0].product].name ? (products[lessonsBooking[0].product].name) : 'No product';
      const productSpeciality = lessonsBooking.length && lessonsBooking[0].product
        && products[lessonsBooking[0].product].speciality
        ? specialities[products[lessonsBooking[0].product].speciality] : null;
      const productLevel = lessonsBooking.length && lessonsBooking[0].product
        && products[lessonsBooking[0].product].level
        ? products[lessonsBooking[0].product].level : null;
      const clientsName = booking.client && clients[booking.client].name ? clients[booking.client].name : '';
      const clientsSurname = booking.client && clients[booking.client].surname ? clients[booking.client].surname : '';
      if (entity === 'client') {
        row[0] = booking.id ? (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => this.handleEditButton(booking.id)}
          >
            {`B-${booking.bid}`}
          </span>
        ) : '-';
        row[1] = minDateBookingLesson && maxDateBookingLesson ? `${moment(minDateBookingLesson.timeFrom).format('DD/MMM/YYYY')} - ${moment(maxDateBookingLesson.timeTo).format('DD/MMM/YYYY')}` : '-';
        let secondRow = '-';
        if (lessonsBooking.length) {
          if (lessonsBooking.length > 1) {
            secondRow = (
              <span
                ref={`booking_${booking.id}`}
                onClick={() => this.handleShowLessonsLabel(booking)}
                style={{ cursor: 'pointer' }}
              >
                {`${lessonsBooking.length} lessons`}
              </span>
            );
          } else {
            secondRow = (
              <div>
                <span>{instructorFullName}</span>
                <span>{productName}</span>
                {productSpeciality ? (
                  <SpecialityIcon
                    speciality={productSpeciality}
                    level={productLevel}
                    activity={productActivity}
                  />
                ) : null}
              </div>
            );
          }
        }
        row[2] = secondRow;
        row[3] = booking.total ? `${accounts[0].currency} ${booking.total}` : '-';
        row[4] = booking.status ? booking.status : '-';
        row[5] = (
          <div>
            <span onClick={() => this.handleDownloadButton(booking.id)}>
              <DownloadEntityButton />
            </span>
            <span
              onClick={() => this.handleNotifyDialog(booking.id)}
              style={{ marginLeft: '10px', marginRight: '5px', cursor: 'pointer' }}
              hidden={!booking.paid}
            >
              @
            </span>
          </div>
        );
      }
      if (entity === 'partnerManager') {
        row[0] = (
          <Checkbox
            checked={_.some(selectedBookings, { id: booking.id })}
            onCheck={() => this.handleSingleCheck(booking.id)}
          />
        );
        row[1] = booking.id ? (
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => this.handleEditButton(booking.id)}
          >
            {`B-${booking.bid}`}
          </span>
        ) : '-';
        row[2] = minDateBookingLesson && maxDateBookingLesson ? `${moment(minDateBookingLesson.timeFrom).format('DD/MMM/YYYY')} - ${moment(maxDateBookingLesson.timeTo).format('DD/MMM/YYYY')}` : '-';
        row[3] = `${clientsName} ${clientsSurname}`;
        let fourthRow = '-';
        if (lessonsBooking.length) {
          if (lessonsBooking.length > 1) {
            fourthRow = (
              <span
                ref={`booking_${booking.id}`}
                onClick={() => this.handleShowLessonsLabel(booking)}
                style={{ cursor: 'pointer' }}
              >
                {`${lessonsBooking.length} lessons`}
              </span>
            );
          } else {
            fourthRow = (
              <div>
                <span>{instructorFullName}</span>
                <span>{productName}</span>
                {productSpeciality ? (
                  <SpecialityIcon
                    speciality={productSpeciality}
                    level={productLevel}
                    activity={productActivity}
                  />
                ) : null}
              </div>
            );
          }
        }
        row[4] = fourthRow;
        row[5] = booking.total ? `${accounts[0].currency} ${booking.total}` : '-';
        row[6] = booking.status ? booking.status : '-';
        row[7] = (
          <span onClick={() => this.handleDownloadButton(booking.id)}>
            <DownloadEntityButton />
          </span>
        );
      }
      rows.push(row);
    });
    return rows;
  }

  render() {
    const { entity } = this.props;
    const {
      bookings,
      comments,
      ready,
      turnover,
      open,
      anchorEl,
      isCommentsLabel,
      lessons,
      notifyDialogOpen,
      notifyDialogId,
    } = this.state;

    if (!ready) {
      return null;
    }
    return (
      <div>
        <Dialog
          modal={false}
          open={notifyDialogOpen}
          onRequestClose={() => this.handleNotifyDialog(0)}
          className="email-dialog"
        >
          <>
            <div onClick={() => this.handleNotifyDialog(0)}>
              <i className="material-icons sidemenu__close close-button">
                close
              </i>
            </div>
            <p>Send notification email to client</p>
            <FlatButton
              label="Submit"
              primary
              onClick={() => this.handleEmailButton(notifyDialogId)}
              style={{ float: 'right' }}
            />
          </>
        </Dialog>
        {entity === 'partnerManager'
          ? (
            <div>
              <ListTable
                dest="manager"
                names={['checkbox', 'bid', 'date', 'client', 'lessons', 'price', 'payment', 'icons']}
                header={[
                  <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
                    <Checkbox ref="multiCheck" onCheck={this.handleMultiCheck} />
                  </MuiThemeProvider>,
                  'ID',
                  'Date',
                  'Client',
                  'Lesson',
                  'Price',
                  'Status',
                  ' ',
                ]}
                rows={this.bookingsToRows(bookings)}
                handleSort={this.handleSortTable}
              />
              <div className="turnover">
                <span className="turnover__label">TURNOVER:</span>
                <input
                  type="text"
                  disabled
                  value={turnover}
                />
                <button className="turnover__button" onClick={this.generateTurnover} type="button">GENERATE</button>
              </div>
            </div>
          )
          : (
            <div className="download-icon">
              <ListTable
                dest="client"
                names={['id', 'date', 'lessons', 'price', 'payment', 'icons']}
                small
                header={[
                  'ID',
                  'Date',
                  'Lesson',
                  'Price',
                  'Payment',
                  ' ',
                ]}
                rows={this.bookingsToRows(bookings)}
                handleSort={this.handleSortTable}
              />
            </div>
          )}
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          onRequestClose={this.handleCloseLabel}
        >
          {isCommentsLabel ? <ClientCommentsLabel comments={comments} />
            : <TableLessonLabel initialValues={lessons} />}
        </Popover>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.entities.client,
  specialities: state.entities.speciality,
  activities: state.entities.activity,
  instructors: state.entities.instructor,
  lessons: _.groupBy(state.entities.lessons, 'lessonBlock'),
  products: state.entities.product,
  accounts: _.values(state.entities.account),
  groups: state.entities.group,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchClients,
    fetchSpecialities,
    fetchActivities,
    fetchInstructors,
    fetchPartnerManagers,
    fetchProducts,
    fetchMyAccount,
    fetchLessons,
    fetchGroups,
    fetchBooking,
    showDrawer,
    startLoading,
    stopLoading,
  }, dispatch),
});

BookingHistoryTable.propTypes = {
  actions: PropTypes.shape({
    fetchClients: PropTypes.func,
    fetchSpecialities: PropTypes.func,
    fetchActivities: PropTypes.func,
    fetchInstructors: PropTypes.func,
    fetchPartnerManagers: PropTypes.func,
    fetchProducts: PropTypes.func,
    fetchMyAccount: PropTypes.func,
    fetchLessons: PropTypes.func,
    fetchGroups: PropTypes.func,
    fetchBooking: PropTypes.func,
    showDrawer: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  bookings: PropTypes.array,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    currency: PropTypes.string,
  })),
  entity: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  clients: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  lessons: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  instructors: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  specialities: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  activities: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  groups: PropTypes.array,
  user: PropTypes.shape({
    account: PropTypes.shape({
      currency: PropTypes.string,
    }),
  }),
  openDialog: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistoryTable);
