import { schema } from 'normalizr';
import activitySchema from './Activity';

const specialitySchema = new schema.Entity('speciality', { idAttribute: 'id' });

specialitySchema.define({
  activity: activitySchema,
});

export default specialitySchema;
