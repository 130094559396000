import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete';
import { bookingStyleObject } from '../styles/BookingFormStyles';

const RenderField = ({
  dataSource,
  onNewRequest,
  searchText,
  hintText,
  className,
  onFocus,
  style,
  autocomplete,
  openOnFocus,
  filter,
  input,
  label,
  placeholder,
  type,
  disabled,
  min,
  max,
  step,
  radio,
  columnIndex,
  rowIndex,
  labelClass,
  labelStyle,
  divStyle,
  meta: { touched, error, warning },
  onClick,
}) => {
  const component = useMemo(() => {
    if (autocomplete) {
      return (
        <AutoComplete
          id={label}
          style={style}
          underlineStyle={{ display: 'none' }}
          className={className}
          textFieldStyle={bookingStyleObject.testStyle}
          fullWidth={false}
          filter={filter}
          openOnFocus={openOnFocus}
          dataSource={dataSource}
          dataSourceConfig={{ text: 'name', value: 'id' }}
          onNewRequest={onNewRequest}
          searchText={searchText}
          hintText={hintText}
          onFocus={onFocus}
        />
      );
    }
    return radio ? (
      <div className="radio-button">
        <input onClick={onClick} name="radio-group" id={`radio-row${rowIndex}-column${columnIndex}`} {...input} placeholder={placeholder} type={type} disabled={disabled} min={min} />
        <label htmlFor={`radio-row${rowIndex}-column${columnIndex}`}> </label>
      </div>
    ) : (
      <input
        {...input}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
      />
    );
  }, [
    autocomplete,
    radio,
    dataSource,
    onNewRequest,
    searchText,
    hintText,
    className,
    onFocus,
    style,
    openOnFocus,
    filter,
    input,
    placeholder,
    type,
    disabled,
    min,
    max,
    step,
    columnIndex,
    rowIndex,
    onClick,
  ]);

  return (
    <div className="render-field" style={divStyle}>
      {label && <label className={labelClass} style={labelStyle}>{label}</label> }
      <div>
        {component}
        {touched
        && ((error && <span className="error">{error}</span>)
          || (warning && <span className="warning">{warning}</span>))}
      </div>
    </div>
  );
};

RenderField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.any,
  onNewRequest: PropTypes.func,
  searchText: PropTypes.string,
  hintText: PropTypes.string,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  autocomplete: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  step: PropTypes.number,
  radio: PropTypes.bool,
  columnIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  rowIndex: PropTypes.number,
  labelClass: PropTypes.string,
  labelStyle: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  divStyle: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default React.memo(RenderField);
