import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/Pricing.scss';

const PlansRenderComponent = ({
  planPricing,
  paymentType,
  onChoosePlanClick,
}) => (
  <div>
    {planPricing && (
    <div className="plans-boxes">
      <div className="plans-boxes__box">
        <div className="plans-boxes__box--header">
          {planPricing[1].name}
        </div>
        <div className="plans-boxes__box--price">
          {paymentType === 'monthly' ? planPricing[1].priceMonthly : planPricing[1].priceAnnually}
          &nbsp;€
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Active instructors</div>
          <div className="plans-boxes__box--metadata--value">
            {planPricing[1].instructorLimit !== -1 && 'up to '}
            <span>
              {planPricing[1].instructorLimit !== -1 ? planPricing[1].instructorLimit : 'Unlimited'}
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Bookings</div>
          <div className="plans-boxes__box--metadata--value">
            {planPricing[1].bookingLimit !== -1 && 'up to '}
            <span>
              {planPricing[1].bookingLimit !== -1 ? planPricing[1].bookingLimit : 'Unlimited'}
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Support</div>
          <div className="plans-boxes__box--metadata--value">
            <span>Basic</span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Setup fee</div>
          <div className="plans-boxes__box--metadata--value">
            <span>
              {planPricing[1].setupFee}
              &nbsp;€
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--button" onClick={() => onChoosePlanClick(planPricing[1].id)}>
          Choose plan
        </div>
      </div>
      <div className="plans-boxes__box plans-boxes__box--big">
        <div className="plans-boxes__box--bestseller">BESTSELLER</div>
        <div className="plans-boxes__box--header">
          {planPricing[2].name}
        </div>
        <div className="plans-boxes__box--price">
          {paymentType === 'monthly' ? planPricing[2].priceMonthly : planPricing[2].priceAnnually}
          &nbsp;€
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Active instructors</div>
          <div className="plans-boxes__box--metadata--value">
            {planPricing[2].instructorLimit !== -1 && 'up to '}
            <span>
              {planPricing[2].instructorLimit !== -1 ? planPricing[2].instructorLimit : 'Unlimited'}
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Bookings</div>
          <div className="plans-boxes__box--metadata--value">
            {planPricing[2].bookingLimit !== -1 && 'up to '}
            <span>
              {planPricing[2].bookingLimit !== -1 ? planPricing[2].bookingLimit : 'Unlimited'}
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Support</div>
          <div className="plans-boxes__box--metadata--value">
            <span>PRO</span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Setup fee</div>
          <div className="plans-boxes__box--metadata--value">
            <span>
              {planPricing[2].setupFee}
              &nbsp;€
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--button" onClick={() => onChoosePlanClick(planPricing[2].id)}>
          Choose plan
        </div>
      </div>
      <div className="plans-boxes__box">
        <div className="plans-boxes__box--header">
          {planPricing[3].name}
        </div>
        <div className="plans-boxes__box--price">
          {paymentType === 'monthly' ? planPricing[3].priceMonthly : planPricing[3].priceAnnually}
          &nbsp;€
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Active instructors</div>
          <div className="plans-boxes__box--metadata--value">
            {planPricing[3].instructorLimit !== -1 && 'up to '}
            <span>
              {planPricing[3].instructorLimit !== -1 ? planPricing[3].instructorLimit : 'Unlimited'}
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Bookings</div>
          <div className="plans-boxes__box--metadata--value">
            {planPricing[3].bookingLimit !== -1 && 'up to '}
            <span>
              {planPricing[3].bookingLimit !== -1 ? planPricing[3].bookingLimit : 'Unlimited'}
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Support</div>
          <div className="plans-boxes__box--metadata--value">
            <span>Advanced</span>
          </div>
        </div>
        <div className="plans-boxes__box--metadata">
          <div className="plans-boxes__box--metadata--label">Setup fee</div>
          <div className="plans-boxes__box--metadata--value">
            <span>
              {planPricing[3].setupFee}
              &nbsp;€
            </span>
          </div>
        </div>
        <div className="plans-boxes__box--button" onClick={() => onChoosePlanClick(planPricing[3].id)}>
          Choose plan
        </div>
      </div>
    </div>
    )}
  </div>
);

PlansRenderComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  planPricing: PropTypes.object,
  paymentType: PropTypes.string,
  onChoosePlanClick: PropTypes.func,
};

export default React.memo(PlansRenderComponent);
