import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field, reduxForm, formValueSelector, SubmissionError,
} from 'redux-form';
import _ from 'lodash';
import Select from 'react-select';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import { notifySuccess } from '../actions/notificationActions';
import * as partnerManagerActions from '../actions/partnerManagerActions';
import * as partnerActions from '../actions/partnerActions';
import * as bookingsActions from '../actions/bookingActions';

import RenderField from '../components/RenderField';
import EntityForm from '../components/EntityForm';
import MinusButton from '../components/MinusButton';
import RenderSelect from '../components/RenderSelect';
import RenderTextArea from '../components/RenderTextArea';
import EditClientButton from '../components/EditClientButton';
import RenderPhoneInput from '../components/RenderPhoneInput';
import { DateField } from '../components/Field';

import BookingHistoryTable from './BookingHistoryTable';

import {
  email,
  required,
  maxLength255,
  phone,
  notEmpty,
  postalCode,
} from '../utils/validators';
import { mapPropsToOptions } from '../utils/map';
import {
  formatLanguagesValues,
  formatPhoneNumbersValues,
  formatDateOfBirth,
  parseOptionValue,
} from '../utils/helpers';
import { languageOptions } from '../constants/LanguageOptions';
import '../styles/ProductAdd.scss';

class PartnerManagerForm extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params: { id } } } = props;
    this.state = {
      genderOptions: [
        { id: 'male', name: 'Male' },
        { id: 'female', name: 'Female' },
      ],
      phoneNumbers: [{}],
      languages: [{}],
      deletedPhoneNumbers: [],
      deletedLanguages: [],
      ready: !id,
      disabled: false,
      errorLanguage: false,
      errorPhone: false,
    };
  }

  componentDidMount() {
    const {
      match: { params: { id, overview } },
      actions: {
        partners: { fetchPartners },
        partnerManagers: { fetchPartnerManagers },
        bookings: { fetchBookingsTemp },
      },
    } = this.props;
    Promise.all([
      fetchPartners(),
      id ? fetchPartnerManagers() : null,
      overview ? fetchBookingsTemp() : null,
    ]).then((response) => {
      let phoneNumbers = [{}];
      let languages = [{}];
      _.forEach(response, (responseEntity) => {
        if (responseEntity && responseEntity.type === 'PARTNER_MANAGERS_GET_SUCCESS') {
          phoneNumbers = _.get(responseEntity.payload.result.partnerManager[id], 'phoneNumbers', [{}]);
          languages = _.get(responseEntity.payload.result.partnerManager[id], 'languages', [{}]);
          phoneNumbers = phoneNumbers && phoneNumbers.length !== 0 ? phoneNumbers : [{}];
          languages = languages && languages.length !== 0 ? languages : [{}];
          this.setState({
            phoneNumbers,
            languages,
          });
        }
      });
      const disabled = !!overview;
      this.setState({
        ready: true,
        disabled,
      });
    });
  }

  onSubmit = (values) => {
    const { deletedLanguages, deletedPhoneNumbers } = this.state;
    const {
      match: { params: { id } },
      history: { push },
      actions: {
        notifySuccessA,
        partnerManagers: { createPartnerManager, updatePartnerManager },
      },
    } = this.props;
    const languages = formatLanguagesValues(values.languages, deletedLanguages);
    const phoneNumbers = formatPhoneNumbersValues(values.phoneNumbers, deletedPhoneNumbers);
    const dateOfBirth = values.dateOfBirth ? formatDateOfBirth(values.dateOfBirth) : null;
    const gender = parseOptionValue(values.gender);
    const partner = parseOptionValue(values.partner);
    const partnerManager = {
      ...values,
      dateOfBirth,
      languages,
      phoneNumbers,
      partner,
      gender,
    };
    if (dateOfBirth && dateOfBirth === 'Invalid date') {
      throw new SubmissionError({ dateOfBirth: 'This value is not a valid date' });
    }
    if (id) {
      return updatePartnerManager(id, partnerManager)
        .then(() => {
          push('/managers');
          notifySuccessA({}, 'Manager updated successfully');
        }).catch((err) => {
          this.handleError(err);
        });
    }
    return createPartnerManager(partnerManager)
      .then(() => {
        push('/managers');
        notifySuccessA({}, 'Manager created successfully');
      }).catch((err) => {
        this.handleError(err);
      });
  }

  setSelectorValue = (fieldName) => (value) => {
    const { change } = this.props;
    if (fieldName === 'month') {
      const month = value[0] + value[1];
      change(fieldName, month);
    }
    change(fieldName, value.value);
  }

  handleAddButton = (option) => () => {
    const { phoneNumbers, languages } = this.state;
    switch (option) {
      case 'telephone': {
        const tempPhoneNumbers = phoneNumbers || [];
        tempPhoneNumbers.push({});
        this.setState({
          phoneNumbers: tempPhoneNumbers,
        });
        break;
      }
      case 'language': {
        const tempLanguages = languages || [];
        tempLanguages.push({});
        this.setState({
          languages: tempLanguages,
        });
        break;
      }
      default:
        break;
    }
  }

  handleMinusButton = (option, id) => () => {
    switch (option) {
      case 'telephone': {
        const { deletedPhoneNumbers } = this.state;
        deletedPhoneNumbers.push(id);
        this.setState({
          deletedPhoneNumbers,
        });
        break;
      }
      case 'language': {
        const { deletedLanguages } = this.state;
        deletedLanguages.push(id);
        this.setState({
          deletedLanguages,
        });
        break;
      }
      default:
        break;
    }
  }

  handleError = (err) => {
    const property = _.get(err, 'data.constraintViolations[0].property', '');
    const message = _.get(err, 'data.constraintViolations[0].message', '');

    if (property === 'phoneNumbers[phoneNumber]') {
      this.setState({ errorPhone: true, errorLanguage: false });
      throw new SubmissionError({ _error: message });
    }
    if (property === 'languages[code]') {
      this.setState({ errorLanguage: true, errorPhone: false });
      throw new SubmissionError({ _error: message });
    }
    if (property === 'partner.name') {
      throw new SubmissionError({ partner: message });
    }
    if (property) {
      throw new SubmissionError({ [property]: message });
    }
  }

  render() {
    const {
      errorLanguage,
      errorPhone,
      ready,
      disabled,
      genderOptions,
      phoneNumbers,
      deletedPhoneNumbers,
      languages: stateLanguages,
      deletedLanguages,
    } = this.state;
    if (!ready) {
      return null;
    }
    const {
      handleSubmit,
      languages,
      partner,
      gender,
      match: { params },
      bookings,
      error,
      submitting,
      pristine,
      invalid,
      initialValues,
      partners,
      touch,
    } = this.props;

    return (
      <div>
        {params.overview
          ? (
            <EditClientButton
              link
              label="EDIT PROFILE"
              path={`/managers/${params.id}`}
            />
          ) : null}
        <EntityForm
          formDestination="Manager"
          handleSubmit={handleSubmit(this.onSubmit)}
          editing={!!params.id}
          overview={params.overview}
          submitting={submitting}
          pristine={pristine}
          invalid={invalid}
        >
          <div className="client-manager-form">
            <form
              onSubmit={handleSubmit(this.onSubmit)}
              id="manager-form"
            >
              <div className="client-manager-form__main-info">
                <div className="client-manager-form__field-group">
                  {params.overview
                    ? (
                      <div style={{ display: 'inline-block' }}>
                        <div className="client-manager-form__input-wrapper">
                          <label className="client-manager-form__label">NAME</label>
                          <div className="client-manager-form__input">
                            <div
                              className="client-manager-form__overview-name"
                            >
                              {initialValues.name}
                              {initialValues.surname}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div style={{ display: 'inline-block' }}>
                        <div className="client-manager-form__input-wrapper">
                          <label className="client-manager-form__label">NAME*</label>
                          <div className="client-manager-form__input">
                            <Field
                              type="text"
                              component={RenderField}
                              name="name"
                              disabled={disabled}
                              validate={[required]}
                            />
                          </div>
                        </div>
                        <div className="client-manager-form__input-wrapper">
                          <label className="client-manager-form__label">SURNAME*</label>
                          <div className="client-manager-form__input">
                            <Field
                              type="text"
                              component={RenderField}
                              name="surname"
                              disabled={disabled}
                              validate={[required]}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="client-manager-form__input-gender-wrapper">
                    <label className="client-manager-form__label">GENDER</label>
                    <Field
                      component={RenderSelect}
                      name="gender"
                      clearable={false}
                      options={genderOptions}
                      value={gender || ''}
                      onChange={this.setSelectorValue('gender')}
                      disabled={disabled}
                    />
                  </div>
                  <div className="client-manager-form__date-wrapper">
                    <label className="client-manager-form__label">BIRTHDAY</label>
                    <DateField
                      name="dateOfBirth"
                      className="date"
                      disableError
                      disabled={disabled}
                      underlineStyle={disabled && { display: 'none' }}
                      maxDate={new Date()}
                      onShow={() => touch('dateOfBirth')}
                      openToYearSelection
                    />
                  </div>
                </div>
                <div className="client-manager-form__field-group">
                  <div className="client-manager-form__input-wrapper manager-partner-select">
                    <label className="client-manager-form__label">PARTNER*</label>
                    <div className="client-manager-form__multiple-select">
                      <Field
                        component={RenderSelect}
                        name="partner"
                        clearable={false}
                        options={mapPropsToOptions(partners)}
                        value={partner || ''}
                        onChange={this.setSelectorValue('partner')}
                        disabled={disabled}
                        validate={[required, notEmpty]}
                      />
                    </div>
                  </div>
                  <div className="client-manager-form__input-wrapper phone-long">
                    <label className="client-manager-form__label">PHONE</label>
                    {_.map(phoneNumbers,
                      (telephone, index) => (_.includes(deletedPhoneNumbers, index) ? null : (
                        <div key={index} style={{ display: 'block' }}>
                          <div style={{ display: 'inline-block', position: 'relative' }}>
                            <div className="client-manager-form__input">
                              <Field
                                component={RenderPhoneInput}
                                name={`phoneNumbers[${index}].phoneNumber`}
                                disabled={params.overview}
                                validate={[phone]}
                                placeholder="Enter your phone number"
                              />
                            </div>
                          </div>
                          {params.overview ? null : index !== 0
                            && (
                            <span
                              style={{
                                display: 'inline-block',
                                position: 'relative',
                                float: 'right',
                                bottom: '1.9em',
                                left: '2em',
                              }}
                              onClick={this.handleMinusButton('telephone', index)}
                            >
                              <MinusButton />
                            </span>
                            )}
                        </div>
                      )))}
                    <span className="error">
                      {' '}
                      {error && errorPhone && <span>{error}</span>}
                    </span>
                    {params.overview
                      ? null : (
                        <FloatingActionButton
                          className="client-manager-form__plus-button"
                          backgroundColor="#01579B"
                          type="button"
                          mini
                          onClick={this.handleAddButton('telephone')}
                        >
                          <ContentAdd />
                        </FloatingActionButton>
                      )}
                  </div>
                  <div className="client-manager-form__input-wrapper email-long">
                    <label className="client-manager-form__label">E-MAIL*</label>
                    <div className="client-manager-form__input">
                      <Field
                        type="text"
                        component={RenderField}
                        name="email"
                        disabled={disabled}
                        validate={[required, email]}
                      />
                    </div>
                  </div>
                  <div className="client-manager-form__input-wrapper language-select" style={{ width: '200px' }}>
                    <label className="client-manager-form__label">LANGUAGES</label>
                    {_.map(stateLanguages,
                      (language, index) => (_.includes(deletedLanguages, index) ? null : (
                        <div key={index} className="client-manager-form__multiple-select">
                          <Select
                            name={`languages[${index}]`}
                            options={languageOptions}
                            clearable={false}
                            value={languages && languages[index] ? _.find(languageOptions, (option) => option.value === languages[index].code) : ''}
                            onChange={this.setSelectorValue(`languages[${index}].code`)}
                            disabled={disabled}
                          />
                          {params.overview ? null
                            : index !== 0 && <span onClick={this.handleMinusButton('language', index)}><MinusButton /></span>}
                        </div>
                      )))}
                    <span className="error">
                      {' '}
                      {error && errorLanguage && <span>{error}</span>}
                    </span>
                    {params.overview
                      ? null : (
                        <FloatingActionButton
                          className="client-manager-form__plus-button"
                          mini
                          type="button"
                          backgroundColor="#01579B"
                          onClick={this.handleAddButton('language')}
                        >
                          <ContentAdd />
                        </FloatingActionButton>
                      )}
                  </div>
                </div>
              </div>

              <label className="client-manager-form__label-strong">PARTNER ADDRESS</label>
              <div className="client-manager-form__address-group">
                <div className="client-manager-form__input-shorter-wrapper">
                  <label className="client-manager-form__label">STREET</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.street"
                      disabled={disabled}
                      validate={[maxLength255]}
                    />
                  </div>
                </div>
                <div className="client-manager-form__input-shorter-wrapper">
                  <label className="client-manager-form__label">CITY</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.city"
                      disabled={disabled}
                      validate={[maxLength255]}
                    />
                  </div>
                </div>
                <div className="client-manager-form__input-shorter-wrapper">
                  <label className="client-manager-form__label">STATE/PROVINCE</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.state"
                      disabled={disabled}
                      validate={[maxLength255]}
                    />
                  </div>
                </div>
                <div className="client-manager-form__input-shorter-wrapper">
                  <label className="client-manager-form__label">POSTAL CODE</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.postalCode"
                      disabled={disabled}
                      validate={[postalCode]}
                    />
                  </div>
                </div>
                <div className="client-manager-form__input-shorter-wrapper">
                  <label className="client-manager-form__label">COUNTRY</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.country"
                      disabled={disabled}
                      validate={[maxLength255]}
                    />
                  </div>
                </div>
              </div>
              <div className="client-manager-form__field-group">
                <div className="client-manager-form__input-box-wrapper">
                  <label className="client-manager-form__label-strong">COMMENT</label>
                  <Field
                    component={RenderTextArea}
                    name="comment"
                    placeholder="Enter your comment..."
                    disabled={params.overview}
                  />
                </div>
              </div>
            </form>
            {params.overview
              ? <BookingHistoryTable bookings={bookings[params.id]} entity="partnerManager" /> : null}
          </div>
        </EntityForm>
      </div>
    );
  }
}

const formConfig = {
  form: 'PartnerManagerForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
};

const selector = formValueSelector('PartnerManagerForm');

const mapDispatchToProps = (dispatch) => ({
  actions: {
    partnerManagers: bindActionCreators(partnerManagerActions, dispatch),
    partners: bindActionCreators(partnerActions, dispatch),
    bookings: bindActionCreators(bookingsActions, dispatch),
    notifySuccessA: bindActionCreators(notifySuccess, dispatch),
  },
});

const mapStateToProps = (state, props) => {
  let initialValues = {};
  if (props.match.params.id) {
    const partnerManager = state.entities.partnerManager[props.match.params.id];
    const dateOfBirth = partnerManager && new Date(partnerManager.dateOfBirth);
    initialValues = {
      ...partnerManager,
      dateOfBirth,
    };
  }
  const bookings = _.groupBy(state.entities.booking, 'partnerManager');
  const partners = state.entities.partner;
  return {
    bookings,
    partners,
    initialValues,
    partner: selector(state, 'partner'),
    gender: selector(state, 'gender'),
    languages: selector(state, 'languages'),
  };
};

PartnerManagerForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      overview: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  actions: PropTypes.shape({
    notifySuccessA: PropTypes.func,
    partners: PropTypes.shape({
      fetchPartners: PropTypes.func,
    }),
    partnerManagers: PropTypes.shape({
      fetchPartnerManagers: PropTypes.func,
      createPartnerManager: PropTypes.func,
      updatePartnerManager: PropTypes.func,
    }),
    bookings: PropTypes.shape({
      fetchBookingsTemp: PropTypes.func,
    }),
  }),
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  languages: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
  })),
  partner: PropTypes.number,
  gender: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  bookings: PropTypes.object,
  error: PropTypes.string,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.object,
  touch: PropTypes.func,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(PartnerManagerForm)));
