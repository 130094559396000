import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'redux-form-material-ui';
import { FlatButton } from 'material-ui';
import {
  Field,
  reduxForm,
} from 'redux-form';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { email, required } from '../utils/validators';

const indicatorStyle = {
  display: 'inline-block',
  position: 'relative',
};

const ForgotPasswordForm = ({
  handleSubmit,
  forgotPasswordSubmit,
  status,
  invalid,
}) => (
  <form onSubmit={handleSubmit(forgotPasswordSubmit)}>
    {status === 'success'
      ? <p>Please check your e-mail address and follow further directions</p>
      : (
        <div>
          <p className="forgotpass-label">Enter your email address:</p>
          <Field
            name="emailForgot"
            component={TextField}
            validate={[email, required]}
          />
          <FlatButton
            type="submit"
            label="Submit"
            primary
            disabled={invalid || status === 'success'}
            style={{
              float: 'right',
            }}
          />
          <RefreshIndicator
            size={35}
            left={10}
            top={0}
            status={status}
            style={indicatorStyle}
          />
        </div>
      )}
  </form>
);

const formConfig = {
  form: 'ForgotPassword',
};

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  forgotPasswordSubmit: PropTypes.func,
  status: PropTypes.string,
  invalid: PropTypes.bool,
};

export default reduxForm(formConfig)(ForgotPasswordForm);
