import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import RenderPhoneInput from './RenderPhoneInput';
import MinusButton from './MinusButton';
import { phone } from '../utils/validators';

const RenderPhoneArray = ({ fields }) => (
  <div className="client-manager-form__input-wrapper phone-long">
    <label className="client-manager-form__label">PHONE</label>
    {fields.map((field, index) => (
      <div key={`ph${index}`}>
        <div className="inline phone-number">
          <div className="client-manager-form__input">
            <Field
              component={RenderPhoneInput}
              name={field}
              labelClassName="client-manager-form__label"
              placeholder="Enter your phone number"
              validate={[phone]}
            />
          </div>
        </div>
        {index !== 0 && <MinusButton className="fixPosition" onClick={() => fields.remove(index)} />}
      </div>
    ))}
    <FloatingActionButton
      className="client-manager-form__plus-button"
      backgroundColor="#01579B"
      type="button"
      mini
      onClick={() => fields.push('')}
    >
      <ContentAdd />
    </FloatingActionButton>
  </div>
);

RenderPhoneArray.propTypes = {
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    map: PropTypes.func,
  }),
};

export default React.memo(RenderPhoneArray);
