import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import NotFound from '../components/NotFound';
import UserGuide from './UserGuide/UserGuide';

const Authentication = ({
  user,
  allowedRoles,
  children,
}) => {
  const { push } = useHistory();

  const {
    accessToken,
    role,
  } = user;

  useEffect(() => {
    if (!accessToken) {
      push('/login');
    }
  }, [accessToken]);

  return useMemo(() => {
    if (allowedRoles.includes(role) && accessToken) {
      return (
        <div className={`authored-access authored-access__${role}`}>
          <UserGuide />
          {children && React.cloneElement(children, { user })}
        </div>
      );
    }
    return <NotFound />;
  }, [allowedRoles, role, accessToken]);
};

const mapStateToProps = (state) => ({
  user: state.user,
});

Authentication.propTypes = {
  user: PropTypes.shape({
    accessToken: PropTypes.string,
    role: PropTypes.string,
  }),
  allowedRoles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.element,
};

export default connect(mapStateToProps)(Authentication);
