import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Drawer from '../components/Drawer';
import ProfileNav from './ProfileNav';
import Dialog from '../components/Dialog';
import Notification from '../components/Notification';
import { notifyError } from '../actions/notificationActions';
import '../styles/App.scss';
import { open } from '../actions/dialogActions';

const OneColumnLayout = (props) => {
  const {
    error,
    notifyErrorA,
    dialog,
    children,
  } = props;

  useEffect(() => {
    if (error && error.errorMessage) {
      notifyErrorA({}, error.errorMessage);
    }
  }, [error]);

  return (
    <div className="one-col">
      <Dialog {...dialog} />
      <div className="page__top">
        <div className="page__user-navigation">
          <ProfileNav />
        </div>
        <div>
          <Notification />
          <Drawer width={1000} />
          {children && React.cloneElement(children, props)}
        </div>
      </div>
    </div>
  );
};

OneColumnLayout.propTypes = {
  error: PropTypes.shape({
    errorMessage: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  dialog: PropTypes.object,
  notifyErrorA: PropTypes.func,
  children: PropTypes.element,
};

const mapStateToProps = (state) => ({
  dialog: state.dialog,
  error: state.error,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: bindActionCreators(open, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OneColumnLayout);
