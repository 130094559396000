import {
  TRIGGER_AVAILABILITY_FORM_CLOSE,
  TRIGGER_GROUP_FORM_CLOSE,
  TRIGGER_BOOKING_FORM_CLOSE,
} from '../containers/UserGuide/constants';

const initialState = {
  open: false,
  component: null,
  payload: null,
  props: null,
};

const drawer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_DRAWER':
      return {
        open: action.open,
        component: action.component,
        props: action.props,
        submitFunction: action.submitFunction,
      };
    case 'HIDE_DRAWER': {
      return initialState;
    }
    case TRIGGER_AVAILABILITY_FORM_CLOSE: {
      return initialState;
    }
    case TRIGGER_GROUP_FORM_CLOSE: {
      return initialState;
    }
    case TRIGGER_BOOKING_FORM_CLOSE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default drawer;
