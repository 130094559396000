import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
} from 'redux-form';
import _ from 'lodash';
import Select from 'react-select';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import { notifySuccess } from '../actions/notificationActions';
import * as clientsActions from '../actions/clientsActions';
import * as bookingsActions from '../actions/bookingActions';

import RenderField from '../components/RenderField';
import EntityForm from '../components/EntityForm';
import MinusButton from '../components/MinusButton';
import RenderSelect from '../components/RenderSelect';
import RenderTextArea from '../components/RenderTextArea';
import RenderPhoneInput from '../components/RenderPhoneInput';
import { DateField } from '../components/Field';

import BookingHistoryTable from './BookingHistoryTable';

import {
  email,
  required,
  maxLength255,
  phone,
  postalCode,
} from '../utils/validators';
import {
  formatLanguagesValues,
  formatPhoneNumbersValues,
  formatDateOfBirth,
  parseOptionValue,
} from '../utils/helpers';
import { languageOptions } from '../constants/LanguageOptions';
import '../styles/ProductAdd.scss';
import EditClientButton from '../components/EditClientButton';

class ClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      genderOptions: [
        { id: 'male', name: 'Male' },
        { id: 'female', name: 'Female' },
      ],
      phoneNumbers: [{}],
      languages: [{}],
      deletedPhoneNumbers: [],
      deletedLanguages: [],
      errorLanguage: false,
      errorPhone: false,
    };
  }

  componentDidMount() {
    const { match: { params: { id, overview } }, actions, initialValues } = this.props;

    if (!id) {
      return;
    }

    if (initialValues && !_.isEmpty(initialValues)) {
      let phoneNumbers = _.get(initialValues, 'phoneNumbers', [{}]);
      let languages = _.get(initialValues, 'languages', [{}]);

      phoneNumbers = phoneNumbers && phoneNumbers.length !== 0 ? phoneNumbers : [{}];
      languages = languages && languages.length !== 0 ? languages : [{}];

      this.setState({
        phoneNumbers,
        languages,
      });
    } else {
      actions.clients.fetchClient(id).then((response) => {
        const { client } = response.payload.result;

        let phoneNumbers = _.get(client[id], 'phoneNumbers', [{}]);
        let languages = _.get(client[id], 'languages', [{}]);

        phoneNumbers = phoneNumbers && phoneNumbers.length !== 0 ? phoneNumbers : [{}];
        languages = languages && languages.length !== 0 ? languages : [{}];

        this.setState({
          phoneNumbers,
          languages,
        });
      });
    }

    if (overview) {
      actions.bookings.fetchBookings();
    }
  }

  onSubmit = (values) => {
    const { deletedLanguages, deletedPhoneNumbers } = this.state;
    const { actions, match, history } = this.props;
    const { dateOfBirth } = values;
    const languages = formatLanguagesValues(values.languages, deletedLanguages);
    const phoneNumbers = formatPhoneNumbersValues(values.phoneNumbers, deletedPhoneNumbers);
    const formatedDateOfBirth = dateOfBirth ? formatDateOfBirth(dateOfBirth) : undefined;
    const gender = parseOptionValue(values.gender);
    const client = {
      ...values,
      dateOfBirth: formatedDateOfBirth,
      languages,
      phoneNumbers,
      gender,
    };
    if (dateOfBirth === 'Invalid date') {
      throw new SubmissionError({ dateOfBirth: 'This value is not a valid date' });
    }
    if (match.params.id) {
      return actions.clients.updateClient(match.params.id, client)
        .then(() => {
          history.push('/clients');
          actions.notifySuccess({}, 'Client updated successfully');
        }).catch((err) => {
          this.handleError(err);
        });
    }
    return actions.clients.createClient(client)
      .then(() => {
        history.push('/clients');
        actions.notifySuccess({}, 'Client created successfully');
      }).catch((err) => {
        this.handleError(err);
      });
  }

  setSelectorValue = (fieldName) => (value) => {
    const { change } = this.props;
    if (fieldName === 'month') {
      const month = value[0] + value[1];
      change(fieldName, month);
    }
    change(fieldName, value.value);
  }

  handleAddButton = (option) => () => {
    const { phoneNumbers, languages } = this.state;
    switch (option) {
      case 'telephone': {
        const tempPhoneNumbers = phoneNumbers || [];
        tempPhoneNumbers.push({});
        this.setState({
          phoneNumbers: tempPhoneNumbers,
        });
        break;
      }
      case 'language': {
        const tempLanguages = languages || [];
        tempLanguages.push({});
        this.setState({
          languages: tempLanguages,
        });
        break;
      }
      default:
        break;
    }
  }

  handleMinusButton = (option, id) => () => {
    switch (option) {
      case 'telephone': {
        const { deletedPhoneNumbers } = this.state;
        deletedPhoneNumbers.push(id);
        this.setState({
          deletedPhoneNumbers,
        });
        break;
      }
      case 'language': {
        const { deletedLanguages } = this.state;
        deletedLanguages.push(id);
        this.setState({
          deletedLanguages,
        });
        break;
      }
      default:
        break;
    }
  }

  handleError = (err) => {
    const property = _.get(err, 'data.constraintViolations[0].property', '');
    const message = _.get(err, 'data.constraintViolations[0].message', '');

    if (property === 'phoneNumbers[phoneNumber]') {
      this.setState({ errorPhone: true, errorLanguage: false });
      throw new SubmissionError({ _error: message });
    }
    if (property === 'languages[code]') {
      this.setState({ errorLanguage: true, errorPhone: false });
      throw new SubmissionError({ _error: message });
    }
    if (property) {
      throw new SubmissionError({ [property]: message });
    }
  }

  render() {
    const {
      errorLanguage,
      errorPhone,
      genderOptions,
      phoneNumbers,
      deletedPhoneNumbers,
      languages: stateLanguages,
      deletedLanguages,
    } = this.state;
    const {
      handleSubmit,
      match: { params },
      gender,
      languages,
      bookings,
      initialValues,
      submitting,
      pristine,
      error,
      invalid,
      touch,
    } = this.props;

    return (
      <div>
        {params.overview
          ? (
            <EditClientButton
              link
              label="EDIT PROFILE"
              path={`/clients/${params.id}`}
            />
          ) : null}
        <EntityForm
          formDestination="Client"
          handleSubmit={handleSubmit(this.onSubmit)}
          editing={!!params.id}
          overview={params.overview}
          submitting={submitting}
          pristine={pristine}
          invalid={invalid}
        >
          <div className="client-manager-form">
            <form
              onSubmit={handleSubmit(this.onSubmit)}
              id="client-form"
            >
              <div className="client-manager-form__main-info">
                <div className="client-manager-form__field-group">
                  {params.overview
                    ? (
                      <div style={{ display: 'inline-block' }}>
                        <div className="client-manager-form__input-wrapper">
                          <label className="client-manager-form__label">NAME</label>
                          <div className="client-manager-form__input">
                            <div
                              className="client-manager-form__overview-name"
                            >
                              {initialValues.name}
                              {initialValues.surname}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div style={{ display: 'inline-block' }}>
                        <div className="client-manager-form__input-wrapper">

                          <label className="client-manager-form__label">NAME*</label>
                          <div className="client-manager-form__input">
                            <Field
                              type="text"
                              component={RenderField}
                              name="name"
                              disabled={params.overview}
                              validate={[required, maxLength255]}
                            />
                          </div>

                        </div>
                        <div className="client-manager-form__input-wrapper">
                          <label className="client-manager-form__label">SURNAME*</label>
                          <div className="client-manager-form__input">
                            <Field
                              type="text"
                              component={RenderField}
                              name="surname"
                              disabled={params.overview}
                              validate={[required, maxLength255]}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  <div className="client-manager-form__input-gender-wrapper">
                    <label className="client-manager-form__label">GENDER</label>
                    <Field
                      component={RenderSelect}
                      name="gender"
                      clearable={false}
                      options={genderOptions}
                      value={gender || ''}
                      onChange={this.setSelectorValue('gender')}
                      disabled={params.overview}
                    />
                  </div>
                  <div className="client-manager-form__date-wrapper">
                    <label className="client-manager-form__label">BIRTHDAY</label>
                    <DateField
                      name="dateOfBirth"
                      className="date"
                      disableError
                      disabled={params.overview}
                      underlineStyle={params.overview && { display: 'none' }}
                      maxDate={new Date()}
                      onShow={() => touch('dateOfBirth')}
                      openToYearSelection
                    />
                  </div>
                </div>
                <div className="client-manager-form__field-group">
                  <div className="client-manager-form__input-wrapper phone-long">
                    <label className="client-manager-form__label">PHONE</label>
                    {_.map((phoneNumbers),
                      (telephone, index) => (_.includes(deletedPhoneNumbers, index) ? null
                        : (
                          <div key={index} style={{ display: 'block' }}>
                            <div style={{ display: 'inline-block', position: 'relative' }}>
                              <div className="client-manager-form__input">
                                <Field
                                  component={RenderPhoneInput}
                                  name={`phoneNumbers[${index}].phoneNumber`}
                                  disabled={params.overview}
                                  validate={[phone]}
                                  placeholder="Enter your phone number"
                                />
                              </div>
                            </div>
                            {params.overview ? null : index !== 0
                              && (
                              <span
                                style={{
                                  display: 'inline-block',
                                  position: 'relative',
                                  float: 'right',
                                  bottom: '1.9em',
                                  left: '2em',
                                }}
                                onClick={this.handleMinusButton('telephone', index)}
                              >
                                <MinusButton />
                              </span>
                              )}
                          </div>
                        )))}
                    <span className="error">
                      {' '}
                      {error && errorPhone && <span>{error}</span>}
                    </span>
                    {params.overview
                      ? null : (
                        <FloatingActionButton
                          className="client-manager-form__plus-button"
                          backgroundColor="#01579B"
                          type="button"
                          mini
                          onClick={this.handleAddButton('telephone')}
                        >
                          <ContentAdd />
                        </FloatingActionButton>
                      )}
                  </div>
                  <div className="client-manager-form__input-wrapper email-long">
                    <label className="client-manager-form__label">E-MAIL*</label>
                    <div className="client-manager-form__input">
                      <Field
                        type="text"
                        component={RenderField}
                        name="email"
                        disabled={params.overview}
                        validate={[required, email]}
                      />
                    </div>
                  </div>
                  <div className="client-manager-form__input-wrapper language-select" style={{ width: '200px' }}>
                    <label className="client-manager-form__label">LANGUAGES</label>
                    {_.map((stateLanguages),
                      (language, index) => (_.includes(deletedLanguages, index) ? null
                        : (
                          <div key={index} className="client-manager-form__multiple-select">
                            <Select
                              name={`languages[${index}]`}
                              options={languageOptions}
                              clearable={false}
                              value={languages && languages[index] ? _.find(languageOptions, (option) => option.value === languages[index].code) : ''}
                              onChange={this.setSelectorValue(`languages[${index}].code`)}
                              disabled={params.overview}
                            />
                            {params.overview ? null : index !== 0
                              && <span onClick={this.handleMinusButton('language', index)}><MinusButton /></span>}
                          </div>
                        )))}
                    <span className="error">
                      {' '}
                      {error && errorLanguage && <span>{error}</span>}
                    </span>
                    {params.overview
                      ? null : (
                        <FloatingActionButton
                          className="client-manager-form__plus-button"
                          mini
                          type="button"
                          backgroundColor="#01579B"
                          onClick={this.handleAddButton('language')}
                        >
                          <ContentAdd />
                        </FloatingActionButton>
                      )}
                  </div>
                </div>
              </div>

              <label className="client-manager-form__label-strong">ADDRESS</label>

              <div className="client-manager-form__address-group">
                <div className="client-manager-form__input-shorter-wrapper client-manager-form__street">
                  <label className="client-manager-form__label">STREET</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.street"
                      disabled={params.overview}
                      validate={[maxLength255]}
                    />
                  </div>
                </div>
                <div className="client-manager-form__input-shorter-wrapper client-manager-form__city">
                  <label className="client-manager-form__label">CITY</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.city"
                      disabled={params.overview}
                      validate={[maxLength255]}
                    />
                  </div>
                </div>
                <div className="client-manager-form__input-shorter-wrapper">
                  <label className="client-manager-form__label">STATE/PROVINCE</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.state"
                      disabled={params.overview}
                      validate={[maxLength255]}
                    />
                  </div>
                </div>
                <div className="client-manager-form__input-shorter-wrapper">
                  <label className="client-manager-form__label">POSTAL CODE</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.postalCode"
                      disabled={params.overview}
                      validate={[postalCode]}
                    />
                  </div>
                </div>
                <div className="client-manager-form__input-shorter-wrapper">
                  <label className="client-manager-form__label">COUNTRY</label>
                  <div className="client-manager-form__input">
                    <Field
                      type="text"
                      component={RenderField}
                      name="address.country"
                      disabled={params.overview}
                      validate={[maxLength255]}
                    />
                  </div>
                </div>
              </div>
              <div className="client-manager-form__field-group">
                <div className="client-manager-form__input-box-wrapper">
                  <label className="client-manager-form__label-strong">COMMENT</label>
                  <Field
                    component={RenderTextArea}
                    name="comment"
                    placeholder="Enter your comment..."
                    disabled={params.overview}
                  />
                </div>
                <div className="client-manager-form__separator" />
                <div className="client-manager-form__input-box-wrapper">
                  <label className="client-manager-form__label-strong">SPECIAL CLIENT NEEDS</label>
                  <Field
                    component={RenderTextArea}
                    name="specialClientNeeds"
                    placeholder="Enter special needs..."
                    disabled={params.overview}
                  />
                </div>
              </div>
              {params.overview
                ? <BookingHistoryTable bookings={bookings[params.id]} entity="client" /> : null}
            </form>
          </div>
        </EntityForm>
      </div>
    );
  }
}

const formConfig = {
  form: 'ClientForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
};

const selector = formValueSelector('ClientForm');

const mapDispatchToProps = (dispatch) => ({
  actions: {
    clients: bindActionCreators(clientsActions, dispatch),
    bookings: bindActionCreators(bookingsActions, dispatch),
    notifySuccess: bindActionCreators(notifySuccess, dispatch),
  },
});

const mapStateToProps = (state, props) => {
  let initialValues = {};
  const client = _.get(state, `entities.client[${props.match.params.id}]`, {});
  const address = _.get(client, 'address', {});
  const dateOfBirth = client.dateOfBirth && new Date(client.dateOfBirth);

  if (props.match.params.id) {
    if (!(_.isEmpty(client) && !dateOfBirth && _.isEmpty(address))) {
      initialValues = {
        ...client,
        dateOfBirth,
        address: address && Object.keys(address).length === 0 ? {} : address,
      };
    }
  }

  const bookings = _.groupBy(state.entities.booking, 'client');

  return {
    bookings,
    initialValues,
    languages: selector(state, 'languages'),
    gender: selector(state, 'gender'),
  };
};

ClientForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      overview: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  actions: PropTypes.shape({
    notifySuccess: PropTypes.func,
    clients: PropTypes.shape({
      fetchClient: PropTypes.func,
      createClient: PropTypes.func,
      updateClient: PropTypes.func,
    }),
    bookings: PropTypes.shape({
      fetchBookings: PropTypes.func,
    }),
  }),
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  gender: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  languages: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  bookings: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  error: PropTypes.string,
  invalid: PropTypes.bool,
  touch: PropTypes.func,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(ClientForm)));
