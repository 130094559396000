import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Field, reduxForm, formValueSelector, SubmissionError,
} from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import RaisedButton from 'material-ui/RaisedButton';
import { withRouter } from 'react-router';
import { get, map, pick } from 'lodash';
import * as accountActions from '../actions/accountActions';
import StripeWarningDisplay from '../components/StripeWarningDisplay';
import EntityForm from '../components/EntityForm';
import RenderSelect from '../components/RenderSelect';
import ResortSelect from '../components/ResortSelect';
import RenderTextArea from '../components/RenderTextArea';
import RenderToggleSimple from '../components/RenderToggleSimple';
import Dropzone from '../components/dropzone/Dropzone';
import {
  required, notEmpty, minValue0, maxLength255, maxLength65535,
} from '../utils/validators';
import { notifySuccess } from '../actions/notificationActions';
import { changeAccountResort } from '../actions/accountActions';
import { parseOptionValue } from '../utils/helpers';
import currencies from '../currencies.json';
import { goToStep } from '../actions/userGuide';
import invokeURL from '../../config/api-config';

const styles = {
  underlineStyle: {
    borderColor: 'rgba(32,51,90,0.4)',
  },
  underlineFocusStyle: {
    borderColor: 'rgba(32,51,90,0.4)',
  },
  inputStyle: {
    color: '#253D63',
    fontSize: '16px',
    lineHeight: '24px',
  },
};

const anchorStyle = {
  position: 'relative',
  right: '25%',
  height: '1px',
  width: '1px',
  bottom: '20px',
};

const anchorBoxStyle = {
  position: 'relative',
  height: '1px',
  width: '1px',
  top: '40px',
  left: '50%',
};

const anchorBoxStyleOposite = {
  position: 'relative',
  height: '1px',
  width: '1px',
  bottom: '100px',
  left: '50%',
};

const centerStyle = {
  'text-align': 'center',
};

const returnBookingTextPlaceHolder = (accountName) => (
  `${'Dear Client, \n'
  + '\n'
  + 'We would like to confirm your Booking. Please find attached the invoice with all information and the bookings deadline.'
  + 'To ensure your reservation, please make the payment before the deadline date. \n'
  + '\n'
  + 'For payments with a credit card, please use the link below: \n'
  + '\n'
  + '<payment-link> \n'
  + '\n'
  + 'Thank you for using our services! \n'
  + '\n'
  + 'Kind regards, \n'
  + '\n'}${accountName}`
);

class SchoolSettings extends Component {
  constructor() {
    super();
    this.state = {
      currencyOptions: map(currencies, (c) => ({ id: c, name: c })),
      fieldsNames: [
        'currency',
        'timezone',
        'vat',
        'companyName',
        'paymentLocation',
        'invoiceDueDateLength',
        'salaryInvoiceText',
        'salaryInvoiceTextBottom',
        'bookingInvoiceText',
        'address',
        'resort',
        'representative',
        'iban',
        'bic',
        'accountNumber',
        'fee',
        'description',
        'sixPaymentAccountId',
        'sixPaymentAccountPass',
        'sixPaymentGatewayAccountId',
        'sixPaymentTerminalId',
        'StripeAccountId',
        'pdfLogo',
        'sendAlertBookingMail',
      ],
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { actions: { account: { fetchMyAccount } } } = this.props;
    fetchMyAccount();
  }

  componentDidMount() {
    const { userGuide, actions: { goToStepA } } = this.props;
    const { general, currentGuide, step } = userGuide;
    if (general && currentGuide === 'schoolSettings' && step === 0) {
      setTimeout(() => goToStepA(1), 250);
    }
  }

  onSubmit(account) {
    const {
      actions: {
        account: { updateMyAccount },
        changeAccountResortA,
        notifySuccessA,
      },
      resort,
    } = this.props;
    const { fieldsNames, pdfLogo } = this.state;
    changeAccountResortA(resort);
    const fixedAccount = { ...account };
    fixedAccount.resort = parseOptionValue(account.resort);
    fixedAccount.currency = parseOptionValue(account.currency);
    fixedAccount.invoiceDueDateLength = parseInt(account.invoiceDueDateLength, 10);

    if (!fixedAccount.vat) {
      fixedAccount.vat = 0;
    }
    if (!fixedAccount.fee) {
      fixedAccount.fee = 0;
    }
    if (pdfLogo) {
      fixedAccount.pdfLogo = pdfLogo;
    }

    const data = pick(fixedAccount, fieldsNames);

    return updateMyAccount(data).then(() => {
      notifySuccessA({}, 'Account updated successfully');
    }).catch((err) => {
      this.handleError(err);
    });
  }

  stripeOnboard = () => {
    const { user } = this.props;
    window.location.href = `${invokeURL.invokeUrl}/stripe/onboard/${user.id}`;
  };

  stripePortal = () => {
    const { user } = this.props;
    window.location.href = `${invokeURL.invokeUrl}/stripe/portal/${user.id}`;
  };

  setSelectorValue = (fieldName) => (value) => {
    const { change } = this.props;
    const val = fieldName === 'resort' ? value : value.value;
    change(fieldName, val);
  };

  handleError = (err) => {
    const property = get(err, 'data.constraintViolations[0].property', '');
    const message = get(err, 'data.constraintViolations[0].message', '');
    if (property) {
      throw new SubmissionError({ [property]: message });
    }
  };

  onPdfLogoDrop = (acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = () => {
      const imgBase64 = reader.result;
      const img = document.getElementById('img-pdf-logo');
      const dzbox = document.getElementById('dropzone-box');
      const dzdel = document.getElementById('dropzone-del');
      img.src = imgBase64;
      dzbox.hidden = true;
      dzdel.hidden = false;
      this.setState({ pdfLogo: imgBase64 });
      const imgInput = document.getElementById('pdfLogo');
      imgInput.value = imgBase64;
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }

  onPdfLogoDelete = () => {
    const { change } = this.props;
    change('pdfLogo', null);
    this.setState({ pdfLogo: '' });
    const img = document.getElementById('img-pdf-logo');
    const dzbox = document.getElementById('dropzone-box');
    const dzdel = document.getElementById('dropzone-del');
    img.src = '';
    dzbox.hidden = false;
    dzdel.hidden = true;
  };

  render() {
    const {
      handleSubmit,
      currency,
      invalid,
      submitting,
      user,
      resort,
      companyName,
      stripeAccountId,
      pdfLogo,
      sendAlertBookingMail,
    } = this.props;
    const { currencyOptions } = this.state;
    const note = '*Note: to insert payment link use <payment-link>';
    return (
      <EntityForm
        formDestination="School settings"
        handleSubmit={handleSubmit(this.onSubmit.bind(this))}
        invalid={invalid}
        editing
        submitting={submitting}
        user={user}
      >
        <StripeWarningDisplay />
        <div className="lesson-type-form">
          <form
            onSubmit={handleSubmit(this.onSubmit.bind(this))}
            className="centered-form"
          >
            <div className="account-settings__section-header">
              <span>
                Basic settings
              </span>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Representative:*</label>
              <div className="lesson-type-form__input">
                <Field
                  name="representative"
                  component={TextField}
                  type="text"
                  hintText=""
                  validate={[required]}
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Resort:*</label>
              <div className="lesson-type-form__input">
                <Field
                  name="resort"
                  component={ResortSelect}
                  resorts={resort}
                  addResorts={this.setSelectorValue('resort')}
                  validate={[required, notEmpty]}
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Currency:*</label>
              <div className="lesson-type-form__input account-currency">
                <Field
                  component={RenderSelect}
                  className="currency"
                  name="currency"
                  clearable={false}
                  options={currencyOptions}
                  value={currency || ''}
                  onChange={this.setSelectorValue('currency')}
                  validate={[required, notEmpty]}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>VAT percentage:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="vat"
                  component={TextField}
                  type="number"
                  hintText=""
                  min={0}
                  max={100}
                  step={0.01}
                  validate={[minValue0]}
                  {...styles}
                />
                <span id="vat-anchor" style={anchorStyle} />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>School administration fee:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="fee"
                  component={TextField}
                  type="number"
                  hintText=""
                  min={0}
                  step={0.01}
                  validate={[minValue0]}
                  {...styles}
                />
                <span id="administration-fee-anchor" style={anchorStyle} />
              </div>
            </div>
            <div className="account-settings__box-wrapper">
              <label>School representation:</label>
              <div className="account-settings__box-input">
                <Field
                  name="description"
                  component={RenderTextArea}
                  placeholder="Enter your representation text..."
                />
                <span id="description-anchor" style={anchorBoxStyle} />
              </div>
            </div>
            <div className="account-settings__section-header">
              <span>
                School address
              </span>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Street:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="address.street"
                  component={TextField}
                  type="text"
                  hintText=""
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>City:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="address.city"
                  component={TextField}
                  type="text"
                  hintText=""
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>State:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="address.state"
                  component={TextField}
                  type="text"
                  hintText=""
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Postal code:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="address.postalCode"
                  component={TextField}
                  type="text"
                  hintText=""
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Country:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="address.country"
                  component={TextField}
                  type="text"
                  hintText=""
                  {...styles}
                />
                <span id="address-anchor" style={anchorStyle} />
              </div>
            </div>
            <div className="account-settings__section-header">
              <span>
                Instructor salary settings
              </span>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label className="account-settings__preset">
                Preset company:
              </label>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Company:*</label>
              <div className="lesson-type-form__input">
                <Field
                  name="companyName"
                  component={TextField}
                  type="text"
                  hintText=""
                  validate={[required]}
                  {...styles}
                />
              </div>
            </div>

            <div className="lesson-type-form__input-wrapper">
              <label>Payment location:*</label>
              <div className="lesson-type-form__input">
                <Field
                  name="paymentLocation"
                  component={TextField}
                  type="text"
                  hintText=""
                  validate={[required]}
                  {...styles}
                />
              </div>
            </div>
            <div className="account-settings__box-wrapper">
              <label>Salary sheet primary text:</label>
              <div className="account-settings__box-input">
                <Field
                  name="salaryInvoiceText"
                  component={RenderTextArea}
                  validate={[maxLength255]}
                  placeholder="Enter your salary text..."
                />
                <span id="salary-sheets-anchor" style={anchorBoxStyle} />
              </div>
            </div>
            <div className="account-settings__box-wrapper">
              <label>Salary sheet secondary text:</label>
              <div className="account-settings__box-input">
                <Field
                  name="salaryInvoiceTextBottom"
                  component={RenderTextArea}
                  validate={[maxLength255]}
                  placeholder="Enter your salary text..."
                />
              </div>
            </div>
            <div className="account-settings__section-header">
              <span>
                Booking invoice settings
              </span>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>IBAN:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="iban"
                  component={TextField}
                  hintText=""
                  {...styles}
                />
                <span id="invoicing-anchor" style={anchorStyle} />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>BIC:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="bic"
                  component={TextField}
                  hintText=""
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Account number:</label>
              <div className="lesson-type-form__input">
                <Field
                  name="accountNumber"
                  component={TextField}
                  hintText=""
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Invoice due date length:*</label>
              <div className="lesson-type-form__input account-currency">
                <Field
                  component={TextField}
                  name="invoiceDueDateLength"
                  type="number"
                  min={0}
                  hintText=""
                  {...styles}
                  validate={[required]}
                />
              </div>
            </div>
            <div className="account-settings__box-wrapper">
              <label>Booking text:</label>
              <div className="account-settings__box-input account-settings__box-input--big">
                <Field
                  name="bookingInvoiceText"
                  component={RenderTextArea}
                  validate={[maxLength65535]}
                  note={note}
                  placeholder={returnBookingTextPlaceHolder(companyName)}
                />
                <span id="confirmation-text-anchor" style={anchorBoxStyleOposite} />
              </div>
            </div>

            <div className="lesson-type-form__input-wrapper">
              <label>Send alert mail before booking:</label>
              <div className="lesson-type-form__input">
                <Field
                  component={RenderToggleSimple}
                  name="sendAlertBookingMail"
                  onChange={() => {}}
                  value={sendAlertBookingMail}
                  // normalize={(v) => (isBoolean(v) ? v : false)}
                />
                <span id="confirmation-text-anchor" style={anchorBoxStyleOposite} />
              </div>
            </div>

            <div className="account-settings__box-wrapper">
              <label>PDF logo:</label>
              <div className="account-settings__box-input account-settings__box-input--big">
                <div hidden={!!pdfLogo} id="dropzone-box">
                  <Dropzone
                    className="dropzone dropzone__edit-account-settings-pdf-logo"
                    multiple={false}
                    accept="image/png"
                    maxSize={5242880}
                    onDrop={this.onPdfLogoDrop}
                  >
                    <>
                      <p>Drop an image or click to select a file to upload.</p>
                    </>
                  </Dropzone>
                </div>
                <img id="img-pdf-logo" src={pdfLogo} alt="" />
              </div>
              <div
                id="dropzone-del"
                className="delete-text"
                hidden={!pdfLogo}
                onClick={this.onPdfLogoDelete}
              >
                Delete
              </div>
              <div hidden>
                <Field
                  name="pdfLogo"
                  id="pdfLogo"
                  component={TextField}
                  type="hidden"
                  value={pdfLogo}
                />
              </div>
            </div>

            <div>
              <div className="account-settings__section-header">
                <span>
                  Six Payment settings
                </span>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Account ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="sixPaymentAccountId"
                    component={TextField}
                    type="text"
                    hintText=""
                    autoComplete="off"
                    {...styles}
                  />
                </div>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Password:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="sixPaymentAccountPass"
                    component={TextField}
                    type="password"
                    hintText=""
                    autoComplete="off"
                    {...styles}
                  />
                </div>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Gateway account ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="sixPaymentGatewayAccountId"
                    component={TextField}
                    type="text"
                    hintText=""
                    autoComplete="off"
                    {...styles}
                  />
                </div>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Terminal ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="sixPaymentTerminalId"
                    component={TextField}
                    autoComplete="off"
                    type="text"
                    hintText=""
                    {...styles}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="account-settings__section-header">
                <span>
                  Stripe Payment settings
                </span>
              </div>
              <div className="account-settings__box-wrapper">
                <label>Account ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="stripeAccountId"
                    component={TextField}
                    type="text"
                    hintText=""
                    disabled
                    {...styles}
                  />
                </div>
                <label>Customer ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="stripeCustomerId"
                    component={TextField}
                    type="text"
                    hintText=""
                    disabled
                    {...styles}
                  />
                </div>
                <label>Product ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="stripeProductId"
                    component={TextField}
                    type="text"
                    hintText=""
                    disabled
                    {...styles}
                  />
                </div>
                <div className="account-settings__box-wrapper centered-div" style={centerStyle}>
                  <RaisedButton
                    secondary
                    onClick={this.stripeOnboard}
                    // backgroundColor="#253D63"
                    // labelColor="#fff"
                    label="Stripe onboard"
                  />
                  <span>&nbsp;</span>
                  <RaisedButton
                    secondary
                    onClick={this.stripePortal}
                    // backgroundColor="#253D63"
                    // labelColor="#fff"
                    label="Stripe portal"
                    disabled={!stripeAccountId}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </EntityForm>
    );
  }
}

SchoolSettings.propTypes = {
  actions: PropTypes.shape({
    account: PropTypes.shape({
      fetchMyAccount: PropTypes.func,
      updateMyAccount: PropTypes.func,
    }),
    notifySuccessA: PropTypes.func,
    goToStepA: PropTypes.func,
    changeAccountResortA: PropTypes.func,
  }),
  userGuide: PropTypes.shape({
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
  }),
  change: PropTypes.func,
  resort: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  handleSubmit: PropTypes.func,
  currency: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  companyName: PropTypes.string,
  stripeAccountId: PropTypes.string,
  pdfLogo: PropTypes.string,
  sendAlertBookingMail: PropTypes.bool,
};

const formConfig = {
  form: 'SchoolSettings',
  enableReinitialize: true,
};

const selector = formValueSelector('SchoolSettings');

const mapStateToProps = (state, props) => {
  const id = get(props.match.params, 'id', '') || state.user.id;
  const resortId = get(state.entities.account, `[${id}].resort`, '');
  const stripeAccountId = get(state.entities.account, `[${id}].stripeAccountId`, '');
  const resort = get(state.entities.resort, `[${resortId}]`, '');
  const pdfLogo = get(state.entities.account, `[${id}].pdfLogo`, '');
  const sendAlertBookingMail = get(state.entities.account, `[${id}].sendAlertBookingMail`, '');

  const initialValues = {
    ...state.entities.account[Object.keys(state.entities.account)],
    resort,
  };

  return {
    initialValues,
    currency: selector(state, 'currency'),
    resort: selector(state, 'resort'),
    companyName: selector(state, 'companyName'),
    stripeAccountId,
    userGuide: state.userGuide,
    pdfLogo,
    sendAlertBookingMail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    account: bindActionCreators(accountActions, dispatch),
    notifySuccessA: bindActionCreators(notifySuccess, dispatch),
    goToStepA: bindActionCreators(goToStep, dispatch),
    changeAccountResortA: bindActionCreators(changeAccountResort, dispatch),
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(reduxForm(formConfig)(SchoolSettings)),
);
