import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import hotelSchema from '../schema/Hotel';

export const fetchHotels = () => httpRequest({
  method: 'GET',
  path: '/hotel',
  types: [
    'HOTELS_GET_REQUEST',
    'HOTELS_GET_SUCCESS',
    'HOTELS_GET_FAILURE',
  ],
  schema: new schema.Array(hotelSchema),
});

export const updateHotel = (id, body) => httpRequest({
  method: 'PUT',
  path: `/hotel/${id}`,
  body,
  types: [
    'HOTELS_PUT_REQUEST',
    'HOTELS_PUT_SUCCESS',
    'HOTELS_PUT_FAILURE',
  ],
  schema: hotelSchema,
});

export const createHotel = (body) => httpRequest({
  method: 'POST',
  path: '/hotel',
  body,
  types: [
    'HOTELS_POST_REQUEST',
    'HOTELS_POST_SUCCESS',
    'HOTELS_POST_FAILURE',
  ],
  schema: hotelSchema,
});

export const deleteHotel = (id) => httpRequest({
  path: `/hotel/${id}`,
  method: 'DELETE',
  types: [
    'HOTEL_DELETE_REQUEST',
    'HOTEL_DELETE_SUCCESS',
    'HOTEL_DELETE_FAILURE',
  ],
  schema: hotelSchema,
});

export const deleteHotels = (body) => httpRequest({
  path: '/hotel/delete',
  method: 'POST',
  body,
  types: [
    'HOTELS_DELETE_REQUEST',
    'HOTELS_DELETE_SUCCESS',
    'HOTELS_DELETE_FAILURE',
  ],
  clear: true,
  schema: hotelSchema,
});

export const hotelsImport = (body) => httpRequest({
  method: 'POST',
  body,
  path: '/hotel/import',
  types: [
    'HOTELS_IMPORT_POST_REQUEST',
    'HOTELS_IMPORT_POST_SUCCESS',
    'HOTELS_IMPORT_POST_FAILURE',
  ],
  schema: null,
});

export const hotelsExport = () => httpRequest({
  method: 'GET',
  path: '/hotel/export',
  types: [
    'HOTEL_EXPORT_GET_REQUEST',
    'HOTEL_EXPORT_GET_SUCCESS',
    'HOTEL_EXPORT_GET_FAILURE',
  ],
  schema: null,
});
