import React from 'react';
import PropTypes from 'prop-types';
import ProfileNav from '../containers/ProfileNav';
import '../styles/Page.scss';

const Page = ({ name, children, titleSpacing = false }) => (
  <div className="page">
    <div className="page__wrapper">
      <div className="page__top">
        <div className="page__user-navigation">
          <ProfileNav />
        </div>
      </div>
      <div className="page__main">
        <div className={titleSpacing ? 'page__title--spacing' : 'page__title'}>
          <span className="page__header">
            {name}
          </span>
        </div>
        <div className="page__content">
          {children}
        </div>
      </div>
    </div>
  </div>
);

Page.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
  titleSpacing: PropTypes.bool,
};

export default Page;
