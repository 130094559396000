import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'redux-form-material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { omit } from 'lodash';
import skiThemeLessonTimepicker from '../styles/theme/skiThemeLessonTimepicker';
import skiThemeProductForm from '../styles/theme/skiThemeProductForm';

const RenderTimePicker = (props) => {
  const {
    theme,
    input,
    meta,
  } = props;
  return (
    <div className="datepicker">
      {theme === 'white'
        ? (
          <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonTimepicker)}>
            <TimePicker
              {...omit(props, 'theme', 'group')}
              name={input.name}
            />
          </MuiThemeProvider>
        )
        : (
          <MuiThemeProvider muiTheme={getMuiTheme(skiThemeProductForm)}>
            <TimePicker
              {...omit(props, 'theme', 'group')}
              name={input.name}
            />
          </MuiThemeProvider>
        )}
      <span className="error">
        {' '}
        {meta.touched && meta.error && <span>{meta.error}</span>}
      </span>
    </div>
  );
};

RenderTimePicker.propTypes = {
  theme: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default React.memo(RenderTimePicker);
