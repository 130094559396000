import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import '../styles/EntityForm.scss';

const EntityForm = ({
  formDestination,
  handleSubmit,
  children,
  editing,
  overview,
  submitting,
  handleRedirect,
  invalid,
  warns,
  user,
  isSeasonNotValid,
}) => {
  const label = useMemo(() => {
    if (formDestination === 'Instructor' && !editing) {
      return (
        <div className="entity-form__button-label">An account will be created for instructor</div>
      );
    }
    return '';
  }, [formDestination, editing]);
  const buttonText = useMemo(() => {
    if (user && (user.role === 'instructor' || user.role === 'headCoach')) {
      if (submitting) {
        return 'Updating... My account';
      }
      return 'Update My account';
    }
    if (editing) {
      if (submitting) {
        return `Updating... ${formDestination}`;
      }
      return `Update ${formDestination}`;
    }
    if (submitting) {
      return `Creating... ${formDestination}`;
    }
    return `Create ${formDestination}`;
  }, [user, submitting, editing]);

  return (
    <div className="entity-form">
      {children}
      <div className={overview ? 'entity-form__submit-overview' : 'entity-form__submit'}>
        {overview ? null
          : (
            <div className="align-center">
              <button
                disabled={isSeasonNotValid || invalid || !isEmpty(warns)}
                type="button"
                onClick={handleSubmit}
                className="inline w300"
              >
                {buttonText}
              </button>
              {user && (user.role === 'instructor' || user.role === 'headCoach')
                ? (
                  <button
                    className="inline w300"
                    onClick={handleRedirect}
                    style={{ marginLeft: '20px' }}
                    type="button"
                  >
                    Availability list
                  </button>
                ) : label}
            </div>
          )}
      </div>
    </div>
  );
};

EntityForm.propTypes = {
  formDestination: PropTypes.string,
  handleSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
  editing: PropTypes.bool,
  overview: PropTypes.bool,
  submitting: PropTypes.bool,
  handleRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  warns: PropTypes.object,
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  isSeasonNotValid: PropTypes.bool,
};

export default React.memo(EntityForm);
