import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  forEach,
  includes,
  remove,
  concat,
  map,
  values as lodashValues,
  find,
  clone,
  minBy,
  maxBy,
  keyBy,
} from 'lodash';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import ReactTable from 'react-table';
import MassActionsComponent from '../components/MassActionsComponent';

import Component from '../components/Component';
import GroupForm from './GroupForm';
import { GroupListColumns } from './TableColumns';

import * as groupsActions from '../actions/groupsActions';
import * as instructorsActions from '../actions/instructorsActions';
import * as specialitiesActions from '../actions/specialityActions';
import * as indicatorActions from '../actions/refreshIndicatorActions';
import { notifySuccess, notifyError } from '../actions/notificationActions';
import { fetchGroup, fetchGroupPrice, fetchGroupByGuid } from '../actions/groupsActions';
import { fetchResort } from '../actions/resortsActions';

import { showDrawer, hideDrawer } from '../actions/drawerActions';
import { fetchSpeciality } from '../actions/specialityActions';

import { formatGroupListData } from '../utils/format';
import '../styles/GroupList.scss';
import { pageSizes } from '../utils/helpers';
import { downloadCsvFile } from '../utils/csvHelper';

class GroupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      massActions: [
        { text: 'Delete', value: 0 },
        { text: 'Export', value: 1 },
      ],
      selectedMassAction: 0,
      selectedGroups: [],
      selectedGuids: [],
      excludedGroups: [],
      allPageGuids: [],
      pageSize: 20,
      pageNumber: 0,
      orderBy: {
        column: 'timeFrom',
        direction: 'ASC',
      },
      selectAll: false,
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.indicatorActions.startLoading('groups');
    Promise.all([
      this.fetchData(),
      actions.instructors.fetchInstructors(),
      actions.specialities.fetchSpecialities(),
    ]).then(() => {
      actions.indicatorActions.stopLoading('groups');
    });
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.hideDrawer();
  }

  fromGroup = (guid, id) => {
    const { actions } = this.props;
    const { pageNumber, pageSize } = this.state;

    return new Promise((resolve) => {
      let groups = [];
      actions.fetchGroupByGuid(guid).then((res) => {
        groups = map(lodashValues(res.payload.result.group), (group) => ({
          ...group,
          resort: find(res.payload.result.resort, (resort) => resort.id === group.resort),
        }));
        const firstGroupInstance = {
          ...clone(groups[0]),
          id: '*',
          timeFrom: minBy(groups, 'timeFrom').timeFrom,
          timeTo: maxBy(groups, 'timeTo').timeTo,
          repeat: null,
          days: [],
        };
        groups.push(firstGroupInstance);
        groups = keyBy(groups, 'id');
        actions.fetchGroupPrice(guid).then((priceRes) => {
          resolve({
            initialValues: {
              activeGroup: id,
              groups,
              guid,
              groupPrice: lodashValues(priceRes.payload.result.groupPrice),
            },
            initialResorts: res.payload.result.resort,
            updateGroupList: () => this.fetchData(pageNumber, pageSize),
          });
        });
      });
    });
  }

  fetchData = (pageNumber = undefined, pageSize = undefined) => {
    const { actions, specialities } = this.props;
    const {
      orderBy,
      pageNumber: statePageNumber,
      pageSize: statePageSize,
    } = this.state;
    this.setState({ loading: true });
    let updatedPageNumber = pageNumber;
    let updatedPageSize = pageSize;
    if (!updatedPageNumber) {
      updatedPageNumber = statePageNumber === 0 ? 1 : statePageNumber;
    }
    if (!updatedPageSize) {
      updatedPageSize = statePageSize;
    }
    const payload = { orderBy };
    let results = {};
    return actions.group.fetchGroups(updatedPageNumber, updatedPageSize, payload).then((res) => {
      results = res.payload.result;
      const formattedData = formatGroupListData(results.data, specialities);
      const allGuids = {};
      forEach(results.data, (group) => {
        const arrayOfIds = [];
        for (let i = 0; i < group.length; i += 1) {
          arrayOfIds.push(group[i].id);
        }
        allGuids[group[0].guid] = arrayOfIds;
      });
      this.setState({
        tableData: formattedData,
        total: results.count,
        loading: false,
        allPageGuids: allGuids,
      });
    });
  };

  handleDeletion = (id) => {
    const { pageSize } = this.state;
    const { actions } = this.props;
    this.setState({ loading: true });
    return actions.group.deleteGroup(id).then(() => {
      actions.notifySuccess({}, 'Group deleted successfully');
      this.fetchData(1, pageSize);
    }).catch(() => this.setState({ loading: false }));
  };

  onEshopToggle = (id, toggle = true) => {
    const { actions: { group: { updateGroup } } } = this.props;
    const { tableData } = this.state;
    const updatedTableData = tableData;
    updateGroup(id, { export: toggle }).then((res) => {
      const result = res.payload.result.group[id];
      for (let i = 0; i < updatedTableData.length; i += 1) {
        if (id === updatedTableData[i].id) {
          updatedTableData[i].columns.export = result.export;
          break;
        }
      }
      this.setState({
        tableData: updatedTableData,
      });
    });
  }

  onGroupExport2Shop = (guid, exportArray) => {
    const { actions: { group: { updateGroupRange } } } = this.props;
    const { tableData } = this.state;
    const updatedTableData = tableData;
    updateGroupRange(guid, exportArray).then((res) => {
      const results = res.payload.result.groupPrice;
      forEach(results, (group) => {
        for (let i = 0; i < updatedTableData.length; i += 1) {
          if (group.id === updatedTableData[i].id) {
            updatedTableData[i].columns.export = group.export;
            break;
          }
        }
      });
      this.setState({
        tableData: updatedTableData,
      });
    });
  }

  handleMassGroupsDeletion = () => {
    const {
      selectedGroups, selectAll, excludedGroups, pageSize,
    } = this.state;
    const { actions } = this.props;
    this.setState({ loading: true });
    this.handleStateReset();

    let body = {};
    if (selectAll && excludedGroups.length > 0) {
      body = {
        excluded: excludedGroups,
      };
    } else if (!selectAll && selectedGroups.length > 0) {
      body = {
        ids: selectedGroups,
      };
    } else if (!selectAll && !selectedGroups.length) {
      return actions.notifyError({}, 'There are no selected groups');
    }
    return actions.group.deleteGroups(body).then((res) => {
      if (res) {
        actions.notifySuccess({}, 'Selected groups deleted successfully');
        this.fetchData(1, pageSize);
      }
    }).catch(() => this.setState({ loading: false }));
  };

  handleExportBookings = () => {
    const {
      selectedGroups, selectAll, orderBy, excludedGroups,
    } = this.state;
    const { actions } = this.props;
    const payload = {
      orderBy,
    };
    if (selectAll) {
      if (excludedGroups.length > 0) {
        payload.excluded = excludedGroups;
      }
    } else if (selectedGroups.length > 0) {
      payload.ids = selectedGroups;
    } else {
      return actions.notifyError(
        {},
        'There are no groups selected to export. Select at least one group to export',
      );
    }

    this.handleStateReset();

    return actions.group.exportGroups(payload).then((res) => {
      const { payload: { result: { data } } } = res;
      downloadCsvFile(data, 'groups.csv');
    });
  };

  handleMassActionSubmit = () => {
    const { selectedMassAction } = this.state;
    const { openDialog } = this.props;

    if (selectedMassAction === 0) {
      openDialog(() => this.handleMassGroupsDeletion());
    } else if (selectedMassAction === 1) {
      this.handleExportBookings();
    }
  };

  handleStateReset = () => {
    this.setState({
      selectedGroups: [],
      selectedGuids: [],
      excludedGroups: [],
      selectAll: false,
    });
  };

  handleEdit = (guid, id) => {
    this.openDrawerForm(this.fromGroup.bind(this, guid, id), GroupForm);
  };

  setMassActionType = (action) => {
    this.setState({
      selectedMassAction: parseInt(action, 10),
    });
  };

  openDrawerForm = (prepopulationStrategy, container) => {
    const { actions } = this.props;
    actions.indicatorActions.startLoading('drawer');
    prepopulationStrategy().then((values) => {
      actions.showDrawer(container, values);
      actions.indicatorActions.stopLoading('drawer');
    });
  }

  handleRowsSelection = (id, guid) => {
    if (id === '*') {
      this.selectAll();
    } else if (guid) {
      this.selectGuid(guid);
    } else {
      this.selectGroup(id);
    }
  };

  selectAll = () => {
    const {
      selectedGroups,
      selectAll,
      allPageGuids,
    } = this.state;
    let groupsAfterSelect = selectedGroups;
    let guidsAfterSelect;
    if (selectAll) {
      guidsAfterSelect = [];
      groupsAfterSelect = [];
    } else {
      guidsAfterSelect = Object.keys(allPageGuids);
      groupsAfterSelect = [];
      forEach(allPageGuids, (guid) => {
        groupsAfterSelect.push(...guid);
      });
    }
    this.setState({
      selectAll: !selectAll,
      selectedGuids: guidsAfterSelect,
      selectedGroups: groupsAfterSelect,
      excludedGroups: [],
    });
  };

  selectGroup = (id) => {
    const {
      selectedGroups, selectedGuids, excludedGroups, allPageGuids, selectAll,
    } = this.state;
    let guidsAfterSelect = selectedGuids;
    let groupsAfterSelect = selectedGroups;
    let excludedGroupsAfterSelect = excludedGroups;
    let guidById = '';
    // eslint-disable-next-line no-restricted-syntax
    for (const guid in allPageGuids) {
      if (includes(allPageGuids[guid], id)) {
        guidById = guid;
        break;
      }
    }
    if (selectAll) {
      if (includes(groupsAfterSelect, id)) {
        guidsAfterSelect = remove(guidsAfterSelect, (val) => val !== guidById);
        groupsAfterSelect = remove(groupsAfterSelect, (val) => val !== id);
        excludedGroupsAfterSelect.push(id);
      } else {
        groupsAfterSelect.push(id);
        if (allPageGuids[guidById].every((v) => groupsAfterSelect.includes(v))) {
          guidsAfterSelect.push(guidById);
        }
        excludedGroupsAfterSelect = remove(excludedGroupsAfterSelect, (val) => val !== id);
      }
    } else if (includes(groupsAfterSelect, id)) {
      groupsAfterSelect = remove(groupsAfterSelect, (val) => val !== id);
      guidsAfterSelect = remove(guidsAfterSelect, (val) => val !== guidById);
    } else {
      groupsAfterSelect.push(id);
      if (allPageGuids[guidById].every((v) => groupsAfterSelect.includes(v))) {
        guidsAfterSelect.push(guidById);
      }
    }
    this.setState({
      selectedGuids: guidsAfterSelect,
      selectedGroups: groupsAfterSelect,
      excludedGroups: excludedGroupsAfterSelect,
    });
  };

  selectGuid = (guid) => {
    const {
      selectedGuids, selectedGroups, excludedGroups, allPageGuids, selectAll,
    } = this.state;
    let guidsAfterSelect = selectedGuids;
    let groupsAfterSelect = selectedGroups;
    let excludedGroupsAfterSelect = excludedGroups;
    if (selectAll) {
      if (guidsAfterSelect.includes(guid)) {
        guidsAfterSelect = remove(guidsAfterSelect, (val) => val !== guid);
        for (let i = 0; i < allPageGuids[guid].length; i += 1) {
          groupsAfterSelect = remove(groupsAfterSelect, (val) => val !== allPageGuids[guid][i]);
        }
        excludedGroupsAfterSelect.push(...allPageGuids[guid]);
      } else {
        guidsAfterSelect.push(guid);
        for (let i = 0; i < allPageGuids[guid].length; i += 1) {
          if (!groupsAfterSelect.includes(allPageGuids[guid][i])) {
            groupsAfterSelect.push(allPageGuids[guid][i]);
          }
          excludedGroupsAfterSelect = remove(excludedGroupsAfterSelect,
            (val) => val !== allPageGuids[guid][i]);
        }
      }
    } else if (guidsAfterSelect.includes(guid)) {
      guidsAfterSelect = remove(guidsAfterSelect, (val) => val !== guid);
      groupsAfterSelect = remove(groupsAfterSelect, (val) => !includes(allPageGuids[guid], val));
      for (let i = 0; i < allPageGuids[guid].length; i += 1) {
        groupsAfterSelect = remove(groupsAfterSelect, (val) => val !== allPageGuids[guid][i]);
      }
    } else {
      guidsAfterSelect.push(guid);
      groupsAfterSelect = concat(groupsAfterSelect, allPageGuids[guid]);
    }
    this.setState({
      selectedGuids: guidsAfterSelect,
      selectedGroups: groupsAfterSelect,
      excludedGroups: excludedGroupsAfterSelect,
    });
  };

  calculatePageSizeOptions = () => {
    const { total, pageNumber, pageSize } = this.state;
    const updatedPageSizes = [];
    for (let i = 0; i < pageSizes.length; i += 1) {
      if (Math.ceil(total / pageSize) === pageNumber) {
        if (total < pageSize) {
          updatedPageSizes.push(total);
          break;
        } if (total - ((pageNumber - 1) * pageSize) >= pageSizes[i]) {
          updatedPageSizes.push(pageSizes[i]);
        } else {
          updatedPageSizes.push(total - ((pageNumber - 1) * pageSize));
          break;
        }
      } else if (total > pageSizes[i]) {
        updatedPageSizes.push(pageSizes[i]);
      } else {
        updatedPageSizes.push(total);
        break;
      }
    }
    return updatedPageSizes;
  };

  calculatePageSize = () => {
    const { pageSize, pageNumber, total } = this.state;
    if (total < pageSize) {
      return total;
    }
    if (total - (pageNumber * pageSize) >= 0) {
      return pageSize;
    }
    return total - ((pageNumber - 1) * pageSize);
  };

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageSize,
      pageNumber: 1,
    });
    this.fetchData(1, pageSize);
  };

  onPageChange = (pageNumber) => {
    this.setState({
      pageNumber,
    });
    this.fetchData(pageNumber);
  };

  setSorting = (id) => {
    if (id === 'time' || id === 'ids' || id === 'instructors') {
      return;
    }
    const { orderBy } = this.state;
    let updatedId = id;
    switch (id) {
      case 'date':
        updatedId = 'timeFrom';
        break;
      case 'age':
        updatedId = 'minAge';
        break;
      default:
        break;
    }
    if (orderBy.column === updatedId) {
      if (orderBy.direction === 'desc') {
        this.setState({
          orderBy: {
            column: updatedId,
            direction: 'asc',
          },
          pageNumber: 1,
        }, () => this.fetchData());
      } else {
        this.setState({
          orderBy: {
            column: updatedId,
            direction: 'desc',
          },
          pageNumber: 1,
        }, () => this.fetchData());
      }
    } else {
      this.setState({
        orderBy: {
          column: updatedId,
          direction: 'asc',
        },
        pageNumber: 1,
      }, () => this.fetchData());
    }
    this.setState({
      pageNumber: 1,
      selectedGroups: [],
      selectedGuids: [],
    });
  };

  render() {
    const { openDialog, refreshIndicator } = this.props;
    const {
      selectedGroups,
      expanded,
      tableData,
      selectAll,
      selectedGuids,
      pageNumber,
      total,
      pageSize,
      loading,
      massActions,
    } = this.state;

    return (
      <div className="group-list">
        <div className="group-list__row-wrapper">
          <MassActionsComponent
            massActions={massActions}
            detectMassAction={this.setMassActionType}
            handleMassAction={this.handleMassActionSubmit}
          />
        </div>
        {refreshIndicator.groups.loaded
          && (
          <ReactTable
            className={!refreshIndicator.groups.cached ? 'indicator-hidden' : ''}
            loading={loading}
            noDataText="No data found"
            page={pageNumber - 1 < 0 ? 0 : pageNumber - 1}
            pageSizeOptions={this.calculatePageSizeOptions()}
            pageSize={this.calculatePageSize()}
            onPageSizeChange={(val) => this.onPageSizeChange(val)}
            pages={Math.ceil(total / pageSize)}
            columns={GroupListColumns(
              this.handleRowsSelection,
              selectedGroups,
              selectedGuids,
              this.handleEdit,
              this.handleDeletion,
              openDialog,
              this.onEshopToggle,
              this.onGroupExport2Shop,
              selectAll,
            )}
            showPagination
            data={tableData}
            expanded={expanded}
            manual
            onExpandedChange={(e) => this.setState({ expanded: e })}
            pivotBy={['name']}
            onPageChange={(pageIndex) => this.onPageChange(pageIndex + 1)}
            getTheadThProps={(state, rowInfo, column) => ({
              onClick: () => this.setSorting(column.id),
            })}
          />
          )}
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.groups.cached ? 'hide' : 'loading'}
          className={refreshIndicator.groups.cached ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  group: state.entities.group,
  instructors: state.entities.instructor,
  specialities: state.entities.speciality,
  refreshIndicator: state.refreshIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    group: bindActionCreators(groupsActions, dispatch),
    instructors: bindActionCreators(instructorsActions, dispatch),
    specialities: bindActionCreators(specialitiesActions, dispatch),
    notifySuccess: bindActionCreators(notifySuccess, dispatch),
    notifyError: bindActionCreators(notifyError, dispatch),
    fetchGroup: bindActionCreators(fetchGroup, dispatch),
    fetchResort: bindActionCreators(fetchResort, dispatch),
    fetchGroupPrice: bindActionCreators(fetchGroupPrice, dispatch),
    showDrawer: bindActionCreators(showDrawer, dispatch),
    hideDrawer: bindActionCreators(hideDrawer, dispatch),
    fetchSpeciality: bindActionCreators(fetchSpeciality, dispatch),
    fetchGroupByGuid: bindActionCreators(fetchGroupByGuid, dispatch),
    indicatorActions: bindActionCreators(indicatorActions, dispatch),
  },
});

GroupList.propTypes = {
  actions: PropTypes.shape({
    notifySuccess: PropTypes.func,
    notifyError: PropTypes.func,
    fetchGroup: PropTypes.func,
    fetchResort: PropTypes.func,
    fetchGroupPrice: PropTypes.func,
    showDrawer: PropTypes.func,
    hideDrawer: PropTypes.func,
    fetchSpeciality: PropTypes.func,
    fetchGroupByGuid: PropTypes.func,
    group: PropTypes.shape({
      fetchGroups: PropTypes.func,
      updateGroup: PropTypes.func,
      deleteGroup: PropTypes.func,
      updateGroupRange: PropTypes.func,
      deleteGroups: PropTypes.func,
      exportGroups: PropTypes.func,
    }),
    instructors: PropTypes.shape({
      fetchInstructors: PropTypes.func,
    }),
    specialities: PropTypes.shape({
      fetchSpecialities: PropTypes.func,
    }),
    indicatorActions: PropTypes.shape({
      startLoading: PropTypes.func,
      stopLoading: PropTypes.func,
    }),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  specialities: PropTypes.object,
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    groups: PropTypes.shape({
      cached: PropTypes.bool,
      loaded: PropTypes.bool,
    }),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
