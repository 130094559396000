import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { filter, keyBy, get } from 'lodash';
import Dialog from '../components/dialog/Dialog';
import Online from '../components/Online';
import Logout from './Logout';
import UserGuideIcon from '../components/UserGuideIcon';
import AccountLimitationWarning from '../components/AccountLimitationWarning';
import PricingForm from './PricingForm';
import { fetchPlans } from '../actions/subscriptionPlanActions';
import '../styles/ProfileNav.scss';

const titleStyle = {
  color: '#253D63',
  fontFamily: 'Ubuntu',
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '22px',
  paddingLeft: '38px',
};

const handleEshopIconClick = () => {
  const subdomain = window.location.host.split('.')[0];
  const host = window.location.host.split('.')[1];

  const eshopUrl = host === 'adapt-skicms-test'
    ? `https://${subdomain}.skicms-eshop-test.com/`
    : `https://${subdomain}.skischoollesson.com`;

  window.open(eshopUrl, '_blank');
};

class ProfileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    const { fetchPlansA, user: { role } } = this.props;
    if (role === 'admin') {
      fetchPlansA();
    }
  }

  formatInitialPlansPricing = (plans) => {
    const plansArray = filter(plans, (p) => p.id !== 0);

    return keyBy(plansArray, 'id');
  };

  changeDialogState = () => {
    const { dialogOpen } = this.state;
    this.setState({
      dialogOpen: !dialogOpen,
    });
  }

  render() {
    const { user, planPricing, account } = this.props;
    const { dialogOpen } = this.state;

    return (
      <div className="profile-nav">
        <Dialog
          modal={false}
          open={dialogOpen}
          onRequestClose={this.changeDialogState}
          title="Plan Pricing"
          titleStyle={titleStyle}
          className="pricing-dialog"
        >
          <PricingForm
            changeDialogState={this.changeDialogState}
            initialValues={this.formatInitialPlansPricing(planPricing)}
          />
        </Dialog>
        <div className="profile-nav__wrapper">
          {user.role === 'manager' && (account && account.balance > 0)
            && (
            <small style={{ paddingRight: '25px' }}>
              Balance:
              {account.balance.toFixed(2)}
              {' '}
              €
            </small>
            )}
          <ul className="profile-nav__items">
            <li className="profile-nav__item" />
            <li className="profile-nav__item">
              <AccountLimitationWarning />
            </li>
            <li className="profile-nav__item profile-nav__item__videoguide">
              <span
                className="info-icon"
                title="Video guide"
                onClick={() => {
                  window.open('https://docs.skicms.com/docs/booking/', '_blank');
                }}
              />
              Guide
            </li>
            <li className="profile-nav__item">
              <UserGuideIcon />
            </li>
            <li className="profile-nav__item">
              <span className="eshop-icon" onClick={handleEshopIconClick} />
            </li>
            <li className="profile-nav__item">
              <Online />
            </li>
            <li className="profile-nav__item">
              {user.role === 'instructor'
              && <Link to="/profile" style={{ cursor: 'pointer' }}>{user.name}</Link>}
              {user.role === 'headCoach'
              && <Link to="/profile" style={{ cursor: 'pointer' }}>{user.name}</Link>}
              {user.role === 'manager'
              && <Link to="/account" style={{ cursor: 'pointer' }}>{user.name}</Link>}
              {user.role === 'admin'
              && <Link to="/accounts" style={{ cursor: 'pointer' }}>{user.name}</Link>}
            </li>
            <li className="profile-nav__item">
              {user.role === 'admin'
              && <small style={{ cursor: 'pointer' }} onClick={() => this.changeDialogState()}>Pricing</small>}
            </li>
            <li className="profile-nav__item">
              <Logout />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

ProfileNav.propTypes = {
  fetchPlansA: PropTypes.func,
  user: PropTypes.shape({
    role: PropTypes.string,
    name: PropTypes.string,
  }),
  planPricing: PropTypes.shape({
    id: PropTypes.number,
  }),
  account: PropTypes.shape({
    balance: PropTypes.number,
  }),
};

const mapStateToProps = (state) => ({
  user: state.user,
  planPricing: state.entities.subscriptionPlan,
  account: get(state, `entities.account[${state.user.id}]`, undefined),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlansA: bindActionCreators(fetchPlans, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNav);
