export const close = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_DIALOG',
  });
};

export const open = (onOk, onCancel, calendarItem = null, cancel = null) => {
  const message = cancel ? `Are You sure you want to ${cancel} booking?` : 'Delete item from the list?';
  return (dispatch) => {
    dispatch({
      type: 'OPEN_DIALOG',
      message,
      onOk: () => {
        dispatch({ type: 'CLOSE_DIALOG' });
        if (onOk) {
          onOk();
        }
      },
      onCancel: () => {
        dispatch({ type: 'CLOSE_DIALOG' });
        if (onCancel) {
          onCancel();
        }
      },
      calendarItem,
    });
  };
};
