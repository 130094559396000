import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import specialitySchema from '../schema/Speciality';

export const fetchSpecialities = (filter = '') => httpRequest({
  method: 'GET',
  path: `/speciality${filter}`,
  types: [
    'SPECIALITY_GET_REQUEST',
    'SPECIALITY_GET_SUCCESS',
    'SPECIALITY_GET_FAILURE',
  ],
  schema: new schema.Array(specialitySchema),
});

export const fetchSpeciality = (id) => httpRequest({
  method: 'GET',
  path: `/speciality/${id}`,
  types: [
    'SPECIALITY_GET_REQUEST',
    'SPECIALITY_GET_SUCCESS',
    'SPECIALITY_GET_FAILURE',
  ],
  schema: specialitySchema,
});
