import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderField from '../../components/RenderField';
import MinusButton from '../../components/MinusButton';
import '../../styles/ProductAdd.scss';

const RenderGroupPricing = ({ fields, currency }) => (
  <div className="season-pricing group-form__season-pricing">
    <table className="season-pricing__table">
      <thead className="season-pricing__table-header">
        <th>Days</th>
        <th>
          per Person,
          {currency}
          *
        </th>
      </thead>

      <tbody>
        {fields.map((row, index) => (
          <tr key={index}>
            <td>
              <Field
                name={`${row}.days`}
                component={RenderField}
                type="number"
                min={0}
              />
            </td>
            <td>
              <Field
                name={`${row}.pricePerPerson`}
                component={RenderField}
                type="number"
                min={0}
              />
              <MinusButton className="minus-group-pricing" onClick={() => fields.remove(index)} />
              <div style={{
                color: '#294459', fontFamily: 'Ubuntu', fontWeight: '500', display: 'inline-block', float: 'right', marginRight: '10px',
              }}
              >
                {currency}
              </div>
            </td>
          </tr>
        ))}
        <tr key="buttonAddGroup">
          <td>
            <div className="season-pricing__add-group">
              <button
                className="button--add-group"
                onClick={() => fields.push({ days: '', pricePerPerson: '' })}
                type="button"
              >
                Add Group
              </button>
            </div>
          </td>
          <td> </td>
        </tr>
      </tbody>
    </table>
  </div>
);

RenderGroupPricing.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    remove: PropTypes.func,
    push: PropTypes.func,
  }),
  currency: PropTypes.string,
};

export default RenderGroupPricing;
