import React from 'react';
import '../styles/Payment.scss';

import ErrorIcon from '../styles/images/times-solid.svg';

const PaymentErrorPage = () => (
  <div className="payment-error-page">
    <div className="payment-error-page__content-container">
      <div className="payment-error-page__content-container__top">
        <img
          alt="Error icon"
          className="error-indicator"
          src={ErrorIcon}
        />
        <div className="error-message">SOMETHING WENT WRONG</div>
      </div>
      <div className="payment-error-page__content-container__bottom">
        <div className="booking-error">Your payment for the booking failed!</div>
        <div className="message">Contact the ski school to try again</div>
      </div>
    </div>
  </div>
);

export default PaymentErrorPage;
