import { schema } from 'normalizr';

const availabilitySchema = new schema.Entity('availability', { idAttribute: 'id' });
const instructorSchema = new schema.Entity('instructor', { idAttribute: 'id' });
const resortSchema = new schema.Entity('resort', { idAttribute: 'id' });

availabilitySchema.define({
  instructor: new schema.Array(instructorSchema),
  resort: new schema.Array(resortSchema),
});

export default availabilitySchema;
