import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import FlatButton from 'material-ui/FlatButton';
import {
  required, maxLength255, email, phone, postalCode,
} from '../utils/validators';
import RenderField from '../components/RenderField';
import RenderPhoneInput from '../components/RenderPhoneInput';

import '../styles/Sidemenu.scss';
import '../styles/PartnerForm.scss';

const PartnerForm = ({
  handleCreatePartner,
  handleSubmit,
  handleClose,
  initialValues,
  invalid,
}) => (
  <form
    className="partner-form"
    onSubmit={handleSubmit(handleCreatePartner)}
  >
    <div onClick={handleClose}><i className="material-icons sidemenu__close close-button">close</i></div>
    <div className="partner-form__header">{initialValues ? 'EDIT PARTNER' : 'CREATE PARTNER'}</div>
    <div className="partner-form__input-wrapper">
      <Field
        type="text"
        component={RenderField}
        name="name"
        labelClass="partner-form__label"
        label="Name:*"
        validate={[required]}
      />
    </div>
    <div className="partner-form__input-wrapper">
      <Field
        type="text"
        component={RenderField}
        name="email"
        label="Email:*"
        labelClass="partner-form__label"
        validate={[email, required]}
      />
    </div>
    <div className="partner-form__input-wrapper__phone">
      <Field
        component={RenderPhoneInput}
        name="telephone"
        label="Phone number:"
        labelClassName="partner-form__label"
        validate={[phone]}
        placeholder="Enter your phone number"
      />
    </div>
    <div className="partner-form__header">ADDRESS</div>
    <div className="partner-form__input-wrapper">
      <Field
        type="text"
        component={RenderField}
        name="address.street"
        label="Street:"
        labelClass="partner-form__label"
        validate={[maxLength255]}
      />
    </div>
    <div className="partner-form__input-wrapper">
      <Field
        type="text"
        component={RenderField}
        name="address.city"
        label="City:"
        labelClass="partner-form__label"
        validate={[maxLength255]}
      />
    </div>
    <div className="partner-form__input-wrapper">
      <Field
        type="text"
        component={RenderField}
        name="address.state"
        label="State/Province:"
        labelClass="partner-form__label"
        validate={[maxLength255]}
      />
    </div>
    <div className="partner-form__input-wrapper">
      <Field
        type="text"
        component={RenderField}
        name="address.postalCode"
        label="Postal code:"
        labelClass="partner-form__label"
        validate={[postalCode]}
      />
    </div>
    <div className="partner-form__input-wrapper">
      <Field
        type="text"
        component={RenderField}
        name="address.country"
        label="Country:"
        labelClass="partner-form__label"
        validate={[maxLength255]}
      />
    </div>
    <div className="partner-form__input-wrapper partner-form__commission">
      <Field
        name="commission"
        label="Commission:*"
        component={RenderField}
        type="number"
        step={0.1}
        max={100}
        validate={[required]}
        labelClass="partner-form__label"
      />
      <label style={{ fontWeight: '500', marginLeft: '5px' }}>%</label>
    </div>
    <FlatButton
      label={initialValues ? 'UPDATE' : 'CREATE'}
      primary
      type="submit"
      disabled={invalid}
    />
  </form>
);

PartnerForm.propTypes = {
  handleCreatePartner: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  invalid: PropTypes.bool,
};

const formConfig = {
  form: 'PartnerForm',
};

export default reduxForm(formConfig)(React.memo(PartnerForm));
