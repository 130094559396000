import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Checkbox from 'material-ui/Checkbox';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import * as clientsActions from '../actions/clientsActions';
import * as accountActions from '../actions/accountActions';
import { showDrawer, hideDrawer } from '../actions/drawerActions';
import { fetchBooking } from '../actions/bookingActions';
import { fetchLessonBlocksByBooking } from '../actions/lessonBlockActions';
import { fetchBookingServicesByBooking } from '../actions/bookingServiceActions';
import { notifySuccess } from '../actions/notificationActions';
import * as indicatorActions from '../actions/refreshIndicatorActions';
import * as partnerManagerActions from '../actions/partnerManagerActions';
import { fetchGroupByGuid } from '../actions/groupsActions';

import ListTable from '../components/ListTable';
import AddEntityButton from '../components/AddEntityButton';
import EditEntityButton from '../components/EditEntityButton';
import DeleteEntityButton from '../components/DeleteEntityButton';
import NameColumn from '../components/NameColumn';
import MassActionsComponent from '../components/MassActionsComponent';
import UploadModal from './UploadModal/UploadModal';
import Filter from '../components/FilterComponent';
import Component from '../components/Component';
import BookingForm from './BookingForm';
import { sortTable, isEmptyObject } from '../utils/helpers';
import '../styles/InstructorList.scss';
import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';
import { downloadCsvFile } from '../utils/csvHelper';

class ClientsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      searchValues: [],
      massActions: [
        { text: 'Delete', value: 1 },
      ],
      selectedClients: [],
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.indicatorActions.startLoading('clients');
    actions.clients.fetchClients().then(() => {
      actions.indicatorActions.stopLoading('clients');
    }).catch(() => actions.indicatorActions.stopLoading('clients'));
    actions.accounts.fetchMyAccount();
    actions.partnerManagers.fetchPartnerManagers();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { clients } = nextProps;

    if (!isEmptyObject(clients)) {
      this.prepareSearchValuesAndClients(clients);
    } else {
      this.setState({ clients: [] });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.hideDrawer();
  }

  prepareSearchValuesAndClients = (clients) => {
    const searchValues = [];
    _.forEach(clients, (client) => {
      const value = {
        value: client,
        text: client.name.concat(' ', client.surname),
      };
      searchValues.push(value);
    });
    this.setState({
      clients,
      searchValues,
    });
  }

  clientsToRows = (clients) => {
    const { selectedClients } = this.state;
    const { openDialog } = this.props;
    const rows = [];
    _.forEach(clients, (client) => {
      const row = [];
      const phoneNumbers = client.phoneNumbers ? _.map(client.phoneNumbers, (phoneNumber) => {
        if (phoneNumber.phoneNumber) {
          return `${phoneNumber.phoneNumber} `;
        }
        return null;
      }) : '-';
      const address = client.address && client.address.country ? client.address.country : '-';
      let bookingCount = 0;
      const bookingsDisplay = client.bookings && client.bookings.length
        ? _.map(client.bookings, (booking) => {
          if (bookingCount >= 3) {
            return null;
          }
          bookingCount += 1;
          const bookingId = ' B'.concat('-', booking.bid);
          return (
            <span
              key={booking.id}
              className="booking-label"
              onClick={this.handleShowBooking(booking.id)}
              style={{ cursor: 'pointer' }}
            >
              {bookingId}
            </span>
          );
        }) : '-';
      row[0] = (
        <Checkbox
          type="checkbox"
          checked={_.some(selectedClients, { id: client.id })}
          onCheck={this.handleSingleCheck(client.id)}
        />
      );
      row[1] = (
        <NameColumn
          route="clients"
          entity={client}
        />
      );
      row[2] = client.surname ? client.surname : '-';
      row[3] = phoneNumbers;
      row[4] = client.email ? client.email : '-';
      row[5] = address;
      row[6] = bookingsDisplay;
      row[7] = (
        <div className="list-table__column-content--inline">
          <Link to={{ pathname: `/clients/${client.id}` }}><EditEntityButton /></Link>
          <a>
            <DeleteEntityButton
              id={client.id}
              handleDelete={() => openDialog(this.handleDelete(client.id))}
            />
          </a>
        </div>
      );
      rows.push(row);
    });
    return rows;
  }

  handleShowBooking = (id) => () => {
    const prepopulationStrategy = this.fromBooking(id);
    this.openBookingForm(prepopulationStrategy);
  }

  openBookingForm = (prepopulationStrategy) => {
    const { actions } = this.props;
    actions.indicatorActions.startLoading('drawer');
    prepopulationStrategy().then((values) => {
      actions.showDrawer(BookingForm, values);
      actions.indicatorActions.stopLoading('drawer');
    });
  }

  handleSingleCheck = (clientId) => (event) => {
    event.preventDefault();
    const { selectedClients } = this.state;
    const { clients } = this.props;
    let clientsChecked = selectedClients;
    if (_.some(selectedClients, { id: clientId })) {
      clientsChecked = _.filter(clientsChecked, (client) => client.id !== clientId);
      this.refs.multiCheck.state.switched = false;
    } else {
      clientsChecked.push(clients[clientId]);
    }
    this.setState({
      selectedClients: clientsChecked,
    });
  }

  handleMultiCheck = (event, isInputChecked) => {
    const { clients } = this.state;
    let clientsChecked = [];
    if (isInputChecked) {
      clientsChecked = clients;
    }
    this.setState({
      selectedClients: clientsChecked,
    });
  }

  handleDelete = (id) => () => {
    const { actions, clients } = this.props;
    return actions.clients.deleteClient(id).then(() => {
      this.prepareSearchValuesAndClients(clients);
      actions.notifySuccess({}, 'Client deleted successfully');
    });
  }

  handleDeleteMassAction = () => {
    const { actions, clients } = this.props;
    const { selectedClients } = this.state;
    const undeletedClients = [];
    const deletions = _.map(selectedClients,
      (client) => actions.clients.deleteClient(client.id).catch(() => {
        undeletedClients.push(client.id);
      }));
    const results = Promise.all(deletions);
    return results.then(() => {
      if (undeletedClients.length === 0) {
        actions.notifySuccess({}, 'All clients deleted successfully');
      }
      this.setState({
        selectedClients: [],
      });
      this.refs.multiCheck.state.switched = false;
    }).then(() => {
      this.prepareSearchValuesAndClients(clients);
    });
  }

  handleSearchAutocomplete = (requestList) => {
    const { searchValues } = this.state;
    const displayClients = [];
    _.forEach((requestList), (listItem) => {
      displayClients.push(searchValues[listItem.value.key].value);
    });
    this.setState({
      clients: displayClients,
    });
  }

  handleSortTable = (columnIndex, asc) => {
    const { clients } = this.state;
    const columns = {
      1: {
        name: ['name'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      2: {
        name: ['surname'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      3: {
        name: ['phoneNumbers'], dependency: null, level: 1, orderType: 'normal', dataArray: 'phoneNumber',
      },
      4: {
        name: ['email'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      5: {
        name: ['address.country'], dependency: null, level: 1, orderType: 'normal', dataArray: false,
      },
      6: {
        name: ['bookings'], dependency: null, level: 1, orderType: 'normal', dataArray: 'id',
      },
    };

    const sortedList = sortTable(clients, columns, columnIndex, asc);
    this.setState({
      clients: sortedList,
    });
  }

  handleUploadModalState = () => {
    const { uploadModalOpen } = this.state;
    this.setState({ uploadModalOpen: !uploadModalOpen });
  }

  handleExportClick = () => {
    const { actions: { clients: { clientsExport } } } = this.props;
    clientsExport().then((res) => {
      const { payload: { result: { data } } } = res;
      downloadCsvFile(data, 'clients.csv');
    });
  };

  render() {
    const { refreshIndicator, openDialog, actions } = this.props;
    const {
      massActions,
      searchValues,
      uploadModalOpen,
      clients,
    } = this.state;
    return (
      <div className="instructor-list">
        <div className="instructor-list__row-wrapper">
          <div>
            <AddEntityButton
              link
              label="ADD CLIENT"
              path="/client"
            />
            <AddEntityButton
              label="UPLOAD"
              className="add-entity-button__theme--green"
              onClick={this.handleUploadModalState}
            />
            <AddEntityButton
              label="EXPORT"
              className="add-entity-button__theme--pink"
              onClick={this.handleExportClick}
            />
            <MassActionsComponent
              massActions={massActions}
              detectMassAction={() => {}}
              handleMassAction={() => openDialog(this.handleDeleteMassAction)}
            />
            <Filter
              destination="Clients"
              dataSource={searchValues}
              onUpdateInput={this.handleSearchAutocomplete}
            />
          </div>
        </div>
        <UploadModal
          open={uploadModalOpen}
          handleUploadModalState={this.handleUploadModalState}
          uploadFn={actions.clients.clientsImport}
          onSuccessCallback={actions.clients.fetchClients}
          templateName="clients.csv"
          templateLocation="https://api.skicms.com/templates/clients.csv"
        />
        <ListTable
          dest="client"
          names={['checkbox', 'name', 'surname', 'phone', 'email', 'country', 'bookings', 'icons']}
          className={!refreshIndicator.clients.cached ? 'indicator-hidden' : ''}
          header={[
            <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
              <Checkbox ref="multiCheck" onCheck={this.handleMultiCheck} />
            </MuiThemeProvider>,
            'Name',
            'Surname',
            'Phone',
            'E-mail',
            'Country',
            'Bookings',
            ' ',
          ]}
          rows={this.clientsToRows(clients)}
          handleSort={this.handleSortTable}
        />
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.clients.cached ? 'hide' : 'loading'}
          className={refreshIndicator.clients.cached ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.entities.client,
  accounts: _.values(state.entities.account),
  booking: state.entities.booking,
  user: state.user,
  refreshIndicator: state.refreshIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    clients: bindActionCreators(clientsActions, dispatch),
    accounts: bindActionCreators(accountActions, dispatch),
    showDrawer: bindActionCreators(showDrawer, dispatch),
    hideDrawer: bindActionCreators(hideDrawer, dispatch),
    fetchBooking: bindActionCreators(fetchBooking, dispatch),
    fetchLessonBlocksByBooking: bindActionCreators(fetchLessonBlocksByBooking, dispatch),
    fetchBookingServicesByBooking: bindActionCreators(fetchBookingServicesByBooking, dispatch),
    notifySuccess: bindActionCreators(notifySuccess, dispatch),
    indicatorActions: bindActionCreators(indicatorActions, dispatch),
    partnerManagers: bindActionCreators(partnerManagerActions, dispatch),
    fetchGroupByGuid: bindActionCreators(fetchGroupByGuid, dispatch),
  },
});

ClientsList.propTypes = {
  actions: PropTypes.shape({
    showDrawer: PropTypes.func,
    hideDrawer: PropTypes.func,
    fetchBooking: PropTypes.func,
    fetchLessonBlocksByBooking: PropTypes.func,
    fetchBookingServicesByBooking: PropTypes.func,
    notifySuccess: PropTypes.func,
    clients: PropTypes.shape({
      fetchClients: PropTypes.func,
      deleteClient: PropTypes.func,
      clientsExport: PropTypes.func,
      clientsImport: PropTypes.func,
    }),
    accounts: PropTypes.shape({
      fetchMyAccount: PropTypes.func,
    }),
    partnerManagers: PropTypes.shape({
      fetchPartnerManagers: PropTypes.func,
    }),
    indicatorActions: PropTypes.shape({
      startLoading: PropTypes.func,
      stopLoading: PropTypes.func,
    }),
    fetchGroupByGuid: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  clients: PropTypes.object,
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    clients: PropTypes.shape({
      cached: PropTypes.bool,
    }),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);
