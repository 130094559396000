import React from 'react';
import PropTypes from 'prop-types';
import { Provider, ReactReduxContext } from 'react-redux';
import MaterialDialog from 'material-ui/Dialog';

const Dialog = ({
  children,
  ...rest
}) => (
  <ReactReduxContext.Consumer>
    {({ store }) => (
      <MaterialDialog {...rest}>
        <Provider store={store}>
          {children}
        </Provider>
      </MaterialDialog>
    )}
  </ReactReduxContext.Consumer>
);

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
};

export default React.memo(Dialog);
