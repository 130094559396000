import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const ActiveIcon = (props) => (
  <SvgIcon
    {...props}
    style={{ cursor: 'pointer' }}
  >
    <g stroke="null" id="svg_14">
      <g
        transform="matrix(1.2643330891394597,0,0,1.2643330891394597,0.0038743196425969906,0.8753303755245199) "
        fillRule="evenodd"
        fill="none"
        id="svg_8"
      >
        <g stroke="null" fill="#253D63" fillRule="nonzero" id="svg_9">
          <g stroke="null" id="svg_10">
            <g stroke="null" id="svg_11">
              <polygon
                stroke="null"
                points="6.033837277442217,11.991998672485352 1.5564202852547169,7.514580726623535 0.031732428818941116,9.02852725982666 6.033837277442217,15.030617713928223 18.918480832129717,2.1459743976593018 17.404534298926592,0.6320277452468872 "
                id="svg_12"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

ActiveIcon.displayName = 'ActiveIcon';
ActiveIcon.muiName = 'SvgIcon';

export default React.memo(ActiveIcon);
