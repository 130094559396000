import { schema } from 'normalizr';
import clientSchema from '../schema/Client';
import { httpRequest } from '../middleware/http';

export const fetchPartners = () => httpRequest({
  method: 'GET',
  path: '/partner',
  types: [
    'PARTNERS_GET_REQUEST',
    'PARTNERS_GET_SUCCESS',
    'PARTNERS_GET_FAILURE',
  ],
  schema: new schema.Array(clientSchema),
});

export const fetchClients = () => httpRequest({
  method: 'GET',
  path: '/client',
  types: [
    'CLIENTS_GET_REQUEST',
    'CLIENTS_GET_SUCCESS',
    'CLIENTS_GET_FAILURE',
  ],
  schema: new schema.Array(clientSchema),
});

export const fetchClient = (id) => httpRequest({
  method: 'GET',
  path: `/client/${id}`,
  types: [
    'CLIENT_GET_REQUEST',
    'CLIENT_GET_SUCCESS',
    'CLIENT_GET_FAILURE',
  ],
  schema: clientSchema,
});

export const deleteClient = (id) => httpRequest({
  path: `/client/${id}`,
  method: 'DELETE',
  types: [
    'CLIENT_DELETE_REQUEST',
    'CLIENT_DELETE_SUCCESS',
    'CLIENT_DELETE_FAILURE',
  ],
  schema: clientSchema,
});

export const createClient = (body) => httpRequest({
  method: 'POST',
  path: '/client',
  body,
  types: [
    'CLIENT_POST_REQUEST',
    'CLIENT_POST_SUCCESS',
    'CLIENT_POST_FAILURE',
  ],
  schema: clientSchema,
});

export const updateClient = (id, body) => httpRequest({
  method: 'PUT',
  path: `/client/${id}`,
  body,
  types: [
    'CLIENT_PUT_REQUEST',
    'CLIENT_PUT_SUCCESS',
    'CLIENT_PUT_FAILURE',
  ],
  schema: clientSchema,
});

export const clientsImport = (body) => httpRequest({
  method: 'POST',
  body,
  path: '/client/import',
  types: [
    'CLIENT_IMPORT_POST_REQUEST',
    'CLIENT_IMPORT_POST_SUCCESS',
    'CLIENT_IMPORT_POST_FAILURE',
  ],
  schema: null,
});

export const clientsExport = () => httpRequest({
  method: 'GET',
  path: '/client/export',
  types: [
    'CLIENT_EXPORT_GET_REQUEST',
    'CLIENT_EXPORT_GET_SUCCESS',
    'CLIENT_EXPORT_GET_FAILURE',
  ],
  schema: null,
});
