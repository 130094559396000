import { httpRequest } from '../middleware/http';

export const login = (email, password) => httpRequest({
  method: 'POST',
  path: '/user/login',
  body: {
    email,
    password,
    account: window.location.hostname.split('.')[0],
  },
  types: [
    'USER_LOGIN_REQUEST',
    'USER_LOGIN_SUCCESS',
    'USER_LOGIN_FAILURE',
  ],
  schema: null,
});

export const logout = () => httpRequest({
  method: 'POST',
  path: '/user/logout',
  types: [
    'USER_LOGOUT_REQUEST',
    'USER_LOGOUT_SUCCESS',
    'USER_LOGOUT_FAILURE',
  ],
  schema: null,
});
