import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import CalendarPage from './CalendarPage';

const Home = ({
  role,
}) => useMemo(() => {
  if (role) {
    return (
      <CalendarPage />
    );
  }
  return (
    <section className="text-section" />
  );
}, [role]);

const mapStateToProps = (state) => ({
  role: state.user.role,
});

export default connect(mapStateToProps)(Home);
