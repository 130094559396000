import { schema } from 'normalizr';
// eslint-disable-next-line import/no-cycle
import serviceSchema from './Service';

const bookingServiceSchema = new schema.Entity('bookingService', { idAttribute: 'id' });

bookingServiceSchema.define({
  service: serviceSchema,
});

export default bookingServiceSchema;
