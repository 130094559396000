/* eslint-disable */
import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { includes } from 'lodash';
import skiThemeLessonForm from '../../styles/theme/skiThemeLessonForm';
import EditEntityButton from '../../components/EditEntityButton';
import DeleteEntityButton from '../../components/DeleteEntityButton';

const hotelsListColumns = (
  handleCheck,
  selectedAll,
  selected,
  handleDialog,
  openDialog,
  handleDelete,
) => [
  {
    Header: (
      <div>
        <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
          <Checkbox
            ref="multiCheck"
            onCheck={() => handleCheck('*')}
            checked={selectedAll}
          />
        </MuiThemeProvider>
      </div>
    ),
    accessor: 'id',
    Cell: (props) => (
      <div>
        <Checkbox
          onCheck={() => handleCheck(props.value)}
          checked={includes(selected, props.value)}
        />
      </div>
    ),
    sortable: false,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (props) => (
      props.value ?
        <span
          onClick={() => handleDialog(props.original.id)}
          className="name"
        >
          {props.value}
        </span>
        : <span> - </span>
    ),
  },
  {
    Cell: ({ original }) => (
      <div>
        <span onClick={() => handleDialog(original.id)}>
          <EditEntityButton />
        </span>
        <span>
          <DeleteEntityButton
            id={original.id}
            handleDelete={() => openDialog(() => handleDelete(original.id))}
          />
        </span>
      </div>
    ),
  },
];

export default hotelsListColumns;
/* eslint-enable */
