import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import lessonSchema from '../schema/Lesson';

export const fetchLessons = () => httpRequest({
  method: 'GET',
  path: '/lesson',
  types: [
    'LESSONS_GET_REQUEST',
    'LESSONS_GET_SUCCESS',
    'LESSONS_GET_FAILURE',
  ],
  schema: new schema.Array(lessonSchema),
});

export const fetchLessonsPrice = (id) => httpRequest({
  method: 'GET',
  path: `/group/${id}/lesson`,
  types: [
    'GROUP_GET_LESSONS_REQUEST',
    'GROUP_GET_LESSONS_SUCCESS',
    'GROUP_GET_LESSONS_FAILURE',
  ],
  schema: new schema.Array(lessonSchema),
});
export const fetchLessonsByGroup = (id) => httpRequest({
  method: 'GET',
  path: `/group/${id}/lesson`,
  types: [
    'GROUP_GET_LESSONS_REQUEST',
    'GROUP_GET_LESSONS_SUCCESS',
    'GROUP_GET_LESSONS_FAILURE',
  ],
  schema: new schema.Array(lessonSchema),
});

export const fetchLessonPrice = (body) => httpRequest({
  method: 'POST',
  path: '/lesson/price',
  body,
  types: [
    'LESSON_PRICE_POST_REQUEST',
    'LESSON_PRICE_POST_SUCCESS',
    'LESSON_PRICE_POST_FAILURE',
  ],
  schema: null,
});

export const createLesson = (body) => httpRequest({
  method: 'POST',
  path: '/lesson',
  body,
  types: [
    'LESSON_POST_REQUEST',
    'LESSON_POST_SUCCESS',
    'LESSON_POST_FAILURE',
  ],
  schema: lessonSchema,
});

export const updateLesson = (id, body) => httpRequest({
  method: 'PUT',
  path: `/lesson/${id}`,
  body,
  types: [
    'LESSON_PUT_REQUEST',
    'LESSON_PUT_SUCCESS',
    'LESSON_PUT_FAILURE',
  ],
  schema: lessonSchema,
});

export const removeLesson = (id) => httpRequest({
  method: 'DELETE',
  path: `/lesson/${id}`,
  types: [
    'LESSON_DELETE_REQUEST',
    'LESSON_DELETE_SUCCESS',
    'LESSON_DELETE_FAILURE',
  ],
  schema: lessonSchema,
});
