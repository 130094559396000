import React from 'react';
import PropTypes from 'prop-types';
import '../styles/DownloadEntityButton.scss';

const DownloadEntityButton = ({ id, handleDownload }) => {
  const handleButtonClicked = () => {
    if (handleDownload) {
      handleDownload(id);
    }
  };

  return (
    <div className="download-entity-button" onClick={handleButtonClicked}>
      <div className="download-entity-button__label" />
    </div>
  );
};

DownloadEntityButton.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleDownload: PropTypes.func,
};

export default React.memo(DownloadEntityButton);
