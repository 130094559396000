import { schema } from 'normalizr';
import specialitySchema from './Speciality';

const productSchema = new schema.Entity('product', { idAttribute: 'id' });
const seasonSchema = new schema.Entity('season', { idAttribute: 'id' });

productSchema.define({
  speciality: specialitySchema,
  seasons: new schema.Array(seasonSchema),
});

export default productSchema;
