import { createAction, getJSON } from 'redux-api-middleware';
import { normalize } from 'normalizr';
import { v4 as uuidv4 } from 'uuid';
import apiConfig from '../../config/api-config';
import * as c from '../constants/Api';

function onSuccess(method, dispatch, res, schema, clear) {
  return getJSON(res)
    .then((json) => {
      if (schema) {
        const data = clear ? { entities: json } : normalize(json, schema);
        // Dispatch the API Action (will merge with `entities` branch of store)
        dispatch({
          type: c.API_SUCCESS,
          payload: {
            method,
            schema,
            entities: data.entities,
            clear,
          },
        });

        // Payload for the secondary action type, will typically be merged into
        // a related model reducer somewhere else in the store
        return {
          result: data.entities,
        };
      }
      return {
        result: json,
      };
    });
}

function onFailure(dispatch, res) {
  return getJSON(res)
    .then((json) => {
      // Same as the default error action from `redux-api-middleware`
      const payload = {
        status: res.status,
        statusText: res.statusText,
        data: json,
      };
      dispatch({ type: 'GLOBAL_ERROR', payload });
      // Send to the API reducer and return for the secondary reducer
      dispatch({ type: c.API_FAILURE, payload });
      return payload;
    });
}

// eslint-disable-next-line import/prefer-default-export
export const httpRequest = (options) => (dispatch, getState) => new Promise((resolve, reject) => {
  const {
    method = 'GET',
    path,
    body = {},
    schema = null,
    types,
    clear,
  } = options;
  const endpoint = [apiConfig.invokeUrl, path].join('');
  let token;
  if (path !== '/user/login') {
    token = getState().user.accessToken;
  }
  const apiAction = createAction({
    method,
    endpoint,
    body: (Object.keys(body).length === 0) ? null : JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      'X-Request-ID': uuidv4(),
    },
    types: [{
      type: types[0],
      payload: () => dispatch({ type: c.API_REQUEST }),
    }, {
      type: types[1],
      payload: (action, state, res) => onSuccess(method, dispatch, res, schema, clear),
    }, {
      type: types[2],
      payload: (action, state, res) => onFailure(dispatch, res),
    }],
  });
  return dispatch(apiAction).then((response) => {
    if (response.error) {
      if (response.payload.status === 403) {
        window.location.href = '/login';
        return;
      }
      reject(response.payload);
    }
    resolve(response);
  });
});
