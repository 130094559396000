import _ from 'lodash';

const initialState = {};
const keyMap = { lessons: 'L', group: 'G', availability: 'A' };

const createGenericPayload = (key, item) => ({
  [key]: {
    instructor: item.instructor,
    timeFrom: item.timeFrom,
    timeTo: item.timeTo,
  },
});

const createPayloadFromGroup = (group) => {
  let payload = {};
  _.forEach(group.instructors, (instructor) => {
    const key = `${keyMap.group}-${group.id}-${instructor.id}`;
    payload = {
      ...payload,
      ...createGenericPayload(key, {
        ...group,
        instructor: instructor.id,
      }),
    };
  });
  return payload;
};

const events = (state = initialState, action) => {
  let payload = {};
  let newState = {};
  switch (action.type) {
    case 'EVENTS_GET_SUCCESS':
      _.forOwn(action.payload.result, (item) => {
        payload[item.id] = item;
      });
      return payload;
    case 'EVENT_PUT_SUCCESS': {
      const { result } = action.payload;
      const objectList = _.pick(result, _.keys(keyMap));
      _.forOwn(objectList, (object, key) => {
        const resolvedObject = _.pickBy(object, (_object) => Object.keys(_object).length > 2);
        _.forOwn(resolvedObject, (item) => {
          payload = (key === 'group') ? createPayloadFromGroup(item) : createGenericPayload(`${keyMap[key]}-${item.id}`, item);
        });
      });
      return _.merge({}, state, payload);
    }
    case 'EVENT_PUT_FAILURE':
      return _.merge({}, state, {});
    case 'EVENT_REMOVE':
      newState = {
        ...state,
      };
      delete newState[action.id];
      return newState;
    case 'EVENT_PATCH':
      newState = {
        ...state,
      };
      newState[action.id] = {
        ...action.item,
        ...newState[action.id],
        id: action.id,
      };
      return newState;
    case 'EVENTS_CLEAR':
      return {};
    default:
      return state;
  }
};

export default events;
