import React from 'react';
import PropTypes from 'prop-types';

const FailureUpload = ({ errors, handleTryAgain }) => (
  <div className="upload-modal-content upload-modal-content--failure">
    <div className="modal-header">
      File upload failed
    </div>
    <div className="modal-error">
      <ul>
        {errors.map((err) => (
          <li>{err.message}</li>
        ))}
      </ul>
    </div>
    <button onClick={() => handleTryAgain()} className="button button--try-again" type="button">Try again</button>
  </div>
);

FailureUpload.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
  })),
  handleTryAgain: PropTypes.func,
};

export default React.memo(FailureUpload);
