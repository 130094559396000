import { httpRequest } from '../middleware/http';

// eslint-disable-next-line import/prefer-default-export
export const getEshopToken = (body) => httpRequest({
  method: 'POST',
  path: '/instructor/filter/token',
  body,
  types: [
    'TOKEN_REQUEST',
    'TOKEN_SUCCESS',
    'TOKEN_FAILURE',
  ],
  schema: null,
});
