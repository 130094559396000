import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../styles/Calendar.scss';
import { convertToUTC } from '../utils/dateTime';

const GroupEvent = ({ event, onOpen }) => {
  const { timeFrom, timeTo } = event;

  const timeValues = useMemo(() => `${moment(convertToUTC(timeFrom)).toISOString()}-${moment(convertToUTC(timeTo)).toISOString()}`, [timeFrom, timeTo]);

  const onClick = () => {
    onOpen(event);
  };

  return (
    <div className="event whitefont" id={event.id}>
      <div style={{ display: 'flex' }}>
        <div className="event__size" onClick={onClick}>
          <div className="event__size-box">
            <span>{ event.size }</span>
            <div className="size-icon" />
          </div>
        </div>
        <div className="event__language">
          <span>{ event.language }</span>
          <div className={`activity__type-${event.activity}`}> </div>
          <span>{ event.specialityName }</span>
        </div>
        <div className="event__name">
          <span>{ event.name }</span>
        </div>
        <div className="event__wrapper">
          { !!event.minAge && !!event.maxAge
          && (
          <div className="event__age">
            <span>
              { event.minAge }
              {' '}
              -
              {' '}
              { event.maxAge }
              {' '}
              age
            </span>
          </div>
          )}
          <div className="event__amount">
            <span>
              { event.clientAmount }
              {' '}
              people
            </span>
          </div>
        </div>
      </div>
      <div className="hidden-times">
        {timeValues}
      </div>
    </div>
  );
};

GroupEvent.propTypes = {
  onOpen: PropTypes.func,
  event: PropTypes.shape({
    timeFrom: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    timeTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    size: PropTypes.number,
    language: PropTypes.string,
    activity: PropTypes.number,
    specialityName: PropTypes.string,
    name: PropTypes.string,
    minAge: PropTypes.number,
    maxAge: PropTypes.number,
    clientAmount: PropTypes.number,
  }),
};

export default React.memo(GroupEvent);
