import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import { map } from 'lodash';
import { minValue0, notEmpty } from '../utils/validators';
import { notifySuccess } from '../actions/notificationActions';
import { updatePlans } from '../actions/subscriptionPlanActions';
import '../styles/PricingForm.scss';

const RenderField = ({ label, name }) => (
  <div className="admin-pricing-form-block admin-pricing-form-block__render-field">
    <span className="admin-pricing-form-block admin-pricing-form-block__label">
      {label}
      :
    </span>
    <Field
      name={name}
      component={TextField}
      type="number"
      step={0.01}
      min={0}
      validate={[minValue0, notEmpty]}
    />
    <span className="admin-pricing-form-block admin-pricing-form-block__currency">EUR</span>
  </div>
);

const RenderBlock = ({ header, id }) => (
  <div className="admin-pricing-form-block">
    <span className="admin-pricing-form-block admin-pricing-form-block__header">{header}</span>
    <RenderField
      label="Yearly"
      name={`[${id}].priceAnnually`}
    />
    <RenderField
      label="Monthly"
      name={`[${id}].priceMonthly`}
    />
    <RenderField
      label="Setup fee"
      name={`[${id}].setupFee`}
    />
  </div>
);

const PricingForm = ({
  updatePlansA,
  changeDialogState,
  notifySuccessA,
  handleSubmit,
  invalid,
  initialValues,
}) => {
  const onSubmit = (values) => {
    const plans = map(values, (p) => p);
    updatePlansA(plans).then(() => {
      changeDialogState();
      notifySuccessA({}, 'Pricing updated successfully');
    });
  };

  return (
    <form
      className="admin-pricing-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div onClick={changeDialogState}>
        <i className="material-icons sidemenu__close pricing-close-button">
          close
        </i>
      </div>
      {map(initialValues, (plan, key) => (
        <RenderBlock
          key={key}
          header={plan.name}
          id={plan.id}
        />
      ))}
      <RaisedButton
        backgroundColor="#00559D"
        labelColor="#ffffff"
        type="submit"
        label="UPDATE"
        disabled={invalid}
      />
    </form>
  );
};

RenderField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

RenderBlock.propTypes = {
  header: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

PricingForm.propTypes = {
  updatePlansA: PropTypes.func,
  changeDialogState: PropTypes.func,
  notifySuccessA: PropTypes.func,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
};

const formConfig = {
  form: 'PricingForm',
};

const mapDispatchToProps = (dispatch) => ({
  updatePlansA: bindActionCreators(updatePlans, dispatch),
  notifySuccessA: bindActionCreators(notifySuccess, dispatch),
});

export default connect(null, mapDispatchToProps)(reduxForm(formConfig)(PricingForm));
