import React from 'react';
import PropTypes from 'prop-types';
import '../styles/TodaysCalendarButton.scss';

const TodaysCalendarButton = ({ onClick }) => (
  <div className="today-button" onClick={onClick} />
);

TodaysCalendarButton.propTypes = {
  onClick: PropTypes.func,
};

export default React.memo(TodaysCalendarButton);
