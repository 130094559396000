import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import subscriptionPlanSchema from '../schema/SubscriptionPlan';

export const fetchPlans = () => httpRequest({
  method: 'GET',
  path: '/plan',
  types: [
    'PLAN_GET_REQUEST',
    'PLAN_GET_SUCCESS',
    'PLAN_GET_FAILURE',
  ],
  schema: new schema.Array(subscriptionPlanSchema),
});

export const updatePlans = (body) => httpRequest({
  method: 'PUT',
  path: '/plan',
  body,
  types: [
    'PLAN_GET_REQUEST',
    'PLAN_GET_SUCCESS',
    'PLAN_GET_FAILURE',
  ],
  schema: new schema.Array(subscriptionPlanSchema),
});

export const getPaymentLink = (id, plan) => httpRequest({
  method: 'GET',
  path: `/subscription/payment/${id}/${plan}`,
  types: [
    'LINK_GET_REQUEST',
    'LINK_GET_SUCCESS',
    'LINK_GET_FAILURE',
  ],
});

export const getDiscount = (code, id, subType) => httpRequest({
  method: 'GET',
  path: `/coupon/price/${code}/${id}/${subType}`,
  types: [
    'DISCOUNT_GET_REQUEST',
    'DISCOUNT_GET_SUCCESS',
    'DISCOUNT_GET_FAILURE',
  ],
  schema: null,
});
