import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { map } from 'lodash';
import RenderRow from './RenderRow';
import RenderPricingTableHeader from './RenderPricingTableHeader';

const RenderPricingTable = ({
  fields,
  currency,
  handleGroup,
  handleSeason,
  handleRemoveSeason,
  handleSizeChange,
}) => (
  <div className="season-pricing">
    <table className="season-pricing__table">
      <thead>
        <FieldArray
          component={RenderPricingTableHeader}
          name={`${fields.name}[0]`}
          handleSeason={handleSeason}
          handleRemoveSeason={handleRemoveSeason}
        />
      </thead>
      <tbody>
        {fields.map((row, index) => (
          <FieldArray
            key={index}
            component={RenderRow}
            name={row}
            currency={currency}
            index={index}
            removeRow={() => fields.remove(index)}
            handleSizeChange={handleSizeChange}
          />
        ))}
        <tr key="buttonAddGroup">
          <td>
            <div className="season-pricing__add-group">
              <button
                className="button--add-group"
                disabled={fields.getAll() ? !fields.getAll().length : true}
                onClick={() => fields.push(handleGroup())}
                type="button"
              />
            </div>
          </td>
          {map(fields.get(0), (td, index) => (
            <td key={index}> </td>
          ))}
          <td> </td>
        </tr>
      </tbody>
    </table>
  </div>
);

RenderPricingTable.propTypes = {
  fields: PropTypes.shape({
    name: PropTypes.string,
    map: PropTypes.func,
    getAll: PropTypes.func,
    remove: PropTypes.func,
    push: PropTypes.func,
    get: PropTypes.func,
  }),
  currency: PropTypes.string,
  handleGroup: PropTypes.func,
  handleSeason: PropTypes.func,
  handleRemoveSeason: PropTypes.func,
  handleSizeChange: PropTypes.func,
};

export default RenderPricingTable;
