import React from 'react';
import PropTypes from 'prop-types';
import '../styles/DeleteEntityButtonPricingTable.scss';

const DeleteEntityButtonPricingTable = ({ onClick }) => (
  <div className="delete-entity-button-pricing-table" onClick={onClick} />
);

DeleteEntityButtonPricingTable.propTypes = {
  onClick: PropTypes.func,
};

export default React.memo(DeleteEntityButtonPricingTable);
