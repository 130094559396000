import { schema } from 'normalizr';
import moment from 'moment';
import { httpRequest } from '../middleware/http';
import groupSchema from '../schema/Group';
import groupPriceSchema from '../schema/GroupPrice';

export const fetchGroups = (pageNumber, pageSize, body) => httpRequest({
  method: 'POST',
  path: `/group/list?page=${pageNumber}&size=${pageSize}`,
  types: [
    'GROUP_GET_REQUEST',
    'GROUP_GET_SUCCESS',
    'GROUP_GET_FAILURE',
  ],
  body,
});

export const fetchGroupsForBookings = () => {
  const timestamp = moment().startOf('day').unix();
  return httpRequest({
    method: 'GET',
    path: `/groups/from/${timestamp}`,
    types: [
      'GROUP_GET_REQUEST',
      'GROUP_GET_SUCCESS',
      'GROUP_GET_FAILURE',
    ],
    schema: new schema.Array(groupSchema),
  });
};

export const fetchGroup = (id) => httpRequest({
  method: 'GET',
  path: `/group/${id}`,
  types: [
    'GROUP_GET_REQUEST',
    'GROUP_GET_SUCCESS',
    'GROUP_GET_FAILURE',
  ],
  schema: groupSchema,
});

export const createGroup = (body) => httpRequest({
  method: 'POST',
  path: '/group',
  body,
  types: [
    'GROUP_POST_REQUEST',
    'GROUP_POST_SUCCESS',
    'GROUP_POST_FAILURE',
  ],
  schema: new schema.Array(groupSchema),
});

export const deleteGroup = (id) => httpRequest({
  method: 'DELETE',
  path: `/group/${id}`,
  types: [
    'GROUP_DELETE_REQUEST',
    'GROUP_DELETE_SUCCESS',
    'GROUP_DELETE_FAILURE',
  ],
  schema: groupSchema,
});

export const deleteGroups = (body) => httpRequest({
  method: 'DELETE',
  path: '/groups',
  body,
  types: [
    'GROUP_DELETE_REQUEST',
    'GROUP_DELETE_SUCCESS',
    'GROUP_DELETE_FAILURE',
  ],
  schema: groupSchema,
});

export const updateGroup = (id, body) => httpRequest({
  method: 'PUT',
  path: `/group/${id}`,
  body,
  types: [
    'GROUP_PUT_REQUEST',
    'GROUP_PUT_SUCCESS',
    'GROUP_PUT_FAILURE',
  ],
  schema: groupSchema,
});

export const updateGroupRange = (guid, body) => httpRequest({
  method: 'PUT',
  path: `/group/${guid}`,
  body,
  types: [
    'GROUP_PUT_REQUEST',
    'GROUP_PUT_SUCCESS',
    'GROUP_PUT_FAILURE',
  ],
  schema: new schema.Array(groupPriceSchema),
});

export const fetchGroupPrice = (guid) => httpRequest({
  method: 'GET',
  path: `/groupPrice/${guid}`,
  types: [
    'GROUP_PRICE_GET_REQUEST',
    'GROUP_PRICE_GET_SUCCESS',
    'GROUP_PRICE_GET_FAILURE',
  ],
  schema: new schema.Array(groupPriceSchema),
});

export const fetchGroupDates = (guid) => httpRequest({
  method: 'GET',
  path: `/group/${guid}/date`,
  types: [
    'GROUP_DATES_GET_REQUEST',
    'GROUP_DATES_GET_SUCCESS',
    'GROUP_DATES_GET_FAILURE',
  ],
  schema: null,
});

export const updateGroupPrice = (guid, body) => httpRequest({
  method: 'PUT',
  body,
  path: `/groupPrice/${guid}`,
  types: [
    'GROUP_PRICE_PUT_REQUEST',
    'GROUP_PRICE_PUT_SUCCESS',
    'GROUP_PRICE_PUT_FAILURE',
  ],
  schema: new schema.Array(groupPriceSchema),
});

export const fetchGroupByGuid = (guid) => httpRequest({
  method: 'GET',
  path: `/group/${guid}`,
  types: [
    'GROUP_GET_REQUEST',
    'GROUP_GET_SUCCESS',
    'GROUP_GET_FAILURE',
  ],
  schema: new schema.Array(groupSchema),
});

export const exportGroups = (body) => httpRequest({
  method: 'POST',
  path: '/group/export',
  types: [
    'GROUP_EXPORT_REQUEST',
    'GROUP_EXPORT_SUCCESS',
    'GROUP_EXPORT_FAILURE',
  ],
  body,
  schema: null,
});
