import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { DateField } from '../components/Field';
import { required } from '../utils/validators';
import { checkSeasonsOverlapingValidity } from '../utils/helpers';

const SeasonForm = ({
  handleSubmit,
  handleSeasonAdd,
  dateValues,
  error,
  change,
}) => {
  useEffect(() => {
    change('dateFrom', null);
    change('dateTo', null);

    return () => {
      change('dateFrom', null);
      change('dateTo', null);
    };
  }, []);

  const handleDate = (type) => (event, val) => {
    switch (type) {
      case 'dateFrom':
        change('dateFrom', val);
        break;
      case 'dateTo':
        change('dateTo', val);
        break;
      default:
        break;
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleSeasonAdd)}
    >
      <DateField
        fieldLabel="From:"
        name="dateFrom"
        hintText="Season start date"
        onChange={handleDate('dateFrom')}
        validate={[required]}
      />
      <DateField
        fieldLabel="To:"
        name="dateTo"
        hintText="Season end date"
        onChange={handleDate('dateTo')}
        minDate={dateValues.dateFrom}
        validate={[required]}
      />
      <span className="error">
        {' '}
        {error && <span>{error}</span>}
      </span>
    </form>
  );
};

const validate = (values, props) => {
  const errors = {};
  const { fields } = props;
  if (!checkSeasonsOverlapingValidity(fields.getAll(), values.dateFrom, values.dateTo)) {
    // eslint-disable-next-line no-underscore-dangle
    errors._error = 'Season overlaps other season';
  }
  if (values.dateFrom && values.dateTo && values.dateFrom > values.dateTo) {
    // eslint-disable-next-line no-underscore-dangle
    errors._error = 'Not valid dates selected';
  }
  return errors;
};

const formConfig = {
  form: 'RenderPricingTableHeader',
  enableReinitialize: true,
  validate,
};

SeasonForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleSeasonAdd: PropTypes.func,
  dateValues: PropTypes.shape({
    dateFrom: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  error: PropTypes.string,
  change: PropTypes.func,
};

export default reduxForm(formConfig)(React.memo(SeasonForm));
