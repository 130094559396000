import { schema } from 'normalizr';
import instructorSchema from './Instructor';
import specialitySchema from './Speciality';
import resortSchema from './Resort';
import groupPriceSchema from './GroupPrice';

const groupSchema = new schema.Entity('group', { idAttribute: 'id' });

groupSchema.define({
  instructor: instructorSchema,
  speciality: specialitySchema,
  resort: resortSchema,
  groupPrice: new schema.Array(groupPriceSchema),
});

export default groupSchema;
