import React from 'react';
import PropTypes from 'prop-types';
import '../styles/FormField.scss';

const FormField = ({
  label,
  theme,
  children,
}) => (
  <div className="form-field">
    {label
        && (
        <span>
          {theme
            ? (
              <div className={`form-label__${theme}`}>
                {label}
              </div>
            )
            : (
              <div className="form-label">
                {label}
              </div>
            )}
        </span>
        )}

    {theme
      ? (
        <div className={`form-input form-input__${theme}`}>
          { children }
        </div>
      )
      : (
        <div className="form-input">
          { children }
        </div>
      )}
  </div>
);

FormField.propTypes = {
  label: PropTypes.string,
  theme: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
};

export default React.memo(FormField);
