import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/AvailabilityListIcons.scss';

const CalendarButton = (props) => {
  const openDialog = () => {
    props.open(props.refName);
  };

  return (
    <div className="c" onClick={openDialog} />
  );
};

CalendarButton.propTypes = {
  open: PropTypes.func,
  refName: PropTypes.string,
};

export default React.memo(CalendarButton);
