import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import { maxBy } from 'lodash';
import TourButton from './tour-button';
import TourButtonContainer from './tour-button-container';
import Arrow from './arrow';
import positions from './helpers/position-helpers';
import * as viewBoxHelpers from './helpers/viewbox-helpers';
import scrollToPosition from './helpers/scroll-to-position';

const getContainerHeight = () => {
  const container = document.querySelector('.react-user-tour-container');
  if (container) {
    const el = container.firstChild;
    return window.getComputedStyle(el, null).getPropertyValue('height');
  }
  return 0;
};

class ReactUserTour extends Component {
  constructor(props) {
    super(props);
    this.prevPos = {
      top: 0,
      left: 0,
    };
    this.getStepPosition = this.getStepPosition.bind(this);
    this.state = {
      height: 0,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { active } = nextProps;

    if (active) {
      setTimeout(() => this.setState({ height: getContainerHeight() }), 10);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      step,
      active,
      hideButtons,
      hideClose,
    } = this.props;
    const {
      step: nextStep,
      active: nextActive,
      hideButtons: nextHideButtons,
      hideClose: nextHideClose,
    } = nextProps;
    const { height } = this.state;
    const { height: nextHeight } = nextState;
    return step !== nextStep
      || active !== nextActive
      || hideButtons !== nextHideButtons
      || hideClose !== nextHideClose
      || height !== nextHeight;
  }

  getStepPosition(
    selector,
    tourElWidth,
    tourElHeight,
    overridePos,
    margin = 25,
    horizontalOffset = 0,
    verticalOffset = 0,
  ) {
    const { arrowSize } = this.props;
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const el = document.querySelector(selector);
    if (el) {
      let position = el ? el.getBoundingClientRect() : {};
      const isElementBelowViewBox = viewBoxHelpers
        .isElementBelowViewBox(windowHeight, position.top);
      const isElementAboveViewBox = viewBoxHelpers.isElementBelowViewBox(position.bottom);
      if (isElementBelowViewBox) {
        position = scrollToPosition(el, position.bottom);
      } else if (isElementAboveViewBox) {
        position = scrollToPosition(el, window.pageYOffset + position.top);
      }
      const shouldPositionLeft = viewBoxHelpers.shouldPositionLeft(windowWidth, position.left);
      const shouldPositionAbove = viewBoxHelpers.shouldPositionAbove(windowHeight, position.bottom);
      const shouldPositionBelow = viewBoxHelpers.shouldPositionBelow(position.top);
      let elPos;
      if (overridePos && positions[overridePos]) {
        elPos = positions[overridePos]({
          position,
          tourElWidth,
          tourElHeight,
          arrowSize,
          offsetHeight: el.offsetHeight,
          margin,
        });
      } else if (shouldPositionLeft && !shouldPositionAbove && !shouldPositionBelow) {
        elPos = positions.left({
          position,
          tourElWidth,
          margin,
        });
      } else if (shouldPositionAbove) {
        elPos = shouldPositionLeft ? positions.topLeft({
          position,
          tourElWidth,
          tourElHeight,
          arrowSize,
          margin,
        })
          : positions.top({
            position,
            tourElHeight,
            arrowSize,
            margin,
          });
      } else if (shouldPositionBelow) {
        elPos = shouldPositionLeft ? positions.bottomLeft({
          position,
          tourElWidth,
          arrowSize,
          offsetHeight: el.offsetHeight,
          margin,
        })
          : positions.bottom({
            position,
            arrowSize,
            offsetHeight: el.offsetHeight,
            margin,
          });
      } else {
        elPos = positions.right({
          position,
          margin,
        });
      }

      elPos.left += horizontalOffset;
      elPos.top += verticalOffset;

      this.prevPos = elPos;
      return elPos;
    }

    return this.prevPos;
  }

  getCustomArrow(position) {
    const {
      arrow,
      style: { width },
      arrowSize,
      arrowColor,
    } = this.props;
    const { height } = this.state;
    return (
      typeof arrow === 'function'
        ? arrow({
          position: position.positioned,
          width,
          height,
          size: arrowSize,
          color: arrowColor,
        })
        : arrow
    );
  }

  render() {
    const {
      steps,
      step,
      active,
      style,
      arrow: propsArrow,
      arrowSize,
      arrowColor,
      onSkip,
      skipButtonText,
      onNext,
      nextButtonText,
      onBack,
      backButtonText,
      onCancel,
      doneButtonText,
      hideButtons,
      hideClose,
      closeButtonText,
      containerStyle,
    } = this.props;
    const { height } = this.state;
    const currentTourStep = steps.filter((s) => s.step === step)[0];
    const lastTourStep = maxBy(steps, 'step');
    let nextTourStep = steps.filter((s) => s.step === step + 1);
    let prevTourStep = steps.filter((s) => s.step === step - 1);

    if (nextTourStep) {
      [nextTourStep] = nextTourStep;
    }

    if (prevTourStep) {
      [prevTourStep] = prevTourStep;
    }

    if (!active || !currentTourStep) {
      return <span />;
    }
    const position = this.getStepPosition(
      currentTourStep.selector,
      style.width,
      '',
      currentTourStep.position,
      currentTourStep.margin,
      currentTourStep.horizontalOffset,
      currentTourStep.verticalOffset,
    );

    const arrow = (
      propsArrow
        ? this.getCustomArrow(position)
        : (
          <Arrow
            position={position.positioned}
            width={style.width}
            height={height}
            size={arrowSize}
            color={arrowColor}
          />
        )
    );

    const skipButton = (
      <TourButton
        onClick={() => onSkip(lastTourStep, 'skip')}
        className="transparent transparent--underlined"
      >
        {skipButtonText}
      </TourButton>
    );

    const nextButton = (
      !currentTourStep.lastStep
        ? (
          <TourButton
            onClick={() => onNext(step + 1, nextTourStep)}
            className="filled"
          >
            {nextButtonText}
          </TourButton>
        ) : ''
    );

    const backButton = (
      step > 1
        ? (
          <TourButton
            onClick={() => onBack(step - 1, prevTourStep)}
            className="transparent"
          >
            {backButtonText}
          </TourButton>
        ) : ''
    );

    const doneButton = (
      currentTourStep.lastStep
        ? (
          <TourButton
            onClick={() => onCancel(currentTourStep)}
            className="filled"
          >
            {doneButtonText}
          </TourButton>
        ) : ''
    );

    const tourButtonContainer = (
      !hideButtons
        ? (
          <TourButtonContainer>
            {skipButton}
            <div className="right-floated">
              {backButton}
              {nextButton}
              {doneButton}
            </div>
          </TourButtonContainer>
        ) : ''
    );

    const xStyle = {
      float: 'right',
      cursor: 'pointer',
      paddingRight: 10,
      paddingTop: 10,
    };

    const closeButton = (
      !hideClose
        ? (
          <span
            className="react-user-tour-close"
            style={xStyle}
            onClick={onCancel}
            onTouchTap={onCancel}
          >
            {closeButtonText}
          </span>
        ) : ''
    );

    return (
      <div className="react-user-tour-container" style={containerStyle}>
        <Motion style={{ x: spring(position.left), y: spring(position.top) }}>
          {({ x, y }) => (
            <div style={{ ...style, transform: `translate3d(${x}px, ${y}px, 0)` }}>
              {arrow}
              <div className="react-user-tour-wrapper">
                {closeButton}
                {currentTourStep.title}
                {currentTourStep.body}
                {tourButtonContainer}
              </div>
            </div>
          )}
        </Motion>
      </div>
    );
  }
}

ReactUserTour.defaultProps = {
  style: {
    height: 'auto',
    width: 400,
    position: 'absolute',
    zIndex: 1400,
    backgroundColor: '#1E88E5',
    color: '#FFF',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.14), 0 3px 4px 0 rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    borderRadius: '16px',
  },
  containerStyle: {},
  onCancel: () => {},
  onNext: () => {},
  onBack: () => {},
  onSkip: () => {},
  nextButtonText: 'Next',
  backButtonText: 'Back',
  doneButtonText: 'Done',
  closeButtonText: 'Close',
  skipButtonText: 'Skip',
  hideButtons: false,
  hideClose: false,
  arrowColor: '#1E88E5',
  arrowSize: 10,
};

ReactUserTour.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    position: PropTypes.string,
    zIndex: PropTypes.number,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    boxShadow: PropTypes.string,
    borderRadius: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  containerStyle: PropTypes.object,
  onCancel: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onSkip: PropTypes.func,
  nextButtonText: PropTypes.string,
  backButtonText: PropTypes.string,
  doneButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  skipButtonText: PropTypes.string,
  hideButtons: PropTypes.bool,
  hideClose: PropTypes.bool,
  arrowColor: PropTypes.string,
  arrowSize: PropTypes.number,
  active: PropTypes.bool,
  step: PropTypes.number,
  arrow: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  steps: PropTypes.arrayOf(PropTypes.object),
};

export default ReactUserTour;
