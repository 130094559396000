import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { get, find } from 'lodash';
import { fetchPlans } from '../actions/subscriptionPlanActions';
import { fetchMyAccount } from '../actions/accountActions';
import { notifyWarning } from '../actions/notificationActions';
import { showWarning, hideWarning } from '../actions/usedAmounts';
import { fetchBookingsUsedAmount } from '../actions/bookingActions';
import { fetchInstructorsUsedAmount } from '../actions/instructorsActions';
import { isEmptyObject } from '../utils/helpers';

const params = {
  instructors: {
    displayName: 'Active instructors',
    entityName: 'instructorLimit',
    entityResponseName: 'activeInstructorsCount',
    messageDisplayName: 'active instructors',
  },
  bookings: {
    displayName: 'Bookings',
    entityName: 'bookingLimit',
    entityResponseName: 'activeUpcomingBookingsCount',
    messageDisplayName: 'bookings',
  },
};

class EntityAmountDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notified: false,
      planId: undefined,
      used: 0,
      total: 0,
      displayName: '',
    };
  }

  componentDidMount() {
    const {
      user: { role },
      fetchMyAccountA,
      fetchPlansA,
      fetchInstructorsUsedAmountA,
      fetchBookingsUsedAmountA,
    } = this.props;
    if (role && role === 'instructor') {
      return;
    }
    fetchMyAccountA();
    fetchPlansA();
    fetchInstructorsUsedAmountA();
    fetchBookingsUsedAmountA();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      plans,
      account,
      user,
      user: { role },
      usedAmounts,
      history,
      entity,
    } = nextProps;
    const {
      notifyWarningA,
      showLimitationWarningA,
      hideLimitationWarningA,
    } = this.props;
    const { notified } = this.state;

    if (role === 'instructor') {
      return;
    }
    const paramsObj = this.getEntityParams(entity);

    const state = {
      ...this.getTotalAmount(user, account, plans, paramsObj),
      used: this.getUsedAmount(usedAmounts, paramsObj),
      displayName: paramsObj.displayName,
    };

    if (this.notifyUser(state)) {
      if (!notified) {
        notifyWarningA(
          { autoDismiss: 15, action: { label: 'Upgrade now', callback: () => history.push('/account') } },
          this.getNotifyMsg(paramsObj.messageDisplayName),
        );
        this.setState({ notified: true });
      }

      if (!usedAmounts.warningOn[paramsObj.entityName]) {
        showLimitationWarningA(paramsObj.entityName);
      }
    } else if (usedAmounts.warningOn[paramsObj.entityName]) {
      hideLimitationWarningA(paramsObj.entityName);
    }

    this.setState({ ...state });
  }

  getTotalAmount = (user, account, plans, paramsObj) => {
    if (user && !isEmptyObject(user)
      && account && !isEmptyObject(account)
      && plans && !isEmptyObject(plans)) {
      const planId = get(account[user.id], 'plan.id');
      const currentPlan = find(plans, { id: planId });
      const { entityName } = paramsObj;
      const total = currentPlan ? currentPlan[entityName] : 0;

      return {
        total,
        planId,
      };
    }
    return undefined;
  };

  getUsedAmount = (usedAmounts, paramsObj) => usedAmounts[paramsObj.entityResponseName] || 0;

  getEntityParams = (entity) => params[entity];

  notifyUser = (paramsObj) => {
    const { used, total } = paramsObj;

    if ((!used && !total) || total < 0) {
      return false;
    }

    const totalFourFifth = (total * 80) / 100;

    return used >= totalFourFifth || total - used <= 1;
  };

  getNotifyMsg = (messageDisplayName) => `You are getting close to your ${messageDisplayName} limit`;

  render() {
    const { hide } = this.props;
    const {
      used,
      total,
      displayName,
      planId,
    } = this.state;

    if (hide || (planId === 2 && displayName === 'Bookings')) {
      return <span> </span>;
    }

    return total !== -1 ? (
      <div className="entity-amount-display">{`${displayName} ${used} out of ${total}`}</div>
    ) : <div />;
  }
}

EntityAmountDisplay.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  fetchPlansA: PropTypes.func,
  fetchMyAccountA: PropTypes.func,
  notifyWarningA: PropTypes.func,
  showLimitationWarningA: PropTypes.func,
  hideLimitationWarningA: PropTypes.func,
  fetchBookingsUsedAmountA: PropTypes.func,
  fetchInstructorsUsedAmountA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  plans: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.object,
  usedAmounts: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    warningOn: PropTypes.object,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  entity: PropTypes.string,
  hide: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  fetchPlansA: bindActionCreators(fetchPlans, dispatch),
  fetchMyAccountA: bindActionCreators(fetchMyAccount, dispatch),
  notifyWarningA: bindActionCreators(notifyWarning, dispatch),
  showLimitationWarningA: bindActionCreators(showWarning, dispatch),
  hideLimitationWarningA: bindActionCreators(hideWarning, dispatch),
  fetchBookingsUsedAmountA: bindActionCreators(fetchBookingsUsedAmount, dispatch),
  fetchInstructorsUsedAmountA: bindActionCreators(fetchInstructorsUsedAmount, dispatch),
});

const mapStateToProps = (state) => ({
  plans: state.entities.subscriptionPlan,
  account: state.entities.account,
  user: state.user,
  usedAmounts: state.usedAmounts,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EntityAmountDisplay));
