import React from 'react';

import SuccessIcon from '../styles/images/status-success.svg';
import '../styles/Payment.scss';

const PaymentErrorPage = () => (
  <div className="payment-success-page">
    <div className="payment-success-page__content-container">
      <div className="payment-success-page__content-container__top">
        <img
          alt="Success icon"
          className="success-indicator"
          src={SuccessIcon}
        />
        <div className="success-message">THANK YOU</div>
      </div>
      <div className="payment-success-page__content-container__bottom">
        <div className="booking-success">You have successfully paid for your booking!</div>
        <div className="message">Enjoy your vacation!</div>
      </div>
    </div>
  </div>
);

export default PaymentErrorPage;
