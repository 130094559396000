const initialState = [];

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INVOICES_GET_SUCCESS': {
      const invoices = action.payload.result;

      return [
        ...invoices,
      ];
    }
    case 'CLEAR_INVOICES': {
      return [];
    }
    default:
      return state;
  }
};

export default invoicesReducer;
