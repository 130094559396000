// Season
export const SEASON_GET_REQUEST = 'SEASON_GET_REQUEST';
export const SEASON_GET_SUCCESS = 'SEASON_GET_SUCCESS';
export const SEASON_GET_FAILURE = 'SEASON_GET_FAILURE';
export const SEASON_PUT_REQUEST = 'SEASON_PUT_REQUEST';
export const SEASON_PUT_SUCCESS = 'SEASON_PUT_SUCCESS';
export const SEASON_PUT_FAILURE = 'SEASON_PUT_FAILURE';
export const SEASON_DELETE_REQUEST = 'SEASON_DELETE_REQUEST';
export const SEASON_DELETE_SUCCESS = 'SEASON_DELETE_SUCCESS';
export const SEASON_DELETE_FAILURE = 'SEASON_DELETE_FAILURE';

// Seasons
export const SEASONS_POST_REQUEST = 'SEASONS_POST_REQUEST';
export const SEASONS_POST_SUCCESS = 'SEASONS_POST_SUCCESS';
export const SEASONS_POST_FAILURE = 'SEASONS_POST_FAILURE';
export const SEASONS_GET_REQUEST = 'SEASONS_GET_REQUEST';
export const SEASONS_GET_SUCCESS = 'SEASONS_GET_SUCCESS';
export const SEASONS_GET_FAILURE = 'SEASONS_GET_FAILURE';

// Lesson type
export const PRODUCT_GET_REQUEST = 'PRODUCT_GET_REQUEST';
export const PRODUCT_GET_SUCCESS = 'PRODUCT_GET_SUCCESS';
export const PRODUCT_GET_FAILURE = 'PRODUCT_GET_FAILURE';
export const PRODUCT_POST_REQUEST = 'PRODUCTS_POST_REQUEST';
export const PRODUCT_POST_SUCCESS = 'PRODUCTS_POST_SUCCESS';
export const PRODUCT_POST_FAILURE = 'PRODUCTS_POST_FAILURE';
export const PRODUCT_PUT_REQUEST = 'PRODUCT_PUT_REQUEST';
export const PRODUCT_PUT_SUCCESS = 'PRODUCT_PUT_SUCCESS';
export const PRODUCT_PUT_FAILURE = 'PRODUCT_PUT_FAILURE';
export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAILURE = 'PRODUCT_DELETE_FAILURE';
export const PRODUCT_GROUP_SEASON_ENTRY_ADD = 'PRODUCT_GROUP_SEASON_ENTRY_ADD';
export const PRODUCT_GROUP_STATE_ADD = 'PRODUCT_GROUP_STATE_ADD';
export const PRODUCT_GROUPS_UPDATE = 'PRODUCT_GROUPS_UPDATE';

// Lesson types
export const PRODUCTS_GET_REQUEST = 'PRODUCTS_GET_REQUEST';
export const PRODUCTS_GET_SUCCESS = 'PRODUCTS_GET_SUCCESS';
export const PRODUCTS_GET_FAILURE = 'PRODUCTS_GET_FAILURE';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
