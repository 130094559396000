import React from 'react';
import PropTypes from 'prop-types';

const InstructorSelectReadOnly = ({ instructors }) => (
  <div className="form-field">
    <span>
      <div className="form-label__white">Instructor(s):</div>
    </span>
    <div className="form-input form-input__white">
      <div className="react-select">
        <div className="Select has-value is-disabled Select--multi">
          <div className="Select-control">
            <div className="Select-multi-value-wrapper" id="react-select-18--value">
              {instructors?.map((inst) => (
                <div className="Select-value">
                  <span
                    className="Select-value-label"
                    role="option"
                    aria-selected="true"
                    id="react-select-13--value-0"
                  >
                    {inst.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

InstructorSelectReadOnly.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  instructors: PropTypes.array,
};

export default InstructorSelectReadOnly;
