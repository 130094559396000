import React from 'react';
import PropTypes from 'prop-types';

const TourButtonContainer = ({ children }) => (
  <div className="guide-buttons">
    {children}
  </div>
);

TourButtonContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ])),
};

export default TourButtonContainer;
