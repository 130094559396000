import {
  ACTIVATE_GUIDE,
  DEACTIVATE_GUIDE,
  GO_TO_STEP,
  SET_TIMELINE_ANCHOR,
  TRIGGER_TIMELINE_BOUNDS_CHANGE,
  REMOVE_TIMELINE_BOUNDS_CHANGE,
  TRIGGER_MENU_OPEN,
  REMOVE_MENU_OPEN,
  REMOVE_BOOKING_SCROLL_INTO_VIEW,
  SET_INSTRUCTOR_ANCHOR,
  TRIGGER_AVAILABILITY_FORM_OPEN,
  TRIGGER_AVAILABILITY_FORM_CLOSE,
  TRIGGER_GROUP_FORM_OPEN,
  TRIGGER_BOOKING_FORM_OPEN,
  TRIGGER_GROUP_FORM_CLOSE,
  TRIGGER_BOOKING_FORM_CLOSE,
  GROUP_SCROLL_INTO_VIEW,
  BOOKING_SCROLL_INTO_VIEW,
  TRIGGER_ADD_NEW_CLIENT_OPEN,
  TRIGGER_ADD_NEW_PARTNER_OPEN,
  TRIGGER_CLIENT_FROM_PARTNER_OPEN,
  TRIGGER_LESSON_BLOCK_ADD,
  TRIGGER_ADDITIONAL_SERVICE_OPEN,
  TRIGGER_PARTNER_MODAL_OPEN,
  TRIGGER_CREATE_OFFERS_OPEN,
  SET_MANAGER_ANCHOR,
  TRIGGER_CREATE_OFFERS_CLOSE,
  TRIGGER_EXPORT_STEP_CHANGE,
  TRIGGER_CALENDAR_SCROLL_INTO_VIEW,
  REMOVE_CALENDAR_SCROLL_INTO_VIEW,
} from '../containers/UserGuide/constants';

export const activateGuide = (currentGuide, general = false, step = 1) => ({
  type: ACTIVATE_GUIDE,
  payload: { currentGuide, general, step },
});

export const deactivateGuide = () => ({ type: DEACTIVATE_GUIDE });

export const goToStep = (step) => ({ type: GO_TO_STEP, payload: { step } });

export const setTimelineAnchor = (timelineAnchor) => ({
  type: SET_TIMELINE_ANCHOR,
  payload: { timelineAnchor },
});

export const triggerTimelineBoundsChange = (value) => ({
  type: TRIGGER_TIMELINE_BOUNDS_CHANGE,
  payload: { triggerTimelineBoundsChange: value },
});

export const removeTimelineBoundsChange = () => ({ type: REMOVE_TIMELINE_BOUNDS_CHANGE });

export const triggerMenuOpen = () => ({ type: TRIGGER_MENU_OPEN });

export const removeMenuOpen = () => ({ type: REMOVE_MENU_OPEN });

export const setInstructorAnchor = (instructorAnchor) => ({
  type: SET_INSTRUCTOR_ANCHOR,
  payload: { instructorAnchor },
});

export const triggerAvailabilityFormOpen = () => ({ type: TRIGGER_AVAILABILITY_FORM_OPEN });

export const triggerAvailabilityFormClose = () => ({ type: TRIGGER_AVAILABILITY_FORM_CLOSE });

export const triggerGroupFormOpen = () => ({ type: TRIGGER_GROUP_FORM_OPEN });

export const triggerGroupFormClose = () => ({ type: TRIGGER_GROUP_FORM_CLOSE });

export const triggerBookingFormOpen = () => ({ type: TRIGGER_BOOKING_FORM_OPEN });

export const triggerBookingFormClose = () => ({ type: TRIGGER_BOOKING_FORM_CLOSE });

export const triggerGroupScrollIntoView = () => ({ type: GROUP_SCROLL_INTO_VIEW });

export const triggerBookingScrollIntoView = (bookingScrollIntoView, alignTop) => ({
  type: BOOKING_SCROLL_INTO_VIEW,
  payload: { bookingScrollIntoView, alignTop },
});

export const removeBookingScrollIntoView = () => ({ type: REMOVE_BOOKING_SCROLL_INTO_VIEW });

export const triggerAddNewClientOpen = () => ({ type: TRIGGER_ADD_NEW_CLIENT_OPEN });

export const triggerAddNewPartnerOpen = () => ({ type: TRIGGER_ADD_NEW_PARTNER_OPEN });

export const triggerClientFromPartnerOpen = () => ({ type: TRIGGER_CLIENT_FROM_PARTNER_OPEN });

export const triggerLessonBlockAdd = () => ({ type: TRIGGER_LESSON_BLOCK_ADD });

export const triggerAdditionalServiceOpen = () => ({ type: TRIGGER_ADDITIONAL_SERVICE_OPEN });

export const triggerPartnerModalOpen = () => ({ type: TRIGGER_PARTNER_MODAL_OPEN });

export const setManagerAnchor = (managerAnchor) => ({
  type: SET_MANAGER_ANCHOR,
  payload: { managerAnchor },
});

export const triggerCreateOffersOpen = () => ({ type: TRIGGER_CREATE_OFFERS_OPEN });

export const triggerCreateOffersClose = () => ({ type: TRIGGER_CREATE_OFFERS_CLOSE });

export const triggerExportStepChange = (exportWizardStep) => ({
  type: TRIGGER_EXPORT_STEP_CHANGE,
  payload: { exportWizardStep },
});

export const triggerCalendarScrollIntoView = (calendarScrollIntoView) => ({
  type: TRIGGER_CALENDAR_SCROLL_INTO_VIEW,
  payload: { calendarScrollIntoView },
});

export const removeCalendarScrollIntoView = () => ({ type: REMOVE_CALENDAR_SCROLL_INTO_VIEW });
