import * as types from '../constants/ActionTypes';

const seasons = (state = [], action) => {
  switch (action.type) {
    case types.SEASON_GET_SUCCESS: {
      const newState = [...state];
      const season = action.payload;
      let presentSeason;
      newState.forEach((s, i) => {
        if (s.id === season.id) {
          presentSeason = i;
        }
      });
      if (presentSeason) {
        newState[presentSeason] = season;
      } else {
        newState.push(season);
      }
      return newState;
    }
    case types.SEASONS_POST_SUCCESS:
      return [...state, action.payload];
    case types.SEASONS_GET_SUCCESS:
      return state;
    case types.SEASON_DELETE_SUCCESS:
      return state;
    default:
      return state;
  }
};

export default seasons;
