import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import availabilitySchema from '../schema/Availability';

export const fetchAvailabilities = () => httpRequest({
  method: 'GET',
  path: '/availability',
  types: [
    'AVAILABILITIES_GET_REQUEST',
    'AVAILABILITIES_GET_SUCCESS',
    'AVAILABILITIES_GET_FAILURE',
  ],
  schema: new schema.Array(availabilitySchema),
});

export const createAvailability = (body) => httpRequest({
  method: 'POST',
  path: '/availability',
  body,
  types: [
    'AVAILABILITIES_POST_REQUEST',
    'AVAILABILITIES_POST_SUCCESS',
    'AVAILABILITIES_POST_FAILURE',
  ],
  schema: availabilitySchema,
});

export const deleteAvailability = (body) => httpRequest({
  method: 'POST',
  path: '/availability/delete',
  body,
  types: [
    'AVAILABILITY_DELETE_REQUEST',
    'AVAILABILITY_DELETE_SUCCESS',
    'AVAILABILITY_DELETE_FAILURE',
  ],
  schema: availabilitySchema,
});

export const editAvailability = (id, body) => httpRequest({
  method: 'PUT',
  path: `/availability/${id}`,
  body,
  types: [
    'AVAILABILITY_PUT_REQUEST',
    'AVAILABILITY_PUT_SUCCESS',
    'AVAILABILITY_PUT_FAILURE',
  ],
  schema: availabilitySchema,
});
