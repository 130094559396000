import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, Provider, ReactReduxContext } from 'react-redux';
import MaterialDrawer from 'material-ui/Drawer';
import { hideDrawer } from '../actions/drawerActions';
import '../styles/GroupView.scss';

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollDisable: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { userGuide } = this.props;
    const { userGuide: prevUserGuide } = prevProps;

    if (!prevUserGuide.active && userGuide.active) {
      this.setScrollDisableState({ overflowY: 'hidden' });
    }

    if (prevUserGuide.active && !userGuide.active) {
      this.setScrollDisableState({});
    }
  }

  setScrollDisableState = (scrollDisable) => this.setState({ scrollDisable });

  render() {
    const { drawer, hideDrawer: hideDrawerA } = this.props;
    const { scrollDisable } = this.state;

    return (
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <MaterialDrawer
            docked
            openSecondary
            open={false}
            width={370}
            containerStyle={{ boxShadow: '-6px 4px 2px 0 rgba(0,0,0,0.15)', ...scrollDisable }}
            {...drawer}
          >
            <Provider store={store}>
              <>
                <div onClick={hideDrawerA} style={{ cursor: 'pointer' }}>
                  <i className="material-icons backspace-icon" style={{ marginLeft: '22px', marginTop: '12px' }}>keyboard_backspace</i>
                </div>
                <div id="drawer">
                  {drawer.component && React.createElement(drawer.component, drawer.props)}
                </div>
              </>
            </Provider>
          </MaterialDrawer>
        )}
      </ReactReduxContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  drawer: state.drawer,
  userGuide: state.userGuide,
});

const mapDispatchToProps = (dispatch) => ({
  hideDrawer: bindActionCreators(hideDrawer, dispatch),
});

Drawer.propTypes = {
  userGuide: PropTypes.shape({
    active: PropTypes.bool,
  }),
  drawer: PropTypes.shape({
    component: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.node,
      PropTypes.object,
      PropTypes.array,
      PropTypes.func,
    ]),
    // eslint-disable-next-line react/forbid-prop-types
    props: PropTypes.object,
  }),
  hideDrawer: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
