import React from 'react';
import PropTypes from 'prop-types';
import '../styles/UserGuide.scss';

const GuideActivationPopup = ({ onSkipClick, onBeginClick }) => (
  <div className="guide-activation-modal">
    <div className="header">
      Welcome to SSMS - a Software dedicated to help you manage your Snowsport School bookings.
    </div>
    <div className="text">
      Follow this Guide to get to know how to use the Software and its Features.
    </div>
    <div className="buttons">
      <button
        className="buttons buttons__skip"
        onClick={() => onSkipClick()}
        style={{ textDecoration: 'underline' }}
        type="button"
      >
        Skip
      </button>
      <button className="buttons buttons__begin" onClick={() => onBeginClick()} type="button">
        Begin
      </button>
    </div>
  </div>
);

GuideActivationPopup.propTypes = {
  onSkipClick: PropTypes.func,
  onBeginClick: PropTypes.func,
};

export default React.memo(GuideActivationPopup);
