import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { TextField } from 'redux-form-material-ui';
import _ from 'lodash';
import { required } from '../../utils/validators';
import '../../styles/RegistrationWizard.scss';
import '../../styles/Register.scss';
import ResortSelect from '../../components/ResortSelect';
import RenderSelect from '../../components/RenderSelect';
import timezones from '../../timezones.json';
import currencies from '../../currencies.json';
import { mapCountriesToObject } from '../../CountriesNames';

class SecondPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timezoneOptions: _.map(timezones, (timezone) => ({ id: timezone, name: timezone })),
      currencyOptions: _.map(currencies, (c) => ({ id: c, name: c })),
    };
    this.resort = ['Resort'];
  }

  setSelectorValue = (fieldName) => (value) => {
    const { change } = this.props;
    change(fieldName, value.value);
  }

  addResorts = (value) => {
    const { change } = this.props;
    change('resort', value ? { value: value.id, label: value.name } : null);
  }

  render() {
    const { currencyOptions, timezoneOptions } = this.state;
    const {
      resort,
      currency,
      country,
      handleSubmit,
      previousPage,
      city,
      state,
    } = this.props;
    return (
      <div>
        <div className="step-info">
          <div>Step 2 - Fill in your school information</div>
          <div>Help us get to know your school better and set up correctly</div>
        </div>
        <form onSubmit={handleSubmit} className="register-form">
          <div className="register-form__input-wrapper">
            <label>School name:*</label>
            <Field
              name="name"
              validate={[required]}
              component={TextField}
              className="register-form__account-name"
              type="text"
              hintText="School name"
              underlineStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              underlineFocusStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              inputStyle={{
                color: '#253D63',
                fontSize: '16px',
                lineHeight: '24px',
              }}
            />
          </div>
          <div className="register-form__input-wrapper">
            <label>Representative:*</label>
            <Field
              name="representative"
              className="register-form__representative"
              component={TextField}
              validate={[required]}
              type="text"
              hintText="Representative name"
              underlineStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              underlineFocusStyle={{
                borderColor: 'rgba(32,51,90,0.4)',
              }}
              inputStyle={{
                color: '#253D63',
                fontSize: '16px',
                lineHeight: '24px',
              }}
            />
          </div>
          <div className="register-form__input-wrapper">
            <label>Resort:*</label>
            <Field
              name="resort"
              component={ResortSelect}
              resorts={resort}
              addResorts={this.addResorts}
              validate={[required]}
            />
          </div>
          <div className="register-form__input-wrapper">
            <label>Currency:*</label>
            <Field
              name="currency"
              component={RenderSelect}
              validate={[required]}
              className="currency"
              clearable={false}
              multi={false}
              options={currencyOptions}
              value={currency}
              onChange={this.setSelectorValue('currency')}
            />
          </div>
          <div className="register-form__input-wrapper">
            <label>Timezone:*</label>
            <Field
              simpleValue
              clearable={false}
              name="timezone"
              className="timezone"
              component={RenderSelect}
              options={timezoneOptions}
              onChange={this.setSelectorValue('timezone')}
              validate={[required]}
            />
          </div>
          <div className="timezone-text">
            Set up your working timezone. You will not be able to change the timezone later.
          </div>
          <div className="secondary-title">
            School Address
          </div>
          <div className="register-form__section-wrapper">
            <div className="register-form__input-wrapper">
              <label>Country:*</label>
              <Field
                name="country"
                component={RenderSelect}
                className="currency"
                clearable={false}
                multi={false}
                options={mapCountriesToObject()}
                value={country}
                validate={[required]}
                onChange={this.setSelectorValue('country')}
                id="countryId"
              />
            </div>
            <div className="register-form__input-wrapper">
              <label>State:</label>
              <Field
                name="state"
                component={TextField}
                className="register-form__account-name"
                value={state}
                onChange={this.setSelectorValue('state')}
                hintText="State"
                underlineStyle={{
                  borderColor: 'rgba(32,51,90,0.4)',
                }}
                underlineFocusStyle={{
                  borderColor: 'rgba(32,51,90,0.4)',
                }}
                inputStyle={{
                  color: '#253D63',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              />
            </div>
            <div className="register-form__input-wrapper">
              <label>City:*</label>
              <Field
                name="city"
                component={TextField}
                className="register-form__account-name"
                validate={[required]}
                value={city}
                onChange={this.setSelectorValue('city')}
                hintText="City"
                underlineStyle={{
                  borderColor: 'rgba(32,51,90,0.4)',
                }}
                underlineFocusStyle={{
                  borderColor: 'rgba(32,51,90,0.4)',
                }}
                inputStyle={{
                  color: '#253D63',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              />
            </div>
            <div className="register-form__input-wrapper">
              <label>Street:*</label>
              <Field
                name="street"
                component={TextField}
                className="register-form__account-name"
                validate={[required]}
                value={country}
                onChange={this.setSelectorValue('street')}
                hintText="Street"
                underlineStyle={{
                  borderColor: 'rgba(32,51,90,0.4)',
                }}
                underlineFocusStyle={{
                  borderColor: 'rgba(32,51,90,0.4)',
                }}
                inputStyle={{
                  color: '#253D63',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              />
            </div>
            <div className="register-form__input-wrapper">
              <label>Postal code:*</label>
              <Field
                name="postal"
                validate={[required]}
                component={TextField}
                className="register-form__account-name"
                type="text"
                hintText="Postal code"
                underlineStyle={{
                  borderColor: 'rgba(32,51,90,0.4)',
                }}
                underlineFocusStyle={{
                  borderColor: 'rgba(32,51,90,0.4)',
                }}
                inputStyle={{
                  color: '#253D63',
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              />
            </div>
          </div>
          <div className="navigation">
            <span onClick={previousPage}>Back</span>
            <button type="submit">Next</button>
          </div>
        </form>
      </div>
    );
  }
}

SecondPage.propTypes = {
  change: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  resort: PropTypes.object,
  currency: PropTypes.string,
  country: PropTypes.string,
  handleSubmit: PropTypes.func,
  previousPage: PropTypes.func,
  city: PropTypes.func,
  state: PropTypes.string,
};

const formConfig = {
  form: 'RegisterSecondPage',
  destroyOnUnmount: false,
};

const selector = formValueSelector('RegisterSecondPage');

const mapStateToProps = (state) => ({
  resort: selector(state, 'resort'),
});

export default connect(mapStateToProps)(reduxForm(formConfig)(SecondPage));
