import { schema } from 'normalizr';
// eslint-disable-next-line import/no-cycle
import bookingServiceSchema from './BookingService';

const serviceSchema = new schema.Entity('service', { idAttribute: 'id' });

serviceSchema.define({
  bookingService: new schema.Array(bookingServiceSchema),
});

export default serviceSchema;
