import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import instructorSalarySchema from '../schema/InstructorSalary';

export const fetchInstructorsSalary = (body) => httpRequest({
  method: 'GET',
  path: `/salary?timeFrom=${body.dateFrom}&timeTo=${body.dateTo}`,
  types: [
    'INSTRUCTORS_SALARY_GET_REQUEST',
    'INSTRUCTORS_SALARY_GET_SUCCESS',
    'INSTRUCTORS_SALARY_GET_FAILURE',
  ],
  schema: new schema.Array(instructorSalarySchema),
});

export const updateInstructorSalary = (body, id) => httpRequest({
  method: 'PUT',
  path: `/salary/${id}`,
  body,
  types: [
    'INSTRUCTORS_SALARY_PUT_REQUEST',
    'INSTRUCTORS_SALARY_PUT_SUCCESS',
    'INSTRUCTORS_SALARY_PUT_FAILURE',
  ],
  schema: instructorSalarySchema,
});
