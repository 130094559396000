import { schema } from 'normalizr';
import clientSchema from './Client';
import resortSchema from './Resort';
import hotelSchema from './Hotel';
import partnerManagerSchema from './PartnerManager';
import bookingServiceSchema from './BookingService';
// eslint-disable-next-line import/no-cycle
import lessonSchema from './Lesson';

const bookingSchema = new schema.Entity('booking', { idAttribute: 'id' });

bookingSchema.define({
  client: clientSchema,
  resort: resortSchema,
  hotel: hotelSchema,
  partnerManager: partnerManagerSchema,
  bookingService: new schema.Array(bookingServiceSchema),
  lessons: new schema.Array(lessonSchema),
});

export default bookingSchema;
