import React from 'react';
import PropTypes from 'prop-types';
import '../styles/GenderIcon.scss';

const GenderIcon = ({ gender }) => (
  <div className={`gender-icon  gender-icon--${gender}`} />
);

GenderIcon.propTypes = {
  gender: PropTypes.string,
};

export default React.memo(GenderIcon);
