import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormSyncErrors } from 'redux-form';
import FirstPage from './WizardFormFirstPage';
import SecondPage from './WizardFormSecondPage';
import ThirdPage from './WizardFormThirdPage';
import ForthPage from './WizardFormForthPage';
import '../../styles/RegistrationWizard.scss';

class WizardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      success: false,
    };
  }

  nextPage = () => {
    const { page } = this.state;
    this.setState({ page: page + 1 });
  }

  previousPage = () => {
    const { page } = this.state;
    this.setState({ page: page - 1 });
  }

  getStepClass = (prop) => {
    const { page } = this.state;

    if (page === prop) {
      return '--selected';
    } if (page > prop) {
      return '--completed';
    }
    return '';
  }

  getCurrentStep = () => {
    const { page } = this.state;
    switch (page) {
      case 1:
        return <FirstPage nextPage={this.nextPage} />;
      case 2:
        return (
          <SecondPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        );
      case 3:
        return (
          <ThirdPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        );
      case 4:
        return (
          <ForthPage
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            success={this.setSuccess}
          />
        );
      default:
        return null;
    }
  }

  setSuccess = () => {
    this.setState({
      success: true,
    });
  }

  setPage = (page) => () => {
    const {
      syncErrorsFirstPage,
      syncErrorsSecondPage,
      syncErrorsThirdPage,
      syncErrorsForthPage,
    } = this.props;
    const { page: statePage } = this.state;
    let selected;
    switch (statePage) {
      case 1:
        selected = syncErrorsFirstPage;
        break;
      case 2:
        selected = syncErrorsSecondPage;
        break;
      case 3:
        selected = syncErrorsThirdPage;
        break;
      case 4:
        selected = syncErrorsForthPage;
        break;
      default:
        break;
    }
    if (page < statePage || !selected) {
      this.setState({
        page,
      });
    }
  }

  render() {
    const { page, success } = this.state;
    return (
      <div className="register-form-page__wrapper">
        <div className="register-form-page__form-wrapper">
          {!success
            && (
            <div>
              <div className="wizard-header">
                <span
                  className={`wizard-header${page === 1 ? '--selected' : '--completed'}`}
                  onClick={this.setPage(1)}
                >
                  {page === 1 ? 1 : ''}
                </span>
                <label onClick={this.setPage(1)}>Plan</label>
                <span
                  className={`wizard-header${this.getStepClass(2)}`}
                  onClick={this.setPage(2)}
                >
                  {page <= 2 && 2}
                </span>
                <label onClick={this.setPage(2)}>School information</label>
                <span
                  className={`wizard-header${this.getStepClass(3)}`}
                  onClick={this.setPage(3)}
                >
                  {page <= 3 && 3}
                </span>
                <label onClick={this.setPage(3)}>Account details</label>
                <span
                  className={`wizard-header${this.getStepClass(4)}`}
                  onClick={this.setPage(4)}
                >
                  {page <= 4 && 4}
                </span>
                <label onClick={this.setPage(4)}>Review</label>
              </div>
              <div className="wizard-header--title">REGISTER</div>
            </div>
            )}
          <div>
            {this.getCurrentStep()}
          </div>
        </div>
      </div>
    );
  }
}

WizardForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  syncErrorsFirstPage: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  syncErrorsSecondPage: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  syncErrorsThirdPage: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  syncErrorsForthPage: PropTypes.object,
};

const mapStateToProps = (state) => ({
  syncErrorsFirstPage: getFormSyncErrors('RegisterFirstPage')(state),
  syncErrorsSecondPage: getFormSyncErrors('RegisterSecondPage')(state),
  syncErrorsThirdPage: getFormSyncErrors('RegisterThirdPage')(state),
  syncErrorsForthPage: getFormSyncErrors('RegisterForthPage')(state),
});

export default connect(mapStateToProps)(WizardForm);
