// eslint-disable-next-line import/prefer-default-export
export const bookingStyleObject = {
  togglestyle: {
    display: 'inline-block',
    width: 'auto',
    labelStyle: {
      width: 'auto',
      fontFamily: 'Ubuntu',
      color: 'white',
      fontWeight: '500',
      fontSize: '12px',
    },
  },
  bookButton: {
    width: '149px',
    label: {
      color: 'white',
      fontSize: '18px',
    },
  },
  cancelButton: {
    marginTop: '10px',
    label: {
      color: 'white',
      fontSize: '13px',
    },
  },
  bookandcreateButton: {
    marginLeft: '15px',
    width: '170px',
    label: {
      paddingLeft: '0px',
      paddingRight: '0px',
      color: 'white',
      fontSize: '13px',
    },
  },
  specialNeedsTextArea: {
    width: '270px',
    minHeight: '100px',
    border: '2px solid #00559D',
    marginTop: '13px',
  },
  additionalServicesDiscountField: {
    width: '37px',
    backgroundColor: 'green',
  },
  popoverStyles: {
    boxShadow: 'none',
    backgroundColor: 'none',
    top: '0',
  },
  anchorStyleClient: {
    position: 'absolute',
    top: '-55px',
    right: '40px',
  },
  anchorStylePartner: {
    position: 'absolute',
    top: '450px',
    left: '105px',
  },
  parentStyle: {
    position: 'relative',
  },
  createNewClientButton: {
    marginTop: '10px',
    marginLeft: '77px',
    height: '27px',
    width: '155px',
    label: {
      position: 'relative',
      top: '3px',
      color: 'white',
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Ubuntu',
    },
  },
  createNewPartnerButton: {
    position: 'relative',
    bottom: '4px',
    marginLeft: '77px',
    height: '27px',
    width: '155px',
    label: {
      position: 'relative',
      top: '3px',
      color: 'white',
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Ubuntu',
    },
  },
  commission: {
    width: '80px',
    display: 'inline-block',
    color: '#455D8D',
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    fontSize: '13px',
    maxWidth: '80px',
    textAlign: 'right',
    marginRight: '7px',
    marginLeft: '13px',
    com: {
      marginTop: '6px',
    },
  },
  testStyle: {
    width: '210px',
    height: '36px',
  },
};
