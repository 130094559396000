import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { DEFAULT_PAGE_SIZE } from '../../common/constants';

const DataTable = (props) => {
  const { className } = props;
  return (
    <ReactTable
      {...props}
      defaultPageSize={DEFAULT_PAGE_SIZE}
      className={`-striped -highlight${className ? ` ${className}` : ''}`}
    />
  );
};

DataTable.propTypes = {
  pageSize: PropTypes.number,
  className: PropTypes.string,
};

export default DataTable;
