import React from 'react';
import PropTypes from 'prop-types';

const Group = ({ event, onGroupOpen }) => (
  <div className={`event event--${event.level}`}>
    <div className="event-row--group">
      <div className="event-group-column">
        <div>
          {event && event.ageRestrictions}
        </div>
        <div>
          {event && event.clientAmount}
        </div>
      </div>
      <div>
        <div className="event-group-column">
          <div>
            {event && event.name}
          </div>
        </div>
      </div>
      <div className="event-group-column">
        <div className="split-column">
          {event && event.speciality}
        </div>
        <div className="split-column split-column--circle" onClick={() => onGroupOpen(event)}>
          <div>
            {event && event.groupSize}
          </div>
          <div className="size-icon" />
        </div>
      </div>
    </div>
  </div>
);

Group.propTypes = {
  event: PropTypes.shape({
    level: PropTypes.string,
    ageRestrictions: PropTypes.number,
    clientAmount: PropTypes.number,
    name: PropTypes.string,
    speciality: PropTypes.string,
    groupSize: PropTypes.number,
  }),
  onGroupOpen: PropTypes.func,
};

export default React.memo(Group);
