import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import DualListBox from 'react-dual-listbox';
import { forEach, includes, get } from 'lodash';
import { switchFilteredProductTimes } from '../actions/productsActions';
import { getEshopToken } from '../actions/eshopActions';
import {
  formatGeneratedTimesOptions,
  formatFilterPayload,
  formatSelectedTimesPayload,
} from '../utils/format';
import 'font-awesome/css/font-awesome.min.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const ExportGetLinkForm = ({
  options,
  selected,
  getEshopTokenA,
  values,
  account,
  switchFilteredProductTimesA,
  product,
}) => {
  const linkInput = useRef();

  const getLink = () => {
    const filter = formatFilterPayload(values);
    const lessons = formatSelectedTimesPayload(options, selected);
    const name = get(Object.values(account), '[0].domain', '');

    getEshopTokenA({
      filter,
      lessons,
    }).then((res) => {
      const input = document.querySelector('.get-link-input');
      const token = res.payload.result;

      const host = window.location.host.split('.')[1];
      const eshopHost = host === 'adapt-skicms-test'
        ? '.skicms-eshop-test.com'
        : '.skischoollesson.com';

      const domain = `https://${name}${eshopHost}`;
      const params = '/?filter=';
      linkInput.current.value = [domain, params, token].join('');
      input.select();
      document.execCommand('copy');
    });
  };

  const handleSelection = (selectedItems) => {
    const selectedOptions = [];
    forEach(selectedItems, (s) => {
      selectedOptions.push(s);
    });
    switchFilteredProductTimesA(selectedOptions);
  };

  const handleFilter = (option, filterInput) => {
    let productNames = {};
    forEach(product, (p) => {
      productNames = {
        ...productNames,
        [p.id]: p.name.toLowerCase(),
      };
    });
    const key = option.value.split('[')[0];
    const labelName = productNames[key];
    if (filterInput === '') {
      return true;
    }
    return includes(labelName, filterInput.toLowerCase())
      || includes(option.label.toLowerCase(), filterInput.toLowerCase());
  };

  return (
    <div>
      <div style={{ display: 'flex', 'justify-content': 'space-around' }}>
        <h4 style={{ float: 'left' }}>Available lessons</h4>
        <h4 style={{ float: 'right' }}>Selected lessons</h4>
      </div>
      <DualListBox
        options={formatGeneratedTimesOptions(options, product)}
        selected={selected}
        canFilter
        onChange={handleSelection}
        filterCallback={handleFilter}
      />
      <div className="get-link-wrapper">
        <input
          ref={linkInput}
          type="text"
          disabled
          value=""
          className="get-link-input"
        />
        <button
          type="submit"
          className="get-link-button"
          onClick={getLink}
        >
          GET LINK
        </button>
      </div>
    </div>
  );
};

ExportGetLinkForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.array,
  getEshopTokenA: PropTypes.func,
  values: PropTypes.shape({
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.array,
  switchFilteredProductTimesA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
};

const mapStateToProps = (state) => ({
  options: state.productTimes.options,
  selected: state.productTimes.selected,
  product: state.entities.product,
  user: state.user,
  account: state.entities.account,
  values: getFormValues('ExportFilterWizard')(state),
});

const mapDispatchToProps = (dispatch) => ({
  switchFilteredProductTimesA: bindActionCreators(switchFilteredProductTimes, dispatch),
  getEshopTokenA: bindActionCreators(getEshopToken, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ExportGetLinkForm));
