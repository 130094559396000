import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactCalendarTimeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from 'react-calendar-timeline/lib';
import moment from 'moment';
import {
  map,
  get,
  pick,
  find,
  filter,
  minBy,
  isEqual,
} from 'lodash';
import GroupRenderer from '../components/GroupRenderer';
import EventRenderer from '../components/EventRenderer';
import InfoLabel from '../components/InfoLabel';
import SecondaryDateHeaderCell from '../components/SecondaryDateHeaderCell';

import { setTimelineAnchor } from '../actions/userGuide';
import { convertForCalendar, convertToUTC } from '../utils/dateTime';
import { notifyError } from '../actions/notificationActions';
import { fetchBooking } from '../actions/bookingActions';
import { resolveEntity } from '../utils/formHelpers';

import 'react-calendar-timeline/lib/Timeline.css';

const Stickyfill = require('stickyfilljs');

const defaultSettings = {
  sidebarWidth: 180,
  lineHeight: 54,
  headerLabelGroupHeight: 32,
  headerLabelHeight: 28,
  itemHeightRatio: 0.75,
  timeSteps: {
    hour: 1,
    day: 1,
    month: 1,
    year: 1,
  },
  keys: {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    itemIdKey: 'id',
    itemGroupKey: 'instructor',
    itemTimeStartKey: 'timeFrom',
    itemTimeEndKey: 'timeTo',
  },
  defaultHeaderLabelFormats: {
    hourLong: 'dddd, HH:00',
    dayLong: 'dddd, DD MMM',
    monthLong: 'MMMM YYYY',
    yearLong: 'YYYY',
  },
};

const behaviourSettings = {
  canMove: ['individual', 'group'],
  canChangeGroup: ['individual', 'group'],
};

const createGroups = (groupProps, reservedBookingsCt) => {
  const { onInstructorClick, role, instructors } = groupProps;

  const groups = map(instructors, (instructor) => ({
    ...pick(instructor, ['id', 'name', 'surname']),
    onClick: onInstructorClick,
    role,
    title: `${instructor.name} ${instructor.surname}`,
  }));

  if (role === 'manager') {
    groups.push({
      role,
      id: 0,
      title: (
        <div>
          Reserved bookings
          {reservedBookingsCt ? <span className="reserved-bookings-ct">{reservedBookingsCt}</span> : ''}
        </div>
      ),
    });
  } else {
    groups.push({
      id: '',
      role,
    });
  }

  return groups;
};

const itemClassName = (item, role) => {
  if (item.eventType === 'availability') {
    return `${item.eventType}__type--${item.type}`;
  }

  if (item.eventType === 'individual') {
    const paid = role === 'manager' ? item.paid : 'false';
    return `${item.eventType}__paid--${paid}`;
  }

  if (item.eventType === 'group') {
    return `${item.eventType}__level--${item.level}`;
  }
  return undefined;
};

const createItems = (itemProps) => {
  const { role, onGroupOpen, events } = itemProps;

  return map(events, (item) => ({
    ...pick(item, [
      'id',
      'eventType',
      'language',
      'bid',
      'clientFullName',
      'minAge',
      'resortName',
      'maxAge',
      'clientAmount',
      'activity',
      'name',
      'specialityName',
      'booking',
      'guid',
      'size',
      'level',
    ]),
    timeFrom: moment(convertForCalendar(item.timeFrom)),
    timeTo: moment(convertForCalendar(item.timeTo)),
    instructor: item.instructor || 0,
    canMove: behaviourSettings.canMove.indexOf(item.eventType) !== -1,
    canResize: false,
    canChangeGroup: behaviourSettings.canChangeGroup.indexOf(item.eventType) !== -1,
    className: itemClassName(item, role),
    role,
    onOpen: onGroupOpen,
    anchorIndicator: item.timeFrom,
  }));
};

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      groups: [],
      draggedItem: undefined,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      role,
      events,
      instructors,
      onInstructorClick,
      onGroupOpen,
      setTimelineAnchorA,
      userGuide,
    } = nextProps;
    const {
      instructors: currentInstructors,
      events: currentEvents,
    } = this.props;
    if (!isEqual(instructors, currentInstructors) || !isEqual(events, currentEvents)) {
      const groupProps = {
        role,
        instructors,
        onInstructorClick,
      };
      const itemProps = {
        role,
        events,
        onGroupOpen,
      };
      const items = createItems(itemProps);
      const timelineAnchor = minBy(filter(items, { eventType: 'group' }), 'anchorIndicator');
      const reservedBookingsCt = filter(items, (i) => i.instructor === 0).length;

      this.setState({
        items,
        groups: createGroups(groupProps, reservedBookingsCt),
      });

      if (timelineAnchor && (userGuide && !userGuide.timelineAnchor)) {
        setTimelineAnchorA(timelineAnchor);
      }
    }
  }

  componentWillUnmount() {
    Stickyfill.removeAll();
    moment.tz.setDefault();
  }

  handleHeaderRef = (el) => {
    if (el) {
      Stickyfill.addOne(el);
    }
  };

  onItemDoubleClick = (itemId) => {
    const { items } = this.state;
    const { onItemDoubleClick } = this.props;
    const item = items.find((i) => i.id === itemId);
    if (!item) {
      return;
    }
    onItemDoubleClick(item);
  };

  onItemMove = (itemId, dragTime, newGroupOrder) => {
    const { items, groups } = this.state;
    const { onItemUpdate } = this.props;

    const item = items.find((i) => i.id === itemId);

    const instructor = groups.find((i, key) => key === newGroupOrder);

    const updatedItem = {
      ...item,
      instructor: instructor.id,
      timeFrom: moment(convertToUTC(dragTime)),
      timeTo: moment(convertToUTC(dragTime + (item.timeTo - item.timeFrom))),
    };

    onItemUpdate(item, updatedItem);
  };

  checkActivitySpeciality = (activityName, specialityName, newInstructorId) => {
    const { instructors } = this.props;
    const instructor = find(instructors, (i) => i.id === newInstructorId);
    if (instructor) {
      const { activities: instructorActivities } = instructor;
      return !!instructorActivities[activityName]
        && !!instructorActivities[activityName][specialityName];
    }
    return true;
  };

  getInstructorAvailability = (item, items, dragTime, instructorId) => {
    const { instructors } = this.props;
    const instructor = find(instructors, (i) => i.id === instructorId);
    if (instructor) {
      const timeFrom = dragTime;
      const timeTo = (moment(item.timeTo).valueOf() - moment(item.timeFrom).valueOf()) + dragTime;

      const availabilities = filter(items, (i) => (i.eventType === 'availability')
        && (new Date(i.timeFrom) <= timeFrom && new Date(i.timeTo) >= timeTo));

      return find(availabilities, (a) => a.instructor === instructorId);
    }
    return true;
  };

  checkResort = (item, items, dragTime, newInstructorId) => {
    const { instructors } = this.props;
    const originalInstructor = find(instructors, (i) => i.id === item.instructor);
    const newInstructor = find(instructors, (i) => i.id === newInstructorId);
    if (originalInstructor && newInstructor) {
      const { id } = originalInstructor;

      const oldInstructorAvailability = this.getInstructorAvailability(
        item,
        items,
        item.timeFrom,
        id,
      );
      const newInstructorAvailability = this.getInstructorAvailability(
        item,
        items,
        dragTime,
        newInstructorId,
      );

      return oldInstructorAvailability.resortName === newInstructorAvailability.resortName;
    }
    return true;
  };

  handleDialogChecks = (itemId, dragTime, newGroupOrder, item, activityName, specialityName) => {
    const { items, groups } = this.state;
    const {
      instructors,
      notifyErrorA,
      openDialog,
    } = this.props;
    const changedInstructor = groups[newGroupOrder];
    const changedInstructorName = get(changedInstructor, 'name', 'Reserved bookings');
    const changedInstructorSurname = get(changedInstructor, 'surname', '');

    if (!this.checkActivitySpeciality(activityName, specialityName, changedInstructor.id)) {
      notifyErrorA({}, `Instructor ${changedInstructorName} ${changedInstructorSurname} cannot teach ${activityName}/${specialityName}`);
    } else if (!this.getInstructorAvailability(item, items, dragTime, changedInstructor.id)) {
      notifyErrorA({}, 'There is no availability or the availability doesn\'t match with the time');
    } else if (!this.checkResort(item, items, dragTime, changedInstructor.id)) {
      notifyErrorA({}, 'Resorts don\'t match');
    } else {
      const itemName = item.eventType === 'individual' ? `B-${item.bid}` : `group ${item.name}`;
      const originalInstructor = find(instructors, (i) => i.id === item.instructor);
      const originalInstructorName = get(originalInstructor, 'name', 'Reserved bookings');
      const originalInstructorSurname = get(originalInstructor, 'surname', '');

      const calendarItem = {
        original: {
          name: itemName,
          instructor: originalInstructorName.concat(' ', originalInstructorSurname),
          timeFrom: moment(convertToUTC(item.timeFrom)).format('DD/MMM/YYYY HH:mm'),
          timeTo: moment(convertToUTC(item.timeTo)).format('DD/MMM/YYYY HH:mm'),
        },
        changed: {
          name: itemName,
          instructor: changedInstructorName.concat(' ', changedInstructorSurname),
          timeFrom: moment(dragTime).format('DD/MMM/YYYY HH:mm'),
          timeTo: moment(dragTime + (item.timeTo - item.timeFrom)).format('DD/MMM/YYYY HH:mm'),
        },
      };
      openDialog(() => this.onItemMove(itemId, dragTime, newGroupOrder), null, calendarItem);
    }
  };

  openDialogOnMove = (itemId, dragTime, newGroupOrder) => {
    const { items } = this.state;
    const {
      role,
      activities,
      fetchBookingA,
    } = this.props;

    if (role === 'instructor' || role === 'headCoach') {
      return;
    }

    this.setState({ draggedItem: undefined });

    const item = find(items, { id: itemId });
    if (item.eventType === 'individual') {
      resolveEntity(this.props, 'booking', item.booking, fetchBookingA, 'bid').then((booking) => {
        let lesson;
        const { lessonBlocks } = booking;
        for (let i = 0; i < lessonBlocks.length; i += 1) {
          lesson = find(lessonBlocks[i].lessons, (l) => l.id === Number(item.id.split('-')[1]));
          if (lesson) {
            break;
          }
        }
        let activityName;
        let specialityName;
        if (lesson) {
          activityName = lesson.activity.name;
          specialityName = lesson.speciality.name;
        } else {
          const itemActivity = find(activities, (a) => a.id === item.activity);
          activityName = itemActivity.name;
          specialityName = item.specialityName;
        }
        this.handleDialogChecks(
          itemId,
          dragTime,
          newGroupOrder,
          item,
          activityName,
          specialityName,
        );
      });
    } else {
      const { activity, specialityName } = item;
      const itemActivity = find(activities, (a) => a.id === activity);
      const { name: activityName } = itemActivity;
      this.handleDialogChecks(itemId, dragTime, newGroupOrder, item, activityName, specialityName);
    }
  };

  handleItemDrag = ({
    itemId,
    time,
    newGroupOrder,
  }) => {
    const { draggedItem, items, groups } = this.state;
    let item = draggedItem ? draggedItem.item : undefined;
    if (!item) {
      item = items.find((i) => i.id === itemId);
    }
    this.setState({
      draggedItem: { item, group: groups[newGroupOrder], time },
    });
  };

  render() {
    const { items, groups, draggedItem } = this.state;
    const { handleTimelineRef } = this.props;

    return (
      <>
        <ReactCalendarTimeline
          ref={handleTimelineRef}
          headerRef={this.handleHeaderRef}
          {...defaultSettings}
          {...this.props}
          defaultTimeStart={moment().add(-12, 'hour')}
          defaultTimeEnd={moment().add(12, 'hour')}
          items={items}
          groups={groups}
          groupRenderer={GroupRenderer}
          itemRenderer={EventRenderer}
          itemTouchSendsClick={false}
          stickyHeader
          onItemMove={this.openDialogOnMove}
          onItemClick={this.onItemDoubleClick}
          onItemDrag={this.handleItemDrag}
          canMove
        >
          <TimelineHeaders>
            <SidebarHeader />
            <DateHeader unit="primaryHeader" />
            <DateHeader
              className="rct-secondary-header"
              intervalRenderer={SecondaryDateHeaderCell}
            />
          </TimelineHeaders>
          <TimelineMarkers>
            <TodayMarker />
          </TimelineMarkers>
        </ReactCalendarTimeline>
        {draggedItem && (
          <InfoLabel
            group={draggedItem.group}
            time={draggedItem.time}
          />
        )}
      </>
    );
  }
}

Timeline.propTypes = {
  role: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  instructors: PropTypes.array,
  onInstructorClick: PropTypes.func,
  onGroupOpen: PropTypes.func,
  setTimelineAnchorA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  userGuide: PropTypes.object,
  onItemDoubleClick: PropTypes.func,
  onItemUpdate: PropTypes.func,
  notifyErrorA: PropTypes.func,
  activities: PropTypes.shape({
    id: PropTypes.number,
  }),
  openDialog: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  handleTimelineRef: PropTypes.any,
  fetchBookingA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  setTimelineAnchorA: bindActionCreators(setTimelineAnchor, dispatch),
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  fetchBookingA: bindActionCreators(fetchBooking, dispatch),
});

const mapStateToProps = (state) => ({
  userGuide: state.userGuide,
  activities: state.entities.activity,
  booking: state.entities.booking,
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
