import { success, error, warning } from 'react-notification-system-redux';

export const notify = (type, options, title, message = '') => {
  const notificationOptions = {
    title,
    message,
    autoDismiss: 5,
    position: 'tc',
    ...options,
  };
  if (type === 'success') {
    return success(notificationOptions);
  } if (type === 'error') {
    return error(notificationOptions);
  } if (type === 'warning') {
    return warning(notificationOptions);
  }
  return warning('Something went wrong... Try again later.');
};

export const notifySuccess = (options, title, message) => notify('success', options, title, message);

export const notifyError = (options, title, message) => notify('error', options, title, message);

export const notifyWarning = (options, title, message) => notify('warning', options, title, message);
