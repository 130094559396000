import React from 'react';
import PropTypes from 'prop-types';
import IndividualEvent from './IndividualEvent';
import GroupEvent from './GroupEvent';
import AvailabilityEvent from './AvailabilityEvent';

const EventRenderer = ({
  item,
  getItemProps,
  itemContext,
}) => {
  const className = `${getItemProps(item.itemProps).className}${itemContext.selected ? ' selected' : ''}`;
  return (
    <div
      {...getItemProps(item.itemProps)}
      className={className}
    >
      <div className="rct-item-content">
        <div className={`event__type-${item.eventType}`} id={item.id}>
          {item.eventType === 'individual'
          && (
            <IndividualEvent
              event={item}
            />
          )}
          {item.eventType === 'group'
          && (
            <GroupEvent
              onOpen={item.onOpen}
              event={item}
            />
          )}
          {item.eventType === 'availability'
          && (
            <AvailabilityEvent
              event={item}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EventRenderer.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    eventType: PropTypes.string,
    onOpen: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    itemProps: PropTypes.object,
  }),
  getItemProps: PropTypes.func,
  itemContext: PropTypes.shape({
    selected: PropTypes.bool.isRequired,
  }),
};

export default EventRenderer;
