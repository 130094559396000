/* eslint-disable */
import React from 'react';
import { includes } from 'lodash';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Checkbox from 'material-ui/Checkbox';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import skiThemeLessonForm from '../../styles/theme/skiThemeLessonForm';
import EditEntityButton from '../../components/EditEntityButton';
import DeleteEntityButton from '../../components/DeleteEntityButton';

const additionalServicesListColumns = (
  selectedAll,
  openDialog,
  selected,
  currency,
  handleEditButton,
  handleDelete,
  handleCheck,
) => [
  {
    Header: (
      <div>
        <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
          <Checkbox
            ref="multiCheck"
            onCheck={() => handleCheck('*')}
            checked={selectedAll}
          />
        </MuiThemeProvider>
      </div>
    ),
    accessor: 'id',
    Cell: (props) => (
      <div>
        <Checkbox
          onCheck={() => handleCheck(props.value)}
          checked={includes(selected, props.value)}
        />
      </div>
    ),
    sortable: false,
  },
  {
    Header: 'Additional service',
    accessor: 'name',
    Cell: (props) => (
      props.value
        ? (
          <span
            onClick={() => handleEditButton(props.original.id)}
            className="name"
          >
            {props.value}
          </span>
        )
        : <span> - </span>
    ),
  },
  {
    Header: 'Price',
    accessor: 'price',
    Cell: (props) => (
      <div>
        <span>{props.value ? props.value + currency : '-'}</span>
      </div>
    ),
  },
  {
    Cell: ({ original }) => (
      <div>
        <span
          onClick={() => handleEditButton(original.id)}
        >
          <EditEntityButton />
        </span>
        <span>
          <DeleteEntityButton
            id={original.id}
            handleDelete={() => openDialog(() => handleDelete(original.id))}
          />
        </span>
      </div>
    ),
  },
];

export default additionalServicesListColumns;
/* eslint-enable */
