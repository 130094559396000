import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import buyerSchema from '../schema/Buyer';

export const fetchBuyersByLesson = (id) => httpRequest({
  method: 'GET',
  path: `/lesson/${id}/buyer`,
  types: [
    'LESSON_GET_BUYERS_REQUEST',
    'LESSON_GET_BUYERS_SUCCESS',
    'LESSON_GET_BUYERS_FAILURE',
  ],
  schema: new schema.Array(buyerSchema),
});

export const fetchBuyersByGroupGuid = (groupId) => httpRequest({
  method: 'GET',
  path: `/group/${groupId}/participants`,
  types: [
    'LESSON_GET_BUYERS_BY_ID_REQUEST',
    'LESSON_GET_BUYERS_BY_ID_SUCCESS',
    'LESSON_GET_BUYERS_BY_ID_FAILURE',
  ],
  schema: new schema.Array(buyerSchema),
});

export const moveBuyer = (id, groupId) => httpRequest({
  method: 'POST',
  path: `/buyer/${id}/move/${groupId}`,
  body: {},
  types: [
    'MOVE_BUYER_POST_REQUEST',
    'MOVE_BUYER_POST_SUCCESS',
    'MOVE_BUYER_POST_FAILURE',
  ],
  schema: buyerSchema,
});
