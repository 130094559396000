import { httpRequest } from '../middleware/http';
import lessonSchema from '../schema/Lesson';
import groupSchema from '../schema/Group';

export const fetchEvents = (body) => httpRequest({
  method: 'POST',
  path: '/event',
  body,
  types: [
    'EVENTS_GET_REQUEST',
    'EVENTS_GET_SUCCESS',
    'EVENTS_GET_FAILURE',
  ],
  schema: null,
});

export const fetchEventsByFilter = () => {
  const filter = localStorage.getItem('filter') || '{}';

  return httpRequest({
    method: 'POST',
    path: '/event',
    body: JSON.parse(filter),
    types: [
      'EVENTS_GET_REQUEST',
      'EVENTS_GET_SUCCESS',
      'EVENTS_GET_FAILURE',
    ],
    schema: null,
  });
};

export const removeEvent = (id) => (dispatch) => {
  dispatch({
    id,
    type: 'EVENT_REMOVE',
  });
};

export const patchEvent = (id, item) => (dispatch) => {
  dispatch({
    id,
    item,
    type: 'EVENT_PATCH',
  });
};

export const clear = () => (dispatch) => {
  dispatch({
    type: 'EVENTS_CLEAR',
  });
};

const resolveUnderlyingObjectSchema = (eventType) => {
  switch (eventType) {
    case 'individual':
      return lessonSchema;
    case 'group':
      return groupSchema;
    default:
      throw new Error('Invalid eventType');
  }
};

const resolveUnderlyingObjectName = (eventType) => {
  switch (eventType) {
    case 'individual':
      return 'lesson';
    case 'group':
      return 'group';
    default:
      throw new Error('Invalid eventType');
  }
};

const updateUnderlyingObject = (id, body, objectName, objectSchema) => httpRequest({
  method: 'PUT',
  path: `/${objectName}/${id}`,
  body,
  types: [
    'EVENT_PUT_REQUEST',
    'EVENT_PUT_SUCCESS',
    'EVENT_PUT_FAILURE',
  ],
  schema: objectSchema,
});

export const updateEvent = (id, body) => {
  const tempBody = body;
  const objectName = resolveUnderlyingObjectName(tempBody.eventType);
  const objectSchema = resolveUnderlyingObjectSchema(tempBody.eventType);

  delete tempBody.eventType;

  return updateUnderlyingObject(id, tempBody, objectName, objectSchema);
};
