import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
  getFormSyncWarnings,
  getFormValues,
} from 'redux-form';
import moment from 'moment';
import { get, pick, concat } from 'lodash';
import { TextField } from 'redux-form-material-ui';

import StripeWarningDisplay from '../components/StripeWarningDisplay';
import Dialog from '../components/dialog/Dialog';
import { required, phone, password } from '../utils/validators';
import { getUrlProperty } from '../utils/helpers';
import EntityForm from '../components/EntityForm';
import RenderPhoneInput from '../components/RenderPhoneInput';
import RenderSelectedPlan from '../components/RenderSelectedPlan';
import PlanPaymentRedirection from '../components/PlanPaymentRedirection';
import { notifySuccess, notifyWarning } from '../actions/notificationActions';
import { goToStep } from '../actions/userGuide';
import { fetchPlans } from '../actions/subscriptionPlanActions';
import {
  fetchAccounts,
  fetchMyAccount,
  updateAccount,
  updateMyAccount,
} from '../actions/accountActions';

const styles = {
  underlineStyle: {
    borderColor: 'rgba(32,51,90,0.4)',
  },
  underlineFocusStyle: {
    borderColor: 'rgba(32,51,90,0.4)',
  },
  inputStyle: {
    color: '#253D63',
    fontSize: '16px',
    lineHeight: '24px',
  },
};

const anchorStyle = {
  position: 'relative',
  right: '25%',
  height: '1px',
  width: '1px',
  bottom: '20px',
};

class AccountSettings extends Component {
  constructor(props) {
    super(props);

    let fields = [
      'name',
      'email',
      'phone',
      'passwordOld',
      'passwordNew',
      'passwordRepeat',
      'planPaymentType',
    ];

    if (props.user.role === 'admin') {
      fields = concat(fields, [
        'sixPaymentAccountId',
        'sixPaymentAccountPass',
        'sixPaymentGatewayAccountId',
        'sixPaymentTerminalId',
        'plan',
      ]);
    }

    this.state = {
      fieldsNames: fields,
      dialogOpen: false,
      pendingPlan: undefined,
    };
  }

  componentDidMount() {
    const { userGuide, actions, match: { params } } = this.props;
    const { general, currentGuide, step } = userGuide;
    if (general && currentGuide === 'accountInformation' && step === 0) {
      setTimeout(() => actions.goToStep(1), 250);
    }
    const query = getUrlProperty('query');
    const paymentQuery = get(query, 'payment');

    if (paymentQuery === 'success') {
      actions.notifySuccess({}, 'Payment has been approved, it might take several minutes to confirm the payment');
    }

    actions.fetchPlans();
    const id = get(params, 'id');
    if (id) {
      return actions.fetchAccounts(id);
    }
    return actions.fetchMyAccount();
  }

  setSelectorValue = (fieldName, value) => {
    const { change } = this.props;
    change(fieldName, value);
  }

  onSubmit = (account) => {
    const { actions, match: { params }, planPaymentType } = this.props;
    const { fieldsNames } = this.state;
    const tempAccount = { ...account };

    tempAccount.planPaymentType = planPaymentType.id || planPaymentType;
    tempAccount.invoiceDueDateLength = parseInt(tempAccount.invoiceDueDateLength, 10);
    tempAccount.plan = tempAccount.plan.id || tempAccount.plan;
    const data = pick(tempAccount, fieldsNames);
    const emailMessage = this.checkForChangedEmail(data.email);

    const id = get(params, 'id');
    const updateStrategy = id ? actions.updateAccount(id, data) : actions.updateMyAccount(data);

    return updateStrategy.then(() => {
      actions.notifySuccess({}, 'Account updated successfully');
      if (emailMessage) {
        actions.notifyWarning({}, emailMessage);
      }
    }).catch((err) => {
      this.handleError(err);
    });
  }

  handleError = (err) => {
    let property = get(err, 'data.constraintViolations[0].property', '');
    const message = get(err, 'data.constraintViolations[0].message', '');
    property = property === 'domain' ? 'name' : property;

    if (property) {
      throw new SubmissionError({ [property]: message });
    }
  };

  checkForChangedEmail = (em) => {
    const { initialValues: { email }, user: { role } } = this.props;
    if (em === email) {
      return '';
    }
    return role === 'admin'
      ? `User new email address will be changed after He verifies it by clicking the link we sent to ${em}`
      : `Your new email address will be changed after You verify it by clicking the link we sent to ${em}`;
  };

  handleDialog = (pendingPlan) => {
    const { dialogOpen } = this.state;
    this.setState({
      dialogOpen: !dialogOpen,
      pendingPlan,
    });
  }

  handleInstantPlanChange = (paymentType, plan) => {
    const { formValues, actions } = this.props;
    const { fieldNames } = this.state;
    const predefinedPaymentType = get(formValues, 'planPaymentType');
    const predefinedPlan = get(formValues, 'plan');
    const planId = plan ? get(plan, 'id', plan) : undefined;
    const predefinedPlanId = predefinedPlan ? get(predefinedPlan, 'id', predefinedPlan) : undefined;

    if ((paymentType === predefinedPaymentType) && (planId === predefinedPlanId)) {
      return;
    }

    if (paymentType !== predefinedPaymentType) {
      const payload = {
        ...pick(formValues, fieldNames),
        planPaymentType: paymentType,
      };
      actions.updateMyAccount(payload).then(() => this.handleDialog(planId));
      return;
    }

    this.handleDialog(planId);
  }

  render() {
    const {
      planPaymentType,
      handleSubmit,
      plan,
      invalid,
      submitting,
      warns,
      user,
      planPricing,
      downgrade,
      upcomingPlan,
    } = this.props;
    const { dialogOpen, pendingPlan } = this.state;

    return (
      <EntityForm
        formDestination="Account settings"
        handleSubmit={handleSubmit(this.onSubmit)}
        editing
        invalid={invalid}
        submitting={submitting}
        warns={warns}
        user={user}
      >
        <StripeWarningDisplay />
        <Dialog
          modal={false}
          open={dialogOpen}
          title="Payment"
        >
          <PlanPaymentRedirection
            handleDialog={this.handleDialog}
            id={user.id}
            plan={pendingPlan}
          />
        </Dialog>
        <div className="lesson-type-form">
          <form
            onSubmit={handleSubmit(this.onSubmit)}
            className="centered-form"
          >
            <div className="account-settings__section-header">
              <span>
                Account information
              </span>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Account name:*</label>
              <div className="lesson-type-form__input">
                <Field
                  name="name"
                  component={TextField}
                  type="text"
                  hintText=""
                  validate={[required]}
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Email:*</label>
              <div className="lesson-type-form__input">
                <Field
                  name="email"
                  component={TextField}
                  type="text"
                  hintText=""
                  validate={[required]}
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Phone number:</label>
              <div className="client-manager-form__input">
                <Field
                  name="phone"
                  component={RenderPhoneInput}
                  validate={[phone]}
                  placeholder="Enter your phone number"
                />
                <span id="phone-anchor" style={anchorStyle} />
              </div>
            </div>
            <div className="account-settings__section-header">
              <span>
                Plan information
              </span>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Current plan:*</label>
              <div className="lesson-type-form__input">
                <RenderSelectedPlan
                  plan={plan}
                  planPaymentType={planPaymentType}
                  planPricing={planPricing}
                  setSelectorValue={this.setSelectorValue}
                  handleInstantPlanChange={user.role === 'manager' && this.handleInstantPlanChange}
                />
              </div>
            </div>
            {downgrade
              && (
              <div className="lesson-type-form__input-wrapper">
                <label />
                <div className="lesson-type-form__input">
                  <small className="note" style={{ color: '#455D8D' }}>
                    Upcoming plan*:
                    {upcomingPlan.name}
                  </small>
                </div>
              </div>
              )}
            <div className="lesson-type-form__input-wrapper">
              <label>Plan payment type:*</label>
              <div className="lesson-type-form__input">
                <Field
                  name="planPaymentType"
                  component={TextField}
                  type="text"
                  disabled
                  {...styles}
                />
              </div>
            </div>
            <div className="lesson-type-form__input-wrapper">
              <label>Paid until:</label>
              <div className="lesson-type-form__input account-currency">
                <Field
                  component={TextField}
                  name="planPaidUntil"
                  disabled
                />
              </div>
            </div>
            {user.role !== 'admin'
            && (
            <div>
              <div className="account-settings__section-header">
                <span>
                  Change password
                </span>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Old password:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="passwordOld"
                    component={TextField}
                    type="password"
                    hintText=""
                    {...styles}
                  />
                  <span id="change-password-anchor" style={anchorStyle} />
                </div>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>New password:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="passwordNew"
                    component={TextField}
                    type="password"
                    hintText=""
                    validate={[password]}
                    {...styles}
                  />
                </div>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Repeat password:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="passwordRepeat"
                    component={TextField}
                    type="password"
                    hintText=""
                    {...styles}
                  />
                </div>
              </div>
            </div>
            )}
            {user.role === 'admin'
            && (
            <div>
              <div className="account-settings__section-header">
                <span>
                  Six Payment settings
                </span>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Account ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="sixPaymentAccountId"
                    component={TextField}
                    autoComplete="off"
                    type="text"
                    hintText=""
                    {...styles}
                  />
                </div>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Password:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="sixPaymentAccountPass"
                    component={TextField}
                    autoComplete="off"
                    type="password"
                    hintText=""
                    {...styles}
                  />
                </div>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Gateway account ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="sixPaymentGatewayAccountId"
                    component={TextField}
                    autoComplete="off"
                    type="text"
                    hintText=""
                    {...styles}
                  />
                </div>
              </div>
              <div className="lesson-type-form__input-wrapper">
                <label>Terminal ID:</label>
                <div className="lesson-type-form__input">
                  <Field
                    name="sixPaymentTerminalId"
                    component={TextField}
                    autoComplete="off"
                    type="text"
                    hintText=""
                    {...styles}
                  />
                </div>
              </div>
            </div>
            )}
          </form>
        </div>
      </EntityForm>
    );
  }
}

const warn = (values, props) => {
  const warnings = {};

  const passwords = [];
  if (values.passwordOld) {
    passwords.push({});
  }
  if (values.passwordNew) {
    passwords.push({});
  }
  if (values.passwordRepeat) {
    passwords.push({});
  }
  if (!values.passwordOld && passwords.length > 0) {
    warnings.passwordOld = 'Please type in your old password';
    props.touch('passwordOld');
  }
  if (!values.passwordRepeat && passwords.length > 0) {
    warnings.passwordRepeat = 'Please type in your new password';
    props.touch('passwordRepeat');
  }
  if (!values.passwordNew && passwords.length > 0) {
    warnings.passwordNew = 'Please type in your new password';
    props.touch('passwordNew');
  }
  if (values.passwordNew && values.passwordRepeat && values.passwordNew !== values.passwordRepeat) {
    warnings.passwordRepeat = 'Passwords must match';
  }

  if (values.role === 'admin') {
    const sixPayments = [];

    if (values.sixPaymentAccountId) {
      sixPayments.push({});
    }
    if (values.sixPaymentTerminalId) {
      sixPayments.push({});
    }
    if (values.sixPaymentAccountPass) {
      sixPayments.push({});
    }
    if (values.sixPaymentGatewayAccountId) {
      sixPayments.push({});
    }

    if (!values.sixPaymentAccountId && sixPayments.length > 0) {
      warnings.sixPaymentAccountId = 'This field is required';
      props.touch('sixPaymentAccountId');
    }
    if (!values.sixPaymentTerminalId && sixPayments.length > 0) {
      warnings.sixPaymentTerminalId = 'This field is required';
      props.touch('sixPaymentTerminalId');
    }
    if (!values.sixPaymentAccountPass && sixPayments.length > 0) {
      warnings.sixPaymentAccountPass = 'This field is required';
      props.touch('sixPaymentAccountPass');
    }
    if (!values.sixPaymentGatewayAccountId && sixPayments.length > 0) {
      warnings.sixPaymentGatewayAccountId = 'This field is required';
      props.touch('sixPaymentGatewayAccountId');
    }
  }

  return warnings;
};

const formConfig = {
  form: 'AccountSettings',
  enableReinitialize: true,
  warn,
};

const selector = formValueSelector('AccountSettings');

const mapStateToProps = (state, props) => {
  const id = get(props.match.params, 'id', '') || state.user.id;

  const initialValues = {
    ...state.entities.account[id || Object.keys(state.entities.account)],
  };

  if (initialValues.planPaidUntil) {
    initialValues.planPaidUntil = moment(state.entities.account[id || Object.keys(state.entities.account)].planPaidUntil).format('DD/MM/YYYY');
  }

  return {
    initialValues,
    formValues: getFormValues('AccountSettings')(state),
    plan: selector(state, 'plan'),
    downgrade: selector(state, 'downgrade'),
    upcomingPlan: selector(state, 'upgradePlan'),
    planPaymentType: selector(state, 'planPaymentType'),
    warns: getFormSyncWarnings('AccountSettings')(state),
    planPricing: state.entities.subscriptionPlan,
    user: state.user,
    userGuide: state.userGuide,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAccounts,
    fetchMyAccount,
    updateAccount,
    updateMyAccount,
    notifySuccess,
    notifyWarning,
    fetchPlans,
    goToStep,
  }, dispatch),
});

AccountSettings.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    fetchAccounts: PropTypes.func,
    fetchMyAccount: PropTypes.func,
    updateAccount: PropTypes.func,
    updateMyAccount: PropTypes.func,
    notifySuccess: PropTypes.func,
    notifyWarning: PropTypes.func,
    fetchPlans: PropTypes.func,
    goToStep: PropTypes.func,
  }),
  userGuide: PropTypes.shape({
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
  }),
  change: PropTypes.func,
  planPaymentType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ]),
  initialValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  formValues: PropTypes.shape({
    plan: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
    ]),
    planPaymentTypes: PropTypes.string,
  }),
  handleSubmit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  plan: PropTypes.object,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  warns: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  planPricing: PropTypes.object,
  downgrade: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  upcomingPlan: PropTypes.object,
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(AccountSettings)));
