import React from 'react';
import PropTypes from 'prop-types';
import '../styles/SpecialityIcon.scss';

const SpecialityIcon = ({ level, speciality }) => (
  <div className={`speciality-tag ${level ? level.toLowerCase() : 'unset'}`}>
    <label>{speciality.name}</label>
  </div>
);

SpecialityIcon.propTypes = {
  level: PropTypes.string,
  speciality: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default React.memo(SpecialityIcon);
