import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const DatePickerIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="#33334A" d="M240.65,12.475 L238.766667,12.475 L238.766667,14.3583333 L240.65,14.3583333 L240.65,12.475 Z M244.416667,12.475 L242.533333,12.475 L242.533333,14.3583333 L244.416667,14.3583333 L244.416667,12.475 Z M248.183333,12.475 L246.3,12.475 L246.3,14.3583333 L248.183333,14.3583333 L248.183333,12.475 Z M250.066667,5.88333333 L249.125,5.88333333 L249.125,4 L247.241667,4 L247.241667,5.88333333 L239.708333,5.88333333 L239.708333,4 L237.825,4 L237.825,5.88333333 L236.883333,5.88333333 C235.838083,5.88333333 235.009417,6.73083333 235.009417,7.76666667 L235,20.95 C235,21.9858333 235.838083,22.8333333 236.883333,22.8333333 L250.066667,22.8333333 C251.1025,22.8333333 251.95,21.9858333 251.95,20.95 L251.95,7.76666667 C251.95,6.73083333 251.1025,5.88333333 250.066667,5.88333333 Z M250.066667,20.95 L236.883333,20.95 L236.883333,10.5916667 L250.066667,10.5916667 L250.066667,20.95 Z" transform="translate(-235 -4)" />
  </SvgIcon>
);

DatePickerIcon.displayName = 'DatePickerIcon';
DatePickerIcon.muiName = 'SvgIcon';

export default React.memo(DatePickerIcon);
