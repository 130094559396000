import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Route from './components/Route';
import configureStore from './store/ConfigureStore';
import MainLayout from './containers/MainLayout';
import CalendarLayout from './containers/CalendarLayout';
import Site from './components/Site';
import Login from './containers/Login';
import ChangePassword from './containers/ChangePassword';
import Home from './containers/Home';
import CalendarPage from './containers/CalendarPage';
import Products from './containers/ProductList';
import ProductForm from './containers/ProductForm';
import AccountSettings from './containers/AccountSettings';
import SchoolSettings from './containers/SchoolSettings';
import Hotels from './containers/Hotels';
import skiTheme from './styles/theme/skiTheme';
import GroupList from './containers/GroupList';
import InstructorList from './containers/InstructorList';
import InstructorPage from './containers/InstructorPage';
import InstructorsSalary from './containers/InstructorsSalary';
import ClientsList from './containers/ClientsList';
import ClientForm from './containers/ClientForm';
import PartnerManagers from './containers/PartnerManagerList';
import PartnerManagerForm from './containers/PartnerManagerForm';
import GroupForm from './containers/GroupForm';
import BookingList from './containers/BookingList';
import InstructorAvailabilityList from './containers/InstructorAvailabilityList';
import AdditionalServicesList from './containers/AdditionalServicesList';
import PartnerList from './containers/PartnerList';
import NotFound from './components/NotFound';
import AccountList from './containers/AccountList';
import PaymentErrorPage from './containers/PaymentErrorPage';
import PaymentSuccessPage from './containers/PaymentSuccessPage';
import WizardForm from './containers/RegistrationWizard/WizardForm';
import Statistics from './containers/Statistics/Statistics';

import 'react-table/react-table.css';
import 'react-select/dist/react-select.css';

const store = configureStore();

ReactDOM.render(
  (
    <MuiThemeProvider muiTheme={getMuiTheme(skiTheme)}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" layout={Site} component={Home} />
            <Route exact path="/login" layout={Site} component={Login} />
            <Route path="/register" layout={Site} component={WizardForm} />
            <Route path="/changePassword" layout={Site} component={ChangePassword} />
            <Route name="Calendar" path="/calendar" layout={CalendarLayout} component={CalendarPage} allowedRoles={['manager']} />
            <Route exact name="Products" path="/products" layout={MainLayout} display="two-col" component={Products} allowedRoles={['manager']} />
            <Route exact name="Add Product" path="/product" layout={MainLayout} display="two-col" component={ProductForm} allowedRoles={['manager']} />
            <Route exact name="Edit Product" path="/products/:id" layout={MainLayout} display="two-col" component={ProductForm} allowedRoles={['manager']} />

            <Route exact name="Account Information" path="/account" layout={MainLayout} display="two-col" component={AccountSettings} allowedRoles={['manager']} />
            <Route exact name="Account Settings" path="/account/:id" layout={MainLayout} display="one-col" component={AccountSettings} allowedRoles={['admin']} />
            <Route exact name="Accounts" path="/accounts" display="superadmin" layout={MainLayout} component={AccountList} allowedRoles={['admin']} />
            <Route name="School Settings" path="/school" display="two-col" layout={MainLayout} component={SchoolSettings} allowedRoles={['manager']} />

            <Route name="Hotels" path="/hotels" display="two-col" layout={MainLayout} component={Hotels} allowedRoles={['manager']} />

            <Route exact name="Groups" path="/groups" layout={MainLayout} display="two-col" component={GroupList} allowedRoles={['manager']} />
            <Route exact name="Add Group" path="/group" layout={MainLayout} display="two-col" component={GroupForm} allowedRoles={['manager']} />
            <Route exact name="Edit Group" path="/groups/:id" layout={MainLayout} display="two-col" component={GroupForm} allowedRoles={['manager']} />

            <Route exact name="Instructors" path="/instructors" layout={MainLayout} display="two-col" component={InstructorList} allowedRoles={['manager']} />
            <Route exact name="Instructors Salary" path="/instructors-salary" layout={MainLayout} display="two-col" component={InstructorsSalary} allowedRoles={['manager']} />
            <Route exact name="Add Instructor" path="/instructor/add" display="two-col" layout={MainLayout} component={InstructorPage} allowedRoles={['manager']} />
            <Route exact name="Edit Instructor" path="/instructor/:id" display="two-col" layout={MainLayout} component={InstructorPage} allowedRoles={['manager']} />
            <Route exact name="Overview" path="/instructor/:id/:overview" display="two-col" layout={MainLayout} component={InstructorPage} allowedRoles={['manager']} />
            <Route exact name="" path="/availability/:id" display="two-col" layout={MainLayout} component={InstructorAvailabilityList} allowedRoles={['manager']} />
            <Route exact name="My Availability List" path="/availability" layout={MainLayout} display="one-col" component={InstructorAvailabilityList} allowedRoles={['instructor', 'headCoach']} />
            <Route exact name="My Profile" path="/profile" display="one-col" layout={MainLayout} component={InstructorPage} allowedRoles={['instructor', 'headCoach']} />

            <Route exact name="Payment success" path="/success" component={PaymentSuccessPage} layout={Site} />
            <Route exact name="Payment error" path="/error" component={PaymentErrorPage} layout={Site} />

            <Route exact name="Statistics" path="/statistics" display="two-col" layout={MainLayout} component={Statistics} allowedRoles={['manager']} />

            <Route exact name="Client List" path="/clients" display="two-col" layout={MainLayout} component={ClientsList} allowedRoles={['manager']} />
            <Route exact name="Client Add" path="/client" display="two-col" layout={MainLayout} component={ClientForm} allowedRoles={['manager']} />
            <Route exact name="Client Edit" path="/clients/:id" display="two-col" layout={MainLayout} component={ClientForm} allowedRoles={['manager']} />
            <Route exact name="Client Edit" path="/clients/:id/:overview" display="two-col" layout={MainLayout} component={ClientForm} allowedRoles={['manager']} />

            <Route exact name="Partners Managers" path="/managers" display="two-col" component={PartnerManagers} layout={MainLayout} allowedRoles={['manager']} />
            <Route exact name="Add Partner Manager" path="/manager" display="two-col" component={PartnerManagerForm} layout={MainLayout} allowedRoles={['manager']} />
            <Route exact name="Edit Partner Manager" path="/managers/:id" display="two-col" component={PartnerManagerForm} layout={MainLayout} allowedRoles={['manager']} />
            <Route exact path="/managers/:id/:overview" display="two-col" component={PartnerManagerForm} layout={MainLayout} allowedRoles={['manager']} />

            <Route exact name="Bookings" path="/bookings" display="two-col" component={BookingList} allowedRoles={['manager']} layout={MainLayout} />

            <Route exact name="Additional Services" path="/services" display="two-col" component={AdditionalServicesList} allowedRoles={['manager']} layout={MainLayout} />

            <Route exact name="Partners" path="/partners" display="two-col" component={PartnerList} allowedRoles={['manager']} layout={MainLayout} />

            <Route name="404" path="*" component={NotFound} />
          </Switch>
        </Router>
      </Provider>
    </MuiThemeProvider>
  ),
  document.getElementById('root'),
);
