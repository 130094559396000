import React from 'react';
import PropTypes from 'prop-types';
import Individual from './Individual';
import Group from './Group';

const Event = ({ event, onGroupOpen, onItemDoubleClick }) => {
  if (event.type === 'individual') {
    return (
      <Individual event={event} onItemDoubleClick={onItemDoubleClick} />
    );
  }
  return (
    <Group event={event} onGroupOpen={onGroupOpen} />
  );
};

Event.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
  }),
  onGroupOpen: PropTypes.func,
  onItemDoubleClick: PropTypes.func,
};

export default React.memo(Event);
