const initialState = {
  ...JSON.parse(localStorage.getItem('user')),
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGIN_SUCCESS':
      return {
        ...action.payload.result,
      };
    case 'USER_LOGOUT_SUCCESS':
      return {};
    case 'ACCOUNT_CHANGE_RESORT':
      localStorage.setItem('user', JSON.stringify({
        ...state,
        account: {
          ...state.account,
          resort: action.value,
        },
      }));
      return {
        ...state,
        account: {
          ...state.account,
          resort: action.value,
        },
      };
    default:
      return state;
  }
};

export default user;
