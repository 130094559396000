const initialState = {
  dirty: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'BOOKING_POST_SUCCESS':
      return {
        dirty: true,
      };
    case 'FLUSH_CALENDAR':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
