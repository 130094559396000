import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AutoComplete from 'material-ui/AutoComplete';
import '../styles/Filter.scss';

class MassActionsComponent extends Component {
  handleRequestList = (inputValue) => {
    const { onUpdateInput } = this.props;
    const requestList = this.refs.AutoComplete.requestsList;
    onUpdateInput(requestList, inputValue);
  };

  render() {
    let hintText = 'Client name, surname';
    const { destination, dataSource } = this.props;

    if (destination === 'Instructors') {
      hintText = 'Instructor name, surname';
    }
    if (destination === 'Managers') {
      hintText = 'Manager name, surname or partner';
    }
    if (destination === 'Booking') {
      hintText = 'Booking number, client name, partner...';
    }
    if (destination === 'Services') {
      hintText = 'Additional service';
    }
    if (destination === 'Partners') {
      hintText = 'Partner name';
    }
    if (destination === 'Hotels') {
      hintText = 'Hotel name';
    }

    return (
      <div className="filter">
        <label>Filter:</label>
        <div className="filter__autocomplete">
          <AutoComplete
            ref="AutoComplete"
            filter={AutoComplete.caseInsensitiveFilter}
            dataSource={dataSource}
            menuStyle={{ display: 'none' }}
            onUpdateInput={this.handleRequestList}
            hintText={hintText}
          />
        </div>
      </div>
    );
  }
}

MassActionsComponent.propTypes = {
  onUpdateInput: PropTypes.func,
  destination: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
};

export default MassActionsComponent;
