import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AccountLimitationWarning = ({ usedAmounts, user }) => {
  if (user.role === 'manager' && usedAmounts.warningOn && (usedAmounts.warningOn.bookingLimit || usedAmounts.warningOn.instructorLimit)) {
    return (
      <span className="limitation-warning">
        <i className="material-icons">
          warning
        </i>
        <span style={{ marginLeft: '10px' }}>Account close to plan limits</span>
      </span>
    );
  }
  return <span />;
};

const mapStateToProps = (state) => ({
  usedAmounts: state.usedAmounts,
  user: state.user,
});

AccountLimitationWarning.propTypes = {
  usedAmounts: PropTypes.shape({
    warningOn: PropTypes.shape({
      bookingLimit: PropTypes.bool,
      instructorLimit: PropTypes.bool,
    }),
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
};

export default connect(mapStateToProps)(React.memo(AccountLimitationWarning));
