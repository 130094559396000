import React, { Component } from 'react';
import '../styles/Online.scss';

class Online extends Component {
  componentDidMount() {
    this.interval = setInterval(this.forceUpdate.bind(this), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <span>
        { navigator.onLine ? (
          <span className="online">
            &nbsp;You are Online
          </span>
        ) : (
          <span className="offline">
            &nbsp;You are Offline, you can only use the calendar
          </span>
        )}
      </span>
    );
  }
}

export default Online;
