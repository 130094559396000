import React from 'react';
import PropTypes from 'prop-types';
import '../styles/DeleteEntityButton.scss';

const DeleteEntityButton = ({ handleDelete, id, label }) => {
  const handleButtonClicked = () => {
    handleDelete(id);
  };

  return (
    <div className="delete-entity-button" onClick={handleButtonClicked}>
      <div className="delete-entity-button__label">
        {label}
      </div>
    </div>
  );
};

DeleteEntityButton.propTypes = {
  handleDelete: PropTypes.func,
  id: PropTypes.number,
  label: PropTypes.string,
};

export default React.memo(DeleteEntityButton);
