import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const InfoLabel = ({ group, time }) => {
  const date = moment(time, 'x');
  const label = group ? group.title : '';
  return (
    <div
      style={{
        position: 'fixed',
        left: 100,
        bottom: 100,
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: 10,

        fontSize: 20,
        borderRadius: 5,
        zIndex: 85,
      }}
    >
      {`${date.format('LLL')}, ${label}`}
    </div>
  );
};

InfoLabel.propTypes = {
  group: PropTypes.shape({
    title: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  time: PropTypes.object,
};

export default React.memo(InfoLabel);
