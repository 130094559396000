import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Select from 'react-select';
import { Toggle } from 'material-ui';
import moment from 'moment';
import { fetchEvents } from '../actions/eventActions';
import { fetchFilteredInstructors } from '../actions/instructorsActions';
import { fetchActivities } from '../actions/activitiesActions';
import { fetchProducts } from '../actions/productsActions';
import { fetchGroups } from '../actions/groupsActions';
import { fetchSpecialities } from '../actions/specialityActions';
import DatePicker from '../components/DatePicker';
import DatePickerIcon from '../components/DatePickerIcon';
import { mapPropsToOptions, mapPersonsToOptions } from '../utils/map';
import { filterActiveInstructors } from '../utils/filter';
import '../styles/FilterCalendar.scss';
import { languageOptions } from '../constants/LanguageOptions';
import { levels, types } from '../utils/formHelpers';

const Filter = ({
  actions,
  instructors,
  products,
  groups,
  activities,
  _specialities,
  onUpdate,
  onInstructorUpdate,
  updateFilter,
  setUpdateFilter,
  updateCalendarView,
}) => {
  const [filterByInstructor, setFilterByInstructor] = useState(false);
  const [filteredSpecialities, setFilteredSpecialities] = useState([]);
  const [filterState, setFilterState] = useState({});

  useEffect(() => {
    actions.fetchProducts();
    actions.fetchGroups();
    actions.fetchActivities();
    actions.fetchSpecialities();
  }, []);

  const handleReset = (instructor) => {
    setFilterState({});
    const check = instructor !== undefined ? instructor : filterByInstructor;

    if (check) {
      actions.fetchFilteredInstructors({}).then((res) => {
        onInstructorUpdate({
          instructors: res.payload.result,
        });
      });
    } else {
      actions.fetchEvents({});
      onUpdate({});
    }
  };

  const onFilterChange = () => {
    handleReset(!filterByInstructor);
    setFilterByInstructor(!filterByInstructor);
  };

  const update = (filter) => {
    let values = { ...filter };

    if (values && values.timeFrom) {
      const t = moment(values.timeFrom).hours(0).minutes(0).seconds(0);

      values = {
        ...values,
        timeFrom: t.format(),
      };
    }

    if (values && values.timeTo) {
      const t = moment(values.timeTo).hours(23).minutes(59).seconds(59);

      values = {
        ...values,
        timeTo: t.format(),
      };
    }

    if (values && values.instructor) {
      if (Array.isArray(values.instructor)) {
        values = {
          ...values,
          instructors: map(values.instructor, (val) => {
            if (val.value) {
              return val.value;
            }
            return val.id;
          }),
        };
      } else {
        values = {
          ...values,
          instructors: [values.instructor.value],
        };
      }
      delete values.instructor;
    }
    if (values && values.type) {
      values = {
        ...values,
        type: values.type.value,
      };
    } else {
      values = {
        ...values,
        type: null,
      };
    }
    if (values && values.product && values.product.value) {
      values = {
        ...values,
        product: values.product.value,
      };
    }
    if (values && values.group && values.group.value) {
      values = {
        ...values,
        group: values.group.value,
      };
    }
    if (values && values.activity && values.activity.value) {
      values = {
        ...values,
        activity: values.activity.value,
      };
    }
    if (values && values.speciality && values.speciality.value) {
      values = {
        ...values,
        speciality: values.speciality.value,
      };
    }
    if (values && values.level && values.level.value) {
      values = {
        ...values,
        level: values.level.value,
      };
    }
    if (values && values.language && values.language.value) {
      values = {
        ...values,
        language: values.language.value,
      };
    }

    if (filterByInstructor) {
      actions.fetchFilteredInstructors(filter).then((res) => {
        onInstructorUpdate({
          instructors: res.payload.result,
        });
      });
    } else {
      actions.fetchEvents(values);
      onUpdate(values);
    }
  };

  useEffect(() => {
    if (updateFilter) {
      update(filterState);
      setUpdateFilter();
    }
  }, [updateFilter]);

  const updateCalendar = ({ timeFrom, timeTo }) => {
    if (timeFrom && timeTo) {
      // adding 1 day to time to because it is set to selected day 00:00
      // and we need the next day 00:00
      updateCalendarView(timeFrom, moment(timeTo).add(1, 'day'));
    } else if (!timeTo) {
      updateCalendarView(timeFrom, moment(timeFrom).add(1, 'day'));
    } else {
      updateCalendarView(moment(timeTo).add(-1, 'day'), timeTo);
    }
  };

  const change = (field, value) => {
    const filter = {
      ...filterState,
      [field]: value,
    };
    if (field === 'timeFrom' || field === 'timeTo') {
      updateCalendar(filter);
    }
    setFilterState(filter);
    update(filter);
  };

  const onTypeChange = (item) => {
    const filter = {
      ...filterState,
      product: null,
      group: null,
      type: item,
    };
    setFilterState(filter);
    update(filter);
  };

  const onProductChange = (item) => {
    if (!Object.prototype.hasOwnProperty.call(products, item)) {
      const filter = {
        ...filterState,
        product: item,
        activity: null,
        speciality: null,
      };
      setFilterState(filter);
      update(filter);
      return;
    }

    const product = products[item];
    const filter = {
      ...filterState,
      product: item,
      group: null,
    };

    if (product.speciality) {
      filter.activity = _specialities[product.speciality].activity;
      filter.speciality = product.speciality;
    }
    if (product.level && product.level !== 'any') {
      filter.level = product.level;
    }
    setFilterState(filter);
    update(filter);
  };

  const onGroupChange = (item) => {
    if (!Object.prototype.hasOwnProperty.call(groups, item)) {
      const filter = {
        ...filterState,
        group: item,
      };
      setFilterState(filter);
      update(filter);

      return;
    }

    const group = groups[item];

    const filter = {
      ...filterState,
      instructor: map(group.instructors, (i) => ({ value: i.id, label: i.name })),
      product: null,
      activity: _specialities[group.speciality].activity,
      speciality: group.speciality,
      level: group.level,
      language: group.language,
      group: item,
    };
    setFilterState(filter);
    update(filter);
  };

  const onActivityChange = (val) => {
    const filter = {
      ...filterState,
      speciality: null,
      activity: val,
    };
    setFilterState(filter);
    update(filter);
    if (val) {
      actions.fetchSpecialities(`/activity/${val}`).then((res) => {
        setFilteredSpecialities(mapPropsToOptions(res.payload.result.speciality, false, true));
      });
    } else {
      setFilteredSpecialities([]);
    }
  };

  const {
    timeFrom,
    timeTo,
    instructor,
    type,
    product,
    group,
    activity,
    speciality,
    level,
    language,
  } = filterState;

  const instructorOptions = useMemo(() => mapPersonsToOptions(instructors, true), [instructors]);
  const productOptions = useMemo(() => mapPropsToOptions(products, false, true), [products]);
  const groupOptions = useMemo(() => mapPropsToOptions(groups, false, true, true), [groups]);

  return (
    <div className="filter-main">
      <div className="filter-toggle">
        <label className="filter-toggle-label" id="calendar-filter">Calendar filter</label>
        <Toggle
          name="timeRange"
          label="Instructors filter"
          labelPosition="right"
          value={filterByInstructor}
          onToggle={onFilterChange}
          inputStyle={{ width: '40px' }}
        />
      </div>
      <div
        className="filter-form"
      >
        {!filterByInstructor
        && (
          <div className="filter-main__date-picker">
            <div className="filter-main__date-picker-field">
              <DatePicker
                name="timeFrom"
                placeholder="Date from"
                enabled
                value={timeFrom}
                change={change}
              />
            </div>
            <p> - </p>
            <div className="filter-main__date-picker-field-2">
              <DatePicker
                name="timeTo"
                placeholder="Date to"
                enabled
                value={timeTo}
                change={change}
              />
            </div>
            <DatePickerIcon className="filter-main__date-picker-icon" />
          </div>
        )}
        {!filterByInstructor
        && (
          <div className="filter-main__instructor field">
            <Select
              multi
              clearable
              name="instructor"
              options={instructorOptions}
              disabled={!!group}
              placeholder="Instructor"
              onChange={(value) => change('instructor', value)}
              value={instructor || null}
            />
          </div>
        )}
        {!filterByInstructor
        && (
          <div className="filter-main__type field">
            <Select
              name="type"
              options={types}
              disabled={false}
              clearable
              placeholder="Type"
              onChange={onTypeChange}
              value={type || null}
            />
          </div>
        )}
        {type && (type.id === 'individual' || type.value === 'individual')
        && (
          <div className="filter-main__product field">
            <Select
              simpleValue
              name="product"
              options={productOptions}
              disabled={false}
              clearable
              placeholder="Product"
              onChange={onProductChange}
              value={product || null}
            />
          </div>
        )}
        {type && (type.id === 'group' || type.value === 'group')
        && (
          <div className="filter-main__product field">
            <Select
              simpleValue
              name="group"
              options={groupOptions}
              disabled={false}
              clearable
              placeholder="Group"
              onChange={onGroupChange}
              value={group || null}
            />
          </div>
        )}
        <div className="filter--field field">
          <Select
            simpleValue
            name="activity"
            options={activities}
            disabled={!!group || (!!product
              && products[product].speciality !== null)}
            clearable
            placeholder="Activity"
            onChange={onActivityChange}
            value={activity || null}
          />
        </div>
        <div className="filter--field field">
          <Select
            simpleValue
            name="speciality"
            options={activity ? filteredSpecialities : []}
            disabled={!!group || (!!product
              && products[product].speciality !== null)}
            clearable
            placeholder="Speciality"
            onChange={(value) => change('speciality', value)}
            value={speciality || null}
          />
        </div>
        <div className="filter--field field">
          <Select
            simpleValue
            name="level"
            options={levels}
            disabled={!!group || (!!product
              && products[product].level !== null)}
            clearable
            placeholder="Level"
            onChange={(value) => change('level', value)}
            value={level || null}
          />
        </div>
        <div className="filter-main__language field">
          <Select
            simpleValue
            name="language"
            options={languageOptions}
            disabled={!!group}
            clearable
            placeholder="Language"
            onChange={(value) => change('language', value)}
            value={language || null}
          />
        </div>
        <i className="material-icons" style={{ cursor: 'pointer' }} onClick={() => handleReset()}>clear</i>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  instructor: state.entities.instructor,
  instructors: filterActiveInstructors(state.entities.instructor),
  activities: mapPropsToOptions(state.entities.activity, false, true),
  specialities: mapPropsToOptions(state.entities.speciality, false, true),
  groups: state.entities.group,
  products: state.entities.product,
  _activities: state.entities.activity,
  _specialities: state.entities.speciality,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchEvents,
    fetchFilteredInstructors,
    fetchActivities,
    fetchProducts,
    fetchGroups,
    fetchSpecialities,
  }, dispatch),
});

Filter.propTypes = {
  actions: PropTypes.shape({
    fetchEvents: PropTypes.func,
    fetchFilteredInstructors: PropTypes.func,
    fetchActivities: PropTypes.func,
    fetchProducts: PropTypes.func,
    fetchGroups: PropTypes.func,
    fetchSpecialities: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  instructors: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  activities: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  products: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  _specialities: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  groups: PropTypes.object,
  onUpdate: PropTypes.func,
  onInstructorUpdate: PropTypes.func,
  updateFilter: PropTypes.bool,
  setUpdateFilter: PropTypes.func,
  updateCalendarView: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Filter));
