import React from 'react';
import PropTypes from 'prop-types';

const RenderToggleSimple = ({
  input,
  disabled,
  wrapperClass,
}) => {
  const labelClass = 'label label--inverted';
  const sliderClass = 'slider'; //  slider--inverted slider--inverted--padded
  return (
    <div className="toggle-wrapper" style={{ position: 'relative' }}>
      <div className={wrapperClass}>
        <label className="toggle-element" style={{ textAlign: 'initial', marginTop: 0 }}>
          <input
            type="checkbox"
            disabled={disabled}
            checked={input.value}
            value={input.value}
            onChange={input.onChange}
            name={input.name}
          />
          <span className={sliderClass} />
        </label>
        <label
          className={input.value ? (`${labelClass}__checked`) : labelClass}
          style={{ textAlign: 'initial', marginTop: 0 }}
        />
      </div>
    </div>
  );
};

RenderToggleSimple.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    onChange: PropTypes.func,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

export default React.memo(RenderToggleSimple);
