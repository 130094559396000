import React from 'react';
import PropTypes from 'prop-types';

const DownloadStep = ({ templateLocation, templateName }) => (
  <div className="step">
    <span className="step-no">1</span>
    <span className="step-header">
      Step 1 - Download the template
    </span>
    <div className="step-content">
      <span className="excel-icon" />
      <a download={templateName} href={templateLocation} className="file-name">{templateName}</a>
      <span className="download-icon" />
    </div>
    <div className="note">*If you are using safari right click to download the file</div>
  </div>
);

DownloadStep.propTypes = {
  templateLocation: PropTypes.string,
  templateName: PropTypes.string,
};

export default React.memo(DownloadStep);
