import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfileNav from './ProfileNav';
import Dialog from '../components/Dialog';
import { open } from '../actions/dialogActions';

const AdminLayout = (props) => {
  const { dialog, children } = props;
  return (
    <div>
      <Dialog {...dialog} />
      <ProfileNav />
      {children && React.cloneElement(children, props)}
    </div>
  );
};

const mapStateToProps = (state) => ({
  dialog: state.dialog,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: bindActionCreators(open, dispatch),
});

AdminLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dialog: PropTypes.object,
  children: PropTypes.element,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AdminLayout));
