import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { map } from 'lodash';
import TimelineDayBlock from './TimelineDayBlock';

const TimelineMonthBlock = ({
  month,
  events,
  onGroupOpen,
  onItemDoubleClick,
}) => (
  <div className="timeline-month-block">
    <h3 className="month">{moment(month, 'MM').format('MMMM')}</h3>
    {map(events, (e, key) => (
      <TimelineDayBlock
        key={key}
        day={e.day}
        events={e.events}
        onGroupOpen={onGroupOpen}
        onItemDoubleClick={onItemDoubleClick}
      />
    ))}
  </div>
);

TimelineMonthBlock.propTypes = {
  month: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.array,
  onGroupOpen: PropTypes.func,
  onItemDoubleClick: PropTypes.func,
};

export default React.memo(TimelineMonthBlock);
