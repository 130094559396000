/* eslint-disable */
import React from 'react';
import _ from 'lodash';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import NativeSelect from '@material-ui/core/NativeSelect';
import ActiveButton from './ActiveButton';
import { formatDate } from '../utils/format';
import DownloadEntityButton from './DownloadEntityButton';
import DeleteEntityButton from './DeleteEntityButton';

const Column = (props) => (
  <div style={{ textAlign: 'center' }}>
    {props.children && React.cloneElement(props.children, props)}
  </div>
);

const accountListColumns = (
  accountList,
  resortList,
  onActiveButtonClick,
  openStatistics,
  setIndividualDiscount,
  handleDialog,
  deleteSchool,
  setRef,
  updatePaymentType,
  historyPush,
  openDialog,
) => [
  {
    Header: 'Active',
    accessor: 'newId',
    sortMethod: (a, b) => {
      if (accountList[a].active === accountList[b].active) {
        return accountList[a].createdAt > accountList[b].createdAt ? 1 : -1;
      }
      return accountList[a].active > accountList[b].active ? 1 : -1;
    },
    Cell: (props) => (
      <Column>
        <ActiveButton
          id={props.original.id}
          status={props.original.active}
          onClick={() => onActiveButtonClick(props.original.id)}
        />
      </Column>
    ),
    maxWidth: 60,
  },
  {
    Header: 'Register date',
    accessor: 'createdAt',
    Cell: (props) => (
      <Column>
        <span>{formatDate(props.value)}</span>
      </Column>
    ),
    minWidth: 85,
    maxWidth: 120,
  },
  {
    Header: 'School name',
    accessor: 'name',
    Cell: (props) => (
      <Column>
        <span
          onClick={(e) => { e.stopPropagation(); historyPush(`/account/${props.original.id}`); }}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          {_.capitalize(props.value)}
        </span>
      </Column>
    ),
  },
  {
    Header: 'Resort',
    accessor: 'id',
    sortMethod: (a, b) => {
      if (resortList[accountList[a].resort] === resortList[accountList[b].resort]) {
        return accountList[a].createdAt > accountList[b].createdAt ? 1 : -1;
      }
      return resortList[accountList[a].resort].name > resortList[accountList[b].resort].name ? 1 : -1;
    },
    Cell: (props) => <span>{resortList[props.original.resort].name}</span>,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: (props) => (
      <span
        onClick={() => window.location.href = `mailto:${props.value}`}
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
      >
        {props.value}
      </span>
    ),
  },
  {
    Header: 'Plan',
    accessor: 'account.id',
    sortMethod: (a, b) => {
      if (accountList[a].plan.id === accountList[b].plan.id) {
        return accountList[a].createdAt > accountList[b].createdAt ? 1 : -1;
      }
      return accountList[a].plan.name > accountList[b].plan.name ? 1 : -1;
    },
    Cell: (props) => (
      <div
        onClick={(e) => openStatistics(e, props.row.id)}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        {accountList[props.row.id].plan.name}
      </div>
    ),
    maxWidth: 100,
  },
  {
    Header: 'Payment plan',
    accessor: 'planPaymentType',
    maxWidth: 135,
    Cell: (props) => (
      <NativeSelect
        name="planPaymentType"
        onChange={(e) => updatePaymentType(e, props.original.id, props.value)}
        value={props.value}
      >
        <option value="annually">Anually</option>
        <option value="monthly">Monthly</option>
      </NativeSelect>
    ),
  },
  {
    Header: 'Discount',
    accessor: 'discount',
    minWidth: 170,
    maxWidth: 220,
    Cell: (props) => (
      <Column>
        <div>
          <TextField
            type="number"
            style={{ width: '40px' }}
            key={props.original.id}
            id={`${props.original.id}`}
            ref={(input) => setRef(input, props.original.id)}
            defaultValue={props.value}
          />
          <span className="account-list-currency"> EUR </span>
          <RaisedButton
            onClick={(e) => setIndividualDiscount(e, props.original.id)}
            label="UPDATE"
            backgroundColor="#00559D"
            labelColor="#ffffff"
            className="table-button-update"
          />
        </div>
      </Column>
    ),
  },
  {
    Header: 'Paid until',
    accessor: 'planPaidUntil',
    Cell: (props) => <span>{formatDate(props.value)}</span>,
    minWidth: 85,
    maxWidth: 120,
  },
  {
    Header: 'Payment date',
    accessor: 'paymentDate',
    Cell: (props) => <span>{formatDate(props.value)}</span>,
    minWidth: 85,
    maxWidth: 120,
  },
  {
    accessor: 'id',
    Cell: (props) => (
      <div className="table-buttons">
        <DownloadEntityButton handleDownload={() => handleDialog(props.value)} />
        <DeleteEntityButton handleDelete={() => openDialog(() => deleteSchool(props.value))} />
      </div>
    ),
    maxWidth: 70,
  },
];

export default accountListColumns;
