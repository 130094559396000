import React from 'react';
import PropTypes from 'prop-types';
import { getUploadModalExplainText } from '../../utils/helpers';

const ExplainStep = ({ templateName }) => (
  <div className="step">
    <span className="step-no">2</span>
    <span className="step-header">
      Step 2 - Fill in the template with the relevant information
    </span>
    <div className="step-content">
      <div className={`explain-image explain-image--${templateName}`} />
      <div className="explain-note">{getUploadModalExplainText(templateName)}</div>
    </div>
  </div>
);

ExplainStep.propTypes = {
  templateName: PropTypes.string,
};

export default React.memo(ExplainStep);
