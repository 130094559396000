import { schema } from 'normalizr';

const instructorSalarySchema = new schema.Entity('salary', { idAttribute: 'id' });
const accountSchema = new schema.Entity('accounts', { idAttribute: 'id' });
const lessonSchema = new schema.Entity('lessons', { idAttribute: 'id' });

instructorSalarySchema.define({
  accounts: new schema.Array(accountSchema),
  lessons: new schema.Array(lessonSchema),
});

export default instructorSalarySchema;
