import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import {
  map,
  includes,
  forEach,
  find,
} from 'lodash';
import MinusButton from './MinusButton';

const RenderSelect = ({
  selector,
  handleSelectChange,
  handleMinusButton,
  handleAddButton,
  languageOptions,
  languageLevelsOptions,
  languages,
  deletedLanguages,
  meta: { touched, error },
}) => {
  const [languageValues, languageLevelValues] = useMemo(() => {
    const values = [];
    const levelValues = [];
    forEach(languages, (language, index) => {
      if (selector.languages && selector.languages[index] && selector.languages[index].code) {
        if (selector.languages[index].code.value) {
          values.push(selector.languages[index].code);
        } else {
          values.push(find(languageOptions,
            (option) => option.value === selector.languages[index].code));
        }
      }
      if (selector.languages && selector.languages[index] && selector.languages[index].level) {
        if (selector.languages[index].level.value) {
          levelValues.push(selector.languages[index].level);
        } else {
          levelValues.push(find(languageLevelsOptions,
            (option) => option.value === selector.languages[index].level));
        }
      }
    });
    return [values, levelValues];
  }, [selector.languages, languages]);

  return (
    <div className="client-manager-form__input-wrapper w400 language-select">
      <label className="client-manager-form__label">LANGUAGE / LEVEL</label>
      {map((languages), (language, index) => (includes(deletedLanguages, index) ? null
        : (
          <div key={index}>
            <div key={`la${index}`}>
              <div className="client-manager-form__input-wrapper w145">
                <div className="entity-form__language-input-tiny">
                  <Select
                    name={`languages[${index}].code`}
                    clearable={false}
                    options={languageOptions}
                    value={languageValues[index]}
                    onChange={(value) => handleSelectChange(`languages[${index}].code`, value)}
                  />
                </div>
              </div>
              <div className="client-manager-form__input-wrapper w145">
                <div className="entity-form__language-input-tiny">
                  <Select
                    simplevalue
                    name={`languages[${index}].level`}
                    clearable={false}
                    options={languageLevelsOptions}
                    value={languageLevelValues[index]}
                    onChange={(value) => handleSelectChange(`languages[${index}].level`, value)}
                  />
                </div>
              </div>
              {index !== 0
              && (
                <MinusButton
                  className="floatPosition"
                  onClick={() => handleMinusButton('language', index)}
                />
              )}
            </div>
          </div>
        )))}
      <span className="error">
        {' '}
        {touched && error && <span>{error}</span>}
      </span>
      <FloatingActionButton
        id="floatingButton"
        className="client-manager-form__plus-button"
        mini
        type="button"
        backgroundColor="#01579B"
        onClick={() => handleAddButton('language')}
      >
        <ContentAdd id="addLang" />
      </FloatingActionButton>
    </div>
  );
};

RenderSelect.propTypes = {
  selector: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      level: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    })),
  }),
  handleSelectChange: PropTypes.func,
  handleMinusButton: PropTypes.func,
  handleAddButton: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  languageOptions: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  languageLevelsOptions: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  languages: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  deletedLanguages: PropTypes.array,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default React.memo(RenderSelect);
