import React from 'react';
import SvgIcon from 'material-ui/SvgIcon';

const InActiveIcon = (props) => (
  <SvgIcon {...props} style={{ cursor: 'pointer' }}>
    <polygon
      id="Shape"
      fill="#253D63"
      fillRule="nonzero"
      points="4 11 20 11 20 13 4 13"
    />
  </SvgIcon>
);
InActiveIcon.displayName = 'InActiveIcon';
InActiveIcon.muiName = 'SvgIcon';

export default React.memo(InActiveIcon);
