import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Checkbox } from 'material-ui';
import _, {
  filter,
  orderBy,
  forEach,
  map,
  get,
  includes,
} from 'lodash';
import moment from 'moment';
import Popover from 'material-ui/Popover';
import AvailabilityListRow from '../components/AvailabilityListRow';
import AddEntityButton from '../components/AddEntityButton';
import AvailabilityForm from './AvailabilityForm';
import Timeline from './Timeline';
import { createAvailability, deleteAvailability, editAvailability } from '../actions/availabilityActions';
import { showDrawer, hideDrawer } from '../actions/drawerActions';
import { fetchEvents, clear } from '../actions/eventActions';
import { fetchInstructor, fetchInstructors } from '../actions/instructorsActions';
import { notifySuccess } from '../actions/notificationActions';
import Component from '../components/Component';
import TodaysCalendarButton from '../components/TodaysCalendarButton';
import MassActionsComponent from '../components/MassActionsComponent';
import GroupForm from './GroupForm';
import BookingForm from './BookingForm';
import GroupView from './GroupView';

import { fetchProducts } from '../actions/productsActions';
import { fetchClients } from '../actions/clientsActions';
import { fetchActivities } from '../actions/activitiesActions';
import { fetchSpecialities } from '../actions/specialityActions';
import { startLoading, stopLoading } from '../actions/refreshIndicatorActions';
import { fetchGroupPrice, fetchGroupByGuid } from '../actions/groupsActions';
import { formatMessageFromDiscardedAvailabilities } from '../utils/format';
import { convertToUTC } from '../utils/dateTime';
import InstructorTimeline from './InstructorTimeline/InstructorTimeline';
import { activateGuide } from '../actions/userGuide';
import { fetchBooking } from '../actions/bookingActions';

import '../styles/InstructorList.scss';
import '../styles/AvailabilityList.scss';

import CalendarItemPopover from '../components/CalendarItemPopover';
import { DateField } from '../components/Field';

class InstructorAvailabilityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAvailabilities: [],
      massActions: [
        { text: 'Delete', value: 0 },
      ],
      windowWidth: window.innerWidth,
      popoverProps: {},
      anchorEl: undefined,
      popoverOpen: false,
    };
  }

  componentDidMount() {
    const {
      user,
      match: { params },
      userGuide,
      actions,
    } = this.props;
    const { general, currentGuide, step } = userGuide;
    const id = user.role === 'instructor' ? user.id : params.id;

    actions.fetchClients();

    if (user.role === 'manager' || user.role === 'instructor') {
      actions.fetchInstructor(id);
      actions.fetchEvents({ instructors: [id] });
    }
    if (user.role !== 'manager') {
      actions.fetchProducts();
      actions.fetchActivities();
      actions.fetchSpecialities();
      if (user.role === 'headCoach') {
        actions.fetchInstructors();
        actions.fetchEvents({});
      }
    }

    if (general && currentGuide === 'instructors' && step === 7) {
      setTimeout(() => actions.activateGuide('availabilities', true), 500);
    }
    if (user.role === 'instructor') {
      window.addEventListener('resize', this.updateWindowDimensions);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      userGuide,
      instructor,
      user: { id },
    } = nextProps;
    const { userGuide: currentUserGuide } = this.props;
    if (!currentUserGuide.triggerAvailabilityFormOpen && userGuide.triggerAvailabilityFormOpen) {
      this.handleAddButton();
    }
    if (id && instructor && instructor[id] && instructor[id].createBookingPermission) {
      this.setState({ createBookingPermission: true });
    }
  }

  componentWillUnmount() {
    const { actions, user } = this.props;
    actions.hideDrawer();
    actions.clear();

    if (user.role === 'instructor') {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  availabilitiesToRows = (availabilities, dateFrom, dateTo) => {
    const { user } = this.props;

    const orderedAvailabilities = orderBy(availabilities, ['timeFrom', 'timeTo'], 'asc');
    const availabilitiesData = [];
    forEach(orderedAvailabilities, (availability) => {
      const availabilityId = availability.id.split('-');
      const dateFromValidation = moment.utc(availability.timeFrom)
        .isSameOrAfter(moment(dateFrom), 'day');
      const dateToValidation = (!dateTo
        || moment.utc(availability.timeTo)
          .isSameOrBefore(moment(dateTo), 'day'));
      if (availabilityId[0] === 'A' && dateFromValidation && dateToValidation) {
        const availabilityDateFrom = availability.timeFrom ? moment.utc(availability.timeFrom).format('DD/MMM/YYYY') : '';
        const availabilityDateTo = availability.timeTo ? moment.utc(availability.timeTo).format('DD/MMM/YYYY') : '';
        const timeFrom = availability.timeFrom ? moment.utc(availability.timeFrom).format('HH:mm') : '';
        const timeTo = availability.timeTo ? moment.utc(availability.timeTo).format('HH:mm') : '';
        const resort = availability.resortName ? availability.resortName : '';
        const { id } = availability;
        const { instructor } = availability;
        const availabilityData = {
          dateFrom: availabilityDateFrom,
          dateTo: availabilityDateTo,
          timeFrom,
          timeTo,
          resort,
          id,
        };
        if (user.role === 'manager') {
          availabilitiesData.push(availabilityData);
        } else if (user.role === 'instructor') {
          availabilitiesData.push(availabilityData);
        } else if (user.role === 'headCoach' && user.id === instructor) {
          availabilitiesData.push(availabilityData);
        }
      }
    });

    return availabilitiesData;
  }

  handleCreateButton = (body) => {
    const { actions, match, user } = this.props;
    let instructorId = user.role === 'manager' ? match.params.id : user.id;
    instructorId = user.role === 'headCoach' ? null : instructorId;

    return actions.createAvailability(body).then(() => {
      if (instructorId) {
        actions.fetchEvents({ instructors: [instructorId] });
      } else {
        actions.fetchEvents();
      }
      actions.hideDrawer();
      actions.notifySuccess({}, 'Availability created successfully');
    }).catch((err) => err);
  }

  handleUpdateButton = (id, body) => {
    const { actions, match, user } = this.props;
    let instructorId = user.role === 'manager' ? match.params.id : user.id;
    instructorId = user.role === 'headCoach' ? null : instructorId;

    return actions.editAvailability(id, body).then(() => {
      if (instructorId) {
        actions.fetchEvents({ instructors: [instructorId] });
      } else {
        actions.fetchEvents();
      }
      actions.hideDrawer();
      actions.notifySuccess({}, 'Availability updated successfully');
    }).catch((err) => err);
  }

  handleDelete = (id) => {
    const {
      actions, match, user, events,
    } = this.props;
    let instructorId = user.role === 'manager' ? match.params.id : user.id;
    instructorId = user.role === 'headCoach' ? null : instructorId;
    let body = {};
    if (id === '*') {
      body = {
        ids: this.formatMultipleDeletionPayload(),
      };
    } else {
      body = {
        ids: [parseInt(id, 10)],
      };
    }
    actions.deleteAvailability(body).then((res) => {
      Promise.all([
        instructorId && actions.fetchEvents({ instructors: [instructorId] }),
        !instructorId && actions.fetchEvents(),
      ]).then(() => {
        const discarded = get(res, 'payload.result.availability.undefined.discarded');
        let msg = 'Availability deleted successfully';
        if (discarded.length) {
          const discardedAvailabilities = filter(events, (e) => includes(discarded, parseInt(e.id.split('-')[1], 10)));
          msg = formatMessageFromDiscardedAvailabilities(discardedAvailabilities);
        }
        actions.notifySuccess({}, msg);
      });
    });
  }

  handleAddButton = () => {
    const { actions, match, user } = this.props;
    const instructorId = user.role === 'manager' ? match.params.id : user.id;

    actions.showDrawer(AvailabilityForm, {
      initialValues: {
        instructor: instructorId,
        editing: false,
      },
      onSubmit: this.handleCreateButton,
    });
  }

  handleEdit = (body) => {
    const {
      actions, match, user, events,
    } = this.props;
    const { id } = body;
    const instructorId = user.role === 'manager' ? match.params.id : user.id;

    actions.showDrawer(AvailabilityForm, {
      initialValues: {
        instructor: instructorId,
        type: events[id].type,
        dateFrom: events[id].timeFrom,
        dateTo: events[id].timeTo,
        timeFrom: events[id].timeFrom,
        timeTo: events[id].timeTo,
        resort: { label: events[id].resortName, value: events[id].resort },
        editing: true,
        id: id.split('-')[1],
      },
      onSubmit: this.handleUpdateButton,
    });
  }

  onItemDoubleClick = (item) => {
    const { user: { role } } = this.props;
    const id = _.get(item, 'id', item).split('-');
    const type = id[0];

    switch (type) {
      case 'L':
        this.openDrawerForm(() => this.fromBooking(item.booking), BookingForm);
        break;
      case 'A':
        this.openDrawerForm(() => this.fromAvailability(item), BookingForm);
        break;
      case 'G':
        if (role === 'headCoach') {
          this.openDrawerForm(() => this.fromGroup(item.guid, id[1]), GroupForm);
        }
        break;
      default:
    }
  }

  openDrawerForm = (prepopulationStrategy, container) => {
    const { actions } = this.props;

    actions.startLoading('drawer');
    actions.showDrawer(false, {});
    prepopulationStrategy().then((values) => {
      actions.stopLoading('drawer');
      actions.showDrawer(container, values);
    });
  }

  onTodaysCalendarButtonClick = () => {
    const timeFrom = moment().add(-12, 'hour');
    const timeTo = moment().add(12, 'hour');

    this.timeline.updateScrollCanvas(timeFrom.valueOf(), timeTo.valueOf());
  }

  onGroupOpen = (event) => {
    const { actions } = this.props;
    const id = event.id.split('-')[1];
    actions.showDrawer(GroupView, {
      group: {
        id,
        guid: event.guid,
        name: event.name,
        level: event.level,
        fullId: event.id,
      },
      onClose: this.onGroupViewClose,
    });
  }

  onGroupViewClose = () => {
    const { actions } = this.props;
    const f = localStorage.getItem('filter') || {};

    actions.fetchEvents(JSON.parse(f));
  }

  getInstructorNameSurname = () => {
    const { user, instructor, match: { params } } = this.props;
    let currentInstructor = {};

    if (user.role === 'manager') {
      currentInstructor = instructor[params.id];
    } else {
      currentInstructor = instructor[user.id];
    }
    if (currentInstructor) {
      return `${currentInstructor.name} ${currentInstructor.surname}`;
    }
    return '';
  }

  handleSelection = (id) => {
    const { selectedAvailabilities } = this.state;
    let selected = selectedAvailabilities;

    if (includes(selected, id)) {
      selected = filter(selected, (_id) => _id !== id);
    } else {
      selected.push(id);
    }
    this.setState({
      selectedAvailabilities: selected,
    });
  }

  handleMultipleSelection = () => {
    const { events, formValues } = this.props;
    const { selectedAvailabilities } = this.state;

    const dateFromParam = formValues && formValues.dateFrom
      ? moment(convertToUTC(formValues.dateFrom)) : moment(convertToUTC(new Date()));
    const dateToParam = formValues && formValues.dateTo
      ? moment(convertToUTC(formValues.dateTo)) : undefined;
    const updatedAvailabilities = this.availabilitiesToRows(events, dateFromParam, dateToParam);

    if (selectedAvailabilities.length !== updatedAvailabilities.length) {
      this.setState({
        selectedAvailabilities: map(updatedAvailabilities, 'id'),
      });
    } else {
      this.setState({
        selectedAvailabilities: [],
      });
    }
  }

  formatMultipleDeletionPayload = () => {
    const { selectedAvailabilities } = this.state;

    return map(selectedAvailabilities, (a) => parseInt(a.split('-')[1], 10));
  }

  handleTimelineRef = (timeline) => {
    this.timeline = timeline;
  }

  onAddBookingButtonClick = () => {
    this.openDrawerForm(() => this.fromFilter(JSON.parse(localStorage.filter || '{}')), BookingForm);
  }

  onItemContextMenu = (id) => {
    const { events } = this.props;
    const type = id.split('-')[0];

    if (type === 'L') {
      const item = _.find(events, { id });
      const domNode = document.getElementById(id);
      this.handlePopoverState(domNode);
      this.setState({ popoverProps: item });
    }
  };

  handlePopoverState = (anchorEl = undefined) => {
    const { popoverOpen } = this.state;

    this.setState({ popoverOpen: !popoverOpen });
    if (anchorEl) {
      this.setState({ anchorEl });
    }
  };

  handlePopoverClose = () => {
    this.setState({
      popoverOpen: false,
    });
  }

  render() {
    const {
      events,
      user: { role, id },
      instructor,
      formValues,
      openDialog,
    } = this.props;
    const {
      selectedAvailabilities,
      massActions,
      windowWidth,
      createBookingPermission,
      popoverProps,
      popoverOpen,
      anchorEl,
    } = this.state;
    const dateFromParam = formValues && formValues.dateFrom
      ? moment(convertToUTC(formValues.dateFrom)) : moment(convertToUTC(new Date()));
    const dateToParam = formValues && formValues.dateTo
      ? moment(convertToUTC(formValues.dateTo)) : undefined;
    const instructorName = this.getInstructorNameSurname();
    const updatedAvailabilities = this.availabilitiesToRows(events, dateFromParam, dateToParam);
    const multipleCheckboxValue = selectedAvailabilities.length === updatedAvailabilities.length
      && updatedAvailabilities.length !== 0;
    return (
      <div className={`instructor-list instructor-list__${role}`}>
        <div className="availability-list">
          <div className="buttons">
            <h2>
              {instructorName}
            </h2>
            <AddEntityButton
              label="ADD AVAILABILITY"
              onClick={this.handleAddButton}
            />
            { role !== 'manager' && createBookingPermission
            && (
            <AddEntityButton
              label="Add booking"
              onClick={this.onAddBookingButtonClick}
            />
            )}
            {(role === 'instructor' || role === 'headCoach')
            && (
            <TodaysCalendarButton
              onClick={this.onTodaysCalendarButtonClick}
            />
            )}
          </div>
          {role === 'headCoach' && (
          <div className="instructor-list__timeline">
            <div className="pad-y-20">
              <Timeline
                handleTimelineRef={this.handleTimelineRef}
                instructors={orderBy(filter(instructor, (i) => i.active), 'weight', 'asc')}
                role={role}
                events={events}
                onItemDoubleClick={this.onItemDoubleClick}
                onGroupOpen={this.onGroupOpen}
                onItemContextMenu={this.onItemContextMenu}
              />
              <Popover
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                onRequestClose={this.handlePopoverClose}
              >
                <CalendarItemPopover {...popoverProps} />
              </Popover>
            </div>
          </div>
          )}
          {role === 'instructor' && windowWidth > 992
          && (
          <div className="instructor-list__timeline">
            <div className="pad-y-20">
              <Timeline
                handleTimelineRef={this.handleTimelineRef}
                instructors={filter(instructor, (i) => i.id === id)}
                role={role}
                events={events}
                onItemDoubleClick={this.onItemDoubleClick}
                onGroupOpen={this.onGroupOpen}
                onItemContextMenu={this.onItemContextMenu}
              />
              <div
                id="reserved-bookings-anchor"
                style={{
                  width: '1px', height: '1px', position: 'relative', left: '60vh', bottom: '53px',
                }}
              />
              <Popover
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                onRequestClose={this.handlePopoverClose}
              >
                <CalendarItemPopover {...popoverProps} />
              </Popover>
            </div>
          </div>
          )}
          {role === 'instructor' && windowWidth <= 992
          && (
          <InstructorTimeline
            events={events}
            onItemDoubleClick={this.onItemDoubleClick}
            onGroupOpen={this.onGroupOpen}
          />
          )}
          <div className="availability-list-mass-actions">
            <div className="row-box no-margin">
              <Checkbox
                onCheck={this.handleMultipleSelection}
                checked={multipleCheckboxValue}
                style={{ width: '5%' }}
              />
            </div>
            <div className="row-box mass-action">
              <MassActionsComponent
                massActions={massActions}
                detectMassAction={() => {}}
                handleMassAction={() => openDialog(() => this.handleDelete('*'))}
              />
            </div>
            <div className="date-picker-wrapper">
              <div className="form-field">
                <label className="l1">Date From:</label>
                <div className="date-input">
                  <DateField
                    name="dateFrom"
                    className="date"
                    underlineStyle={{ display: 'none' }}
                  />
                </div>
              </div>
              <div className="form-field">
                <label className="l1">Date To:</label>
                <div className="date-input">
                  <DateField
                    name="dateTo"
                    className="date"
                    minDate={formValues && formValues.dateFrom
                      ? new Date(formValues.dateFrom) : undefined}
                    underlineStyle={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: 'relative', left: '50%', bottom: '130px' }} id="availability-anchor" />
          {map(updatedAvailabilities, (availability, index) => (
            <AvailabilityListRow
              key={index}
              availability={availability}
              handleDelete={() => {
                openDialog(() => this.handleDelete(availability.id.split('-')[1]));
              }}
              handleEdit={() => this.handleEdit(availability)}
              handleCheck={this.handleSelection}
              selected={selectedAvailabilities}
            />
          ))}
        </div>
      </div>
    );
  }
}

const formConfig = {
  form: 'availabilityFilter',
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    createAvailability,
    editAvailability,
    deleteAvailability,
    showDrawer,
    hideDrawer,
    fetchEvents,
    clear,
    fetchInstructor,
    fetchInstructors,
    notifySuccess,
    startLoading,
    stopLoading,
    fetchProducts,
    activateGuide,
    fetchClients,
    fetchActivities,
    fetchSpecialities,
    fetchBooking,
    fetchGroupPrice,
    fetchGroupByGuid,
  }, dispatch),
});

const mapStateToProps = (state) => ({
  events: state.events,
  availabilities: state.entities.availability,
  instructor: state.entities.instructor,
  user: state.user,
  userGuide: state.userGuide,
  activity: state.entities.activity,
  speciality: state.entities.speciality,
  formValues: getFormValues('availabilityFilter')(state),
});

InstructorAvailabilityList.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  userGuide: PropTypes.shape({
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
    triggerAvailabilityFormOpen: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    createAvailability: PropTypes.func,
    editAvailability: PropTypes.func,
    deleteAvailability: PropTypes.func,
    showDrawer: PropTypes.func,
    hideDrawer: PropTypes.func,
    fetchEvents: PropTypes.func,
    clear: PropTypes.func,
    fetchInstructor: PropTypes.func,
    fetchInstructors: PropTypes.func,
    notifySuccess: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
    fetchProducts: PropTypes.func,
    activateGuide: PropTypes.func,
    fetchClients: PropTypes.func,
    fetchActivities: PropTypes.func,
    fetchSpecialities: PropTypes.func,
    fetchBooking: PropTypes.func,
    fetchGroupPrice: PropTypes.func,
    fetchGroupByGuid: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  instructor: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.object,
  formValues: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    dateFrom: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    dateTo: PropTypes.object,
  }),
  openDialog: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm(formConfig)(InstructorAvailabilityList),
);
