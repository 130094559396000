import React from 'react';
import PropTypes from 'prop-types';

const RenderTextArea = ({
  input,
  placeholder,
  disabled,
  note,
  meta: { touched, error },
}) => (
  <div className="field field__textarea-wrapper">
    <textarea
      {...input}
      className="textarea"
      placeholder={placeholder}
      disabled={disabled}
    />
    {note && <small className="note note--textarea">{note}</small>}
    <span className="error error--textarea">
      {' '}
      {touched && error && <span>{error}</span>}
    </span>
  </div>
);

RenderTextArea.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  note: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default React.memo(RenderTextArea);
