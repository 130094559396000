import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../styles/AddEntityButton.scss';

const AddEntityButton = ({
  link,
  path,
  label,
  onClick,
  className,
  id,
  disabled,
}) => {
  const tempClassName = useMemo(() => className || 'add-entity-button__theme--blue', [className]);

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div className={tempClassName} id={id}>
      { !link ? (
        <div
          className="label"
          onClick={handleClick}
        >
          { label }
        </div>
      ) : (
        <Link
          className="link"
          to={{ pathname: path }}
        >
          { label }
        </Link>
      )}
    </div>
  );
};

AddEntityButton.propTypes = {
  link: PropTypes.bool,
  path: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(AddEntityButton);
