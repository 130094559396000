import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import serviceSchema from '../schema/Service';

export const fetchServices = () => httpRequest({
  method: 'GET',
  path: '/service',
  types: [
    'SERVICES_GET_REQUEST',
    'SERVICES_GET_SUCCESS',
    'SERVICES_GET_FAILURE',
  ],
  schema: new schema.Array(serviceSchema),
});

export const fetchService = (id) => httpRequest({
  method: 'GET',
  path: `/service/${id}`,
  types: [
    'SERVICE_GET_REQUEST',
    'SERVICE_GET_SUCCESS',
    'SERVICE_GET_FAILURE',
  ],
  schema: serviceSchema,
});

export const updateService = (body, id) => httpRequest({
  method: 'PUT',
  path: `/service/${id}`,
  body,
  types: [
    'SERVICE_PUT_REQUEST',
    'SERVICE_PUT_SUCCESS',
    'SERVICE_PUT_FAILURE',
  ],
  schema: serviceSchema,
});

export const createService = (body) => httpRequest({
  method: 'POST',
  path: '/service',
  body,
  types: [
    'SERVICE_POST_REQUEST',
    'SERVICE_POST_SUCCESS',
    'SERVICE_POST_FAILURE',
  ],
  schema: serviceSchema,
});

export const deleteService = (id) => httpRequest({
  method: 'DELETE',
  path: `/service/${id}`,
  types: [
    'SERVICE_DELETE_REQUEST',
    'SERVICE_DELETE_SUCCESS',
    'SERVICE_DELETE_FAILURE',
  ],
  schema: serviceSchema,
});

export const deleteServices = (body) => httpRequest({
  method: 'POST',
  body,
  path: '/service/delete',
  types: [
    'SERVICES_DELETE_REQUEST',
    'SERVICES_DELETE_SUCCESS',
    'SERVICES_DELETE_FAILURE',
  ],
  schema: serviceSchema,
  clear: true,
});

export const importServices = (body) => httpRequest({
  method: 'POST',
  body,
  path: '/service/import',
  types: [
    'SERVICES_IMPORT_POST_REQUEST',
    'SERVICES_IMPORT_POST_SUCCESS',
    'SERVICES_IMPORT_POST_FAILURE',
  ],
  schema: null,
});

export const servicesExport = () => httpRequest({
  method: 'GET',
  path: '/service/export',
  types: [
    'SERVICE_EXPORT_GET_REQUEST',
    'SERVICE_EXPORT_GET_SUCCESS',
    'SERVICE_EXPORT_GET_FAILURE',
  ],
  schema: null,
});
