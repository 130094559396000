import React from 'react';
import PropTypes from 'prop-types';
import * as Field from './Field';

const RenderIndividualBuyer = ({
  fields,
  value,
  disabled,
  options,
}) => (
  <div>
    {fields.map((buyer, index) => (
      <Field.Select
        key={index}
        simpleValue
        fieldLabel="Level:"
        name={`${buyer}.level`}
        options={options}
        value={value || null}
        disabled={disabled}
        theme="white"
        clearable
      />
    ))}
  </div>
);

RenderIndividualBuyer.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
  }),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
};

export default React.memo(RenderIndividualBuyer);
