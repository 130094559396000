import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../styles/MassActions.scss';

class MassActionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  componentDidMount() {
    const { detectMassAction } = this.props;
    const { value } = this.state;
    detectMassAction(value);
  }

    handleChange = (event) => {
      const { detectMassAction } = this.props;
      this.setState({ value: event.target.value });
      detectMassAction(event.target.value);
    };

    render() {
      const { handleMassAction, massActions } = this.props;
      const { value } = this.state;
      return (
        <div className="mass-actions">
          Mass actions:
          <select
            value={value}
            onChange={this.handleChange}
            className="mass-actions__dropdown-menu"
          >
            {_.map(massActions, (item, index) => (
              <option className="menu-item" key={index} value={index}>
                {item.text}
              </option>
            ))}
          </select>
          <button
            className="mass-actions__button"
            type="button"
            onClick={handleMassAction}
          >
            CONFIRM
          </button>
        </div>
      );
    }
}

MassActionsComponent.propTypes = {
  detectMassAction: PropTypes.func,
  handleMassAction: PropTypes.func,
  massActions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
  })),
};

export default MassActionsComponent;
