import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserGuide } from '../utils/helpers';
import { activateGuide } from '../actions/userGuide';

class UserGuideIcon extends React.Component {
  handleGuideButton = (userGuide) => {
    const { activateGuideA, type, step } = this.props;
    const s = step || 2;
    if (type) {
      activateGuideA(type, false, s);
    } else {
      activateGuideA(userGuide);
    }
  };

  render() {
    const {
      type,
      user,
      style,
    } = this.props;
    const userGuide = getUserGuide(window.location.pathname);

    return (
      <span>
        {(userGuide || type) && user.role === 'manager'
        && (
          <span
            onClick={() => this.handleGuideButton(userGuide)}
            className="help-icon"
            style={{
              cursor: 'pointer', color: '#4286f4', marginRight: '3%', ...style,
            }}
          />
        )}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  activateGuideA: bindActionCreators(activateGuide, dispatch),
});

UserGuideIcon.propTypes = {
  activateGuideA: PropTypes.func,
  type: PropTypes.string,
  step: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGuideIcon);
