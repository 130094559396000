import _ from 'lodash';

export const filterProductsByResort = (products, resort) => _.filter(_.values(products),
  (product) => _.find(product.resorts, (item) => item.id === resort || item.id === resort.id));

export const filterGroupsByGuid = (groups, group) => _.filter(groups,
  (item) => item.guid === group);

export const filterLevelsByWeight = (level) => {
  const levels = [
    { name: 'First Steps', id: 'first_steps' },
    { name: 'Beginner', id: 'beginner' },
    { name: 'Intermediate', id: 'intermediate' },
    { name: 'Advanced', id: 'advanced' },
  ];
  const index = levels.findIndex((obj) => obj.id === level);

  if (index > -1) {
    return levels.slice(0, index + 1);
  }

  return levels;
};

export const filterProducts = (products, filter) => {
  let productsFiltered = _.filter(products, ['timeRange', true]);
  const specialityFilter = _.get(filter, 'speciality.id', null);
  const levelFilter = _.get(filter, 'level.id', null);
  const resortFilter = [];

  productsFiltered = _.filter(productsFiltered, ['export', true]);
  _.forEach(_.get(filter, 'resort'), (resort) => {
    resortFilter.push(resort.id);
  });
  if (resortFilter.length) {
    productsFiltered = _.filter(productsFiltered, (product) => {
      let returnIndicator = false;
      _.forEach(product.resorts, (resort) => {
        if (_.includes(resortFilter, resort.id)) {
          returnIndicator = true;
        }
      });
      return returnIndicator;
    });
  }
  if (specialityFilter) {
    productsFiltered = _.filter(productsFiltered, ['speciality', specialityFilter]);
  }
  if (levelFilter) {
    productsFiltered = _.filter(productsFiltered, ['level', levelFilter]);
  }

  return _.map(productsFiltered, (product) => ({
    value: product.id,
    label: product.name,
    checked: true,
  }));
};

export const filterActiveInstructors = (instructors) => _.filter(instructors,
  (instructor) => instructor.active);
