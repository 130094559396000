import { schema } from 'normalizr';
// eslint-disable-next-line import/no-cycle
import partnerSchema from './Partner';

const commissionSchema = new schema.Entity('commission', { idAttribute: 'id' });

commissionSchema.define({
  partner: partnerSchema,
});

export default commissionSchema;
