import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../styles/AuthoredNav.scss';

const AuthoredNav = () => (
  <div className="authored-nav">
    <h2 className="authored-nav__title">Menu</h2>
    <div className="authored-nav__wrapper">
      <Link to="/calendar" className="authored-nav__link-calendar"><span>Booking Calendar</span></Link>
      <Link to="/bookings" className="authored-nav__link-bookings"><span>Bookings</span></Link>
      <Link to="/instructors" className="authored-nav__link-instructors" id="instructors-anchor"><span>Instructors</span></Link>
      <Link to="/instructors-salary" className="authored-nav__link-noicon"><span>Instructors Salary</span></Link>
      <Link to="/products" className="authored-nav__link-lesson-types" id="products-anchor"><span>Products</span></Link>
      <Link to="/services" className="authored-nav__link-noicon" id="additional-service-anchor"><span>Additional services</span></Link>
      <Link to="/groups" className="authored-nav__link-groups"><span>Groups</span></Link>
      <Link to="/partners" className="authored-nav__link-managers"><span>Partners</span></Link>
      <Link to="/managers" className="authored-nav__link-noicon"><span>Managers</span></Link>
      <Link to="/clients" className="authored-nav__link-clients" id="clients-anchor"><span>Clients</span></Link>
      <Link to="/statistics" className="authored-nav__link-statistics" id="school-settings-anchor"><span>Statistics</span></Link>
      <Link to="/school" className="authored-nav__link-settings" id="school-settings-anchor"><span>School settings</span></Link>
      <Link to="/account" className="authored-nav__link-noicon" id="account-information-anchor"><span>Account information</span></Link>
      <Link to="/hotels" className="authored-nav__link-noicon" id="hotels-anchor"><span>Hotels</span></Link>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(React.memo(AuthoredNav));
