import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../styles/ActivityLabel.scss';

const LabelActivity = ({ specialities }) => (
  <div className="speciality-list-label">
    {_.map(specialities, (index, key) => (
      <span key={key + index} className="speciality">{key}</span>
    ))}
  </div>
);

LabelActivity.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  specialities: PropTypes.object,
};

export default React.memo(LabelActivity);
