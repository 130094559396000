import React from 'react';
import PropTypes from 'prop-types';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentRemove from 'material-ui/svg-icons/content/remove';
import { get } from 'lodash';

class FloatingContentBlock extends React.Component {
  constructor(props) {
    super(props);

    const { openedByDefault } = props;

    this.state = {
      opened: openedByDefault,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const bookingServices = get(props, 'children.props.bookingServices');
    const updatedState = { ...state };

    if (bookingServices && bookingServices.length === 0 && !props.preopen) {
      updatedState.opened = false;
    }

    if (props.preopen) {
      updatedState.opened = true;
    }
    return updatedState;
  }

  onClick = () => {
    const { beforeOpen } = this.props;
    const { opened } = this.state;
    if (beforeOpen) {
      beforeOpen();
    }
    this.setState({
      opened: !opened,
    });
  }

  render() {
    const { disabled, label, children } = this.props;
    const { opened } = this.state;
    return (
      <div className="booking-form-additional">
        <span id="special-needs-anchor" />
        <FloatingActionButton
          mini
          className="floating-button"
          backgroundColor="#01579B"
          onClick={this.onClick}
          disabled={disabled}
        >
          { opened ? <ContentRemove /> : <ContentAdd /> }
        </FloatingActionButton>
        <label className="booking-form-additional-labels">
          {label}
        </label>
        { opened && children && React.cloneElement(children, {}) }
      </div>
    );
  }
}

FloatingContentBlock.propTypes = {
  openedByDefault: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.array,
  ]),
  beforeOpen: PropTypes.func,
  disabled: PropTypes.bool,
  preopen: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
};

export default FloatingContentBlock;
