import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { fetchMyAccount } from '../actions/accountActions';
import { notifyWarning } from '../actions/notificationActions';
import invokeURL from '../../config/api-config';
// import { showWarning, hideWarning } from '../actions/usedAmounts';

class StripeWarningDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notified: false,
      stripeWarning: false,
      message1: 'Complete the onboarding on your Stripe account as soon as possible, '
      + 'otherwise your customers can\'t use the card to pay for your services. Onboarding finish ',
      link1:
  <a
    onClick={this.onboardClick}
    style={{
      textDecoration: 'underline',
      color: '#ffeb3b',
      cursor: 'pointer',
    }}
  >
    here.
  </a>,
    };
  }

  componentDidMount() {
    const {
      user: { role },
      fetchMyAccountA,
    } = this.props;
    if (role && role === 'instructor') {
      return;
    }
    fetchMyAccountA();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user: { role } } = nextProps;
    // const { notifyWarningA } = this.props;
    // const { notified, message1, link1 } = this.state;
    const { stripeAccountId } = nextProps;
    const { notified } = this.state;

    if (role === 'instructor') {
      return;
    }
    if (!notified) {
      this.setState({ notified: true });
    }
    if (!stripeAccountId || stripeAccountId.trim() === '') {
      this.setState({ stripeWarning: true });
    }

    // notifyWarningA(
    //   {
    //     autoDismiss: 15,
    //     action: {
    //       label: 'Onboard now',
    //       callback: () => {},
    //     },
    //   },
    //   message1 + link1,
    // );
  }

  onboardClick = () => {
    const { user } = this.props;
    window.location.href = `${invokeURL.invokeUrl}/stripe/onboard/${user.id}`;
  }

  render() {
    const { message1, link1, stripeWarning } = this.state;
    if (!stripeWarning) {
      return '';
    }

    return (
      <div
        id="stripe-warning-display"
        className="stripe-warning-display"
        style={{
          backgroundColor: '#d10000',
          padding: '10px 15px',
          color: '#ffffff',
          textAlign: 'center',
          margin: '0 auto 7px auto',
          width: '90%',
        }}
      >
        { message1 }
        { link1 }
      </div>
    );
  }
}

StripeWarningDisplay.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
  }),
  fetchMyAccountA: PropTypes.func,
  stripeAccountId: PropTypes.string,
  // stripeWarning: PropTypes.bool,
  // notifyWarningA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  // account: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  // hide: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  fetchMyAccountA: bindActionCreators(fetchMyAccount, dispatch),
  notifyWarningA: bindActionCreators(notifyWarning, dispatch),
});

const mapStateToProps = (state) => ({
  // account: state.entities.account,
  user: state.user,
  stripeAccountId: get(state.entities.account, `[${state.user.id}].stripeAccountId`, ''),
  stripeWarning: false,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StripeWarningDisplay));
