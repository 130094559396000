const usedAmounts = (state = {
  warningOn: {},
}, action) => {
  switch (action.type) {
    case 'USED_AMOUNT_GET_SUCCESS': {
      return {
        ...state,
        ...action.payload.result,
      };
    }
    case 'SHOW_LIMITATION_WARNING': {
      const warningOn = {
        ...state.warningOn,
        [action.entityName]: true,
      };

      return {
        ...state,
        warningOn,
      };
    }
    case 'HIDE_LIMITATION_WARNING': {
      const warningOn = {
        ...state.warningOn,
        [action.entityName]: false,
      };

      return {
        ...state,
        warningOn,
      };
    }
    default: {
      return state;
    }
  }
};

export default usedAmounts;
