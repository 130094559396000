import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Field, FieldArray } from 'redux-form';
import { find } from 'lodash';
import RenderField from './RenderField';
import config from '../../config/config';
import {
  email,
  required,
  maxLength255,
  postalCode,
  birthdayValidator,
} from '../utils/validators';
import RenderSelect from './RenderSelect';
import genderOptions from '../constants/GenderOptions';
import RenderPhoneArray from './RenderPhoneArray';
import { DateField } from './Field';

class InstructorTab extends Component {
  constructor(props) {
    super(props);
    const { selector: { currentAddress } } = props;
    this.state = {
      gMapSrc: `https://www.google.com/maps?q=${currentAddress}&key=${config.gmaps_api_key}&output=embed&size=370z135`,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selector } = nextProps;
    const { selector: prevSelector } = this.props;
    if (selector.currentAddress && selector.currentAddress !== prevSelector.currentAddress) {
      this.setState({
        gMapSrc: `https://www.google.com/maps?q=${selector.currentAddress}&key=${config.gmaps_api_key}&output=embed&size=370z135`,
      });
    }
  }

  setMapSrc(ee) {
    const { setSelectorValue } = this.props;
    this.setState({ gMapSrc: `https://www.google.com/maps?q=${ee.target.value}&key=${config.gmaps_api_key}&output=embed&size=370z135` });
    setSelectorValue('currentAddress', ee.target.value);
  }

  render() {
    const {
      selector,
      setSelectorValue,
      nationalities,
      touch,
    } = this.props;
    const { gMapSrc } = this.state;
    return (
      <div>
        <div className="client-manager-form__main-info">
          <div className="client-manager-form__field-group">
            <div className="client-manager-form__input-wrapper">
              <div className="client-manager-form__input">
                <Field
                  type="text"
                  component={RenderField}
                  name="name"
                  labelClass="client-manager-form__label"
                  label="NAME*"
                  instructor
                  validate={[required]}
                />
              </div>
            </div>
            <div className="client-manager-form__input-wrapper">
              <div className="client-manager-form__input">
                <Field
                  type="text"
                  component={RenderField}
                  name="surname"
                  labelClass="client-manager-form__label"
                  label="SURNAME*"
                  validate={[required]}
                />
              </div>
            </div>
            <div className="client-manager-form__input-gender-wrapper">
              <label className="client-manager-form__label">GENDER*</label>
              <Field
                simpleValue
                clearable={false}
                disabled={false}
                name="gender"
                component={RenderSelect}
                options={genderOptions}
                value={selector.gender}
                onChange={(val) => {
                  setSelectorValue('gender', val);
                }}
                validate={[required]}
              />
            </div>
            <div className="client-manager-form__date-wrapper">
              <label className="client-manager-form__label">BIRTHDAY*</label>
              <DateField
                name="dateOfBirth"
                className="date"
                disableError
                maxDate={new Date()}
                onShow={() => touch('dateOfBirth')}
                openToYearSelection
                validate={[birthdayValidator]}
              />
            </div>
          </div>
          <div className="client-manager-form__field-group">
            <div className="client-manager-form__input-wrapper manager-partner-select">
              <label className="client-manager-form__label">NATIONALITY</label>
              <Select
                simpleValue
                name="nationality"
                onChange={(val) => {
                  setSelectorValue('nationality', val);
                }}
                value={selector.nationality && selector.nationality.value ? selector.nationality
                  : find(nationalities,
                    (nationality) => nationality.value === selector.nationality)}
                options={nationalities}
              />
            </div>
            <FieldArray
              name="phoneNumbers"
              component={RenderPhoneArray}
            />
            <div className="client-manager-form__input-wrapper email-long w290">
              <div className="client-manager-form__input w290">
                <Field
                  type="text"
                  component={RenderField}
                  name="email"
                  labelClass="client-manager-form__label"
                  label="EMAIL*"
                  validate={[email, required]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="client-manager-form__field-group">
          <div className="client-manager-form__input-box-wrapper w440">
            <label className="client-manager-form__label-strong">ADDRESS</label>
            <div style={{ marginBottom: '20px' }}>
              <div className="client-manager-form__instructor-address-field">
                <div className="client-manager-form__input">
                  <Field
                    type="text"
                    component={RenderField}
                    name="address.street"
                    labelClass="client-manager-form__label"
                    label="STREET"
                    validate={[maxLength255]}
                  />
                </div>
              </div>
              <div className="client-manager-form__instructor-address-field">
                <div className="client-manager-form__input">
                  <Field
                    type="text"
                    component={RenderField}
                    name="address.city"
                    labelClass="client-manager-form__label"
                    label="CITY"
                    validate={[maxLength255]}
                  />
                </div>
              </div>
              <div className="client-manager-form__instructor-address-field">
                <div className="client-manager-form__input">
                  <Field
                    type="text"
                    component={RenderField}
                    name="address.state"
                    labelClass="client-manager-form__label"
                    label="STATE / PROVINCE"
                    validate={[maxLength255]}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="client-manager-form__instructor-address-field">
                <div className="client-manager-form__input client-manager-form__input--postal-code">
                  <Field
                    type="text"
                    component={RenderField}
                    name="address.postalCode"
                    labelClass="client-manager-form__label"
                    label="POSTAL CODE"
                    validate={[postalCode]}
                  />
                </div>
              </div>
              <div className="client-manager-form__instructor-address-field">
                <div className="client-manager-form__input client-manager-form__input--country">
                  <Field
                    type="text"
                    component={RenderField}
                    name="address.country"
                    labelClass="client-manager-form__label"
                    label="COUNTRY"
                    validate={[maxLength255]}
                  />
                </div>
              </div>
              <div className="client-manager-form_instructor-address-field" />
            </div>

          </div>
          <div className="client-manager-form__input-box-wrapper w300" style={{ marginLeft: '30px' }}>
            <label className="client-manager-form__label-strong">CURRENT LOCATION*</label>
            <iframe
              className="client-manager-form__input-box"
              id={config.gmaps_api_key}
              src={gMapSrc}
              title="Map"
            />

            <div className="client-manager-form__input">
              <Field
                type="text"
                component={RenderField}
                name="currentAddress"
                value={selector.currentAddress}
                placeholder="Fill in current address"
                onChange={(e) => {
                  this.setMapSrc(e);
                }}
                validate={[maxLength255, required]}
              />
            </div>
          </div>
        </div>
      </div>

    );
  }
}

InstructorTab.propTypes = {
  selector: PropTypes.shape({
    currentAddress: PropTypes.string,
    gender: PropTypes.string,
    nationality: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  setSelectorValue: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  nationalities: PropTypes.array,
  touch: PropTypes.func,
};

export default InstructorTab;
