import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import accountSchema from '../schema/Account';

export const fetchMyAccount = () => httpRequest({
  method: 'GET',
  path: '/account',
  types: [
    'ACCOUNT_GET_REQUEST',
    'ACCOUNT_GET_SUCCESS',
    'ACCOUNT_GET_FAILURE',
  ],
  schema: accountSchema,
});

export const fetchAccount = (id) => httpRequest({
  method: 'GET',
  path: `/account/${id}`,
  types: [
    'ACCOUNT_GET_REQUEST',
    'ACCOUNT_GET_SUCCESS',
    'ACCOUNT_GET_FAILURE',
  ],
  schema: accountSchema,
});

export const fetchAccounts = () => httpRequest({
  method: 'GET',
  path: '/~account',
  types: [
    'ACCOUNT_GET_REQUEST',
    'ACCOUNT_GET_SUCCESS',
    'ACCOUNT_GET_FAILURE',
  ],
  schema: new schema.Array(accountSchema),
});

export const createAccount = (body) => httpRequest({
  method: 'POST',
  path: '/account',
  body,
  types: [
    'ACCOUNT_POST_REQUEST',
    'ACCOUNT_POST_SUCCESS',
    'ACCOUNT_POST_FAILURE',
  ],
  schema: accountSchema,
});

export const updateAccount = (id, body) => httpRequest({
  method: 'PUT',
  path: `/account/${id}`,
  body,
  types: [
    'ACCOUNT_PUT_REQUEST',
    'ACCOUNT_PUT_SUCCESS',
    'ACCOUNT_PUT_FAILURE',
  ],
  schema: accountSchema,
});

export const fetchAccountStatistics = (id) => httpRequest({
  method: 'GET',
  path: `/account/${id}/statistics`,
  types: [
    'ACCOUNT_GET_REQUEST',
    'ACCOUNT_GET_SUCCESS',
    'ACCOUNT_GET_FAILURE',
  ],
  schema: accountSchema,
});

export const updateMyAccount = (body) => httpRequest({
  method: 'PUT',
  path: '/account',
  body,
  types: [
    'ACCOUNT_PUT_REQUEST',
    'ACCOUNT_PUT_SUCCESS',
    'ACCOUNT_PUT_FAILURE',
  ],
  schema: accountSchema,
});

export const forgotPassword = (email, domain) => httpRequest({
  method: 'GET',
  path: `/account/password/reset/${domain}/${email}`,
  types: [
    'ACCOUNT_GET_REQUEST',
    'ACCOUNT_GET_SUCCESS',
    'ACCOUNT_GET_FAILURE',
  ],
  schema: accountSchema,
});

export const changePassword = (body) => httpRequest({
  method: 'POST',
  path: '/account/password/new',
  body,
  types: [
    'ACCOUNT_POST_REQUEST',
    'ACCOUNT_POST_SUCCESS',
    'ACCOUNT_POST_FAILURE',
  ],
  schema: accountSchema,
});

export const deleteAccount = (id) => httpRequest({
  method: 'DELETE',
  path: `/account/${id}`,
  types: [
    'ACCOUNT_DELETE_REQUEST',
    'ACCOUNT_DELETE_SUCCESS',
    'ACCOUNT_DELETE_FAILURE',
  ],
  schema: accountSchema,
});

export const downloadInvoices = (id) => httpRequest({
  method: 'GET',
  path: `/planInvoices/${id}`,
  types: [
    'INVOICES_GET_REQUEST',
    'INVOICES_GET_SUCCESS',
    'INVOICES_GET_FAILURE',
  ],
  schema: null,
});

export const downloadInvoiceTemporal = (id) => httpRequest({
  method: 'GET',
  path: `/planInvoice/temporal/${id}`,
  types: [
    'INVOICE_TEMP_GET_REQUEST',
    'INVOICE_TEMP_GET_SUCCESS',
    'INVOICE_TEMP_GET_FAILURE',
  ],
  schema: null,
});

export const clearInvoices = () => ({ type: 'CLEAR_INVOICES' });

export const changeAccountResort = (value) => ({
  type: 'ACCOUNT_CHANGE_RESORT',
  value,
});
