import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import RaisedButton from 'material-ui/RaisedButton';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getPaymentLink } from '../actions/subscriptionPlanActions';
import * as accountActions from '../actions/accountActions';

const indicatorStyle = {
  display: 'inline-block',
  position: 'relative',
};

const buttonStyle = {
  display: 'block',
  position: 'relative',
  bottom: '0',
  padding: '20px 0 0 0',
};

class PlanPaymentRedirection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: '',
      message: '',
      status: 'loading',
    };
  }

  componentDidMount() {
    const { getPaymentLinkA, id, plan } = this.props;
    const p = get(plan, 'id', plan);

    getPaymentLinkA(id, p).then((res) => {
      const link = get(res, 'payload.result.link', undefined);
      this.setState({
        link,
        status: 'hide',
      });
    }).catch((res) => {
      const message = get(res, 'payload.result.message', undefined);
      this.setState({
        message,
        status: 'hide',
      });
    });
  }

  componentWillUnmount() {
    const { account } = this.props;

    return account.fetchMyAccount();
  }

  redirectToPayment = () => {
    const { handleDialog } = this.props;
    const { link } = this.state;

    if (link) {
      const win = window.open(
        link,
        '_blank',
      );
      win.focus();
    }
    handleDialog();
  }

  render() {
    const { link, status, message } = this.state;

    return (
      <div>
        {link ? 'In order to change payment details click the button below.' : message}
        {status === 'hide'
        && (
        <RaisedButton
          onClick={() => this.redirectToPayment()}
          disabled={status !== 'hide'}
          label={link ? 'Proceed to payment' : 'OK'}
          style={buttonStyle}
        />
        )}
        <RefreshIndicator
          size={35}
          left={10}
          top={0}
          status={status}
          style={indicatorStyle}
        />
      </div>
    );
  }
}

PlanPaymentRedirection.propTypes = {
  getPaymentLinkA: PropTypes.func,
  id: PropTypes.number,
  plan: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ]),
  account: PropTypes.shape({
    fetchMyAccount: PropTypes.func,
  }),
  handleDialog: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  getPaymentLinkA: bindActionCreators(getPaymentLink, dispatch),
  account: bindActionCreators(accountActions, dispatch),
});

export default connect(null, mapDispatchToProps)(PlanPaymentRedirection);
