import { schema } from 'normalizr';
import instructorSchema from './Instructor';
import resortSchema from './Resort';

const accountSchema = new schema.Entity('account', { idAttribute: 'id' });

accountSchema.define({
  instructors: new schema.Array(instructorSchema),
  resort: resortSchema,
});

export default accountSchema;
