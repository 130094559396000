import React from 'react';
import Bookmark from 'react-bookmark';
import LoginForm from './LoginForm';
import '../styles/Login.scss';

const Login = () => (
  <div className="form-page__wrapper">
    <div className="form-page__form-wrapper">
      <div className="form-page__form-header">
        <h2 className="form-page__form-heading">Login</h2>
      </div>
      <LoginForm />
      <Bookmark
        className="bookmark"
        href={window.location.href}
        title="SKICMS"
      />
    </div>
  </div>
);

export default Login;
