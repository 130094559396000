import React from 'react';
import PropTypes from 'prop-types';
import _, { filter } from 'lodash';
import moment from 'moment';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { connect } from 'react-redux';
import '../../styles/RegistrationWizard.scss';
import { Checkbox, TextField } from 'redux-form-material-ui';
import {
  Field,
  formValueSelector,
  getFormValues,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import termsAndConditions from '../../agreements/termsAndConditions.pdf';
import privacyPolicy from '../../agreements/privacyPolicy.pdf';
import userAgreement from '../../agreements/userAgreement.pdf';
import * as accountActions from '../../actions/accountActions';
import { fetchPlans, getDiscount } from '../../actions/subscriptionPlanActions';
import { notifyError, notifySuccess } from '../../actions/notificationActions';
import FullscreenAgreementsDialog from '../../components/FullscreenAgreementsDialog';

class ForthPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      termsConditions: false,
      privacyPolicy: false,
      userAgreement: false,
      numPages: null,
      pageNumber: 1,
      fileToOpen: {},
      selectedModal: '',
      modalOpened: false,
      emailRegistered: '',
      success: false,
      status: 'hide',
      priceWithDiscount: undefined,
    };
  }

  componentDidMount() {
    const { actions: { fetchPlansA } } = this.props;

    fetchPlansA();
  }

  handleSubmit = (values) => {
    const {
      firstPage,
      secondPage,
      thirdPage,
      success,
      actions: {
        notifyErrorA,
        account: { createAccount },
      },
    } = this.props;
    const secondPageUpdatedValues = {
      currency: secondPage.currency,
      name: secondPage.name,
      representative: secondPage.representative,
      resort: secondPage.resort.value,
      timezone: secondPage.timezone,
      address: {
        city: secondPage.city,
        country: secondPage.country.label,
        postalCode: secondPage.postal,
        state: secondPage.state,
        street: secondPage.street,
      },
    };
    const payload = {
      ...firstPage,
      ...secondPageUpdatedValues,
      ...thirdPage,
      ...values,
    };

    const currency = payload.currency ? payload.currency.value : null;

    if (!payload.userAgreement) {
      return notifyErrorA({}, 'Please agree with user agreement');
    }
    if (!payload.termsConditions) {
      return notifyErrorA({}, 'Please agree with terms & conditions');
    }
    if (!payload.privacyPolicy) {
      return notifyErrorA({}, 'Please agree with privacy policy');
    }
    this.setState({
      status: 'loading',
    });
    return createAccount({ ...payload, currency }).then((response) => {
      const account = _.values(response.payload.result.account)[0];
      this.setState({
        emailRegistered: account.email,
        success: true,
      });
      success(true);
    }).catch((er) => {
      this.setState({
        status: 'hide',
      });
      const property = _.get(er, 'data.constraintViolations[0].property', '');
      const message = _.get(er, 'data.constraintViolations[0].message', '');
      if (property) {
        if (property === 'domain') {
          notifyErrorA({}, 'School name you selected is already used');
          throw new SubmissionError({ name: message });
        }
        notifyErrorA({}, message);
        throw new SubmissionError({ property: message });
      }
    });
  }

  generateLines = (title, value) => (
    <div>
      <span className="title">
        {title}
        :
      </span>
      <span className="value">{value}</span>
    </div>
  );

  handleAgreementsState = (prop) => {
    const {
      termsConditions,
      modalOpened,
      privacyPolicy: statePrivacyPolicy,
      userAgreement: stateUserAgreement,
    } = this.state;
    switch (prop) {
      case 'Terms & Conditions':
        this.setState({
          termsConditions: !termsConditions,
          fileToOpen: termsAndConditions,
          selectedModal: 'Terms & Conditions',
          modalOpened: !modalOpened,
        });
        break;
      case 'Privacy Policy':
        this.setState({
          privacyPolicy: !statePrivacyPolicy,
          fileToOpen: privacyPolicy,
          selectedModal: 'Privacy Policy',
          modalOpened: !modalOpened,
        });
        break;
      case 'User Agreement':
        this.setState({
          userAgreement: !stateUserAgreement,
          fileToOpen: userAgreement,
          selectedModal: 'User Agreement',
          modalOpened: !modalOpened,
        });
        break;
      default:
        break;
    }
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({
      numPages,
      pageNumber: 1,
    });
  }

  setPageNumber = (pageNo) => {
    this.setState({
      pageNumber: pageNo,
    });
  }

  getDiscount = (code, id, subType) => {
    const { actions: { getDiscountA, notifySuccessA } } = this.props;

    getDiscountA(code, id, subType).then((response) => {
      this.setState({
        priceWithDiscount: response.payload.result.price,
      });
      notifySuccessA({}, 'Discount was applied successfully');
    });
  }

  render() {
    const {
      handleSubmit,
      firstPage,
      secondPage,
      thirdPage,
      planPricing,
      discountCoupon,
    } = this.props;
    const {
      modalOpened,
      pageNumber,
      numPages,
      selectedModal,
      fileToOpen,
      success,
      emailRegistered,
      status,
      priceWithDiscount,
    } = this.state;

    const selectedPlan = filter(planPricing, { id: firstPage.upgradePlan })[0];
    return (
      <div>
        {success ? (
          <p className="confirmation-text">
            Please confirm your e-mail address by clicking the link we sent to
            {' '}
            {emailRegistered}
          </p>
        ) : (
          <div>
            <div className="last-step--title step-info">
              <div>Step 4 - Review your information</div>
            </div>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <FullscreenAgreementsDialog
                open={modalOpened}
                pageNumber={pageNumber}
                numPages={numPages}
                handleAgreementsState={() => this.handleAgreementsState(selectedModal)}
                onDocumentLoad={this.onDocumentLoad}
                title={selectedModal}
                file={fileToOpen}
                setPageNumber={this.setPageNumber}
              />
              <div className="secondary-title secondary-title">
                Plan
              </div>
              <div className="last-step--info-text">
                {this.generateLines('Payment type',
                  firstPage
                    .planPaymentType
                    .charAt(0)
                    .toUpperCase()
                  + firstPage
                    .planPaymentType
                    .slice(1))}
                {this.generateLines('Plan', selectedPlan.name)}
                {this.generateLines('Price',
                  `€ ${firstPage.planPaymentType === 'monthly' ? selectedPlan.priceMonthly
                    : selectedPlan.priceAnnually}`)}
                <div className="before-info">
                  <span className="title" />
                  <span className="info">
                    Paid
                    {' '}
                    {firstPage.planPaymentType}
                    .
                    Next payment date
                    {firstPage.planPaymentType === 'monthly'
                      ? moment().add(1, 'M').format('DD.MM.YYYY')
                      : moment().add(1, 'y').format('DD.MM.YYYY')}
                  </span>
                </div>
                {this.generateLines('Setup fee', `€ ${selectedPlan.setupFee}`)}
                <div className="before-info">
                  <span className="title" />
                  <span className="info">One time setup payment</span>
                </div>
                <div className="secondary-title secondary-title__small">
                  School information
                </div>
                {this.generateLines('School name', secondPage.name)}
                {this.generateLines('Timezone', secondPage.timezone)}
                {this.generateLines('Representative', secondPage.representative)}
                {this.generateLines('Resort', secondPage.resort.label)}
                {this.generateLines('Currency', secondPage.currency.label)}
                {this.generateLines('Country', secondPage.country.label)}
                {this.generateLines('State', secondPage.state)}
                {this.generateLines('City', secondPage.city)}
                {this.generateLines('Postal code', secondPage.postal)}
                <div className="secondary-title secondary-title_small">
                  User information
                </div>
                <div className="wizard-email">
                  {this.generateLines('E-mail', thirdPage.email)}
                </div>
                <div className="last-step--title" />
                <div className="secondary-title secondary-title__small">
                  Enter discount
                </div>
                <div className="last-step--discount-field">
                  <span className="title">Discount coupon:</span>
                  <span className="value">
                    <Field
                      name="discountCoupon"
                      component={TextField}
                      className="register-form__email"
                      type="text"
                      hintText="Input text"
                      underlineStyle={{
                        borderColor: 'rgba(32,51,90,0.4)',
                      }}
                      underlineFocusStyle={{
                        borderColor: 'rgba(32,51,90,0.4)',
                      }}
                      inputStyle={{
                        color: '#253D63',
                        fontSize: '16px',
                        lineHeight: '24px',
                      }}
                    />
                  </span>
                  <button
                    className="submit-button__discount submit-button"
                    type="button"
                    onClick={() => this.getDiscount(
                      discountCoupon,
                      firstPage.upgradePlan,
                      firstPage.planPaymentType,
                    )}
                  >
                    Apply
                  </button>
                </div>
                <div className="last-step--total" />
                <div className="total">
                  <span>Total:&nbsp;</span>
                  <span>
                    {priceWithDiscount === undefined ? ((firstPage.planPaymentType === 'monthly' ? selectedPlan.priceMonthly : selectedPlan.priceAnnually) + selectedPlan.setupFee) : priceWithDiscount}
                    {' '}
                    Eur
                  </span>
                </div>
              </div>
              <div className="terms-conditions">
                <Field
                  component={Checkbox}
                  name="userAgreement"
                />
                <p style={{ float: 'left' }}>
                  I agree with
                  <a
                    onClick={() => this.handleAgreementsState('User Agreement')}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#253D63',
                      paddingLeft: '10px',
                    }}
                  >
                    User Agreement
                  </a>
                </p>
              </div>
              <div className="terms-conditions">
                <Field
                  component={Checkbox}
                  name="termsConditions"
                />
                <p style={{ float: 'left' }}>
                  I agree with
                  <a
                    onClick={() => this.handleAgreementsState('Terms & Conditions')}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#253D63',
                      paddingLeft: '10px',
                    }}
                  >
                    Terms & Conditions
                  </a>
                </p>
              </div>
              <div className="terms-conditions">
                <Field
                  component={Checkbox}
                  name="privacyPolicy"
                />
                <p style={{ float: 'left' }}>
                  I agree with
                  <a
                    onClick={() => this.handleAgreementsState('Privacy Policy')}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: '#253D63',
                      paddingLeft: '10px',
                    }}
                  >
                    Privacy policy
                  </a>
                </p>
              </div>
              <RefreshIndicator
                size={50}
                left={755}
                top={1040}
                status={status}
              />
              <div className="button-alignment">
                <button className="submit-button" type="submit">Continue to Payment</button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

ForthPage.propTypes = {
  firstPage: PropTypes.shape({
    upgradePlan: PropTypes.number,
    planPaymentType: PropTypes.string,
  }),
  secondPage: PropTypes.shape({
    currency: PropTypes.shape({
      label: PropTypes.string,
    }),
    name: PropTypes.string,
    representative: PropTypes.string,
    resort: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
    timezone: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.shape({
      label: PropTypes.string,
    }),
    postal: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
  }),
  thirdPage: PropTypes.shape({
    email: PropTypes.string,
  }),
  success: PropTypes.func,
  actions: PropTypes.shape({
    fetchPlansA: PropTypes.func,
    notifyErrorA: PropTypes.func,
    notifySuccessA: PropTypes.func,
    getDiscountA: PropTypes.func,
    account: PropTypes.shape({
      createAccount: PropTypes.func,
    }),
  }),
  handleSubmit: PropTypes.func,
  planPricing: PropTypes.shape({
    id: PropTypes.number,
  }),
  discountCoupon: PropTypes.string,
};

const formConfig = {
  form: 'RegisterFourthPage',
  destroyOnUnmount: false,
};

const selector = formValueSelector('RegisterFourthPage');

const mapStateToProps = (state) => ({
  firstPage: getFormValues('RegisterFirstPage')(state),
  secondPage: getFormValues('RegisterSecondPage')(state),
  thirdPage: getFormValues('RegisterThirdPage')(state),
  planPricing: state.entities.subscriptionPlan,
  discountCoupon: selector(state, 'discountCoupon'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    account: bindActionCreators(accountActions, dispatch),
    fetchPlansA: bindActionCreators(fetchPlans, dispatch),
    notifyErrorA: bindActionCreators(notifyError, dispatch),
    notifySuccessA: bindActionCreators(notifySuccess, dispatch),
    getDiscountA: bindActionCreators(getDiscount, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(formConfig)(ForthPage));
