import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '../actions/userActions';
import { clear } from '../actions/eventActions';
import '../styles/Logout.scss';

const Logout = ({
  logoutA,
  clearA,
}) => {
  const { push } = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    logoutA().then(() => {
      clearA();
    });
    push('/login');
  };

  return (
    <span className="logout" onClick={handleLogout}>Logout</span>
  );
};

Logout.propTypes = {
  logoutA: PropTypes.func,
  clearA: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  clearA: bindActionCreators(clear, dispatch),
  logoutA: bindActionCreators(logout, dispatch),
});

export default connect(null, mapDispatchToProps)(Logout);
