import URL from 'url-parse';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import _, { forEach, sortBy } from 'lodash';
import { convertToUTC } from './dateTime';
import timeszonesWithoutDst from '../timezonesWithoutDst.json';

const moment = extendMoment(Moment);

export const FormatAvailabilities = (timeFrom, timeTo, dateFrom, dateTo) => {
  const availabilities = [];
  const daysCount = moment(dateTo).diff(moment(dateFrom), 'days') + 1;
  const timeStart = moment.utc(convertToUTC(timeFrom)).toISOString();
  const timeEnd = moment.utc(convertToUTC(timeTo)).toISOString();
  let dateStart = moment.utc(convertToUTC(dateFrom)).toISOString();
  for (let i = 0; i < daysCount; i += 1) {
    const availabilityTimeFrom = `${dateStart.split('T')[0]}T${timeStart.split('T').pop()}`;
    const availabilityTimeTo = `${dateStart.split('T')[0]}T${timeEnd.split('T').pop()}`;
    availabilities.push({
      timeFrom: availabilityTimeFrom,
      timeTo: availabilityTimeTo,
    });
    dateStart = moment(dateStart).add(1, 'day').toISOString();
  }
  return availabilities;
};

const sortByParsedTime = (data, column, order) => _.orderBy(data, [(element) => moment(element[column.name[0]]).format('HH:mm'), (element) => moment(element[column.name[1]]).format('HH:mm')], order);

export const sortDates = (lessons) => sortBy(lessons, (lesson) => new Date(lesson.timeFrom));

const sortByValueUngrouped = (data, column, order, dependencyData) => {
  let sortedList = data;
  if (column.name.length === 1) {
    sortedList = _.orderBy(data, (element) => {
      const idx = element[dependencyData.name];
      const dependencyOb = dependencyData.data[idx];
      const sortProp = column.name;
      if (column.dataArray) {
        let returnVal = '';
        _.forEach(dependencyOb[sortProp], (ob) => {
          returnVal += ob[column.dataArray];
        });
        return returnVal;
      }
      return _.get(dependencyOb, sortProp, ' ');
    }, order);
  } else if (column.name.length === 2) {
    sortedList = _.orderBy(data, [(element) => {
      const idx = element[dependencyData.name];
      const dependencyOb = dependencyData.data[idx];
      const sortProp = column.name[0];
      if (column.dataArray) {
        let returnVal = '';
        _.forEach(idx, (ob) => {
          returnVal += ob[column.name[0]];
        });
        return returnVal;
      }
      return _.get(dependencyOb, sortProp, ' ');
    }, (element) => {
      const idx = element[dependencyData.name];
      const dependencyOb = dependencyData.data[idx];
      const sortProp = column.name[1];
      if (column.dataArray) {
        let returnVal = '';
        _.forEach(idx, (ob) => {
          returnVal += ob[column.name[1]];
        });
        return returnVal;
      }
      return _.get(dependencyOb, sortProp, ' ');
    }], order);
  }

  return sortedList;
};

const sortByValueGrouped = (data, column, order, dependencyData) => {
  let sortedList = data;
  if (column.name.length === 1) {
    sortedList = _.orderBy(data, (element) => {
      const idx = element[dependencyData.name];
      let returnVal = 0;
      _.forEach(dependencyData.data[idx], (ob) => {
        returnVal += ob[column.name];
      });
      return returnVal;
    }, order);
  } else if (column.name.length === 2) {
    sortedList = _.orderBy(data, [(element) => {
      const blocks = element[dependencyData.name];
      let max = _.maxBy(dependencyData.data[blocks[0].id], column.name[0])[column.name[0]];

      _.forEach(blocks, (block) => {
        const blockMax = _.maxBy(dependencyData.data[block.id], column.name[0])[column.name[0]];
        max = max < blockMax ? blockMax : max;
      });

      return max;
    }, (element) => {
      const blocks = element[dependencyData.name];
      let max = _.maxBy(dependencyData.data[blocks[0].id], column.name[1])[column.name[1]];

      _.forEach(blocks, (block) => {
        const blockMax = _.maxBy(dependencyData.data[block.id], column.name[1])[column.name[1]];
        max = max < blockMax ? blockMax : max;
      });

      return max;
    }], order);
  }

  return sortedList;
};

const sortByValueLevel2 = (data, column, order, dependencyData) => {
  let sortedList = data;
  if (dependencyData.grouped) {
    sortedList = sortByValueGrouped(data, column, order, dependencyData);
  } else {
    sortedList = sortByValueUngrouped(data, column, order, dependencyData);
  }

  return sortedList;
};

const sortByValue = (data, column, order) => {
  if (column.customInfo === 'parseTime') {
    return sortByParsedTime(data, column, order);
  }
  if (column.dataArray === 'key') {
    return _.orderBy(data, [(element) => {
      let orderVal = '';
      _.forEach(element[column.name[0]], (el, key) => {
        orderVal += key;
      });

      return orderVal;
    }, (element) => element[column.name[1]]], order);
  }
  if (column.dataArray === 'timeFrom') {
    return _.orderBy(data, (element) => _.minBy(element.lessonBlocks.map((lesson) => _.minBy(lesson.lessons, 'timeFrom')), 'timeFrom').timeFrom, order);
  }
  if (column.dataArray) {
    if (column.name.length === 1) {
      return _.orderBy(data, (element) => {
        let orderVal = '';
        _.forEach(element[column.name], (el) => {
          const orderValAdd = column.dataArray !== 'empty' ? el[column.dataArray] : el;
          orderVal += orderValAdd;
        });

        return orderVal;
      }, order);
    }
    if (column.name.length === 2) {
      return _.orderBy(data, [(element) => {
        let orderVal = '';
        _.forEach(element[column.name[0]], (el) => {
          orderVal += column.dataArray !== 'empty' ? el[column.dataArray] : el;
        });

        return orderVal;
      }, (element) => element[column.name[1]]], order);
    }
  }
  return _.orderBy(data, column.name, order);
};

export const sortTable = (data, columns, columnIndex, order, dependencyData = {}) => {
  if (columns[columnIndex]) {
    let orderValues = [];
    switch (columns[columnIndex].orderType) {
      case 'rotational':
        _.forEach(columns[columnIndex].name, (name, index) => {
          let val = 'desc';
          if ((index % 2 === 0 && order) || (index % 2 !== 0 && !order)) {
            val = 'asc';
          }
          orderValues.push(val);
        });
        break;
      case 'every':
        _.forEach(columns[columnIndex].name, () => {
          const val = order ? 'asc' : 'desc';
          orderValues.push(val);
        });
        break;
      case 'normal':
        orderValues = order ? 'asc' : 'desc';
        break;
      default:
        break;
    }
    switch (columns[columnIndex].level) {
      case 1:
        return sortByValue(data, columns[columnIndex], orderValues);
      case 2:
        return sortByValueLevel2(
          data,
          columns[columnIndex],
          orderValues,
          dependencyData[columns[columnIndex].dependency],
        );
      default:
        break;
    }
  }
  return data;
};

export const formatLanguagesLevelsValues = (languages, deletedLanguages) => {
  if (!languages) return null;
  const languagesReturn = [];
  _.forEach(languages, (language, index) => {
    if (language && !_.includes(deletedLanguages, index)) {
      if (Object.prototype.hasOwnProperty.call(language, 'code') && Object.prototype.hasOwnProperty.call(language, 'level')) {
        const code = language.code.value ? language.code.value : language.code;
        const level = language.level.value ? language.level.value : language.level;
        languagesReturn.push({ code, level });
      }
    }
  });
  return languagesReturn;
};

export const formatLanguagesValues = (languages, deletedLanguages) => {
  if (!languages) return null;
  const languagesReturn = [];
  _.forEach(languages, (language, index) => {
    if (language && !_.includes(deletedLanguages, index)) {
      if (Object.prototype.hasOwnProperty.call(language, 'code')) {
        const code = language.code.value ? language.code.value : language.code;
        languagesReturn.push({ code });
      }
    }
  });
  return languagesReturn;
};

export const formatPhoneNumbersArray = (phoneNumbers, deletedPhoneNumbers) => {
  if (!phoneNumbers) return null;
  const phoneNumbersReturn = [];
  _.forEach(phoneNumbers, (phoneNumber, index) => {
    if (phoneNumber && !_.includes(deletedPhoneNumbers, index)) {
      const phoneNo = phoneNumber.phoneNumber ? phoneNumber.phoneNumber : phoneNumber;
      phoneNumbersReturn.push(phoneNo);
    }
  });
  return phoneNumbersReturn;
};

export const formatPhoneNumbersValues = (phoneNumbers, deletedPhoneNumbers) => {
  if (!_.get(phoneNumbers, '[0].phoneNumber', null)) {
    return [];
  }
  const phoneNumbersReturn = [];
  _.forEach(phoneNumbers, (phoneNumber, index) => {
    if (phoneNumber && !_.includes(deletedPhoneNumbers, index)) {
      const phoneNo = phoneNumber.phoneNumber ? phoneNumber.phoneNumber : phoneNumber;
      phoneNumbersReturn.push({ phoneNumber: phoneNo });
    }
  });
  return phoneNumbersReturn;
};

export const formatDateOfBirth = (date) => moment(`${date}`).format('YYYY-MM-DD');

export const parseReactSelectValues = (values) => {
  if (!values) return null;
  const valuesParsed = [];
  if (Array.isArray(values)) {
    _.forEach(values, (value) => {
      const val = value.value || value;
      valuesParsed.push(val);
    });
  } else {
    valuesParsed.push(values.value || values);
  }
  return valuesParsed;
};

export const parseOptionValue = (option) => {
  if (typeof option === 'number' || typeof option === 'string') {
    return option;
  }

  const optionReturn = _.isEmpty(option) ? null : option;
  if (optionReturn) {
    if (optionReturn.id) {
      return optionReturn.id;
    }
    if (optionReturn.value) {
      return optionReturn.value;
    }
    return optionReturn;
  }
  return null;
};

export const checkSeasonsOverlapingValidity = (seasons, dateFrom, dateTo) => {
  const dateF = moment(dateFrom, 'DD/MMM/YYYY');
  const dateT = moment(dateTo, 'DD/MMM/YYYY');
  const newRange = moment.range(dateF, dateT);
  let valid = true;
  _.forEach(seasons, (season) => {
    const dateFromFormatted = moment(season.dateFrom, 'DD/MMM/YYYY');
    const dateToFormatted = moment(season.dateTo, 'DD/MMM/YYYY');
    const range = moment.range(dateFromFormatted, dateToFormatted);
    if (newRange.overlaps(range, { adjacent: true })) {
      valid = false;
    }
  });
  return valid;
};

export const parseSeasons = (seasons = []) => {
  const formatedSeasons = [];

  _.forEach(seasons, (group) => {
    _.forEach(group, (season) => {
      const { dateFrom } = season;
      const { dateTo } = season;
      formatedSeasons.push({
        ...season,
        dateFrom: moment.utc(dateFrom, 'DD/MMM/YYYY').toISOString(),
        dateTo: moment.utc(dateTo, 'DD/MMM/YYYY').toISOString(),
        size: parseInt(season.size, 10),
        price: parseInt(season.price, 10),
      });
    });
  });
  return formatedSeasons;
};

export const parseGroupSizeArray = (seasons = []) => {
  const ar = [];
  _.forEach(seasons, (s) => {
    ar.push(parseInt(_.get(s, '[0].size', ''), 10));
  });
  return ar;
};

export const parseGroupDays = (prices = []) => {
  const ar = [];
  _.forEach(prices, (p) => {
    ar.push(parseInt(_.get(p, 'days', ''), 10));
  });
  return ar;
};

export const parseActivity = (activities, speciality) => {
  const tempActivities = _.values(activities);
  const activity = _.find(tempActivities,
    (a) => _.find(a.specialities, (s) => s.id === speciality));
  return activity ? activity.id : null;
};

export const getUrlProperty = (property = null, string = window.location.href) => {
  const parsedUrlObject = new URL(string, true);

  return _.get(parsedUrlObject, property, null);
};

export const minutesOfDay = (time) => {
  const m = moment(time);

  return m.minutes() + ((m.hours() + new Date(time).getTimezoneOffset() / 60) * 60);
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parsePhoneNumber = (str) => {
  try {
    const phones = [];
    const arr = JSON.parse(str);
    _.forEach(arr, (ob) => phones.push(ob.phoneNumber));
    return phones.join(', ');
  } catch (e) {
    return '';
  }
};

export const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0;

export const getUserGuide = (pathName) => {
  const route = pathName.split('/')[1];
  switch (route) {
    case 'school': {
      return 'schoolSettings';
    }
    case 'account': {
      return 'accountInformation';
    }
    case 'instructors': {
      return 'instructors';
    }
    case 'availability': {
      return 'availabilities';
    }
    case 'hotels': {
      return 'hotels';
    }
    case 'services': {
      return 'additionalServices';
    }
    case 'products': {
      return 'products';
    }
    case 'product': {
      return 'productForm';
    }
    case 'calendar': {
      return 'calendar';
    }
    case 'partners': {
      return 'partners';
    }
    case 'managers': {
      if (pathName.split('/').length === 2) {
        return 'managers';
      }
      return undefined;
    }
    case 'clients': {
      return 'clients';
    }
    default: return undefined;
  }
};

export const parseBase64Body = (base64) => base64.split(',')[1];

export const getUploadModalExplainText = (name) => {
  switch (name) {
    case 'instructors': {
      return ' - Columns that must be filled: Email, Name, Surname, Gender, Current address\n'
        + '    - Accepted date formats DD/MM/YYYY and YYYY-MM-DD\n'
        + '    - In Nationality enter the name of the country\n'
        + '    - Phone number starts with ‘+’\n'
        + '    - Languages are written in abbreviations, find your language abbreviations here\n'
        + '    - In Work permissions enter the abbreviation of the country. See the list of country abbreviations here \n'
        + '    - Separate multiple items (languages, phone numbers, work permissions) with ‘;’\n'
        + '    - Symbol ‘,’ (comma) is not allowed in the fields';
    }
    case 'partners': {
      return ' -Columns that must be filled: Email, Name, Commission, Commission type\n'
        + '    - Phone number starts with ‘+’\n'
        + '    - Symbol ‘,’ (comma) is not allowed in the fields';
    }
    case 'services': {
      return '  - Columns that must be filled: Name, Price\n'
        + '   - Symbol ‘,’ (comma) is not allowed in the fields ';
    }
    case 'partnerManagers': {
      return ' - Columns that must be filled:  Email, Name, Surname, Partner\n'
        + '    - Accepted date formats DD/MM/YYYY and YYYY-MM-DD\n'
        + '    - Phone number starts with ‘+’\n'
        + '    - Languages are written in abbreviations, find your language abbreviations here \n'
        + '    - Separate multiple items (languages, phone numbers) with ‘;’\n'
        + '    - Make sure your Partner is in the Partner list\n'
        + '    - Symbol ‘,’ (comma) is not allowed in the fields';
    }
    case 'clients': {
      return ' - Columns that must be filled: Email, Name, Surname\n'
        + '    - Accepted date formats DD/MM/YYYY and YYYY-MM-DD\n'
        + '    - Phone number starts with ‘+’\n'
        + '    - Languages are written in abbreviations, find your language abbreviations here\n'
        + '    - Separate multiple items (languages, phone numbers) with ‘;’\n'
        + '    - Symbol ‘,’ (comma) is not allowed in the fields';
    }
    case 'hotels': {
      return '  - Columns that must be filled: Name,\n'
       + '  - Symbol ‘,’ (comma) is not allowed in the fields';
    }
    default:
      return '';
  }
};

export const parseProductTiming = (tFrom, tTo) => {
  if (!tFrom || !tTo) {
    return {};
  }

  const timeFrom = moment(convertToUTC(tFrom)).toISOString();
  const timeTo = moment(convertToUTC(tTo)).toISOString();

  return {
    timeFrom,
    timeTo,
  };
};

export const checkBrowser = () => {
  const isSafari = navigator.userAgent.indexOf('Safari') > -1
    && navigator.userAgent.indexOf('Chrome') < 0
    && navigator.userAgent.indexOf('Chromium') < 0;

  const isDST = moment.tz(Date.prefs[0]).isDST();

  return isSafari && isDST;
};

const checkIfChrome = () => {
  const isSafari = navigator.userAgent.indexOf('Safari') > -1
    && navigator.userAgent.indexOf('Chrome') < 0
    && navigator.userAgent.indexOf('Chromium') < 0;

  return !isSafari;
};

export const checkIfHaveDst = (userTimezone) => {
  let isTimezoneDst = false;
  _.forEach(timeszonesWithoutDst, (value) => {
    _.forEach(value.utc, (utc) => {
      if (utc.includes(userTimezone)) {
        isTimezoneDst = value.isdst;
      }
    });
  });

  return isTimezoneDst;
};

export const checkChromeAndDst = (userTimezone) => {
  if (!checkIfChrome()) {
    return false;
  }

  return !checkIfHaveDst(userTimezone);
};

export const formatDataForStatistics = (data) => {
  const tempData = [];
  for (let i = 1; i < data.data.rows.length; i += 1) {
    const formattedData = {};
    forEach(data.data.rows[i].columns, (column) => {
      formattedData[column.index] = column.value;
    });
    tempData.push(formattedData);
  }
  return tempData;
};

export const pageSizes = [
  5,
  10,
  20,
  25,
  50,
  100,
];
