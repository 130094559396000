import React from 'react';
import PropTypes from 'prop-types';
import '../styles/EventLabel.scss';

const ClientCommentsLabel = ({ comments }) => (
  <div className="event-label">
    <div className="event-label__wrapper">
      {comments ? <span>comments</span> : 'No comments'}
    </div>
  </div>
);

ClientCommentsLabel.propTypes = {
  comments: PropTypes.bool,
};

export default React.memo(ClientCommentsLabel);
