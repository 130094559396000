import { httpRequest } from '../middleware/http';

export const fetchStatisticsColumns = (body, pageNumber, pageSize) => httpRequest({
  method: 'POST',
  path: `/analytics?page=${pageNumber}&size=${pageSize}`,
  body,
  types: [
    'STATISTICS_GET_COLUMNS_REQUEST',
    'STATISTICS_GET_COLUMNS_SUCCESS',
    'STATISTICS_GET_COLUMNS_FAILURE',
  ],
});

export const exportStatistics = (body) => httpRequest({
  path: '/analytics/export',
  method: 'POST',
  types: [
    'STATISTICS_EXPORT_REQUEST',
    'STATISTICS_EXPORT_SUCCESS',
    'STATISTICS_EXPORT_FAILURE',
  ],
  body,
  schema: null,
});
