import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import '../styles/Page.scss';

class Notification extends Component {
  componentWillUnmount() {
    const { removeAll } = this.props;
    removeAll();
  }

  render() {
    const { notifications } = this.props;

    const style = {
      NotificationItem: {
        DefaultStyle: {
          margin: '18px 10px 10px 10px',
        },
      },
    };
    return (
      <Notifications style={style} notifications={notifications} />
    );
  }
}

Notification.propTypes = {
  removeAll: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  notifications: PropTypes.array,
};

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  removeAll: bindActionCreators(Notifications.removeAll, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
