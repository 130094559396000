import React from 'react';
import PropTypes from 'prop-types';
import '../styles/AddLessonButton.scss';

const AddLessonButton = ({ onClick }) => (
  <div className="add-lesson-button" onClick={onClick} />
);

AddLessonButton.propTypes = {
  onClick: PropTypes.func,
};

export default React.memo(AddLessonButton);
