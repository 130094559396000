import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { convertToUTC } from '../utils/dateTime';
import '../styles/Calendar.scss';

const IndividualEvent = ({ event }) => {
  const { timeFrom, timeTo } = event;
  const [hiddenTimes, timeValues] = useMemo(() => [
    `${moment(convertToUTC(timeFrom)).toISOString()}-${moment(convertToUTC(timeTo)).toISOString()}`,
    `${moment(convertToUTC(timeFrom)).format('HH:mm')} - ${moment(convertToUTC(timeTo)).format('HH:mm')}`,
  ], [timeFrom, timeTo]);

  return (
    <div className="event">
      <div className="event__row">
        <div className="event__container-left">
          <div className="event__component--inline">
            <div className="event__time">
              {timeValues}
            </div>
          </div>
          <div className="event__component--inline">
            <div className="event__language">
              {event.language}
            </div>
          </div>
        </div>
        <div className="event__container-right" />
      </div>
      <div className="event__row">
        <div className="event__container-left">
          <div className="event__booking-id">
            {`B-${event.bid}`}
          </div>
        </div>
        <div className="event__container-right">
          <div className="event__instructor">
            {event.clientFullName}
          </div>
        </div>
      </div>
      <div className="hidden-times">
        {hiddenTimes}
      </div>
    </div>
  );
};

IndividualEvent.propTypes = {
  event: PropTypes.shape({
    timeFrom: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    timeTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    language: PropTypes.string,
    bid: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    clientFullName: PropTypes.string,
  }),
};

export default React.memo(IndividualEvent);
