import React from 'react';
import PropTypes from 'prop-types';
import { forEach } from 'lodash';

const Cell = ({ value }) => <span>{value}</span>;

const statisticsColumns = (columnsData) => {
  const columnsArray = [];
  forEach(columnsData, (column) => {
    columnsArray.push({
      Header: column.value,
      sortable: false,
      accessor: `${column.index}`,
      Cell,
    });
  });
  return columnsArray;
};

Cell.propTypes = {
  value: PropTypes.string,
};

export default statisticsColumns;
