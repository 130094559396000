import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import resortSchema from '../schema/Resort';

export const fetchResorts = (text) => httpRequest({
  method: 'GET',
  path: `/resort/${text}`,
  types: [
    'RESORTS_GET_REQUEST',
    'RESORTS_GET_SUCCESS',
    'RESORTS_GET_FAILURE',
  ],
  schema: new schema.Array(resortSchema),
});

export const fetchResort = (id) => httpRequest({
  method: 'GET',
  path: `/resort/${id}`,
  types: [
    'RESORT_GET_REQUEST',
    'RESORT_GET_SUCCESS',
    'RESORT_GET_FAILURE',
  ],
  schema: resortSchema,
});

export const fetchResortsByProduct = (id) => httpRequest({
  method: 'GET',
  path: `/product/${id}/resort`,
  types: [
    'RESORTS_GET_REQUEST',
    'RESORTS_GET_SUCCESS',
    'RESORTS_GET_FAILURE',
  ],
  schema: new schema.Array(resortSchema),
});
