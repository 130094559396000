import { reducer as formReducer } from 'redux-form';
import * as types from '../constants/ActionTypes';

const form = formReducer.plugin({
  productAdd: (state, action) => {
    switch (action.type) {
      case types.PRODUCT_GROUP_SEASON_ENTRY_ADD:
        if (!state.values || !state.values.groups) {
          return state;
        }
        return {
          ...state,
          values: {
            ...state.values,
            groups: state.values.groups.map((group) => ({
              ...group,
              seasons: [
                ...(group.seasons ? group.seasons : []),
                0,
              ],
            })),
          },
        };
      default:
        return state;
    }
  },
});
export default form;
