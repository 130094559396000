const defaultArrow = {
  width: '0px',
  height: '0px',
  position: 'absolute',
};

export const arrowUp = ({ color, size }) => (
  {
    ...defaultArrow,
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
    borderBottom: `${size}px solid ${color}`,
    top: `-${size}px`,
  }
);

export const arrowDown = ({ color, size }) => (
  {
    ...defaultArrow,
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
    borderTop: `${size}px solid ${color}`,
  }
);

export const arrowRight = ({ color, size }) => (
  {
    ...defaultArrow,
    borderTop: `${size}px solid transparent`,
    borderBottom: `${size}px solid transparent`,
    borderLeft: `${size}px solid ${color}`,
  }
);

export const arrowLeft = ({ color, size }) => (
  {
    ...defaultArrow,
    borderTop: `${size}px solid transparent`,
    borderBottom: `${size}px solid transparent`,
    borderRight: `${size}px solid ${color}`,
    left: `-${size}px`,
  }
);
