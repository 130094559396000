import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Event from './Event';

const TimelineDayBlock = ({
  day,
  events,
  onItemDoubleClick,
  onGroupOpen,
}) => (
  <div className="timeline-day-block">
    <div className="day">
      {day}
    </div>
    <div className="day-events">
      {map(events, (e, index) => (
        <Event
          event={e}
          key={index}
          onItemDoubleClick={onItemDoubleClick}
          onGroupOpen={onGroupOpen}
        />
      ))}
    </div>
  </div>
);

TimelineDayBlock.propTypes = {
  day: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.array,
  onItemDoubleClick: PropTypes.func,
  onGroupOpen: PropTypes.func,
};

export default React.memo(TimelineDayBlock);
