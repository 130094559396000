import React from 'react';
import PropTypes from 'prop-types';

const RenderToggle = ({
  input,
  disabled,
  wrapperClass,
  commission,
}) => {
  const labelClass = wrapperClass === 'commission' ? 'label label--inverted' : 'label';
  let sliderClass = 'slider';
  let labelText = 'Not Paid';
  if (wrapperClass === 'commission') {
    sliderClass = commission ? 'slider slider--inverted slider slider--inverted--padded' : 'slider slider--inverted';
    labelText = commission ? `Commission ${commission}` : 'Commission';
  }
  return (
    <div className="toggle-wrapper">
      <div className={wrapperClass}>
        <label className={labelClass}>
          {labelText}
        </label>
        <label className="toggle-element">
          <input
            type="checkbox"
            disabled={disabled}
            checked={input.value}
            value={input.value}
            onChange={input.onChange}
            name={input.name}
          />
          <span className={sliderClass} />
        </label>
        <label className={input.value ? (`${labelClass}__checked`) : labelClass}>
          {wrapperClass === 'commission' ? '' : 'Paid'}
        </label>
      </div>
    </div>
  );
};

RenderToggle.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    onChange: PropTypes.func,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  wrapperClass: PropTypes.string,
  commission: PropTypes.string,
};

export default React.memo(RenderToggle);
