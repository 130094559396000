import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, omit } from 'lodash';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { DatePicker } from 'redux-form-material-ui';
import moment from 'moment';
import RenderSelect from '../components/RenderSelect';
import { fetchSpecialities } from '../actions/specialityActions';
import { mapPropsToOptions } from '../utils/map';
import { required, notEmpty } from '../utils/validators';
import { Levels } from '../constants/Levels';
import { languageOptionsIdName } from '../constants/LanguageOptions';
import ResortSelect from '../components/ResortSelect';

const LabeledField = (props) => {
  const { label, anchor } = props;
  return (
    <div className="export-filter-form-field">
      <label>{label}</label>
      <div className="form-field-input-wrapper" id={anchor}>
        <Field
          {...omit(props, ['label', 'component', 'clearable'])}
          placeholder=""
          component={RenderSelect}
          clearable
        />
      </div>
    </div>
  );
};

const formatDate = (date) => moment(date).format('DD/MMM/YYYY');

const LabeledDateField = (props) => {
  const { label, anchor } = props;
  return (
    <div className="export-filter-form-field">
      <label className="export-filter-datepicker-label">{label}</label>
      <div className="form-field-input-wrapper" id={anchor}>
        <Field
          {...omit(props, ['label'])}
          component={DatePicker}
          autoOk
          formatDate={formatDate}
          validate={[required, notEmpty]}
        />
      </div>
    </div>
  );
};

const LabeledResortField = (props) => {
  const { anchor, resorts, addResorts } = props;
  return (
    <div className="export-filter-form-field">
      <label>Resort:</label>
      <div className="form-field-input-wrapper" id={anchor}>
        <Field
          {...omit(props, ['resorts', 'addResorts'])}
          component={ResortSelect}
          resorts={resorts}
          addResorts={addResorts}
          multi
        />
      </div>
    </div>
  );
};

class ExportFilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speciality: [],
    };
  }

  onActivityChange = (value) => {
    const { fetchSpecialitiesA, change } = this.props;
    change('speciality', null);
    change('activity', value);
    if (value) {
      fetchSpecialitiesA(`/activity/${value.value}`).then((res) => {
        const speciality = get(res, 'payload.result.speciality', []);
        this.setState({
          speciality,
        });
      });
    }
  }

  addResorts = (value) => {
    const { change } = this.props;
    change('resort', value);
  }

  render() {
    const { activity, timeFrom, resort } = this.props;
    const { speciality } = this.state;
    return (
      <form>
        <div className="export-filter-form">
          <LabeledDateField
            label="Date from:* "
            name="timeFrom"
            anchor="dateFrom"
          />
          <LabeledDateField
            label="Date to:* "
            name="timeTo"
            minDate={timeFrom}
            anchor="dateTo"
          />
          <LabeledField
            label="Language: "
            name="language"
            options={languageOptionsIdName}
            anchor="language"
          />
          <LabeledField
            label="Activity: "
            name="activity"
            options={mapPropsToOptions(activity, false, true)}
            onChange={this.onActivityChange}
            anchor="activity"
          />
          <LabeledField
            label="Speciality: "
            name="speciality"
            options={mapPropsToOptions(speciality, false, true)}
            anchor="speciality"
          />
          <LabeledField
            label="Level: "
            name="level"
            options={Levels}
            anchor="level"
          />
          <LabeledResortField
            name="resort"
            resorts={resort}
            addResorts={this.addResorts}
            anchor="resort"
          />
        </div>
      </form>
    );
  }
}

LabeledField.propTypes = {
  label: PropTypes.string,
  anchor: PropTypes.string,
};

LabeledDateField.propTypes = {
  label: PropTypes.string,
  anchor: PropTypes.string,
};

LabeledResortField.propTypes = {
  anchor: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  resorts: PropTypes.array,
  addResorts: PropTypes.func,
};

ExportFilterForm.propTypes = {
  fetchSpecialitiesA: PropTypes.func,
  change: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  activity: PropTypes.object,
  timeFrom: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  resort: PropTypes.object,
};

const selector = formValueSelector('ExportFilterWizard');

const formConfig = {
  form: 'ExportFilterWizard',
  destroyOnUnmount: false,
};

const mapStateToProps = (state) => ({
  activity: state.entities.activity,
  timeFrom: selector(state, 'timeFrom'),
  resort: selector(state, 'resort'),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSpecialitiesA: bindActionCreators(fetchSpecialities, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(ExportFilterForm));
