import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { forEach, isEmpty } from 'lodash';
import moment from 'moment';
import DataTable from '../Table/DataTable';
import * as Fields from '../../components/Field';
import { required } from '../../utils/validators';
import dropdownsValues from './statisticsValues';
import { fetchStatisticsColumns, exportStatistics } from '../../actions/statisticsActions';
import '../../styles/Statistics.scss';
import statisticsColumns from '../TableColumns/StatisticsColumns';
import {
  formatDataForStatistics,
  pageSizes,
} from '../../utils/helpers';
import { convertToUTC } from '../../utils/dateTime';
import AddEntityButton from '../../components/AddEntityButton';
import { downloadCsvFile } from '../../utils/csvHelper';

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 20,
      pageNumber: 0,
      payload: {
        dateFrom: moment(convertToUTC(moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }))).subtract(1, 'month').toISOString(),
        dateTo: moment(convertToUTC(moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }))).toISOString(),
        data: {
          0: 'booking',
          1: 'resort',
        },
        sorting: {
          column: 0,
          direction: 'desc',
        },
      },
      loading: true,
    };
  }

  componentDidMount() {
    this.initializeFilter();
  }

  initializeFilter = () => {
    const { change } = this.props;
    change('options0', dropdownsValues[0][0]);
    change('options1', dropdownsValues[1][0]);

    this.fetchData();
  }

  onDataChange = (value, param) => {
    const { formValues } = this.props;
    const { payload } = this.state;
    const tempPayload = payload;
    if (param === 'dateFrom' || param === 'dateTo') {
      tempPayload[param] = moment(convertToUTC(value)).toISOString();
    } else if (param === 'resort') {
      tempPayload.resort = value;
    } else if (value === 'additionalServices') {
      tempPayload.data = {
        [param]: value,
      };
    } else if (value === 'booking') {
      tempPayload.data = {
        ...tempPayload.data,
        0: value,
        1: formValues.options1.value,
      };
      if (formValues.options2 && formValues.options2.value !== 'select_data') {
        tempPayload.data = {
          ...tempPayload.data,
          2: formValues.options2.value,
        };
      }
    } else {
      tempPayload.data = {
        ...tempPayload.data,
        [param]: value,
      };
    }
    this.setState({
      payload: tempPayload,
    }, () => {
      this.checkIfDataIsValid();
    });
  };

  checkIfDataIsValid = () => {
    const { payload } = this.state;
    let dataValid = true;
    if (isEmpty(payload.data) || !payload.data[0]) {
      dataValid = false;
    }
    if (payload.dateFrom && payload.dateTo && dataValid) {
      this.fetchData();
    }
  };

  updateDropdownsOption = (number) => {
    const { payload: { data } } = this.state;
    let dataArray = [];
    if (number === 2) {
      if (data[2]) {
        dataArray = [{ name: 'No select', value: 'select_data' }];
      }
      dataArray = [...dataArray, ...dropdownsValues[1]];
      for (let i = 0; i < dataArray.length; i += 1) {
        if (dataArray[i].value === data[1]) {
          dataArray.splice(i, 1);
        }
      }
    } else {
      dataArray = [...dropdownsValues[1]];
      for (let i = 0; i < dataArray.length; i += 1) {
        if (dataArray[i].value === data[2]) {
          dataArray.splice(i, 1);
        }
      }
    }
    return dataArray;
  };

  generateDataFields = (number) => {
    const { payload: { data } } = this.state;
    const formattedData = [];
    formattedData.push(
      <Fields.Select
        name="options0"
        key={number}
        placeholder="Select data"
        options={dropdownsValues[0]}
        onChange={(e, val) => this.onDataChange(val.value, 0)}
      />,
    );
    if (data[0] !== 'additionalServices') {
      formattedData.push(
        <Fields.Select
          name="options1"
          key={number + 1}
          placeholder="Select data"
          options={this.updateDropdownsOption(1)}
          onChange={(e, val) => this.onDataChange(val.value, 1)}
          noResultsText="Please deselect next option"
        />,
      );
      formattedData.push(
        <Fields.Select
          name="options2"
          key={number + 2}
          placeholder="Select data"
          options={this.updateDropdownsOption(2)}
          onChange={(e, val) => this.onDataChange(val.value, 2)}
        />,
      );
    }
    return formattedData;
  };

  formatPayload = () => {
    const { payload } = this.state;
    const mode = {
      structure: 'table',
    };
    const dateRange = {
      from: payload.dateFrom,
      to: payload.dateTo,
    };
    const orderBy = payload.sorting;
    const selectors = [];
    forEach(payload.data, (data, key) => {
      if (data && data !== 'select_data') {
        selectors.push({
          index: Number(key) + 1,
          value: data,
        });
      }
    });
    return {
      mode,
      selectors,
      orderBy,
      dateRange,
    };
  };

  fetchData = (pageNumber = undefined, pageSize = undefined) => {
    const { pageNumber: statePageNumber, pageSize: statePageSize } = this.state;
    const { fetchStatisticsColumnsA } = this.props;
    this.setState({
      loading: true,
    });
    let updatedPageNumber = pageNumber;
    let updatedPageSize = pageSize;
    if (!updatedPageNumber) {
      updatedPageNumber = statePageNumber === 0 ? 1 : statePageNumber;
    }
    if (!updatedPageSize) {
      updatedPageSize = statePageSize;
    }
    const payload = this.formatPayload();
    let results = {};
    fetchStatisticsColumnsA(payload, updatedPageNumber, updatedPageSize).then((res) => {
      results = res.payload.result;
      const formattedColumns = [...results.data.rows[0].columns];
      if (formattedColumns.filter((column) => column.value === 'Individual')[0]) {
        const { index } = formattedColumns.filter((column) => column.value === 'Individual')[0];
        formattedColumns[index].value = 'Private';
      }
      const formattedData = [...formatDataForStatistics(results)];
      this.setState({
        columns: formattedColumns,
        tableData: formattedData,
        total: results.total,
        loading: false,
      });
    });
  };

  calculatePageSizeOptions = () => {
    const { total, pageNumber, pageSize } = this.state;
    const updatedPageSizes = [];
    for (let i = 0; i < pageSizes.length; i += 1) {
      if (Math.ceil(total / pageSize) === pageNumber) {
        if (total < pageSize) {
          updatedPageSizes.push(total);
          break;
        } if (total - ((pageNumber - 1) * pageSize) >= pageSizes[i]) {
          updatedPageSizes.push(pageSizes[i]);
        } else {
          updatedPageSizes.push(total - ((pageNumber - 1) * pageSize));
          break;
        }
      } else if (total > pageSizes[i]) {
        updatedPageSizes.push(pageSizes[i]);
      } else {
        updatedPageSizes.push(total);
        break;
      }
    }
    return updatedPageSizes;
  };

  onPageSizeChange = (pageSize) => {
    this.setState({
      pageSize,
      pageNumber: 1,
    });
    this.fetchData(1, pageSize);
  };

  onPageChange = (pageNumber) => {
    this.setState({
      pageNumber,
    });
    this.fetchData(pageNumber);
  };

  setSorting = (id) => {
    const { payload: { sorting }, payload } = this.state;
    if (sorting.column === id) {
      if (sorting.direction === 'desc') {
        this.setState({
          payload: {
            ...payload,
            sorting: {
              column: id,
              direction: 'asc',
            },
          },
        }, () => this.fetchData());
      } else {
        this.setState({
          payload: {
            ...payload,
            sorting: {
              column: id,
              direction: 'desc',
            },
          },
        }, () => this.fetchData());
      }
    } else {
      this.setState({
        payload: {
          ...payload,
          sorting: {
            column: id,
            direction: 'asc',
          },
        },
      }, () => this.fetchData());
    }
  };

  calculatePageSize = () => {
    const { pageSize, pageNumber, total } = this.state;
    if (total < pageSize) {
      return total;
    }
    if (total - (pageNumber * pageSize) >= 0) {
      return pageSize;
    }
    return total - ((pageNumber - 1) * pageSize);
  };

  handleExportClick = () => {
    const { exportStatisticsA } = this.props;
    const { payload } = this.state;
    const selectors = [];
    forEach(payload.data, (data, indexValue) => {
      selectors.push({
        index: Number(indexValue) + 1,
        value: data,
      });
    });
    const updatedPayload = {
      dateRange: {
        from: payload.dateFrom,
        to: payload.dateTo,
      },
      mode: {
        structure: 'table',
      },
      orderBy: payload.sorting,
      selectors,
    };

    const fileName = this.formatExportFileName();
    // eslint-disable-next-line react/no-unused-state
    this.setState({ fileName }, () => {
      exportStatisticsA(updatedPayload).then((res) => {
        const { payload: { result: { data } } } = res;
        downloadCsvFile(data, fileName);
      });
    });
  };

  formatExportFileName = () => {
    const { payload } = this.state;
    let fileName = 'statistics';
    forEach(payload.data, (item) => {
      fileName = `${fileName}-${item}`;
    });
    const dateFrom = moment(convertToUTC(moment(payload.dateFrom).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }))).format('DD/MMM/YYYY');
    const dateTo = moment(convertToUTC(moment(payload.dateTo).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }))).format('DD/MMM/YYYY');
    fileName = `${fileName}-${dateFrom}-${dateTo}.csv`;
    return fileName;
  };

  render() {
    const { formValues } = this.props;
    const {
      pageSize,
      tableData,
      columns,
      total,
      pageNumber,
      loading,
    } = this.state;

    const pages = Math.ceil(total / pageSize);

    return (
      <div>
        <form>
          <div className="date-select">
            <Fields.DateFrom
              name="dateFrom"
              fieldLabel="Date From:"
              onChange={(e, val) => this.onDataChange(val, 'dateFrom')}
              validate={required}
            />
            <Fields.DateTo
              name="dateTo"
              fieldLabel="Date To:"
              onChange={(e, val) => this.onDataChange(val, 'dateTo')}
              validate={required}
              minDate={formValues && formValues.dateFrom}
              maxDate={(formValues && !!formValues.dateFrom) ? moment(formValues.dateFrom).add(5, 'year').toDate() : moment().add(5, 'year').toDate()}
            />
            <AddEntityButton
              label="EXPORT"
              className="export-button add-entity-button__theme--pink"
              onClick={this.handleExportClick}
            />
          </div>
          <div className="date-select">
            <span className="data-select">
              <span>Select:</span>
              {this.generateDataFields(0)}
            </span>
            <div className="total">
              Total:
              {total}
            </div>
          </div>
          <div className={`table-styles${tableData && tableData.length ? '' : ' table-styles--no-data'}`}>
            <DataTable
              loading={loading}
              page={pageNumber - 1 < 0 ? 0 : pageNumber - 1}
              pageSizeOptions={this.calculatePageSizeOptions()}
              pageSize={this.calculatePageSize()}
              onPageSizeChange={(val) => this.onPageSizeChange(val)}
              pages={Number.isNaN(pages) ? 0 : pages}
              columns={(statisticsColumns(columns))}
              showPagination
              data={tableData}
              startRow={2}
              manual
              getTheadThProps={(
                state,
                rowInfo,
                column,
              ) => ({ onClick: () => this.setSorting(Number(column.id)) })}
              onPageChange={(pageIndex) => this.onPageChange(pageIndex + 1)}
            />
          </div>
        </form>
      </div>
    );
  }
}

Filter.propTypes = {
  change: PropTypes.func,
  fetchStatisticsColumnsA: PropTypes.func,
  exportStatisticsA: PropTypes.func,
  initialValues: PropTypes.shape({
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
  }),
  formValues: PropTypes.shape({
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    options1: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
      }),
      PropTypes.string,
    ]),
    options2: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  }),
};

const formConfig = {
  form: 'StatisticsForm',
};

const mapStateToProps = (state) => ({
  initialValues: {
    dateFrom: moment(convertToUTC(moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }))).subtract(1, 'month').toDate(),
    dateTo: moment(convertToUTC(moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }))).toDate(),
  },
  formValues: getFormValues('StatisticsForm')(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchStatisticsColumnsA: bindActionCreators(fetchStatisticsColumns, dispatch),
  exportStatisticsA: bindActionCreators(exportStatistics, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(formConfig)(Filter));
