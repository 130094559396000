import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import reducers from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const logger = createLogger({});
const middlewares = [apiMiddleware, thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = composeEnhancers(middlewareEnhancer);

export default function configureStore(initialState) {
  return createStore(reducers, initialState, enhancers);
}
