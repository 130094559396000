import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFound.scss';

const NotFound = () => (
  <div className="not-found">
    <div className="whoops">Whoops</div>
    <div className="whoops-text">The page you are looking for cannot be found</div>
    <div className="rectangle">
      <Link to="/" className="rectangle" style={{ padding: '0px' }}>Home</Link>
    </div>
  </div>
);

export default NotFound;
