import React from 'react';

const SuccessUpload = () => (
  <div className="upload-modal-content upload-modal-content--success">
    <div className="modal-header">File contents imported successfully</div>
    <i className="material-icons" style={{ 'font-size': '110px', 'margin-top': '5vh' }}>check_circle</i>
  </div>
);

export default React.memo(SuccessUpload);
