import { schema } from 'normalizr';

const instructorSchema = new schema.Entity('instructor', { idAttribute: 'id' });
const accountSchema = new schema.Entity('accounts', { idAttribute: 'id' });
const lessonSchema = new schema.Entity('lessons', { idAttribute: 'id' });

instructorSchema.define({
  accounts: new schema.Array(accountSchema),
  lessons: new schema.Array(lessonSchema),
});

export default instructorSchema;
