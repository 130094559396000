import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import CookieConsent from 'react-cookie-consent';
import Sidemenu from '../components/Sidemenu';
import Drawer from '../components/Drawer';
import Notification from '../components/Notification';
import { notifyError } from '../actions/notificationActions';
import { open } from '../actions/dialogActions';
import Dialog from '../components/Dialog';
import FullscreenAgreementsDialog from '../components/FullscreenAgreementsDialog';
import cookiePolicy from '../agreements/cookiePolicy.pdf';
import '../styles/App.scss';

class CalendarLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      pageNumber: 1,
      numPages: null,
    };
  }

  componentDidUpdate() {
    const { notifyErrorA } = this.props;
    const errorMessage = get(this.props, 'error.errorMessage', '');
    if (errorMessage) {
      notifyErrorA({}, errorMessage);
    }
  }

  handleModalOpenState = () => {
    const { modalOpened } = this.state;
    this.setState({
      modalOpened: !modalOpened,
    });
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  }

  setPageNumber = (pageNo) => {
    this.setState({
      pageNumber: pageNo,
    });
  }

  render() {
    const { modalOpened, pageNumber, numPages } = this.state;
    const { dialog, children } = this.props;

    return (
      <div className="authored-access calendar-page calendar-page__manager">
        <Dialog {...dialog} />
        <Notification />

        <Sidemenu />
        <Drawer width={1000} />
        {children && React.cloneElement(children, this.props)}
        {
          modalOpened
            ? (
              <FullscreenAgreementsDialog
                open={modalOpened}
                pageNumber={pageNumber}
                numPages={numPages}
                handleAgreementsState={this.handleModalOpenState}
                onDocumentLoad={this.onDocumentLoad}
                title="Cookie Policy"
                file={cookiePolicy}
                setPageNumber={this.setPageNumber}
              />
            )
            : (
              <CookieConsent
                containerClasses="cookie-footer"
                contentClasses="cookie-footer--content"
              >
                We use cookies to give you the best experience on site.
                If you want to continue using site please click and agree with our&nbsp;
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={this.handleModalOpenState}
                >
                  cookie policy
                </a>
                .
              </CookieConsent>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  drawer: state.drawer,
  error: state.error,
  dialog: state.dialog,
});

const mapDispatchToProps = (dispatch) => ({
  notifyErrorA: bindActionCreators(notifyError, dispatch),
  openDialog: bindActionCreators(open, dispatch),
});

CalendarLayout.propTypes = {
  notifyErrorA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  dialog: PropTypes.object,
  children: PropTypes.element,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarLayout);
