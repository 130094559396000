import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import { Link } from 'react-router-dom';
import GenderIcon from './GenderIcon';

const NameColumn = ({ entity, route }) => (
  <div className="name-column">
    <Avatar
      icon={<GenderIcon gender={entity.gender} />}
      size={30}
    />
    <span>
      <Link to={{ pathname: `/${route}/${entity.id}/overview` }}>
        {entity.name}
      </Link>
    </span>
  </div>
);

NameColumn.propTypes = {
  entity: PropTypes.shape({
    gender: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  route: PropTypes.string,
};

export default React.memo(NameColumn);
