// -------------------------- *** Modules *** ----------------------------------
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';
import {
  filter,
  find,
  forEach,
  forOwn,
  get,
  merge,
  sortBy,
  uniqBy,
  values,
  omit,
} from 'lodash';
import moment from 'moment-timezone';

// -------------------------- *** Components *** -------------------------------
import RenderIndividualBuyer from '../components/RenderIndividualBuyer';
import RenderGroupBuyer from '../components/RenderGroupBuyer';
import * as Field from '../components/Field';

// ---------------------------- *** Actions *** --------------------------------
import { fetchResort } from '../actions/resortsActions';
import { fetchProduct } from '../actions/productsActions';
import { fetchActivity } from '../actions/activitiesActions';
import {
  fetchSpecialities,
  fetchSpeciality,
} from '../actions/specialityActions';
import { fetchInstructorsByAvailabilityFilter } from '../actions/instructorsActions';

// --------------------------- *** Utils *** -----------------------------------
import {
  mapDateTimeToTime,
  mapGroupsToOptions,
  mapPersonsToOptions,
  mapPropsToOptions,
} from '../utils/map';
import { filterLevelsByWeight, filterProductsByResort } from '../utils/filter';
import { minutesOfDay } from '../utils/helpers';
import {
  convertForCalendar,
  convertToDate,
  convertToUTC,
} from '../utils/dateTime';
import { ageValidator, minValue1 } from '../utils/validators';
import { resolveEntity, resolveSpecialityFromValue, filterSpecialities } from '../utils/formHelpers';

// --------------------------- *** Stylesheets *** -----------------------------
import 'react-dates/lib/css/_datepicker.css';
import '../styles/BookingForm.scss';
import { removeBookingScrollIntoView } from '../actions/userGuide';
import { languageOptionsIdName } from '../constants/LanguageOptions';
import InstructorSelectReadOnly from './InstructorSelectReadOnly';

const Lesson = (props) => {
  const {
    activeLesson,
    type,
    userGuide,
    valueOf,
    lesson,
    edit,
    fieldsDisabled,
    change,
    createBookingPermission,
    product,
    group: propsGroup,
    lessons,
    instructor,
    activity,
    fetchSpecialityA,
    fetchActivityA,
    user,
  } = props;

  const isIndividual = useMemo(() => {
    if (typeof type === 'string') {
      return type === 'individual';
    }
    return type?.value === 'individual';
  }, [type]);

  const [disabledActivity, setDisabledActivity] = useState(false);
  const [disabledSpeciality, setDisabledSpeciality] = useState(false);
  const [group, setGroup] = useState({});
  const [instructors, setInstructors] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [stopInitialInstructorsFetch, setStopInitialInstructorsFetch] = useState(true);
  const [varyTimes, setVaryTimes] = useState(false);
  const [validator, setValidator] = useState([]);

  const activeLessonPrev = useRef();
  const userGuidePrev = useRef();
  const guideElements = useRef({});
  const initial = useRef(true);

  const getInstructorFilter = () => {
    const times = mapDateTimeToTime(valueOf('dateFrom'), valueOf('dateTo'), valueOf('timeFrom'), valueOf('timeTo'));
    const timeFrom = moment(times.timeFrom).set({
      seconds: 0,
      milliseconds: 0,
    }).toISOString();
    const timeTo = moment(times.timeTo).set({
      seconds: 0,
      milliseconds: 0,
    }).toISOString();
    const dateFrom = moment(convertToUTC(valueOf('dateFrom'))).toISOString();
    const dateTo = moment(convertToUTC(valueOf('dateTo'))).toISOString();
    if (timeFrom && timeTo && dateFrom && dateTo) {
      let resort = valueOf('resort') || undefined;
      if (resort) {
        if (resort.id) {
          resort = resort.id;
        } else if (resort.value) {
          resort = resort.value;
        }
      }

      return {
        resort,
        activity: valueOf('activity'),
        speciality: valueOf('speciality'),
        level: get(product, `[${valueOf('product')}].level`) || undefined,
        timeFrom: `${dateFrom.split('T')[0]}T${timeFrom.split('T').pop()}`,
        timeTo: `${dateTo.split('T')[0]}T${timeTo.split('T').pop()}`,
      };
    }
    return {};
  };

  const isTimeFromTimeToValid = (timeFrom, timeTo) => {
    if (!timeFrom || !timeTo) {
      return undefined;
    }
    const t1 = moment(timeFrom);
    const t2 = moment(timeTo);
    return t1 < t2;
  };

  const shouldFetchInstructors = (f) => f.resort && f.timeFrom && f.timeTo && f.activity
    && f.speciality && isTimeFromTimeToValid(f.timeFrom, f.timeTo);

  const fetchInstructors = (f) => {
    const { fetchInstructorsByAvailabilityFilterA } = props;

    fetchInstructorsByAvailabilityFilterA(f).then((response) => {
      const tempInstructors = get(response, 'payload.result.instructor', []);
      const instructorsSorted = sortBy(tempInstructors, (i) => instructor[i.id]?.weight, 'asc');

      setInstructors(mapPersonsToOptions(instructorsSorted));
    });
  };

  const fetchInstructorsIfFilterOk = () => {
    if (!type || !isIndividual) {
      return;
    }
    const f = getInstructorFilter();

    if (shouldFetchInstructors(f)) {
      fetchInstructors(f);
    }
  };

  const updateSpecialities = (a) => {
    const {
      fetchSpecialitiesA,
      speciality,
    } = props;
    const filteredSpecialities = filter(speciality, (s) => s.activity === a);
    let selectedSpeciality;
    if (valueOf('speciality') && !find(filteredSpecialities, (s) => s.id === valueOf('speciality'))) {
      selectedSpeciality = find(speciality, (s) => s.id === valueOf('speciality'));
      if (selectedSpeciality && selectedSpeciality.name === 'Any') {
        selectedSpeciality = undefined;
      }
    }
    const standardSpeciality = find(filteredSpecialities, (s) => s.name === 'Standard');
    if (filteredSpecialities.length && standardSpeciality) {
      let options = mapPropsToOptions(filteredSpecialities, true);
      if (selectedSpeciality) {
        options = [...options, selectedSpeciality];
      }
      setSpecialities(options);
      if (!lessons[0].speciality) {
        change('speciality', standardSpeciality, false);
      }
      fetchInstructorsIfFilterOk();
    } else {
      fetchSpecialitiesA(`/activity/${a}`).then((response) => {
        const { speciality: tempSpeciality } = response.payload.result;
        let options = mapPropsToOptions(tempSpeciality, true);
        if (selectedSpeciality) {
          options = [...options, selectedSpeciality];
        }
        setSpecialities(options);
        if (!lessons[0].speciality) {
          const initialSpeciality = find(tempSpeciality, { name: 'Standard' });
          change('speciality', initialSpeciality, false);
        }
        fetchInstructorsIfFilterOk();
      });
    }
  };

  const changeTimes = (timeFromH, timeFromM, timeToH, timeToM) => {
    const tF = new Date();
    tF.setHours(timeFromH);
    tF.setMinutes(timeFromM);
    const tT = new Date();
    tT.setHours(timeToH);
    tT.setMinutes(timeToM);
    change('timeFrom', tF, false);
    change('timeTo', tT, true);
  };

  useEffect(() => {
    if (valueOf('activity')) {
      updateSpecialities(valueOf('activity'));
    }
    if (stopInitialInstructorsFetch) {
      setStopInitialInstructorsFetch(false);
    }
    window.setTimes = (timeFromH, timeFromM, timeToH, timeToM) => {
      changeTimes(timeFromH, timeFromM, timeToH, timeToM);
    };
  }, []);

  useEffect(() => {
    if (activeLesson !== activeLessonPrev.current && !stopInitialInstructorsFetch) {
      fetchInstructorsIfFilterOk();
    }
    activeLessonPrev.current = activeLesson;
  }, [activeLesson]);

  const checkForVaryTimes = () => {
    const timeFrom = new Date().getTimezoneOffset();
    // 1439 because that equal to 23:59 converted to minutes
    const timeTo = 1439 + new Date().getTimezoneOffset();
    const tempTimeFrom = minutesOfDay(valueOf('timeFrom'));
    const tempTimeTo = minutesOfDay(valueOf('timeTo'));

    if (timeFrom === tempTimeFrom && timeTo === tempTimeTo && !varyTimes) {
      setVaryTimes(true);
    } else if ((timeFrom !== tempTimeFrom || timeTo !== tempTimeTo) && varyTimes) {
      setVaryTimes(false);
    }
  };

  const getActivityFromSpeciality = (id) => {
    const { speciality } = props;

    return find(speciality, (spec) => spec.id === id).activity;
  };

  const handleGroupChange = (item, initialChange) => {
    const {
      fetchResortA,
      onGroupChange,
      index,
    } = props;
    const tempGroup = find(propsGroup, (g) => g.guid === item.value);
    const filteredGroups = filter(propsGroup, (g) => g.guid === item.value);
    const timeFrom = minutesOfDay(tempGroup.timeFrom);
    const timeTo = minutesOfDay(tempGroup.timeTo);
    const tempInstructors = [];
    let equalTimes = true;
    let times = {
      timeFrom: moment({ hours: 0, minutes: 0, seconds: 0 }).toISOString(),
      timeTo: moment({ hours: 23, minutes: 59, seconds: 59 }).toISOString(),
    };

    forEach(filteredGroups, (g) => {
      const gTimeFrom = minutesOfDay(g.timeFrom);
      const gTimeTo = minutesOfDay(g.timeTo);

      if (gTimeFrom !== timeFrom || gTimeTo !== timeTo) {
        equalTimes = false;
      }
    });
    forEach(tempGroup.instructors, (i) => {
      tempInstructors.push(i);
    });
    if (equalTimes) {
      times = {
        timeFrom: moment(convertForCalendar(tempGroup.timeFrom)).toISOString(),
        timeTo: moment(convertForCalendar(tempGroup.timeTo)).toISOString(),
      };
    }
    onGroupChange(index, tempGroup.guid);

    let changes = {
      dateFrom: moment(convertToUTC(tempGroup.timeFrom)).set({
        hours: 0,
        minutes: 0,
        seconds: 0,
      }).toISOString(),
      dateTo: moment(convertToUTC(tempGroup.timeTo)).set({
        hours: 0,
        minutes: 0,
        seconds: 0,
      }).toISOString(),
      language: tempGroup.language,
      meetingPoint: tempGroup.meetingPoint,
      instructor: tempInstructors,
      ...times,
    };
    if (initialChange) {
      changes = omit(changes, ['dateFrom', 'dateTo', 'instructor']);
    }

    resolveSpecialityFromValue(props, change, tempGroup.speciality, false);
    forOwn(changes, (value, key) => {
      change(key, value, false);
    });
    resolveEntity(props, 'resort', tempGroup.resort, fetchResortA).then((resort) => {
      change('resort', { id: resort, name: resort.name }, false);
    });
    updateSpecialities(getActivityFromSpeciality(tempGroup.speciality));

    setGroup(tempGroup);
    setValidator([ageValidator(tempGroup.minAge, tempGroup.maxAge, `Group age ${tempGroup.minAge}-${tempGroup.maxAge}`)]);
    if (!initialChange) {
      return change('group', item);
    }
    return null;
  };

  useEffect(() => {
    if (!isIndividual) {
      checkForVaryTimes();
      if (initial.current) {
        const guid = valueOf('group');
        if (guid) {
          handleGroupChange({ value: guid }, true);
        }
        initial.current = false;
      }
    }
  }, [type]);

  const scrollIntoView = (name, alignTop) => {
    const { removeBookingScrollIntoViewA } = props;
    const el = guideElements.current[name];

    if (el) {
      el.scrollIntoView(alignTop);
      removeBookingScrollIntoViewA();
    }
  };

  useEffect(() => {
    if (userGuide.bookingScrollIntoView && !userGuidePrev.current.bookingScrollIntoView) {
      scrollIntoView(userGuide.bookingScrollIntoView, userGuide.alignTop);
    }
    userGuidePrev.current = userGuide;
  }, [userGuide]);

  const onClientAmountChange = (e, value) => {
    new Promise((resolve) => resolve(change('clientAmount', value, false))).then(() => {
      const length = parseInt(value, 10);

      if (!length || Number.isNaN(length) || length > 50) {
        change('buyers', null, false);
        return;
      }

      const buyers = valueOf('buyers') || [];

      const arr = Array.from(Array(length), () => (isIndividual ? { level: null } : {
        name: '',
        surname: '',
        age: '',
      }));
      const val = merge(arr, buyers).slice(0, length);

      change('buyers', val);
    });
  };

  const onResortChange = (data) => {
    if (!data.initial) {
      change('product', null);
      change('speciality', null);
      change('activity', null);
    }

    change('resort', data.item);
    fetchInstructorsIfFilterOk();
  };

  const onProductChange = (item) => {
    const { fetchProductA, speciality } = props;
    let timeFrom = valueOf('timeFrom');

    return resolveEntity(props, 'product', item.value, fetchProductA).then((p) => {
      if (p.timeRange) {
        timeFrom = convertToDate(p.timeFrom);
        const timeTo = convertToDate(p.timeTo);
        change('timeFrom', timeFrom, false);
        change('timeTo', timeTo, false);
      } else if (timeFrom) {
        change('timeTo', moment(timeFrom).add(p.lessonHours, 'hours').toDate(), false);
        change('dateTo', null, false);
      }
      if (p.speciality) {
        resolveSpecialityFromValue(props, change, p.speciality, true);
      }
      const tempSpecialities = filterSpecialities(speciality, p.speciality);

      if (tempSpecialities.length) {
        setSpecialities(mapPropsToOptions(tempSpecialities, true));
      }
      change('product', item.value);
      fetchInstructorsIfFilterOk();
    });
  };

  const onTimeFromChange = (e, time) => {
    const { fetchProductA } = props;

    const timeFrom = new Date(time);
    const productId = valueOf('product');

    if (productId) {
      resolveEntity(props, 'product', productId, fetchProductA).then((p) => {
        if (!p.timeRange) {
          change('timeTo', moment(timeFrom).add(p.lessonHours - new Date().getTimezoneOffset() / 60, 'hours').toDate(), true);
        }
      });
    }
  };

  const shouldDisableDate = (day) => {
    const { enabledDates } = props;

    return !find(enabledDates,
      (date) => date.getFullYear() === day.getFullYear()
        && date.getMonth() === day.getMonth() && date.getDate() === day.getDate());
  };

  const onDateChange = (field) => (e, date) => {
    const submit = field !== 'dateFrom' || (!!valueOf('dateTo') && !!valueOf('dateFrom'));
    const parsedDate = convertToDate(date);

    if (field === 'timeFrom') {
      onTimeFromChange(e, parsedDate);
    }

    if (!edit && field === 'dateFrom') {
      change('dateTo', date, false);
      guideElements.current.dateTo.getRenderedComponent().focus();
    }

    if (field === 'dateFrom' && parsedDate > valueOf('dateTo')) {
      change('dateTo', null, false);
    }

    if (field === 'dateFrom' && edit && isIndividual && valueOf('id') !== '*') {
      change('dateTo', parsedDate, false);
    }

    if (field === 'dateTo') {
      change('dateTo', date, submit);
    } else {
      change(field, date, submit);
    }
    if (isIndividual) {
      change('instructor', null);
    }
    fetchInstructorsIfFilterOk();
  };

  const onActivityChange = (e, value) => {
    change('activity', value, false);
    if (isIndividual) {
      change('instructor', null);
    }
    updateSpecialities(value);
  };

  const onSpecialityChange = (e, v) => {
    change('speciality', v, false);
    if (isIndividual) {
      change('instructor', null);
    }
    fetchInstructorsIfFilterOk();
  };

  const disableActivity = () => {
    let disabled = true;
    const promises = [];
    if (!isIndividual) {
      disabled = true;
      promises.push(disabled);
    } else if (product[valueOf('product')] && product[valueOf('product')].speciality) {
      promises.push(resolveEntity(props, 'speciality', product[valueOf('product')].speciality, fetchSpecialityA).then((spec) => {
        promises.push(resolveEntity(props, 'activity', spec.activity, fetchActivityA).then((a) => {
          disabled = a.name !== 'Any';
        }));
      }));
    } else {
      disabled = !!(!isIndividual || (product[valueOf('product')] && product[valueOf('product')].speciality));
      promises.push(disabled);
    }

    Promise.all(promises).then(() => {
      if (disabled !== disabledActivity) {
        setDisabledActivity(disabled);
      }
    });
  };

  const disableSpeciality = () => {
    let disabled = true;
    const promises = [];
    if (!isIndividual) {
      disabled = true;
      promises.push(disabled);
    } else if (product[valueOf('product')] && product[valueOf('product')].speciality) {
      promises.push(resolveEntity(props, 'speciality', product[valueOf('product')].speciality, fetchSpecialityA).then((spec) => {
        disabled = spec.name !== 'Any';
      }));
    } else {
      disabled = !!(!isIndividual || (product[valueOf('product')] && product[valueOf('product')].speciality));
      promises.push(disabled);
    }

    Promise.all(promises).then(() => {
      if (disabled !== disabledSpeciality) {
        setDisabledSpeciality(disabled);
      }
    });
  };

  const getInstructorOptions = (options) => {
    if (user.role === 'instructor') {
      return filter(options, (option) => option.id === user.id);
    }
    return options;
  };

  useEffect(() => {
    disableActivity();
    disableSpeciality();
  }, [isIndividual, product[valueOf('product')]?.speciality]);

  return (
    <div>
      <Field.SelectAsync
        name={`${lesson}.resort`}
        fieldLabel="Resort*:"
        onChange={onResortChange}
        disabled={!isIndividual || (fieldsDisabled && !createBookingPermission)}
        theme="white"
        noOptionsMessage="Type to search"
        valueAsInitialOptions
      />
      {isIndividual
        ? (
          <Field.Select
            name={`${lesson}.product`}
            fieldLabel="Product*:"
            options={values(filterProductsByResort(product, valueOf('resort')))}
            onChange={onProductChange}
            theme="white"
            disabled={fieldsDisabled && !createBookingPermission}
          />
        )
        : (
          <Field.Select
            name={`${lesson}.group`}
            fieldLabel="Group*:"
            options={uniqBy(mapGroupsToOptions(propsGroup), 'value')}
            onChange={handleGroupChange}
            theme="white"
            disabled={fieldsDisabled && !createBookingPermission}
          />
        )}
      <Field.Activity
        clearable
        name={`${lesson}.activity`}
        options={filter(values(activity), (val) => val.name !== 'Any')}
        onChange={onActivityChange}
        disabled={disabledActivity || (fieldsDisabled && !createBookingPermission)}
        theme="white"
      />
      <Field.Speciality
        clearable
        name={`${lesson}.speciality`}
        options={specialities}
        onChange={onSpecialityChange}
        disabled={disabledSpeciality || (fieldsDisabled && !createBookingPermission)}
        theme="white"
      />
      <Field.DateFrom
        name={`${lesson}.dateFrom`}
        onChange={onDateChange('dateFrom')}
        theme="white"
        shouldDisableDate={!isIndividual || valueOf('id') === '*' ? shouldDisableDate : null}
        fieldLabel={edit && valueOf('id') !== '*' && lessons.length > 1 ? 'Date:*' : 'Date from:*'}
        disabled={fieldsDisabled && !createBookingPermission}
      />
      {(!edit || valueOf('id') === '*' || lessons.length === 1)
      && (
        <Field.DateTo
          name={`${lesson}.dateTo`}
          minDate={new Date(valueOf('dateFrom'))}
          onChange={onDateChange('dateTo')}
          theme="white"
          shouldDisableDate={!isIndividual || valueOf('id') === '*' ? shouldDisableDate : null}
          refName={(element) => { guideElements.current.dateTo = element; }}
          disabled={fieldsDisabled && !createBookingPermission}
        />
      )}
      <span
        id="lesson-anchor"
        ref={(element) => {
          guideElements.current.lessonAnchor = element;
        }}
      />
      <Field.TimeFrom
        name={`${lesson}.timeFrom`}
        onChange={onDateChange('timeFrom')}
        defaultTime={new Date()}
        disabled={!isIndividual || (product[valueOf('product')] && product[valueOf('product')].timeRange) || (fieldsDisabled && !createBookingPermission)}
        theme="white"
      />
      <Field.TimeTo
        name={`${lesson}.timeTo`}
        disabled
        onChange={onDateChange('timeTo')}
        theme="white"
      />
      {varyTimes
      && (
        <small style={{ color: 'white', paddingLeft: '40px' }}>
          *Time varies
          within selected group
        </small>
      )}
      <Field.Select
        simpleValue
        clearable
        name={`${lesson}.language`}
        fieldLabel="Language:"
        options={languageOptionsIdName}
        disabled={!isIndividual || (fieldsDisabled && !createBookingPermission)}
        theme="white"
      />
      {/*
      <Field.Select
        multi={!isIndividual}
        clearable
        name={`${lesson}.instructor`}
        fieldLabel="Instructor(s):"
        options={isIndividual
          ? getInstructorOptions(instructors)
          : mapPersonsToOptions(instructor, true)}
        onChange={(e, v) => change(v, 'instructor', false)}
        onOpen={instructors.length === 0 ? fetchInstructorsIfFilterOk : () => {}}
        disabled={!isIndividual || (fieldsDisabled && !createBookingPermission)}
        theme="white"
      />
      */}
      {(!isIndividual && group) ? (
        <InstructorSelectReadOnly instructors={group.instructors} />
      ) : (
        <Field.Select
          multi={!isIndividual}
          clearable
          name={`${lesson}.instructor`}
          fieldLabel="Instructor(s):"
          options={isIndividual
            ? getInstructorOptions(instructors)
            : mapPersonsToOptions(instructor, true)}
          onChange={(e, v) => change(v, 'instructor', false)}
          onOpen={instructors.length === 0 ? fetchInstructorsIfFilterOk : () => {}}
          disabled={!isIndividual || (fieldsDisabled && !createBookingPermission)}
          theme="white"
        />
      )}
      <Field.Text
        name={`${lesson}.meetingPoint`}
        fieldLabel="Meeting point:"
        disabled={!isIndividual || (fieldsDisabled && !createBookingPermission)}
        theme="white"
      />
      <Field.Number
        name={`${lesson}.clientAmount`}
        fieldLabel="Client amount:*"
        min="1"
        max="50"
        onChange={onClientAmountChange}
        theme="white"
        disabled={fieldsDisabled && !createBookingPermission}
        validate={minValue1}
      />
      {isIndividual ? (
        <FieldArray
          name={`${lesson}.buyers`}
          component={RenderIndividualBuyer}
          options={filterLevelsByWeight(product[valueOf('product')] ? product[valueOf('product')].level : null)}
          disabled={fieldsDisabled && !createBookingPermission}
        />
      ) : (
        <FieldArray
          name={`${lesson}.buyers`}
          component={RenderGroupBuyer}
          group={group}
          disabled={fieldsDisabled && !createBookingPermission}
          validators={validator}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { entities } = state;
  return {
    product: entities.product,
    group: entities.group,
    activity: entities.activity,
    speciality: entities.speciality,
    instructor: entities.instructor,
    buyers: entities.buyer,
    resort: entities.resort,
    userGuide: state.userGuide,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchProductA: bindActionCreators(fetchProduct, dispatch),
  fetchSpecialitiesA: bindActionCreators(fetchSpecialities, dispatch),
  fetchSpecialityA: bindActionCreators(fetchSpeciality, dispatch),
  fetchActivityA: bindActionCreators(fetchActivity, dispatch),
  fetchInstructorsByAvailabilityFilterA: bindActionCreators(
    fetchInstructorsByAvailabilityFilter,
    dispatch,
  ),
  fetchResortA: bindActionCreators(fetchResort, dispatch),
  removeBookingScrollIntoViewA: bindActionCreators(removeBookingScrollIntoView, dispatch),
});

Lesson.propTypes = {
  activeLesson: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  userGuide: PropTypes.shape({
    bookingScrollIntoView: PropTypes.string,
    alignTop: PropTypes.bool,
  }),
  valueOf: PropTypes.func,
  lesson: PropTypes.string,
  edit: PropTypes.bool,
  fieldsDisabled: PropTypes.bool,
  change: PropTypes.func,
  createBookingPermission: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  group: PropTypes.object,
  lessons: PropTypes.arrayOf(PropTypes.shape({
    speciality: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
    ]),
  })),
  // eslint-disable-next-line react/forbid-prop-types
  instructor: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  activity: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  speciality: PropTypes.object,
  fetchSpecialitiesA: PropTypes.func,
  fetchInstructorsByAvailabilityFilterA: PropTypes.func,
  removeBookingScrollIntoViewA: PropTypes.func,
  fetchProductA: PropTypes.func,
  fetchSpecialityA: PropTypes.func,
  fetchActivityA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  enabledDates: PropTypes.array,
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Lesson));
