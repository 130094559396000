import { schema } from 'normalizr';
import * as types from '../constants/ActionTypes';
import { httpRequest } from '../middleware/http';
import seasonSchema from '../schema/Season';

export const addSeason = (season) => httpRequest({
  path: '/season',
  method: 'POST',
  body: season,
  types: [
    types.SEASONS_POST_REQUEST,
    types.SEASONS_POST_SUCCESS,
    types.SEASONS_POST_FAILURE,
  ],
  schema: seasonSchema,
});

export const deleteSeason = (id) => httpRequest({
  path: `/season/${id}`,
  method: 'DELETE',
  types: [
    types.SEASON_DELETE_REQUEST,
    {
      type: types.SEASON_DELETE_SUCCESS,
      meta: {
        id,
      },
    },
    types.SEASON_DELETE_FAILURE,
  ],
  schema: seasonSchema,
});

export const loadSeasons = () => httpRequest({
  path: '/season',
  method: 'GET',
  types: [
    types.SEASONS_GET_REQUEST,
    types.SEASONS_GET_SUCCESS,
    types.SEASONS_GET_FAILURE,
  ],
  schema: new schema.Array(seasonSchema),
});

export const prepareSeasonEditForm = (id) => httpRequest({
  path: `/season/${id}`,
  method: 'GET',
  types: [
    types.SEASON_GET_REQUEST,
    types.SEASON_GET_SUCCESS,
    types.SEASON_GET_FAILURE,
  ],
  schema: seasonSchema,
});

export const updateSeason = (id, season) => httpRequest({
  path: `/season/${id}`,
  method: 'PUT',
  body: JSON.stringify(season),
  types: [
    types.SEASON_PUT_REQUEST,
    types.SEASON_PUT_SUCCESS,
    types.SEASON_PUT_FAILURE,
  ],
  schema: seasonSchema,
});

export const fetchSeasons = () => httpRequest({
  path: '/season',
  method: 'GET',
  types: [
    types.SEASONS_GET_REQUEST,
    types.SEASONS_GET_SUCCESS,
    types.SEASONS_GET_FAILURE,
  ],
  schema: new schema.Array(seasonSchema),
});

export const fetchSeasonsByProduct = (id) => httpRequest({
  path: `/season/product/${id}`,
  method: 'GET',
  types: [
    types.SEASONS_GET_REQUEST,
    types.SEASONS_GET_SUCCESS,
    types.SEASONS_GET_FAILURE,
  ],
  schema: new schema.Array(seasonSchema),
});
