import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { bookingStyleObject } from '../styles/BookingFormStyles';

const OtherInfo = ({ disabled }) => (
  <Field
    style={bookingStyleObject.specialNeedsTextArea}
    name="otherInfo"
    component="textarea"
    type="textarea"
    placeholder="Info about clients"
    disabled={disabled}
  />
);

OtherInfo.propTypes = {
  disabled: PropTypes.bool,
};

export default OtherInfo;
