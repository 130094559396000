import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'material-ui';
import { includes } from 'lodash';
import CalendarButton from './availability-list-icons/CalendarButton';
import DeleteButton from './availability-list-icons/DeleteButton';
import EditButton from './availability-list-icons/EditButton';

import '../styles/AvailabilityList.scss';

const AvailabilityListRow = ({
  availability,
  handleCheck,
  selected,
  handleEdit,
  handleDelete,
}) => (
  <div className="availability-row">
    <div className="row-box">
      <Checkbox
        onCheck={() => handleCheck(availability.id)}
        checked={includes(selected, availability.id)}
      />
    </div>
    <div className="row-box">
      <CalendarButton />
      <span className="box-item">{availability.dateFrom}</span>
      <span className="box-item">-</span>
      <span className="box-item">{availability.dateTo}</span>
    </div>
    <div className="row-box">
      <span className="box-item small">Available times:</span>
      <span className="box-item">{availability.timeFrom}</span>
      <span className="box-item">-</span>
      <span className="box-item">{availability.timeTo}</span>
    </div>
    <div className="row-box location-text">
      <span className="box-item">{availability.resort}</span>
    </div>
    <div className="row-box">
      <span onClick={handleEdit}><EditButton /></span>
      <span onClick={handleDelete}><DeleteButton /></span>
    </div>
  </div>
);

AvailabilityListRow.propTypes = {
  availability: PropTypes.shape({
    id: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    resort: PropTypes.string,
  }),
  handleCheck: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default React.memo(AvailabilityListRow);
