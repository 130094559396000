import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ContentRemove from 'material-ui/svg-icons/content/remove';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import RenderField from '../../components/RenderField';

const RenderRow = ({
  fields,
  currency,
  removeRow,
  handleSizeChange,
  index,
}) => (
  fields.getAll().length
    ? (
      <tr>
        <td>
          <div className="season-error season-pricing__group-size">
            <Field
              component={RenderField}
              name={`${fields.name}[0].size`}
              type="number"
              min={1}
              onChange={handleSizeChange(index)}
            />
          </div>
        </td>
        {fields.map((row, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <td key={`price_${i}`}>
            <div className="season-pricing__-price-wrapper">
              <div className="season-error season-pricing__price-input">
                <label style={{ textAlign: 'center' }}>{currency}</label>
                <Field
                  component={RenderField}
                  name={`${row}.price`}
                  type="number"
                  min={0}
                  divStyle={{ display: 'inline-block' }}
                />
              </div>
            </div>
          </td>
        ))}
        <td>
          <div className="season-pricing__delete-row">
            <FloatingActionButton
              mini
              className="floating-button"
              backgroundColor="#3A93D9"
              onClick={removeRow}
            >
              <ContentRemove />
            </FloatingActionButton>
            <span style={{ size: '9px' }} onClick={removeRow}>
              Delete price row
            </span>
          </div>

        </td>
      </tr>
    ) : null
);

RenderRow.propTypes = {
  fields: PropTypes.shape({
    getAll: PropTypes.func,
    name: PropTypes.string,
    map: PropTypes.func,
  }),
  currency: PropTypes.string,
  removeRow: PropTypes.func,
  handleSizeChange: PropTypes.func,
  index: PropTypes.number,
};

export default RenderRow;
