// -------------------------- *** Modules *** ----------------------------------
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  FieldArray,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  forEach,
  get,
  isBoolean,
  keys,
  map,
  maxBy,
  merge,
  minBy,
  values as lodashValues,
  isEmpty,
} from 'lodash';
import moment from 'moment';
import { Checkbox as ReduxCheckbox } from 'redux-form-material-ui';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import ContentAdd from 'material-ui/svg-icons/content/add';
import ContentRemove from 'material-ui/svg-icons/content/remove';

// -------------------------- *** Containers *** -------------------------------
import LessonBlock from './LessonBlock';

// -------------------------- *** Components *** -------------------------------
import RenderSelect from '../components/RenderSelect';
import FormField from '../components/FormField';
import RenderBookingServices from '../components/RenderBookingServices';
import RenderToggle from '../components/RenderToggle';
import ClientForm from '../components/ClientForm';
import BookingPartnerManagerForm from '../components/BookingPartnerManagerForm';
import SpecialNeeds from '../components/SpecialNeeds';
import OtherInfo from '../components/OtherInfo';
import Prepayment from '../components/Prepayment';
import FloatingContentBlock from '../components/FloatingContentBlock';
import UserGuideIcon from '../components/UserGuideIcon';

// ---------------------------- *** Actions *** --------------------------------
import { fetchHotels } from '../actions/hotelsActions';
import { createClient } from '../actions/clientsActions';
import { createPartnerManager } from '../actions/partnerManagerActions';
import { createPartner, fetchPartner } from '../actions/partnerActions';
import {
  cancelBooking,
  createBooking,
  fetchBookingPrice,
  fetchBookingsUsedAmount,
  revertCanceledBooking,
  updateBooking,
  updateComment,
} from '../actions/bookingActions';
import { fetchEventsByFilter } from '../actions/eventActions';
import { hideDrawer } from '../actions/drawerActions';
import { notify, notifySuccess } from '../actions/notificationActions';
import { removeBookingScrollIntoView } from '../actions/userGuide';
// --------------------------- *** Utils *** -----------------------------------
import {
  asyncErrorMapper,
  discount,
  number,
  required,
} from '../utils/validators';
import {
  mapBookingServicesToPayload,
  mapLessonBlockToPayload,
  mapPersonsToOptions,
} from '../utils/map';
import { formatCurrency } from '../utils/format';

// --------------------------- *** Stylesheets *** -----------------------------
import { bookingStyleObject } from '../styles/BookingFormStyles';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/BookingForm.scss';
import 'react-select/dist/react-select.css';

const requiredFields = [
  'resort',
  'activity',
  'speciality',
  'dateFrom',
  'dateTo',
  'timeFrom',
  'timeTo',
  'clientAmount',
];

const validateLessons = (values) => {
  const errors = {};

  if (!values) return undefined;

  values.forEach((lessonBlock, blockIndex) => {
    const blockErrors = {};
    forEach(lessonBlock.lessons, (lesson, lessonIndex) => {
      const lessonErrors = {};
      forEach(requiredFields, (field) => {
        if (!lesson[field]) {
          lessonErrors[field] = 'This field is required';
        }
      });
      if (lessonBlock.type) {
        if ((lessonBlock.type === 'group' || lessonBlock.type.value === 'group') && !lesson.group) {
          lessonErrors.group = 'This is required field';
        }
        if ((lessonBlock.type === 'individual' || lessonBlock.type.value === 'individual') && !lesson.product) {
          lessonErrors.product = 'This is required field';
        }
      }
      if (lesson.dateTo && lesson.dateFrom && !(moment(lesson.dateTo).isSameOrAfter(moment(lesson.dateFrom), 'day'))) {
        lessonErrors.dateTo = 'End date should be greater than start date';
      }
      if (keys(lessonErrors).length) {
        blockErrors[lessonIndex] = lessonErrors;
      }
    });
    if (!lessonBlock.type
      || (lessonBlock.type !== 'individual' && lessonBlock.type.value !== 'individual'
        && lessonBlock.type !== 'group' && lessonBlock.type.value !== 'group')) {
      blockErrors.type = 'This field is required';
    }
    if (discount(lessonBlock.discount)) {
      blockErrors.discount = 'Invalid discount';
    }
    if (keys(blockErrors).length) {
      errors[blockIndex] = blockErrors;
    }
  });
  return keys(errors).length ? errors : undefined;
};

const validateBookingServices = (values) => {
  const errors = {};

  if (!values) return undefined;

  values.forEach((bookingService, bookingServiceIndex) => {
    const bookingServiceErrors = {};
    forEach(['service', 'price'], (field) => {
      if (!bookingService[field]) {
        bookingServiceErrors[field] = 'This field is required';
      }
    });
    if (number(bookingService.price)) {
      bookingServiceErrors.number = 'Must be a number';
    }
    if (discount(bookingService.discount)) {
      bookingServiceErrors.discount = 'Invalid discount';
    }
    if (keys(bookingServiceErrors).length) {
      errors[bookingServiceIndex] = bookingServiceErrors;
    }
  });

  return keys(errors).length ? errors : undefined;
};

const BookingForm = ({
  actions,
  initialValues,
  currency,
  hotelList,
  partnerManagerList,
  formValues,
  change,
  submitErrors,
  user,
  edit,
  instructor,
  userGuide,
  clientList,
  bookingPage,
  refreshBookingTable,
  openDialog,
  updateCalendarView,
  handleSubmit,
  pristine,
  submitting,
  prepayment,
  setUpdateFilter,
}) => {
  const [
    openPartnerManagerBlock,
    setOpenPartnerManagerBlock,
  ] = useState(!!initialValues.partnerManager);
  const [openClientForm, setOpenClientForm] = useState(false);
  const [openPartnerManagerForm, setOpenPartnerManagerForm] = useState(false);
  const [clients, setClients] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [partnerManagers, setPartnerManagers] = useState([]);
  const [lessonBlocks, setLessonBlocks] = useState([]);
  const [price, setPrice] = useState(formatCurrency(initialValues.total || 0, currency));
  const [appliedPartnerManagerCommission, setAppliedPartnerManagerCommission] = useState(null);
  const [listenForChanges, setListenForChanges] = useState(true);

  const [
    bookingServiceOpened,
    prepaymentOpened,
    commentOpened,
    specialNeedsOpened,
    fieldsDisabled,
    createBookingPermission,
    seePricePermission,
  ] = useMemo(() => [
    initialValues.bookingServices,
    initialValues.prepayment || initialValues.prepaymentType || initialValues.fullpaymentType,
    initialValues.otherInfo,
    initialValues.specialClientNeeds,
    user.role !== 'manager',
    !edit && user && user.id && instructor[user.id] && instructor[user.id].createBookingPermission,
    (user && user.id && instructor[user.id] && instructor[user.id].createBookingPermission) || (user && user.role === 'manager'),
  ], []);

  const userGuidePrev = useRef();
  const guideElements = useRef({});

  const onChange = (field, item) => change(field, item);

  const recalculateBookingPrice = () => {
    if (formValues) {
      // TODO Totally worst approach. Needs new validation solution to avoid this
      const tempLessonBlocks = map(lessonBlocks, (lessonBlock) => {
        const lessons = [];
        let payload;
        if (lessonBlock.type === 'group') {
          payload = lessonBlock;
        } else {
          payload = mapLessonBlockToPayload(lessonBlock);
        }

        forEach(payload.lessons, (lesson) => {
          const tempLesson = lesson;
          if (tempLesson.id !== '*') {
            lessons.push(tempLesson);
          }
          if (lessonBlock.type === 'individual') {
            delete tempLesson.group;
            delete tempLesson.instructor;
            delete tempLesson.language;
            delete tempLesson.meetingPoint;
          }
        });

        return {
          ...payload,
          lessons,
        };
      });

      const payload = {
        ...formValues,
        bookingServices: mapBookingServicesToPayload(formValues.bookingServices),
        lessonBlocks: tempLessonBlocks,
      };
      if (payload.partnerManager && typeof payload.partnerManager === 'object') {
        payload.partnerManager = payload.partnerManager.value || payload.partnerManager.id;
      }

      actions.fetchBookingPrice(payload).then((response) => {
        setPrice(formatCurrency(response.payload.result.price, currency));
      });
    }
  };

  useEffect(() => {
    if (listenForChanges && !isEmpty(formValues)) {
      recalculateBookingPrice();
      setListenForChanges(false);
    }
  }, [formValues]);

  const onPartnerManagerChange = (partnerManager, partnerManagerObj) => {
    if (partnerManager) {
      const partnerId = partnerManagerObj ? partnerManagerObj.partner
        : partnerManagerList[partnerManager].partner;
      actions.fetchPartner(partnerId).then((response) => {
        const { partner } = response.payload.result;
        const values = lodashValues(partner)[0];

        setAppliedPartnerManagerCommission(values.commission);
        onChange('partnerManager', partnerManager)
          .then(() => {
            if (formValues.partnerManagerCommission) {
              setListenForChanges(true);
            }
          });
      });
    } else {
      change('partnerManagerCommission', false);
    }
  };

  useEffect(() => {
    const hotelListLength = Object.keys(hotelList).length;

    onPartnerManagerChange(initialValues.partnerManager);
    if (!hotelListLength) {
      actions.fetchHotels();
    }
  }, []);

  const updatePartnerManagerBlock = () => {
    setOpenPartnerManagerBlock(!openPartnerManagerBlock);

    if (!openPartnerManagerBlock) {
      Promise.all([
        change('partnerManager', null),
        change('partnerManagerCommission', false),
      ]).then(() => {
        setAppliedPartnerManagerCommission(null);
        setListenForChanges(true);
      });
    }
  };

  const beforeBookingServiceOpen = () => {
    const { bookingServices } = formValues;
    let values = [];

    if (bookingServices) {
      values = [
        ...bookingServices,
      ];
    }

    if (values.length === 0) {
      values.push({});
    }

    change('bookingServices', values);
  };

  const scrollIntoView = (name, alignTop) => {
    const el = guideElements.current[name];

    if (el) {
      el.scrollIntoView(alignTop);
      actions.removeBookingScrollIntoView();
    }
  };

  useEffect(() => {
    if (userGuide) {
      if (userGuide.triggerAddNewClientOpen && !userGuidePrev.current.triggerAddNewClientOpen) {
        setOpenClientForm(true);
      }
      if (userGuide.triggerAddNewPartnerOpen && !userGuidePrev.current.triggerAddNewPartnerOpen) {
        setOpenPartnerManagerForm(true);
      }
      if (userGuide.triggerClientFromPartnerOpen
        && !userGuidePrev.current.triggerClientFromPartnerOpen) {
        updatePartnerManagerBlock();
      }
      if (userGuide.triggerAdditionalServiceOpen
        && !userGuidePrev.current.triggerAdditionalServiceOpen) {
        beforeBookingServiceOpen();
      }
      if (userGuide.bookingScrollIntoView && !userGuidePrev.current.bookingScrollIntoView) {
        scrollIntoView(userGuide.bookingScrollIntoView, userGuide.alignTop);
      }
    }
    userGuidePrev.current = userGuide;
  }, [userGuide]);

  useEffect(() => {
    setClients(lodashValues(clientList));
  }, [clientList]);

  useEffect(() => {
    setHotels(lodashValues(hotelList));
  }, [hotelList]);

  // useEffect(() => {
  //   console.log('sendClientInvoiceToManager', formValues);
  //   recalculateBookingPrice();
  // }, [formValues.sendClientInvoiceToManager]);

  const fixLessonBlocks = (blocks) => {
    const fixed = { ...blocks };
    forEach(fixed, (lessonBlock, indexBlock) => {
      const { lessons } = lessonBlock;
      forEach(lessons, (lesson, indexLesson) => {
        const tempLesson = lesson;
        const { activity, speciality } = tempLesson;
        if (typeof activity !== 'number' && activity && activity.id) {
          tempLesson.activity = activity ? activity.id : undefined;
          change(`lessonBlocks[${indexBlock}].lessons[${indexLesson}].activity`, tempLesson.activity);
        }
        if (typeof speciality !== 'number') {
          tempLesson.speciality = speciality ? speciality.id : 0;
          change(`lessonBlocks[${indexBlock}].lessons[${indexLesson}].speciality`, tempLesson.speciality);
        }
      });
    });
    return fixed;
  };

  useEffect(() => {
    setLessonBlocks(fixLessonBlocks(merge(initialValues.lessonBlocks, lessonBlocks)));
  }, [initialValues.lessonBlocks]);

  useEffect(() => {
    setPartnerManagers(lodashValues(partnerManagerList));
  }, [partnerManagerList]);

  const onCommissionToggle = (e, value) => {
    change('partnerManagerCommission', value);
    setListenForChanges(true);
  };

  const onSendClientInvoiceToManagerToggle = (e, value) => {
    change('sendClientInvoiceToManager', value);
    setListenForChanges(true);
  };

  const handleRevertButtonSubmit = () => {
    actions.revertCanceledBooking(initialValues.id).then(() => {
      actions.notifySuccess({}, 'Booking reverted successfully');
      actions.hideDrawer();
      actions.fetchBookingsUsedAmount();
      if (bookingPage) {
        refreshBookingTable();
      }
    });
  };

  const handleCancelButtonSubmit = () => {
    actions.cancelBooking(initialValues.id, { notifyClient: formValues.notifyClient }).then(() => {
      if (setUpdateFilter) {
        setUpdateFilter();
      } else {
        actions.fetchEventsByFilter();
      }
      actions.notifySuccess({}, 'Booking canceled successfully');
      actions.hideDrawer();
      actions.fetchBookingsUsedAmount();
      if (bookingPage) {
        refreshBookingTable();
      }
    });
  };

  const onUpdateButtonClick = () => {
    const body = {
      otherInfo: formValues.otherInfo,
    };

    return actions.updateComment(initialValues.id, body).then(() => {
      actions.notifySuccess({}, 'Comment updated successfully');
      if (bookingPage) {
        refreshBookingTable();
      }
    });
  };

  const callbackSelection = () => {
    if (initialValues.status === 'canceled') {
      return openDialog(handleRevertButtonSubmit, null, null, 'revert');
    }
    if (fieldsDisabled) {
      onUpdateButtonClick();
      return undefined;
    }
    return openDialog(handleCancelButtonSubmit, null, null, 'cancel');
  };

  const handleError = (err) => {
    if (
      err.data
      && err.data.constraintViolations
      && (err.data.constraintViolations[0].property === '' || err.data.constraintViolations[0].property.indexOf(']') !== -1)
    ) {
      actions.notify('error', {
        title: err.data.constraintViolations[0].message,
      });
    }
  };

  const getMomentDateTime = (lesson, dateKey, timeKey) => moment(lesson[dateKey])
    .set('hour', moment(lesson[timeKey]).get('hour'))
    .set('minute', moment(lesson[timeKey]).get('minute'));

  const onSubmit = (values) => {
    let sendClientInvoiceToManager = get(values, 'sendClientInvoiceToManager', false);

    if (typeof sendClientInvoiceToManager === 'string' && sendClientInvoiceToManager.length === 0) {
      sendClientInvoiceToManager = false;
    }

    const formattedValues = {
      ...values,
      lessonBlocks: map(values.lessonBlocks, (lessonBlock) => mapLessonBlockToPayload(lessonBlock)),
      bookingServices: mapBookingServicesToPayload(values.bookingServices),
      sendClientInvoiceToManager,
    };

    const lessons = get(values, 'lessonBlocks[0].lessons', []);
    const earliestLesson = minBy(map(lessons),
      (lesson) => getMomentDateTime(lesson, 'dateFrom', 'timeFrom').valueOf());
    const earliest = getMomentDateTime(earliestLesson, 'dateFrom', 'timeFrom');
    const latestLesson = maxBy(map(lessons),
      (lesson) => getMomentDateTime(lesson, 'dateTo', 'timeTo').valueOf());
    const latest = getMomentDateTime(latestLesson, 'dateTo', 'timeTo');

    if (!parseInt(formattedValues.prepayment, 10)) {
      formattedValues.prepayment = 0;
    }

    let promise;

    if (edit) {
      promise = actions.updateBooking(initialValues.id, formattedValues);
    } else {
      promise = actions.createBooking(formattedValues);
    }

    return promise.then(() => {
      if (user.role !== 'instructor' && user.role !== 'headCoach') {
        actions.fetchBookingsUsedAmount();
      }
      if (setUpdateFilter) {
        setUpdateFilter();
      } else {
        actions.fetchEventsByFilter();
      }
      actions.hideDrawer();
      if (bookingPage) {
        refreshBookingTable();
      }
      actions.notifySuccess({}, `Booking ${edit ? 'updated' : 'created'} successfully`);
      if (earliest && latest && updateCalendarView) {
        updateCalendarView(earliest, latest);
      }
    }).catch((err) => {
      if (
        err.data
        && err.data.constraintViolations
        && err.data.constraintViolations[0].property.indexOf(']') !== -1
      ) {
        handleError(err);
      }
    });
  };

  const onLessonBlockSubmit = (index, values) => {
    const tempLessonBlocks = lessonBlocks;

    if (!values) {
      tempLessonBlocks.splice(index, 1);
    } else {
      tempLessonBlocks[index] = values;
    }

    setLessonBlocks(tempLessonBlocks);
    if (!listenForChanges) {
      recalculateBookingPrice();
    }
  };

  const mapPhoneNumberErrors = (errors) => {
    const tempErrors = errors;
    const error = tempErrors['phoneNumbers[phoneNumber]'];

    if (error) {
      tempErrors.phoneNumbers = error;
      delete tempErrors['phoneNumbers[phoneNumber]'];
    }

    return tempErrors;
  };

  const onClientSubmit = (values) => {
    const newClient = {
      ...values,
      gender: 'other',
      phoneNumbers: [{
        phoneNumber: values.phoneNumbers,
      }],
      address: {
        country: values.country,
      },
    };
    delete newClient.country;

    return actions.createClient(newClient).then((response) => {
      const { client } = response.payload.result;
      setOpenClientForm(false);
      onChange('client', lodashValues(client)[0].id);
    }).catch((err) => {
      const errors = mapPhoneNumberErrors(asyncErrorMapper(err));
      throw new SubmissionError(errors);
    });
  };

  const onPartnerManagerSubmit = (values) => {
    const { partner } = values;

    return new Promise((resolve, reject) => {
      if (!Number.isInteger(partner.id)) {
        actions.createPartner({
          name: partner.name,
          commission: values.commission,
          commissionType: 'percent',
        }).then((response) => {
          const { payload: { result: { partner: responsePartner } } } = response;
          actions.createPartnerManager({
            ...values,
            gender: 'other',
            partner: lodashValues(responsePartner)[0].id,
            phoneNumbers: [{
              phoneNumber: values.phoneNumbers,
            }],
          }).then(resolve, reject);
        });
      } else {
        actions.createPartnerManager({
          ...values,
          gender: 'other',
          partner: partner.id,
          phoneNumbers: [{
            phoneNumber: values.phoneNumbers,
          }],
        }).then(resolve, reject);
      }
    }).then((response) => {
      const { partnerManager } = response.payload.result;
      const vals = lodashValues(partnerManager)[0];
      setOpenPartnerManagerForm(false);
      onPartnerManagerChange(vals.id, vals);
    }).catch((err) => {
      const errors = mapPhoneNumberErrors(asyncErrorMapper(err));
      throw new SubmissionError(errors);
    });
  };

  const handleAdditionalServiceChange = () => {
    setListenForChanges(true);
  };

  const canceledButtonText = useMemo(() => {
    if (submitting) {
      if (edit) {
        return 'UPDATING...';
      }
      return 'SAVING...';
    }
    if (edit) {
      return 'UPDATE';
    }
    return 'BOOK';
  }, [submitting, edit]);

  const preopened = useMemo(() => userGuide && userGuide.currentGuide === 'createBooking', [userGuide]);

  return (
    <div className="BookingForm">
      <span
        className="BookingForm__title"
        ref={(element) => {
          guideElements.current.formTop = element;
        }}
      >
        {edit ? `Booking B-${initialValues.bid}` : 'New Booking'}
        {initialValues.status === 'canceled' ? ' (Canceled)' : ''}
        <UserGuideIcon type="createBooking" />
      </span>
      <form>
        <FormField label="Client*:">
          <span id="client-anchor" />
          <Field
            simpleValue
            clearable
            name="client"
            component={RenderSelect}
            options={mapPersonsToOptions(clients)}
            onChange={(e, v) => onChange('client', v)}
            value={formValues.client}
            disabled={fieldsDisabled && !createBookingPermission}
            validate={[required]}
          />
        </FormField>
        {formValues.client
        && (
          <FormField label="Email:">
            <span
              style={{ paddingLeft: '7px' }}
            >
              {clientList[formValues.client].email}
            </span>
          </FormField>
        )}
        {(!fieldsDisabled || createBookingPermission)
        && (
          <div>
            <div className="BookingForm__addnew-button">
              <FloatingActionButton
                className="floating-button-small"
                backgroundColor="#01579B"
                onClick={() => setOpenClientForm(!openClientForm)}
              >
                {openClientForm ? <ContentRemove /> : <ContentAdd />}
              </FloatingActionButton>
              <label className="addnew-label">Add new client</label>
            </div>
            {openClientForm
            && <ClientForm onSubmit={onClientSubmit} />}
          </div>
        )}
        <FormField label="Hotel:">
          <span
            id="add-hotel-anchor"
            ref={(element) => {
              guideElements.current.hotel = element;
            }}
          />
          <Field
            simpleValue
            clearable
            name="hotel"
            component={RenderSelect}
            options={hotels}
            onChange={(e, v) => onChange('hotel', v)}
            value={formValues.hotel}
            disabled={fieldsDisabled && !createBookingPermission}
          />
        </FormField>
        {!fieldsDisabled
        && (
          <div>
            <div className="BookingForm__clientFromPartner-box">
              <div className="BookingForm__addnew-button">
                <FloatingActionButton
                  className="floating-button"
                  mini
                  backgroundColor="#01579B"
                  onClick={updatePartnerManagerBlock}
                >
                  {openPartnerManagerBlock ? <ContentRemove />
                    : <ContentAdd />}
                </FloatingActionButton>
                <label className="client-from-partner-label">
                  Client from
                  partner
                </label>
              </div>
              {openPartnerManagerBlock
              && (
                <div>
                  <FormField label="Manager:">
                    <Field
                      clearable
                      name="partnerManager"
                      component={RenderSelect}
                      options={mapPersonsToOptions(partnerManagers, true)}
                      onChange={(item) => onPartnerManagerChange(item?.value)}
                      value={formValues.partnerManager}
                      validate={edit ? [] : [required]}
                    />
                  </FormField>
                  <div className="BookingForm__toggle">
                    <Field
                      component={RenderToggle}
                      name="partnerManagerCommission"
                      onChange={(e, v) => onCommissionToggle(e, v)}
                      value={formValues.partnerManagerCommission}
                      normalize={(v) => (isBoolean(v) ? v : false)}
                      wrapperClass="commission"
                      commission={appliedPartnerManagerCommission && `${appliedPartnerManagerCommission}%`}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '3em',
                      marginBottom: '1em',
                    }}
                    id="invoicing-anchor"
                  >
                    {formValues.client
                    && (
                      <Field
                        component={ReduxCheckbox}
                        name="sendClientInvoiceToManager"
                        onChange={(e, v) => onSendClientInvoiceToManagerToggle(e, v)}
                        checked={formValues.sendClientInvoiceToManager}
                        label="Invoice issued for Client"
                      />
                    )}
                  </div>
                  <div className="BookingForm__addnew-button">
                    <FloatingActionButton
                      className="floating-button-small"
                      backgroundColor="#01579B"
                      onClick={() => setOpenPartnerManagerForm(!openPartnerManagerForm)}
                    >
                      {openPartnerManagerForm ? <ContentRemove />
                        : <ContentAdd />}
                      <span
                        id="new-partner-manager-anchor"
                        ref={(element) => {
                          guideElements.current.newPartnerManager = element;
                        }}
                      />
                    </FloatingActionButton>
                    <label className="addnew-label">Add new manager</label>
                  </div>
                  {openPartnerManagerForm
                  && (
                    <BookingPartnerManagerForm
                      onSubmit={onPartnerManagerSubmit}
                    />
                  )}
                </div>
              )}
              <br />
            </div>
          </div>
        )}
        <label
          className="booking-section-label-lesson"
          ref={(element) => {
            guideElements.current.lessonTop = element;
          }}
        >
          Lesson
        </label>
        <FieldArray
          name="lessonBlocks"
          component={LessonBlock}
          edit={edit}
          currency={currency}
          change={change}
          onLessonSubmit={onLessonBlockSubmit}
          validate={validateLessons}
          fieldsDisabled={fieldsDisabled}
          createBookingPermission={createBookingPermission}
          seePricePermission={seePricePermission}
        />
        <span
          ref={(element) => {
            guideElements.current.lessonBlockBottom = element;
          }}
          id="special-needs-anchor"
        />
        {(!fieldsDisabled || createBookingPermission)
        && (
          <FloatingContentBlock
            label="Special needs"
            openedByDefault={specialNeedsOpened || preopened}
            preopen={preopened}
          >
            <SpecialNeeds />
          </FloatingContentBlock>
        )}
        <span
          id="additional-service-add-anchor"
          ref={(element) => {
            guideElements.current.additionalServiceAnchor = element;
          }}
        />
        {(!fieldsDisabled || createBookingPermission)
        && (
          <FloatingContentBlock
            label="Additional services"
            openedByDefault={bookingServiceOpened || preopened}
            beforeOpen={beforeBookingServiceOpen}
            preopen={preopened}
          >
            <FieldArray
              name="bookingServices"
              component={RenderBookingServices}
              change={change}
              onChange={handleAdditionalServiceChange}
              validate={validateBookingServices}
              bookingServices={formValues.bookingServices}
              preopen={preopened}
            />
          </FloatingContentBlock>
        )}
        <span id="comment-anchor" />
        <FloatingContentBlock
          label="Comment"
          openedByDefault={commentOpened || preopened}
          preopen={preopened}
        >
          <OtherInfo />
        </FloatingContentBlock>

        {!fieldsDisabled
        && (
          <FloatingContentBlock
            label="Prepayment"
            openedByDefault={prepaymentOpened || preopened}
            disabled={fieldsDisabled}
            preopen={preopened}
          >
            <Prepayment
              prepayment={prepayment}
              change={change}
              disabled={fieldsDisabled}
            />
          </FloatingContentBlock>
        )}
        <div>
          {seePricePermission
          && (
            <div className="booking-form-total">
              <div className="booking-form-total-label">
                TOTAL:
              </div>
              <div className="booking-form-total-value">
                {price}
              </div>
            </div>
          )}
          <div className="BookingForm__footer-checkbox">
            <Checkbox
              onCheck={(e, checked) => onChange('notifyClient', checked)}
              disabled={fieldsDisabled && !createBookingPermission}
            />
            <label>Notify client/manager about booking</label>
          </div>
        </div>
        {(!fieldsDisabled || createBookingPermission)
          ? (
            <div className="BookingForm__submit">
              <span
                ref={(element) => {
                  guideElements.current.drawerBottom = element;
                }}
                id="book-anchor"
              />
              {initialValues.status !== 'canceled'
              && (
                <RaisedButton
                  backgroundColor="#01579B"
                  label={canceledButtonText}
                  labelStyle={bookingStyleObject.bookButton.label}
                  style={bookingStyleObject.bookButton}
                  onClick={handleSubmit(onSubmit)}
                  disabled={(!edit && pristine) || submitting || !isEmpty(submitErrors)}
                />
              )}
              {edit
              && (
                <RaisedButton
                  backgroundColor="#e83c3c"
                  label={initialValues.status === 'canceled' ? 'REVERT BOOKING' : 'CANCEL BOOKING'}
                  labelStyle={bookingStyleObject.cancelButton.label}
                  style={bookingStyleObject.cancelButton}
                  onClick={() => callbackSelection()}
                  disabled={(!edit && pristine) || submitting || !isEmpty(submitErrors)}
                />
              )}
            </div>
          )
          : (
            <div className="BookingForm__submit">
              <RaisedButton
                backgroundColor="#01579B"
                label="UPDATE COMMENT"
                labelStyle={bookingStyleObject.cancelButton.label}
                style={bookingStyleObject.cancelButton}
                onClick={() => callbackSelection()}
                disabled={submitting}
              />
            </div>
          )}
      </form>
    </div>
  );
};

const formConfig = {
  form: 'BookingForm',
};

const selector = formValueSelector('BookingForm');

const mapStateToProps = (state) => {
  const { entities } = state;
  return {
    clientList: entities.client,
    partnerManagerList: entities.partnerManager,
    hotelList: entities.hotel,
    partnerList: entities.partner,
    user: state.user,
    formValues: selector(state, 'client', 'hotel', 'partnerManager', 'partnerManagerCommission', 'sendClientInvoiceToManager', 'lessonBlocks', 'bookingServices', 'notifyClient', 'otherInfo'),
    prepayment: selector(state, 'prepaymentType', 'fullpaymentType'),
    userGuide: state.userGuide,
    instructor: state.entities.instructor,
    submitErrors: getFormSyncErrors('BookingForm')(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    createClient,
    createPartner,
    createPartnerManager,
    fetchHotels,
    fetchPartner,
    createBooking,
    updateBooking,
    cancelBooking,
    fetchBookingPrice,
    hideDrawer,
    fetchEventsByFilter,
    notify,
    notifySuccess,
    revertCanceledBooking,
    updateComment,
    removeBookingScrollIntoView,
    fetchBookingsUsedAmount,
  }, dispatch),
});

BookingForm.propTypes = {
  actions: PropTypes.shape({
    createClient: PropTypes.func,
    createPartner: PropTypes.func,
    createPartnerManager: PropTypes.func,
    fetchHotels: PropTypes.func,
    fetchPartner: PropTypes.func,
    createBooking: PropTypes.func,
    updateBooking: PropTypes.func,
    cancelBooking: PropTypes.func,
    fetchBookingPrice: PropTypes.func,
    hideDrawer: PropTypes.func,
    fetchEventsByFilter: PropTypes.func,
    notify: PropTypes.func,
    notifySuccess: PropTypes.func,
    revertCanceledBooking: PropTypes.func,
    updateComment: PropTypes.func,
    removeBookingScrollIntoView: PropTypes.func,
    fetchBookingsUsedAmount: PropTypes.func,
  }),
  initialValues: PropTypes.shape({
    bid: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    partnerManager: PropTypes.number,
    total: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    bookingServices: PropTypes.array,
    prepayment: PropTypes.string,
    prepaymentType: PropTypes.string,
    fullpaymentType: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    otherInfo: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    specialClientNeeds: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    lessonBlocks: PropTypes.array,
    id: PropTypes.string,
    status: PropTypes.string,
  }),
  currency: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  hotelList: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  partnerManagerList: PropTypes.object,
  formValues: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    bookingServices: PropTypes.array,
    partnerManagerCommission: PropTypes.bool,
    notifyClient: PropTypes.bool,
    otherInfo: PropTypes.string,
    client: PropTypes.number,
    hotel: PropTypes.number,
    partnerManager: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
    ]),
    sendClientInvoiceToManager: PropTypes.bool,
  }),
  change: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  submitErrors: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  edit: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  instructor: PropTypes.object,
  userGuide: PropTypes.shape({
    triggerAddNewClientOpen: PropTypes.bool,
    triggerAddNewPartnerOpen: PropTypes.bool,
    triggerClientFromPartnerOpen: PropTypes.bool,
    triggerAdditionalServiceOpen: PropTypes.bool,
    bookingScrollIntoView: PropTypes.string,
    currentGuide: PropTypes.string,
    alignTop: PropTypes.bool,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  clientList: PropTypes.object,
  bookingPage: PropTypes.bool,
  refreshBookingTable: PropTypes.func,
  openDialog: PropTypes.func,
  updateCalendarView: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  prepayment: PropTypes.object,
  setUpdateFilter: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(React.memo(BookingForm)));
