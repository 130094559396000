import React from 'react';
import '../styles/CalendarEntityButton.scss';

const EditEntityButton = () => (
  <div className="calendar-entity-button">
    <div className="calendar-entity-button__label" />
  </div>
);

export default React.memo(EditEntityButton);
