import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CookieConsent from 'react-cookie-consent';
import CalendarLayout from './CalendarLayout';
import ColumnLayout from './ColumnLayout';
import AdminLayout from './AdminLayout';
import FullscreenAgreementsDialog from '../components/FullscreenAgreementsDialog';
import cookiePolicy from '../agreements/cookiePolicy.pdf';
import '../styles/App.scss';

const MainLayout = (props) => {
  const {
    error,
    notifyError,
    allowedRoles,
    children,
    display,
  } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalOpened, setModalOpened] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const updateWindowDimensions = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (error && error.errorMessage) {
      notifyError({}, error.errorMessage);
    }
  }, [error]);

  const handleModalOpenState = () => {
    setModalOpened((open) => !open);
  };

  const onDocumentLoad = ({ numPages: pages }) => {
    setNumPages(pages);
  };

  return (
    <div>
      { display === 'one-col'
      && (
        <ColumnLayout
          {...props}
          display={display}
        >
          { children }
        </ColumnLayout>
      )}
      { display === 'two-col' && windowWidth > 992
      && (
        <ColumnLayout
          {...props}
          display={display}
        >
          {children}
        </ColumnLayout>
      )}
      { display === 'two-col' && windowWidth <= 992
      && (
        <div className="portable-layout">
          <CalendarLayout
            {...props}
            allowedRoles={allowedRoles}
          >
            {children}
          </CalendarLayout>
        </div>
      )}
      { display === 'superadmin'
        ? (
          <div className="admin-layout">
            <AdminLayout>
              {children}
            </AdminLayout>
          </div>
        )
        : null}
      {
        modalOpened
          ? (
            <FullscreenAgreementsDialog
              open={modalOpened}
              pageNumber={pageNumber}
              numPages={numPages}
              handleAgreementsState={handleModalOpenState}
              onDocumentLoad={onDocumentLoad}
              title="Cookie Policy"
              file={cookiePolicy}
              setPageNumber={setPageNumber}
            />
          )
          : (
            <CookieConsent
              containerClasses="cookie-footer"
              contentClasses="cookie-footer--content"
            >
              We use cookies to give you the best experience on site.
              If you want to continue using site please click and agree with our&nbsp;
              <a
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={handleModalOpenState}
              >
                cookie policy
              </a>
              .
            </CookieConsent>
          )
      }
    </div>
  );
};

MainLayout.propTypes = {
  error: PropTypes.shape({
    errorMessage: PropTypes.string,
  }),
  notifyError: PropTypes.func,
  allowedRoles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.element,
  display: PropTypes.string,
};

export default React.memo(MainLayout);
