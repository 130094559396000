import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

const UploadStep = ({ onDrop }) => {
  const [docName, setDocName] = useState('');

  const handleDrop = (acceptedFiles) => {
    const reader = new FileReader();

    reader.onload = () => {
      const docBase64 = reader.result;

      setDocName(acceptedFiles[0].name);
      onDrop(docBase64);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    accept: '.csv',
    maxSize: 5242880,
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div className="step">
      <span className="step-no">3</span>
      <span className="step-header">
        Step 3 - Upload the file below
      </span>
      <div className="step-content">
        <div {...getRootProps({ className: 'dropzone dropzone--full-width' })}>
          <input {...getInputProps()} />
          <p>
            <span className="upload-icon" />
            {docName ? <span>{docName}</span> : <span>Drag and drop file or</span>}
            <button className="button button--choose" type="button" onClick={open}>Choose file</button>
          </p>
        </div>
      </div>
    </div>
  );
};

UploadStep.propTypes = {
  onDrop: PropTypes.func,
};

export default UploadStep;
