import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import {
  map,
  get,
  omit,
  forEach,
  toLower,
  includes,
  filter,
  isEqual,
} from 'lodash';

import RefreshIndicator from 'material-ui/RefreshIndicator';

import ReactTable from 'react-table';
import Dialog from '../components/dialog/Dialog';
import AddEntityButton from '../components/AddEntityButton';
import MassActionsComponent from '../components/MassActionsComponent';
import Filter from '../components/FilterComponent';
import UploadModal from './UploadModal/UploadModal';

import HotelForm from './HotelForm';

import { formatObjectToArray } from '../utils/format';
import hotelsListColumns from './TableColumns/HotelsListColumns';
import { notifyError, notifySuccess } from '../actions/notificationActions';
import {
  fetchHotels,
  createHotel,
  deleteHotel,
  deleteHotels,
  updateHotel,
  hotelsImport,
  hotelsExport,
} from '../actions/hotelsActions';
import { startLoading, stopLoading } from '../actions/refreshIndicatorActions';
import { goToStep } from '../actions/userGuide';
import { downloadCsvFile } from '../utils/csvHelper';
import '../styles/Hotels.scss';

class Hotels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      initialValues: undefined,
      hotels: [],
      selectedAll: false,
      selected: [],
      searchValues: [],
      massActions: [
        { text: 'Delete', value: 1 },
      ],
    };
  }

  componentDidMount() {
    const { userGuide, actions } = this.props;
    const { general, currentGuide, step } = userGuide;

    actions.startLoading('hotels');
    actions.fetchHotels().then(() => {
      actions.stopLoading('hotels');
    });

    if (general && currentGuide === 'hotels' && step === 0) {
      setTimeout(() => actions.goToStep(1), 250);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hotels } = nextProps;
    const { state: stateHotels } = this.state;

    if (!isEqual(hotels, stateHotels)) {
      const searchValues = map(hotels, (h) => ({ value: h, text: h.name }));

      this.setState({
        hotels: formatObjectToArray(hotels),
        searchValues,
      });
    }
  }

  handleAdd = (values) => {
    const { actions } = this.props;
    actions.createHotel(values).then(() => {
      actions.notifySuccess({}, 'Hotel created successfully');
      this.handleDialog();
    });
  };

  handleDelete = (id) => {
    const { actions } = this.props;
    const { selected } = this.state;

    if (id === '*') {
      return actions.deleteHotels({ ids: selected }).then((res) => (
        this.handleMultipleDeletionResponse(res)
      ));
    }
    return actions.deleteHotel(id).then(() => {
      actions.notifySuccess({}, 'Hotel deleted successfully');
    });
  };

  handleMultipleDeletionResponse = (res) => {
    const { actions } = this.props;
    const discarded = get(res, 'payload.result.discarded', []);

    if (discarded.length) {
      return actions.notifyError({}, 'Some of the hotels could not be deleted because of the relations to bookings');
    }

    return actions.notifySuccess({}, 'All hotels deleted successfully');
  };

  handleEdit = (values) => {
    const { actions } = this.props;

    actions.updateHotel(values.id, { ...omit(values, 'id') }).then(() => {
      actions.notifySuccess({}, 'Hotel updated successfully');
      this.handleDialog();
    });
  };

  handleDialog = (id) => {
    const { hotels } = this.props;
    const { dialogOpen } = this.state;
    const initialValues = hotels[id] || undefined;

    this.setState({
      dialogOpen: !dialogOpen,
      initialValues,
    });
  };

  handleCheck = (id) => {
    if (id === '*') {
      this.handleMultiCheck();
    } else {
      this.handleSingleCheck(id);
    }
  };

  handleMultiCheck = () => {
    const {
      hotels,
      selectedAll,
    } = this.state;
    const selected = [];

    if (!selectedAll) {
      forEach(hotels, (h) => selected.push(h.id));
    }

    this.setState({
      selected,
      selectedAll: !selectedAll,
    });
  };

  handleSingleCheck = (id) => {
    const { selected, hotels } = this.state;
    let tempSelected = selected;

    if (includes(selected, id)) {
      tempSelected = filter(selected, (s) => s !== id);
    } else {
      tempSelected.push(id);
    }

    this.setState({
      selected: tempSelected,
      selectedAll: hotels.length === tempSelected.length,
    });
  };

  handleSearchAutocomplete = (requestList, inputValue) => {
    const { hotels } = this.props;

    const filtered = filter(hotels, (h) => includes(toLower(h.name), toLower(inputValue)));

    this.setState({ hotels: filtered });
  };

  handleUploadModalState = () => {
    const { uploadModalOpen } = this.state;
    this.setState({ uploadModalOpen: !uploadModalOpen });
  }

  handleExportClick = () => {
    const { actions } = this.props;
    actions.hotelsExport().then((res) => {
      const { payload: { result: { data } } } = res;
      downloadCsvFile(data, 'hotels.csv');
    });
  };

  render() {
    const { openDialog, refreshIndicator, actions } = this.props;
    const {
      hotels,
      dialogOpen,
      initialValues,
      massActions,
      searchValues,
      selected,
      selectedAll,
      uploadModalOpen,
    } = this.state;
    return (
      <div className="react-table hotel-list">
        <div className="react-table__row-wrapper">
          <AddEntityButton
            label="ADD HOTEL"
            onClick={this.handleDialog}
          />
          <AddEntityButton
            label="UPLOAD"
            onClick={this.handleUploadModalState}
            className="add-entity-button__theme--green"
            id="upload-anchor"
          />
          <AddEntityButton
            label="EXPORT"
            className="add-entity-button__theme--pink"
            onClick={this.handleExportClick}
          />
          <MassActionsComponent
            massActions={massActions}
            detectMassAction={() => {}}
            handleMassAction={() => openDialog(() => this.handleDelete('*'))}
          />
          <Filter
            destination="Hotels"
            dataSource={searchValues}
            onUpdateInput={this.handleSearchAutocomplete}
          />
          <UploadModal
            open={uploadModalOpen}
            handleUploadModalState={this.handleUploadModalState}
            uploadFn={actions.hotelsImport}
            onSuccessCallback={actions.fetchHotels}
            templateName="hotels.csv"
            templateLocation="https://api.skicms.com/templates/s3/skicms-csv/hotels.csv"
          />
          <Dialog
            title={initialValues ? 'Edit hotel' : 'Add hotel'}
            titleClassName="hotel-title"
            modal={false}
            open={dialogOpen}
            contentClassName="hotel-dialog"
            onRequestClose={this.handleDialog}
          >
            <HotelForm
              initialValues={initialValues}
              onSubmit={initialValues ? this.handleEdit : this.handleAdd}
            />
          </Dialog>
        </div>
        {refreshIndicator.hotels.loaded
          && (
          <ReactTable
            className={!refreshIndicator.hotels.cached ? 'indicator-hidden' : ''}
            noDataText="No data found"
            columns={hotelsListColumns(
              this.handleCheck,
              selectedAll,
              selected,
              this.handleDialog,
              openDialog,
              this.handleDelete,
            )}
            data={hotels}
            pageSize={hotels.length}
            showPagination={false}
          />
          )}
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.hotels.cached ? 'hide' : 'loading'}
          className={refreshIndicator.hotels.cached ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hotels: state.entities.hotel,
  userGuide: state.userGuide,
  refreshIndicator: state.refreshIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchHotels,
    createHotel,
    deleteHotel,
    deleteHotels,
    updateHotel,
    hotelsImport,
    hotelsExport,
    notifySuccess,
    notifyError,
    goToStep,
    startLoading,
    stopLoading,
  }, dispatch),
});

Hotels.propTypes = {
  actions: PropTypes.shape({
    fetchHotels: PropTypes.func,
    createHotel: PropTypes.func,
    deleteHotel: PropTypes.func,
    deleteHotels: PropTypes.func,
    updateHotel: PropTypes.func,
    hotelsImport: PropTypes.func,
    hotelsExport: PropTypes.func,
    notifySuccess: PropTypes.func,
    notifyError: PropTypes.func,
    goToStep: PropTypes.func,
    startLoading: PropTypes.func,
    stopLoading: PropTypes.func,
  }),
  userGuide: PropTypes.shape({
    general: PropTypes.bool,
    currentGuide: PropTypes.string,
    step: PropTypes.number,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  hotels: PropTypes.object,
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    hotels: PropTypes.shape({
      cached: PropTypes.bool,
      loaded: PropTypes.bool,
    }),
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hotels));
