import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../styles/EditClientButton.scss';

const EditClientButton = ({
  link,
  path,
  label,
  onClick,
  className,
}) => {
  const tempClassName = useMemo(() => className || 'edit-button', [className]);
  return (
    <div className={tempClassName}>
      { !link ? (
        <div
          className="label"
          onClick={onClick}
        >
          { label }
        </div>
      ) : (
        <Link
          className="link"
          to={{ pathname: path }}
        >
          { label }
        </Link>
      )}
    </div>
  );
};

EditClientButton.propTypes = {
  link: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default React.memo(EditClientButton);
