import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Checkbox from 'material-ui/Checkbox';
import RefreshIndicator from 'material-ui/RefreshIndicator';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import * as managersActions from '../actions/partnerManagerActions';
import * as partnersActions from '../actions/partnerActions';
import * as accountActions from '../actions/accountActions';
import * as bookingActions from '../actions/bookingActions';
import { fetchBooking } from '../actions/bookingActions';
import { hideDrawer, showDrawer } from '../actions/drawerActions';
import { fetchLessonBlocksByBooking } from '../actions/lessonBlockActions';
import { fetchBookingServicesByBooking } from '../actions/bookingServiceActions';
import { notifySuccess } from '../actions/notificationActions';
import { setManagerAnchor } from '../actions/userGuide';
import * as indicatorActions from '../actions/refreshIndicatorActions';
import * as clientsActions from '../actions/clientsActions';

import ListTable from '../components/ListTable';
import MassActionsComponent from '../components/MassActionsComponent';
import AddEntityButton from '../components/AddEntityButton';
import DeleteEntityButton from '../components/DeleteEntityButton';
import EditEntityButton from '../components/EditEntityButton';
import Filter from '../components/FilterComponent';
import NameColumn from '../components/NameColumn';
import Component from '../components/Component';
import UploadModal from './UploadModal/UploadModal';

import BookingForm from './BookingForm';

import { isEmptyObject, sortTable } from '../utils/helpers';

import skiThemeLessonForm from '../styles/theme/skiThemeLessonForm';
import { downloadCsvFile } from '../utils/csvHelper';

class PartnerManagersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managers: [],
      selectedManagers: [],
      massActions: [
        { text: 'Delete', value: 1 },
      ],
      searchValues: [],
    };
  }

  componentDidMount() {
    const {
      actions: {
        indicatorActions: { startLoading, stopLoading },
        managers: { fetchPartnerManagers },
        partners: { fetchPartners },
        accounts: { fetchMyAccount },
        bookings: { fetchBookings },
        clients: { fetchClients },
      },
    } = this.props;
    startLoading('managers');
    Promise.all([
      fetchPartnerManagers(),
      fetchPartners(),
      fetchMyAccount(),
      fetchBookings(),
      fetchClients(),
    ]).then(() => {
      stopLoading('managers');
    }).catch(() => {
      stopLoading('managers');
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { partners, managers } = nextProps;

    if (!isEmptyObject(partners) && !isEmptyObject(managers)) {
      this.prepareSearchValuesAndPartners(managers, partners);
    } else {
      this.setState({ managers: [] });
    }
  }

  componentWillUnmount() {
    const {
      actions: {
        hideDrawerA,
        indicatorActions: { stopLoading },
      },
    } = this.props;
    hideDrawerA();
    stopLoading('twoColumns');
  }

  prepareSearchValuesAndPartners = (managers, partners) => {
    const { actions: { setManagerAnchorA }, userGuide } = this.props;
    const searchValues = [];
    let managerAnchor;

    _.forEach(managers, (manager) => {
      if (!managerAnchor) {
        managerAnchor = manager;
      }
      const valueName = {
        value: manager,
        text: manager.name.concat(' ', manager.surname),
      };
      const valuePartner = {
        value: manager,
        text: partners[manager.partner].name,
      };
      searchValues.push(valueName, valuePartner);
    });
    this.setState({
      searchValues,
      managers,
    });

    if (managerAnchor && (userGuide && !userGuide.managerAnchor)) {
      setManagerAnchorA(managerAnchor);
    }
  }

  managersToRows = (managers) => {
    const {
      partners,
      accounts,
      booking,
      openDialog,
    } = this.props;
    const { selectedManagers } = this.state;
    const currency = _.get(accounts, '[0]currency', '');

    const rows = [];
    const bookings = booking;
    _.forEach(managers, (manager) => {
      const row = [];
      const partner = manager.partner ? manager.partner : null;
      const phoneNumbers = manager.phoneNumbers ? _.map(manager.phoneNumbers, (phoneNumber) => {
        if (phoneNumber.phoneNumber) {
          return `${phoneNumber.phoneNumber} `;
        }
        return undefined;
      }) : '-';
      let turnover = 0;
      let bookingCount = 0;
      const bookingsDisplay = manager.bookings && manager.bookings.length
        ? _.map(manager.bookings, (b) => {
          if (bookings[b.id] && bookings[b.id].status !== 'canceled') {
            turnover += bookings[b.id].total;
            if (bookingCount >= 3) {
              return null;
            }
            bookingCount += 1;
            const bookingId = ' B'.concat('-', b.bid);
            return (
              <span
                key={b.id}
                className="booking-label"
                onClick={this.handleShowBooking(b.id)}
                style={{ cursor: 'pointer' }}
              >
                {bookingId}
              </span>
            );
          }
          return undefined;
        }) : '-';
      row[0] = (
        <Checkbox
          type="checkbox"
          checked={_.some(selectedManagers, { id: manager.id })}
          onCheck={this.handleSingleCheck(manager.id)}
        />
      );
      row[1] = (
        <NameColumn
          route="managers"
          entity={manager}
        />
      );
      row[2] = manager.surname ? manager.surname : '-';
      row[3] = '-';
      if (partner && partners[partner].name) {
        row[3] = partners[partner].name;
      }
      row[4] = phoneNumbers;
      row[5] = manager.email ? manager.email : '-';
      row[6] = bookingsDisplay;
      row[7] = `${turnover.toFixed(2)} ${currency}`;
      row[8] = (
        <div className="list-table__column-content--inline">
          <Link
            to={{ pathname: `/managers/${manager.id}` }}
          >
            <EditEntityButton />
          </Link>
          <DeleteEntityButton
            id={manager.id}
            handleDelete={() => openDialog(() => this.handleDelete(manager.id))}
          />
        </div>
      );
      rows.push(row);
    });
    return rows;
  }

  handleShowBooking = (id) => () => {
    const prepopulationStrategy = this.fromBooking.bind(this, id);
    this.openBookingForm(prepopulationStrategy);
  }

  openBookingForm = (prepopulationStrategy) => {
    const {
      actions: {
        showDrawerA,
        indicatorActions: { startLoading, stopLoading },
      },
    } = this.props;
    startLoading('drawer');
    prepopulationStrategy().then((values) => {
      showDrawerA(BookingForm, values);
      stopLoading('drawer');
    });
  }

  handleDelete = (id) => {
    const {
      actions: {
        notifySuccessA,
        managers: { deletePartnerManager },
      },
      managers,
      partners,
    } = this.props;
    return deletePartnerManager(id).then(() => {
      this.prepareSearchValuesAndPartners(managers, partners);
      notifySuccessA({}, 'Manager deleted successfully');
    });
  }

  handleDeleteMassAction = () => {
    const {
      actions: {
        notifySuccessA,
        managers: { deletePartnerManager },
      },
      managers,
      partners,
    } = this.props;
    const { selectedManagers } = this.state;
    const undeletedManagers = [];
    const deletions = _.map(selectedManagers,
      (manager) => deletePartnerManager(manager.id)
        .catch(() => {
          undeletedManagers.push(manager.id);
        }));
    const results = Promise.all(deletions);
    return results.then(() => {
      if (undeletedManagers.length === 0) {
        notifySuccessA({}, 'All managers deleted successfully');
      }
      this.setState({
        selectedManagers: [],
      });
      this.refs.multiCheck.state.switched = false;
    }).then(() => {
      this.prepareSearchValuesAndPartners(managers, partners);
    });
  }

  handleSingleCheck = (id) => (e) => {
    e.preventDefault();
    const { selectedManagers } = this.state;
    const { managers } = this.props;
    let managersChecked = selectedManagers;
    if (_.some(selectedManagers, { id })) {
      managersChecked = _.filter(managersChecked, (manager) => manager.id !== id);
      this.refs.multiCheck.state.switched = false;
    } else {
      managersChecked.push(managers[id]);
    }
    this.setState({
      selectedManagers: managersChecked,
    });
  }

  handleMultiCheck = (event, isInputChecked) => {
    const { managers } = this.state;
    let managersChecked = [];
    if (isInputChecked) {
      managersChecked = managers;
    }
    this.setState({
      selectedManagers: managersChecked,
    });
  }

  setMassActionType = () => {
    this.setState((state) => ({ ...state }));
  }

  handleSearchAutocomplete = (requestList) => {
    const { searchValues } = this.state;
    const displayManagers = [];
    _.forEach((requestList), (listItem) => (_.includes(displayManagers,
      searchValues[listItem.value.key].value)
      ? null : displayManagers.push(searchValues[listItem.value.key].value)));
    this.setState({
      managers: displayManagers,
    });
  }

  handleSortTable = (columnIndex, asc) => {
    const { partners, booking } = this.props;
    const { managers } = this.state;
    const columns = {
      1: {
        name: ['name'],
        dependency: null,
        level: 1,
        orderType: 'normal',
        dataArray: false,
      },
      2: {
        name: ['surname'],
        dependency: null,
        level: 1,
        orderType: 'normal',
        dataArray: false,
      },
      3: {
        name: ['name'],
        dependency: 'managers',
        level: 2,
        orderType: 'normal',
        dataArray: false,
      },
      4: {
        name: ['phoneNumbers'],
        dependency: null,
        level: 1,
        orderType: 'normal',
        dataArray: 'phoneNumber',
      },
      5: {
        name: ['email'],
        dependency: null,
        level: 1,
        orderType: 'normal',
        dataArray: false,
      },
      6: {
        name: ['bookings'],
        dependency: null,
        level: 1,
        orderType: 'normal',
        dataArray: 'id',
      },
      7: {
        name: ['total'],
        dependency: 'booking',
        level: 2,
        orderType: 'normal',
        dataArray: false,
      },
    };
    const dependencyData = {
      managers: { data: partners, name: 'partner' },
      booking: {
        data: _.groupBy(booking, 'partnerManager'),
        name: 'id',
        grouped: true,
      },
    };

    const sortedList = sortTable(managers, columns, columnIndex, asc, dependencyData);
    this.setState({
      managers: sortedList,
    });
  }

  handleUploadModalState = () => {
    const { uploadModalOpen } = this.state;
    this.setState({ uploadModalOpen: !uploadModalOpen });
  }

  handleExportClick = () => {
    const { actions: { managers: { partnerManagersExport } } } = this.props;
    return partnerManagersExport().then((res) => {
      const { payload: { result: { data } } } = res;
      downloadCsvFile(data, 'partners-managers.csv');
    });
  };

  render() {
    const {
      refreshIndicator,
      openDialog,
      actions: {
        managers: { partnerManagersImport, fetchPartnerManagers },
      },
    } = this.props;
    const {
      massActions,
      searchValues,
      uploadModalOpen,
      managers,
    } = this.state;

    return (
      <div className="instructor-list">
        <div className="instructor-list__row-wrapper">
          <div>
            <AddEntityButton
              link
              label="ADD MANAGER"
              path="/manager"
            />
            <AddEntityButton
              label="UPLOAD"
              className="add-entity-button__theme--green"
              onClick={this.handleUploadModalState}
            />

            <AddEntityButton
              label="EXPORT"
              className="add-entity-button__theme--pink"
              onClick={this.handleExportClick}
            />
            <MassActionsComponent
              massActions={massActions}
              detectMassAction={this.setMassActionType}
              handleMassAction={() => openDialog(this.handleDeleteMassAction)}
            />
            <Filter
              destination="Managers"
              dataSource={searchValues}
              onUpdateInput={this.handleSearchAutocomplete}
            />
          </div>
        </div>
        <UploadModal
          open={uploadModalOpen}
          handleUploadModalState={this.handleUploadModalState}
          uploadFn={partnerManagersImport}
          onSuccessCallback={fetchPartnerManagers}
          templateName="partnerManagers.csv"
          templateLocation="https://api.skicms.com/templates/s3/skicms-csv/partnerManagers.csv"
        />
        <ListTable
          dest="manager"
          names={['checkbox', 'name', 'surname', 'partner', 'phone', 'email', 'bookings', 'turnover', 'icons']}
          className={!refreshIndicator.managers.cached ? 'indicator-hidden' : ''}
          header={[
            <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonForm)}>
              <Checkbox
                ref="multiCheck"
                onCheck={this.handleMultiCheck}
              />
            </MuiThemeProvider>,
            'Name',
            'Surname',
            'Partner',
            'Phone',
            'E-mail',
            'Bookings',
            'Turnover',
            ' ',
          ]}
          rows={this.managersToRows(managers)}
          handleSort={this.handleSortTable}
        />
        <RefreshIndicator
          size={300}
          top={150}
          left={540}
          status={refreshIndicator.managers.cached ? 'hide' : 'loading'}
          className={refreshIndicator.managers.cached ? 'indicator-hidden' : 'indicator-shown indicator-shown__table'}
        />
      </div>
    );
  }
}

PartnerManagersList.propTypes = {
  actions: PropTypes.shape({
    showDrawerA: PropTypes.func,
    hideDrawerA: PropTypes.func,
    setManagerAnchorA: PropTypes.func,
    notifySuccessA: PropTypes.func,
    indicatorActions: PropTypes.shape({
      startLoading: PropTypes.func,
      stopLoading: PropTypes.func,
    }),
    managers: PropTypes.shape({
      fetchPartnerManagers: PropTypes.func,
      deletePartnerManager: PropTypes.func,
      partnerManagersExport: PropTypes.func,
      partnerManagersImport: PropTypes.func,
    }),
    partners: PropTypes.shape({
      fetchPartners: PropTypes.func,
    }),
    accounts: PropTypes.shape({
      fetchMyAccount: PropTypes.func,
    }),
    bookings: PropTypes.shape({
      fetchBookings: PropTypes.func,
    }),
    clients: PropTypes.shape({
      fetchClients: PropTypes.func,
    }),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  managers: PropTypes.object,
  userGuide: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    managerAnchor: PropTypes.object,
  }),
  accounts: PropTypes.arrayOf(PropTypes.shape({
    currency: PropTypes.string,
  })),
  booking: PropTypes.shape({
    status: PropTypes.string,
  }),
  openDialog: PropTypes.func,
  refreshIndicator: PropTypes.shape({
    managers: PropTypes.shape({
      cached: PropTypes.bool,
    }),
  }),
};

const mapStateToProps = (state) => ({
  managers: state.entities.partnerManager,
  partners: state.entities.partner,
  accounts: _.values(state.entities.account),
  booking: state.entities.booking,
  userGuide: state.userGuide,
  user: state.user,
  refreshIndicator: state.refreshIndicator,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    managers: bindActionCreators(managersActions, dispatch),
    partners: bindActionCreators(partnersActions, dispatch),
    accounts: bindActionCreators(accountActions, dispatch),
    showDrawerA: bindActionCreators(showDrawer, dispatch),
    hideDrawerA: bindActionCreators(hideDrawer, dispatch),
    fetchBooking: bindActionCreators(fetchBooking, dispatch),
    bookings: bindActionCreators(bookingActions, dispatch),
    fetchLessonBlocksByBooking: bindActionCreators(fetchLessonBlocksByBooking, dispatch),
    fetchBookingServicesByBooking: bindActionCreators(fetchBookingServicesByBooking, dispatch),
    notifySuccessA: bindActionCreators(notifySuccess, dispatch),
    clients: bindActionCreators(clientsActions, dispatch),
    indicatorActions: bindActionCreators(indicatorActions, dispatch),
    setManagerAnchorA: bindActionCreators(setManagerAnchor, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerManagersList);
