import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { bookingStyleObject } from '../styles/BookingFormStyles';

const SpecialNeeds = ({ disabled }) => (
  <div>
    <Field
      style={bookingStyleObject.specialNeedsTextArea}
      name="specialClientNeeds"
      component="textarea"
      type="textarea"
      placeholder="Enter client needs"
      disabled={disabled}
    />
  </div>
);

SpecialNeeds.propTypes = {
  disabled: PropTypes.bool,
};

export default React.memo(SpecialNeeds);
