import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import { values, forEach, map } from 'lodash';
import { fetchResorts } from '../actions/resortsActions';

const RenderSelectAsync = ({
  fetchResorts: fetchResortsA,
  input,
  autoload,
  multi,
  clearable,
  simpleValue,
  searchable,
  disabled,
  meta: { touched, error },
  styleError,
  placeholder,
  noOptionsMessage = 'NO OPTIONS',
  initialOptions,
  valueAsInitialOptions,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (initialOptions) {
      setOptions(map(initialOptions, (option) => ({ value: option.id, label: option.name })));
    }
  }, [initialOptions]);

  useEffect(() => {
    if (valueAsInitialOptions && input.value) {
      setOptions([{ value: input.value.id, label: input.value.name }]);
      input.onChange({ initial: true, ...input.value });
    }
  }, [valueAsInitialOptions]);

  const getResorts = (inputValue) => {
    if (inputValue.length < 3) {
      return Promise.resolve([]);
    }
    return fetchResortsA(inputValue).then((response) => {
      const resortsResponse = [];
      forEach(response.payload.result.resort, (resort) => {
        resortsResponse.push({
          value: resort.id,
          label: resort.name,
        });
      });
      setOptions(resortsResponse);
      return resortsResponse;
    });
  };

  const value = useMemo(() => {
    if (typeof input.value === 'object' && input.value.id) {
      return {
        value: input.value.id,
        label: input.value.name,
      };
    }
    return input.value;
  }, [input.value]);

  return (
    <div className="react-select">
      <Select.Async
        autoload={autoload}
        multi={multi}
        clearable={clearable}
        simpleValue={simpleValue}
        searchable={searchable}
        loadOptions={getResorts}
        placeholder={placeholder || 'Input text'}
        name={input.name}
        value={value}
        onChange={input.onChange}
        disabled={disabled}
        filterOptions={() => options}
        noOptionsMessage={() => noOptionsMessage}
        cache={false}
      />
      {touched && error && <small style={styleError}>{error}</small>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  options: values(state.entities.resort),
});

const mapDispatchToProps = (dispatch) => ({
  fetchResorts: bindActionCreators(fetchResorts, dispatch),
});

RenderSelectAsync.propTypes = {
  fetchResorts: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
  }),
  autoload: PropTypes.bool,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  simpleValue: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  styleError: PropTypes.object,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  initialOptions: PropTypes.object,
  valueAsInitialOptions: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderSelectAsync);
