import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/Pricing.scss';
import { map } from 'lodash';
import Plans from '../constants/Plans';

const PlansRender = ({
  setSelectorValue,
  planPricing,
}) => {
  const [plan, setPlan] = useState(0);

  const selectDiv = (value) => () => {
    setPlan(value);
    setSelectorValue({ value });
  };

  return (
    <div className="plans">
      <div
        style={{ cursor: 'pointer' }}
        onClick={selectDiv(1)}
        className={plan === 1 ? 'selected' : ''}
      >
        <span>{planPricing && planPricing[1].name}</span>
        <strong>
          {planPricing && planPricing[1].priceMonthly}
          {' '}
          EUR
        </strong>
        <div>
          {map(Plans[1].credits, (credit, key) => <p key={key}>{credit}</p>)}
          <p>
            Setup fee
            {planPricing && planPricing[1].setupFee}
            {' '}
            EUR
          </p>
        </div>
      </div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={selectDiv(2)}
        className={plan === 2 ? 'selected' : ''}
      >
        <div className="tape">BESTSELLER</div>
        <span>{planPricing && planPricing[2].name}</span>
        <strong>
          {planPricing && planPricing[2].priceMonthly}
          {' '}
          EUR
          <br />
          <small>PER MONTH</small>
        </strong>
        <div>
          {map(Plans[2].credits, (credit, key) => <p key={key}>{credit}</p>)}
          <p>
            Setup fee
            {planPricing && planPricing[2].setupFee}
            {' '}
            EUR
          </p>
        </div>
      </div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={selectDiv(3)}
        className={plan === 3 ? 'selected' : ''}
      >
        <span>{planPricing && planPricing[3].name}</span>
        <strong>
          {planPricing && planPricing[3].priceMonthly}
          {' '}
          EUR
          <br />
          <small>PER MONTH</small>
        </strong>
        <div>
          {map(Plans[3].credits, (credit, key) => <p key={key}>{credit}</p>)}
          <p>
            Setup fee
            {' '}
            {planPricing && planPricing[3].setupFee}
            {' '}
            EUR
          </p>
        </div>
      </div>
    </div>
  );
};

PlansRender.propTypes = {
  setSelectorValue: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  planPricing: PropTypes.object,
};

export default PlansRender;
