import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { map, find, uniqBy } from 'lodash';

const RenderSelect = ({
  placeholder = 'Input text',
  input,
  multi,
  clearable,
  simpleValue,
  options,
  disabled,
  className,
  onOpen,
  meta: { error, warning, touched },
  noResultsText,
}) => {
  const memoisedOptions = useMemo(() => {
    const mapCallback = (option) => {
      if (option.id) {
        return { value: option.id, label: option.name };
      }
      if (option.name) {
        return { value: option.value, label: option.name };
      }
      return option;
    };
    if (options.length) {
      let vals = [];
      if (input.value && typeof input.value === 'object') {
        vals = Array.isArray(input.value) ? input.value : [input.value];
      }
      return uniqBy(map([...vals, ...options], mapCallback), 'value');
    }
    if (input.value) {
      if (Array.isArray(input.value)) {
        return map(input.value, mapCallback);
      }
      if (input.value.id) {
        return [{ value: input.value.id, label: input.value.name }];
      }
      if (input.value.name) {
        return [{ value: input.value.value, label: input.value.name }];
      }
      return [input.value];
    }
    return [];
  }, [options, input.value]);

  const values = useMemo(() => {
    if (input.value && Array.isArray(input.value)) {
      return map(input.value, (value) => {
        if (memoisedOptions.length) {
          if (value.id) {
            return find(memoisedOptions, (option) => option.value === value.id);
          }
          if (value.value) {
            return find(memoisedOptions, (option) => option.value === value.value);
          }
          return find(memoisedOptions, (option) => option.value === value);
        }
        if (value.id) {
          return {
            value: value.id,
            label: value.name,
          };
        }
        return value;
      });
    }
    if (typeof input.value === 'object') {
      if (input.value.id) {
        return find(memoisedOptions, (option) => option.value === input.value.id);
      }
      return find(memoisedOptions, (option) => option.value === input.value.value);
    }
    let val = input.value;
    if (input.name === 'activeGroup' && typeof input.value === 'string' && input.value !== '*') {
      val = Number(val);
    }
    return find(memoisedOptions, (option) => option.value === val);
  }, [input.value, memoisedOptions]);

  return (
    <div className="react-select">
      <Select
        name={input.name}
        multi={multi}
        clearable={clearable}
        simpleValue={simpleValue}
        options={memoisedOptions}
        placeholder={placeholder}
        onChange={(value) => input.onChange(value)}
        value={values}
        disabled={disabled}
        className={className}
        onOpen={onOpen}
        noResultsText={noResultsText}
      />
      <span className={(error && 'error') || (warning && 'warning')}>
        {' '}
        {(error && touched && <span>{error}</span>) || (warning && <span>{warning}</span>)}
      </span>
    </div>
  );
};

RenderSelect.propTypes = {
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
  }),
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  simpleValue: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
  })),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onOpen: PropTypes.func,
  meta: PropTypes.shape({
    error: PropTypes.string,
    warning: PropTypes.string,
    touched: PropTypes.bool,
  }),
  noResultsText: PropTypes.string,
};

export default React.memo(RenderSelect);
