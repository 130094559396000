import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tag from './Tag';
import '../styles/TagList.scss';

const TagList = ({ tags }) => (
  <div className="tag-list">
    {_.map(tags, (value, key) => (
      <Tag
        key={key}
        colorName="blue"
        value={key}
        specialities={value}
      />
    ))}
  </div>
);

TagList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.object,
};

export default React.memo(TagList);
