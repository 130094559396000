import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import '../styles/ListTable.scss';

class ListTable extends Component {
  constructor(props) {
    super(props);

    const { header, small } = props;

    this.state = {
      ascValues: this.calculateAscValues(header, small),
      draggedItemId: null,
    };
  }

  calculateAscValues = (header, small) => {
    const initiatedAscValues = [];
    header.map((column, index) => {
      if ((index !== 0 && index !== header.length - 1) || small) {
        const ascVal = {
          value: true,
          id: index,
          ascClassName: 'list-table__label-content column-sort',
        };
        return initiatedAscValues.push(ascVal);
      }
      return false;
    });
    return initiatedAscValues;
  }

  handlePivot = (index) => () => {
    const { ascValues } = this.state;
    const { handleSort } = this.props;
    const ascVal = _.filter((ascValues), (val) => {
      if (val.id === index) {
        return val;
      }
      return null;
    })[0];
    handleSort(index, ascVal.value);
    ascVal.value = !ascVal.value;
    if (ascVal.value) {
      ascVal.ascClassName = 'list-table__label-content column-sort--ascending';
    } else {
      ascVal.ascClassName = 'list-table__label-content column-sort--descending';
    }
    const ascValuesChanged = _.map((ascValues), (val) => {
      if (val.id === index) {
        return ascVal;
      }
      return val;
    });
    this.setState({
      ascValues: ascValuesChanged,
    });
  }

  handleDragStart = (event, id) => {
    this.setState({
      draggedItemId: id,
    });
  }

  handleDrop = (event, index, id) => {
    const { onDrag } = this.props;
    const { draggedItemId } = this.state;
    document.getElementById(`id_${index}`).style.opacity = 1;
    onDrag(draggedItemId, id);
  }

  handleDragOver = (event, index) => {
    event.preventDefault();
    document.getElementById(`id_${index}`).style.opacity = 0;
  }

  handleDragLeave = (event, index) => {
    event.preventDefault();
    document.getElementById(`id_${index}`).style.opacity = 1;
  }

  render() {
    const {
      dest,
      names,
      header,
      rows,
      style,
      draggable,
      small,
      className,
    } = this.props;
    const { ascValues } = this.state;

    return (
      <div className={`list-table ${className}`} style={style || {}}>
        <table className="list-table__container" id="table">
          {header
          && (
          <thead className="list-table__header">
            <tr className="list-table__header-wrapper">
              {header.map((headerRow, index) => (
                <th className="list-table__label" key={`headerRow_${index}`}>
                  {index !== 0 && index !== header.length - 1 ? (
                    <div
                      className={ascValues[index - 1].ascClassName}
                      onClick={this.handlePivot(index)}
                      style={{ cursor: 'pointer' }}
                    >
                      {headerRow}
                    </div>
                  ) : (
                    <div
                      className="list-table__label-content"
                      onClick={small && this.handlePivot(index)}
                      style={small && { cursor: 'pointer' }}
                    >
                      {headerRow}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          )}
          {rows && (
          <tbody className="list-table__body" id="body">
            {rows.map((bodyRow, index) => (
              <tr
                className="list-table__row"
                key={`row_${index}`}
                id={`id_${index}`}
                draggable={draggable}
                onDragStart={(event) => this.handleDragStart(event, bodyRow[12])}
                onDrop={(event) => this.handleDrop(event, index, bodyRow[12])}
                onDragOver={(event) => this.handleDragOver(event, index)}
                onDragLeave={(event) => this.handleDragLeave(event, index)}
              >
                {bodyRow.map((rowColumn, columnIndex) => (
                  columnIndex === 12 && draggable ? null
                    : (
                      <td className="list-table__column" key={`column_${columnIndex}`}>
                        <div className={`list-table__column-content--${dest}--${names[columnIndex]}`}>
                          {rowColumn}
                        </div>
                      </td>
                    )
                ))}
              </tr>
            ))}
          </tbody>
          )}
        </table>
      </div>
    );
  }
}

ListTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  header: PropTypes.array,
  small: PropTypes.bool,
  handleSort: PropTypes.func,
  onDrag: PropTypes.func,
  dest: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  draggable: PropTypes.bool,
  className: PropTypes.string,
};

export default ListTable;
