import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderField from './RenderField';

const number = (value) => (value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined);

const Prepayment = ({ prepayment, change, disabled }) => {
  const unselectPrepaymentType = (type) => () => {
    if (type === prepayment.prepaymentType) {
      change('prepaymentType', null);
    }
  };

  const unselectFullpaymentType = (type) => () => {
    if (type === prepayment.fullpaymentType) {
      change('fullpaymentType', null);
    }
  };

  return (
    <div className="booking-form-additional-otherinfo">
      <label className="booking-form-additional-otherinfo-amount-label">Amount: </label>
      <div className="booking-form-additional-otherinfo-amount-value">
        <Field
          name="prepayment"
          component={RenderField}
          type="number"
          placeholder="Input text"
          validate={[number]}
          disabled={disabled}
          min={0}
        />
      </div>
      <div>
        <div className="booking-form-additional-otherinfo-method-label"><label>Payment method for prepayment</label></div>
        <div className="booking-form-additional-otherinfo-method-values">
          <label>
            <Field name="prepaymentType" type="radio" component="input" value="cash" onClick={unselectPrepaymentType('cash')} disabled={disabled} />
            Paid by cash
          </label>
          <label>
            <Field name="prepaymentType" type="radio" component="input" value="card" onClick={unselectPrepaymentType('card')} disabled={disabled} />
            Paid by card
          </label>
          <label>
            <Field name="prepaymentType" type="radio" component="input" value="bank" onClick={unselectPrepaymentType('bank')} disabled={disabled} />
            Paid by bank
          </label>
        </div>
        <div className="booking-form-additional-otherinfo-method-label"><label>Payment method for fullpayment</label></div>
        <div className="booking-form-additional-otherinfo-method-values">
          <label>
            <Field name="fullpaymentType" type="radio" component="input" value="cash" onClick={unselectFullpaymentType('cash')} disabled={disabled} />
            Paid by cash
          </label>
          <label>
            <Field name="fullpaymentType" type="radio" component="input" value="card" onClick={unselectFullpaymentType('card')} disabled={disabled} />
            Paid by card
          </label>
          <label>
            <Field name="fullpaymentType" type="radio" component="input" value="bank" onClick={unselectFullpaymentType('bank')} disabled={disabled} />
            Paid by bank
          </label>
        </div>
      </div>
    </div>
  );
};

Prepayment.propTypes = {
  prepayment: PropTypes.shape({
    prepaymentType: PropTypes.string,
    fullpaymentType: PropTypes.string,
  }),
  change: PropTypes.func,
  disabled: PropTypes.bool,
};

export default React.memo(Prepayment);
