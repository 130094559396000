import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../styles/Calendar.scss';
import { convertToUTC } from '../utils/dateTime';

const AvailabilityEvent = ({ event }) => {
  const { timeFrom, timeTo } = event;
  const timeValues = useMemo(() => `${moment(convertToUTC(timeFrom)).toISOString()}-${moment(convertToUTC(timeTo)).toISOString()}`, [timeFrom, timeTo]);
  return (
    <div className={event.eventType}>
      <div className="hidden-times">
        {timeValues}
      </div>
    </div>
  );
};

AvailabilityEvent.propTypes = {
  event: PropTypes.shape({
    timeFrom: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    timeTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    eventType: PropTypes.string,
  }),
};

export default React.memo(AvailabilityEvent);
