import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'redux-form-material-ui';
import { FieldArray, Field, reduxForm } from 'redux-form';

const RenderProductOption = React.memo(({ fields }) => (
  <ul className="export-select-form">
    {fields.map((p, index) => (
      <li key={fields.get(index).value} id={`li-${index}`}>
        <Field
          component={Checkbox}
          name={`${p}.checked`}
          checked={fields.get(index).checked}
          labelPosition="right"
          label={fields.get(index).label}
        />
      </li>
    ))}
  </ul>
));

const SelectForm = () => (
  <form>
    <FieldArray
      component={RenderProductOption}
      name="products"
    />
  </form>
);

RenderProductOption.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    get: PropTypes.func,
  }),
};

const formConfig = {
  form: 'ExportFilterWizard',
  destroyOnUnmount: false,
};

export default reduxForm(formConfig)(React.memo(SelectForm));
