import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../styles/RegistrationWizard.scss';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPlans } from '../../actions/subscriptionPlanActions';
import '../../styles/RegisterForm.scss';
import '../../styles/Register.scss';
import PlansRenderComponent from './PlansRenderComponent';
import { required } from '../../utils/validators';

const FirstPage = ({
  actions: {
    fetchPlansA,
  },
  change,
  nextPage,
  planPaymentType,
  handleSubmit,
  planPricing,
}) => {
  useEffect(() => {
    fetchPlansA();
  }, []);

  const onChoosePlanClick = (newPlan) => {
    change('upgradePlan', newPlan);
    nextPage();
  };

  return (
    <div className="step-info">
      <div>Step 1 - Choose a plan</div>
      <div>Choose plan that is the best for you</div>
      <div className="plan-payment">
        <span
          className={planPaymentType === 'monthly'
            ? 'background-color__selected'
            : 'background-color__unselected'}
          onClick={() => change('planPaymentType', 'monthly')}
        >
          Monthly
        </span>
        <span
          className={planPaymentType === 'annually'
            ? 'background-color__selected'
            : 'background-color__unselected'}
          onClick={() => change('planPaymentType', 'annually')}
        >
          Annually
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <Field
          name="upgradePlan"
          component={PlansRenderComponent}
          planPricing={planPricing}
          onChoosePlanClick={onChoosePlanClick}
          paymentType={planPaymentType}
          validate={[required]}
        />
      </form>
    </div>
  );
};

FirstPage.propTypes = {
  actions: PropTypes.shape({
    fetchPlansA: PropTypes.func,
  }),
  change: PropTypes.func,
  nextPage: PropTypes.func,
  planPaymentType: PropTypes.string,
  handleSubmit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  planPricing: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetchPlansA: bindActionCreators(fetchPlans, dispatch),
  },
});

const selector = formValueSelector('RegisterFirstPage');

const mapStateToProps = (state) => ({
  planPricing: state.entities.subscriptionPlan,
  planPaymentType: selector(state, 'planPaymentType'),
  initialValues: {
    planPaymentType: 'monthly',
  },
});

const formConfig = {
  form: 'RegisterFirstPage',
  destroyOnUnmount: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(formConfig)(FirstPage));
