import 'react-phone-number-input/style.css';
import React from 'react';
import PropTypes from 'prop-types';
import Phone from 'react-phone-number-input';
import phones from '../../phone.json';
import '../styles/PhoneInput.scss';

const FlagComponent = (options) => {
  const { country } = options;

  return (
    <div className="">
      <img
        alt={country}
        src={`https://catamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
        style={{ width: '32px', height: '20px' }}
      />
    </div>
  );
};

const RenderPhoneInput = ({
  input: { onChange, value, onBlur },
  label,
  labelClassName,
  placeholder,
  meta: { touched, error = '' },
  disabled,
}) => {
  const setPhoneNumberValue = (countryCode) => {
    if (countryCode && !value) {
      onChange(phones[countryCode.toUpperCase()]);
    }
  };

  return (
    <div>
      {label && (
        <label className={labelClassName}>
          {label}
        </label>
      )}
      <Phone
        onChange={onChange}
        onCountryChange={setPhoneNumberValue}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        error={touched ? error : ''}
        flagComponent={(options) => (
          <FlagComponent
            {...options}
          />
        )}
        disabled={disabled}
        flagUrl="https://catamphetamine.github.io/country-flag-icons/3x2/"
        international
      />
    </div>
  );
};

RenderPhoneInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
    onBlur: PropTypes.func,
  }),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default React.memo(RenderPhoneInput);
