import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { isEmpty } from 'lodash';

import FlatButton from 'material-ui/FlatButton';
import { TextField } from 'redux-form-material-ui';

import { required } from '../utils/validators';

const HotelForm = ({ handleSubmit, onSubmit, formErrors }) => (
  <form
    className="hotel-form"
    onSubmit={handleSubmit(onSubmit.bind(this))}
  >
    <div className="hotel-input-wrapper">
      <label className="hotel-label">Hotel name*:</label>
      <Field
        component={TextField}
        name="name"
        validate={[required]}
      />
    </div>
    <FlatButton
      label="Submit"
      type="submit"
      primary
      disabled={!isEmpty(formErrors)}
    />
  </form>
);

const formConfig = {
  form: 'Hotel',
  enableReinitialize: true,
};

HotelForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
};

export default reduxForm(formConfig)(React.memo(HotelForm));
