const initialState = {
  open: false,
};

const dialog = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return {
        open: true,
        message: action.message,
        onOk: action.onOk,
        onCancel: action.onCancel,
        calendarItem: action.calendarItem,
      };
    case 'CLOSE_DIALOG':
      return {
        open: false,
      };
    default:
      return state;
  }
};

export default dialog;
