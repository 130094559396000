import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const RenderSelectAsync = ({
  input,
  clearable,
  simpleValue,
  searchable,
  disabled,
  loadOptions,
  options,
  meta: { touched, error },
  styleError,
}) => (
  <div className="react-select">
    <Select.Async
      autoload={false}
      clearable={clearable}
      simpleValue={simpleValue}
      searchable={searchable}
      loadOptions={loadOptions}
      onOpen={loadOptions}
      placeholder="Input text"
      name={input.name}
      value={input.value}
      valueKey="id"
      labelKey="name"
      onChange={input.onChange}
      disabled={disabled}
      filterOptions={() => options}
    />
    {touched && error && <small style={styleError}>{error}</small>}
  </div>
);

RenderSelectAsync.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  clearable: PropTypes.bool,
  simpleValue: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  loadOptions: PropTypes.array,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  styleError: PropTypes.object,
};

export default React.memo(RenderSelectAsync);
