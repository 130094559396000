import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import Popover from 'material-ui/Popover';
import Component from '../components/Component';
import { fetchLessonsByGroup } from '../actions/lessonActions';
import { fetchSpeciality } from '../actions/specialityActions';
import { moveBuyer, fetchBuyersByLesson, fetchBuyersByGroupGuid } from '../actions/buyerActions';
import { showDrawer } from '../actions/drawerActions';
import { fetchBookingServicesByBooking } from '../actions/bookingServiceActions';

import { fetchLessonBlocksByBooking } from '../actions/lessonBlockActions';
import { fetchBookingsByIds, fetchBooking } from '../actions/bookingActions';
import { fetchGroup, fetchGroupByGuid } from '../actions/groupsActions';
import { fetchEventsByFilter } from '../actions/eventActions';
import * as indicatorActions from '../actions/refreshIndicatorActions';
import BuyerItem from '../components/BuyerItem';
import BookingForm from './BookingForm';
import '../styles/GroupView.scss';
import CalendarItemPopover from '../components/CalendarItemPopover';

class GroupView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      violationList: [],
      lock: false,
      anchorEl: undefined,
      popoverOpen: false,
      popoverProps: {},
    };
  }

  componentDidMount() {
    this.updateState(this.props);
  }

  componentDidUpdate() {
    const { lock, groups } = this.state;
    const { group: propsGroup } = this.props;
    if (!lock && groups.length < 2) {
      const group = groups.find((g) => g.guid === propsGroup.guid);
      if (!group) {
        this.updateState(this.props);
      }
    }
  }

  updateState = (props) => {
    const { actions } = this.props;
    this.setState({ lock: true });

    this.fetchBuyers(props.group)
      .then((buyers) => {
        const bookingsIds = [];
        _.forEach(buyers, (buyer) => {
          bookingsIds.push(buyer.bookingId);
        });

        if (bookingsIds.length !== 0) {
          actions.fetchBookingsByIds({ ids: bookingsIds }).then((res) => {
            this.setState({
              popoverProps: {
                groupInfo: _.groupBy(res.payload.result, 'id'),
                group: true,
              },
            });
          });
        }
        this.mapBuyersToState(props.group, buyers);
      })
      .then(() => {
        this.forceUpdate();
      })
      .then(() => {
        this.setState({ lock: false });
      });
  }

  mapBuyersToState = (group, buyers) => {
    const { groups } = this.state;
    const groupBuyers = {
      ...group,
      buyers,
    };

    this.setState({
      groups: _.concat(groups, groupBuyers),
    });
  }

  fetchBuyers = (group) => {
    const { activity, speciality, actions } = this.props;
    return new Promise((resolve) => {
      actions.fetchBuyersByGroupGuid(group.id).then((response) => {
        const buyers = _.map(response.payload.result.buyer, (buyer) => ({
          ...buyer,
          specialityName: _.get(speciality, `[${buyer.speciality}].name`, ''),
          activityName: _.get(activity, `[${buyer.activity}].name`, ''),
          language: group.language,
        }));
        resolve(buyers);
      });
    });
  }

  onDoubleClick = (groupIndex, buyerIndex) => {
    const { groups } = this.state;
    const { user, actions } = this.props;

    this.setState({
      violationList: [],
    });

    if (groups.length <= 1 || user.role !== 'manager') {
      return;
    }

    const buyer = groups[groupIndex].buyers[buyerIndex];
    const group = groups[1 - groupIndex];

    actions.moveBuyer(buyer.id, group.id)
      .then((response) => this.moveBuyer(response, groupIndex, buyerIndex))
      .catch(this.showError);
  }

  moveBuyer = (response, groupIndex, buyerIndex) => {
    const { actions } = this.props;
    const { groups } = this.state;
    const { result } = response.payload;

    groups[groupIndex].buyers.splice(buyerIndex, 1);
    groups[1 - groupIndex].buyers.push({
      ..._.values(result.buyer)[0],
    });

    this.setState({
      groups,
    });
    actions.fetchEventsByFilter();
    this.forceUpdate();
  }

  showError = (response) => {
    if (response.status !== 400) {
      return;
    }

    this.setState({
      violationList: response.data.constraintViolations,
    });
  }

  onBookingClick = (bookingId) => {
    const { actions, openDialog } = this.props;
    actions.indicatorActions.startLoading('drawer');
    this.fromBooking(bookingId).then((values) => {
      actions.showDrawer(BookingForm, { ...values, openDialog });
      actions.indicatorActions.stopLoading('drawer');
    });
  }

  onItemContextMenu = (id, e, bookingId) => {
    e.preventDefault();
    const position = this.getActiveAreaPositioning(`#${id}`);
    this.setState({
      y: position.y,
      x: position.x,
      bookingId,
    });

    const domNode = document.getElementById(id);
    this.handlePopoverState(domNode);
  };

  handlePopoverState = (anchorEl = undefined) => {
    const { popoverOpen } = this.state;

    this.setState({ popoverOpen: !popoverOpen });
    if (anchorEl) {
      this.setState({ anchorEl });
    }
  };

  handlePopoverClose = () => {
    this.setState({
      popoverOpen: false,
    });
  };

  getActiveAreaPositioning = (selector) => {
    const element = document.querySelector(selector);

    if (element) {
      const rect = element.getBoundingClientRect();

      return {
        bl: {
          x: rect.left + window.scrollX,
          y: rect.bottom + window.scrollY,
        },
      };
    }
    return undefined;
  };

  render() {
    const {
      violationList,
      groups,
      anchorEl,
      popoverOpen,
      popoverProps,
      x,
      y,
      bookingId,
    } = this.state;
    const { group: originalGroup } = this.props;
    return (
      <div className="group-view">
        { violationList.length > 0
        && (
        <div className="error-list">
          { _.map(violationList, (violation, violationKey) => (
            <div key={violationKey} className="error">
              { violation.message }
            </div>
          ))}
        </div>
        )}
        <div className="group-list">
          { _.map(groups, (group, groupIndex) => (
            <div key={groupIndex} className="group">
              <div className="title">{ group.name }</div>
              <div>
                { _.map(group.buyers, (buyer, buyerIndex) => (
                  <div onContextMenu={(e) => this.onItemContextMenu(`${originalGroup.fullId}-${buyerIndex}`, e, buyer.bookingId)}>
                    <BuyerItem
                      key={buyerIndex}
                      item={buyer}
                      group={group}
                      onDoubleClick={() => this.onDoubleClick(groupIndex, buyerIndex)}
                      onClick={() => this.onBookingClick(buyer.bookingId)}
                      originalId={`${originalGroup.fullId}-${buyerIndex}`}
                      onContextClick={this.onItemContextMenu}
                    />
                    <div
                      id="reserved-bookings-anchor"
                      style={{
                        width: '1px', height: '1px', position: 'relative', left: '60vh', bottom: '53px',
                      }}
                    />
                    <Popover
                      open={popoverOpen}
                      anchorEl={anchorEl}
                      anchorPosition={{ left: y, top: x }}
                      targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                      onRequestClose={this.handlePopoverClose}
                      className="popover-shadow"
                    >
                      <CalendarItemPopover {...popoverProps} group bookingId={bookingId} />
                    </Popover>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activity: state.entities.activity,
  speciality: state.entities.speciality,
  user: state.user,
  events: state.events,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    moveBuyer: bindActionCreators(moveBuyer, dispatch),
    showDrawer: bindActionCreators(showDrawer, dispatch),
    fetchLessonsByGroup: bindActionCreators(fetchLessonsByGroup, dispatch),
    fetchBuyersByLesson: bindActionCreators(fetchBuyersByLesson, dispatch),
    fetchBuyersByGroupGuid: bindActionCreators(fetchBuyersByGroupGuid, dispatch),
    fetchSpeciality: bindActionCreators(fetchSpeciality, dispatch),
    fetchBookingsByIds: bindActionCreators(fetchBookingsByIds, dispatch),
    fetchBooking: bindActionCreators(fetchBooking, dispatch),
    fetchBookingServicesByBooking: bindActionCreators(fetchBookingServicesByBooking, dispatch),
    fetchLessonBlocksByBooking: bindActionCreators(fetchLessonBlocksByBooking, dispatch),
    fetchGroup: bindActionCreators(fetchGroup, dispatch),
    indicatorActions: bindActionCreators(indicatorActions, dispatch),
    fetchEventsByFilter: bindActionCreators(fetchEventsByFilter, dispatch),
    fetchGroupByGuid: bindActionCreators(fetchGroupByGuid, dispatch),
  },
});

GroupView.propTypes = {
  actions: PropTypes.shape({
    moveBuyer: PropTypes.func,
    showDrawer: PropTypes.func,
    fetchLessonsByGroup: PropTypes.func,
    fetchBuyersByLesson: PropTypes.func,
    fetchBuyersByGroupGuid: PropTypes.func,
    fetchSpeciality: PropTypes.func,
    fetchBookingsByIds: PropTypes.func,
    fetchBooking: PropTypes.func,
    fetchBookingServicesByBooking: PropTypes.func,
    fetchLessonBlocksByBooking: PropTypes.func,
    fetchGroup: PropTypes.func,
    indicatorActions: PropTypes.shape({
      startLoading: PropTypes.func,
      stopLoading: PropTypes.func,
    }),
    fetchEventsByFilter: PropTypes.func,
    fetchGroupByGuid: PropTypes.func,
  }),
  group: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  activity: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  speciality: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  user: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  openDialog: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupView);
