import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  SubmissionError,
  reduxForm,
  getFormValues,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import _, {
  get,
  pick,
  filter,
  map,
  isEmpty,
  uniq,
  sortBy,
  forEach,
  includes,
  minBy,
  maxBy,
  find,
  reduce,
} from 'lodash';
import { fetchInstructorsByAvailabilityFilter } from '../actions/instructorsActions';
import { fetchActivities } from '../actions/activitiesActions';
import { fetchSpecialities } from '../actions/specialityActions';
import { fetchResorts } from '../actions/resortsActions';
import { updateGroup, createGroup, updateGroupRange } from '../actions/groupsActions';
import { fetchMyAccount } from '../actions/accountActions';
import { hideDrawer } from '../actions/drawerActions';
import { fetchEventsByFilter } from '../actions/eventActions';
import { notifySuccess, notifyError } from '../actions/notificationActions';
import FormField from '../components/FormField';
import RenderField from '../components/RenderField';
import UserGuideIcon from '../components/UserGuideIcon';
import { bookingStyleObject } from '../styles/BookingFormStyles';
import {
  required,
  maxValue24,
  maxLength255,
  number,
  notEmpty,
  minValue1,
  minValue0,
} from '../utils/validators';
import {
  mapPropsToOptions,
  mapPersonsToOptions,
  mapGroupDatesToOptions,
  mapGroupsToPayload,
} from '../utils/map';
import { parseGroupDays, parseOptionValue, parseActivity } from '../utils/helpers';
import { languageOptionsIdName } from '../constants/LanguageOptions';
import RenderGroupPricing from './PricingTable/RenderGroupPricing';
import '../styles/GroupForm.scss';
import * as Fields from '../components/Field';
import { convertToUTC, convertForCalendar, convertToDate } from '../utils/dateTime';
import { levels, days, repeat as repeatOptions } from '../utils/formHelpers';

import 'react-select/dist/react-select.css';

const moment = extendMoment(Moment);

const validateGroupPrice = (values) => {
  const errors = {};
  if (values && values.length) {
    forEach(values, (value, index) => {
      const rowError = {};
      if (!value.days) {
        rowError.days = 'This field is required';
      }
      if (!value.pricePerPerson) {
        rowError.pricePerPerson = 'This field is required';
      }
      if (!isEmpty(rowError)) {
        errors[index] = rowError;
      }
    });
  } else {
    return 'This field is required';
  }
  return isEmpty(errors) ? undefined : errors;
};

const keyArray = [
  'name',
  'description',
  'guid',
  'minAge',
  'maxAge',
  'clientAmount',
  'meetingPoint',
  'level',
  'language',
  'speciality',
  'instructors',
  'paidHours',
  'resort',
  'groupPrice',
  'timeFrom',
  'timeTo',
];

const GroupForm = (props) => {
  const {
    user,
    initialValues,
    formValues,
    speciality,
    activity,
    instructors: propInstructors,
    userGuide,
    invalid,
    change,
    updateCalendarView,
    handleSubmit,
    pristine,
    submitting,
    account,
    initialResorts,
    fetchActivities: fetchActivitiesA,
    fetchSpecialities: fetchSpecialitiesA,
    fetchMyAccount: fetchMyAccountA,
    fetchInstructorsByAvailabilityFilter: fetchInstructorsByAvailabilityFilterA,
    notifySuccess: notifySuccessA,
    notifyError: notifyErrorA,
    fetchEventsByFilter: fetchEventsByFilterA,
    createGroup: createGroupA,
    hideDrawer: hideDrawerA,
    updateGroup: updateGroupA,
    updateGroupRange: updateGroupRangeA,
    updateGroupList,
    setUpdateFilter,
  } = props;

  const [instructors, setInstructors] = useState([]);
  const [timeFrom, setTimeFrom] = useState();
  const [timeTo, setTimeTo] = useState();
  const [groupOptions, setGroupOptions] = useState();
  const [specialities, setSpecialities] = useState([]);
  const [activities, setActivities] = useState([]);

  const fieldsDisabled = useMemo(() => user.role !== 'manager', []);

  const userGuidePrev = useRef({});
  const prevFormValues = useRef({});
  const bottom = useRef();
  const dateTo = useRef();
  const initialRef = useRef(true);

  const filterSpecialities = (ss, a) => filter(_.values(ss),
    (s) => a && a === s.activity && s.name !== 'Any')
    .map((item) => ({ id: item.id, name: item.name }));

  const formatFieldName = (name, id = null) => {
    const activeGroupId = id
      || (formValues && formValues.activeGroup ? formValues.activeGroup : null);

    return groupOptions && activeGroupId ? `groups[${activeGroupId}].${name}` : name;
  };

  const getFieldValue = (name, values = undefined) => {
    if (!values) {
      return get(formValues, formatFieldName(name), null);
    }
    return get(values, formatFieldName(name), null);
  };

  const getFilterValue = (field, value, selectedField) => {
    if (field && value && field === selectedField) {
      return value;
    }
    return getFieldValue(selectedField);
  };

  const getInstructorFilter = (field, value) => {
    let tf = moment(convertToUTC(getFilterValue(field, value, 'timeFrom'))).set({ seconds: 0, milliseconds: 0 }).toISOString();
    let tt = moment(convertToUTC(getFilterValue(field, value, 'timeTo'))).set({ seconds: 0, milliseconds: 0 }).toISOString();
    let df = moment(convertToUTC(getFilterValue(field, value, 'dateFrom'))).toISOString();
    let dt = moment(convertToUTC(getFilterValue(field, value, 'dateTo'))).toISOString();
    if (formValues && formValues.groups && formValues.groups[formValues.activeGroup]
      && formValues.groups[formValues.activeGroup].timeFrom) {
      tf = moment(convertToUTC(formValues.groups[formValues.activeGroup]
        .timeFrom))
        .set({ seconds: 0, milliseconds: 0 })
        .toISOString();
      df = moment(convertToUTC(formValues.groups[formValues.activeGroup].timeFrom))
        .toISOString();
    }
    if (formValues && formValues.groups && formValues.groups[formValues.activeGroup]
      && formValues.groups[formValues.activeGroup].timeTo) {
      tt = moment(convertToUTC(formValues.groups[formValues.activeGroup].timeTo))
        .set({ seconds: 0, milliseconds: 0 })
        .toISOString();
      dt = moment(convertToUTC(formValues.groups[formValues.activeGroup].timeTo))
        .toISOString();
    }
    if (tf && tt && df && dt) {
      return {
        resort: parseOptionValue(getFilterValue(field, value, 'resort')),
        activity: parseOptionValue(getFilterValue(field, value, 'activity')),
        speciality: parseOptionValue(getFilterValue(field, value, 'speciality')),
        level: parseOptionValue(getFilterValue(field, value, 'level')),
        timeFrom: `${df.split('T')[0]}T${tf.split('T').pop()}`,
        timeTo: `${dt.split('T')[0]}T${tt.split('T').pop()}`,
      };
    }
    return {};
  };

  const shouldFetchInstructors = (f) => f.resort && f.timeFrom && f.timeTo
    && f.activity && f.speciality && f.level;

  const fetchInstructors = (f) => {
    fetchInstructorsByAvailabilityFilterA(f).then((response) => {
      const instructor = get(response, 'payload.result.instructor', []);
      const instructorsSorted = sortBy(instructor, (i) => propInstructors[i.id].weight, 'asc');

      setInstructors(mapPersonsToOptions(instructorsSorted));
    });
  };

  const fetchInstructorsIfFilterOk = (initial = false, a, field, value) => {
    let f = getInstructorFilter(field, value);
    if (initial) {
      f = {
        ...f,
        timeFrom: get(formValues, `groups[${initialValues.activeGroup}].timeFrom`, null),
        timeTo: get(formValues, `groups[${initialValues.activeGroup}].timeTo`, null),
        activity: a,
      };
    }
    if (shouldFetchInstructors(f)) {
      fetchInstructors(f);
    }
  };

  const setFormValues = (id, initial = false) => {
    const ignoredFields = ['dateFrom', 'dateTo', 'speciality', 'activity', 'resort'];

    setSpecialities(filterSpecialities(speciality, parseActivity(activity, get(initialValues, `groups[${id}].speciality`, null))));
    const specialityVal = speciality[initialValues.groups[id].speciality];
    const activityVal = get(specialityVal, 'activity', null);
    if (initial) {
      fetchInstructorsIfFilterOk(true, activityVal);
    } else {
      fetchInstructorsIfFilterOk();
    }
    forEach(initialValues.groups[id], (value, key) => {
      if (includes(keyArray, key) && !includes(ignoredFields, key)) {
        change(formatFieldName(key, id), value);
      }
    });
    change(formatFieldName('dateFrom', id), convertToDate(initialValues.groups[id].timeFrom));
    change(formatFieldName('dateTo', id), convertToDate(initialValues.groups[id].timeTo));
    change(formatFieldName('timeFrom', id), moment(convertForCalendar(initialValues.groups[id].timeFrom)));
    change(formatFieldName('timeTo', id), moment(convertForCalendar(initialValues.groups[id].timeTo)));
    change(formatFieldName('speciality', id), specialityVal);
    change(formatFieldName('activity', id), activityVal);
  };

  const changeTimes = (timeFromH, timeFromM, timeToH, timeToM) => {
    const tF = new Date();
    tF.setHours(timeFromH);
    tF.setMinutes(timeFromM);
    const tT = new Date();
    tT.setHours(timeToH);
    tT.setMinutes(timeToM);
    change('timeFrom', tF, false);
    change('timeTo', tT, false);
  };

  useEffect(() => {
    if (initialValues && initialValues.groups) {
      setGroupOptions(mapGroupDatesToOptions(initialValues.groups));
    } else {
      initialRef.current = false;
    }

    Promise.all([
      fetchActivitiesA(),
      fetchSpecialitiesA(),
      fetchMyAccountA(),
    ]).then(() => {
      if (initialValues && initialValues.groups) {
        initialRef.current = false;
      }
    });
    window.setTimes = (timeFromH, timeFromM, timeToH, timeToM) => {
      changeTimes(timeFromH, timeFromM, timeToH, timeToM);
    };

    return () => {
      if (timeFrom && timeTo && updateCalendarView) {
        updateCalendarView(
          moment(timeFrom),
          moment(timeTo),
        );
      }
    };
  }, []);

  useEffect(() => {
    setActivities(mapPropsToOptions(activity, true));
  }, [activity]);

  useEffect(() => {
    if (formValues) {
      if (!prevFormValues.current
        || formValues.activeGroup !== prevFormValues.current.activeGroup) {
        setFormValues(formValues.activeGroup, initialRef.current);
      }
      prevFormValues.current = formValues;
    }
  }, [formValues]);

  const scrollIntoView = () => {
    const el = bottom.current;
    el.scrollIntoView({ behavior: 'instant' });
  };

  useEffect(() => {
    if (userGuide && userGuide.groupScrollIntoView !== userGuidePrev.current.groupScrollIntoView) {
      scrollIntoView();
    }
    userGuidePrev.current = userGuide;
  }, [userGuide]);

  const onChange = (field) => (value) => {
    const filterFields = ['resort', 'level', 'activity', 'speciality', 'dateFrom', 'dateTo', 'timeFrom', 'timeTo'];

    if (filterFields.includes(field)) {
      if (getFieldValue('instructors')) {
        change(formatFieldName('instructors'), null);
      }
      change(formatFieldName(field), value);
      fetchInstructorsIfFilterOk(false, undefined, field, value);
    }
    change(formatFieldName(field), value);
  };

  const onActivityChange = (e, value) => {
    change(formatFieldName('speciality'), null);
    setSpecialities(filterSpecialities(speciality, value));
  };

  const onRepeatChange = (item) => {
    if (item.value === 0) {
      return onChange('days')([]);
    }

    return onChange('repeat')(item);
  };

  const handleError = (err) => {
    const property = get(err, 'data.constraintViolations[0].property', '');
    const message = get(err, 'data.constraintViolations[0].message', '');

    if (property) {
      notifyErrorA({}, message);
      throw new SubmissionError({ [property.split('.')]: message });
    }
  };

  const getDate = (date) => ({
    year: date.get('year'),
    month: date.get('month'),
    date: date.get('date'),
  });

  const formatGroupDate = (dateFrom, dt, tf, tt) => {
    const dFrom = getDate(moment(dateFrom));
    const dTo = getDate(moment(dt));
    const tFrom = moment(tf).set(dFrom);
    const tTo = moment(tt).set(dTo);

    return {
      timeFrom: tFrom.toISOString(),
      timeTo: tTo.toISOString(),
    };
  };

  const addGroupsToPayload = (payload, groupId) => {
    const repeat = getFieldValue('repeat');
    const d = getFieldValue('days');
    const dateFrom = getFieldValue('dateFrom');
    const dt = getFieldValue('dateTo');
    const tf = new Date(getFieldValue('timeFrom'));
    const tt = new Date(getFieldValue('timeTo'));

    if (!groupId) {
      return mapGroupsToPayload(repeat.value, d, moment(dateFrom), moment(dt), payload);
    }
    if (groupId !== '*') {
      return {
        ...payload,
        ...formatGroupDate(dateFrom, dt, tf, tt),
        updatePrice: true,
      };
    }
    const range = formatGroupDate(dateFrom, dt, tf, tt);
    const selectedRange = moment.range(range.timeFrom, range.timeTo);
    const filteredGroups = filter(initialValues.groups, (grp) => grp.id !== '*');
    return reduce(filteredGroups, (result, group) => {
      const groupRange = formatGroupDate(group.timeFrom, group.timeTo, tf, tt);
      const groupSelectedRange = moment.range(groupRange.timeFrom, groupRange.timeTo);
      const center = groupSelectedRange.center();
      if (selectedRange.contains(center)) {
        result.push({
          ...payload,
          ...groupRange,
          updatePrice: true,
          id: group.id,
        });
      }

      return result;
    }, []);
  };

  const formatPayload = (values, groupId) => {
    const tf = new Date(getFieldValue('timeFrom'));
    const tt = new Date(getFieldValue('timeTo'));
    const instructorsValue = getFieldValue('instructors');
    let { resort } = values;
    if (values.resort.value) {
      resort = values.resort.value;
    } else if (values.resort.id) {
      resort = values.resort.id;
    }
    const instructorsArray = instructorsValue ? instructorsValue.map((item) => {
      if (item.id) {
        return item.id;
      }
      return item.value;
    }) : [];
    const payload = {
      ...values,
      resort,
      speciality: values.speciality.id ? values.speciality.id : values.speciality,
      instructors: instructorsArray,
      timeFrom: tf,
      timeTo: tt,
      clientAmount: parseInt(values.clientAmount, 10),
      maxAge: parseInt(values.maxAge, 10),
      minAge: parseInt(values.minAge, 10),
      paidHours: parseFloat(values.paidHours),
      groupPrice: formValues.groupPrice,
    };
    return addGroupsToPayload(payload, groupId);
  };

  const validatePayload = () => {
    const checkEmpty = isEmpty(formValues.groupPrice, true);
    const checkDaysUnique = parseGroupDays(formValues.groupPrice).length
      === uniq(parseGroupDays(formValues.groupPrice)).length;
    const checkCorrectPricingFilling = filter(formValues.groupPrice,
      (gp) => (!gp.days || !gp.pricePerPerson)).length;
    const d = getFieldValue('days');
    const repeat = getFieldValue('repeat');

    if ((repeat && repeat.value !== 0) && (isEmpty(d, true) || d.every((day) => !day || typeof day === 'undefined'))) {
      notifyErrorA({}, 'No days of the week selected');
      return false;
    }
    if (checkCorrectPricingFilling > 0) {
      notifyErrorA({}, 'Pricing table fields can not be blank');
      return false;
    }
    if (checkEmpty) {
      notifyErrorA({}, 'No price selected for this group');
      return false;
    }
    if (!checkDaysUnique) {
      notifyErrorA({}, 'Days count must be unique for every price');
      return false;
    }
    return true;
  };

  const submitCallback = (message) => () => {
    notifySuccessA({}, message);
    if (updateGroupList) {
      updateGroupList();
    } else if (setUpdateFilter) {
      setUpdateFilter();
    } else {
      fetchEventsByFilterA();
    }
    hideDrawerA();
  };

  const onSubmit = (values) => {
    const groupId = formValues.activeGroup;

    if (!validatePayload()) {
      return;
    }

    let valuesToPayload = groupId ? formValues.groups[groupId] : values;
    valuesToPayload = pick(valuesToPayload, keyArray);
    const payload = formatPayload(valuesToPayload, groupId);
    const tf = moment(convertToUTC(get(payload, `[${0}].timeFrom`))).toISOString();
    const tt = moment(convertToUTC(get(payload, `[${0}].timeTo`))).toISOString();

    if (!groupId) {
      forEach(payload, (group) => {
        const tempGroup = group;
        const tempTimeFrom = moment(convertToUTC(tempGroup.timeFrom)).toISOString();
        const tempTimeTo = moment(convertToUTC(tempGroup.timeTo)).toISOString();
        tempGroup.timeFrom = tempTimeFrom;
        tempGroup.timeTo = tempTimeTo;
      });
      setTimeFrom(tf);
      setTimeTo(tt);
      createGroupA(payload).then(submitCallback('Group created successfully')).catch((err) => {
        handleError(err);
      });
    } else if (groupId !== '*') {
      const tempTimeFrom = moment(convertToUTC(payload.timeFrom)).toISOString();
      const tempTimeTo = moment(convertToUTC(payload.timeTo)).toISOString();
      payload.timeFrom = tempTimeFrom;
      payload.timeTo = tempTimeTo;
      setTimeFrom(payload.timeFrom);
      setTimeTo(payload.timeTo);
      updateGroupA(groupId, payload).then(submitCallback('Group updated successfully')).catch((err) => {
        handleError(err);
      });
    } else {
      forEach(payload, (group) => {
        const tempGroup = group;
        const tempTimeFrom = moment(convertToUTC(tempGroup.timeFrom)).toISOString();
        const tempTimeTo = moment(convertToUTC(tempGroup.timeTo)).toISOString();
        tempGroup.timeFrom = tempTimeFrom;
        tempGroup.timeTo = tempTimeTo;
      });
      setTimeFrom(tf);
      setTimeTo(tt);
      updateGroupRangeA(initialValues.guid, payload).then(submitCallback('Group updated successfully')).catch((err) => {
        handleError(err);
      });
    }
  };

  const onDateFromChange = (value) => {
    const activeGroup = formValues ? formValues.activeGroup : null;

    change(formatFieldName('dateTo'), value);
    if (!activeGroup || activeGroup === '*') {
      dateTo.current.getRenderedComponent().focus();
    }
    fetchInstructorsIfFilterOk();
  };

  const [minDate, maxDate] = useMemo(() => {
    if (initialValues && initialValues.groups) {
      return [
        new Date(minBy(_.values(initialValues.groups), 'timeFrom').timeFrom),
        new Date(maxBy(_.values(initialValues.groups), 'timeTo').timeTo),
      ];
    }
    return [new Date(), new Date()];
  }, [initialValues]);

  const repeat = getFieldValue('repeat') && getFieldValue('repeat').value && getFieldValue('repeat').value > 0;

  const buttonLabel = useMemo(() => {
    if (submitting) {
      return 'SAVING...';
    }
    if (initialValues && initialValues.groups) {
      return 'UPDATE';
    }
    return 'CREATE';
  }, [submitting, initialValues]);

  const getMinimumMaxAge = () => {
    if (initialValues && initialValues.maxAge) {
      return initialValues.maxAge;
    }
    const minAge = getFieldValue('minAge');
    if (minAge) {
      return minAge;
    }
    return 0;
  };

  return (
    <div>
      <form
        className="group-form"
        onSubmit={handleSubmit(onSubmit)}
        id="form"
      >
        <span className="BookingForm__title">
          {initialValues && initialValues.groups ? 'Edit Group' : 'Add Group'}
          <UserGuideIcon type="createGroup" />
        </span>

        {groupOptions
        && (
          <Fields.Select
            simpleValue
            clearable={false}
            name="activeGroup"
            fieldLabel="Select group:"
            options={groupOptions}
          />
        )}
        <Fields.DateFrom
          name={formatFieldName('dateFrom')}
          onChange={(e, value) => onDateFromChange(value)}
          validate={[required]}
          minDate={formValues && formValues.activeGroup === '*' && minDate}
          maxDate={formValues && formValues.activeGroup === '*' && maxDate}
          disabled={fieldsDisabled}
        />
        <span id="date-time-anchor" />
        <Fields.DateTo
          name={formatFieldName('dateTo')}
          onChange={onChange('dateTo')}
          validate={[required]}
          minDate={new Date(getFieldValue('dateFrom'))}
          maxDate={formValues && formValues.activeGroup === '*' && maxDate}
          refName={(ref) => {
            dateTo.current = ref;
          }}
          disabled={(!!groupOptions && formValues && formValues.activeGroup !== '*') || fieldsDisabled}
        />
        {((initialValues && !initialValues.groups) || !initialValues)
        && (
          <div style={{ marginBottom: '10px' }}>
            <span id="repeat-anchor" />
            <Fields.Select
              clearable={false}
              name={formatFieldName('repeat')}
              options={repeatOptions}
              onChange={onRepeatChange}
              validate={[required, minValue0, notEmpty]}
              fieldLabel="Repeat:*"
              disabled={fieldsDisabled}
            />
            <div className="checkbox-container">
              {repeat > 0
              && map(days, (day, key) => (
                <div key={key} className="checkbox-field">
                  <div className="checkbox-input">
                    <Checkbox
                      onCheck={(e, checked) => onChange(`days[${key}]`)(checked)}
                      disabled={fieldsDisabled}
                    />
                  </div>
                  <label className="checkbox-label">
                    {days[key].label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
        <Fields.TimeFrom
          name={formatFieldName('timeFrom')}
          validate={[required]}
          group
          onChange={onChange('timeFrom')}
          disabled={fieldsDisabled}
        />
        <Fields.TimeTo
          name={formatFieldName('timeTo')}
          validate={[required]}
          onChange={onChange('timeTo')}
          disabled={fieldsDisabled}
        />
        <Fields.SelectAsync
          searchable
          clearable={false}
          name={formatFieldName('resort')}
          onChange={onChange('resort')}
          autoload
          validate={[required]}
          fieldLabel="Resort:*"
          disabled={fieldsDisabled}
          initialOptions={initialResorts}
        />
        <Fields.Text
          name={formatFieldName('name')}
          type="text"
          fieldLabel="Name:*"
          disabled={(!!initialValues && (formValues && formValues.activeGroup && formValues.activeGroup !== '*')) || fieldsDisabled}
          validate={[required, maxLength255]}
        />
        <FormField label="Description:">
          <Field
            component="textarea"
            name={formatFieldName('description')}
            type="textarea"
            disabled={(!!initialValues && (formValues && formValues.activeGroup && formValues.activeGroup !== '*')) || fieldsDisabled}
          />
        </FormField>
        <span id="other-anchor" />
        <Fields.Select
          simpleValue
          name={formatFieldName('level')}
          options={levels}
          onChange={onChange('level')}
          fieldLabel="Group level:*"
          validate={[required]}
          disabled={fieldsDisabled}
        />
        <Fields.Select
          simpleValue
          name={formatFieldName('activity')}
          options={activities}
          onChange={onActivityChange}
          fieldLabel="Activity:*"
          validate={[required]}
          disabled={fieldsDisabled}
        />
        <Fields.Select
          simpleValue
          name={formatFieldName('speciality')}
          options={specialities}
          onChange={onChange('speciality')}
          fieldLabel="Speciality:*"
          validate={[required]}
          disabled={fieldsDisabled}
        />
        <Fields.Select
          multi
          clearable
          name={formatFieldName('instructors')}
          options={instructors}
          fieldLabel="Instructor:*"
          validate={[required, notEmpty]}
          disabled={fieldsDisabled}
        />
        <span id="amounts-anchor" />
        <Fields.Number
          fieldLabel="Customer amount:*"
          type="number"
          min={initialValues && !!initialValues.groups ? initialValues.size : 0}
          name={formatFieldName('clientAmount')}
          validate={[required, number, minValue1]}
          disabled={fieldsDisabled}
        />
        <FormField label="Age*:" theme="age-field">
          <Field
            component={RenderField}
            type="number"
            max={initialValues && !!initialValues.groups && initialValues.minAge}
            min={0}
            name={formatFieldName('minAge')}
            validate={[number, required]}
            disabled={fieldsDisabled}
          />
          -
          <Field
            component={RenderField}
            type="number"
            min={getMinimumMaxAge()}
            name={formatFieldName('maxAge')}
            validate={getFieldValue('minAge') ? [required, number] : []}
            disabled={fieldsDisabled}
          />
        </FormField>
        <Fields.Select
          simpleValue
          name={formatFieldName('language')}
          options={languageOptionsIdName}
          onChange={onChange('language')}
          fieldLabel="Language:"
          disabled={fieldsDisabled}
        />
        <Fields.Text
          type="text"
          name={formatFieldName('meetingPoint')}
          fieldLabel="Meeting point:*"
          validate={[required, maxLength255]}
          disabled={fieldsDisabled}
        />
        <span id="hours-anchor" />
        <Fields.Text
          type="number"
          min={0.5}
          name={formatFieldName('paidHours')}
          fieldLabel="Instructor hours:*"
          validate={[required, number, minValue0, maxValue24]}
          step={0.5}
          disabled={fieldsDisabled}
        />
      </form>
      <span id="price-anchor" />
      {!fieldsDisabled
      && (
        <FieldArray
          name="groupPrice"
          component={RenderGroupPricing}
          currency={get(account, '[0].currency', null)}
          validate={validateGroupPrice}
        />
      )}
      {!fieldsDisabled
      && (
        <form id="form" className="group-submit-form">
          <div
            style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}
            ref={(ref) => {
              bottom.current = ref;
            }}
          >
            <RaisedButton
              backgroundColor="#01579B"
              label={buttonLabel}
              labelStyle={bookingStyleObject.bookButton.label}
              style={bookingStyleObject.bookButton}
              onClick={handleSubmit(onSubmit)}
              disabled={pristine || invalid || submitting}
            />
          </div>
        </form>
      )}
    </div>
  );
};

const formConfig = {
  form: 'GroupForm',
};

const mapStateToProps = (state, props) => {
  const { entities, user } = state;
  const { initialValues } = props;
  let updatedInitialValues = initialValues;
  const activityFiltered = find(entities.activity, (a) => a.name === 'SKI');
  const specialitiesFiltered = filter(entities.speciality,
    (s) => s.activity === activityFiltered.id);
  const specialityFiltered = find(specialitiesFiltered, (s) => s.name === 'Standard');
  if (!initialValues) {
    updatedInitialValues = {
      resort: user.account.resort,
      activity: activityFiltered,
      speciality: specialityFiltered,
    };
  }

  return {
    initialValues: updatedInitialValues,
    account: _.values(entities.account),
    instructors: entities.instructor,
    activity: entities.activity,
    speciality: entities.speciality,
    formValues: getFormValues('GroupForm')(state),
    user: state.user,
    userGuide: state.userGuide,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchResorts: bindActionCreators(fetchResorts, dispatch),
  fetchInstructorsByAvailabilityFilter: bindActionCreators(
    fetchInstructorsByAvailabilityFilter,
    dispatch,
  ),
  fetchActivities: bindActionCreators(fetchActivities, dispatch),
  fetchSpecialities: bindActionCreators(fetchSpecialities, dispatch),
  createGroup: bindActionCreators(createGroup, dispatch),
  updateGroup: bindActionCreators(updateGroup, dispatch),
  updateGroupRange: bindActionCreators(updateGroupRange, dispatch),
  fetchMyAccount: bindActionCreators(fetchMyAccount, dispatch),
  hideDrawer: bindActionCreators(hideDrawer, dispatch),
  fetchEventsByFilter: bindActionCreators(fetchEventsByFilter, dispatch),
  notifySuccess: bindActionCreators(notifySuccess, dispatch),
  notifyError: bindActionCreators(notifyError, dispatch),
});

GroupForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  speciality: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  activity: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  instructors: PropTypes.object,
  userGuide: PropTypes.shape({
    groupScrollIntoView: PropTypes.bool,
  }),
  invalid: PropTypes.bool,
  change: PropTypes.func,
  updateCalendarView: PropTypes.func,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.array,
  fetchActivities: PropTypes.func,
  fetchSpecialities: PropTypes.func,
  fetchMyAccount: PropTypes.func,
  fetchInstructorsByAvailabilityFilter: PropTypes.func,
  notifySuccess: PropTypes.func,
  notifyError: PropTypes.func,
  fetchEventsByFilter: PropTypes.func,
  createGroup: PropTypes.func,
  hideDrawer: PropTypes.func,
  updateGroup: PropTypes.func,
  updateGroupRange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  initialResorts: PropTypes.object,
  updateGroupList: PropTypes.func,
  setUpdateFilter: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(formConfig)(React.memo(GroupForm)));
