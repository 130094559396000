import {
  ACTIVATE_GUIDE,
  DEACTIVATE_GUIDE,
  GO_TO_STEP,
  SET_TIMELINE_ANCHOR,
  TRIGGER_TIMELINE_BOUNDS_CHANGE,
  REMOVE_TIMELINE_BOUNDS_CHANGE,
  TRIGGER_MENU_OPEN,
  REMOVE_MENU_OPEN,
  SET_INSTRUCTOR_ANCHOR,
  SET_MANAGER_ANCHOR,
  TRIGGER_AVAILABILITY_FORM_OPEN,
  TRIGGER_BOOKING_FORM_OPEN,
  TRIGGER_GROUP_FORM_OPEN,
  GROUP_SCROLL_INTO_VIEW,
  BOOKING_SCROLL_INTO_VIEW,
  TRIGGER_ADD_NEW_CLIENT_OPEN,
  TRIGGER_CLIENT_FROM_PARTNER_OPEN,
  TRIGGER_ADD_NEW_PARTNER_OPEN,
  TRIGGER_LESSON_BLOCK_ADD,
  REMOVE_BOOKING_SCROLL_INTO_VIEW,
  // TRIGGER_SPECIAL_NEEDS_OPEN,
  TRIGGER_ADDITIONAL_SERVICE_OPEN,
  TRIGGER_PARTNER_MODAL_OPEN, TRIGGER_AVAILABILITY_FORM_CLOSE, TRIGGER_CREATE_OFFERS_OPEN,
  // TRIGGER_COMMENT_OPEN,
  // TRIGGER_PREPAYMENT_OPEN,
  TRIGGER_CREATE_OFFERS_CLOSE,
  TRIGGER_EXPORT_STEP_CHANGE, TRIGGER_CALENDAR_SCROLL_INTO_VIEW, REMOVE_CALENDAR_SCROLL_INTO_VIEW,
} from '../containers/UserGuide/constants';

const userGuide = (state = { active: false }, action) => {
  switch (action.type) {
    case ACTIVATE_GUIDE: {
      return {
        ...state,
        active: true,
        ...action.payload,
      };
    }
    case DEACTIVATE_GUIDE: {
      return {
        active: false,
      };
    }
    case GO_TO_STEP: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_TIMELINE_ANCHOR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_INSTRUCTOR_ANCHOR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case TRIGGER_TIMELINE_BOUNDS_CHANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REMOVE_TIMELINE_BOUNDS_CHANGE: {
      return {
        ...state,
        triggerTimelineBoundsChange: undefined,
      };
    }
    case TRIGGER_MENU_OPEN: {
      return {
        ...state,
        triggerMenuOpen: true,
      };
    }
    case REMOVE_MENU_OPEN: {
      return {
        ...state,
        triggerMenuOpen: false,
      };
    }
    case TRIGGER_AVAILABILITY_FORM_OPEN: {
      return {
        ...state,
        triggerAvailabilityFormOpen: true,
      };
    }
    case TRIGGER_AVAILABILITY_FORM_CLOSE: {
      return {
        ...state,
        triggerAvailabilityFormOpen: false,
      };
    }
    case TRIGGER_GROUP_FORM_OPEN: {
      return {
        ...state,
        triggerGroupFormOpen: true,
      };
    }
    case TRIGGER_BOOKING_FORM_OPEN: {
      return {
        ...state,
        triggerBookingFormOpen: true,
      };
    }
    case TRIGGER_ADD_NEW_CLIENT_OPEN: {
      return {
        ...state,
        triggerAddNewClientOpen: true,
      };
    }
    case TRIGGER_ADD_NEW_PARTNER_OPEN: {
      return {
        ...state,
        triggerAddNewPartnerOpen: true,
      };
    }
    case TRIGGER_CLIENT_FROM_PARTNER_OPEN: {
      return {
        ...state,
        triggerClientFromPartnerOpen: true,
      };
    }
    case GROUP_SCROLL_INTO_VIEW: {
      return {
        ...state,
        groupScrollIntoView: true,
      };
    }
    case BOOKING_SCROLL_INTO_VIEW: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REMOVE_BOOKING_SCROLL_INTO_VIEW: {
      return {
        ...state,
        bookingScrollIntoView: undefined,
        alignTop: undefined,
      };
    }
    case TRIGGER_LESSON_BLOCK_ADD: {
      return {
        ...state,
        triggerLessonBlockAdd: true,
      };
    }
    // case TRIGGER_SPECIAL_NEEDS_OPEN: {
    //   return {
    //     ...state,
    //     triggerSpecialNeedsOpen: true,
    //   }
    // }
    case TRIGGER_ADDITIONAL_SERVICE_OPEN: {
      return {
        ...state,
        triggerAdditionalServiceOpen: true,
      };
    }
    case TRIGGER_PARTNER_MODAL_OPEN: {
      return {
        ...state,
        triggerPartnerModalOpen: true,
      };
    }
    // case TRIGGER_COMMENT_OPEN: {
    //   return {
    //     ...state,
    //     triggerCommentOpen: true,
    //   }
    // }
    // case TRIGGER_PREPAYMENT_OPEN: {
    //   return {
    //     ...state,
    //     triggerPrepaymentOpen: true,
    //   }
    // }
    case SET_MANAGER_ANCHOR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case TRIGGER_CREATE_OFFERS_OPEN: {
      return {
        ...state,
        triggerCreateOffersOpen: true,
      };
    }
    case TRIGGER_CREATE_OFFERS_CLOSE: {
      return {
        ...state,
        triggerCreateOffersOpen: false,
      };
    }
    case TRIGGER_EXPORT_STEP_CHANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case TRIGGER_CALENDAR_SCROLL_INTO_VIEW: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REMOVE_CALENDAR_SCROLL_INTO_VIEW: {
      return {
        ...state,
        calendarScrollIntoView: undefined,
      };
    }
    default:
      return state;
  }
};

export default userGuide;
