import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import '../styles/AnonymousNav.scss';

const AnonymousNav = () => (
  <div className="nav">
    <div className="nav__wrapper">
      <div className="nav__icon" />
      <div className="nav__menu-wrapper--anonymous">
        <ul className="nav__menu">
          <li>
            <Link to="/login" className="btn btn--login btn--nav">Login</Link>
          </li>
          <li>
            <Link to="/register" className="btn btn--register btn--nav">Register</Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(React.memo(AnonymousNav));
