import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { FlatButton } from 'material-ui';
import { TextField } from 'redux-form-material-ui';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { get } from 'lodash';
import { changePassword } from '../actions/accountActions';
import { password, required } from '../utils/validators';
import '../styles/Login.scss';

const indicatorStyle = {
  display: 'inline-block',
  position: 'relative',
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'hide',
    };
  }

  onSubmit = (values) => {
    const { actions, history } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const body = { ...values, token };

    this.setState({
      status: 'loading',
    });
    return actions.changePassword(body).then(() => {
      this.setState({
        status: 'hide',
      });
      history.push('/login?confirmation=changed');
    }).catch((er) => {
      const message = get(er, 'data.constraintViolations[0].message', '');

      this.setState({
        status: 'hide',
      });
      throw new SubmissionError({ _error: message });
    });
  }

  render() {
    const { handleSubmit, invalid, error } = this.props;
    const { status } = this.state;

    return (
      <div className="form-page__form-wrapper">
        <h2 className="form-page__form-heading">Change password</h2>
        <form onSubmit={handleSubmit(this.onSubmit)} className="login-form">
          <span className="error">
            {' '}
            {error && <span>{error}</span>}
          </span>
          <RefreshIndicator
            size={40}
            left={10}
            top={0}
            status={status}
            style={indicatorStyle}
          />
          <div className="login-form__input-wrapper">
            <label>New password:</label>
            <Field
              name="password"
              type="password"
              component={TextField}
              validate={[password, required]}
            />
          </div>
          <div className="login-form__input-wrapper">
            <label>Repeat password:</label>
            <Field
              name="passwordRepeat"
              type="password"
              component={TextField}
              validate={[required]}
            />
          </div>
          <FlatButton
            type="submit"
            label="SUBMIT"
            style={{
              color: '#FFFFFF',
              margin: '30px auto 0 auto',
              width: '200px',
              display: 'block',
              cursor: invalid ? 'default' : 'pointer',
              backgroundColor: invalid ? 'grey' : '#253D63',
            }}
            disabled={invalid}
          />
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (values.password && values.passwordRepeat && values.password !== values.passwordRepeat) {
    errors.passwordRepeat = 'Passwords must match';
  }
  return errors;
};

const formConfig = {
  form: 'ChangePassword',
  validate,
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    changePassword: bindActionCreators(changePassword, dispatch),
  },
});

ChangePassword.propTypes = {
  actions: PropTypes.shape({
    changePassword: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  error: PropTypes.string,
};

export default connect(null, mapDispatchToProps)((reduxForm(formConfig)(ChangePassword)));
