import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { DatePicker } from 'redux-form-material-ui';
import { omit } from 'lodash';
import skiThemeLessonDatepicker from '../styles/theme/skiThemeLessonDatepicker';
import skiThemeRegularDatepicker from '../styles/theme/skiThemeRegularDatepicker';

const formatDate = (date) => moment(date).format('DD/MMM/YYYY');

const RenderDatePicker = (props) => {
  const {
    input,
    theme,
    refName,
    disableError,
    meta,
  } = props;
  return (
    <div className={`datepicker${input.name === 'dateOfBirth' ? ' datepicker--birthday' : ''}`}>
      {theme === 'white'
        ? (
          <MuiThemeProvider muiTheme={getMuiTheme(skiThemeLessonDatepicker)}>
            <DatePicker
              {...omit(props, 'theme', 'group', 'refName')}
              name={input.name}
              formatDate={formatDate}
              ref={refName}
            />
          </MuiThemeProvider>
        )
        : (
          <MuiThemeProvider muiTheme={getMuiTheme(skiThemeRegularDatepicker)}>
            <DatePicker
              {...omit(props, 'theme', 'group', 'refName')}
              name={input.name}
              formatDate={formatDate}
              ref={refName}
            />
          </MuiThemeProvider>
        )}
      {!disableError
      && (
        <span className="error">
          {' '}
          {meta.touched && meta.error && <span>{meta.error}</span>}
        </span>
      )}
    </div>
  );
};

RenderDatePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
  }),
  theme: PropTypes.string,
  refName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  disableError: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default React.memo(RenderDatePicker);
