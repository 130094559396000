import React from 'react';
import PropTypes from 'prop-types';
import {
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
} from './helpers/arrow-styles';

const Arrow = ({
  position,
  width,
  height,
  size,
  color,
}) => {
  let arrowStyle;
  switch (position) {
    case 'left':
      arrowStyle = arrowRight({ size, color });
      arrowStyle.left = width;
      arrowStyle.top = '1em';
      break;
    case 'right':
      arrowStyle = arrowLeft({ size, color });
      arrowStyle.top = '1em';
      break;
    case 'top':
      arrowStyle = arrowDown({ size, color });
      arrowStyle.top = height;
      arrowStyle.left = '1em';
      break;
    case 'topLeft':
      arrowStyle = arrowDown({ size, color });
      arrowStyle.top = height;
      arrowStyle.left = width - (size * 3.5);
      break;
    case 'bottom':
      arrowStyle = arrowUp({ size, color });
      arrowStyle.left = '1em';
      break;
    case 'bottomLeft':
      arrowStyle = arrowUp({ size, color });
      arrowStyle.left = width - (size * 4);
      break;
    default:
      arrowStyle = {};
  }
  return <div className="react-user-tour-arrow" style={arrowStyle} />;
};

Arrow.propTypes = {
  position: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
};

export default Arrow;
