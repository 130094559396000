import getMuiTheme from 'material-ui/styles/getMuiTheme';

const skiThemeRegularDatepicker = getMuiTheme({
  palette: {
    textColor: '#0d47a1',
    primary1Color: '#1565c0',
    accent3Color: '#424242',
    primary3Color: '#616161',
    primary2Color: '#0d47a1',
    shadowColor: 'rgba(255, 255, 255, 0.03)',
    clockCircleColor: '#1565c0',
    pickerHeaderColor: '#1565c0',
    accent1Color: '#1976d2',
    borderColor: '#aaa',
    secondaryTextColor: '#546e7a',
  },
  textField: {
    floatingLabelColor: '#0d47a1',
    hintColor: '#0d47a1',
  },
  datePicker: {
    selectColor: '#1565c0',
    selectTextColor: '#ffffff',
    calendarYearBackgroundColor: '#ffffff',
  },

});

export default skiThemeRegularDatepicker;
