const secondDropdown = [
  {
    name: 'Resort',
    value: 'resort',
  },
  {
    name: 'Instructor',
    value: 'instructor',
  },
  {
    name: 'Product',
    value: 'product',
  },
  {
    name: 'Group',
    value: 'group',
  },
  {
    name: 'Activity',
    value: 'activity',
  },
  {
    name: 'Speciality',
    value: 'speciality',
  },
  {
    name: 'Partner',
    value: 'partner',
  },
  {
    name: 'Manager',
    value: 'partnerManager',
  },
  {
    name: 'Client',
    value: 'client',
  },
  {
    name: 'Hotel',
    value: 'hotel',
  },
];

const firstDropdown = [
  {
    name: 'Booking',
    value: 'booking',
  },
  {
    name: 'Additional services',
    value: 'additionalServices',
  },
];

const dropdownsValues = [
  firstDropdown,
  secondDropdown,
];

export default dropdownsValues;
