import React from 'react';
import PropTypes from 'prop-types';
import '../styles/Minus.scss';

const MinusButton = ({ className, onClick }) => (
  <div className={className ? `${className} minus-button` : 'minus-button'} onClick={onClick} />
);

MinusButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default React.memo(MinusButton);
