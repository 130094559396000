import getMuiTheme from 'material-ui/styles/getMuiTheme';

const skiThemeProductForm = getMuiTheme({
  palette: {
    primary3Color: '#253D63',
    primary2Color: '#0d47a1',
    primary1Color: '#253D63',
    accent1Color: '#00e676',
    accent2Color: '#00e676',
    accent3Color: '#00e676',
    borderColor: '#aaa',
    canvasColor: '#ffffff',
    textColor: '#ffffff',
    secondaryTextColor: '#1565c0',
    alternateTextColor: 'rgb(255, 255, 255)',
    disabledColor: 'rgba(255, 255, 255, 0.87)',
  },
  datePicker: {
    selectColor: '#1565c0',
    selectTextColor: '#ffffff',
    calendarYearBackgroundColor: '#ffffff',
  },
  timePicker: {
    clockCircleColor: '#1565c0',
    headerColor: '#1565c0',
    color: '#253D63',
    textColor: '#ffffff',
    accentColor: '#ffffff',
    selectColor: '#1565c0',
    selectTextColor: '#000000',
  },

  textField: {
    textColor: '#0d47a1',
    floatingLabelColor: '#0d47a1',
    hintColor: '#0d47a1',

  },

});

export default skiThemeProductForm;
