import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Tour from './react-user-tour';
import {
  getCurrentGuideSteps,
  getNextGuide,
} from './utils';
import * as userGuideActions from '../../actions/userGuide';

import '../../styles/UserGuide.scss';

class UserGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userGuide } = nextProps;

    this.setState({ ...userGuide });
  }

  handleDoneClick = (tourStep, skip) => {
    const { actions: { activateGuide, deactivateGuide } } = this.props;
    const { general, currentGuide } = this.state;
    const {
      suspendCallbackOnLast, step, reduceNextStepForSkip, increaseNext, extend,
    } = tourStep;
    const nextGuide = getNextGuide(currentGuide, skip);
    const callbackFn = this.getCallbackFn(currentGuide, step, skip);
    const callbackFnParams = this.getCallbackFnParams(currentGuide, step, skip);
    let stepNo = (extend || increaseNext) ? 1 : 0;

    if (reduceNextStepForSkip && skip) {
      stepNo -= 1;
    }

    const latency = get(tourStep, 'latency', 250);

    deactivateGuide();

    if (callbackFn && !suspendCallbackOnLast && (general || tourStep.extend)) {
      callbackFn(callbackFnParams);
    }
    if ((general || tourStep.extend) && nextGuide) {
      setTimeout(() => activateGuide(nextGuide, general, stepNo), latency);
    }
  };

  handleStepChange = (step, tourStep) => {
    const { actions } = this.props;
    const { currentGuide, step: prevStep } = this.state;
    const callbackFn = this.getCallbackFn(currentGuide, step, undefined, prevStep);
    const callbackFnParams = this.getCallbackFnParams(currentGuide, step);
    const latency = get(tourStep, 'latency', 250);

    if (callbackFn && (!tourStep.lastStep || tourStep.enableCallback)) {
      callbackFn(callbackFnParams);
    }
    setTimeout(() => actions.goToStep(step), latency);
  };

  getCallbackFn = (guide, step, skip, prevStep) => {
    const { actions, history } = this.props;

    if (guide === 'createBooking') {
      if (step === 2) {
        return actions.triggerBookingFormOpen;
      }
      if (step === 3) {
        return actions.triggerAddNewClientOpen;
      }
      if (step === 5) {
        actions.triggerBookingScrollIntoView('formTop');
        return actions.triggerClientFromPartnerOpen;
      }
      if (step === 6) {
        actions.triggerBookingScrollIntoView('newPartnerManager', true);
        return actions.triggerAddNewPartnerOpen;
      }
      if (step === 8) {
        actions.triggerBookingScrollIntoView('hotel', true);
      }
      if (step === 10) {
        actions.triggerBookingScrollIntoView('lessonTop');
      }
      if (step === 11) {
        actions.triggerBookingScrollIntoView('formBottom-0');
      }
      if (step === 12) {
        actions.triggerBookingScrollIntoView('discountAnchor-0');
        return actions.triggerLessonBlockAdd;
      }
      if (step === 13) {
        actions.triggerBookingScrollIntoView('discountAnchor-1', { inline: 'nearest' });
      }
      if (step === 14 || step === 15 || step === 19) {
        return actions.triggerBookingScrollIntoView;
      }
      if (step === 16) {
        actions.triggerBookingScrollIntoView('additionalServiceAnchor');
        return actions.triggerAdditionalServiceOpen;
      }
      if (step === 21) {
        return actions.triggerBookingFormClose;
      }
    }

    if (prevStep > step) {
      return undefined;
    }

    switch (guide) {
      case 'calendar': {
        if (step === 3) {
          return actions.triggerTimelineBoundsChange;
        }
        if (step === 7) {
          return actions.triggerCalendarScrollIntoView;
        }
        if (step === 9) {
          return actions.triggerMenuOpen;
        }
        return undefined;
      }
      case 'schoolSettings': {
        if (step === 1) {
          return history.push;
        }
        return undefined;
      }
      case 'accountInformation': {
        if (step === 1) {
          return history.push;
        }
        return undefined;
      }
      case 'instructors': {
        if (step === 1 && !skip) {
          return history.push;
        }
        if (step === 6 && !skip) {
          return (timeout) => setTimeout(() => document.getElementById('edit-anchor').scrollIntoView(true), timeout);
        }
        if (step === 7 && !skip) {
          return history.push;
        }
        return undefined;
      }
      case 'availabilities': {
        if (step === 3) {
          return actions.triggerAvailabilityFormOpen;
        }
        if (step === 4) {
          return actions.triggerAvailabilityFormClose;
        }
        return undefined;
      }
      case 'hotels': {
        if (step === 1 && !skip) {
          return history.push;
        }
        return undefined;
      }
      case 'additionalServices': {
        if (step === 1 && !skip) {
          return history.push;
        }
        return undefined;
      }
      case 'products': {
        if (skip) {
          return history.push;
        }
        if (step === 1) {
          return history.push;
        }
        if (step === 3) {
          return history.push;
        }
        return undefined;
      }
      case 'productForm': {
        if (step === 10) {
          return history.push;
        }
        return undefined;
      }
      case 'createGroup': {
        if (step === 2) {
          return actions.triggerGroupFormOpen;
        }
        if (step === 6) {
          return actions.triggerGroupScrollIntoView;
        }
        return undefined;
      }
      case 'partners': {
        if (step === 3) {
          return actions.triggerPartnerModalOpen;
        }
        return undefined;
      }
      case 'managers': {
        if (step === 2) {
          return history.push;
        }
        return undefined;
      }
      case 'createOffer': {
        if (step === 1) {
          return actions.triggerCreateOffersOpen;
        }
        if ((step === 5 || step === 8) && !skip) {
          return actions.triggerExportStepChange;
        }
        if (step === 13) {
          return actions.triggerCreateOffersClose;
        }
        return undefined;
      }
      default: return undefined;
    }
  };

  getCallbackFnParams = (guide, step, skip) => {
    const { timelineAnchor, instructorAnchor, managerAnchor } = this.state;

    switch (guide) {
      case 'calendar': {
        if (step === 3) {
          if (timelineAnchor) {
            const { timeFrom, timeTo, instructor } = timelineAnchor;

            return { timeFrom: timeFrom.add(-3, 'hour'), timeTo: timeTo.add(3, 'hour'), group: `group-${instructor}` };
          }
        }
        if (step === 7) {
          return 'group-0';
        }
        return undefined;
      }
      case 'schoolSettings': {
        if (step === 1) {
          return '/school';
        }
        return undefined;
      }
      case 'accountInformation': {
        if (step === 1) {
          return '/account';
        }
        return undefined;
      }
      case 'instructors': {
        if (step === 1) {
          return '/instructors';
        }
        if (step === 6 && !skip) {
          return 200;
        }
        if (step === 7 && !skip) {
          return `/availability/${instructorAnchor.id}`;
        }
        return undefined;
      }
      case 'hotels': {
        if (step === 1) {
          return '/hotels';
        }
        return undefined;
      }
      case 'additionalServices': {
        if (step === 1) {
          return '/services';
        }
        return undefined;
      }
      case 'products': {
        if (skip) {
          return '/calendar';
        }
        if (step === 1) {
          return '/products';
        }
        if (step === 3) {
          return '/product';
        }
        return undefined;
      }
      case 'productForm': {
        if (step === 10) {
          return '/calendar';
        }
        return undefined;
      }
      case 'createGroup': {
        if (step === 6) {
          return 'hours';
        }
        return undefined;
      }
      case 'createBooking': {
        if (step === 15) {
          return 'lessonBlockBottom';
        }
        if (step === 19) {
          return 'drawerBottom';
        }
        if (step === 14) {
          return 'lessonTop';
        }
        return undefined;
      }
      case 'managers': {
        if (step === 2) {
          return `/managers/${managerAnchor.id}/:overview`;
        }
        return undefined;
      }
      case 'createOffer': {
        if (step === 5) {
          return 2;
        }
        if (step === 8) {
          return 3;
        }
        return undefined;
      }
      default: return undefined;
    }
  };

  render() {
    const {
      active,
      currentGuide,
      step,
    } = this.state;

    return (
      <div>
        <Tour
          active={active}
          step={step}
          onNext={this.handleStepChange}
          onBack={this.handleStepChange}
          onCancel={this.handleDoneClick}
          onSkip={this.handleDoneClick}
          doneButtonText="Finish"
          steps={getCurrentGuideSteps(currentGuide)}
          hideClose
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userGuide: state.userGuide,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userGuideActions, dispatch),
});

UserGuide.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  userGuide: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserGuide));
