import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { getFormValues, getFormSyncErrors, change } from 'redux-form';
import { omit, isEmpty } from 'lodash';
import ExportFilterForm from './ExportFilterForm';
import ExportSelectForm from './ExportSelectForm';
import ExportGetLinkForm from './ExportGetLinkForm';
import NavigateButton from '../components/NavigateButton';
import UserGuideIcon from '../components/UserGuideIcon';
import { fetchProducts, getFilteredProductsTimes } from '../actions/productsActions';
import { fetchMyAccount } from '../actions/accountActions';
import { filterProducts } from '../utils/filter';
import { formatFilterPayload } from '../utils/format';

import '../styles/ExportWizard.scss';
import { goToStep } from '../actions/userGuide';

const Wizard = {
  1: {
    header: 'Filter products',
    component: <ExportFilterForm />,
  },
  2: {
    header: 'Select products',
    component: <ExportSelectForm />,
  },
  3: {
    header: 'Get link',
    component: <ExportGetLinkForm />,
  },
};

class ExportFilterWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      loading: 'hide',
      error: false,
    };
  }

  componentDidMount() {
    const { fetchMyAccountA } = this.props;

    fetchMyAccountA();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { errors, userGuide } = nextProps;
    const { userGuide: prevUserGuide } = this.props;
    const { activePage } = this.state;

    if (!errors || isEmpty(errors)) {
      this.setState({
        error: false,
      });
    }

    if (userGuide && userGuide.exportWizardStep
      && prevUserGuide.exportWizardStep !== userGuide.exportWizardStep) {
      const direction = activePage < userGuide.exportWizardStep ? 'forward' : 'backward';
      this.changeActivePage(direction);
    }
  }

  changeActivePage = (direction) => {
    const { activePage: stateActivePage } = this.state;
    const { errors } = this.props;
    if (errors && !isEmpty(errors)) {
      this.setState({
        error: true,
      });
    } else {
      const currentPage = stateActivePage;
      const activePage = direction === 'forward' ? currentPage + 1 : currentPage - 1;
      this.setState({
        activePage,
        error: false,
      });
      this.nextPageDataPreparation(currentPage, activePage);
    }
  }

  nextPageDataPreparation = (prevPage, activePage) => {
    if (prevPage === 1 && activePage === 2) {
      this.fromFirstToSecond();
    } else if (prevPage === 2 && activePage === 3) {
      this.fromSecondToThird();
    }
  }

  fromFirstToSecond = () => {
    const {
      fetchProductsA,
      values,
      changeA,
      userGuide,
      goToStepA,
    } = this.props;
    this.setState({
      loading: 'loading',
    });
    fetchProductsA().then((res) => {
      const filteredProducts = filterProducts(res.payload.result.product, omit(values, ['timeFrom', 'timeTo', 'activity']));
      changeA('ExportFilterWizard', 'products', filteredProducts);
      this.setState({
        loading: 'hide',
      });
    });
    if (userGuide && userGuide.step === 4) {
      goToStepA(5);
    }
  }

  fromSecondToThird = () => {
    const {
      values,
      getFilteredProductsTimesA,
      userGuide,
      goToStepA,
    } = this.props;
    const requestPayload = formatFilterPayload(values);
    this.setState({
      loading: 'loading',
    });
    getFilteredProductsTimesA(requestPayload).then(() => {
      this.setState({
        loading: 'hide',
      });
    });

    if (userGuide && userGuide.step === 7) {
      setTimeout(() => goToStepA(8), 3000);
    }
  }

  render() {
    const { activePage, loading, error } = this.state;
    const { handleModalState } = this.props;

    return (
      <div className="export-wizard">
        <div>
          <i onClick={() => handleModalState()} className="material-icons sidemenu__close close-button">close</i>
          <UserGuideIcon
            type="createOffer"
            step={1}
            style={{
              marginRight: '5%', position: 'absolute', top: '20px', right: '20px',
            }}
          />
        </div>
        <h2 className="export-wizard-header">{Wizard[activePage].header}</h2>
        {error
          && <h5>Required* fields can not be empty</h5>}
        {loading === 'loading'
          ? (
            <RefreshIndicator
              size={40}
              left={10}
              top={10}
              status={loading}
            />
          )
          : Wizard[activePage].component}
        {Wizard[activePage - 1]
          && (
          <NavigateButton
            direction="backward"
            label="Previous"
            onClickHandler={() => this.changeActivePage('backward')}
            disabled={loading === 'loading'}
            id="button-previous"
          />
          )}
        {Wizard[activePage + 1]
          && (
          <NavigateButton
            direction="forward"
            label="Next"
            onClickHandler={() => this.changeActivePage('forward')}
            disabled={loading === 'loading'}
            id="button-next"
          />
          )}
      </div>
    );
  }
}

ExportFilterWizard.propTypes = {
  fetchMyAccountA: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  userGuide: PropTypes.shape({
    step: PropTypes.number,
    exportWizardStep: PropTypes.number,
  }),
  fetchProductsA: PropTypes.func,
  values: PropTypes.shape({
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
  }),
  changeA: PropTypes.func,
  goToStepA: PropTypes.func,
  getFilteredProductsTimesA: PropTypes.func,
  handleModalState: PropTypes.func,
};

const mapStateToProps = (state) => ({
  values: getFormValues('ExportFilterWizard')(state),
  errors: getFormSyncErrors('ExportFilterWizard')(state),
  product: state.entities.product,
  userGuide: state.userGuide,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductsA: bindActionCreators(fetchProducts, dispatch),
  getFilteredProductsTimesA: bindActionCreators(getFilteredProductsTimes, dispatch),
  changeA: bindActionCreators(change, dispatch),
  fetchMyAccountA: bindActionCreators(fetchMyAccount, dispatch),
  goToStepA: bindActionCreators(goToStep, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportFilterWizard);
