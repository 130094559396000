import { schema } from 'normalizr';
import { httpRequest } from '../middleware/http';
import lessonBlockSchema from '../schema/LessonBlock';

// eslint-disable-next-line import/prefer-default-export
export const fetchLessonBlocksByBooking = (id) => httpRequest({
  method: 'GET',
  path: `/booking/${id}/lessonBlock`,
  types: [
    'LESSON_BLOCKS_GET_REQUEST',
    'LESSON_BLOCKS_GET_SUCCESS',
    'LESSON_BLOCKS_GET_FAILURE',
  ],
  schema: new schema.Array(lessonBlockSchema),
});
