import { schema } from 'normalizr';
// eslint-disable-next-line import/no-cycle
import partnerSchema from './Partner';

const partnerManagerSchema = new schema.Entity('partnerManager', { idAttribute: 'id' });

partnerManagerSchema.define({
  partner: partnerSchema,
});

export default partnerManagerSchema;
