import {
  filter,
  find,
  forEach,
  forOwn,
  get,
  isEmpty,
  map,
  sortBy,
  values,
  uniqBy,
} from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { sortDates } from './helpers';
import { convertToUTC } from './dateTime';

const moment = extendMoment(Moment);

const parseLessonProperties = (lesson) => {
  let parsedLesson = {};

  forEach(lesson, (val, key) => {
    if (typeof val === 'object') {
      let value = get(val, 'id', undefined);
      if (!value) {
        value = get(val, 'value', val);
      }
      parsedLesson = {
        ...parsedLesson,
        [key]: value,
      };
    }
    parsedLesson = {
      ...parsedLesson,
      [key]: val,
    };
  });

  return parsedLesson;
};

export const mapPropsToOptions = (props, removeAny, fixKeys, isGroup) => {
  const options = [];
  const items = Array.isArray(props) ? props : map(props, (item) => item);
  const uniqItems = uniqBy(items, (item) => {
    if (isGroup) {
      return item.guid;
    }
    return item.id;
  });
  forEach(uniqItems, (item) => {
    if (!removeAny || (removeAny && (item.name !== 'Any'))) {
      if (fixKeys) {
        options.push({ value: item.id, label: item.name });
      } else {
        options.push({ id: item.id, name: item.name });
      }
    }
  });
  return options;
};

export const mapGroupsToOptions = (groups) => {
  const groupsArray = [];
  forEach(groups, (group) => {
    if (moment(group.timeTo).isSameOrAfter(moment(), 'day')) {
      groupsArray.push({
        value: group.guid,
        label: group.name,
      });
    }
  });
  return groupsArray;
};

export const mapPersonsToOptions = (props, fixKeys) => map(props, (item) => {
  if (fixKeys) {
    return { value: item.id, label: `${item.name} ${item.surname}` };
  }
  return { id: item.id, name: `${item.name} ${item.surname}` };
});

export const mapInstructorsToValue = (instructors) => {
  if (Array.isArray(instructors)) {
    return map(instructors, (instructor) => instructor.id);
  }
  return instructors.id;
};

export const mapLessonsToOptions = (lessons) => {
  const sortedLessons = sortDates(lessons);
  return map(sortedLessons, (item) => ({
    id: item.id,
    name: typeof item.id === 'number' ? moment(item.dateFrom).set({
      hours: new Date(item.timeFrom).getHours(),
      minutes: new Date(item.timeFrom).getMinutes(),
    }).format('DD/MMM/YYYY HH:mm') : 'All lessons',
  }));
};

export const mapDateTimeToTime = (dateFrom, dateTo, timeFrom, timeTo) => {
  if (!dateFrom || !dateTo || !timeFrom || !timeTo) {
    return {
      timeFrom: undefined,
      timeTo: undefined,
    };
  }
  const isoDateFrom = moment(convertToUTC(dateFrom)).toISOString();
  const isoDateTo = moment(convertToUTC(dateTo)).toISOString();
  const isoTimeFrom = moment(convertToUTC(timeFrom)).toISOString();
  const isoTimeTo = moment(convertToUTC(timeTo)).toISOString();

  return {
    timeFrom: `${isoDateFrom.split('T')[0]}T${isoTimeFrom.split('T').pop()}`,
    timeTo: `${isoDateTo.split('T')[0]}T${isoTimeTo.split('T').pop()}`,
  };
};

export const mapLessonToPayload = (type, lesson) => {
  let payload = {
    ...lesson,
  };

  const objects = ['booking', 'product', 'resort', 'speciality'];

  forEach(objects, (key) => {
    if (!payload[key] || !payload[key].id) return;
    payload[key] = payload[key].id;
  });

  forEach(lesson.buyers, (buyer) => {
    const temp = buyer;
    if (temp.level === '') {
      temp.level = null;
    }
    delete temp.id;
  });

  if (type === 'individual' && payload.instructor && payload.instructor.id) {
    payload.instructor = payload.instructor.id;
  }

  if (type === 'individual' && payload.instructor && payload.instructor.value) {
    payload.instructor = payload.instructor.value;
  }

  if (type === 'group' && payload.group) {
    if (payload.group.value) {
      payload.group = payload.group.value;
    } else if (payload.group.id) {
      payload.group = payload.group.id;
    }
    payload.dateFrom = moment(convertToUTC(payload.dateFrom)).toISOString();
    payload.dateTo = moment(convertToUTC(payload.dateTo)).toISOString();
    delete payload.instructor;
  }

  const time = mapDateTimeToTime(
    payload.dateFrom,
    payload.dateTo,
    payload.timeFrom,
    payload.timeTo,
  );

  payload = {
    ...parseLessonProperties(payload),
    ...time,
  };
  if (payload.resort.value) {
    payload.resort = payload.resort.value;
  } else if (payload.resort.id) {
    payload.resort = payload.resort.id;
  }
  if (payload['object Object']) {
    delete payload['object Object'];
  }
  if (payload.null) {
    delete payload.null;
  }
  return payload;
};

export const mapNewLessonToPayload = (type, lesson) => {
  const payload = [];

  const tmp = moment(lesson.dateFrom);

  const dateFrom = moment(lesson.dateTo).set({
    year: tmp.get('year'),
    month: tmp.get('month'),
    date: tmp.get('date'),
  });
  const dateTo = moment(lesson.dateTo);

  for (let date = moment(dateFrom); date.diff(dateTo, 'days') <= 0; date.add(1, 'days')) {
    const unit = mapLessonToPayload(type, {
      ...lesson,
      dateFrom: moment(convertToUTC(date)).set({ hours: 0, minutes: 0, seconds: 0 }),
      dateTo: moment(convertToUTC(date)).set({ hours: 0, minutes: 0, seconds: 0 }),
    });
    payload.push(unit);
  }

  return payload;
};

export const mapSelectedLessonsToPayload = (lessons, type) => {
  const lessonPayload = find(lessons, { id: '*' });
  const selectedRange = moment.range(lessonPayload.dateFrom, lessonPayload.dateTo);
  selectedRange.end = moment(selectedRange.end).add(1, 'day');

  const lessonsReturn = map(lessons, (lesson) => {
    if (lesson.id !== '*') {
      if (selectedRange.contains(lesson.dateFrom) && selectedRange.contains(lesson.dateTo)) {
        return mapLessonToPayload(type, {
          ...lessonPayload,
          dateFrom: lesson.dateFrom,
          dateTo: lesson.dateTo,
          id: lesson.id,
        });
      }
    }
    return null;
  });
  return filter(lessonsReturn, (lesson) => !!lesson);
};

export const mapLessonBlockToPayload = (lessonBlock) => {
  let type = get(lessonBlock, 'type', lessonBlock.type);
  if (typeof type === 'object') {
    type = type.value;
  }

  if (lessonBlock.edit && lessonBlock.activeLesson === '*') {
    return {
      ...lessonBlock,
      type,
      discount: (lessonBlock.discount || 0).toString().match(/^\d+(?:\.\d+|)/)[0],
      lessons: mapSelectedLessonsToPayload(lessonBlock.lessons, lessonBlock.type),
    };
  }

  if (lessonBlock.edit) {
    const lessons = [];
    forEach(lessonBlock.lessons, (lesson) => {
      if (lesson.id && lesson.id !== '*') {
        lessons.push(mapLessonToPayload(type, lesson));
      }
    });

    return {
      ...lessonBlock,
      type,
      discount: (lessonBlock.discount || 0).toString().match(/^\d+(?:\.\d+|)/)[0],
      lessons,
    };
  }

  return {
    ...lessonBlock,
    type,
    discount: (lessonBlock.discount || 0).toString().match(/^\d+(?:\.\d+|)/)[0],
    lessons: mapNewLessonToPayload(type, lessonBlock.lessons[0]),
  };
};

export const mapBookingServiceToPayload = (bookingService) => {
  if (!bookingService) {
    return null;
  }
  return {
    ...bookingService,
    discount: (bookingService.discount || 0).toString().match(/^\d+(?:\.\d+|)/)[0],
    vat: bookingService.vat || false,
  };
};

export const mapBookingServicesToPayload = (bookingServices) => {
  if (!bookingServices || isEmpty(bookingServices[0])) {
    return [];
  }
  const list = map(bookingServices, (bookingService) => mapBookingServiceToPayload(bookingService));
  return filter(list, (item) => !!item);
};

export const mapGroupDatesToOptions = (groups) => map(groups, (group) => {
  const name = group.id === '*' ? 'All groups'
    : moment(group.timeFrom).add(new Date().getTimezoneOffset() / 60, 'hour').format('DD/MMM/YYYY HH:mm');
  return { value: group.id, label: name };
});

const mapGroupsNoRepeatToPayload = (splitedRange, vals) => map(splitedRange, (d) => ({
  ...vals,
  timeFrom: moment(d).set({
    hour: vals.timeFrom.getHours(),
    minute: vals.timeFrom.getMinutes(),
  }).toISOString(),
  timeTo: moment(d)
    .set({ hour: vals.timeTo.getHours(), minute: vals.timeTo.getMinutes() })
    .toISOString(),
}));

const mapGroupsSelectedRepeatValueToPayload = (days, splitedRange, vals, repeat) => {
  const groups = [];
  let gap = false;

  forEach(splitedRange, (d) => {
    if (days[d.weekday()] === true && !gap) {
      groups.push({
        ...vals,
        timeFrom: moment(d).set({
          hour: vals.timeFrom.getHours(),
          minute: vals.timeFrom.getMinutes(),
        }).toISOString(),
        timeTo: moment(d)
          .set({ hour: vals.timeTo.getHours(), minute: vals.timeTo.getMinutes() })
          .toISOString(),
      });
    }
    if (repeat === 2) {
      if (d.weekday() === 0) {
        gap = !gap;
      }
    }
  });

  return groups;
};

export const mapGroupsToPayload = (repeat, days, start, end, vals) => {
  const selectedRange = moment.range(start, end);
  const splitedRange = Array.from(selectedRange.by('day'));

  if (!repeat) {
    return mapGroupsNoRepeatToPayload(splitedRange, vals);
  }

  return mapGroupsSelectedRepeatValueToPayload(days, splitedRange, vals, repeat);
};

const getEventType = (e) => {
  const type = e.id.split('-')[0];

  switch (type) {
    case 'L': {
      return 'individual';
    }
    case 'G': {
      return 'group';
    }
    default: {
      return '';
    }
  }
};

export const mapEventsToBlocks = (events) => {
  let blocks = {};

  forOwn(events, (e) => {
    const today = moment();
    const momentFrom = moment.utc(e.timeFrom);
    const momentTo = moment.utc(e.timeTo);
    const month = momentFrom.format('MM');
    const day = momentFrom.format('DD');
    const type = e.id.split('-')[0];

    if (e.instructor && type !== 'A' && moment(e.timeTo).isSameOrAfter(today)) {
      const dayEvents = get(blocks, `[${month}][${day}]`, []);

      dayEvents.push({
        type: getEventType(e),
        timeFrom: momentFrom.format('HH:mm'),
        timeTo: momentTo.format('HH:mm'),
        client: e.clientFullName,
        bid: `B-${e.bid}`,
        paid: e.paid,
        level: e.level,
        ageRestrictions: `${e.minAge}-${e.maxAge} age`,
        clientAmount: `${e.clientAmount} people`,
        name: e.name,
        speciality: e.specialityName,
        groupSize: e.size,
        id: e.id,
        booking: e.booking,
        guid: e.guid,
      });
      blocks = {
        ...blocks,
        [month]: {
          ...blocks[month],
          [day]: dayEvents,
        },
      };
    }
  });

  return blocks;
};

export const mapMonthEvents = (month) => sortBy(map(month,
  (m, key) => ({ events: m, day: key })), (d) => d.day);

export const mapSpecToActivity = (specialities, activityList) => {
  const activityMap = [];
  values(activityList).forEach((act) => {
    if (act.value !== 'Any') {
      activityMap[act.id] = act.label;
    }
  });

  const activitySpecs = {};
  forEach(specialities, (spec) => {
    const activityName = activityMap[spec.activity];
    if (activityName) {
      if (!activitySpecs[activityName]) {
        activitySpecs[activityName] = [];
      }
      if (spec.name !== 'Any') {
        activitySpecs[activityName].push(spec.name);
      }
    }
  });
  return activitySpecs;
};
