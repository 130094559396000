import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import FlatButton from 'material-ui/FlatButton';
import Dialog from '../../components/dialog/Dialog';
import DeleteEntityButtonPricingTable from '../../components/DeleteEntityButtonPricingTable';
import SeasonForm from '../SeasonForm';

class RenderPricingTableHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  handleDialog = () => {
    const { dialogOpen } = this.state;
    this.setState({
      dialogOpen: !dialogOpen,
    });
  }

  handleSeasonAdd = () => {
    const { dateValues, handleSeason } = this.props;
    this.handleDialog();
    handleSeason(dateValues.dateFrom, dateValues.dateTo);
  }

  handleSeasonDelete = (index) => () => {
    const { handleRemoveSeason, fields } = this.props;
    const { dateFrom } = fields.get(index);
    const { dateTo } = fields.get(index);
    handleRemoveSeason(dateFrom, dateTo);
  }

  render() {
    const {
      fields,
      dateValues,
      invalid,
    } = this.props;
    const { dialogOpen } = this.state;
    const actions = [
      <FlatButton
        label="Cancel"
        default
        onClick={this.handleDialog}
      />,
      <FlatButton
        label="Submit"
        primary
        onClick={this.handleSeasonAdd}
        disabled={invalid}
      />,
    ];

    return (
      <tr className="season-pricing__table-header">
        <th className="season-pricing__table-size-header">
          <span>Size*</span>
        </th>
        {fields.map((season, index) => fields.get(index).dateFrom && fields.get(index).dateTo
          && (
          // eslint-disable-next-line react/no-array-index-key
          <th key={`season_${index}`} className="season-pricing__season">
            <span className="season__date-to">
              <DeleteEntityButtonPricingTable onClick={this.handleSeasonDelete(index)} />
            </span>
            <span className="season__date-from">
              {fields.get(index).dateFrom}
            </span>
            <span className="season__separator">-</span>
            <span className="season__date-to">
              {fields.get(index).dateTo}
            </span>
          </th>
          ))}
        <th className="season-pricing__table-size-column">
          <div className="season-pricing__add-season">
            <button
              className="button--add-season"
              onClick={this.handleDialog}
              type="button"
            />
            <label className="season-pricing__add-season-label">Add season*</label>
          </div>
        </th>
        <Dialog
          contentClassName="add-season-dialog"
          title="Add Season"
          actions={actions}
          modal={false}
          open={dialogOpen}
          onRequestClose={this.handleDialog}
        >
          <SeasonForm
            dateValues={dateValues}
            handleSeasonAdd={this.handleSeasonAdd}
            fields={fields}
          />
        </Dialog>
      </tr>
    );
  }
}

const selector = formValueSelector('RenderPricingTableHeader');

function mapStateToProps(state) {
  return {
    dateValues: selector(state, 'dateFrom', 'dateTo'),
  };
}

RenderPricingTableHeader.propTypes = {
  dateValues: PropTypes.shape({
    dateFrom: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    dateTo: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }),
  handleSeason: PropTypes.func,
  handleRemoveSeason: PropTypes.func,
  fields: PropTypes.shape({
    get: PropTypes.func,
    map: PropTypes.func,
  }),
  invalid: PropTypes.bool,
};

export default connect(mapStateToProps)(RenderPricingTableHeader);
